import React, { useState } from 'react'
import { BiPlus, BiTrashAlt } from 'react-icons/bi';
import { MdArrowDownward, MdPersonOutline, MdArrowUpward, MdContentCopy, MdFolderOpen, MdOutlineVisibilityOff, MdOutlineVisibility } from 'react-icons/md';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useEffect } from 'react';
import { useProductPaletteContext } from '../../../../../../context/productPaletteContext';
import { useMessage } from '../../../../../../context/messageContext';
import { addCollectionColours, deleteCollection, updateCollectionOrder } from '../../../../../../services/productServices';
import { copyItem, itemVisibility } from '../../../../../../services/commonServices';
import { useRightContext } from '../../../../../../context/rightMenuContext';



const CollectionsRight = ({ setLoading, getAllCollectionServices }) => {

  const { newItemList, fetchSelected, activeIndex, handleActiveIndexDown, handleActiveIndexUp, setActiveIndex, timestamp } = useProductPaletteContext();
  const { setMessage, setMessageType } = useMessage()
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [isDeleted, setIsDeleted] = useState(true)

  const accessToken = localStorage.getItem('access_token')

  const navigate = useNavigate()

  const { selectedRightItem, userDataContext, setSelectedRightItem } = useRightContext()



  useEffect(() => {
    setActiveIndex(0)
  }, [])

  useEffect(() => {
    if (newItemList?.length > 0) {
      let selectedRight = newItemList?.find((p) => p.id === timestamp);

      setSelectedRightItem(selectedRight);
    }
  }, [timestamp, newItemList]);

  const getSingleColor = newItemList?.find((item) => item.id === timestamp)

  const addCollectionServices = async (name) => {
    setLoading(true)
    const data = {
      id: "",
      name: name,
      type: 1,
    }
    const res = await addCollectionColours(data, accessToken)
    if (res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        setMessageType('success')
        setMessage(res?.data?.message ? res?.data?.message : 'Added new colour')
        getAllCollectionServices('added')
        setLoading(false)
      } else {
        setMessage(res?.data?.message ? res?.data?.message : 'Something went wrong')
        setMessageType('error')
        getAllCollectionServices()
        setLoading(false)
      }
    }
  }

  const handleVisibility = async (e, item) => {
    setLoading(true)
    const res = await itemVisibility(accessToken, item?.id, 15, !item?.isEnabled)

    if (res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        console.log("rrs", res)
        setMessageType('success')
        setLoading(false)
        setMessage(res?.data?.message ? res.data.message : 'Action Successful')
        getAllCollectionServices()
      } else {
        setMessage(res?.data?.message ? res.data.message : 'Something went wrong')
        setMessageType('error')
        getAllCollectionServices()
        setLoading(false)
      }
    } else {
      setLoading(false)
      setMessage(res?.message ? res.message : 'Something went wrong');
      setMessageType('error')
      getAllCollectionServices()
      if (res?.message === "Network Error") {
        navigate('/error/network')
      }
    }
  }

  const deleteCollectionServices = async () => {
    setLoading(true)
    setIsDeleted(false)
    const res = await deleteCollection(accessToken, timestamp)
    if (res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        setMessageType('success')
        setMessage(res?.data?.message ? res.data.message : 'Deleted Color Successfully')
        getAllCollectionServices()
        setLoading(false)
        fetchSelected(0, newItemList[0]?.id)
        setShowDeleteModal(false)
        setIsDeleted(true)
      } else {
        setMessage(res?.data?.message ? res.data.message : 'Something went wrong')
        setMessageType('error')
        getAllCollectionServices()
        setLoading(false)
        setIsDeleted(true)

      }
    } else {
      setLoading(false)
      setMessage(res?.message ? res.message : 'Something went wrong');
      setMessageType('error')
      getAllCollectionServices()
      setIsDeleted(true)
      if (res?.message === "Network Error") {
        navigate('/error/network')
      }
    }

  }

  const updateOrderServices = async (direction) => {
    setLoading(true)
    const res = await updateCollectionOrder(timestamp, 1, direction, accessToken)
    if (res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        setMessageType('success')
        setMessage(res?.data?.message ? res?.data?.message : 'Order updated successfully')
        getAllCollectionServices()
        setLoading(false)
        if (direction === 'UP') {
          handleActiveIndexUp()
        } else {
          handleActiveIndexDown()
        }
      } else {
        setMessage(res?.data?.message ? res?.data?.message : 'Something went wrong')
        setMessageType('error')
        getAllCollectionServices()
        setLoading(false)
      }
    }
  }

  const handleUp = () => {
    updateOrderServices('UP')
  }

  const handleDown = () => {
    updateOrderServices('DOWN')
  }

  const copyPaletteServices = async (id) => {
    setLoading(true)
    const res = await copyItem(accessToken, id, 15)
    if (res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        setMessageType('success')
        setMessage(res?.data?.message ? res.data.message : 'Item copied')
        getAllCollectionServices()
        setLoading(false)
      } else {
        setMessage(res?.data?.message ? res.data.message : 'Something went wrong')
        setMessageType('error')
        getAllCollectionServices()
        setLoading(false)
      }
    } else {
      setLoading(false)
      setMessage(res?.message ? res.message : 'Something went wrong');
      setMessageType('error')
      getAllCollectionServices()
      if (res?.message === "Network Error") {
        navigate('/error/network')
      }
    }
  }

  return (
    <>
      <div className='h-100 d-flex flex-column flex-grow-1 justify-content-between'>
        <div className='inside_scroll has_btns'>
          <div className='right_list'>
            {newItemList && newItemList?.map((item, index) =>
              <div key={index} className={`position-relative d-flex align-items-center right_item_wrap has_delete has_copy ${userDataContext.role === "Supplier" && 'count_3'}`}>
                <Link to="#" className={`right_item w-100 ${activeIndex === index && "active"}`} onClick={() => { fetchSelected(index, item?.id) }} >
                  <span className='me-2'>
                    <MdFolderOpen size={22} />
                  </span>
                  <span className='text-truncate'>
                    {item.name}
                  </span>
                </Link>
                <span className='position-absolute action_btns'>
                  {userDataContext && userDataContext.role === "Supplier" && (
                    <Link to="#" onClick={(e) => { fetchSelected(index, item.id); handleVisibility(e, item) }}>
                      {!item?.isEnabled ? (
                        <MdOutlineVisibilityOff className='text-muted visibility_off' size={20} />
                      ) : (
                        <MdOutlineVisibility className='text-muted visibility_on' size={20} />
                      )}
                    </Link>
                  )}
                  {item?.isSelf && <>
                    <Link to="#" onClick={() => { fetchSelected(index, item?.id); setShowDeleteModal(true) }} > <BiTrashAlt className='text-muted' size={20} /> </Link>
                    <Link to="#" onClick={() => copyPaletteServices(item?.id)}> <MdContentCopy className='text-muted' size={18} /> </Link>
                  </>}
                </span>
              </div>
            )}
          </div>
        </div>

        <Modal isOpen={showDeleteModal} toggle={() => setShowDeleteModal(false)}>
          <ModalHeader >
            Delete Collection
          </ModalHeader>
          <ModalBody>
            Are you sure you want delete <strong>{getSingleColor?.name}</strong> ?
          </ModalBody>
          <ModalFooter>
            <Button color="outline-danger" size="md" onClick={() => deleteCollectionServices()} disabled={!isDeleted}>Delete</Button>
            <Button onClick={() => setShowDeleteModal(false)} color="primary" size="md">Cancel</Button>
          </ModalFooter>
        </Modal>

        <div className='d-flex gap-2 gap-xl-3 main_buttons'>
          <Button
            color="primary"
            size="md"
            className='px-4'
            onClick={() => addCollectionServices(`Collection Color ${newItemList.length + 1}`)}
          >
            <BiPlus size={20} className='me-1' />
            New
          </Button>

          {selectedRightItem?.isSelf && <>
            <Button
              color=""
              size="md"
              className='border-0 text-primary px-0'
              onClick={() => handleUp()}
              disabled={activeIndex === 0 || newItemList.length === 0}
            >
              <MdArrowUpward size={22} className='me-1' />
              Up
            </Button>
            <Button
              color=""
              size="md"
              className='border-0 text-primary px-0'
              onClick={() => handleDown()}
              disabled={activeIndex === newItemList.length - 1 || newItemList.length === 0 || (selectedRightItem?.isLast)}
            >
              <MdArrowDownward size={22} className='me-1' />
              Down
            </Button>
          </>}
        </div>
      </div>
    </>
  )
}

export default CollectionsRight