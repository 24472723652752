import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Table } from "react-bootstrap";
import { Button } from "reactstrap";
import axios from "axios";
import {
  handleEmptyValues,
  newHandleInput,
} from "../../../../../../utility/helper";
import PricingHeader from "../../../../../../components/Pricing/PricingHeader";
import CustomizedButton from "../../../../../../components/CustomizedButton";
import { servicePath } from "../../../../../../utility/data";
import { useRightContext } from "../../../../../../context/rightMenuContext";
import PricingTableComp from "../../../../../../components/PricingTableComp";

const Left = ({
  token,
  pageName,
  setMessage,
  setMessageType,
  loading,
  setLoading,
}) => {
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [selectedPricing, setSelectedPricing] = useState();
  const [selectedSupplyOnly, setSelectedSupplyOnly] = useState("");
  const [costPrice, setCostPrice] = useState([]);
  const { userDataContext, setUserDataContext } = useRightContext("");
  const handleInput = (id, key, value) => {
    if (validate(value)) {
      setData((prevData) =>
        prevData.map((item, index) =>
          index === id ? { ...item, [key]: parseFloat(value) } : item
        )
      );
    }
  };

  const validate = (value) => {
    if (value < 0) return false;
    return true;
  };

  useEffect(() => {
    setLoading(true);

    if (selectedPricing && selectedSupplyOnly) {
      getAdditionalArticlePricingData();
    }
  }, [selectedPricing, selectedSupplyOnly]);

  const getAdditionalArticlePricingData = () => {
    const FormData = require("form-data");
    let data = new FormData();
    data.append("PriceMasterId", selectedPricing);
    data.append("Markupmasterid", selectedSupplyOnly);
    data.append("TypeId", 2);
    data.append("PriceType", 1);
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${servicePath}/General/GetAdditionalArticlesPricing`,
      headers: {
        accept: "text/plain",
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
      data: data,
    };
    axios
      .request(config)
      .then((res) => {
        if (res?.data?.statusCode === 200) {
          setLoading(false);
          setData(res?.data?.entity);
          console.log(data, "data");
        } else if (res?.data?.statusCode === 404) {
          setLoading(false);
          navigate("/error/404");
        } else {
          setLoading(false);
          setMessage(
            res?.data?.message ? res.data.message : "Something went wrong"
          );
          setMessageType("error");
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        setMessage(error.message ? error.message : "Something went wrong");
        setMessageType("error");
        if (error?.message === "Network Error") {
          navigate("/error/network");
        }
      });
  };

  const handleUpdate = () => {
    setLoading(true);
    updateTableData();
  };

  const updateTableData = () => {
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${servicePath}/General/AddUpdateAdditionalArticlesPricing`,
      headers: {
        accept: "text/plain",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: handleEmptyValues(data),
    };
    axios
      .request(config)
      .then((res) => {
        console.log(res.data, "UpdatedProjectAncillaries");
        if (res?.data?.statusCode === 200) {
          setMessage(res.data.message);
          setMessageType("success");
          getAdditionalArticlePricingData();
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        setMessage(error.message ? error.message : "Something went wrong");
        setMessageType("error");
        if (error?.message === "Network Error") {
          navigate("/error/network");
        }
      });
  };

  const handleFormReset = () => {
    setLoading(true);
    getAdditionalArticlePricingData();
  };

  const data1 = { url: `/product/hardware/additional-hardware` };

  return (
    <>
      <PricingHeader
        setSelectedSupplyOnly={setSelectedSupplyOnly}
        setSelectedPricing={setSelectedPricing}
        selectedPricing={selectedPricing}
        selectedSupplyOnly={selectedSupplyOnly}
        costPrice={costPrice}
      />

      <div className="d-flex align-items-center mb-4 flex-wrap gap-3 left_header">
        <h5 className="mb-0 text-wrap text-break me-4 main_title">
          {pageName === "" ? "Additional Hardware" : pageName}
        </h5>

        <CustomizedButton data={data1} />
      </div>
      <Form className="h-100 d-flex flex-column flex-grow-1 justify-content-between">
        <div className="inside_scroll has_tab has_btns">
          <PricingTableComp
            data={data}
            handleInput={handleInput}
            newHandleInput={newHandleInput}
            setData={setData}
            userDataContext={userDataContext}
          ></PricingTableComp>
        </div>
        <div className="d-flex gap-2 gap-xl-3 main_buttons">
          <Button
            color="primary"
            size="md"
            className="px-4"
            onClick={handleUpdate}
          >
            Save Changes
          </Button>

          <Button
            color="primary"
            size="md"
            outline
            className="px-4"
            onClick={handleFormReset}
          >
            Reset
          </Button>
        </div>
      </Form>
    </>
  );
};

export default Left;
