import React from 'react'
import InnerLayout from "../../../../../../layouts/internalLayout"
import DocumentsLeft from './left'
import DocumentsRight from './right'
import { useState } from 'react'
import { Oval } from 'react-loader-spinner'
import { useMessage } from '../../../../../../context/messageContext'
import { addNewRightItem, getAllPrintTemplate, getItemDetails, getTabPrintTemplate, updateItemDetails } from '../../../../../../services/printDocsService'
import { useEffect } from 'react'
import { useRightContext } from '../../../../../../context/rightMenuContext'
import { useNavigate } from 'react-router-dom'
import { getFontSizeMaster } from '../../../../../../services/commonServices'

const generalInitialValues = {
  "id": '',
  "name": '',
  "fileName": '',
  'paperSize': "A4",
  'paperOrientation': "Portrait",
  'margin': "",
  'showTableOfContentsFrames': "4",
  'markUpPriceList': "From project",
  "initialValue": true
}

const HeaderInitialValues = {
  "id": '',
  "title": '',
  "titleFontSize": '',
  'projectInfo': [],
  'projectInfoFontSize': "",
  'showHeader': "On first page only",
  "initialValue": true
}

const ForewordInitialValues = {
  "id": '',
  'text': [],
  'newPageAfter': [],
  'fontSize': "",
  "initialValue": true
}

var SummaryInitialValues = {
  "id": '',
  'frames': false,
  'installation': false,
  'ancillaries': false,
  'ancillariesPrice': false,
  'total': false,
  'startNewPageBeforeSummary': false,
  "initialValue": true
}

const TermInitialValues = {
  "id": '',
  'text': [],
  'newPageAfter': [],
  'fontSize': "",
  "initialValue": true
}

const FrameInitialValues = {
  "id": '',
  'nameFontSize': "",
  'StartNewPageForEachFrame': false,
  'StartNewRowForEachFrame': false,
  'printFrameInfo': [],
  'fontSize': "",
  'View': "Icons",
  'printFrameProperty': [],
  'MinimumWidth': "",
  'PanelTitleFontSize': "",
  'PropertyIconSize': "",
  'PropertyFontSize': "",
  'HideInstallation': false,
  'Ancillaries': false,
  'Total': false,
  'ExternalView': true,
  'InternalView': false,
  'FrameDimensions': false,
  'IncludeSill': false,
  'TransomDimensions': false,
  "openSash": false,
  'Size': "",
  'RotationX': 0,
  'RotationY': 0,

  'gridLineHorizontal': false,
  'gridLineVertical': false,
  'leftRightPadding': "",
  'topBottomPadding': "",
  'itemised': false,
  "initialValue": true
}

var ManufacturingInitialValues = {
  "id": '',

  'individualCuttingList': false,
  'indCLFirstCut': false,
  'indCLFinishedCut': false,
  'indCLLength': false,
  'indCLEndPrep': false,
  'indGlassSize': false,
  'indPanelSize': false,
  'indDoorSize': false,

  'combCutList': false,
  'combCDFirstCut': false,
  'combCDFinishedCut': false,
  'combinedProfileCuttingList': false,
  'combCLFirstCut': false,
  'combCLFinishedCut': false,
  'combGlassSize': false,
  'combPanelSize': false,
  'combinedCuttingDiagram': false,
  "combDoorSize": false,
  "initialValue": true
}

const Documents = () => {

  const accessToken = localStorage.getItem('access_token')

  const [activeTab, setActiveTab] = useState("General")
  const [printIdKey, setPrintIdKey] = useState('')
  const [fontOptionsDynamic, setFontOptionsDynamic] = useState([])

  const [loading, setLoading] = useState(false)
  const [selectItemDetails, setSelectItemDetails] = useState([])
  const [allRightListItems, setAllRightListItems] = useState([])

  const { setMessage, setMessageType } = useMessage()
  const { timestamp, fetchSelected, setTimestamp, activeIndex } = useRightContext()

  const navigate = useNavigate();

  var getFirstElId = allRightListItems[0]?.id || ''
  var itemAddedTrue = ''

  useEffect(() => {
    setLoading(true);
    setTimestamp('')

    getFontSizeOptions(1)
    
    setTimeout(() => {
      getAllPrintTempData()
    }, 400);
  }, [])

  useEffect(() => {
    setPrintIdKey(timestamp)

    if (allRightListItems?.length > 0 && timestamp) {
      getRightItemDetails(timestamp, activeTab)
    }

  }, [timestamp])

  useEffect(() => {
    if (getFirstElId && allRightListItems?.length > 0 && activeIndex !== -1) {
      if (allRightListItems?.length > 0 && activeIndex !== 0) {
        getRightItemDetails(timestamp, activeTab)
      } else {
        getRightItemDetails(getFirstElId, activeTab)
      }
    } else {
      if (activeTab === "General") {
        setSelectItemDetails(generalInitialValues)
      }

      if (activeTab === "Header") {
        setSelectItemDetails(HeaderInitialValues)
      }

      if (activeTab === "Foreword") {
        setSelectItemDetails(ForewordInitialValues)
      }

      if (activeTab === "Summary") {
        setSelectItemDetails(SummaryInitialValues)
      }

      if (activeTab === "Term") {
        setSelectItemDetails(TermInitialValues)
      }

      if (activeTab === "Manufacturing") {
        setSelectItemDetails(ManufacturingInitialValues)
      }

      if (activeTab === "Frame") {
        setSelectItemDetails(FrameInitialValues)
      }
    }
  }, [allRightListItems])

  useEffect(() => {
    if (timestamp) {
      if (timestamp && activeTab !== "General") {
        getTabAllPrintTempData()
      } else {
        getRightItemDetails(timestamp, activeTab)
      }
    } else {
      setSelectItemDetails([])
    }
  }, [activeTab])

  // get font size dropdown from master
  const getFontSizeOptions = async (typeID) => {
    const res = await getFontSizeMaster(accessToken, typeID);

    if (res && res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        if (res?.data?.entity !== null) {
          if (res?.data?.entity?.length > 0) {
            setFontOptionsDynamic(res?.data?.entity)
          }
        }
      }
    }
  }

  // get all print template docs list
  const getAllPrintTempData = async () => {
    const res = await getAllPrintTemplate(accessToken);

    if (res && res && res.status === 200) {

      if (res?.data?.statusCode === 200) {
        if (res?.data?.entity !== null) {

          if (res?.data?.entity?.length > 0) {
            setAllRightListItems(res?.data?.entity)

            if (itemAddedTrue == 'added') {
              const addedItemIndex = res.data.entity.length - 1

              if (addedItemIndex) {
                fetchSelected(addedItemIndex, res.data.entity[addedItemIndex]?.id)
                itemAddedTrue = ''
              }
            }

          } else {
            setAllRightListItems([])
            setTimestamp('')

            fetchSelected(0, '');
          }
        }

        setTimeout(() => {
          setLoading(false)
        }, 1200);
      } else {
        setLoading(false)
        setMessage(res?.data.message ? res.data.message : 'Something went wrong');
        setMessageType('error')
      }

    } else {
      setLoading(false)
      setMessage(res?.message ? res.message : 'Something went wrong');
      setMessageType('error')

      if (res?.message === "Network Error") {
        navigate('/error/network')
      }
    }
  }

  // get all print template docs list tab wise
  const getTabAllPrintTempData = async () => {
    setLoading(true)

    const res = await getTabPrintTemplate(accessToken, printIdKey, activeTab);

    if (res && res && res.status === 200) {

      if (res?.data?.statusCode === 200) {
        if (res?.data?.entity !== null) {
          setSelectItemDetails(res?.data?.entity)
        } else {

          if (activeTab === "Header") {
            setSelectItemDetails(HeaderInitialValues)
          }

          if (activeTab === "Foreword") {
            setSelectItemDetails(ForewordInitialValues)
          }

          if (activeTab === "Summary") {
            setSelectItemDetails(SummaryInitialValues)
          }

          if (activeTab === "Term") {
            setSelectItemDetails(TermInitialValues)
          }

          if (activeTab === "Manufacturing") {
            setSelectItemDetails(ManufacturingInitialValues)
          }
          if (activeTab === "Frame") {
            setSelectItemDetails(FrameInitialValues)
          }
        }

        setTimeout(() => {
          setLoading(false)
        }, 1200);
      } else {
        setLoading(false)
        setMessage(res?.data.message ? res.data.message : 'Something went wrong');
        setMessageType('error')
      }

    } else {
      setLoading(false)
      setMessage(res?.message ? res.message : 'Something went wrong');
      setMessageType('error')

      if (res?.message === "Network Error") {
        navigate('/error/network')
      }
    }
  }

  // get details of selected item in right menu by passing item id
  const getRightItemDetails = async (id, tabName) => {
    setLoading(true);

    const res = await getItemDetails(accessToken, id, tabName);

    // console.log(res, `docs ${tabName} details`)

    if (res && res && res.status === 200) {

      if (res?.data?.statusCode === 200) {

        if (res?.data?.entity !== null) {
          // set state to get details of current selected item which will display data in left side component
          setSelectItemDetails(res.data.entity)

          // set selected item id which will update context
          setTimestamp(id)
        } else {

          if (activeTab === "Header") {
            setSelectItemDetails(HeaderInitialValues)
          }

          if (activeTab === "Foreword") {
            setSelectItemDetails(ForewordInitialValues)
          }

          if (activeTab === "Summary") {
            setSelectItemDetails(SummaryInitialValues)
          }

          if (activeTab === "Term") {
            setSelectItemDetails(TermInitialValues)
          }

          if (activeTab === "Manufacturing") {
            setSelectItemDetails(ManufacturingInitialValues)
          }

          if (activeTab === "Frame") {
            setSelectItemDetails(FrameInitialValues)
          }
        }

        setTimeout(() => {
          setLoading(false)
        }, 1200);
      } else {
        setLoading(false)
        setMessage(res?.data.message ? res.data.message : 'Something went wrong');
        setMessageType('error')
      }

    } else {
      setLoading(false)
      setMessage(res?.message ? res.message : 'Something went wrong');
      setMessageType('error')

      if (res?.message === "Network Error") {
        navigate('/error/network')
      }
    }
  }

  // api call to update all selected item details
  const updateItemDetailsServices = async (data, tabType, printID) => {

    setLoading(true)

    const res = await updateItemDetails(accessToken, timestamp ? timestamp : getFirstElId, printID ? printID : printIdKey, data, tabType)

    if (res && res && res.status === 200) {

      if (res?.data?.statusCode === 200) {
        setMessage(res?.data?.message);
        setMessageType('success')

        if (timestamp && activeTab !== "General") {
          getTabAllPrintTempData()
        } else {
          getRightItemDetails(timestamp, activeTab)
        }

        setTimeout(() => {
          setLoading(false)
        }, 1200);
      } else {
        setLoading(false)
        setMessage(res?.data?.message ? res.data.message : 'Something went wrong');
        setMessageType('error')
      }
    } else {
      setLoading(false)
      setMessage(res?.message ? res.message : 'Something went wrong');
      setMessageType('error')

      if (res?.message === "Network Error") {
        navigate('/error/network')
      }
    }
  }

  // api call to added new right item
  const addNewRightItemServices = async (title, type, addedIndex) => {
    setLoading(true)
    itemAddedTrue = "added"

    setActiveTab("General")

    const res = await addNewRightItem(accessToken, title, type)

    if (res && res && res.status === 200) {
      // console.log(res, 'create docs res')

      if (res?.data?.statusCode === 200) {
        setMessage(res?.data?.message);
        setMessageType('success')

        getAllPrintTempData()

      } else {
        setLoading(false)
        setMessage(res?.data?.message ? res.data.message : 'Something went wrong');
        setMessageType('error')
      }
    } else {
      setLoading(false)
      setMessage(res?.message ? res.message : 'Something went wrong');
      setMessageType('error')

      if (res?.message === "Network Error") {
        navigate('/error/network')
      }
    }
  }

  const handleFormReset = () => {
    if (timestamp) {

      if (activeTab === "General") {
        getAllPrintTempData()
      }

      getRightItemDetails(timestamp, activeTab)
    }
  }

  return (
    <>
      {loading && (
        <div className='loader_main full_loader left_loader'>
          <Oval
            height={50}
            width={50}
            color="#44C8F5"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel='oval-loading'
            secondaryColor="#44C8F5"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      )}

      <InnerLayout pageTitle="Documents" layoutType=""
        leftContent={<DocumentsLeft fontOptions={fontOptionsDynamic} handleFormReset={handleFormReset} accessToken={accessToken} activeTab={activeTab} setActiveTab={setActiveTab} updateItemDetailsServices={updateItemDetailsServices} loading={loading} selectItemDetails={selectItemDetails} setMessage={setMessage} setMessageType={setMessageType} setLoading={setLoading} />}
        rightContent={<DocumentsRight accessToken={accessToken} selectItemDetails={selectItemDetails} allRightListItems={allRightListItems} setLoading={setLoading} getAllPrintTempData={getAllPrintTempData} addNewRightItemServices={addNewRightItemServices} />} />
    </>
  )
}

export default Documents