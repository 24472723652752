import React, { useState, useEffect } from 'react'
import { Form } from 'react-bootstrap';
import { MdOutlineKeyboardArrowDown } from 'react-icons/md';
import { MenuItem, OutlinedInput, Select } from 'material-ui-core';
import {
    Row,
    Col,
    Button,
    FormGroup,
} from 'reactstrap';
import { MenuProps, noSelectOptions } from '../utility/data';
import { useRightContext } from '../context/rightMenuContext';
import { GetProfileMastersForSystem } from '../services/commonServices';
import DualListHideShowSystems from './DualListHideShowSystems';
import { SetDefaultProductProfile, UpdateSashProductProfileOrder, addSashProductProfiles, dualListItemMoveToLeft, getAllHiddenVisibleProfileItems } from '../services/sysSashProductsService';
import { useMessage } from '../context/messageContext';

const SystemSashProfile = ({ isEditable, navigate, sashId, setLoading, activeTab, accessToken, selectItemDetails, updateItemDetailsServices, handleFormReset }) => {

    const { timestamp } = useRightContext()
    const { setMessage, setMessageType } = useMessage()

    const [profileOptions, setProfileOptions] = useState([]);

    const [itemId, setItemId] = useState('')
    const [profileId, setProfileId] = useState("")

    const [hiddenItems, setHiddenItems] = useState([])
    const [visibleItems, setVisibleItems] = useState([])
    const [selectedHiddenIndex, setSelectedHiddenIndex] = useState(0);
    const [selectedVisibleIndex, setSelectedVisibleIndex] = useState(0);
    const [visibleData, setVisibleData] = useState('')

    useEffect(() => {
        if (activeTab === "Profiles" && sashId) {

            var typeId = 2

            if (sashId == 3) {
                typeId = 3
            }

            if (sashId == 5) {
                typeId = 5
            }

            if (sashId == 4 || sashId == 6) {
                typeId = 4
            }

            getProfileMasterForSys(typeId)
        }
    }, [activeTab, sashId])

    useEffect(() => {
        if (profileId && sashId && timestamp) {
            getHiddenVisibleListService()
            // getVisibleListService()
        }
    }, [profileId, sashId, timestamp])

    useEffect(() => {
        if (profileOptions && profileOptions.length > 0) {
            setProfileId(profileOptions[0]?.id)
        }
    }, [profileOptions])

    // get product color collection
    const getProfileMasterForSys = async (typeId) => {
        const res = await GetProfileMastersForSystem(accessToken, typeId)

        if (res && res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                if (res?.data?.entity !== null) {
                    if (res?.data?.entity?.length > 0) {
                        setProfileOptions(res?.data?.entity)
                    } else {
                        setProfileOptions(noSelectOptions)
                    }
                }
            }
        }
    }

    const getHiddenVisibleListService = async () => {
        setLoading(true)

        const res = await getAllHiddenVisibleProfileItems(accessToken, profileId, sashId, timestamp)

        if (res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                setMessage(res?.data?.message)
                setMessageType('success')
                setHiddenItems(res?.data?.entity?.hiddenProfiles)
                setVisibleItems(res?.data?.entity?.visibleProfiles)

                setLoading(false)
            }
        } else {
            setLoading(false)
        }
    }

    // const getVisibleListService = async () => {
    //     setLoading(true)
    //     const res = await getAllHiddenVisibleProfileItems(accessToken, profileId, sashId, timestamp)
    //     if (res && res.status === 200) {
    //         if (res?.data?.statusCode === 200) {
    //             setMessage(res?.data?.message)
    //             setMessageType('success')
    //             setLoading(false)
    //             setVisibleItems(res?.data?.entity?.visibleProfiles)
    //         }
    //     } else {
    //         setLoading(false)
    //     }
    // }

    const addExternalProductService = async (itemID) => {
        setLoading(true)
        const res = await addSashProductProfiles(accessToken, profileId, sashId, timestamp, itemID)
        if (res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                setMessage(res?.data?.message ? res.data.message : 'Set to visible')
                setMessageType('success')
                setLoading(false)
                getHiddenVisibleListService()
                // getVisibleListService()

            } else {
                setLoading(false)
                setMessage(res?.data?.message ? res.data.message : 'Something went wrong');
                setMessageType('error')
                getHiddenVisibleListService()
                // getVisibleListService()
            }
        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')
            if (res?.message === "Network Error") {
                navigate('/error/network')
                setLoading(false)
            }
        }
    }

    const dualListItemMoveToLeftServices = async (itemId) => {

        setLoading(true)

        const res = await dualListItemMoveToLeft(accessToken, itemId, "Profiles")
        if (res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                setMessage(res?.data?.message ? res.data.message : 'Set to hidden')
                setMessageType('success')
                setLoading(false)
                getHiddenVisibleListService()
                // getVisibleListService()
            } else {
                setLoading(false)
                setMessage(res?.data?.message ? res.data.message : 'Something went wrong');
                setMessageType('error')
                getHiddenVisibleListService()
                // getVisibleListService()
            }
        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')
            if (res?.message === "Network Error") {
                navigate('/error/network')
                setLoading(false)
            }
        }
    }

    const setDefaultItemService = async (itemId) => {
        setLoading(true)

        const res = await SetDefaultProductProfile(accessToken, itemId)

        if (res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                setMessage(res?.data?.message ? res.data.message : "Set to default")
                setMessageType('success')
                getHiddenVisibleListService()
            } else {
                setLoading(false)
                setMessage(res?.message ? res.message : 'Something went wrong');
                setMessageType('error')
            }
        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')
            if (res?.message === "Network Error") {
                navigate('/error/network')
                setLoading(false)
            }
        }
    }

    const updateHiddenVisibleOrder = async (direction) => {
        setLoading(true)

        const res = await UpdateSashProductProfileOrder(accessToken, visibleData.id, direction, timestamp, profileId)

        if (res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                // setMessage(res?.data?.message ? res.data.message : "Set to default")
                // setMessageType('success')
                getHiddenVisibleListService()
            } else {
                setLoading(false)
                setMessage(res?.message ? res.message : 'Something went wrong');
                setMessageType('error')
            }
        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')
            if (res?.message === "Network Error") {
                navigate('/error/network')
                setLoading(false)
            }
        }
    }

    const defaultServices = async () => {
        if (visibleData && visibleData.id) {
            setDefaultItemService(visibleData.id)
        }
    }

    const updateOrder = async (direction) => {
        updateHiddenVisibleOrder(direction)

        if(direction === "UP"){
            setSelectedVisibleIndex(selectedVisibleIndex - 1)
        } else {
            setSelectedVisibleIndex(selectedVisibleIndex + 1)
        }
    }

    const handleFormSubmit = () => {

        var formDataValues = {
            'profileId': profileId,
            'id': selectItemDetails?.id,
        }

        updateItemDetailsServices(formDataValues, "Profile")
    }

    return (
        <Form className='h-100 d-flex flex-column flex-grow-1 justify-content-between'>
            <div className='inside_scroll has_tab has_head'>
                <Row>
                    <Col xxl="12">
                        <Row>
                            <Col lg="6">
                                <FormGroup className="form-group">
                                    <Select
                                        labelId="profileSelect"
                                        id="profileSelect"
                                        value={profileId || ''}
                                        label="profileSelect"
                                        onChange={(e) => { setProfileId(e.target.value) }}
                                        input={<OutlinedInput className='w-100' name='profileSelect' />}

                                        MenuProps={MenuProps}

                                        IconComponent={() => (
                                            <MdOutlineKeyboardArrowDown size={20} className='me-1 MuiSvgIcon-root MuiSelect-icon' />
                                        )}
                                    >
                                        {profileOptions && profileOptions.map((o, index) => (
                                            <MenuItem key={index} value={o?.id}>
                                                {o?.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormGroup>
                            </Col>

                            <Col lg="12" className='mb-5'>
                                <DualListHideShowSystems isSelf={isEditable} visibleItems={visibleItems} hiddenItems={hiddenItems} moveToRight={addExternalProductService} moveToLeft={dualListItemMoveToLeftServices} selectedHiddenIndex={selectedHiddenIndex} selectedVisibleIndex={selectedVisibleIndex} setSelectedVisibleIndex={setSelectedVisibleIndex} setSelectedHiddenIndex={setSelectedHiddenIndex} setVisibleData={setVisibleData} visibleData={visibleData} setDefaultItem={defaultServices} updateOrder={updateOrder} />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>

            {/* <div className='d-flex gap-2 gap-xl-3 main_buttons'>
                <Button
                    color="primary"
                    size="md"
                    className='px-4'
                    onClick={() => handleFormSubmit()}
                    disabled={!timestamp || !itemId}
                >
                    Save Changes
                </Button>

                <Button
                    color="primary"
                    size="md"
                    outline
                    className='px-4'
                    onClick={() => handleFormReset()}
                    disabled={!timestamp}
                >
                    Reset
                </Button>
            </div> */}
        </Form>
    )
}

export default SystemSashProfile