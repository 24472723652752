import React, { useEffect } from 'react'
import { useState } from 'react';
import Slider from 'react-rangeslider';
import { useProductPaletteContext } from '../../context/productPaletteContext';
import {
    Row,
    Col,
    Button,
    Label,
    FormGroup,
    Input,
} from 'reactstrap';
import { Form } from 'react-bootstrap';
import { MenuItem, OutlinedInput, Select } from 'material-ui-core';
import { MdOutlineKeyboardArrowDown } from 'react-icons/md';
import { MenuProps, glazignSpecifcationSide } from '../../utility/data';
import NumberInput from '../NumberInput';
import { addUpdateGlazingPalette, uniqueGlazingPalette } from '../../services/glazingServices';
import { useMessage } from '../../context/messageContext';
import { useNavigate } from 'react-router-dom';
import { allHardwareCollection } from '../../services/productHardwareServices';
import NumberInputDecimal from '../NumberInputDecimal';
import { convertCanvasToImageFile } from '../../utility/helper';
import { getGlazingpaletteInUsed, removeGlazingpaletteInUsed } from '../../services/commonServices';
import UsedInProducts from '../UsedInProducts';
import { useRightContext } from '../../context/rightMenuContext';


const SpecificationPalette = ({ setCommonHeading, setLoading, allPalette, tabType, getAllPaletteServices, allColours, setUsedProductsFromPalette }) => {

    const accessToken = localStorage.getItem('access_token');

    const { handleEdit, newItemList, timestamp, setTimestamp, setActiveIndex, activeIndex, handleDescEdit, handleModificationChange } = useProductPaletteContext();
    const { setMessage, setMessageType } = useMessage()

    const { setSelectedRightItem, selectedRightItem } = useRightContext()

    const [thickness, setThickness] = useState(0)
    const [panes, setPanes] = useState(0)
    const [textureSide, setTextureSide] = useState(0)
    const [maxWidth, setMaxWidth] = useState(0)
    const [maxHeight, setMaxHeight] = useState(0)
    const [maxMSquare, setMaxMSquare] = useState(0)
    const [weight, setWeight] = useState(0)
    const [name, setName] = useState('')
    const [desc, setDesc] = useState('')
    const [specification, setSpecification] = useState('')
    const [colour, setColour] = useState("")
    const [collection, setCollection] = useState('')
    const [allCollection, setAllCollection] = useState([])
    const [id, setId] = useState()
    const [sideId, setSideId] = useState('')
    const [usedProducts, setUsedProducts] = useState([])

    const navigate = useNavigate()

    const firstEl = allPalette[0];

    useEffect(() => {
        setActiveIndex(0)
    }, [])

    useEffect(() => {
        if (firstEl && tabType === 'palette') {
            if (allPalette?.length && activeIndex !== 0 && tabType === 'palette') {
                setTimestamp(timestamp)
            } else {
                setTimestamp(firstEl?.id)
            }
        }
    }, [allPalette, timestamp])

    useEffect(() => {
        if (newItemList?.length === 0) {
            setTimestamp('')
            setThickness('')
            setPanes('')
            setTextureSide('')
            setMaxWidth('')
            setMaxHeight('')
            setMaxMSquare('')
            setWeight('')
            setName('')
            setDesc('')
            setSpecification('')
            setColour('')
            setCollection('')
        }
    }, [newItemList, tabType])

    useEffect(() => {
        if (timestamp && tabType === 'palette') {
            getUniquePaletteServices()
        }
    }, [timestamp, tabType])

    useEffect(() => {
        getAllCollectionServices()
    }, [])
    useEffect(() => {
        if (timestamp) {
            getGlazingpaletteInUsedService()
        };
    }, [timestamp]);

    useEffect(() => {
        setUsedProductsFromPalette(usedProducts);

    }, [usedProducts])

    const handleNameChange = (e) => {
        const value = e.target.value;
        if (e.target.value?.length <= 50) {
            setName(value)
            handleEdit(value)
            setCommonHeading(value)
        }
    }

    const reset = () => {
        getUniquePaletteServices()
        getAllPaletteServices()
        setColour("")
        setCollection("")
    }

    const getUniquePaletteServices = async () => {
        setLoading(true)
        const res = await uniqueGlazingPalette(timestamp, accessToken)
        if (res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                // console.log(res.data, "81");
                const selectedRight = allPalette?.find((p) => p.id == timestamp)
                setSelectedRightItem(selectedRight)
                setName(res?.data.entity?.name)
                setCommonHeading(res?.data.entity?.name)
                setDesc(res?.data.entity?.description ? res?.data.entity?.description : "")
                setSpecification(res?.data.entity?.specification ? res?.data.entity?.specification : "")
                setColour(res?.data.entity?.colourId ? res?.data.entity?.colourId : "")
                setThickness(res?.data.entity?.textureSide ? res?.data.entity?.textureSide : "")
                setPanes(res?.data.entity?.noOfPanes ? res?.data.entity?.noOfPanes : "")
                setSideId(res?.data?.entity?.side)
                setTextureSide(res?.data.entity?.textureSide ? res?.data.entity?.textureSide : "")
                setMaxWidth(res?.data.entity?.maximumWidth ? res?.data.entity?.maximumWidth : "")
                setMaxHeight(res?.data.entity?.maximumHeight ? res?.data.entity?.maximumHeight : "")
                setMaxMSquare(res?.data.entity?.maximumsquare ? res?.data.entity?.maximumsquare : "")
                setWeight(res?.data.entity?.weight ? res?.data.entity?.weight : "")
                setLoading(false)
                setTimestamp(timestamp)
                setId(res?.data.entity?.id)
            } else {
                setLoading(false)
                setMessage(res?.data?.message ? res.data.message : 'Something went wrong');
                setMessageType('error')
            }
        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')
            if (res?.message === "Network Error") {
                navigate('/error/network')
                setLoading(false)
            }

        }
    }

    const getAllCollectionServices = async () => {
        const res = await allHardwareCollection(accessToken)
        if (res?.data?.statusCode === 200) {
            setAllCollection(res?.data?.entity)
            setMessageType('success')
            setMessage(res.message)
        } else {
            setMessageType('error')
            setMessage(res.message)
        }

    }

    const handleSideId = (e) => {
        const value = e.target.value
        setSideId(typeof value === 'string' ? value.split(',') : value)
    }

    const handleColour = (e) => {
        const value = e.target.value
        setColour(typeof value === 'string' ? value.split(',') : value)
        const selectedColor = allColours.find((item) => item?.id === value);
        handleModificationChange("hex", selectedColor?.hexValue);
    }

    const handleCollection = (e) => {
        const value = e.target.value
        setCollection(typeof value === 'string' ? value.split(',') : value)
    }

    const handleDescChange = (e) => {
        const value = e.target.value
        if (e.target.value?.length <= 80) {
            setDesc(value)
            handleDescEdit(value)
        }
    }

    const updateGlazingPaletteServices = async () => {
        const capturedFile = convertCanvasToImageFile();

        setLoading(true)

        const data = {
            id: timestamp,
            name: name,
            desc: desc,
            specification: specification,
            colourId: colour,
            thickness: thickness,
            noOfPanes: panes,
            textureSide: textureSide,
            side: sideId,
            maximumWidth: maxWidth,
            maximumHeight: maxHeight,
            maximumsquare: maxMSquare,
            weight: weight,
            imageId: "",
            typeId: 1,
            hardwareCollectionId: collection,
            sequenceNo: "",
            CapturedFile: capturedFile,
            CapturedImage: ""
        }

        const res = await addUpdateGlazingPalette(data, accessToken)
        if (res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                setMessageType('success')
                setMessage(res?.data?.message ? res.data.message : 'Added new colour')
                getAllPaletteServices()
                getUniquePaletteServices()
                setLoading(false)
            } else {
                setMessage(res?.data?.message ? res.data.message : 'Something went wrong')
                setMessageType('error')
                getAllPaletteServices()
                getUniquePaletteServices()
                setLoading(false)

            }
        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')
            getAllPaletteServices()
            if (res?.message === "Network Error") {
                navigate('/error/network')
            }
        }
    }
    const getGlazingpaletteInUsedService = async () => {
        setLoading(true)

        const res = await getGlazingpaletteInUsed(accessToken, timestamp)

        if (res && res && res.status === 200) {
            if (res?.data?.statusCode === 200) {

                if (res?.data.entity.result !== null) {
                    setUsedProducts(res?.data.entity.result)

                } else {
                    setUsedProducts([])
                }

                setLoading(false)
            } else {
                setLoading(false)
                setMessage(res?.data?.message ? res.data.message : 'Something went wrong');
                setMessageType('error')
            }
        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')

            if (res?.message === "Network Error") {
                navigate('/error/network')
            }
        }
    }
    const removeGlazingpaletteInUsedService = async (itemId, productType) => {

        const res = await removeGlazingpaletteInUsed(accessToken, itemId, productType)

        if (res && res.status === 200) {
            debugger;
            if (res?.data?.statusCode === 200) {
                setMessage(res?.data?.message ? res?.data?.message : 'Invalid data');
                setMessageType('success')
                getGlazingpaletteInUsedService()

            } else {
                setMessage(res?.data?.message ? res.data.message : 'Something went wrong');
                setMessageType('error')
                getGlazingpaletteInUsedService()
            }
        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')
            if (res?.message === "Network Error") {
                navigate('/error/network')
                setLoading(false)
            }
            getGlazingpaletteInUsedService()
        }
    }
    return (
        <>
            <Form className='h-100 d-flex flex-column flex-grow-1 justify-content-between'>
                <div className='inside_scroll has_btns has_head'>
                    <Row>
                        <Col lg="6">
                            <FormGroup className="form-group">
                                <Label for="name">
                                    Name
                                </Label>
                                <Input disabled={!selectedRightItem?.isSelf} className='mb-1' name='name' value={name} onChange={(e) => handleNameChange(e)} />
                                {name && name?.length > 49 && (
                                    <div className='mt-1 text-muted text-small'>Max 50 characters are allowed</div>
                                )}

                                {(!name && id) && (
                                    <div className='mt-1 text-danger text-small'>Name is required</div>
                                )}
                            </FormGroup>
                        </Col>
                        <Col lg="6" >
                            <FormGroup className="form-group">
                                <Label for="description">
                                    Description
                                </Label>
                                <Input disabled={!selectedRightItem?.isSelf} className='mb-1' name='description' value={desc} onChange={(e) => handleDescChange(e)} />
                                {desc && desc?.length > 79 && (
                                    <div className='mt-1 text-muted text-small'>Max 80 characters are allowed</div>
                                )}
                            </FormGroup>
                        </Col>
                        <Col lg="6" >
                            <FormGroup className="form-group">
                                <Label for="description">
                                    Specification
                                </Label>
                                <Input disabled={!selectedRightItem?.isSelf} className='mb-1' name='specification' value={specification} onChange={(e) => setSpecification(e.target.value)} />

                            </FormGroup>
                        </Col>
                        <Col lg="6">
                            <FormGroup className="form-group">
                                <Label for="colour">
                                    Colour
                                </Label>
                                <Select
                                    labelId="printdoc-label"
                                    id="colour"
                                    value={colour}
                                    label="colour"
                                    disabled={!selectedRightItem?.isSelf}
                                    input={<OutlinedInput className='w-100' name='colour' />}
                                    onChange={handleColour}
                                    MenuProps={MenuProps}
                                    IconComponent={() => (
                                        <MdOutlineKeyboardArrowDown size={20} className='me-1 MuiSvgIcon-root MuiSelect-icon' />
                                    )}
                                >
                                    {allColours.map((item, index) => {
                                        return (
                                            <MenuItem value={item?.id} key={index} >{item?.name}</MenuItem>

                                        )
                                    })}
                                </Select>
                            </FormGroup>
                        </Col>
                        <Col lg="6">
                            <FormGroup className="form-group">
                                <Label for="thickness">Thickness</Label>

                                <NumberInput
                                    name="thickness"
                                    disabled={!selectedRightItem?.isSelf}
                                    value={thickness}
                                    setValue={setThickness}
                                />
                            </FormGroup>
                        </Col>
                        <Col lg="6">
                            <FormGroup className="form-group">
                                <Label for="numberofpanes">Number of panes</Label>

                                <NumberInput
                                    name="numberofpanes"
                                    value={panes}
                                    disabled={!selectedRightItem?.isSelf}
                                    setValue={setPanes}
                                />
                            </FormGroup>
                        </Col>
                        <Col lg="6">
                            <FormGroup className="form-group">
                                <Label for="textureside">Texture side</Label>
                                <Select
                                    labelId="printdoc-label"
                                    id="color"
                                    value={sideId}
                                    label="color"
                                    disabled={!selectedRightItem?.isSelf}
                                    onChange={handleSideId}
                                    input={<OutlinedInput className='w-100' name='colours' />}

                                    MenuProps={MenuProps}
                                    IconComponent={() => (
                                        <MdOutlineKeyboardArrowDown size={20} className='me-1 MuiSvgIcon-root MuiSelect-icon' />
                                    )}
                                >
                                    {glazignSpecifcationSide?.map((item, index) => {
                                        return (
                                            <MenuItem value={item?.id} key={index} >{item?.name}</MenuItem>

                                        )
                                    })}
                                </Select>
                            </FormGroup>
                        </Col>
                        {/* <Col lg="6">
                            <FormGroup className="form-group">
                                <Label for="textureside">Texture side</Label>

                                <NumberInput
                                    name="textureside"
                                    value={textureSide}
                                    setValue={setTextureSide}
                                />
                            </FormGroup>
                        </Col> */}
                        <Col lg="6">
                            <FormGroup className="form-group">
                                <Label for="maximumwidth">Maximum width</Label>

                                <NumberInput
                                    name="maximumwidth"
                                    value={maxWidth}
                                    disabled={!selectedRightItem?.isSelf}
                                    setValue={setMaxWidth}
                                />
                            </FormGroup>
                        </Col>
                        <Col lg="6">
                            <FormGroup className="form-group">
                                <Label for="maximumheight">Maximum height</Label>

                                <NumberInput
                                    name="maximumheight"
                                    disabled={!selectedRightItem?.isSelf}
                                    value={maxHeight}
                                    setValue={setMaxHeight}
                                />
                            </FormGroup>
                        </Col>

                        <Col lg="6">
                            <FormGroup className="form-group">
                                <Label for="weight">Weight</Label>

                                <NumberInputDecimal
                                    name="weight"
                                    value={weight}
                                    disabled={!selectedRightItem?.isSelf}
                                    setValue={setWeight}
                                />
                            </FormGroup>
                        </Col>

                        <Col lg="6">
                            <FormGroup className="form-group">
                                <Label for="maximumm2">Maximum m&sup2;</Label>

                                <NumberInputDecimal
                                    name="maximumm2"
                                    value={maxMSquare}
                                    disabled={!selectedRightItem?.isSelf}
                                    setValue={setMaxMSquare}
                                />
                            </FormGroup>
                        </Col>

                        <Col lg="12">
                            <div className='seperator mt-1 mb-4'></div>

                            <h5 className="mb-4 text-wrap text-break me-4 main_title">Hardware</h5>
                        </Col>

                        <Col lg="6">
                            <FormGroup className="form-group">
                                <Label for="collection">
                                    Collection
                                </Label>
                                <Select
                                    labelId="printdoc-label"
                                    id="collection"
                                    value={collection}
                                    label="collection"
                                    disabled={!selectedRightItem?.isSelf}
                                    onChange={handleCollection}
                                    input={<OutlinedInput className='w-100' name='collection' />}

                                    MenuProps={MenuProps}
                                    IconComponent={() => (
                                        <MdOutlineKeyboardArrowDown size={20} className='me-1 MuiSvgIcon-root MuiSelect-icon' />
                                    )}
                                >
                                    {allCollection?.map((item, index) => {
                                        return (
                                            <MenuItem value={item?.id} key={index} >{item?.name}</MenuItem>

                                        )
                                    })}
                                </Select>
                            </FormGroup>
                        </Col>
                        <Col sm="12" className='mt-5'>
                            <div className={`${!selectedRightItem?.isSelf ? "not_allowed" : ""}`}>
                                <h4 className="main_title sub_title">Used in products</h4>

                                <UsedInProducts data={usedProducts} handleRemove={removeGlazingpaletteInUsedService} />
                            </div>
                        </Col>
                    </Row>
                </div>
                {selectedRightItem?.isSelf && <div className='d-flex gap-2 gap-xl-3 main_buttons mt-4'>
                    <Button
                        color="primary"
                        size="md"
                        className='px-4'
                        onClick={() => updateGlazingPaletteServices()}
                        disabled={allPalette?.length === 0 || desc?.length > 79 || name?.length > 49 || !name}
                    >
                        Save Changes
                    </Button>

                    <Button
                        color="primary"
                        size="md"
                        outline
                        className='px-4'
                        onClick={() => reset()}
                        disabled={allPalette?.length === 0 || desc?.length > 79 || name?.length > 49 || !name}
                    >
                        Reset
                    </Button>
                </div>}
            </Form>
        </>
    )
}

export default SpecificationPalette