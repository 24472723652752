import React, { useEffect, useRef, useState } from 'react';
import DocFrameLayout from './DocFrameLayout';
import DocHeader from './DocHeader';
import DocSummary from './DocSummary';
import { NavLink } from 'reactstrap';
import { Oval } from 'react-loader-spinner';

const BasicDocument = ({ docsData, printableRef }) => {

    const iframeRef = useRef();

    const [pageWidthMain, setPageWidthMain] = useState('')
    const [pageWidth, setPageWidth] = useState('794px')
    const [pageHeight, setPageHeight] = useState('1123px')

    const [pdfData, setPdfData] = useState({
        "addressLine1": '',
        "addressLine2": '',
        "email": '',
        "fontSize": 14,
        "logoImagePath": '',
        "phone": '',
        "vat": '',
        "website": '',
    })

    const [forewordNew, setForewordNew] = useState(false)
    const [foreword, setForeword] = useState('')
    const [frame, setFrame] = useState({
        "nameFontSize": '18',
        "fontSize": '14'
    })

    const [currency, setCurrency] = useState('')
    const [general, setGeneral] = useState('')
    const [header, setHeader] = useState('')
    const [manufacturing, setManufacturing] = useState('')
    const [summary, setSummary] = useState('')
    const [summaryPrice, setSummaryPrice] = useState('')
    const [term, setTerm] = useState('')
    const [frameSpecificData, setFrameSpecificData] = useState('')
    // const [divDimensions, setDivDimensions] = useState({ width: 0, height: 0 });
    const [totalPages, setTotalPages] = useState(0)
    const [pageNum, setPageNum] = useState(2)
    // console.log("dimm--->", divDimensions);
    var generalCss = {
        displayNone: {
            display: 'none',
        },
        black: {
            color: '#000000',
        },
        mb0: {
            marginBottom: 0,
        },
        mb1: {
            marginBottom: ".25rem",
        },
        mb2: {
            marginBottom: ".5rem",
        },
        mb3: {
            marginBottom: "1rem",
        },
        mb4: {
            marginBottom: "1.5rem",
        },
        mb5: {
            marginBottom: "3rem",
        },
        pb0: {
            paddingBottom: 0,
        },
        px0: {
            paddingLeft: 0,
            paddingRight: 0,
        },
        py0: {
            paddingTop: 0,
            paddingBottom: 0,
        },
        pb4: {
            paddingBottom: "30px",
        },
        fw500: {
            fontWeight: 500,
        },
        flexCol: {
            display: 'flex',
            flexDirection: "column",
        },
        preLine: {
            whiteSpace: 'pre-line',
        },
        breakBefore: {
            pageBreakBefore: "always"
        },
        mw180: {
            minWidth: '180px',
            maxWidth: '180px',
            width: "180px",
        },
        mw100: {
            minWidth: '100px',
            maxWidth: '100px',
            width: "100px",
        },

        mw200: {
            minWidth: '200px',
        },
        w100: {
            width: "100%"
        },
        textCenter: {
            textAlign: "center"
        },
        heightUnset: {
            height: 'unset',
        },
        maxwauto: {
            maxWidth: 'auto',
        },
        breakInside: {
            breakInside: 'avoid',
        },
        svgTop: {
            position: 'absolute',
            top: '-8%',
            left: 0,
            right: 0,
            fontSize: frame?.size > 70 ? 12 : 14,
        },
        svgLeft: {
            position: 'absolute',
            top: 0,
            left: '-8%',
            right: 0,
            fontSize: frame?.size > 70 ? 12 : 14,
        },
        summaryGap: {
            display: 'flex',
            gap: '20px',
            flexDirection: 'column',
        },
        capitalize: {
            textTransform: "capitalize"
        }
    }

    var stylesHeader = {
        mxAuto: {
            margin: "auto",
        },

        page: {
            fontFamily: "'Poppins', sans-serif",
            display: "block",
            width: "100%",
            color: "#000000",
            WebkitPrintColorAdjust: "exact",
            fontSize: 14,
            lineHeight: 1.5,
            fontWeight: 400,

            breakAfter: {
                pageBreakAfter: "always"
            },

            a4p: {
                width: '794px',
                height: '1123px',
            },
            a4l: {
                width: '1123px',
                height: '892.913px',
            },
            a3p: {
                width: '1123px',
                height: '1587px',
            },
            a3l: {
                width: '1587px',
                height: '1123px',
            },

            pageLayout: {
                marginBottom: 20,
                backgroundColor: "#ffffff",
                boxShadow: 'rgba(0, 0, 0, 0.133) 0px 6.4px 14.4px 0px, rgba(0, 0, 0, 0.11) 0px 1.2px 3.6px 0px',
                // width: pageWidth,
                height: pageHeight,
                paddingTop: general?.margin,
                paddingBottom: general?.margin,
            },

            pageLayoutForewordsFrame: {
                marginBottom: 20,
                // backgroundColor: "#ffffff",
                // boxShadow: 'rgba(0, 0, 0, 0.133) 0px 6.4px 14.4px 0px, rgba(0, 0, 0, 0.11) 0px 1.2px 3.6px 0px',
                // width: pageWidth,
                height: pageHeight,
                paddingTop: general?.margin,
                paddingBottom: general?.margin,
            },

            pageLayoutFrameSummary: {
                // backgroundColor: '#ffffff',
                // boxShadow: (frame?.startNewPageForEachFrame || summary?.startNewPageBeforeSummary) ? 'rgba(0, 0, 0, 0.133) 0px 6.4px 14.4px 0px, rgba(0, 0, 0, 0.11) 0px 1.2px 3.6px 0px' : '',
                // width: frame?.startNewPageForEachFrame ? pageWidth : pageWidth,
                height: frame?.startNewPageForEachFrame ? pageHeight : '',
                paddingTop: (frame?.startNewPageForEachFrame || forewordNew) ? general?.margin : 10,
                paddingBottom: (frame?.startNewPageForEachFrame || forewordNew) ? general?.margin : 10,
                marginBottom: summary?.startNewPageBeforeSummary ? 20 : 0,
            },

            pageLayoutFrame: {
                // backgroundColor: '#ffffff',
                // boxShadow: (frame?.startNewPageForEachFrame || forewordNew) ? 'rgba(0, 0, 0, 0.133) 0px 6.4px 14.4px 0px, rgba(0, 0, 0, 0.11) 0px 1.2px 3.6px 0px' : '',
                // width: (frame?.startNewPageForEachFrame || forewordNew) ? pageWidth : pageWidth,
                height: (frame?.startNewPageForEachFrame || forewordNew) ? pageHeight : '',
                paddingTop: (frame?.startNewPageForEachFrame || forewordNew) ? general?.margin : 10,
                paddingBottom: (frame?.startNewPageForEachFrame || forewordNew) ? general?.margin : 10,
                marginBottom: summary?.startNewPageBeforeSummary ? 20 : 0,
            },

            pageLayoutSummary: {
                // backgroundColor: '#ffffff',
                // boxShadow: summary?.startNewPageBeforeSummary ? 'rgba(0, 0, 0, 0.133) 0px 6.4px 14.4px 0px, rgba(0, 0, 0, 0.11) 0px 1.2px 3.6px 0px' : '',
                // width: summary?.startNewPageBeforeSummary ? pageWidth : pageWidth,
                height: summary?.startNewPageBeforeSummary ? pageHeight : '',
                paddingTop: summary?.startNewPageBeforeSummary ? general?.margin : 0,
                paddingBottom: summary?.startNewPageBeforeSummary ? general?.margin : 10,
                // marginBottom: summary?.startNewPageBeforeSummary ? 20 : 0,
            },

            pageDimention: {
                width: pageWidthMain,
                // height: pageHeight,
            }
        },

        section: {
            paddingLeft: general?.margin || 20,
            paddingRight: general?.margin || 20,
        },

        pdfHeader: {
            display: "flex",
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: "flex-start",
            paddingBottom: 20,
        },
        heading: {
            fontSize: 20,
            fontWeight: 600,
        },
        logo: {
            maxWidth: 250,
            maxHeight: 100,
            display: "flex",
            flexDirection: 'row',
            marginBottom: "1rem"
        },
        logoImg: {
            maxWidth: "100%",
            objectFit: "contain",
            objectPosition: "left"
        },
        fullWidth: {
            width: "100%",
        },
        maxFullWidth: {
            maxWidth: "100%",
        },
        head_item_span: {
            lineHeight: 1.5,
            display: "block",
            wordWrap: "break-word",
            wordWreak: "break-word",
        },
        head_icon: {
            marginRight: ".5rem",
            fontSize: `${pdfData?.fontSize ? (parseInt(pdfData?.fontSize) + 4) : '18'}`
        },
        page_setup_text: {
            display: "flex",
            flexDirection: 'row',
            marginBottom: ".5rem",
            maxWidth: 300,
            whiteSpace: "break-spaces",
            wordBreak: 'break-word',
            fontSize: pdfData?.fontSize || 14,
        },
        rightTitle: {
            fontSize: header?.titleFontSize || 18,
            lineHeight: 1.5,
            margin: "0 0 10px 0",
            fontWeight: 500,
            color: "#000000",
            maxWidth: 460,
            wordWrap: 'break-word',
        },
        tableRight: {
            marginLeft: "auto",
            marginBottom: 10,
            color: "#000000",
            td: {
                fontSize: 12,
                whiteSpace: 'normal'
            },
            right: {
                textAlign: "right",
                paddingLeft: 10,
                fontSize: 12,
            }
        },
        rightBoxWrap: {
            marginBottom: 6,
        },
        rightBox: {
            padding: 10,
            backgroundColor: "#efefef",
            height: "100%",
            // marginBottom: 6,

            headText: {
                margin: 0,
                fontSize: header?.projectInfoFontSize || 14,
                color: "#000000",
                marginBottom: 0,
            },

            text: {
                margin: 0,
                fontSize: frame?.panelTitleFontSize || 14,
                lineHeight: 1,
                fontWeight: 500,
                marginBottom: 10,
                color: "#000000",
            }
        },
        table: {
            width: "100%",
            margin: 0,

            td: {
                padding: '5px 4px',
                color: '#000000',
                borderBottom: '1px solid #989898',
                whiteSpace: 'normal',
                fontSize: 14,
            },

            borderNone: {
                borderBottom: 'none',
            },

            gridLineBottom: {
                paddingTop: frame?.topBottomPadding ? `${frame.topBottomPadding}px` : '6px',
                paddingBottom: frame?.topBottomPadding ? `${frame.topBottomPadding}px` : '6px',
                paddingLeft: frame?.leftRightPadding ? `${frame.leftRightPadding}px` : '5px',
                paddingRight: frame?.leftRightPadding ? `${frame.leftRightPadding}px` : '5px',
                borderBottom: frame.gridLineHorizontal ? '1px solid #989898' : 'none',
                fontSize: frame.propertyFontSize || '12px',
            },

            gridLineLeft: {
                borderLeft: frame.gridLineVertical ? '1px solid #989898' : 'none',
            },

            textEnd: {
                textAlign: 'end',
            }
        }
    };

    var frameGridView = {

        frameNewPage: {
            pageBreakBefore: frame?.startNewPageForEachFrame ? "always" : '',
            breakInside: 'avoid',
            marginBottom: "30px",
            // padding: frame?.startNewPageForEachFrame ? general?.margin : '',
            // padding: general?.margin || 20,
        },

        mainFrame: {
            display: "flex",
            flexDirection: 'row',
            justifyContent: "center",
            flexWrap: "wrap",
            width: "auto",
            margin: "auto",
            gap: 20,
            paddingTop: frame?.frameDimensions ? 60 : 30,
            overflow: 'hideen',

            title: {
                marginBottom: 20,

                head: {
                    fontSize: frame?.nameFontSize || 20,
                    lineHeight: 1,
                    margin: 0,
                    color: "#000000",
                },
                sub: {
                    fontSize: 12,
                }
            }
        },

        viewName: {
            position: 'absolute',
            top: '-10px',
            left: '20px',
            fontWeight: 600,
            fontSize: "16px",
        },

        mainMultiWrap: {
            width: `${(frame?.size * 2)}%` || "40%",
            maxWidth: '100%',
            display: "flex",
            gap: "16px",
        },

        mainImageWrap: {
            width: `${frame?.size}%` || "40%",
            maxWidth: '100%',
            display: "flex",
            flexDirection: 'column',
            justifyContent: "center",
            alignItems: 'center',
            pointerEvents: 'none',
            marginBottom: frame?.transomDimensions ? 60 : 20,
            position: 'relative'
            // overflowY: "hidden",
        },

        externalView: {
            position: 'relative'
        },

        mainImageFrame: {
            width: '100%',
            maxWidth: "100%",
            objectFit: "contain",
            mixBlendMode: 'multiply',
            // marginTop: "-10%",
            // marginBottom: '-10%',
            // transform: 'scale(1.4)',
        },

        mainImage: {
            width: '100%',
            maxWidth: "100%",
            objectFit: "contain",
            mixBlendMode: 'multiply',
            maxHeight: '80px',
        },

        mainFrameLeft: {
            icons: {
                flex: 1,
                minWidth: frame?.minimumWidth || 420,
                maxWidth: frame?.minimumWidth || 420,
                breakInside: 'avoid',
                zIndex: 2,
            },

            grid: {
                // flex: 1,
                maxWidth: "100%",
                breakInside: 'avoid',
                zIndex: 2,
            },

            inner: {
                box: {
                    // width: "100%",
                    minWidth: '45%',
                    // maxWidth: '160px',
                    display: "flex",
                    flexDirection: 'row',
                    alignItems: "center",
                    gap: 6,
                    marginBottom: 8,
                    // flex: 1,

                    lg: {
                        wrap: {
                            width: frame?.propertyIconSize + 20 || 30,
                            height: 'auto',
                            // height: frame?.propertyIconSize || 30,
                            minWidth: frame?.propertyIconSize + 20 || 30,
                            display: "flex",
                            flexDirection: 'row',
                            justifyContent: "center",
                            alignItems: "center",
                            // margin: "-10px 12px -10px -15px"
                        }
                    },
                    sm: {
                        wrap: {
                            width: frame?.propertyIconSize || 30,
                            height: 'auto',
                            // height: frame?.propertyIconSize || 30,
                            minWidth: frame?.propertyIconSize || 30,
                            display: "flex",
                            flexDirection: 'row',
                            justifyContent: "center",
                            alignItems: "center",
                            // margin: "-10px 12px -10px -15px"
                        }
                    },

                    title: {
                        head: {
                            fontSize: frame?.propertyFontSize || 12,
                            marginBottom: 0,
                            fontWeight: 500,
                            color: "#000000",
                            whiteSpace: "break-spaces",
                            wordWrap: 'break-word',
                        },
                        sub: {
                            fontSize: frame?.propertyFontSize - 2 || 11,
                            fontWeight: 500,
                            color: "#495057",
                        }
                    }
                }
            }
        },
    }

    useEffect(() => {

        if (docsData) {
            console.log(docsData, "docsData")

            setCurrency(docsData.currency)
            setPdfData(docsData.pageSetup)
            setForeword(docsData.foreword)
            setFrame(docsData.frame)
            setGeneral(docsData.general)
            setHeader(docsData.header)
            setManufacturing(docsData.manufacturing)
            setSummary(docsData.summary)
            setSummaryPrice(docsData?.price)
            setTerm(docsData.term)

            setFrameSpecificData(docsData.frameSpecificData)

            if (docsData.foreword && docsData.foreword.foreWord && docsData.foreword?.foreWord?.length > 0) {
                for (let i = 0; i < docsData.foreword.foreWord.length; i++) {
                    if (docsData.foreword.foreWord[i].newPage) {
                        setForewordNew(true)
                    }
                }
            } else {
                setForewordNew(false)
            }

            if (docsData.general?.paperSize === "A4" && docsData.general?.paperOrientation === "Portrait") {
                setPageWidth('820px')
                setPageHeight('1123px')
            }

            if (docsData.general?.paperSize === "A4" && docsData.general?.paperOrientation === "Landscape") {
                setPageWidth('1123px')
                setPageHeight('700px')
            }

            if (docsData.general?.paperSize === "A3" && docsData.general?.paperOrientation === "Portrait") {
                setPageWidth('1123px')
                setPageHeight('1587px')
            }

            if (docsData.general?.paperSize === "A3" && docsData.general?.paperOrientation === "Landscape") {
                setPageWidth('1587px')
                setPageHeight('1000px')
            }
        }
    }, [docsData])

    useEffect(() => {

        if (pageWidth) {
            var page_width_main = ''

            var str = pageWidth
            var margin = parseInt(general?.margin) * 2

            page_width_main = parseInt(str.substring(0, str?.length - 2)) + parseInt(margin)

            // console.log(page_width_main)
            setPageWidthMain(`${page_width_main}px`)
        }
    }, [pageWidth])

    // useEffect(() => {
    //     setTimeout(() => {
    //         if (printableRef) {
    //             const { offsetWidth, offsetHeight } = printableRef?.current;
    //             setDivDimensions({ width: offsetWidth, height: offsetHeight });
    //         }
    //     }, [2000])
    // }, [printableRef]);

    // useEffect(() => {
    //     if (divDimensions?.height && divDimensions?.height !== 0) {
    //         console.log("daa-->", Math.round(divDimensions?.height / 1123));

    //         setTotalPages(Math.round(divDimensions?.height / 1123))
    //     }
    // }, [divDimensions])

    useEffect(() => {
        if (docsData && printableRef) {
            setTimeout(() => {
                // calculatePages()

            }, [2000])
        }
    }, [docsData, printableRef])

    const calculatePages = () => {
        const iframe = iframeRef.current;
        const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
        const content = printableRef.current.innerHTML;

        iframeDoc.open();
        iframeDoc.write('<html><head><style>body { margin: 0; }</style></head><body>' + content + '</body></html>');
        iframeDoc.close();

        iframe.onload = () => {
            const totalHeight = iframeDoc.body.scrollHeight;
            const pageHeight = 1123;
            const totalPages = Math.ceil(totalHeight / pageHeight);
            setTotalPages(totalPages)
            // console.log(
            //     ('Total pages: ' + totalPages)

            // );
        };
    };

    // console.log("PAGE NUM IN BASIX", pageNum)

    return (
        <>

            {/* <div className='loader_main full_loader save_images'>
                <div className='d-flex align-items-center gap-3'>
                    <Oval
                        height={50}
                        width={50}
                        color="#44C8F5"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel='oval-loading'
                        secondaryColor="#44C8F5"
                        strokeWidth={2}
                        strokeWidthSecondary={2}
                    />

                    <span className='fs-5 text-muted fw-500'>Generating PDF</span>
                </div>
            </div> */}

            <div ref={printableRef}>
                {docsData && (
                    <div style={{ ...stylesHeader.mxAuto, ...stylesHeader.page.pageDimention }} className='page_wrap'>
                        <div style={stylesHeader.page}>

                            {/* Start: Table of Contents */}
                            {(frameSpecificData?.length >= general?.showTableOfContentsFrames) && (
                                <div style={{ ...stylesHeader.section, ...stylesHeader?.page?.breakAfter }} className="page_section">
                                    {header?.showHeader === "On first page only" && (
                                        <DocHeader
                                            stylesHeader={stylesHeader}
                                            generalCss={generalCss}
                                            pdfData={pdfData}
                                            header={header}
                                            itemCount={frameSpecificData && frameSpecificData?.length || 0}
                                            totalPages={totalPages}
                                            setPageNum={setPageNum}
                                            pageNum={1}
                                        />
                                    )}

                                    {header?.showHeader === "On every page" && (
                                        <DocHeader
                                            stylesHeader={stylesHeader}
                                            generalCss={generalCss}
                                            pdfData={pdfData}
                                            header={header}
                                            itemCount={frameSpecificData && frameSpecificData?.length || 0}
                                            pageNum={1}
                                            setPageNum={setPageNum}
                                            totalPages={totalPages}
                                        />
                                    )}

                                    <h5 style={generalCss.black}>Contents</h5>

                                    <table style={stylesHeader.table}>
                                        <tbody>
                                            {foreword.foreWord && foreword.foreWord.length > 0 && (
                                                <tr>
                                                    <td style={{ ...stylesHeader.table.td, ...generalCss.px0 }}>
                                                        <NavLink href='#foreword' style={{ ...generalCss.black, ...generalCss.px0 }}>
                                                            Foreword
                                                        </NavLink>
                                                    </td>
                                                </tr>
                                            )}

                                            {frameSpecificData && frameSpecificData?.length > 0 && frameSpecificData.map((o, index) => (
                                                <tr key={index}>
                                                    <td style={{ ...stylesHeader.table.td, ...generalCss.px0 }}>
                                                        <NavLink href={`#frame_${o?.id}`} style={{ ...generalCss.black, ...generalCss.px0 }}>
                                                            {o?.title}
                                                        </NavLink>
                                                    </td>
                                                    {/* <td style={{ ...stylesHeader.table.td, ...stylesHeader.table.textEnd }}>
                                                    {currency}{o?.price}
                                                </td> */}
                                                </tr>
                                            ))}

                                            {summary?.frames && summaryPrice?.framePrice && summaryPrice?.framePrice?.length > 0 && (
                                                <tr>
                                                    <td style={{ ...stylesHeader.table.td, ...generalCss.px0 }}>
                                                        <NavLink href='#summary_frame' style={{ ...generalCss.black, ...generalCss.px0 }}>
                                                            Summary - Frames
                                                        </NavLink>
                                                    </td>
                                                </tr>
                                            )}

                                            {summary?.installation && summaryPrice?.installationPrice && summaryPrice?.installationPrice?.length > 0 && (
                                                <tr>
                                                    <td style={{ ...stylesHeader.table.td, ...generalCss.px0 }}>
                                                        <NavLink href='#summary_installation' style={{ ...generalCss.black, ...generalCss.px0 }}>
                                                            Summary - Installation
                                                        </NavLink>
                                                    </td>
                                                </tr>
                                            )}

                                            {summary?.ancillaries && summaryPrice?.ancillariesPrice && summaryPrice?.ancillariesPrice?.length > 0 && (
                                                <tr>
                                                    <td style={{ ...stylesHeader.table.td, ...generalCss.px0 }}>
                                                        <NavLink href='#summary_ancillaries' style={{ ...generalCss.black, ...generalCss.px0 }}>
                                                            Summary - Ancillaries
                                                        </NavLink>
                                                    </td>
                                                </tr>
                                            )}

                                            {summary?.total && (
                                                <tr>
                                                    <td style={{ ...stylesHeader.table.td, ...generalCss.px0 }}>
                                                        <NavLink href='#summary_total' style={{ ...generalCss.black, ...generalCss.px0 }}>
                                                            Summary - Total
                                                        </NavLink>
                                                    </td>
                                                </tr>
                                            )}

                                            {term?.term && term?.term?.length > 0 && (
                                                <tr>
                                                    <td style={{ ...stylesHeader.table.td, ...generalCss.px0 }}>
                                                        <NavLink href='#terms' style={{ ...generalCss.black, ...generalCss.px0 }}>
                                                            Terms
                                                        </NavLink>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                            {/* End: Table of Contents */}

                            {/* Start: foreword */}
                            {foreword && foreword.foreWord && foreword.foreWord?.slice(0, 1).map((o, index) => (
                                <div key={index} className={o.newPage ? "full_page" : ''} style={(o.newPage || frame?.startNewPageForEachFrame) ? stylesHeader.page.pageLayoutFrame : generalCss.mb0}>
                                    <div style={stylesHeader.section} className="page_section">
                                        <div key={index} style={{ pageBreakAfter: `${o.newPage ? "always" : ''}` }} id='foreword'>

                                            {frameSpecificData?.length >= general?.showTableOfContentsFrames ? (
                                                <>
                                                    {header?.showHeader === "On every page" && (
                                                        <DocHeader
                                                            stylesHeader={stylesHeader}
                                                            generalCss={generalCss}
                                                            pdfData={pdfData}
                                                            header={header}
                                                            itemCount={frameSpecificData && frameSpecificData?.length || 0}
                                                            pageNum={frameSpecificData?.length >= general?.showTableOfContentsFrames ? 2 : 1}
                                                            totalPages={totalPages}
                                                            setPageNum={setPageNum}
                                                        />
                                                    )}
                                                </>
                                            ) : (
                                                <>
                                                    {header?.showHeader === "On first page only" && index == 0 && (
                                                        <DocHeader
                                                            stylesHeader={stylesHeader}
                                                            generalCss={generalCss}
                                                            pdfData={pdfData}
                                                            setPageNum={setPageNum}
                                                            header={header}
                                                            itemCount={frameSpecificData && frameSpecificData?.length || 0}
                                                            pageNum={frameSpecificData?.length >= general?.showTableOfContentsFrames ? 2 : 1}
                                                            totalPages={totalPages}
                                                        />
                                                    )}

                                                    {header?.showHeader === "On every page" && (o.newPage || index == 0) && (
                                                        <DocHeader
                                                            stylesHeader={stylesHeader}
                                                            generalCss={generalCss}
                                                            pdfData={pdfData}
                                                            pageNum={frameSpecificData?.length >= general?.showTableOfContentsFrames ? 2 : 1}
                                                            header={header}
                                                            itemCount={frameSpecificData && frameSpecificData?.length || 0}
                                                            setPageNum={setPageNum}
                                                            totalPages={totalPages}
                                                        />
                                                    )}
                                                </>
                                            )}

                                            <p style={generalCss.preLine}>
                                                {o.text}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            {/* End: foreword */}

                            {/* Start: frame related bindings */}
                            <div className='page_layout'>
                                {frameSpecificData && frameSpecificData?.length > 0 && frameSpecificData.map((item, index) => (
                                    <div key={index} style={{ ...frameGridView.frameNewPage }}>
                                        <div style={stylesHeader.section} className="page_section mb-4">
                                            {/* {foreword && foreword?.foreWord === null && header?.showHeader === "On first page only" && (
                                            <DocHeader
                                                stylesHeader={stylesHeader}
                                                generalCss={generalCss}
                                                pdfData={pdfData}
                                                header={header}
                                                itemCount={frameSpecificData && frameSpecificData?.length || 0}
                                            />
                                        )} */}

                                            {/* pdf header design */}
                                            {header?.showHeader === "On every page" && (frame && frame?.startNewPageForEachFrame) && (
                                                <DocHeader
                                                    stylesHeader={stylesHeader}
                                                    generalCss={generalCss}
                                                    pdfData={pdfData}
                                                    header={header}
                                                    itemCount={frameSpecificData && frameSpecificData?.length || 0}
                                                    totalPages={totalPages}
                                                    setPageNum={setPageNum}
                                                    pageNum={frameSpecificData?.length >= general?.showTableOfContentsFrames ? index + 3 : index + 2} />
                                            )}

                                            <DocFrameLayout
                                                stylesHeader={stylesHeader}
                                                frameGridView={frameGridView}
                                                frame={frame}
                                                generalCss={generalCss}
                                                item={item}
                                                header={header}
                                                pdfData={pdfData}
                                                currency={currency}
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>
                            {/* End: frame related bindings */}

                            {/* Start: summary related bindings */}
                            <div className='page_layout' style={generalCss.breakInside}>

                                <div style={stylesHeader.section} className="page_section">
                                    {/* summary layouts  */}
                                    <div style={{ pageBreakBefore: `${summary?.startNewPageBeforeSummary ? "always" : ''}` }}>

                                        {/* pdf header design */}
                                        {/* {header?.showHeader === "On every page" && ( */}
                                        {header?.showHeader === "On every page" && summary?.startNewPageBeforeSummary && (
                                            <DocHeader
                                                stylesHeader={stylesHeader}
                                                generalCss={generalCss}
                                                pdfData={pdfData}
                                                pageNum={pageNum + 1}
                                                header={header}
                                                itemCount={frameSpecificData && frameSpecificData?.length || 0}
                                                setPageNum={setPageNum}
                                                totalPages={totalPages}
                                            />
                                        )}

                                        {/* summary section */}
                                        <DocSummary
                                            stylesHeader={stylesHeader}
                                            generalCss={generalCss}
                                            summary={summary}
                                            summaryPrice={summaryPrice}
                                            currency={currency}
                                        />
                                    </div>
                                </div>
                            </div>
                            {/* End: summary related bindings */}

                            {/* Start: terms sections */}
                            <div className='page_layout' style={generalCss.breakInside}>
                                <div style={stylesHeader.section} className="page_section" id='terms'>
                                    {term && term.term && term?.term?.length > 0 && (
                                        <>
                                            {term.term.map((o, index) => (
                                                <div key={index} style={{ pageBreakBefore: `${o.newPage ? "always" : ''}` }}>

                                                    {header?.showHeader === "On every page" && o.newPage && (
                                                        <DocHeader
                                                            stylesHeader={stylesHeader}
                                                            generalCss={generalCss}
                                                            pdfData={pdfData}
                                                            header={header}
                                                            itemCount={frameSpecificData && frameSpecificData?.length || 0}
                                                            pageNum={pageNum + 1}
                                                            totalPages={totalPages}
                                                            setPageNum={setPageNum}
                                                        />
                                                    )}

                                                    <p style={generalCss.preLine}>
                                                        {o.text}
                                                    </p>
                                                </div>
                                            ))}
                                        </>
                                    )}
                                </div>
                            </div>
                            {/* End: terms sections */}

                        </div>
                    </div>
                )}
                {/* <iframe ref={iframeRef} style={{ position: 'absolute', width: '210mm', height: '297mm', left: '-9999px' }}></iframe>
            <style>
                {`
          @media print {
            iframe {
              display: none;
            }
          }
        `}
            </style> */}
            </div>
        </>
    );
}

export default BasicDocument;