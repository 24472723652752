import React, { useEffect, useState } from "react";
import { MdAdd, MdExpandMore } from "react-icons/md";
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  UncontrolledDropdown,
} from "reactstrap";
import NumberInputDecimal from "./NumberInputDecimal";
import { BiTrashAlt } from "react-icons/bi";
import { getRightMenuList } from "../services/pricing/promotionsService";
import { useMessage } from "../context/messageContext";
import {
  addUpdateQuotationPromotionService,
  deletePromotionsService,
} from "../services/pricingServices";

const PromotionComponent = ({
  accessToken,
  addedPromotions,
  setAddedPromotions,
  setTotalDiscount,
  quotationId,
  getFrameInstallationData,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [promotionData, setPromotionData] = useState([]);

  const { setMessage, setMessageType } = useMessage();

  // console.log("ADDEDPromotions-->",addedPromotions)

  useEffect(() => {
    getPromotionDataService();
  }, []);

  useEffect(() => {
    if (addedPromotions?.length > 0) {
      let total = 0;
      addedPromotions?.forEach((item) => {
        total += parseFloat(item?.default);
      });
      setTotalDiscount(total);
    }
  }, [addedPromotions]);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handlePromotions = (item) => {
    setAddedPromotions([...addedPromotions, item]);
    addUpdatePromotions(item, "add", "");
  };

  const handlePromotionDesc = (value, item) => {
    let numericValue = parseFloat(value);
    if (isNaN(numericValue) || value === "") {
      numericValue = 0;
    }
    const itemData = promotionData?.find(
      (ele) => ele?.id === item?.promotionId
    );
    const constrainedValue =
      numericValue > itemData.maximum ? itemData.maximum : numericValue;

    const newState = addedPromotions.map((ele) =>
      ele.id === item.id
        ? {
            ...ele,
            default: constrainedValue,
          }
        : ele
    );
    setAddedPromotions(newState);

    const promotion = addedPromotions?.find((ele) => ele?.id === item?.id);
    setTimeout(() => {
      addUpdatePromotions(promotion, "update", constrainedValue);
    }, [100]);
  };

  const getPromotionDataService = async () => {
    const res = await getRightMenuList(accessToken);
    if (res?.data?.statusCode === 200) {
      setPromotionData(res?.data?.entity);
    } else {
      setPromotionData([]);
    }
  };

  const deletePromotion = (idToRemove, indexToRemove) => {
    setAddedPromotions((prevData) => {
      const newData = prevData.filter(
        (item, index) => index !== indexToRemove || item.id !== idToRemove
      );
      return newData;
    });
    deletePromotions(idToRemove);
  };

  const addUpdatePromotions = async (item, type, value) => {
    const data = {
      id: type === "update" ? item.id : 0,
      promotionId: item.id,
      name: item.name,
      default: type === "update" ? value : item.default,
      quotationId: quotationId,
    };
    const res = await addUpdateQuotationPromotionService(accessToken, data);
    if (res?.data?.statusCode === 200) {
      // setMessageType("success");
      // setMessage(res?.data?.message);
      getFrameInstallationData();
    } else {
      setMessageType("error");
      setMessage(res?.data?.message);
    }
  };

  const deletePromotions = async (id) => {
    const res = await deletePromotionsService(accessToken, id);
    if (res?.data?.statusCode === 200) {
      setMessage(
        res?.data?.message
          ? "Deleted promotion successfully !"
          : res?.data?.message
      );
      setMessageType("success");
    } else {
      setMessageType("error");
      setMessage(res?.data?.message);
    }
  };

  // console.log("promotions", addedPromotions);

  return (
    <>
      <h5 className="mt-3">Promotions</h5>

      <FormGroup className="d-flex gap-2 my-3">
        <UncontrolledDropdown
          group
          className="add_ancillary_btn border br-4"
          isOpen={dropdownOpen}
          toggle={toggleDropdown}
        >
          <Button color="trans" onClick={toggleDropdown}>
            <MdAdd size={22} className="me-1" />
            Promotion
          </Button>

          <DropdownToggle
            color="trans"
            className="p-2"
            onClick={toggleDropdown}
          >
            <MdExpandMore className="border-right" size={22} />
          </DropdownToggle>

          <DropdownMenu>
            {promotionData &&
              promotionData.map((item, index) => (
                <DropdownItem
                  key={index}
                  onClick={() => {
                    handlePromotions(item);
                    setDropdownOpen(false);
                  }}
                >
                  {item.name}
                </DropdownItem>
              ))}
          </DropdownMenu>
        </UncontrolledDropdown>
      </FormGroup>

      {addedPromotions && addedPromotions?.length > 0 && (
        <>
          {addedPromotions?.map((item, index) => (
            <div
              className="mb-3 mt-2 d-flex align-items-center gap-2"
              key={index}
            >
              <span className="fw-bold me-2">{item?.name}</span>
              <NumberInputDecimal
                value={item?.default}
                setValue={(value) => handlePromotionDesc(value, item)}
              />
              <span
                className="cursor-pointer ms-2"
                onClick={() => {
                  deletePromotion(item.id, index);
                }}
              >
                <BiTrashAlt className="text-danger" size={20} />
              </span>
            </div>
          ))}
        </>
      )}
    </>
  );
};

export default PromotionComponent;
