import React, { useEffect, useState } from 'react'
import { FormControl, FormControlLabel, Radio, RadioGroup } from 'material-ui-core';
import { Form } from 'react-bootstrap';
import {
    Row,
    Col,
    Button,
    FormGroup,
    Label,
    Input,
    FormText,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import { MdKeyboardArrowDown, MdOutlineSell } from 'react-icons/md';
import { GrDocument } from "react-icons/gr";
import { addUpdatePricingDefault, costPriceMaster, pricingDefault } from '../../../../../../services/pricingServices';
import { useMessage } from '../../../../../../context/messageContext';
import PricingHeader from '../../../../../../components/Pricing/PricingHeader';
import { useRightContext } from '../../../../../../context/rightMenuContext';


const ProjectDefaultsLeft = ({ token, loading, setLoading }) => {

    const navigate = useNavigate()
    const { setMessage, setMessageType } = useMessage()
    const { userDataContext, setUserDataContext } = useRightContext('')

    const initialValues = {
        apiURL: "",
    };

    const [currency, setCurrency] = useState('')
    const [hidePrice, setHidePrice] = useState(false)
    const [id, setId] = useState()
    const [installation, setInstallation] = useState(false)
    const [stdPricing, setStdPricing] = useState(false)
    const [selectedPricing, setSelectedPricing] = useState()
    const [selectedSupplyOnly, setSelectedSupplyOnly] = useState('')
    const [supplyOnly, setSupplyOnly] = useState(false)
    const [vat, setVat] = useState('')
    const [costPrice, setCostPrice] = useState([])


    useEffect(() => {
        getPricingDefaultService()
        costPriceMasterService()
    }, [])

    const costPriceMasterService = async () => {
        const res = await costPriceMaster(token)
        console.log(res, "52");
        setCostPrice(res.data.entity)
    }


    const handleNameChange = (e) => {
        const value = e.target.value
        setCurrency(value)
    }


    const getPricingDefaultService = async () => {
        setLoading(true)
        const res = await pricingDefault(token)
        if (res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                setId(res?.data?.entity?.id)
                setCurrency(res?.data?.entity?.currency)
                setHidePrice(res?.data?.entity?.hidePricesWhileEditing)
                setVat(res?.data?.entity?.vat)
                setInstallation(res?.data?.entity?.installation)
                setLoading(false)
            } else {
                setLoading(false)
                setMessage(res?.data?.message ? res.data.message : 'Something went wrong');
                setMessageType('error')
            }
        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')
            if (res?.message === "Network Error") {
                navigate('/error/network')
                setLoading(false)
            }
        }
    }

    const addUpdatePricingDefaultService = async () => {
        setLoading(true)

        const data = {
            id: id,
            currency: '£',
            hidePrice: hidePrice,
            vat: vat,
            installation: installation,
            allowPriceOverried: false
        }

        const res = await addUpdatePricingDefault(token, data)
        if (res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                setMessage(res?.data?.message ? res.data.message : 'Updated Palette')
                setMessageType('success')
                setLoading(false)
                getPricingDefaultService()
            } else {
                setLoading(false)
                setMessage(res?.data?.message ? res.data.message : 'Something went wrong');
                setMessageType('error')
                getPricingDefaultService()
            }
        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')
            if (res?.message === "Network Error") {
                navigate('/error/network')
                setLoading(false)
            }
        }
    }

    const handleStandardPricingDrop = () => {
        setStdPricing((prev) => !prev)
    }

    const handleSupplyOnlyDrop = () => {
        setSupplyOnly((prev) => !prev)
    }

    const handleSelectedPricing = (value) => {
        setSelectedPricing(value)
    }

    const handleSelectedSupplyOnly = (value) => {
        setSelectedSupplyOnly(value)
    }


    useEffect(() => {
        // setLoading(true)

        // getCrmData()
    }, [])

    const handleFormReset = () => {
        // getCrmData()
    }

    return (
        <div className='d-flex flex-column h-100'>
            
            <PricingHeader setSelectedSupplyOnly={setSelectedSupplyOnly} setSelectedPricing={setSelectedPricing} selectedPricing={selectedPricing} selectedSupplyOnly={selectedSupplyOnly} costPrice={costPrice} />
            
            <div className="d-flex align-items-center mb-3 flex-wrap gap-2 left_header">
                <h5 className="mb-0 text-wrap text-break main_title">Pricing defaults</h5>
            </div>

            <Form className='h-100 d-flex flex-column flex-grow-1 justify-content-between'>
                <div className='inside_scroll has_btns'>
                    <Row>
                        {/* <Col lg="12">
                            <Col md="8" lg="5" xl="5">
                                <FormGroup className="form-group mb-3">
                                    <Label for="apiURL">
                                        Currency
                                    </Label>
                                    <Input className='mb-1' name="currency" value={currency} onChange={(e) => handleNameChange(e)} />
                                </FormGroup>
                            </Col>
                        </Col> */}

                        <Col lg="12" >
                            <FormGroup className="form-group mb-3">
                                <Label for="updateCRM">
                                    Hide prices while editing
                                </Label>
                                <Form.Check // prettier-ignore
                                    type="switch"
                                    id="updateCRM"
                                    className='form-switch-lg'
                                    onChange={() => setHidePrice(!hidePrice)}
                                    checked={hidePrice}
                                    label={hidePrice ? 'On' : 'Off'}
                                />
                            </FormGroup>
                        </Col>
                        <Col lg="12">
                            <FormControl className="mb-3 w-100">
                                <Label>VAT</Label>
                                <RadioGroup
                                    aria-labelledby="vat-label"
                                    name="vat"
                                    className=''
                                    value={vat}
                                    onChange={(e) => setVat(e.target.value)}
                                >
                                    <FormControlLabel value="eachitem" control={<Radio />} label="Add to each item" />
                                    <FormControlLabel className='' value="total" control={<Radio />} label="Add at total" />
                                </RadioGroup>
                            </FormControl>
                        </Col>
                        <Col lg="12" >
                            <FormGroup className="form-group">
                                <Label for="installation">
                                    Installation
                                </Label>
                                <Form.Check // prettier-ignore
                                    type="switch"
                                    id="installation"
                                    className='form-switch-lg'
                                    onChange={() => { setInstallation(!installation) }}
                                    checked={installation}
                                    label={installation ? '' : 'None'}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
                <div className='d-flex gap-2 gap-xl-3 main_buttons'>
                    <Button
                        color="primary"
                        size="md"
                        className='px-4'
                        onClick={() => addUpdatePricingDefaultService()}
                        disabled={loading}
                    >
                        Save Changes
                    </Button>

                    <Button
                        color="primary"
                        size="md"
                        outline
                        className='px-4'
                        onClick={() => getPricingDefaultService()}
                    >
                        Reset
                    </Button>
                </div>
            </Form>
        </div>
    )
}

export default ProjectDefaultsLeft