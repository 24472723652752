import { useState } from "react";
import { useContext } from "react";
import { createContext } from "react";


const ColourContext = createContext();

const useCollectionColourContext = () => {
    const value = useContext(ColourContext);
    return value;
}

const CustomColourContext = ({ children }) => {

//context for passing messages through any component
//make sure to pass message type in all small letter for eg : setMessageType('success')

    const [activeColourShown, setActiveColourShown] = useState(0);
    const [activeHiddenColour, setActiveHiddenColour] = useState(0)
    const [uniqueId, setUniqueId] = useState('')

    const handleActiveIndexUp = () => {
            setActiveColourShown(activeColourShown - 1);
    };

    const handleActiveIndexDown = () => {
        setActiveColourShown(activeColourShown + 1);
    };

    return (
        // provider for context
        <ColourContext.Provider value={{ activeColourShown, activeHiddenColour, setActiveHiddenColour,handleActiveIndexUp,handleActiveIndexDown }}>
            {children}
        </ColourContext.Provider>
    )
}

export { useCollectionColourContext }
export default CustomColourContext;

