import React, { useState } from 'react'
import InnerLayout from "../../../../../layouts/internalLayout"
import ComingSoon from '../../errors/coming-soon'
import ColoursLeft from './colour-left'
import ColoursRight from './colours-right'
import { getAllColours, getBuildingVisualElements } from '../../../../../services/productServices'
import { useMessage } from '../../../../../context/messageContext'
import { useProductPaletteContext } from '../../../../../context/productPaletteContext'
import { useEffect } from 'react'
import { Oval } from 'react-loader-spinner'

const Products = () => {

    const [loading, setLoading] = useState(false)
    const { setNewItemList, setTimestamp, setActiveIndex } = useProductPaletteContext()

    const accessToken = localStorage.getItem('access_token')

    const { setMessage, setMessageType } = useMessage()
    const [allColours, setAllColours] = useState([])

    useEffect(() => {
        setTimestamp('')
        getAllColoursServices()
    }, [])

    const getAllColoursServices = async (type) => {
        setLoading(true)
        // const res = await getAllColours("7", accessToken)
        const res=await getBuildingVisualElements(accessToken)
        console.log("RESSSSSSSSSSSSSSS",res)
        if (res.status === 200) {
            let data = res?.data?.entity
            setNewItemList(res?.data?.entity)
            setAllColours(res?.data?.entity)
            if (type === 'added') {
                setTimestamp(data[data?.length - 1]?.colourPaletteId)
                setActiveIndex(data?.length - 1)
            }
            setLoading(false)
            setMessageType('success')
            setMessage(res.message)
            type = ''
        } else {
            setLoading(false)
            setMessageType('error')
            setMessage(res.message)
        }
    }

    return (
        <>

            {loading && (
                <div className='loader_main full_loader'>
                    <Oval
                        height={50}
                        width={50}
                        color="#44C8F5"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel='oval-loading'
                        secondaryColor="#44C8F5"
                        strokeWidth={2}
                        strokeWidthSecondary={2}
                    />
                </div>
            )}
            <InnerLayout pageTitle="Building Colours" layoutType="" leftContent={<ColoursLeft allColours={allColours} setLoading={setLoading} getAllColoursServices={getAllColoursServices} />} rightContent={<ColoursRight loading={loading} setLoading={setLoading} getAllColoursServices={getAllColoursServices} />} />
        </>
    )
}

export default Products