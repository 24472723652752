import React, { useEffect, useState } from "react";
import InternalLayout from "../../../../../../layouts/internalLayout";
import Left from "./left";
import { Oval } from "react-loader-spinner";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'
import { useMessage } from "../../../../../../context/messageContext";
import { useRightContext } from "../../../../../../context/rightMenuContext";
import { useNavigate } from "react-router-dom";
import { getAllFrameStyleColletion } from "../../../../../../services/frameCollectionsService";
import { MdKeyboardArrowDown } from "react-icons/md";
import CustomizedButton from "../../../../../../components/CustomizedButton";

const InstallationFrameProducts = () => {

  var collectionId = ''

  const navigate = useNavigate();
  const accessToken = localStorage.getItem('access_token')

  const { setMessage, setMessageType } = useMessage();
  const { setMainMenuActive, setSubMenuActive, setSubMenu2Active } = useRightContext()

  const [loading, setLoading] = useState(false);
  const [headerDropdown, setHeaderDropdown] = useState(false);
  const [mainDropdownList, setMainDropdownList] = useState([])
  const [frameCollection, setFrameCollection] = useState('')

  const toggleTabDropdown = () => setHeaderDropdown((prevState) => !prevState);

  useEffect(() => {
    getAllFrameService()

    setMainMenuActive("Pricing")
    setSubMenuActive("Installation")
    setSubMenu2Active("Frame styles")
  }, []);

  useEffect(() => {
    if (mainDropdownList && mainDropdownList.length > 0) {

      if (collectionId) {
        const collectionItem = mainDropdownList?.find((p) => p.id == collectionId)

        if (collectionItem && collectionItem.id) {
          setFrameCollection(collectionItem)
        }
      } else {
        setFrameCollection(mainDropdownList[0])
      }
    }
  }, [mainDropdownList]);

  const handleItemSelect = (value) => {
    setFrameCollection(value)
  }

  const getAllFrameService = async () => {
    setLoading(true)

    const res = await getAllFrameStyleColletion(accessToken)
    console.log(61);
    
    // console.log(res, "get data")

    if (res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        setMainDropdownList(res?.data?.entity)
      } else {
        setLoading(false)
        setMessage(res?.data?.message);
        setMessageType('error')
      }
    } else {
      setMessage(res?.message ? res.message : 'Something went wrong');
      setMessageType('error')
      if (res?.message === "Network Error") {
        navigate('/error/network')
      }
    }
  }

  const data1 = { url: `/product/frame-styles/list`, stateValue: frameCollection?.id }

  return (
    <>
      {loading && (
        <div className="loader_main full_loader">
          <Oval
            height={50}
            width={50}
            color="#44C8F5"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#44C8F5"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      )}

      <InternalLayout
        pageTitle=""
        layoutType="full"
        filterButton={
          <>
          <Dropdown
            isOpen={headerDropdown}
            toggle={toggleTabDropdown}
          >
            <DropdownToggle color="none" className="border-0 p-0">
              <div className="d-flex align-items-center">
                <span className="fw-semibold fs-5">
                  {frameCollection && frameCollection?.name}
                  <MdKeyboardArrowDown className="ms-1" size={20} />
                </span>
              </div>
            </DropdownToggle>

            <DropdownMenu className='full_height'>
              {mainDropdownList?.map((item, index) => {
                return (
                  <DropdownItem
                    onClick={() => handleItemSelect(item)}
                    key={index}
                    disabled={item?.id == frameCollection?.id}
                    className={`${item?.id == frameCollection?.id ? 'text-primary fw-medium' : ''}`}
                  >
                    {item?.name}
                  </DropdownItem>
                );
              })}
            </DropdownMenu>
          </Dropdown>

          <CustomizedButton data={data1} hasState={true} />
          </>
        }

        leftContent={
          <Left
            token={accessToken}
            setMessage={setMessage}
            setMessageType={setMessageType}
            loading={loading}
            setLoading={setLoading}
            collectionId={frameCollection?.id}
          />
        }
        rightContent={""}
      />
    </>
  );
};

export default InstallationFrameProducts;
