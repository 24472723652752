import React, { useState } from 'react'
import {
    Nav,
    NavLink,
    NavItem,
    TabContent,
    TabPane
} from 'reactstrap';

import SpecificationColours from '../../../../../../components/GlazingSpecificationComp/SpecificationColours';
import SpecificationPalette from '../../../../../../components/GlazingSpecificationComp/SpecificationPalette';
import SpecificationCollection from '../../../../../../components/GlazingSpecificationComp/SpecificationCollection';
import Editprices from '../../../../../../components/Editprices';

const SpecificationsLeft = ({ setLoading, getAllColoursServices, getAllCollectionServices, getAllPaletteServices, allColours, tabType, setTabType, setCommonHeading, commonHeading, allPalette, allCollection,setUsedProductsFromLeft,setUsedProductsFromLeftForPalette }) => {

    const [activeTab, setActiveTab] = useState("colours")
    // const [glazingType, setGlazingType] = useState("colours")

    const noRefCheck = (tab) => {
        setActiveTab(tab)
        setTabType(tab)
    }

    const editNavData = { url: `/pricing/glazing/1` }
const handleUsedProductsFromLeft=(data)=>{
    if(activeTab==="collections")
    {
        setUsedProductsFromLeft(data)
    }
    
}
const handleUsedColletionsFromLeft=(data)=>{
    if(activeTab==="palette")
    {
        setUsedProductsFromLeftForPalette(data)
    }
    
}
    return (
        <div className='d-flex flex-column h-100'>

            <div className='d-flex align-items-center mb-3 left_header'>
                {commonHeading && <h5 className="mb-0 text-wrap text-break me-4 main_title">{commonHeading}</h5>} <div></div>

                {activeTab==="palette" ?<Editprices data={editNavData} /> : null}
            </div>

            <Nav tabs className="details_header border-0 nav-tabs-custom">
                <NavItem>
                    <NavLink
                        className={`${activeTab === "colours" && 'active'}`}
                        onClick={() => noRefCheck("colours")}
                    >
                        Colours
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={`${activeTab === "palette" && 'active'}`}
                        onClick={() => noRefCheck("palette")}
                    >
                        Specification
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={`${activeTab === "collections" && 'active'}`}
                        onClick={() => noRefCheck("collections")}
                    >
                        Collections
                    </NavLink>
                </NavItem>
            </Nav>

            <TabContent activeTab={activeTab} className='flex-grow-1 d-flex'>
                <TabPane tabId="colours" className='flex-grow-1 w-100'>
                    <SpecificationColours allColours={allColours} setLoading={setLoading} getAllColoursServices={getAllColoursServices} setCommonHeading={setCommonHeading} tabType={tabType} />
                </TabPane>
                <TabPane tabId="palette" className='w-100'>
                    <SpecificationPalette setCommonHeading={setCommonHeading} getAllPaletteServices={getAllPaletteServices} setLoading={setLoading} allPalette={allPalette} tabType={tabType} allColours={allColours} setUsedProductsFromPalette={handleUsedColletionsFromLeft} />
                </TabPane>
                <TabPane tabId="collections" className='w-100'>
                    <SpecificationCollection getAllCollectionServices={getAllCollectionServices} allCollection={allCollection} tabType={tabType} setCommonHeading={setCommonHeading} setLoading={setLoading} setUsedProductsFromCollection={handleUsedProductsFromLeft}/>
                </TabPane>
            </TabContent>
        </div>
    )
}

export default SpecificationsLeft