import React from 'react'
import { useState } from 'react';
import { useProductPaletteContext } from '../../context/productPaletteContext';
import {
    Row,
    Col,
    Button,
    Label,
    FormGroup,
    Input,
} from 'reactstrap';
import { Form } from 'react-bootstrap';
import { FormControl, FormControlLabel, MenuItem, OutlinedInput, Radio, RadioGroup, Select } from 'material-ui-core';
import { MdOutlineKeyboardArrowDown } from 'react-icons/md';
import { MenuProps, glazignDesignColours, glazignDesignLoc, glazignDesignShape, } from '../../utility/data';
import { addUpdateGlazingPalette, getGlazingDesignImageService, getGlazingStyleOptionsService, textureImageMaster, uniqueGlazingPalette } from '../../services/glazingServices';
import { useMessage } from '../../context/messageContext';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import NumberInput from '../NumberInput';
import { useRightContext } from '../../context/rightMenuContext';


const DesignPalette = ({ setLoading, setCommonHeading, allPalette, tabType, getAllPaletteServices }) => {

    const accessToken = localStorage.getItem('access_token')
    const { handleEdit, setTimestamp, timestamp, activeIndex, setActiveIndex, newItemList, handleDescEdit, handleModificationChange } = useProductPaletteContext();
    const { setMessage, setMessageType } = useMessage()
    const [name, setName] = useState('')
    const [desc, setDesc] = useState('')
    const [styleId, setStyleId] = useState('')
    const [locId, setLocId] = useState('')
    const [patternId, setPatternId] = useState('')
    const [colorId, setColorId] = useState('')
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const [imageValues, setImageValues] = useState()
    const [dimensions, setDimensions] = useState('overall')
    // const [sideId, setSideId] = useState('')
    const [sideRadio, setSideRadio] = useState('')
    const [rows, setRows] = useState(0)
    const [id, setId] = useState()
    const [checks, setChecks] = useState({ symmetrical: true, numerals: false })
    const [shapeId, setShapeId] = useState(0)

    const [designStyleOptions, setDesignStyleOptions] = useState([]);

    const [designPatterns, setDesignPatterns] = useState([])

    const { setSelectedRightItem, selectedRightItem } = useRightContext()

    const firstEl = allPalette[0];

    const navigate = useNavigate()

    useEffect(() => {
        setActiveIndex(0)
    }, [tabType])

    useEffect(() => {
        if (firstEl && tabType === 'palette') {
            if (allPalette?.length && activeIndex !== 0 && tabType === 'palette') {
                setTimestamp(timestamp)
            } else {
                setTimestamp(firstEl?.id)
            }
        }
    }, [allPalette, timestamp, tabType])

    useEffect(() => {
        if (timestamp !== ' ' && tabType === 'palette' && newItemList?.length > 0) {
            getUniquePaletteServices()
        }
    }, [timestamp])

    const reset = () => {
        getUniquePaletteServices()
        getAllPaletteServices()
    }


    useEffect(() => {
        if (newItemList?.length === 0) {
            setTimestamp(null)
            setName('')
            setCommonHeading('')
        }
    }, [newItemList])


    useEffect(() => {
        textureImageMasterService();
        getDesignStyleOptions();
    }, []);

    useEffect(() => {
        if (styleId === null) {
            setStyleId([designStyleOptions[0]?.id]);
            setPatternId(designPatterns[0]?.patternId);
            const imageId = designPatterns[0]?.imagePath;
            handleModificationChange("modelLinked", imageId);
        }
    }, [styleId]);

    useEffect(() => {
        if (styleId) getDesignPattern();
    }, [styleId]);

    const handleChecks = (e) => {
        const { checked, name } = e.target
        setChecks({ ...checks, [name]: checked })
    }


    const getUniquePaletteServices = async () => {
        setLoading(true)
        const res = await uniqueGlazingPalette(timestamp, accessToken)
        if (res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                // console.log(res.data, "102");
                const selectedRight = allPalette?.find((p) => p.id == timestamp)
                setSelectedRightItem(selectedRight)
                setName(res?.data?.entity?.name)
                setDesc(res?.data.entity?.description ? res?.data.entity?.description : "")
                setStyleId(res?.data?.entity?.style)
                setLocId(res?.data?.entity?.location)
                setPatternId(res?.data?.entity?.pattern)
                setColorId(res?.data?.entity?.colourId)
                setShapeId(res?.data?.entity?.shape)
                // setSideId(res?.data?.entity?.side)
                setWidth(res?.data?.entity?.width)
                setRows(res?.data?.entity?.rows ? res?.data?.entity?.rows : 0)
                console.log(res?.data?.entity?.rows, "109");
                setDimensions(res?.data?.entity?.dimensions)
                setHeight(res?.data?.entity?.height)
                setCommonHeading(res?.data.entity?.name)
                setLoading(false)
                setTimestamp(timestamp)
                setId(res?.data.entity?.id)
            } else {
                setLoading(false)
                setMessage(res?.data?.message ? res.data.message : 'Something went wrong');
                setMessageType('error')
            }
        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')
            if (res?.message === "Network Error") {
                navigate('/error/network')
                setLoading(false)
            }

        }
    }

    const updateGlazingPaletteServices = async () => {
        setLoading(true)
        const data = {
            id: timestamp,
            name: name,
            desc: desc,
            specification: "",
            colourId: colorId,
            thickness: 0,
            noOfPanes: 0,
            textureSide: 0,
            maximumWidth: 0,
            maximumHeight: 0,
            maximumsquare: 0,
            imageId: 0,
            typeId: 4,
            hardwareCollectionId: 0,
            sequenceNo: 0,
            style: styleId,
            loc: locId,
            pattern: patternId,
            // side: sideId,
            symmetrical: checks?.symmetrical,
            symmetricalSide: sideRadio,
            dimension: dimensions,
            shape: shapeId,
            width: width,
            height: height,
            stepWidth: 0,
            stepHeight: 0,
            radiusWidth: 0,
            radiusHeight: 0,
            rows: rows,
            colorCollectionId: 0,
        }

        const res = await addUpdateGlazingPalette(data, accessToken)
        if (res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                setMessageType('success')
                setMessage(res?.data?.message ? res.data.message : 'Added new palette')
                getAllPaletteServices()
                setLoading(false)
            } else {
                setMessage(res?.data?.message ? res.data.message : 'Something went wrong')
                setMessageType('error')
                getAllPaletteServices()
                setLoading(false)

            }
        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')
            getAllPaletteServices()
            if (res?.message === "Network Error") {
                navigate('/error/network')
            }
        }
    }


    const textureImageMasterService = async () => {
        setLoading(true)
        const res = await textureImageMaster(accessToken)
        if (res?.data?.statusCode === 200) {
            setLoading(false)
            setMessageType('success')
            setMessage(res.message)
            setImageValues(res?.data?.entity)
        } else {
            setLoading(false)
            setMessageType('error')
            setMessage(res.message)
        }
    }

    const getDesignStyleOptions = async () => {
        const res = await getGlazingStyleOptionsService(accessToken);
        if (res?.data?.statusCode === 200) {
            setDesignStyleOptions(res?.data?.entity?.result);
        } else {
            setDesignStyleOptions([]);
        }
    };

    const getDesignPattern = async () => {
        const res = await getGlazingDesignImageService(styleId, accessToken);
        if (res?.data?.statusCode === 200) {
            setDesignPatterns(res?.data?.entity?.result);
        } else {
            setDesignPatterns([]);
        }
    };

    const handleNameChange = (e) => {
        const value = e.target.value;
        if (e.target.value.length <= 50) {
            setName(value)
            handleEdit(value)
            setCommonHeading(value)
        }
    }

    const handleDescriptionChange = (e) => {
        const value = e.target.value;
        if (e.target.value.length <= 80) {
            setDesc(value)
            handleDescEdit(value)
        }
    }

    const handleStyleId = (e) => {
        const value = e.target.value;
        setStyleId(typeof value === 'string' ? value.split(',') : value)
    }

    const handleLocId = (e) => {
        const value = e.target.value;
        setLocId(typeof value === 'string' ? value.split(',') : value)
    }

    const handlePatternId = (e) => {
        const value = e.target.value;
        setPatternId(typeof value === 'string' ? value.split(',') : value)
        const imageId = designPatterns?.find((item) => item.patternId === value).imagePath;
        handleModificationChange("modelLinked", imageId)
    }

    const handleColorId = (e) => {
        const value = e.target.value
        setColorId(typeof value === 'string' ? value.split(',') : value)
    }

    // const handleSideId = (e) => {
    //     const value = e.target.value
    //     setSideId(typeof value === 'string' ? value.split(',') : value)
    // }

    const handleShapeId = (e) => {
        const value = e.target.value
        setShapeId(typeof value === 'string' ? value.split(',') : value)
    }

    return (
        <>
            <Form className='h-100 d-flex flex-column flex-grow-1 justify-content-between'>
                <div className='inside_scroll has_btns has_head'>
                    <Row>
                        <Col lg="6">
                            <FormGroup className="form-group">
                                <Label for="name">
                                    Name
                                </Label>
                                <Input disabled={!selectedRightItem?.isSelf} className='mb-1' name='name' value={name} onChange={(e) => handleNameChange(e)} />
                                {name && name.length > 49 && (
                                    <div className='mt-1 text-muted text-small'>Max 50 characters are allowed</div>
                                )}

                                {(!name && id) && (
                                    <div className='mt-1 text-danger text-small'>Name is required</div>
                                )}
                            </FormGroup>
                        </Col>
                        <Col lg="6">
                            <FormGroup className="form-group">
                                <Label for="name">
                                    Description
                                </Label>
                                <Input disabled={!selectedRightItem?.isSelf} className='mb-1' name='desc' value={desc} onChange={(e) => handleDescriptionChange(e)} />

                            </FormGroup>
                        </Col>
                        <Col lg="6">
                            <FormGroup className="form-group">
                                <Label for="image">
                                    Style
                                </Label>
                                <Select
                                    labelId="printdoc-label"
                                    id="image"
                                    value={styleId}
                                    disabled={!selectedRightItem?.isSelf}
                                    label="image"
                                    onChange={handleStyleId}
                                    input={<OutlinedInput className='w-100' name='colours' />}

                                    MenuProps={MenuProps}
                                    IconComponent={() => (
                                        <MdOutlineKeyboardArrowDown size={20} className='me-1 MuiSvgIcon-root MuiSelect-icon' />
                                    )}
                                >
                                    {designStyleOptions && designStyleOptions?.map((item, index) => {
                                        return (
                                            <MenuItem value={item?.id} key={index} >{item?.text}</MenuItem>

                                        )
                                    })}
                                </Select>
                            </FormGroup>
                        </Col>
                        <Col lg="6">
                            <FormGroup className="form-group">
                                <Label for="image">
                                    Location
                                </Label>
                                <Select
                                    labelId="printdoc-label"
                                    id="image"
                                    value={locId}
                                    disabled={!selectedRightItem?.isSelf}
                                    label="image"
                                    onChange={handleLocId}
                                    input={<OutlinedInput className='w-100' name='colours' />}

                                    MenuProps={MenuProps}
                                    IconComponent={() => (
                                        <MdOutlineKeyboardArrowDown size={20} className='me-1 MuiSvgIcon-root MuiSelect-icon' />
                                    )}
                                >
                                    {glazignDesignLoc?.map((item, index) => {
                                        return (
                                            <MenuItem value={item?.id} key={index} >{item?.name}</MenuItem>

                                        )
                                    })}
                                </Select>
                            </FormGroup>
                        </Col>
                        <Col lg="6">
                            <FormGroup className="form-group">
                                <Label for="image">
                                    Pattern
                                </Label>
                                <Select
                                    labelId="printdoc-label"
                                    id="image"
                                    value={patternId}
                                    disabled={!selectedRightItem?.isSelf}
                                    label="image"
                                    onChange={handlePatternId}
                                    input={<OutlinedInput className='w-100' name='colours' />}

                                    MenuProps={MenuProps}
                                    IconComponent={() => (
                                        <MdOutlineKeyboardArrowDown size={20} className='me-1 MuiSvgIcon-root MuiSelect-icon' />
                                    )}
                                >
                                    {designPatterns && designPatterns?.map((item, index) => {
                                        return (
                                            <MenuItem value={item?.patternId} key={index} >{item?.patternName}</MenuItem>

                                        )
                                    })}
                                </Select>
                            </FormGroup>
                        </Col>
                        {styleId !== 2 &&
                            <Col lg="6">
                                <FormGroup className="form-group">
                                    <Label for="color">
                                        Colours
                                    </Label>
                                    <Select
                                        labelId="printdoc-label"
                                        id="color"
                                        value={colorId}
                                        disabled={!selectedRightItem?.isSelf}
                                        label="color"
                                        onChange={handleColorId}
                                        input={<OutlinedInput className='w-100' name='colours' />}

                                        MenuProps={MenuProps}
                                        IconComponent={() => (
                                            <MdOutlineKeyboardArrowDown size={20} className='me-1 MuiSvgIcon-root MuiSelect-icon' />
                                        )}
                                    >
                                        {glazignDesignColours?.map((item, index) => {
                                            return (
                                                <MenuItem value={item?.id} key={index} >{item?.name}</MenuItem>

                                            )
                                        })}
                                    </Select>
                                </FormGroup>
                            </Col>}
                        {/* <Col lg="6">
                            <FormGroup className="form-group">
                                <Label for="color">
                                    Side
                                </Label>
                                <Select
                                    labelId="printdoc-label"
                                    id="color"
                                    value={sideId}
                                    label="color"
                                    onChange={handleSideId}
                                    input={<OutlinedInput className='w-100' name='colours' />}

                                    MenuProps={MenuProps}
                                    IconComponent={() => (
                                        <MdOutlineKeyboardArrowDown size={20} className='me-1 MuiSvgIcon-root MuiSelect-icon' />
                                    )}
                                >
                                    {glazignDesignSide?.map((item, index) => {
                                        return (
                                            <MenuItem value={item?.id} key={index} >{item?.name}</MenuItem>

                                        )
                                    })}
                                </Select>
                            </FormGroup>
                        </Col> */}
                        <Col lg="6">
                            <FormGroup className="mb-3">
                                <Label for="width">Width</Label>

                                <NumberInput
                                    name="width"
                                    value={width}
                                    disabled={!selectedRightItem?.isSelf}
                                    setValue={setWidth}
                                />
                            </FormGroup>
                        </Col>
                        <Col lg="6">
                            <FormGroup className="mb-3">
                                <Label for="width">Rows</Label>

                                <NumberInput
                                    name="width"
                                    value={rows}
                                    disabled={!selectedRightItem?.isSelf}
                                    setValue={setRows}
                                />
                            </FormGroup>
                        </Col>
                        {styleId !== 2 ?
                            <Col lg="6">
                                <FormGroup className="mb-3">
                                    <Label for="height">Height</Label>

                                    <NumberInput
                                        name="height"
                                        value={height}
                                        disabled={!selectedRightItem?.isSelf}
                                        setValue={setHeight}
                                    />
                                </FormGroup>
                            </Col> : ""}
                        <Col lg="12">
                            <Row>
                                {(styleId !== 2 && locId === 2) &&
                                    <Col lg="6">
                                        <FormGroup className="form-group">
                                            <Label for="symmetrical">
                                                Symmetrical
                                            </Label>
                                            <Form.Check // prettier-ignore
                                                type="switch"
                                                id="symmetrical"
                                                disabled={!selectedRightItem?.isSelf}
                                                className={`form-switch-lg `}
                                                label={'On'}
                                                name='symmetrical'
                                                onChange={(e) => handleChecks(e)}
                                                checked={checks.symmetrical}

                                            />
                                        </FormGroup>
                                    </Col>}
                                <Col lg="6">

                                    <Label className='pb-1' >
                                        Dimensions
                                    </Label>

                                    <FormControl className={`mb-2 w-100 ${!selectedRightItem?.isSelf ? "not_allowed" : ""}`}>
                                        <RadioGroup
                                            aria-labelledby="dimensions-label"
                                            name="dimensions"
                                            className='flex-sm-row gap-2 gap-md-5'
                                            value={dimensions}
                                            onChange={(e) => setDimensions(e.target.value)}
                                        >
                                            <FormControlLabel value="overall" control={<Radio name='dimension' />} label="Overall" />
                                            {styleId !== 2 &&
                                                <FormControlLabel value="shape" control={<Radio name='dimension' />} label="Shape" />}
                                            <FormControlLabel value="defaultSpacing" control={<Radio name='dimension' />} label="Default Spacing" />
                                        </RadioGroup>
                                    </FormControl>
                                </Col>
                                {!checks.symmetrical &&
                                    <Col lg="6">

                                        <Label className='pb-1' >
                                            Side
                                        </Label>

                                        <FormControl className={`mb-2 w-100 ${!selectedRightItem?.isSelf ? "not_allowed" : ""}`}>
                                            <RadioGroup
                                                aria-labelledby="sides-label"
                                                name="sides"
                                                className='flex-sm-row gap-2 gap-md-5'
                                                value={sideRadio}
                                                onChange={(e) => setSideRadio(e.target.value)}
                                            >
                                                <FormControlLabel value="external" control={<Radio name='sidesRadio' />} label="External" />
                                                <FormControlLabel value="internal" control={<Radio name='sidesRadio' />} label="Internal" />
                                            </RadioGroup>
                                        </FormControl>
                                    </Col>}
                                {dimensions === 'shape' &&
                                    <Col lg="6">

                                        <FormGroup className="form-group">
                                            <Label for="shape">
                                                Shape
                                            </Label>
                                            <Select
                                                labelId="shape-label"
                                                id="shape"
                                                value={shapeId}
                                                disabled={!selectedRightItem?.isSelf}
                                                label="shape"
                                                onChange={(e) => handleShapeId(e)}
                                                input={<OutlinedInput className='w-100' name='colours' />}

                                                MenuProps={MenuProps}
                                                IconComponent={() => (
                                                    <MdOutlineKeyboardArrowDown size={20} className='me-1 MuiSvgIcon-root MuiSelect-icon' />
                                                )}
                                            >
                                                {glazignDesignShape?.map((item, index) => {
                                                    return (
                                                        <MenuItem value={item?.id} key={index} >{item?.name}</MenuItem>

                                                    )
                                                })}
                                            </Select>
                                        </FormGroup>
                                    </Col>}

                                {styleId === 2 &&
                                    <Col lg="6">
                                        <FormGroup className="form-group">
                                            <Label for="numerals">
                                                Allow Numerals
                                            </Label>
                                            <Form.Check // prettier-ignore
                                                type="switch"
                                                id="numerals"
                                                disabled={!selectedRightItem?.isSelf}
                                                className={`form-switch-lg `}
                                                label={checks.numerals ? 'On' : 'Off'}
                                                name='numerals'
                                                onChange={(e) => handleChecks(e)}
                                                checked={checks.numerals}

                                            />
                                        </FormGroup>
                                    </Col>}
                            </Row>
                        </Col>
                    </Row>
                </div>
                {selectedRightItem?.isSelf && <div className='d-flex gap-2 gap-xl-3 main_buttons mt-4'>
                    <Button
                        color="primary"
                        size="md"
                        className='px-4'
                        disabled={newItemList?.length === 0 || !name || name.length > 49}
                        onClick={() => updateGlazingPaletteServices()}
                    >
                        Save Changes
                    </Button>

                    <Button
                        color="primary"
                        size="md"
                        outline
                        className='px-4'
                        disabled={newItemList?.length === 0}

                        onClick={() => reset()}
                    >
                        Reset
                    </Button>
                </div>}
            </Form>
        </>
    )
}

export default DesignPalette