import React from 'react'
import OuterFrame from "../../assets/img/DynamicCompImg/Outer frame.png";
import ArrowLeft from "../../assets/img/DynamicCompImg/ArrowLeft.png";
import ArrowRight from "../../assets/img/DynamicCompImg/ArrowRight.png";

import Line from "../../assets/img/DynamicCompImg/Line.png";
import Cross from "../../assets/img/DynamicCompImg/Cross 2.png";
import { map } from 'lodash';


const Vector = ({ data }) => {
    // console.log("hghgfhfgh"+data.windowData);

    // when width is greater than height, max width and height -> 80 and height will be calc based on overallWidth
    let overallWidth = ((data?.layoutFrame?.width / 30) > 80 ? 80 : data?.layoutFrame?.width / 30)
    let heightScalingFactor = overallWidth / data?.layoutFrame?.width
    let overallHeight = data?.layoutFrame?.height * heightScalingFactor

    // when height is greater than width, max height and width -> 80 and width will be calc based on heightData
    let heightData = ((data?.layoutFrame?.height / 30) > 80 ? 80 : data?.layoutFrame?.height / 30)
    let widthScalingFactor = heightData / data?.layoutFrame?.height

    if (data?.layoutFrame?.height > data?.layoutFrame?.width) {
        overallWidth = data?.layoutFrame?.width * widthScalingFactor
        overallHeight = 80
    }

    const length = data?.numberOfSash && data?.numberOfSash?.number
    //const length = 4 + (1);
    const numberOftransome = 0 + (1);
    const hangingNumbers = Array.from({ length }, (_, index) => index + 1);
    // debugger;
    const lineSpacing = overallWidth / hangingNumbers.length
    const startX = (overallWidth - lineSpacing) / 2
    const gap = overallWidth / length;
    const transomegap = overallHeight / numberOftransome;
    var gapsValueOfFrame = [];
    var gappercentageofframe = 0;
    var gappercentageofframe1 = 0;
    const getvalueofsvglocation = (index) => {
        if (data && data?.sashSize && data?.sashSize?.length) {
            gappercentageofframe += (data?.sashSize[index] * 100) / data?.layoutFrame?.width;
        }
        var gapvalueofsvg = (overallWidth * gappercentageofframe) / 100;
        gapsValueOfFrame.push(gapvalueofsvg);
    }
    for (var i = 0; i < length; i++) {
        getvalueofsvglocation(i);
    }


    const lineImages = hangingNumbers.splice(1).map((number, index) => {
        if (data && data?.sashSize && data?.sashSize?.length) {
            gappercentageofframe1 += (data?.sashSize[index] * 100) / data?.layoutFrame?.width;
        }
        var gapvalueofsvg = (overallWidth * gappercentageofframe1) / 100;
        return (
            //<line key={index} height={overallHeight} width={50} x1={gap * (index + 1)} x2={gap * (index + 1)} y1={overallHeight - 2} stroke="black" strokeWidth="2.5" />
            <line key={index} height={overallHeight} width={50} x1={gapvalueofsvg} x2={gapvalueofsvg} y1={overallHeight - 2} stroke="black" strokeWidth="2.5" />
        )

    })
    const transomeNumbers = [];
    for (var i = 0; i < numberOftransome; i++) {
        transomeNumbers.push(i + 1)
    }
    const trasnsomelineimage = transomeNumbers.splice(1).map((number, index) => {
        return (
            <line key={index} height={10} width={overallWidth} x1={overallWidth - 2} y1={transomegap * (index + 1)} y2={transomegap * (index + 1)} stroke="black" strokeWidth="2" />
        )

    })
    const Cross = ({ index, verticleindex }) => {
        verticleindex = verticleindex + 1
        return (<>


            {/* <line width="180" x1={gap * index} y1={(verticleindex==1?0:transomegap*verticleindex)} x2={gap * (index + 1)} y2={numberOftransome==1?overallHeight:(transomegap*(verticleindex==1?1:(verticleindex-1)))} stroke="black" strokeWidth="1"/>
            <line x1={gap * (index + 1)} y1={(verticleindex==1?0:transomegap*verticleindex)} x2={gap * index} y2={numberOftransome==1?overallHeight:(transomegap*(verticleindex==1?1:(verticleindex-1)))} stroke="black" strokeWidth="1" /> */}
            <line width="180" x1={gapsValueOfFrame[index - 1]} y1={(verticleindex == 1 ? 0 : transomegap * verticleindex)} x2={gapsValueOfFrame.length == (index - 1) ? overallWidth : gapsValueOfFrame[index]} y2={numberOftransome == 1 ? overallHeight : (transomegap * (verticleindex == 1 ? 1 : (verticleindex - 1)))} stroke="black" strokeWidth="1" />
            <line x1={gapsValueOfFrame[index]} y1={(verticleindex == 1 ? 0 : transomegap * verticleindex)} x2={gapsValueOfFrame.length == (index - 1) ? overallWidth : gapsValueOfFrame[index - 1]} y2={numberOftransome == 1 ? overallHeight : (transomegap * (verticleindex == 1 ? 1 : (verticleindex - 1)))} stroke="black" strokeWidth="1" />
        </>)

    }
    const LeftArrow = ({ index }) => {

        return (
            // <svg xmlns="http://www.w3.org/2000/svg" width={(overallWidth / length) - 7} height={overallHeight} fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16" x={(gap * index) + 4} y={0} >
            //     <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8" stroke="black" strokeWidth="0.5" />
            // </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width={index == 0 ? (gapsValueOfFrame[index] - 7) : (gapsValueOfFrame[index] - gapsValueOfFrame[index - 1] - 7)} height={overallHeight} fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16" x={(index == 0 ? 0 : gapsValueOfFrame[index - 1]) + 4} y={0} >
                <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8" stroke="black" strokeWidth="0.5" />
            </svg>
        )
    }
    const RightArrow = ({ index }) => {

        return (
            // <svg xmlns="http://www.w3.org/2000/svg" width={(overallWidth / length) - 7} height={overallHeight} fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16" x={(gap * index) + 4} y={0} >
            //     <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8" stroke="black" strokeWidth="0.5" />
            // </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width={index == 0 ? (gapsValueOfFrame[index] - 7) : (gapsValueOfFrame[index] - gapsValueOfFrame[index - 1] - 7)} height={overallHeight} fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16" x={(index == 0 ? 0 : gapsValueOfFrame[index - 1]) + 4} y={0} >
                <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8" stroke="black" strokeWidth="0.5" />
            </svg>
        )
    }
    return <svg width={overallWidth} height={overallHeight} xmlns="http://www.w3.org/2000/svg" className="style_svg" shapeRendering="auto" strokeLinecap="square">
        {/* <image href={OuterFrame} width={overallWidth} height={overallHeight} /> */}

        <rect width={overallWidth} height={overallHeight} stroke="black" fill="transparent" strokeWidth="5" x="0" y="0" />

        {lineImages}

        {data?.windowData && data?.windowData?.length > 0 ?
            (data.windowData.map((item) =>
                item.hangingType == "Left" ? <LeftArrow index={item.index} /> : item.hangingType == "Right" ? <RightArrow index={item.index} /> : item.hangingType == "Fixed" ? <Cross index={item.index} verticleindex={0} /> : null
            )) : null
        }
    </svg>

    {/* <div style={{ display: "flex", flexDirection: "row" }}>
                <div>
                    <svg width="300" height="300" xmlns="http://www.w3.org/2000/svg">
                        <image href={OuterFrame} width="300" height="300" />
                        <image href={Arrow3} width="100" height="100" y="100" x="30" />
                        <image href={Line} width="10" height="270" x="140" y="15" />
                        <image href={Cross} width="150" height="300" x="145" y="0" />
                    </svg>
                </div>
                <div>
                    <svg width="300" height="300" xmlns="http://www.w3.org/2000/svg">
                        <image href={OuterFrame} width="300" height="300" />
                        <image href={Arrow3} width="100" height="100" y="100" x="30" />
                        <image href={Line} width="10" height="270" x="140" y="15" />
                        <image href={Cross} width="150" height="300" x="145" y="0" />
                    </svg>
                </div>
            </div>
            <div style={{ display: "flex", flexDirection: "column", marginTop: "100px" }}>
                <div style={{ marginTop: "-87px" }}>
                    <svg width="300" height="100" xmlns="http://www.w3.org/2000/svg">
                        <image href={OuterFrame} width="300" height="300" />
                        <image href={Arrow3} width="50" height="50" y="40" x="50" />
                        <image href={Line} width="10" height="270" x="140" y="15" />
                        <image href={Cross} width="150" height="100" x="150" y="10" />
                    </svg>
                </div>
                <div style={{ position: "absolute" }}>
                    <svg width="300" height="300" xmlns="http://www.w3.org/2000/svg">
                        <image href={OuterFrame} width="300" height="300" />
                        <image href={Arrow3} width="100" height="100" y="100" x="30" />
                        <image href={Line} width="10" height="270" x="140" y="15" />
                        <image href={Cross} width="150" height="300" x="145" y="0" />
                    </svg>
                </div>
            </div> */}

}

export default Vector