import React, { useEffect, useState } from 'react'
import InnerLayout from "../../../../../layouts/internalLayout"
import ComingSoon from '../../errors/coming-soon'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import ProfileLeft from './left'
import ProfileRight from './right'
import { useMessage } from '../../../../../context/messageContext'
import { useRightContext } from '../../../../../context/rightMenuContext'
import { addNewRightItem, getAllProfileList, getItemDetails, updateItemDetails } from '../../../../../services/productProfileServiceV'
import { Oval } from 'react-loader-spinner'
import { productProfileMenu } from '../../../../../utility/data'
import { getProfileInUsed, removeProfileInUsed } from '../../../../../services/commonServices'
import { deleteFrameProductProfiles } from '../../../../../services/sysFrameProductsService'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'
import { MdKeyboardArrowDown } from 'react-icons/md'
import NoDataCard from '../../../../../components/NoDataCard'

const Profile = () => {

    const accessToken = localStorage.getItem('access_token')

    const [usedProducts, setUsedProducts] = useState([])

    const [loading, setLoading] = useState(true)
    const [selectItemDetails, setSelectItemDetails] = useState([])
    const [allRightListItems, setAllRightListItems] = useState([])
    const [allRightListItemsOrder, setAllRightListItemsOrder] = useState(true)

    const [headerDropdown, setHeaderDropdown] = useState(false);
    const [mainDropdownList, setMainDropdownList] = useState([])
    const [frameCollection, setFrameCollection] = useState('')

    const { setMessage, setMessageType } = useMessage()

    const { timestamp, setSelectedRightItem, fetchSelected, setTimestamp, activeIndex, setActiveIndex, setMainMenuActive, setSubMenuActive } = useRightContext()

    const navigate = useNavigate();

    var getFirstElId = allRightListItems[0]?.id || ''
    var itemAddedTrue = ''

    const [wip, setWIP] = useState(false)
    const [pageName, setPageName] = useState('')
    const [pageType, setPageType] = useState(1)

    // let { profileId } = useParams();

    const location = useLocation();
    const profileId = location.state ? location.state.data : null

    useEffect(() => {
        setMainDropdownList(productProfileMenu)
        setMainMenuActive("Products")
        setSubMenuActive("")
    }, [])

    useEffect(() => {
        if (mainDropdownList && mainDropdownList.length > 0) {
            if (profileId) {
                const collectionItem = mainDropdownList?.find((p) => p.id == profileId)

                if (collectionItem && collectionItem.id) {
                    setFrameCollection(collectionItem)
                }
            } else {
                setFrameCollection(mainDropdownList[0])
            }
        }
    }, [mainDropdownList]);

    useEffect(() => {
        if (frameCollection?.id) {
            setTimestamp('')
            if (productProfileMenu && productProfileMenu.length > 0) {
                const getPageName = productProfileMenu.filter((item) => {
                    return item.id == frameCollection?.id;
                });

                setPageName(getPageName[0]?.title);
            }

            setPageType(frameCollection?.id)

            setAllRightListItemsOrder(true)
            getAllProfileListData(frameCollection?.id)
        }
    }, [frameCollection?.id])

    useEffect(() => {
        if (frameCollection?.id && timestamp) {
            getProfileInUsedService()
        };
    }, [frameCollection?.id, timestamp]);

    useEffect(() => {
        if (allRightListItems?.length > 0 && timestamp) {
            getRightItemDetails(timestamp)
        }
    }, [timestamp])

    useEffect(() => {
        if (allRightListItemsOrder) {
            getFirstElId = allRightListItems[0]?.id
            setActiveIndex(0)

            if (getFirstElId && allRightListItems?.length) {
                getRightItemDetails(getFirstElId)
            }
        } else {
            if (getFirstElId && allRightListItems?.length) {
                if (timestamp && allRightListItems?.length && activeIndex !== 0) {
                    getRightItemDetails(timestamp)
                } else {
                    getRightItemDetails(getFirstElId)
                }
            }
        }
    }, [allRightListItems])

    // get all right menu item list based on page/type (type = 1 for forewords)
    const getAllProfileListData = async (type) => {
        const res = await getAllProfileList(accessToken, type);

        if (res && res && res.status === 200) {

            if (res?.data?.statusCode === 200) {
                if (res?.data?.entity !== null) {
                    if (res?.data?.entity?.length > 0) {
                        setAllRightListItems(res?.data?.entity)

                        if (itemAddedTrue == 'added') {
                            const addedItemIndex = res.data.entity.length - 1

                            if (addedItemIndex) {
                                fetchSelected(addedItemIndex, res.data.entity[addedItemIndex]?.id)
                                itemAddedTrue = ''
                            }
                        }

                    } else {
                        setAllRightListItems([])
                        setTimestamp('')

                        fetchSelected(0, '');

                        const formDataValues = {
                            "id": '',
                            "type": pageType,

                            "name": "",
                            "angle": 0,
                            "width": 0,
                            "height": 0,
                            "description": '',
                            "customePath": '',
                            "feature": 'Overall',
                            "minimum": 0,
                            "maximum": 0,
                            "radius": 0,
                            "radiusHeight": 0,
                            'radiusWidth': 0,
                            "shape": "1",
                            "side": "Inner",
                            'special': "1",
                            "stepWidth": 0,
                            "stepHeight": 0,
                            "xAxis": 0,
                            "yAxis": 0,
                        }

                        setSelectItemDetails(formDataValues)
                    }
                }

                setTimeout(() => {
                    setLoading(false)
                }, 1200);
            } else {
                setLoading(false)
                setMessage(res?.data.message ? res.data.message : 'Something went wrong');
                setMessageType('error')
            }

        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')

            if (res?.message === "Network Error") {
                navigate('/error/network')
            }
        }
    }

    // get details of selected item in right menu by passing item id
    const getRightItemDetails = async (id) => {
        setLoading(true)

        const res = await getItemDetails(accessToken, id);

        setAllRightListItemsOrder(false)

        if (res && res && res.status === 200) {

            if (res?.data?.statusCode === 200) {
                if (res?.data?.entity !== null) {

                    const selectedRight = allRightListItems?.find((p) => p.id == id)
                    setSelectedRightItem(selectedRight)

                    // set state to get details of current selected item which will display data in left side component
                    setSelectItemDetails(res.data.entity)

                    // set selected item id which will update context
                    setTimestamp(id)
                }

                setTimeout(() => {
                    setLoading(false)
                }, 1200);
            } else {
                setLoading(false)
                setMessage(res?.data.message ? res.data.message : 'Something went wrong');
                setMessageType('error')
            }

        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')

            if (res?.message === "Network Error") {
                navigate('/error/network')
            }
        }
    }

    // api call to update all selected item details
    const updateItemDetailsServices = async (data) => {
        setLoading(true)

        const res = await updateItemDetails(accessToken, timestamp ? timestamp : getFirstElId, data, pageType)

        if (res && res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                setLoading(false)
                setMessage(res?.data?.message ? res.data.message : 'Updated details');
                setMessageType('success')

                getAllProfileListData(pageType)

                // setLoading(false)
            } else {
                setLoading(false)
                setMessage(res?.data?.message ? res.data.message : 'Something went wrong');
                setMessageType('error')
            }
        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')

            if (res?.message === "Network Error") {
                navigate('/error/network')
            }
        }
    }

    // api call to added new right item
    const addNewRightItemServices = async (title, type) => {
        setLoading(true)
        itemAddedTrue = "added"

        const res = await addNewRightItem(accessToken, title, type)

        if (res && res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                setMessage(res?.data?.message ? res.data.message : 'Created profile');
                setMessageType('success')

                getAllProfileListData(pageType)
            } else {
                setLoading(false)
                setMessage(res?.data?.message ? res.data.message : 'Something went wrong');
                setMessageType('error')
            }
        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')

            if (res?.message === "Network Error") {
                navigate('/error/network')
            }
        }
    }

    const getProfileInUsedService = async () => {
        setLoading(true)

        const res = await getProfileInUsed(accessToken, frameCollection?.id, timestamp)

        if (res && res && res.status === 200) {
            if (res?.data?.statusCode === 200) {

                if (res.data.entity.result !== null) {
                    setUsedProducts(res.data.entity.result)
                } else {
                    setUsedProducts([])
                }

                setLoading(false)
            } else {
                setLoading(false)
                setMessage(res?.data?.message ? res.data.message : 'Something went wrong');
                setMessageType('error')
            }
        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')

            if (res?.message === "Network Error") {
                navigate('/error/network')
            }
        }
    }
    const RemoveProfileInUsedService = async (itemId, productType) => {

        const res = await removeProfileInUsed(accessToken, itemId, productType)

        if (res && res.status === 200) {
            debugger;
            if (res?.data?.statusCode === 200) {
                setMessage(res?.data?.message ? res?.data?.message : 'Invalid data');
                setMessageType('success')
                getProfileInUsedService()

            } else {
                setMessage(res?.data?.message ? res.data.message : 'Something went wrong');
                setMessageType('error')
                getProfileInUsedService()
            }
        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')
            if (res?.message === "Network Error") {
                navigate('/error/network')
                setLoading(false)
            }
            getProfileInUsedService()
        }
    }
    const deleteFrameProductProfilesService = async (itemId) => {

        const res = await deleteFrameProductProfiles(accessToken, itemId, "Profiles")

        if (res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                setMessage(res?.data?.message ? "Profile unlinked" : 'Profile unlinked');
                setMessageType('success')
                getProfileInUsedService()

            } else {
                setMessage(res?.data?.message ? res.data.message : 'Something went wrong');
                setMessageType('error')
                getProfileInUsedService()
            }
        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')
            if (res?.message === "Network Error") {
                navigate('/error/network')
                setLoading(false)
            }
            getProfileInUsedService()
        }
    }

    const handleFormReset = () => {
        if (timestamp) {
            getAllProfileListData(pageType)
            getRightItemDetails(timestamp)
        }
    }

    const handleItemSelect = (value) => {
        setFrameCollection(value)
    }

    const toggleTabDropdown = () => setHeaderDropdown((prevState) => !prevState);


    return (
        <>
            {wip ? (
                <ComingSoon title={`Profile ${pageName !== null ? pageName : ''} Coming Soon`} />
            ) : (

                <>
                    {loading && (
                        <div className='loader_main full_loader'>
                            <Oval
                                height={50}
                                width={50}
                                color="#44C8F5"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                                ariaLabel='oval-loading'
                                secondaryColor="#44C8F5"
                                strokeWidth={2}
                                strokeWidthSecondary={2}
                            />
                        </div>
                    )}

                    {mainDropdownList && mainDropdownList.length > 0 ? (
                        <InnerLayout pageTitle={''} layoutType=''
                            leftContent={<ProfileLeft setLoading={setLoading} profileId={frameCollection?.id} accessToken={accessToken} pageType={pageType} selectItemDetails={selectItemDetails} usedProducts={usedProducts} updateItemDetailsServices={updateItemDetailsServices} handleFormReset={handleFormReset} deleteFrameProductProfilesService={deleteFrameProductProfilesService} RemoveProfileInUsedService={RemoveProfileInUsedService} />}
                            rightContent={<ProfileRight profileId={frameCollection?.id} accessToken={accessToken} pageName={pageName} pageType={pageType} selectItemDetails={selectItemDetails} allRightListItems={allRightListItems} setLoading={setLoading} getAllProfileListData={getAllProfileListData} addNewRightItemServices={addNewRightItemServices} usedProducts={usedProducts} />}
                            filterButton={<Dropdown
                                isOpen={headerDropdown}
                                toggle={toggleTabDropdown}
                            >
                                <DropdownToggle color="none" className="border-0 p-0">
                                    <div className="d-flex align-items-center">
                                        <span className="fw-semibold fs-5">
                                            {frameCollection && frameCollection?.title}
                                            <MdKeyboardArrowDown className="ms-1" size={20} />
                                        </span>
                                    </div>
                                </DropdownToggle>

                                <DropdownMenu className='full_height'>
                                    {mainDropdownList && mainDropdownList?.map((item, index) => {
                                        return (
                                            <DropdownItem
                                                onClick={() => handleItemSelect(item)}
                                                key={index}
                                                disabled={item?.id == frameCollection?.id}
                                                className={`${item?.id == frameCollection?.id ? 'text-primary fw-medium' : ''}`}
                                            >
                                                {item?.title}
                                            </DropdownItem>
                                        );
                                    })}
                                </DropdownMenu>
                            </Dropdown>} />
                    ) : (
                        <NoDataCard text="profiles" loading={loading} />
                    )}
                </>
            )}
        </>
    )
}

export default Profile