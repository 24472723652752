import axios from "axios";
import { servicePath } from "../utility/data";

// get all right menu item list based on page/type
export const getAllPrintTemplate = (accessToken, sashId) => {

    const formData = new FormData();

    formData.append("SashMasterId", sashId);

    const headers = {
        'accept': 'text/plain',
        'Authorization': `Bearer ${accessToken}`
    }

    return axios
        .post(`${servicePath}/Product/GetAllSashProductGeneral`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            return error;
        });
}

// update details of selected item
export const getTabPrintTemplate = (accessToken, printID, tabName) => {

    console.log(printID, tabName, "tets")

    var apiName = ''

    if (tabName === "General" || tabName === "Sash hanging") {
        apiName = "GetSashProductGeneral"
    }

    if (
        tabName === "Glazing" || 
        tabName === "Profiles" || tabName === "Sub products") {
        apiName = "GetSashProductGlazing"
    }

    // if(tabName==="Glazing"){
    //     apiName="GetSashProductGlazingV1"
    // }

    if (tabName === "Hardware") {
        apiName = "GetSashProductHardware"
    }

    const headers = {
        'accept': 'text/plain',
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data'
    }

    const formData = new FormData();
    formData.append("SashProductGeneralId", printID);

    return axios
        .post(`${servicePath}/Product/${apiName}`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};

// get details of selected item in right menu
export const getItemDetails = (accessToken, id, tabName) => {

    console.log(id, tabName)

    var apiName = ''

    if (tabName === "General" || tabName === "Sash hanging") {
        apiName = "GetSashProductGeneral"
    }

    if (
        tabName === "Glazing" || 
        tabName === "Profiles" || tabName === "Sub products") {
        apiName = "GetSashProductGlazing"
    }

    // if(tabName==="Glazing"){
    //     apiName="GetSashProductGlazingV1"
    // }

    if (tabName === "Hardware") {
        apiName = "GetSashProductHardware"
    }

    const formData = new FormData();

    if (tabName === "General") {
        formData.append("Id", id);
    } else {
        formData.append("SashProductGeneralId", id);
    }

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'multipart/form-data'
        }
    };

    return axios
        .post(`${servicePath}/Product/${apiName}`, formData, config)
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};

// delete selected item
export const deleteRightItem = (accessToken, id) => {
    const headers = {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data'
    }

    const formData = new FormData()
    formData.append("SashProductGeneralId", id)

    return axios
        .post(`${servicePath}/Product/DeleteSashProduct`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}

// update order of selected item
export const updateOrder = (accessToken, id, direction, sashId) => {

    var requestBody = {
        "id": id,
        "sequenceNo": 0,
        "type": direction,
        "typeText": sashId,
    };

    const headers = {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
    }

    return axios
        .post(`${servicePath}/Product/UpdateSashProductOrder`, requestBody, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}

// update details of selected item
export const updateItemDetails = (accessToken, id, printId, data, tabName, sashId) => {
    const headers = {
        'accept': 'text/plain',
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data'
    }

    var apiName = "AddUpdateSashProductGeneral"

    const formData = new FormData();
    if (!tabName === "Accessories") {
        formData.append("SashProductMasterId", sashId)
    }

    if (tabName === "General") {
        apiName = "AddUpdateSashProductGeneral"

        formData.append("Id", id);
        formData.append("Name", data.name);
        formData.append("Description", data.description);
        formData.append('Joints', data?.Joints)
        formData.append('Clearance', data?.Clearance)
        formData.append('GasketClearance', data?.GasketClearance)
        formData.append('HingeClearance', data?.HingeClearance)
        formData.append('LockClearance', data?.LockClearance)
        formData.append('LowThresholdClearance', data?.LowThresholdClearance)
        formData.append('Open', data?.Open)
        formData.append('Flush', data?.Flush)
        formData.append('ProfilePath', data.filePath);
        formData.append('ProfilePathUrl', '');
    }

    if (tabName === "Glazing") {
        // Changing the name of the API as new inputs are being added
        apiName = "AddUpdateSashProductGlazing"
        // apiName="AddUpdateSashProductGlazingV1"

        formData.append("Id", data.id || '');
        formData.append("SashProductGeneralId", printId)

        formData.append("FrameClearance", data?.frameClearance)
        formData.append("GasketClearance", data?.gasketClearance)
        formData.append("Bead", data?.beadRadio)
        // formData.append("Height", data?.height)
        // formData.append("Width", data?.width)

        if (data.specifications) {
            for (let i = 0; i < data.specifications.length; i++) {
                formData.append(`Specification`, data.specifications[i]);
            }
        }
    }

    if (tabName === "Hardware") {
        apiName = "AddUpdateSashProductHardware"

        formData.append("Id", data.id || '');
        formData.append("SashProductGeneralId", printId)
        formData.append("HardwareCollectionId", data.hardwareId)
        formData.append("HandleOffset", data.handleOffset)
    }

    if (tabName === "Accessories") {
        apiName = "AddUpdateSashProductAccessories"

        formData.append("Id", data.id || '');
        formData.append("AccessoriesId", data.AccessoriesId)
        formData.append("Quantity", data.Quantity)
        formData.append("SashProductGeneralId", printId)
    }

    return axios
        .post(`${servicePath}/Product/${apiName}`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};

// add new right menu item based on page/type
export const addNewRightItem = (accessToken, data, sashId) => {

    const formData = new FormData();

    formData.append("Id", '');
    formData.append("Name", data);
    formData.append("SashProductMasterId", sashId);
    formData.append('Open', "Internal")

    const headers = {
        'accept': 'text/plain',
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data'
    }
    return axios
        .post(`${servicePath}/Product/AddUpdateSashProductGeneral`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};

// API's for Dual List Box of Profile Tab

export const getAllHiddenVisibleProfileItems = (accessToken, profileId, sashId, productId) => {

    console.log(profileId, sashId, productId, "test")

    const formData = new FormData();

    formData.append("ProfileMasterId", profileId);
    formData.append("SashProductMasterId", sashId);
    formData.append("SashProductGeneralId", productId);

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    return axios
        .post(`${servicePath}/Product/GetSashProductProfiles`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}

export const addSashProductProfiles = (accessToken, profileId, sashId, productId, itemId) => {

    console.log(profileId, sashId, productId, itemId, "tt")

    const formData = new FormData();

    formData.append("Id", '');
    formData.append("ProfileMasterId", profileId);
    formData.append("SashProductMasterId", sashId);
    formData.append("SashProductGeneralId", productId);
    formData.append("ProfileId", itemId);
    formData.append("DefaultProfile", false);

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    return axios
        .post(`${servicePath}/Product/AddSashProductProfiles`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error
        });
}

export const dualListItemMoveToLeft = (accessToken, itemId, tabName) => {

    console.log(tabName, "S")

    var apiName = "DeleteSashProductProfiles"

    if (tabName !== "Profiles") {
        apiName = "DeleteSashSubProduct"
    }

    const formData = new FormData();

    formData.append("Id", itemId);

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    return axios
        .post(`${servicePath}/Product/${apiName}`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error
        });
}

// API's for Dual List Box of Sub products Tab

export const getAllHiddenVisibleSubProductsItems = (accessToken, subProductId, productId) => {

    console.log(subProductId, productId, "sub products dual")

    const formData = new FormData();

    formData.append("SashProductMasterId", subProductId);
    formData.append("SashProductGeneralId", productId);

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    return axios
        .post(`${servicePath}/Product/GetSashProductSubProduct`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}

export const addSashProductSubProducts = (accessToken, subProductId, productId, getId) => {

    console.log(getId, "itemId")
    const formData = new FormData();

    formData.append("Id", '');
    formData.append("SashProductMasterId", subProductId);
    formData.append("SashProductGeneralId", productId);

    formData.append("SashProductId", getId);
    formData.append("DefaultSubProduct", false);

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    return axios
        .post(`${servicePath}/Product/AddSashProductSubProduct`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error
        });
}

// set selected profile item as default 
export const SetDefaultProductProfile = (accessToken, id) => {
    const headers = {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data'
    }

    const formData = new FormData()
    formData.append("id", id)

    return axios
        .post(`${servicePath}/Product/SetDefaultProductProfile`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}

// set selected profile item as default 
export const SetDefaultSubProduct = (accessToken, id) => {
    const headers = {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data'
    }

    const formData = new FormData()
    formData.append("id", id)

    return axios
        .post(`${servicePath}/Product/SetDefaultSubProduct`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}

export const UpdateSashProductProfileOrder = (accessToken, id, direction, generalId, profileId) => {

    var requestBody = {
        "id": id,
        "sequenceNo": 0,
        "type": direction,
        "masterParentId": generalId,
        "subMasterParentId": profileId,
    };

    const headers = {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
    }

    return axios
        .post(`${servicePath}/Product/UpdateSashProductProfileOrder`, requestBody, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}

export const UpdateSashSubProductOrder = (accessToken, id, direction, generalId, profileId) => {

    var requestBody = {
        "id": id,
        "sequenceNo": 0,
        "type": direction,
        "masterParentId": generalId,
        "subMasterParentId": profileId,
    };

    const headers = {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
    }

    return axios
        .post(`${servicePath}/Product/UpdateSashSubProductOrder`, requestBody, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}


export const deleteSysFrameSashInUsed = (accessToken, itemId) => {

    const formData = new FormData();

    formData.append("Id", itemId);

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    return axios
        .post(`${servicePath}/Master/RemoveSubProductInUsed`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error
        });
}


export const getAllSashProductAccessories = (accessToken, id) => {

    const formData = new FormData();

    formData.append("SashProductGeneralId", id);

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    return axios
        .post(`${servicePath}/Product/GetAllSashProductAccessories`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error
        });
}


export const getIndividualSashProductAccessories = (accessToken, Id) => {

    const formData = new FormData();

    formData.append("Id", Id);

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    return axios
        .post(`${servicePath}/Product/GetSashProductAccessories`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error
        });
}


export const deleteAccessories = (accessToken, Id) => {

    const formData = new FormData();

    formData.append("Id", Id);

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    return axios
        .post(`${servicePath}/Product/DeleteSashProductAccessories`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error
        });
}