import React, { useState } from "react";
import PricingHeader from "../../../../../../components/Pricing/PricingHeader";
import { Link } from "react-router-dom";
import { MdOutlineCreate, MdOutlineKeyboardArrowDown } from "react-icons/md";
import { Form, Table } from "react-bootstrap";
import { Button } from "reactstrap";
import { MenuItem, OutlinedInput, Select } from "material-ui-core";
import { MenuProps } from "../../../../../../utility/data";

export const collectionData = [
  {
    id: 1,
    title: "Black",
  },
  {
    id: 2,
    title: "Window boards",
  },
];

export const priceData = [
  {
    id: 1,
    title: "Colour Pricing",
  },
];

const Left = () => {
  const [tableData, setTableData] = useState([
    {
      id: 1,
      title: "OX",
      selectedCollection: "",
      selectedPriceList: "",
    },
    {
      id: 2,
      title: "XO",
      selectedCollection: "",
      selectedPriceList: "",
    },
    {
      id: 3,
      title: "MX",
      selectedCollection: "",
      selectedPriceList: "",
    },
    {
      id: 4,
      title: "XM",
      selectedCollection: "",
      selectedPriceList: "",
    },
    {
      id: 5,
      title: "XXO",
      selectedCollection: "",
      selectedPriceList: "",
    },
    {
      id: 6,
      title: "OXX",
      selectedCollection: "",
      selectedPriceList: "",
    },
    {
      id: 7,
      title: "MXX",
      selectedCollection: "",
      selectedPriceList: "",
    },
    {
      id: 8,
      title: "XXM",
      selectedCollection: "",
      selectedPriceList: "",
    },
    {
      id: 9,
      title: "Quad",
      selectedCollection: "",
      selectedPriceList: "",
    },
    {
      id: 10,
      title: "OXXO",
      selectedCollection: "",
      selectedPriceList: "",
    },
  ]);
  const [selectedPricing, setSelectedPricing] = useState();
  const [selectedSupplyOnly, setSelectedSupplyOnly] = useState("");
  const [costPrice, setCostPrice] = useState([]);

  const handleCollection = (e, id) => {
    const tempArray = [...tableData];
    tempArray?.forEach((item) => {
      if (item.id === id) {
        item.selectedCollection = e.target.value;
      }
    });
    setTableData(tempArray);
  };

  const handlePrice = (e, id) => {
    const tempArray = [...tableData];
    tempArray?.forEach((item) => {
      if (item.id === id) {
        item.selectedPriceList = e.target.value;
      }
    });
    setTableData(tempArray);
  };

  return (
    <>
      <PricingHeader
        setSelectedSupplyOnly={setSelectedSupplyOnly}
        setSelectedPricing={setSelectedPricing}
        selectedPricing={selectedPricing}
        selectedSupplyOnly={selectedSupplyOnly}
        costPrice={costPrice}
      />
      
      <div className="d-flex align-items-center mb-4 flex-wrap gap-3 left_header">
        <h5 className="mb-0 text-wrap text-break me-4 main_title">
          GS20 Sliding Door
        </h5>
        <Link to={{}}>
          <div className="ms-sm-3">
            <MdOutlineCreate className="text-primary me-1 mb-1" size={18} />
            <span className="text-primary d-none d-sm-inline-block">
              Customize
            </span>
          </div>
        </Link>
      </div>
      <Form className="h-100 d-flex flex-column flex-grow-1 justify-content-between">
        <div className="inside_scroll has_tab has_btns">
          <Table responsive className="w-100 discount_table">
            <thead className="border-bottom">
              <tr>
                <th style={{ width: "60%", minWidth: "60%" }}></th>
                <th style={{ width: "20%", minWidth: "20%" }}>Collection</th>
                <th
                  style={{ width: "20%", minWidth: "20%" }}
                  className="text-start"
                >
                  Price list
                </th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((item, index) => (
                <tr key={index}>
                  <td style={{ width: "40%", minWidth: "40%" }}>
                    {item.title}
                  </td>
                  <td>
                    <Select
                      id={`${item.id}collection`}
                      displayEmpty
                      value={item.selectedCollection}
                      label={"GLideline"}
                      onChange={(e) => handleCollection(e, item.id)}
                      input={
                        <OutlinedInput
                          className="w-100"
                          name="collectionName"
                        />
                      }
                      MenuProps={MenuProps}
                      IconComponent={() => (
                        <MdOutlineKeyboardArrowDown
                          size={20}
                          className="me-1 MuiSvgIcon-root MuiSelect-icon"
                        />
                      )}
                    >
                      {collectionData.map((item) => (
                        <MenuItem key={item.id} value={item.title}>
                          {item.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </td>
                  <td>
                    <Select
                      id={`${item.id}price`}
                      displayEmpty
                      value={item.selectedPriceList}
                      label={"Pricelist"}
                      onChange={(e) => handlePrice(e, item.id)}
                      input={
                        <OutlinedInput
                          className="w-100"
                          name="collectionName"
                        />
                      }
                      MenuProps={MenuProps}
                      IconComponent={() => (
                        <MdOutlineKeyboardArrowDown
                          size={20}
                          className="me-1 MuiSvgIcon-root MuiSelect-icon"
                        />
                      )}
                    >
                      {priceData.map((item) => (
                        <MenuItem key={item.id} value={item.title}>
                          {item.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        <div className="d-flex gap-2 gap-xl-3 main_buttons">
          <Button color="primary" size="md" className="px-4">
            Save Changes
          </Button>

          <Button color="primary" size="md" outline className="px-4">
            Reset
          </Button>
        </div>
      </Form>
    </>
  );
};

export default Left;
