import axios from "axios";
import { servicePath } from "../utility/data";

// get page setup details
export const getPageSetupInfo = (accessToken) => {
    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        headers: {
            'Authorization': `Bearer ${accessToken}`
        }
    };

    return axios
        .post(`${servicePath}/PrintSetting/GetPageSetupInfo`, {}, config)
        .then((res) => {
            return res;
        })
        .catch((error) => {
            return error;
        });
}

// update page setup informations
export const updatePageSetup = (data, accessToken) => {

    const formData = new FormData();

    formData.append('LogoImagePath', data.logo);
    formData.append('AddressLine1', data.address1);
    formData.append('AddressLine2', data.address2);
    formData.append('Phone', data.phone);
    formData.append('Email', data.email);
    formData.append('Website', data.website);
    formData.append('VAT', data.vat);
    formData.append('FontSize', data.fontValue);

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'multipart/form-data'
        }
    };

    return axios
        .post(`${servicePath}/PrintSetting/UpdatePageSetup`, formData, config)
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}

// remove logo API
export const logoRemove = (accessToken) => {

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        headers: {
            'Authorization': `Bearer ${accessToken}`
        }
    };

    return axios
        .post(`${servicePath}/PrintSetting/RemoveLogo`, {}, config)
        .then((res) => {
            return res;
        })
        .catch((error) => {
            return error;
        });
}