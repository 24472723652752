// import React, { useEffect, useRef, useState } from "react";
// import * as THREE from "three";
// import { servicePath } from "../../utility/data";
// import { getAllFrameCollection } from "../../services/3dModelServices";
// import { useMessage } from "../../context/messageContext";
// import { Oval } from "react-loader-spinner";
// import { useNavigate } from "react-router-dom";
// import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";

// const DemoFiberFrame = () => {
//   const containerRef = useRef(null);
//   const access_token = localStorage.getItem("access_token");

//   const [allFrameCollection, setAllFrameCollection] = useState([]);
//   const [loading, setLoading] = useState("");
//   const [tickleVent, setTickleVent] = useState([]);
//   const [doorHandleData, setDoorHandleData] = useState([]);
//   const [newFrameData, setNewFrameData] = useState([]);
//   const [childName, setChildName] = useState("");
//   const { setMessage, setMessageType } = useMessage();

//   let tickleVentReference = [];
//   const navigate = useNavigate();
//   let renderer = new THREE.WebGLRenderer({ antialias: true });

//   let camera = new THREE.PerspectiveCamera(
//     45,
//     window.innerWidth / window.innerHeight,
//     0.25,
//     30
//   );
//   let scene = new THREE.Scene();

//   const internalFrameRef = useRef(null);
//   const externalFrameRef = useRef(null);
//   const windowRef = useRef(null);

//   const glass1 = useRef(null);
//   const glass2 = useRef(null);

//   const sceneRef = useRef(null);

//   let gltfModel = useRef(null);
//   // let glass1, glass2;
//   let loader;

//   const tickleVentRef = useRef([]);
//   const handleRef = useRef([]);
//   let handleReference = [];
//   let heightRef = [];
//   let widthRef = [];

//   const currentModel = allFrameCollection.find((item) => item?.id === 10);

//   useEffect(() => {
//     getAllFrameService();
//   }, []);

//   useEffect(() => {
//     if (currentModel) {
//       init();
//     }
//     // Handle window resize
//     // const handleResize = () => {
//     //   const newWidth = containerRef.current?.clientWidth;
//     //   const newHeight = containerRef.current?.clientHeight;

//     //   // Update renderer size
//     //   renderer.setSize(newWidth, newHeight);

//     //   // Update camera aspect ratio
//     //   camera.aspect = newWidth / newHeight;
//     //   camera.updateProjectionMatrix();
//     // };

//     // handleResize();
//     // // Attach resize event listener
//     // window.addEventListener('resize', handleResize);

//     return () => {
//       if (renderer) {
//         renderer.dispose();
//       }
//     };

//   }, [currentModel]);

//   const getAllFrameService = async () => {
//     setLoading(true);
//     const res = await getAllFrameCollection(access_token);
//     if (res && res.status === 200) {
//       if (res?.data?.statusCode === 200) {
//         setLoading(false);
//         setAllFrameCollection(res?.data?.entity);
//       } else {
//         setLoading(false);
//         setMessage(res?.data?.message);
//         setMessageType("error");
//       }
//     } else {
//       setLoading(false);
//       setMessage(res?.message ? res.message : "Something went wrong");
//       setMessageType("error");
//       if (res?.message === "Network Error") {
//         navigate("/error/network");
//         setLoading(false);
//       }
//     }
//   };

//   console.log(currentModel, "Current!!!@!@");

//   function init() {
//     const container = containerRef.current;
//     initRenderer(container);
//     initScene();
//     setLoading(true);
//     sceneRef.current = scene;
//     loader = new GLTFLoader();

//     loader.load(
//       `${servicePath}/ThreeJSModel/Glb/${currentModel?.modelFilePath}`,
//       function (gltf) {
//         gltfModel.current = gltf.scene;

//         const scale = new THREE.Vector3(1, 1, 1);
//         gltf.scene.scale.copy(scale);

//         gltf.scene.position.set(0, 0, 0);

//         // Enable shadow for the GLTF model
//         gltf.scene.traverse((child) => {
//           //   console.log("child.name " + child.name);

//           if (child instanceof THREE.Mesh) {
//             child.castShadow = true;

//             if (child.name == "Glass1_1") {
//               //   console.log("glass set1!");
//               glass1.current = child;
//             }

//             if (child.name == "Glass2_2") {
//               //   console.log("Glass2_2");
//               glass2.current = child;
//             }

//             if (child.name == "InternalFrame") {
//               //   console.log("internal mesh found!");
//               internalFrameRef.current = child;
//             }

//             if (child.name == "ExternalFrame") {
//               //   console.log("external mesh found!");
//               externalFrameRef.current = child;
//             }
//           }

//           if (child.name == "Window1") {
//             // console.log("window found!");
//             windowRef.current = child;
//           }

//           // if (hardwareType && hardwareType?.name === "Trickle vent") {
//           if (child.name.includes("TickleVentRef")) {
//             tickleVentReference.push(child);
//             tickleVentRef.current = tickleVentReference;
//             // testRef.current = tickleVentReference;
//             setTickleVent((prevTickleVent) => [...prevTickleVent, child]);
//           }

//           //   console.log(child.name, "282");
//           if (child.name.includes("AddFrameRef")) {
//             // testRef.current = tickleVentReference;
//             setNewFrameData((prevFrameData) => [...prevFrameData, child]);
//             setChildName(child?.name);
//             // console.log(child.name, "298");
//           }
//           // if (hardwareType && hardwareType?.name === "Door Handle") {
//           if (child.name.includes("HandleRef")) {
//             // setIsLoaded(false);
//             // console.log("handle ref assigned!");
//             handleReference.push(child);
//             handleRef.current = handleReference;
//             // testRef.current = handleReference
//             setDoorHandleData((prevDoorHandle) => [...prevDoorHandle, child]);
//           }

//           // }

//           // if (child.name.includes("HandleRef")) {
//           // setIsLoaded(false)
//           //   console.log("handle ref assigned!");
//           //   handleReference.push(child);
//           //   // testRef.current = handleReference
//           // }

//           if (child.name.includes("HeightRef")) {
//             heightRef.push(child);
//           }

//           if (child.name.includes("WidthRef")) {
//             widthRef.push(child);
//           }
//         });

//         scene.add(gltf.scene);
//         setLoading(false);
//         animate();
//         // addLayoutLines();
//         render();
//         //addElement(georgianHorizontal);
//         //addFrame(true);

//         // updateGlassDesignRow(astragalRow, 6);
//         // setGlassDesignColumn(7);
//       }
//     );

//     // initControls();
//     // addShadow();
//   }

//   function render() {
//     renderer.render(scene, camera);
//   }

//   function animate() {
//     requestAnimationFrame(animate);

//     // updateOverlayPosition();

//     // updateIconPositions();
//     render();
//   }

//   function initScene() {
//     camera.position.set(0, 0.3, -1.2);
//     const directionalLight = new THREE.DirectionalLight(0xffffff, 2);
//     directionalLight.position.set(1, 5, 5);
//     scene.add(directionalLight);
//     const directionalLight2 = new THREE.DirectionalLight(0xffffff, 2);
//     directionalLight2.position.set(-1, 5, -5);
//     scene.add(directionalLight2);
//   }

//   function initRenderer(container) {
//     renderer.setPixelRatio(window.devicePixelRatio);
//     renderer.setSize(window.innerWidth, window.innerHeight);
//     renderer.setClearColor(0xffffff);
//     renderer.shadowMap.enabled = true;
//     renderer.shadowMap.type = THREE.PCFSoftShadowMap;
//     container.appendChild(renderer.domElement);
//   }

//   return (
//     <>
//       {loading && (
//         <div className="loader_main full_loader">
//           <Oval
//             height={50}
//             width={50}
//             color="#44C8F5"
//             wrapperStyle={{}}
//             wrapperClass=""
//             visible={true}
//             ariaLabel="oval-loading"
//             secondaryColor="#44C8F5"
//             strokeWidth={2}
//             strokeWidthSecondary={2}
//           />
//         </div>
//       )}
//       <div ref={containerRef} />
//     </>
//   );
// };

// export default DemoFiberFrame;

// Rendering the same code using react three fiber
import React, { useRef, useState, useEffect } from "react";
import { Canvas, useFrame, extend, useThree } from "react-three-fiber";
import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";
import { servicePath } from "../../utility/data";
import { getAllFrameCollection } from "../../services/3dModelServices";
import { useMessage } from "../../context/messageContext";
import { Oval } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import * as THREE from "three";
import { OrbitControls } from "three/addons/controls/OrbitControls.js";

extend({ OrbitControls });

const DemoFiberFrame = () => {
  const access_token = localStorage.getItem("access_token");
  const [allFrameCollection, setAllFrameCollection] = useState([]);
  const [loading, setLoading] = useState(false);
  const { setMessage, setMessageType } = useMessage();
  const navigate = useNavigate();

  useEffect(() => {
    getAllFrameService();
  }, []);

  const currentModel = allFrameCollection.find((item) => item?.id === 12);

  //   useEffect(() => {
  //     if (currentModel) {
  //     //   setLoading(true);
  //     }
  //   }, [currentModel]);

  //   useEffect(() => {
  //     if (currentModel) {
  //     //   init();
  //       return () => {
  //         setLoading(false);
  //       };
  //     }
  //   }, [currentModel]);

  const getAllFrameService = async () => {
    setLoading(true);
    try {
      const res = await getAllFrameCollection(access_token);
      if (res && res.status === 200 && res?.data?.statusCode === 200) {
        setAllFrameCollection(res?.data?.entity);
        setLoading(false);
      } else {
        setMessage(res?.data?.message || "Something went wrong");
        setMessageType("error");
      }
    } catch (error) {
      setMessage(error?.message || "Something went wrong");
      setMessageType("error");
      if (error?.message === "Network Error") {
        navigate("/error/network");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && (
        <div className="loader_main full_loader">
          <Oval
            height={50}
            width={50}
            color="#44C8F5"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#44C8F5"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      )}
      <Canvas style={{ height: "550px" }}>
        <FiberScene currentModel={currentModel} />
      </Canvas>
    </>
  );
};


const FiberScene = ({ currentModel }) => {
  const group = useRef();
  const sceneRef = useRef();
  const rendererRef = useRef();
  const cameraRef = useRef();
  const controlsRef = useRef();
  const shadowPlaneRef = useRef(null);
  const { size } = useThree();

  useEffect(() => {
    if (!currentModel) return;

    const loader = new GLTFLoader();
    loader.load(
      `${servicePath}/ThreeJSModel/Glb/${currentModel?.modelFilePath}`,
      (gltf) => {
        gltf.scene.scale.set(5, 5, 5); // Adjust the scale based on your preference

        const bbox = new THREE.Box3().setFromObject(gltf.scene);
        console.log("Bounding Box:", bbox);
        group.current.add(gltf.scene);
      }
    );
    // setDefaultCamera(cameraRef.current);
    return () => {
      // Clean up Three.js resources if needed
    };
  }, [currentModel]);

  useEffect(() => {
    if (group.current) {
      group.current.traverse((child) => {
        // Handle Three.js scene traversal if needed
      });
    }
  }, [group.current]);

  useEffect(() => {
    if (rendererRef.current) {
      rendererRef.current.render(sceneRef.current, cameraRef.current);
    }
  }, [rendererRef.current]);

  const { gl, camera } = useThree();

  // Set up controls
  useEffect(() => {
    controlsRef.current = new OrbitControls(camera, gl.domElement);
    controlsRef.current.enableDamping = true; // an animation loop is required when either damping or auto-rotation are enabled
    controlsRef.current.dampingFactor = 0.25;
    controlsRef.current.screenSpacePanning = false;
    controlsRef.current.maxPolarAngle = Math.PI / 2;
  }, []);

  useFrame(() => {
    if (controlsRef.current) {
      controlsRef.current.update();
    }
  });

  // Set the camera's FOV, aspect ratio, and position to take up the entire screen
  const handleResize = () => {
    const newWidth = window.innerWidth;
    const newHeight = window.innerHeight;

    // Update renderer size
    rendererRef.current.setSize(newWidth, newHeight);

    // Update camera aspect ratio
    cameraRef.current.aspect = newWidth / newHeight;
    cameraRef.current.updateProjectionMatrix();
  };

  useEffect(() => {
    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <group ref={group}>
      <scene ref={sceneRef} />
      <perspectiveCamera
        ref={cameraRef}
        fov={100}
        aspect={window.innerWidth / window.innerHeight}
        near={0.1}
        far={30}
        position={[0, 0.3, -5]}
      />
      <primitive
        ref={rendererRef}
        attach="renderer"
        object={new THREE.WebGLRenderer({ antialias: true })}
      />
    </group>
  );
};

export default DemoFiberFrame;
