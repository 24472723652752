import React, { useState } from "react";
import { Form } from "react-bootstrap";
import {
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Input,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Table,
} from "reactstrap";
import { useEffect } from "react";
import { useRightContext } from "../../../../../../context/rightMenuContext";
import { FormControlLabel, Radio, RadioGroup } from "material-ui-core";
import PricingHeader from "../../../../../../components/Pricing/PricingHeader";
import CustomizedButton from "../../../../../../components/CustomizedButton";
import {
  handleEmptyValues,
  handleFocus,
  newHandleInput,
} from "../../../../../../utility/helper";
import PricingTableComp from "../../../../../../components/PricingTableComp";
const Left = ({
  pageName,
  setLoading,
  accessToken,
  selectItemDetails,
  updateItemDetailsServices,
  handleFormReset,
  getPriceTableDataService,
  selectItemTableData,
  setSelectItemTableData,
  priceBarData,
  setPriceBarData,
}) => {
  const { timestamp, selected, setSelected, handleEdit, userDataContext } =
    useRightContext();

  const [itemId, setItemId] = useState("");

  const [selectedPricing, setSelectedPricing] = useState();
  const [selectedSupplyOnly, setSelectedSupplyOnly] = useState("");

  const [name, setName] = useState("");
  const [priceType, setPriceType] = useState("1");
  const [activeTab, setActiveTab] = useState("Lump sum");
  const [nameError, setNameError] = useState(false);

  useEffect(() => {
    console.log(selectItemDetails, "left data");

    // setLoading(false)

    setSelected(selectItemDetails?.name || "");
    setName(selectItemDetails?.name || "");
    setItemId(selectItemDetails?.id || "");
  }, [selectItemDetails]);

  useEffect(() => {
    setLoading(true);

    var dataFecth = {
      colourCollectionId: selectItemDetails?.colourCollectionId,
      colourPriceId: selectItemDetails?.id,
      priceMasterId: selectedPricing,
      markupMasterId: selectedSupplyOnly,
      colorType: priceType,
    };

    console.log(dataFecth, "dataFecth");

    setTimeout(() => {
      if (
        selectItemDetails?.colourCollectionId &&
        selectItemDetails?.id &&
        priceType &&
        selectedPricing &&
        selectedSupplyOnly
      ) {
        getPriceTableDataService(dataFecth);
      } else {
        setSelectItemTableData([]);
      }
    }, 200);

    setNameError(false);
  }, [selectItemDetails, priceType, selectedPricing, selectedSupplyOnly]);

  const handleNameChange = (e) => {
    const value = e.target.value;

    if (e.target.value?.length <= 50) {
      setName(value);
      handleEdit(value);
      setSelected(value);
    }

    if (value.length > 50) {
      setNameError(true);
    } else {
      setNameError(false);
    }
  };

  const handleActiveTab = (tab) => {
    setActiveTab(tab);
  };

  const validate = (value) => {
    if (value < 0) return false;
    return true;
  };

  const handleInput = (id, key, value) => {
    if (validate(value)) {
      setSelectItemTableData((prevData) =>
        prevData.map((item, index) =>
          index === id ? { ...item, [key]: parseFloat(value) } : item
        )
      );
    }
  };

  const handleBarData = (id, key, value) => {
    if (validate(value)) {
      setPriceBarData((prevData) =>
        prevData.map((item, index) =>
          index === id ? { ...item, [key]: parseFloat(value) } : item
        )
      );
    }
  };

  const handleFormSubmit = () => {
    // console.log(selectItemTableData, "updated json")

    const formDataValues = {
      id: selectItemDetails?.id,
      name: name,
    };

    updateItemDetailsServices(
      formDataValues,
      handleEmptyValues([...selectItemTableData, ...priceBarData])
    );
  };

  const data1 = { url: `/product/colours/collections` };

  return (
    <div className="d-flex flex-column h-100">
      <PricingHeader
        setSelectedSupplyOnly={setSelectedSupplyOnly}
        setSelectedPricing={setSelectedPricing}
        selectedPricing={selectedPricing}
        selectedSupplyOnly={selectedSupplyOnly}
      />

      <div className="d-flex align-items-center mb-4 flex-wrap gap-3 left_header">
        <h5 className="mb-0 text-wrap text-break me-4 main_title">
          {pageName}
        </h5>

        <CustomizedButton data={data1} />
      </div>

      <Form className="h-100 d-flex flex-column flex-grow-1 justify-content-between">
        <div className="inside_scroll has_btns has_head">
          <Row>
            <Col lg="6">
              <FormGroup className="form-group mb-3">
                <Label for="name">Name</Label>

                <Input
                  className="mb-1"
                  name="name"
                  value={name}
                  onChange={(e) => handleNameChange(e)}
                />

                {nameError && timestamp && itemId && (
                  <div className="mt-1 text-muted text-small">
                    Max 50 characters are allowed
                  </div>
                )}

                {!name && itemId && (
                  <div className="mt-1 text-danger text-small">
                    Name is required
                  </div>
                )}
              </FormGroup>
            </Col>

            <Col lg="12" className="mb-3">
              <div>
                <RadioGroup
                  aria-labelledby="priceType-label"
                  name="priceType"
                  className="flex-row gap-2"
                  value={priceType}
                  onChange={(e) => setPriceType(e.target.value)}
                >
                  <FormControlLabel
                    className="label_radio"
                    value="1"
                    control={<Radio />}
                    label={
                      <div className="px-4">
                        <span></span>
                        <span>External</span>
                      </div>
                    }
                  />

                  <FormControlLabel
                    className="label_radio"
                    value="2"
                    control={<Radio />}
                    label={
                      <div className="px-4">
                        <span></span>
                        <span>Internal</span>
                      </div>
                    }
                  />
                </RadioGroup>
              </div>
            </Col>

            <Col lg="12">
              <Nav tabs className="details_header border-0 nav-tabs-custom">
                <NavItem>
                  <NavLink
                    className={`${activeTab === "Lump sum" && "active"}`}
                    onClick={() => handleActiveTab("Lump sum")}
                  >
                    Lump sum
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={`${activeTab === "Percentage" && "active"}`}
                    onClick={() => handleActiveTab("Percentage")}
                  >
                    Percentage
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={`${
                      activeTab === "Price bar length" && "active"
                    }`}
                    onClick={() => handleActiveTab("Price bar length")}
                  >
                    Price bar length
                  </NavLink>
                </NavItem>
              </Nav>

              <TabContent activeTab={activeTab} className="flex-grow-1 d-flex">
                <TabPane tabId="Lump sum" className="flex-grow-1 w-100">
                  <PricingTableComp
                    data={selectItemTableData}
                    handleInput={handleInput}
                    newHandleInput={newHandleInput}
                    setData={setSelectItemTableData}
                    userDataContext={userDataContext}
                  ></PricingTableComp>
                </TabPane>

                <TabPane tabId="Percentage" className="w-100">
                  <Table responsive className="w-100 discount_table">
                    <thead className="border-bottom">
                      <tr>
                        <th></th>
                        <th>Percentage</th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectItemTableData && selectItemTableData.length > 0 ? (
                        <>
                          {selectItemTableData &&
                            selectItemTableData.map((item, index) => (
                              <tr key={index}>
                                <td>{item.colourName}</td>
                                <td>
                                  <Form.Control
                                    type="number"
                                    onFocus={handleFocus}
                                    value={
                                      item.percentage !== null
                                        ? item.percentage
                                        : 0
                                    }
                                    onChange={(e) =>
                                      handleInput(
                                        index,
                                        "percentage",
                                        e.target.value
                                      )
                                    }
                                  />
                                </td>
                              </tr>
                            ))}
                        </>
                      ) : (
                        <tr>
                          <td colSpan={7}>
                            <h5 className="my-4 text-center text-muted w-100">
                              No data found
                            </h5>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </TabPane>
                <TabPane tabId="Price bar length" className="flex-grow-1 w-100">
                  <PricingTableComp
                    data={priceBarData}
                    handleInput={handleInput}
                    newHandleInput={newHandleInput}
                    setData={setPriceBarData}
                    userDataContext={userDataContext}
                  ></PricingTableComp>
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </div>

        <div className="d-flex gap-2 gap-xl-3 main_buttons">
          <Button
            color="primary"
            size="md"
            className="px-4"
            onClick={() => handleFormSubmit()}
            disabled={!timestamp || !name.trim()}
          >
            Save Changes
          </Button>

          <Button
            color="primary"
            size="md"
            outline
            className="px-4"
            disabled={!timestamp}
            onClick={() => handleFormReset()}
          >
            Reset
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default Left;
