import axios from "axios";
import { servicePath } from "../utility/data";

export const addUpdateSettingDesigner = (options, accessToken) => {

    const formData = new FormData();
    formData.append("Id", options.id || '');
    formData.append("WebUrl", options.webUrl ? options.webUrl : "");
    formData.append("WebtestUrl", options.testUrl ? options.testUrl : "");
    formData.append("WebAnalyticJs", options.webAnalytics ? options.webAnalytics : "");
    formData.append("DesignerProductCollection", !options.specificProductCollection ? 0 : options.specificProductCollection);
    formData.append("DesignerTabIframeAddress", !options.desingTabOrIframe ? "" : options.desingTabOrIframe);
    formData.append("DesignerHomeBackground", !options.designerHomeBackground ? "" : options.designerHomeBackground);
    formData.append("DesignerIntroText", !options.introText ? "" : options.introText);
    formData.append("DesignerLastStep", !options.lastStep ? "" : options.lastStep);
    formData.append("DesignerSpecificProduct", !options.specificProduct ? 0 : options.specificProduct);
    formData.append("DesignerGetQuote", !options.getQuote ? "" : options.getQuote);
    formData.append("FrameName", options.frameInfoName);
    formData.append("FrameRefrence", options.frameInfoRef);
    formData.append("FrameComment", options.frameInfoComments);
    formData.append("FormInfo", options.formInfo);
    formData.append("ParentIframe", options.parentIframe);
    formData.append("Name", options.customerInfoName);
    formData.append("NameRequired", options.customerInfoNameReq);
    formData.append("Refrence", options.customerInfoRef);
    formData.append("Refrencerequired", options.customerInfoRefReq);
    formData.append("JobType", "");
    formData.append("JobTypeRequired", "");
    formData.append("Email", options.customerInfoEmail);
    formData.append("EmailRequired", options.customerInfoEmailReq);
    formData.append("Phone", options.customerInfoPhone);
    formData.append("PhoneRequired", options.customerInfoPhoneReq);
    formData.append("Address", options.customerInfoAdd);
    formData.append("AddressRequired", options.customerInfoAddReq);
    formData.append("PostCode", options.customerInfoPostCode);
    formData.append("PostCodeRequired", options.customerInfoPostCodeReq);
    formData.append("Comment", options.customerInfoComment);
    formData.append("CommentRequired", options.customerInfoCommentReq);
    formData.append("AcceptAggrement", options.acceptAggrement);
    formData.append("AgreementComment", options.agreementComment);
    formData.append("Thanks", options.afterSubmitThanks);
    formData.append("PrintDocumentId", !options.printDoc ? 0 : options.printDoc);
    formData.append("AllowSavePdf", options.afterSubmitSavePdf);
    formData.append("DesignerType", options.designerType);
    formData.append("EmailWhenNewEnq", options.afterSubmitEmailEnq);
    formData.append("EmailAddress", !options.afterSubmitEmailAdd ? "" : options.afterSubmitEmailAdd);
    for (let i = 0; i < options.allowedProducts.length; i++) {
        formData.append(`AllowedProduct[${i}]`, options.allowedProducts[i]);
    }

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    return axios
        .post(`${servicePath}/Designer/AddUpdateSettingDesigner`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};


export const getDesignerSetting = (desingerType, accessToken) => {
    const formData = new FormData();
    formData.append("DesignerType", desingerType);

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    return axios
        .post(`${servicePath}/Designer/GetSettingDesinger`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            return error;
        });
}
