import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Form, FormGroup, Input, Table } from "reactstrap";
import { getFramesCuttingSheetData } from "../../../../services/cuttingSheetServices";
import {
  toggleCuttingStatus,
  toggleStatus,
  transformData,
} from "../../../../utility/helper";

const Left = ({
  token,
  setMessage,
  setMessageType,
  setLoading,
  cuttingListData,
  setCuttingListData,
}) => {
  const [profileDetails, setProfileDetails] = useState([]);
  const location = useLocation();
  const navigate = useNavigate()

  const receivedData = location.state;

  useEffect(() => {
    if (
      receivedData?.quoteId &&
      receivedData?.items &&
      receivedData?.items?.length > 0
    ) {
      getCuttingListDataService(receivedData?.quoteId, receivedData?.items);
    }
  }, [receivedData]);

  useEffect(() => {
    if (
      cuttingListData &&
      cuttingListData?.profileDetails &&
      cuttingListData?.profileDetails?.length > 0
    ) {
      setProfileDetails(transformData(cuttingListData?.profileDetails));
    }
  }, [cuttingListData]);

  const getCuttingListDataService = async (quoteId, frameStyles) => {
    setLoading(true);
    const res = await getFramesCuttingSheetData(token, quoteId, frameStyles);
    if (res?.data?.statusCode == 200) {
      setCuttingListData(res?.data?.entity);

      console.log(res?.data?.entity, "cutting list data")

      setLoading(false);
      setMessageType("success");
      setMessage(
        res?.data?.message ? res?.data?.message : "Fetched data successfully !"
      );
    } else {
      setLoading(false);
      setMessageType("error");
      setMessage(res?.data?.message);

      if (res?.response?.status === 401) {
        navigate('/unauthorized')

        localStorage.removeItem("access_token")
      }

      if (res?.response?.status == 404) {
        navigate('/error/503')
      }

      if (res?.message === "Network Error") {
        navigate('/error/network')
      }

    }
  };

  const changeStatus = (detail) => {
    cuttingListData?.profileDetails?.forEach((item) => {
      Object.values(item)?.forEach((product) => {
        if (Array.isArray(product)) {
          product?.forEach((ele) => {
            if (ele?.uniqueKey === detail?.uniqueKey) {
              // This function will show the status changed
              setProfileDetails(
                toggleStatus(profileDetails, detail?.uniqueKey)
              );
              // This function will made changes in the original data and this is used while saving the status
              setCuttingListData((prevData) => ({
                ...prevData,
                profileDetails: toggleCuttingStatus(
                  cuttingListData?.profileDetails,
                  detail?.uniqueKey
                ),
              }));
            }
          });
        }
      });
    });
  };

  // console.log("asa", cuttingListData);

  return (
    <div>
      <div className="d-flex align-items-center justify-content-between">
        <h4 className="fw-bold">{`Project: ${cuttingListData?.projectName}`}</h4>
        <div>
          <div className="d-flex fw-bold">
            <span className="label-width">Job Number: </span>
            <span className="ms-2">{`${cuttingListData?.jobNumber}`}</span>
          </div>
          <div className="d-flex fw-bold">
            <span className="label-width">Person in charge: </span>
            <span className="ms-2">{`${cuttingListData?.personInCharge}`}</span>
          </div>
        </div>
      </div>
      <Form className=" mt-3 h-100 d-flex flex-column flex-grow-1 justify-content-between">
        <div className="inside_scroll has_tab has_btns">
          <Table responsive className="w-100" striped>
            <thead className="border-bottom border-top">
              <tr className="text-end">
                <th>Position</th>
                <th>Seq.No.</th>
                <th>Quantity</th>
                <th>Cutting Length</th>
                <th>Orientation</th>
                <th>Cut</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {profileDetails &&
                profileDetails?.map((item, index) => (
                  <React.Fragment key={index}>
                    <tr>
                      <td colSpan="7">
                        <div className="mt-2"></div>
                      </td>
                    </tr>
                    <tr className="align-middle">
                      <td colSpan="7" className="bg-white">
                        <div className="d-flex align-items-center">
                          <img
                            src={item.image}
                            alt="Icon"
                            style={{ maxWidth: "80px", marginRight: "10px" }}
                          />
                          <div>
                            <div>
                              <strong>{item.name}</strong>
                            </div>
                            <div>{item.description}</div>
                          </div>
                        </div>
                      </td>
                    </tr>
                    {item?.details?.map((detail, detailIndex) => (
                      <tr
                        key={detailIndex}
                        className={`align-middle ${detailIndex === item.details.length - 1
                          ? "border-bottom border-3 pb-3"
                          : ""
                          }`}
                      >
                        <td>
                          {(index + 1) > 99 ? `${index + 1}` : (index + 1) > 9 ? `0${index + 1}` : `00${index + 1}`}
                        </td>
                        <td>{detail.seqNo}</td>
                        <td>{detail.quantity}</td>
                        <td>{detail.cuttingLength}</td>
                        <td>{detail.orientation}</td>
                        <td>{detail.cut}</td>
                        <td>
                          <FormGroup className="form-group mb-3" check>
                            <Input
                              type="checkbox"
                              name="nameCheck"
                              id="radioComments1"
                              checked={detail?.status}
                              onChange={() => {
                                changeStatus(detail);
                              }}
                            />
                          </FormGroup>
                        </td>
                      </tr>
                    ))}
                  </React.Fragment>
                ))}
            </tbody>
          </Table>
        </div>
      </Form>
    </div>
  );
};

export default Left;
