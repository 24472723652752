import React, { useState, useEffect } from 'react'
import { Form } from 'react-bootstrap';
import { MdOutlineKeyboardArrowDown } from 'react-icons/md';
import { MenuItem, OutlinedInput, Select } from 'material-ui-core';
import {
    Row,
    Col,
    Button,
    FormGroup,
} from 'reactstrap';
import { MenuProps, noSelectOptions } from '../utility/data';
import { useRightContext } from '../context/rightMenuContext';
import { GetProfileMastersForSystem } from '../services/commonServices';
import DualListHideShowSystems from './DualListHideShowSystems';
import { SetDefaultProductProfile, UpdateFrameProfileOrder, addFrameProductProfiles, dualListItemMoveToLeft, getAllHiddenVisibleProfileItems } from '../services/sysFrameProductsService';
import { useMessage } from '../context/messageContext';

const SystemProfile = ({ isEditable, navigate, setLoading, activeTab, accessToken, selectItemDetails, updateItemDetailsServices, handleFormReset }) => {

    const { timestamp } = useRightContext()
    const { setMessage, setMessageType } = useMessage()

    const [profileOptions, setProfileOptions] = useState([]);

    const [itemId, setItemId] = useState('')
    const [profileId, setProfileId] = useState("")

    const [hiddenItems, setHiddenItems] = useState([])
    const [visibleItems, setVisibleItems] = useState([])
    const [selectedHiddenIndex, setSelectedHiddenIndex] = useState(0);
    const [selectedVisibleIndex, setSelectedVisibleIndex] = useState(0);
    const [visibleData, setVisibleData] = useState('')

    useEffect(() => {
        if (activeTab === "Profiles") {
            getProfileMasterForSys()
        }
    }, [activeTab])

    useEffect(() => {
        if (profileId) {
            getHiddenVisibleListService()
        }
    }, [profileId, selectItemDetails])

    // setting all the value for prefilled inputs
    useEffect(() => {
        // console.log(selectItemDetails, "data left")

        setItemId(selectItemDetails?.id || '')
    }, [selectItemDetails])

    useEffect(() => {
        if (profileOptions && profileOptions.length > 0) {
            setProfileId(profileOptions[0]?.id)
        }
        // setProfileId(selectItemDetails?.profileId || profileOptions[0]?.id)
    }, [profileOptions])


    // get product color collection
    const getProfileMasterForSys = async () => {
        const res = await GetProfileMastersForSystem(accessToken, 1)

        if (res && res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                if (res?.data?.entity !== null) {
                    if (res?.data?.entity?.length > 0) {
                        setProfileOptions(res?.data?.entity)
                    } else {
                        setProfileOptions(noSelectOptions)
                    }
                }
            }
        }
    }

    const getHiddenVisibleListService = async () => {
        setLoading(true)

        const res = await getAllHiddenVisibleProfileItems(accessToken, profileId, selectItemDetails?.collectionId, timestamp)
        if (res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                setHiddenItems(res?.data?.entity?.hiddenProfiles)
                setVisibleItems(res?.data?.entity?.visibleProfiles)
                setLoading(false)
            }
        } else {
            setLoading(false)
        }
    }

    const addExternalProductService = async (itemID) => {
        setLoading(true)
        const res = await addFrameProductProfiles(accessToken, profileId, selectItemDetails?.collectionId, timestamp, itemID)
        if (res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                setMessage(res?.data?.message ? res.data.message : 'Set to visible')
                setMessageType('success')
                setLoading(false)
                getHiddenVisibleListService()

            } else {
                setLoading(false)
                setMessage(res?.data?.message ? res.data.message : 'Something went wrong');
                setMessageType('error')
                getHiddenVisibleListService()
            }
        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')
            if (res?.message === "Network Error") {
                navigate('/error/network')
                setLoading(false)
            }
        }
    }

    const dualListItemMoveToLeftServices = async (itemId) => {

        setLoading(true)

        const res = await dualListItemMoveToLeft(accessToken, itemId, "Profiles")
        if (res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                setMessage(res?.data?.message ? res.data.message : 'Set to hidden');
                setMessageType('success')
                getHiddenVisibleListService()
            } else {
                setMessage(res?.data?.message ? res.data.message : 'Something went wrong');
                setMessageType('error')
                getHiddenVisibleListService()
            }
        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')
            if (res?.message === "Network Error") {
                navigate('/error/network')
                setLoading(false)
            }
        }
    }

    const setDefaultItemService = async (itemId) => {
        setLoading(true)

        const res = await SetDefaultProductProfile(accessToken, itemId)

        if (res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                setMessage(res?.data?.message ? res.data.message : "Set to default")
                setMessageType('success')
                getHiddenVisibleListService()
            } else {
                setLoading(false)
                setMessage(res?.message ? res.message : 'Something went wrong');
                setMessageType('error')
            }
        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')
            if (res?.message === "Network Error") {
                navigate('/error/network')
                setLoading(false)
            }
        }
    }

    const updateHiddenVisibleOrder = async (direction) => {
        setLoading(true)

        const res = await UpdateFrameProfileOrder(accessToken, visibleData.id, direction, timestamp, profileId)

        if (res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                // setMessage(res?.data?.message ? res.data.message : "Set to default")
                // setMessageType('success')
                getHiddenVisibleListService()
            } else {
                setLoading(false)
                setMessage(res?.message ? res.message : 'Something went wrong');
                setMessageType('error')
            }
        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')
            if (res?.message === "Network Error") {
                navigate('/error/network')
                setLoading(false)
            }
        }
    }

    const defaultServices = async () => {
        if (visibleData && visibleData.id) {
            setDefaultItemService(visibleData.id)
        }
    }

    const updateOrder = async (direction) => {
        updateHiddenVisibleOrder(direction)
        
        if(direction === "UP"){
            setSelectedVisibleIndex(selectedVisibleIndex - 1)
        } else {
            setSelectedVisibleIndex(selectedVisibleIndex + 1)
        }
    }

    const handleFormSubmit = () => {

        var formDataValues = {
            'profileId': profileId,

            'id': selectItemDetails?.id,
            'collectionId': selectItemDetails?.collectionId,
            'name': selectItemDetails?.name,
            'description': selectItemDetails?.description,
            'colourId': selectItemDetails?.colourId,
            'frameStyleId': selectItemDetails?.frameStyleId,
            'jointsId': selectItemDetails?.jointsId,
            'defaultTransomDrop': selectItemDetails?.defaultTransomDrop,
            'defaultSideHungWidth': selectItemDetails?.defaultSideHungWidth,
            'defaultSill': selectItemDetails?.defaultSill,
            'defaultWindow': selectItemDetails?.defaultWindow,

            'specifications': selectItemDetails?.specifications,
            'defaultSpecifications': selectItemDetails?.defaultSpecificationId,
            'frameClearance': selectItemDetails?.frameClearance,
            'gasketClearance': selectItemDetails?.gasketClearance,
            'beadRadio': selectItemDetails?.bead,

            'subProductId': selectItemDetails?.subProductId,
            'hardwareId': selectItemDetails?.hardwareId,
        }

        updateItemDetailsServices(formDataValues, "Profile")
    }

    return (
        <Form className='h-100 d-flex flex-column flex-grow-1 justify-content-between'>
            <div className='inside_scroll has_tab has_btns has_head'>
                <Row>
                    <Col xxl="12">
                        <Row>
                            <Col lg="6">
                                <FormGroup className="form-group">
                                    <Select
                                        labelId="profileSelect"
                                        id="profileSelect"
                                        value={profileId || ''}
                                        label="profileSelect"
                                        onChange={(e) => { setProfileId(e.target.value) }}
                                        input={<OutlinedInput className='w-100' name='profileSelect' />}

                                        MenuProps={MenuProps}

                                        IconComponent={() => (
                                            <MdOutlineKeyboardArrowDown size={20} className='me-1 MuiSvgIcon-root MuiSelect-icon' />
                                        )}
                                    >
                                        {profileOptions && profileOptions.map((o, index) => (
                                            <MenuItem key={index} value={o?.id}>
                                                {o?.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormGroup>
                            </Col>

                            <Col lg="12" className='mb-5'>
                                <DualListHideShowSystems isSelf={isEditable} visibleItems={visibleItems} hiddenItems={hiddenItems} moveToRight={addExternalProductService} moveToLeft={dualListItemMoveToLeftServices} selectedHiddenIndex={selectedHiddenIndex} selectedVisibleIndex={selectedVisibleIndex} setSelectedVisibleIndex={setSelectedVisibleIndex} setSelectedHiddenIndex={setSelectedHiddenIndex} setVisibleData={setVisibleData} visibleData={visibleData} setDefaultItem={defaultServices} updateOrder={updateOrder} />
                            </Col>

                            {/* <Col lg="12" className='mb-5'>
                                <h4 className='main_title sub_title mb-3'>Cross section</h4>
                                <div className="d-flex gap-2 align-items-center flex-nowrap">
                                    <span className="css-502">Inside</span>
                                    <svg width="200" height="140"><g strokeWidth="1" transform="scale(2,2)"><g fill="rgba(73,72,71,0.2)" stroke="rgba(73,72,71,0.6)"><path d="M 0,70 0,30 7.5,30 7.5,50 92.5,50 92.5,30 100,30 100,70 Z "></path></g><path d="M 55,0 59,0 59,50 55,50 Z M 81,0 85,0 85,50 81,50 Z" fill="rgba(0,120,212,0.2)" stroke="rgba(0,120,212,0.6)"></path><path d="M 59,40 81,40 81,50 59,50 Z" fill="rgba(73,72,71,0.4)" stroke="rgba(73,72,71,0.8)"></path></g></svg>
                                    <span className="css-502">Outside</span>
                                </div>
                            </Col> */}
                        </Row>
                    </Col>
                </Row>
            </div>

            {isEditable && (
                <div className='d-flex gap-2 gap-xl-3 main_buttons'>
                    <Button
                        color="primary"
                        size="md"
                        className='px-4'
                        onClick={() => handleFormSubmit()}
                        disabled={!timestamp || !itemId}
                    >
                        Save Changes
                    </Button>

                    <Button
                        color="primary"
                        size="md"
                        outline
                        className='px-4'
                        onClick={() => handleFormReset()}
                        disabled={!timestamp}
                    >
                        Reset
                    </Button>
                </div>
            )}
        </Form>
    )
}

export default SystemProfile