import React from 'react'
import { Image } from 'react-bootstrap'
import mainLogo from '../../../../assets/img/logo/logo.png'
import maintenanceImg from '../../../../assets/img/maintenance.svg'
import ErrorBanner from '../../../../assets/img/error-img.svg'
import { srcLoaderCondition } from '../../../../services/commonServices'
import { useRightContext } from '../../../../context/rightMenuContext'

const ErrorLayout = ({ code, text, subText, layout, pageType, subText2 }) => {

    const userData = JSON.parse(localStorage.getItem("user_data"))

    const { userDataContext } = useRightContext('')
    
    return (
        <>
            <div className={`account-pages error_pages ${pageType === 'full_cover' ? 'full_cover' : pageType}`}>
                <div className="row w-100">
                    <div className="col-lg-12">

                        <div className="text-center mb-3">
                            {layout === 'layout-2' ? (
                                <>
                                    {/* maintenance page */}
                                    <div className="d-flex flex-column justify-content-center">
                                        <div className={`d-flex justify-content-center mb-4 ${pageType === 'inside_content' ? 'd-none' : ''}`}>
                                            <Image src={srcLoaderCondition(userDataContext, mainLogo)} alt="" className="mt-0 logo_large m-0" />
                                        </div>

                                        <div className={`banner_img maintenance_img ${text === "Processing" ? "processing_img" : ''}`}>
                                            <Image src={code ? code : maintenanceImg} alt=""
                                                className="img-fluid mx-auto d-block" />
                                        </div>
                                    </div>
                                    <h4>{text}</h4>

                                    <p className='max-w-400 mx-auto text-muted mb-0'>{subText}</p>

                                    {subText2 && (
                                        <p className='max-w-400 mx-auto text-muted'>{subText2}</p>
                                    )}
                                </>
                            ) : (
                                <>
                                    {/* all other error pages */}
                                    {code ? (
                                        <>
                                            {code}

                                            <h4>{text}</h4>
                                        </>
                                    ) : (
                                        <>
                                            <div className="d-flex justify-content-center mb-3">
                                                <Image src={mainLogo} alt="" className="mt-0 ms-2 ms-lg-3 logo_large" />
                                            </div>

                                            <h4 className='display-4'>{text}</h4>
                                        </>
                                    )}

                                    <p className='max-w-400 mx-auto text-muted mb-0'>{subText}</p>

                                    {subText2 && (
                                        <p className='max-w-400 mx-auto text-muted'>{subText2}</p>
                                    )}

                                    {/* <div className="mt-5 text-center">
                                        <a className="btn btn-primary waves-effect waves-light" href="/">Back to Quotations</a>
                                    </div> */}
                                </>
                            )}
                        </div>
                    </div>
                </div>

                {/*all other error pages except maintenance*/}
                {layout !== 'layout-2' && (
                    <div className="d-flex justify-content-center">
                        <div className="banner_img">
                            <Image src={ErrorBanner} alt="banner" />
                        </div>
                    </div>
                )}
            </div >
        </>
    )
}

export default ErrorLayout