import React from 'react'
import InnerLayout from "../../../../layouts/internalLayout"
import DummyLeft from './dummyleft'
import DummyRight from './dummyright'

const Dummy = () => {
  return (
    <>
        <InnerLayout pageTitle="Dummy page" layoutType="" leftContent ={<DummyLeft/> } rightContent ={<DummyRight/> }  />
    </>
  )
}

export default Dummy