import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import {
  Button,
  Col,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Row,
} from "reactstrap";
import NumberInputDecimal from "../../../../../../components/NumberInputDecimal";
import { Link, useNavigate } from "react-router-dom";
import { MdOutlineModeEdit } from "react-icons/md";
import { BiSort, BiTrashAlt } from "react-icons/bi";
import DataTable from "react-data-table-component";
import {
  addOversizeGlazingService,
  deleteOverSizeGlazingService,
  getAllOversizeGlazingService,
  getOverSizeGlazingIndividualService,
} from "../../../../../../services/oversizeGlazingServices";

const OverSizeLeft = ({ token, setMessage, setMessageType, setLoading }) => {
  const [minimumArea, setMinimumArea] = useState(0);
  const [maximumArea, setMaximumArea] = useState(0);
  const [percentage, setPercentage] = useState(0);
  const [allData, setAllData] = useState([]);
  const [error, setError] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deletedId, setDeletedId] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);
  const [overSizeGlazingId, setOverSizeGlazingId] = useState("");
  const [individualGlazingId, setIndividualGlazingId] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    getAllOverSizingGlazingData();
  }, []);

  useEffect(() => {
    if (minimumArea >= maximumArea) {
      setError(true);
    } else {
      setError(false);
    }
  }, [minimumArea, maximumArea]);

  useEffect(() => {
    if (overSizeGlazingId !== "") {
      getOverSizeGlazingIndividual(overSizeGlazingId);
    }
  }, [overSizeGlazingId]);

  const getAllOverSizingGlazingData = async () => {
    setLoading(true);
    const res = await getAllOversizeGlazingService(token);
    if (res?.data?.statusCode === 200) {
      setAllData(res?.data?.entity);
      setLoading(false);
    } else if (res?.data?.statusCode === 404) {
      setLoading(false);
      setAllData([]);
      navigate("/error/404");
    } else {
      setLoading(false);
      setAllData([]);
      setMessage(
        res?.data?.message ? res.data.message : "Something went wrong"
      );
      setMessageType("error");
    }
  };

  const columns = [
    {
      name: <span>Minimum Area</span>,
      selector: (row) => row.minimumArea,
    },
    {
      name: <span>Maximum Area</span>,
      selector: (row) => row.maximumArea,
    },
    {
      name: <span>Percentage</span>,
      selector: (row) => row.percentage,
    },
    {
      name: <span>Action</span>,
      cell: (row) => (
        <div className="d-flex gap-2">
          <Link onClick={() => setOverSizeGlazingId(row?.id)}>
            <MdOutlineModeEdit size={20} />
          </Link>

          <Link>
            <BiTrashAlt
              size={20}
              onClick={() => {
                setShowDeleteModal(true);
                setDeletedId(row);
              }}
            />
          </Link>
        </div>
      ),
    },
  ];

  const getDataMapping = allData?.map((item) => {
    return {
      minimumArea: item?.minValue,
      maximumArea: item?.maxValue,
      percentage: item?.percentage,
      id: item?.id,
    };
  });

  const addUpdateOversizeGlazing = async () => {
    setLoading(true);
    let id = "";
    if (individualGlazingId !== "") {
      id = individualGlazingId;
    }
    const data = {
      id: id,
      minArea: minimumArea,
      maxArea: maximumArea,
      percentage: percentage,
    };
    const res = await addOversizeGlazingService(token, data);
    if (res?.data?.statusCode === 200) {
      setLoading(false);
      setMessageType("success");
      setMessage(res?.data?.message);
      getAllOverSizingGlazingData();
      setMinimumArea(0);
      setMaximumArea(0);
      setPercentage(0);
      setOverSizeGlazingId("");
      setIndividualGlazingId("");
    } else if (res?.data?.statusCode === 404) {
      setLoading(false);
      navigate("/error/404");
    } else {
      setLoading(false);
      setMessage(
        res?.data?.message ? res.data.message : "Something went wrong"
      );
      setMessageType("error");
    }
  };

  const getOverSizeGlazingIndividual = async (id) => {
    setLoading(true);
    const res = await getOverSizeGlazingIndividualService(token, id);
    if (res?.data?.statusCode === 200) {
      setMaximumArea(res?.data?.entity?.maxValue);
      setMinimumArea(res?.data?.entity?.minValue);
      setPercentage(res?.data?.entity?.percentage);
      setIndividualGlazingId(res?.data?.entity?.id);
      setLoading(false);
    } else if (res?.data?.statusCode === 404) {
      setLoading(false);
      navigate("/error/404");
    } else {
      setLoading(false);
      setMessage(
        res?.data?.message ? res.data.message : "Something went wrong"
      );
      setMessageType("error");
    }
  };

  const deleteOverSizeGlazing = async (id) => {
    setLoading(true);
    setIsDeleting(true);
    const res = await deleteOverSizeGlazingService(id, token);
    if (res?.data?.statusCode === 200) {
      setLoading(false);
      setMessageType("success");
      setMessage(res?.data?.message);
      getAllOverSizingGlazingData();
      setShowDeleteModal(false);
      setIsDeleting(false);
      setIndividualGlazingId("");
    } else if (res?.data?.statusCode === 404) {
      setLoading(false);
      navigate("/error/404");
      setIsDeleting(false);
    } else {
      setLoading(false);
      setMessage(
        res?.data?.message ? res.data.message : "Something went wrong"
      );
      setIsDeleting(false);
      setMessageType("error");
    }
  };

  return (
    <div className="d-flex flex-column h-100">
      <Form className="h-100 d-flex flex-column flex-grow-1 justify-content-between">
        <div className="inside_scroll has_btns has_head">
          <Row>
            <Col lg="6">
              <FormGroup className="form-group">
                <Label for="minimum">Minimum Area (mm²)</Label>

                <NumberInputDecimal
                  name="minimum"
                  value={minimumArea}
                  setValue={setMinimumArea}
                />

                {error && (
                  <div className="mt-1 text-danger text-small">
                    Minimum Area should be less than maximum area.
                  </div>
                )}
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup className="form-group">
                <Label for="maximum">Maximum Area (mm²)</Label>

                <NumberInputDecimal
                  name="maximum"
                  value={maximumArea}
                  setValue={setMaximumArea}
                />
                {error && (
                  <div className="mt-1 text-danger text-small">
                    Maximum Area should be greater than minimum area.
                  </div>
                )}
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup className="form-group">
                <Label for="percentage">Percentage</Label>

                <NumberInputDecimal
                  name="percentage"
                  value={percentage}
                  setValue={setPercentage}
                />
              </FormGroup>
            </Col>
          </Row>
          <div className="d-flex gap-2 gap-xl-3 main_buttons mb-2">
            <Button
              color="primary"
              size="md"
              className="px-4"
              onClick={() => {
                addUpdateOversizeGlazing();
              }}
              disabled={error}
            >
              {individualGlazingId === "" ? "Add" : "Update"}
            </Button>
          </div>
          <div className="d-flex flex-column flex-grow-1 justify-content-between">
              <DataTable
                columns={columns}
                data={getDataMapping}
                className="custom_datatable clickable"
                sortIcon={<BiSort size={18} className="text-muted" />}
                highlightOnHover
                pagination
                paginationPerPage={10}
                paginationRowsPerPageOptions={[5, 10, 15, 20, 30]}
              />
          </div>
        </div>
      </Form>
      <Modal isOpen={showDeleteModal} toggle={() => setShowDeleteModal(false)}>
        <ModalHeader>Delete Selected Item</ModalHeader>
        <ModalBody>
          Are you sure you want delete the{" "}
          <strong>
            {deletedId?.minimumArea} - {deletedId?.maximumArea}
          </strong>{" "}
          slab ?
        </ModalBody>
        <ModalFooter>
          <Button
            color="outline-danger"
            size="md"
            disabled={isDeleting}
            onClick={() => deleteOverSizeGlazing(deletedId?.id)}
          >
            Delete
          </Button>
          <Button
            onClick={() => setShowDeleteModal(false)}
            color="primary"
            size="md"
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default OverSizeLeft;
