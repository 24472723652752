import React, { useState } from "react";
import PricingHeader from "../../../../../components/Pricing/PricingHeader";
import { useNavigate } from "react-router-dom";
import { Button, Nav, NavLink, TabContent, TabPane } from "reactstrap";
import { Form, NavItem, Table } from "react-bootstrap";
import axios from "axios";
import { servicePath } from "../../../../../utility/data";
import { useEffect } from "react";
import {
  handleEmptyValues,
  newHandleInput,
} from "../../../../../utility/helper";
import { useRightContext } from "../../../../../context/rightMenuContext";
import PricingTableComp from "../../../../../components/PricingTableComp";

const Left = ({
  token,
  profileId,
  pageName,
  setMessage,
  setMessageType,
  setLoading,
}) => {
  const navigate = useNavigate();
  const { userDataContext } = useRightContext();

  const [activeTab, setActiveTab] = useState("Lump sum");

  const [selectedPricing, setSelectedPricing] = useState("");
  const [selectedSupplyOnly, setSelectedSupplyOnly] = useState("");

  const [costPrice, setCostPrice] = useState([]);
  // const [data, setData] = useState({
  //   linearMetre: [],
  //   lumpSum: [],
  // });
  const [lumpSumData, setLumpSumData] = useState([]);
  const [linearMetreData, setLinearMetreData] = useState([]);

  useEffect(() => {
    setLoading(true);

    if (profileId && selectedPricing && selectedSupplyOnly) {
      getPricingData(profileId);
    }
  }, [profileId, selectedPricing, selectedSupplyOnly]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const getPricingData = (profileID) => {
    const FormData = require("form-data");

    let data = new FormData();

    data.append("PriceMasterId", selectedPricing);
    data.append("Markupmasterid", selectedSupplyOnly);
    data.append("ProfileMasterId", profileID);

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${servicePath}/General/GetProfilePricing`,
      headers: {
        accept: "text/plain",
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
      data: data,
    };
    axios
      .request(config)
      .then((res) => {
        if (res?.data?.statusCode === 200) {
          setLoading(false);
          // setData(res?.data?.entity)
          setLumpSumData(res?.data?.entity["lumpSum"]);
          setLinearMetreData(res?.data?.entity["linearMetre"]);
          // console.log(data, "data")
        } else if (res?.data?.statusCode === 404) {
          setLoading(false);
          navigate("/error/404");
        } else {
          setLoading(false);
          setMessage(
            res?.data?.message ? res.data.message : "Something went wrong"
          );
          setMessageType("error");
        }
      })
      .catch((error) => {
        console.log(error);

        setLoading(false);
        setMessage(error.message ? error.message : "Something went wrong");
        setMessageType("error");

        if (error?.message === "Network Error") {
          navigate("/error/network");
        }
      });
  };

  // const handleInput = (setData,id, key, value) => {
  //   if (validate(value)) {
  //     setData((prevData) => {
  //       if (activeTab === "Lump sum" && prevData.lumpSum) {
  //         return {
  //           ...prevData,
  //           lumpSum: prevData.lumpSum.map((item, index) =>
  //             index === id ? { ...item, [key]: parseFloat(value) } : item
  //           ),
  //         };
  //       }
  //       if (activeTab === "Linear" && prevData.linearMetre) {
  //         return {
  //           ...prevData,
  //           linearMetre: prevData.linearMetre.map((item, index) =>
  //             index === id ? { ...item, [key]: parseFloat(value) } : item
  //           ),
  //         };
  //       }
  //       return prevData;
  //     });
  //   }
  // }

  const handleInput = (setMethod, id, key, value) => {
    if (validate(value)) {
      setMethod((prevData) =>
        prevData.map((item, index) =>
          index === id ? { ...item, [key]: parseFloat(value) } : item
        )
      );
    }
  };

  const validate = (value) => {
    if (value < 0) return false;
    return true;
  };

  const handleUpdate = () => {
    setLoading(true);
    updateTableData();
  };

  const updateTableData = () => {
    if (!lumpSumData || !linearMetreData) {
      console.error("Data is null.");
      return;
    }

    const mergedData = [...lumpSumData, ...linearMetreData];
    console.log("Updated data", mergedData);

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${servicePath}/General/AddUpdateProfilePricing`,
      headers: {
        accept: "text/plain",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: handleEmptyValues(mergedData),
    };

    axios
      .request(config)
      .then((res) => {
        console.log(res.data, "UpdatedProjectAncillaries");

        if (res?.data?.statusCode === 200) {
          setMessage(res.data.message);
          setMessageType("success");
          getPricingData(profileId);
        }
      })
      .catch((error) => {
        console.log(error);

        setLoading(false);
        setMessage(error.message ? error.message : "Something went wrong");
        setMessageType("error");

        if (error?.message === "Network Error") {
          navigate("/error/network");
        }
      });
  };

  const handleFormReset = () => {
    setLoading(true);
    getPricingData(profileId);
  };

  // const data1 = { url: `/product/profiles/${profileId}` };

  return (
    <>
      <PricingHeader
        setSelectedSupplyOnly={setSelectedSupplyOnly}
        setSelectedPricing={setSelectedPricing}
        selectedPricing={selectedPricing}
        selectedSupplyOnly={selectedSupplyOnly}
        costPrice={costPrice}
      />
      <div className="d-flex align-items-center mb-4 flex-wrap gap-3 left_header">
        <h5 className="mb-0 text-wrap text-break me-4 main_title">
          {pageName}
        </h5>
        {/* <CustomizedButton data= {data1}/> */}
      </div>
      <Nav tabs className="details_header border-0 nav-tabs-custom">
        <NavItem>
          <NavLink
            className={`${activeTab === "Lump sum" ? "active" : "text-muted"}`}
            onClick={() => handleTabChange("Lump sum")}
          >
            Lump sum
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={`${activeTab === "Linear" ? "active" : "text-muted"}`}
            onClick={() => handleTabChange("Linear")}
          >
            Linear metre
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab} className="flex-grow-1 d-flex">
        <TabPane tabId="Lump sum" className="flex-grow-1 w-100">
          <Form className="h-100 d-flex flex-column flex-grow-1 justify-content-between">
            <div className="inside_scroll has_tab has_btns">
              <PricingTableComp
                handleInput={handleInput}
                data={lumpSumData}
                newHandleInput={newHandleInput}
                setData={setLumpSumData}
                userDataContext={userDataContext}
              />
            </div>
            <div className="d-flex gap-2 gap-xl-3 main_buttons">
              <Button
                color="primary"
                size="md"
                className="px-4"
                onClick={handleUpdate}
              >
                Save Changes
              </Button>

              <Button
                color="primary"
                size="md"
                outline
                className="px-4"
                onClick={handleFormReset}
              >
                Reset
              </Button>
            </div>
          </Form>
        </TabPane>
        <TabPane tabId="Linear" className="flex-grow-1 w-100">
          <Form className="h-100 d-flex flex-column flex-grow-1 justify-content-between">
            <div className="inside_scroll has_tab has_btns">
              <PricingTableComp
                handleInput={handleInput}
                data={linearMetreData}
                newHandleInput={newHandleInput}
                setData={setLinearMetreData}
                userDataContext={userDataContext}
              />
            </div>
            <div className="d-flex gap-2 gap-xl-3 main_buttons">
              <Button
                color="primary"
                size="md"
                className="px-4"
                onClick={handleUpdate}
              >
                Save Changes
              </Button>

              <Button
                color="primary"
                size="md"
                outline
                className="px-4"
                onClick={handleFormReset}
              >
                Reset
              </Button>
            </div>
          </Form>
        </TabPane>
      </TabContent>
    </>
  );
};

export default Left;
