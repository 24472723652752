import React, { useState } from 'react'
import InnerLayout from "../../../../../layouts/internalLayout"
import Left from './left'
import { useMessage } from '../../../../../context/messageContext'
import { Oval } from 'react-loader-spinner'
import { Button } from 'reactstrap'
import { useRightContext } from '../../../../../context/rightMenuContext'
import { Link } from 'react-router-dom'
import { BiArrowBack } from 'react-icons/bi'

const CreateQuotations = () => {

  const { userDataContext } = useRightContext('')
  // var userData = JSON.parse(localStorage.getItem("user_data"))

  const accessToken = localStorage.getItem('access_token')

  const { setMessage, setMessageType } = useMessage();
  const [loading, setLoading] = useState(false);

  return (
    <>
      {loading && (
        <div className="loader_main full_loader">
          <Oval
            height={50}
            width={50}
            color="#44C8F5"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#44C8F5"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      )}
      <InnerLayout
        pageTitle=""
        layoutType="full"
        filterButton={
          <div className="d-flex align-items-center gap-3">
            <Link to="/" className='btn btn-primary d-flex align-items-center gap-1 fw-bold back_btn'>
              <BiArrowBack size={22} />
              Back
            </Link>

            <h3 className='mb-0 text-nowrap'>Create Quotation</h3>
          </div>
        }
        leftContent={
          <Left
            accessToken={accessToken}
            setMessage={setMessage}
            setMessageType={setMessageType}
            userData={userDataContext}
            setLoading={setLoading}
          />
        }
        rightContent=''
      />
    </>
  );
}

export default CreateQuotations