import React from 'react'
import InnerLayout from "../../../../../layouts/internalLayout"
import Left from './left'
import { useState } from 'react'
import { Oval } from 'react-loader-spinner'


const SetupModel = () => {
    const [loading, setLoading] = useState(false)
    const accessToken = localStorage.getItem('access_token')
    return (
        <>
            {loading && (
                <div className='loader_main full_loader'>
                    <Oval
                        height={50}
                        width={50}
                        color="#44C8F5"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel='oval-loading'
                        secondaryColor="#44C8F5"
                        strokeWidth={2}
                        strokeWidthSecondary={2}
                    />
                </div>
            )}
            <InnerLayout pageTitle="Setup model" layoutType="full" leftContent={<Left setLoading={setLoading} accessToken={accessToken}/>} rightContent='' />
        </>
    )
}

export default SetupModel