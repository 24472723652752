import React from 'react';
import { Row, Col, Nav } from 'reactstrap';

const DummyLeft = () => {

  return (
    <div className="d-flex flex-column h-100">
      <Nav tabs className="details_header border-0 nav-tabs-custom d-flex justify-content-center">
        <button className="btn btn-primary">
          Generate PDF
        </button>
      </Nav>
      <div>
        <div>
          <h2>Receipt #</h2>
        </div>

        <div className="intro-table my-5 border-top">
          <Row className="d-flex justify-content-between">
            <Col>
              <div className="intro-form intro-form-item">
                <p className="intro-table-title">Billed To:</p>
                <p>
                  Company Ltd.
                  <br />
                  Address
                  <br />
                  Country
                  <br />
                  VAT ID: ATU12345678
                </p>
              </div>
            </Col>
            <Col className="d-flex justify-content-end">
              <div className="intro-form">
                <div className="intro-form-item">
                  <p className="intro-table-title">Payment Date:</p>
                  <p>November 22nd 2021</p>
                </div>

                <div className="intro-form-item">
                  <p className="intro-table-title">Payment Method:</p>
                  <p>Bank Transfer</p>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default DummyLeft;
