import React from "react";
import { Image } from "react-bootstrap";

const DynamicIcon = ({ icon }) => {

    return (
        <span className="menu_icon">
            <Image src={icon} alt="icon" />
        </span>
    );
}

export default DynamicIcon;