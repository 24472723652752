import React from 'react'
import ErrorLayout from './error-layout'
import { BiSolidBuoy } from 'react-icons/bi'

const Error401 = () => {
    return (
        <>
            <ErrorLayout 
                code={<h1 className="display-1 fw-medium">4<BiSolidBuoy className='bx-spin text-primary display-3' />1</h1>} 
                text="Unauthorized"
                subText="You are not authorized to access this page."
                subText2="Go back to Glazingo to access again."
                layout=""
                pageType="full_cover"
            />
        </>
    )
}

export default Error401