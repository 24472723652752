import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, NavLink } from 'reactstrap';
import { useRightContext } from '../../../../../context/rightMenuContext';
import { useMessage } from '../../../../../context/messageContext';
import { deleteRightItem, updateOrder } from '../../../../../services/productProfileServiceV';
import { Image } from 'react-bootstrap';
import { copyItem, itemVisibility } from '../../../../../services/commonServices';
import { GoCircleSlash } from 'react-icons/go';
import { BiPlus, BiTrashAlt } from 'react-icons/bi';
import { MdArrowDownward, MdArrowUpward, MdClose, MdContentCopy, MdOutlineVisibility, MdOutlineVisibilityOff } from 'react-icons/md';

const ProfileRight = ({ profileId, accessToken, pageName, pageType, allRightListItems, setLoading, getAllProfileListData, addNewRightItemServices, selectItemDetails, usedProducts }) => {

	const [previouseSelected, setPreviouseSelected] = useState({ prevId: 1, prevIndex: 1 })
	const [currentSelected, setCurrentSelected] = useState({ currId: 1, currIndex: 1 })
	const [showDeleteModal, setShowDeleteModal] = useState(false)
	const [showVisibilityModal, setShowVisibilityModal] = useState(false)

	const [isDeleting, setIsDeleting] = useState(false)

	const navigate = useNavigate()

	const { timestamp, selectedRightItem, fetchSelected, newItemList, setNewItemList, handleActiveIndexUp, handleActiveIndexDown, activeIndex, setActiveIndex, userDataContext } = useRightContext();

	const { setMessage, setMessageType } = useMessage()

	useEffect(() => {
		// console.log(allRightListItems, "right menu list")

		const assignItemValue = allRightListItems?.map((item) => {
			return {
				name: item.name,
				description: item.description,
				imageUrl: item.imagePath,
				width: item.width,
				height: item.height,
				id: item.id,
				isSelf: item.isSelf,
				isEnabled: item.isEnabled,
			}
		})

		setNewItemList(assignItemValue)

	}, [allRightListItems])

	useEffect(() => {
		setActiveIndex(0)
	}, [])

	const handleItemClick = (currentIndex, id) => {

		getAllProfileListData(pageType)

		setCurrentSelected({ currId: id, currIndex: currentIndex + 1 });

		if (currentSelected) {
			setPreviouseSelected({
				prevId: currentSelected.currId,
				prevIndex: currentSelected.currIndex,
			});
		}

		fetchSelected(currentIndex, id);
	};

	// api to delete right side items
	const deleteRightItemServices = async () => {

		setIsDeleting(true)
		setLoading(true)

		const res = await deleteRightItem(accessToken, selectItemDetails?.id)

		if (res && res && res.status === 200) {

			setShowDeleteModal(false)

			fetchSelected(0, allRightListItems[0]?.id);

			if (res?.data?.statusCode === 200) {
				setMessage(res?.data?.message ? res.data.message : 'Record removed successfully !');
				setMessageType('success')

				getAllProfileListData(pageType)
				setIsDeleting(false)
			} else {
				setIsDeleting(false)
				setLoading(false)
				setMessage(res?.data?.message ? res.data.message : 'Something went wrong');
				setMessageType('error')
			}
		} else {
			setIsDeleting(false)
			setLoading(false)
			setMessage(res?.message ? res.message : 'Something went wrong');
			setMessageType('error')

			setShowDeleteModal(false)
			getAllProfileListData(pageType)

			if (res?.message === "Network Error") {
				navigate('/error/network')
			}
		}
	}

	// api to update right side item
	const updateOrderServices = async (type, direction) => {
		setLoading(true)

		const res = await updateOrder(accessToken, timestamp, type, direction)

		if (res && res && res.status === 200) {

			if (res?.data?.statusCode === 200) {
				getAllProfileListData(pageType)
			} else {
				setLoading(false)
				setMessage(res?.data?.message ? res.data.message : 'Something went wrong');
				setMessageType('error')
			}
		} else {
			setLoading(false)
			setMessage(res?.message ? res.message : 'Something went wrong');
			setMessageType('error')

			if (res?.message === "Network Error") {
				navigate('/error/network')
			}
		}
	}

	const handleItemUp = () => {
		handleActiveIndexUp()
		updateOrderServices(pageType, "UP")
	}

	const handleItemDown = () => {
		handleActiveIndexDown()
		updateOrderServices(pageType, "DOWN")
	}

	const copyPaletteServices = async (id) => {
		setLoading(true)
		const res = await copyItem(accessToken, id, 7)
		if (res && res.status === 200) {
			if (res?.data?.statusCode === 200) {
				setMessageType('success')
				setMessage(res?.data?.message ? res.data.message : 'Item copied')
				getAllProfileListData(pageType)
				setLoading(false)
			} else {
				setMessage(res?.data?.message ? res.data.message : 'Something went wrong')
				setMessageType('error')
				getAllProfileListData(pageType)
				setLoading(false)
			}
		} else {
			setLoading(false)
			setMessage(res?.message ? res.message : 'Something went wrong');
			setMessageType('error')
			getAllProfileListData(pageType)
			if (res?.message === "Network Error") {
				navigate('/error/network')
			}
		}
	}

	const handleVisibility = async (e, item) => {
		if (usedProducts && usedProducts?.length > 0) {
			setShowVisibilityModal(true)
		} else {
			setLoading(true)
			const res = await itemVisibility(accessToken, item?.id, 7, !item?.isEnabled)

			if (res && res.status === 200) {
				if (res?.data?.statusCode === 200) {
					setMessageType('success')
					setMessage(res?.data?.message ? res.data.message : 'Action Successful')
					getAllProfileListData(pageType)
					setLoading(false)
				} else {
					setMessage(res?.data?.message ? res.data.message : 'Something went wrong')
					setMessageType('error')
					getAllProfileListData(pageType)
					setLoading(false)
				}
			} else {
				setLoading(false)
				setMessage(res?.message ? res.message : 'Something went wrong');
				setMessageType('error')
				getAllProfileListData(pageType)
				if (res?.message === "Network Error") {
					navigate('/error/network')
				}
			}
		}
	}

	return (
		<>
			<div className='h-100 d-flex flex-column flex-grow-1 justify-content-between'>
				<div className='inside_scroll has_btns'>
					<div className='right_list'>
						{newItemList?.map((item, index) =>
							<div key={index} className={`position-relative d-flex align-items-center right_item_wrap has_delete has_copy ${userDataContext.role === "Supplier" && 'count_3'}`}>
								<Link to="#" className={`right_item w-100 ${activeIndex === index && "active"}`} onClick={() => handleItemClick(index, item.id)}>
									<span className='me-2 image_icon'>
										{(item.imageUrl) ? (
											<Image src={item.imageUrl} style={{width:"55px", height:"55px"}}/>
										) : (
											<GoCircleSlash size={55} color="#989898" />
										)}
									</span>
									<div className="right_title">
										<span className="text-truncate">{item.name}</span>
										<span className="text-truncate d-block text-small">{item.description}</span>
									</div>
								</Link>
								<span className='position-absolute action_btns'>
									{userDataContext && userDataContext.role === "Supplier" && (
										<Link to="#" onClick={(e) => { fetchSelected(index, item.id); handleVisibility(e, item) }}>
											{item?.isEnabled ? (
												<MdOutlineVisibilityOff className='text-muted visibility_off' size={20} />
											) : (
												<MdOutlineVisibility className='text-muted visibility_on' size={20} />
											)}
										</Link>
									)}

									{item.isSelf && (
										<>
											<Link to="#" onClick={() => { setShowDeleteModal(true); fetchSelected(index, item.id); }}> <BiTrashAlt className='text-muted' size={20} /> </Link>
											<Link to="#" onClick={() => copyPaletteServices(item?.id)}> <MdContentCopy className='text-muted' size={18} /> </Link>
										</>
									)}
								</span>
							</div>
						)}
					</div>
				</div>

				{/* Start: space for buttons at the bottom */}
				<div className='d-flex gap-2 gap-xl-3 main_buttons'>
					<Button
						color="primary"
						size="md"
						className='px-4'
						onClick={() => addNewRightItemServices(`${pageName} ${newItemList && newItemList.length ? (newItemList.length + 1) : 1}`, pageType)}
					>
						<BiPlus size={20} className='me-1' />
						New
					</Button>
					{selectedRightItem?.isSelf && (
						<>
							<Button
								color=""
								size="md"
								className='border-0 text-primary px-0'
								onClick={() => handleItemUp()}
								disabled={timestamp === '' || activeIndex === 0}
							>
								<MdArrowUpward size={22} className='me-1' />
								Up
							</Button>
							<Button
								color=""
								size="md"
								className='border-0 text-primary px-0'
								onClick={() => handleItemDown()}
								disabled={timestamp === '' || (allRightListItems?.length - 1 === activeIndex) || (selectedRightItem?.isLast)}
							>
								<MdArrowDownward size={22} className='me-1' />
								Down
							</Button>
						</>
					)}
				</div>
				{/* End: space for buttons at the bottom */}
			</div>

			<Modal isOpen={showDeleteModal} toggle={() => setShowDeleteModal(false)}>
				{usedProducts?.length > 0 ? (
					<h2 className='px-3 pt-3'>
						Oops!
					</h2>
				) : (
					<ModalHeader >
						Delete Selected Item
					</ModalHeader>
				)}
				{usedProducts?.length > 0 ? (
					<ModalBody>
						<p className='bt-0'>This profile is being used by the following products:</p>

						{usedProducts.map((item, index) =>
							<span key={index}>{index == 0 ? null : <br />} <b>{item.productType}</b> - {item.usedIn}</span>
						)}

						<p className='mt-4'>Remove the profile from these products first.</p>
					</ModalBody>
				) : (
					<ModalBody>
						Are you sure you want delete <strong>{selectItemDetails?.name}</strong> ?
					</ModalBody>
				)}

				<ModalFooter>
					{usedProducts?.length == 0 ? <Button color="outline-danger" size="md" disabled={isDeleting} onClick={() => deleteRightItemServices()}>Delete</Button> : null}

					<Button onClick={() => setShowDeleteModal(false)} color="primary" size="md">Cancel</Button>
				</ModalFooter>
			</Modal>

			<Modal isOpen={showVisibilityModal} toggle={() => setShowVisibilityModal(false)}>
				<div className='d-flex justify-content-between align-items-center p-3'>
					<h2 className='mb-0 lh-1'>
						Oops!
					</h2>

					<NavLink onClick={() => setShowVisibilityModal(false)}>
						<MdClose size={20} />
					</NavLink>
				</div>

				<ModalBody>
					<p className='bt-0'>This profile is being used by the following products:</p>

					{usedProducts.map((item, index) =>
						<span key={index}>{index == 0 ? null : <br />} <b>{item.productType}</b> - {item.usedIn}</span>
					)}

					<p className='mt-4'>Remove the profile from these products first.</p>
				</ModalBody>
			</Modal>
		</>
	)
}

export default ProfileRight