import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap';
import {
    Row,
    Col,
    Button,
    FormGroup,
    Label,
} from 'reactstrap';
import { useRightContext } from '../context/rightMenuContext';

const DocsSummary = ({ selectItemDetails, updateItemDetailsServices, handleFormReset }) => {
    const { timestamp } = useRightContext()
    const [frames, setFrames] = useState(false)
    const [installation, setInstallation] = useState(false)
    const [ancillaries, setAncillaries] = useState(false)
    const [ancillariesPrice, setAncillariesPrice] = useState(false)
    const [total, setTotal] = useState(false)
    const [startNewPageBeforeSummary, setStartNewPageBeforeSummary] = useState(false)

    // setting all the value for prefilled inputs
    useEffect(() => {

        setFrames(selectItemDetails?.frames || false)
        setInstallation(selectItemDetails?.installation || false)
        setAncillaries(selectItemDetails?.ancillaries || false)
        setAncillariesPrice(selectItemDetails?.ancillaryPrices || false)
        setTotal(selectItemDetails?.total || false)
        setStartNewPageBeforeSummary(selectItemDetails?.startNewPageBeforeSummary || false)

    }, [selectItemDetails])

    const handleAncillaryChange = (e) => {
        setAncillaries(!ancillaries)

        if (!e.target.checked) {
            setAncillariesPrice(false)
        }
    }

    const handleFormSubmit = () => {

        var formDataValues = {
            'id': selectItemDetails?.id,
            'frames': frames,
            'installation': installation,
            'ancillaries': ancillaries,
            'ancillariesPrice': ancillariesPrice,
            'total': total,
            'startNewPageBeforeSummary': startNewPageBeforeSummary,
        }
        updateItemDetailsServices(formDataValues, "Summary")
    }

    return (
        <Form className='h-100 d-flex flex-column flex-grow-1 justify-content-between'>
            <div className='inside_scroll has_tab has_btns has_head'>
                <Row>
                    <Col xs="6" sm="4" xl="2" xxl="2">
                        <FormGroup className="form-group">
                            <Label for="frames">
                                Frames
                            </Label>
                            <Form.Check // prettier-ignore
                                type="switch"
                                id="frames"
                                className='form-switch-lg'
                                name='frames'
                                onChange={(e) => setFrames(!frames)}
                                value={frames}
                                checked={frames}
                                label={frames ? 'On' : 'Off'}
                            />
                        </FormGroup>
                    </Col>

                    <Col xs="6" sm="4" xl="2" xxl="2">
                        <FormGroup className="form-group">
                            <Label for="installation">
                                Installation
                            </Label>
                            <Form.Check // prettier-ignore
                                type="switch"
                                id="installation"
                                className='form-switch-lg'
                                name='installation'
                                onChange={(e) => setInstallation(!installation)}
                                value={installation}
                                checked={installation}
                                label={installation ? 'On' : 'Off'}
                            />
                        </FormGroup>
                    </Col>

                    <Col xs="6" sm="4" xl="2" xxl="2">
                        <FormGroup className="form-group">
                            <Label for="ancillaries">
                                Ancillaries
                            </Label>
                            <Form.Check // prettier-ignore
                                type="switch"
                                id="ancillaries"
                                className='form-switch-lg'
                                name='ancillaries'
                                onChange={(e) => handleAncillaryChange(e)}
                                value={ancillaries}
                                checked={ancillaries}
                                label={ancillaries ? 'On' : 'Off'}
                            />
                        </FormGroup>
                    </Col>

                    <Col xs="6" sm="4" xl="3" xxl="2" className={ancillaries ? "" : 'opacity-75'}>
                        <FormGroup className="form-group">
                            <Label for="ancillariesPrice">
                                Ancillary prices
                            </Label>
                            <Form.Check // prettier-ignore
                                type="switch"
                                id="ancillariesPrice"
                                className='form-switch-lg'
                                name='ancillariesPrice'
                                onChange={(e) => setAncillariesPrice(!ancillariesPrice)}
                                value={ancillariesPrice}
                                checked={ancillariesPrice}
                                disabled={!ancillaries}
                                label={ancillariesPrice ? 'On' : 'Off'}
                            />
                        </FormGroup>
                    </Col>

                    <Col xs="6" sm="4" xl="2" xxl="2" className='ps-xl-0 ps-xxl-3'>
                        <FormGroup className="form-group">
                            <Label for="total">
                                Total
                            </Label>
                            <Form.Check // prettier-ignore
                                type="switch"
                                id="total"
                                className='form-switch-lg'
                                name='total'
                                onChange={(e) => setTotal(!total)}
                                value={total}
                                checked={total}
                                label={total ? 'On' : 'Off'}
                            />
                        </FormGroup>
                    </Col>

                    <Col xs="12">
                        <FormGroup className="form-group">
                            <Label for="startNewPageBeforeSummary">
                                Start a new page before the summary
                            </Label>
                            <Form.Check // prettier-ignore
                                type="switch"
                                id="startNewPageBeforeSummary"
                                className='form-switch-lg'
                                name='startNewPageBeforeSummary'
                                onChange={(e) => setStartNewPageBeforeSummary(!startNewPageBeforeSummary)}
                                value={startNewPageBeforeSummary}
                                checked={startNewPageBeforeSummary}
                                label={startNewPageBeforeSummary ? 'On' : 'Off'}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </div>
            <div className='d-flex gap-2 gap-xl-3 main_buttons'>
                <Button
                    color="primary"
                    size="md"
                    className='px-4'

                    onClick={() => handleFormSubmit()}
                    disabled={!timestamp}
                >
                    Save Changes
                </Button>

                <Button
                    color="primary"
                    size="md"
                    outline
                    className='px-4'
                    onClick={() => handleFormReset()}
                    disabled={!timestamp || selectItemDetails?.initialValue}
                >
                    Reset
                </Button>
            </div>
        </Form>
    )
}

export default DocsSummary