/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.16 AluminiumPanel.glb 
*/

import React, { forwardRef, useEffect, useRef, useState } from 'react'
import { useGLTF } from '@react-three/drei'
import * as THREE from 'three';
import PVCsquare from '../../assets/models/PVCsquare.glb'
import PVCgroove from '../../assets/models/PVCgroove.glb'
import PVCgroovesubtract from '../../assets/models/PVCgroovesubtract.glb'
import CladdingAdd from '../../assets/models/CladdingAdd.glb'
import CladdingSub from '../../assets/models/CladdingSubtract.glb'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { useFrame, useLoader } from 'react-three-fiber';
import { Box3, DoubleSide, Object3D, Plane, QuadraticBezierCurve3, ShaderMaterial, Vector3 } from 'three';
import { calculateDiagonalLength } from '../../utility/helper'

const bendVertexShader = `
uniform float bendAmount;
uniform vec3 modelSize;

void main() {
  vec4 modelPosition = modelMatrix * vec4(position, 1.0);

  float bendFactor = (modelPosition.x / modelSize.x) * bendAmount;
  modelPosition.y += bendFactor;

  gl_Position = projectionMatrix * viewMatrix * modelPosition;
}
`;

const PanelModel = forwardRef(({ shape, styleSelect, methodSelect, angle, repeat, posX, posZ, scale }, ref) => {
  const { nodes, materials } = useGLTF('/AluminiumPanel.glb')
  const groupRef = useRef();
  const [modelWid, setModelWid] = useState()

  const modelRef = useRef();
  const model = shape === 2 ? (methodSelect === 'Add' ? CladdingAdd : CladdingSub) : shape === 3 ? PVCsquare : (methodSelect === 'Add' ? PVCgroove : PVCgroovesubtract);
  let gltfLines = useLoader(GLTFLoader, model);

  let glazingWidth = 1220
  let glazingHeight = 1220

  const barModelBoundingBox = new THREE.Box3().setFromObject(gltfLines.scene);
  const modelHeight = (barModelBoundingBox.max.y - barModelBoundingBox.min.y)

  var panelLength = calculateDiagonalLength(angle, glazingWidth, glazingHeight)

  let repeatValue = (parseFloat(modelHeight) * 1000) + (repeat > 30 ? parseFloat(repeat) : 100)

  let repeatRatio = Math.ceil(panelLength / repeatValue)

  const rotationAngle = angle > 0 ? (angle) : 0;
  const rotationAngleInRadians = rotationAngle * Math.PI / 180;

  useEffect(() => {
    const loader = new GLTFLoader();
    loader.load('/AluminiumPanel.glb', (gltf) => {
      const boundingBox = new Box3().setFromObject(gltf.scene);
      const boundingBoxL = new Box3().setFromObject(gltfLines.scene);
      if (!boundingBox.containsBox(boundingBoxL)) {
        console.log('Model is too big')
        setModelWid(boundingBox / boundingBoxL)
      }
    });
  }, []);

  const LineModel = ({ isBackSide }) => {
    const clones = [];

    let scaleX = 1
    
    const clippingPlanes = [
      new Plane(new Vector3(0, 0, 1), .44),
      new Plane(new Vector3(0, 0, -1), .44),
      new Plane(new Vector3(0, 1, 0), .44),
      new Plane(new Vector3(0, -1, 0), .44)
    ];

    var oldPlace = 0

    for (let i = 0; i < repeatRatio; i++) {

      const clone = gltfLines.scene.clone();

      // adding clipping planes in all cases
      clone.children[0].material.clipping = true;
      clone.children[0].material.clippingPlanes = clippingPlanes;
      
      if(angle > 0) {
        scaleX = 2
      }

      var adjustedPosition = 0;

      if (repeatRatio > 1) {
        if (repeatRatio % 2 === 0) {
          if (i % 2 === 0) {
            adjustedPosition = ((((1.20 * (i + 1)) / repeatRatio) + (modelHeight / 2)) / 2)
            oldPlace = adjustedPosition
          } else {
            adjustedPosition = - oldPlace
          }
        } else {
          if (i % 2 === 0) {
            adjustedPosition = - oldPlace
          } else {
            adjustedPosition = ((((1.20 * (i + 1)) / repeatRatio) + (modelHeight / 2)) / 2)
            oldPlace = adjustedPosition
          }
        }
      } else {
        adjustedPosition = 0
      }

      clones.push(
        <mesh rotation={[0, 0, 0]}>
          <mesh visible={(styleSelect === 'Lines' && shape !== 3) || (styleSelect === 'Lines' && shape === 3 && methodSelect === 'Add')}
            position={[!isBackSide ? 0.02 : 0, 0, 0]}
            rotation={[0, Math.PI / 2, 0]}
            key={i}>
            <primitive
              scale={[1, 1, scaleX]}
              rotation={[0, -(Math.PI / 2), 0]}
              object={clone}
              position={[0, adjustedPosition, 0]}
            />
          </mesh>
          <mesh visible={styleSelect === 'Lines' && shape === 3 && methodSelect === 'Subtract'}
            position={[0, 0, 0]}
          >
            <mesh
              position={[0, adjustedPosition, 0]}
              rotation={[Math.PI / 2, 0, 0]}
              scale={[1,scaleX,1]}
            >
              <mesh rotation={[Math.PI / 2, Math.PI / 2, Math.PI / 2]} scale={[1, scaleX, 1]}>
                <planeGeometry args={[0.02, 0.82]} />
                <meshStandardMaterial side={DoubleSide} clippingPlanes={clippingPlanes} />
              </mesh>
            </mesh>
          </mesh>
        </mesh>
      );
    }

    return <>{clones}</>;
  };

  useEffect(() => {
    if (groupRef.current && ref) {
      ref.current = groupRef.current;
    }
  }, []);

  return (
    <group ref={groupRef} scale={scale ? scale : [1, 1, 1]} position={posX && posZ ? [posX, 0, posZ] : [0, 0, 0]}>
      {shape !== 1 && (
        <mesh rotation={[-rotationAngleInRadians, 0, 0]} position={[shape === 2 && methodSelect === 'Subtract' ? 0 : 0, 0, 0]}>
          <LineModel isBackSide={true} />
        </mesh>
      )}
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube001.geometry}
        material={materials['Material.004']}
        position={[-0.02, 0, 0]}
        rotation={[Math.PI, Math.PI / 2, 0]}
        scale={[-0.435, -0.435, -0.45]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.External008.geometry}
          material={materials['Material.004']}
          position={[0, 0.004, 0]}
        />
      </mesh>
      <group position={[0.008, 0.002, 0.425]} rotation={[Math.PI / 2, Math.PI / 2, 0]} scale={-1}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube026.geometry}
          material={materials['Material.004']}
          position={[0.002, 0.425, 0.008]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube028.geometry}
          material={materials['Material.004']}
          position={[0.002, 0.425, 0.008]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube029.geometry}
          material={materials['Material.004']}
          position={[0.002, 0.425, 0.008]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.External006.geometry}
          material={materials['Material.004']}
          position={[0.002, 0.425, 0.008]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.External007.geometry}
          material={materials['Material.004']}
          position={[0.002, 0.425, 0.008]}
        />
      </group>
      <group position={[0.008, 0.002, -0.425]} rotation={[Math.PI / 2, -Math.PI / 2, 0]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube021.geometry}
          material={materials['Material.004']}
          position={[0.002, 0.425, 0.008]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube023.geometry}
          material={materials['Material.004']}
          position={[0.002, 0.425, 0.008]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube024.geometry}
          material={materials['Material.004']}
          position={[0.002, 0.425, 0.008]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.External002.geometry}
          material={materials['Material.004']}
          position={[0.002, 0.425, 0.008]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.External003.geometry}
          material={materials['Material.004']}
          position={[0.002, 0.425, 0.008]}
        />
      </group>
      <group position={[0.008, 0.427, 0]} rotation={[Math.PI, -Math.PI / 2, 0]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube016.geometry}
          material={materials['Material.004']}
          position={[0, 0.427, 0.008]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube018.geometry}
          material={materials['Material.004']}
          position={[0, 0.427, 0.008]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube019.geometry}
          material={materials['Material.004']}
          position={[0, 0.427, 0.008]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.External.geometry}
          material={materials['Material.004']}
          position={[0, 0.427, 0.008]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.External001.geometry}
          material={materials['Material.004']}
          position={[0, 0.427, 0.008]}
        />
      </group>
      <group position={[0.008, -0.423, 0]} rotation={[Math.PI, Math.PI / 2, 0]} scale={-1}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube002.geometry}
          material={materials['Material.004']}
          position={[0, 0.423, 0.008]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube006.geometry}
          material={materials['Material.004']}
          position={[0, 0.423, 0.008]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube008.geometry}
          material={materials['Material.004']}
          position={[0, 0.423, 0.008]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.External004.geometry}
          material={materials['Material.004']}
          position={[0, 0.423, 0.008]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.External005.geometry}
          material={materials['Material.004']}
          position={[0, 0.423, 0.008]}
        />
      </group>
      {shape !== 1 && (
        <mesh
          scale={[1, 1, 1]}
          rotation={[-rotationAngleInRadians, Math.PI, 0]}
          position={[(shape === 3 && methodSelect === 'Add' ? -0.025 : (shape === 3 && methodSelect === 'Subtract' ? -0.03 : (shape === 2 && methodSelect === 'Subtract' ? -0.013 : -0.015))), 0, 0]}
        >
          <LineModel isBackSide={false} />
        </mesh>
      )}
    </group>
  )
});

export { PanelModel };

useGLTF.preload('/AluminiumPanel.glb')