import React, { useEffect, useState } from "react";
import { Table, Form } from "react-bootstrap";
import { FormGroup, Label } from "reactstrap";
import {
  formatValue,
  getAnicllaryPricing,
} from "../utility/helper";
import {
  addNewFrameAncillaryService,
  deleteFrameAncillaries,
  getFrameQuotationPricingService,
  getProjectAncillariesPricelist,
} from "../services/pricingServices";
import { useDesigner } from "../context/designerContext";
import { useMessage } from "../context/messageContext";
import { useNavigate } from "react-router-dom";
import PromotionComponent from "./PromotionComponent";
import { useRightContext } from "../context/rightMenuContext";
import ItemQuantity from "./Pricing/ItemQuantity";
import AncillaryComponent from "./AncillaryComponent";

export const QuotationFramePricing = ({
  quotationId,
  leftPanelOpen,
  accessToken,
  printId,
  isDeleted,
  quoteDetails,
  isCopied,
  setIsCopied
}) => {
  const [frameData, setFrameData] = useState([]);
  const [installData, setInstallData] = useState([]);
  const [ancillaryData, setAncillaryData] = useState([]);
  const [installation, setInstallation] = useState(false);
  const [addedAncillaries, setAddedAncillaries] = useState([]);
  const [isUnlinked, setIsUnLinked] = useState(false);
  const [ancillaryModal, setAncillaryModal] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [isEdit, setIsEdit] = useState(false);
  // const [ancillyPricing, setAncillaryPricing] = useState(0);
  // const [isAncillarySaved, setIsAnicillarySaved] = useState(false);
  const [totalPriceObj, setTotalPriceObj] = useState(null);
  const [discountSwitch, setDiscountSwitch] = useState(true);
  const [hidePrintSwitch, setHidePrintSwitch] = useState(false);
  const [addedPromotions, setAddedPromotions] = useState([]);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [discountSubTotal, setDiscountSubtotal] = useState(0);

  const [modalData, setModalData] = useState({
    id: 0,
    lumpSum: 0,
    quantity: 1,
    percentage: 0,
    description: "",
    preset: -1,
  });

  const { setMessage, setMessageType } = useMessage();
  const navigate = useNavigate();

  const { userDataContext, projectDefault, generalTax } = useRightContext();
  const { setQuotationFramePrice } = useDesigner();

  useEffect(() => {
    if (quotationId && printId !== null) {
      getFrameInstallationData();
    }
  }, [quotationId, printId, isDeleted, installation, isCopied]);

  useEffect(() => {
    if (quoteDetails && quoteDetails.project_type) {
      if (quoteDetails.project_type === "Supply and installation") {
        setInstallation(true);
      } else {
        setInstallation(false);
      }
    }
  }, [quoteDetails]);

  // useEffect(() => {
  //   if (addedAncillaries && addedAncillaries?.length > 0) {
  //     setAncillaryPricing(getAnicllaryPricing(addedAncillaries, totalPrice));
  //     setIsAnicillarySaved(true);
  //   }
  // }, [addedAncillaries]);

  useEffect(() => {

    let total = 0;

    // frame price with quantity
    if (frameData && frameData?.length > 0) {
      frameData.forEach((item) => {
        total += parseFloat(item?.price);
      });
    }

    // frame installation price with quantity
    if (installation && installData && installData?.length > 0) {
      installData.forEach((item) => {
        total += parseFloat(item?.price);
      });
    }

    // let ancTotalPrice = 0

    const totalFramePrice = parseFloat(totalPriceObj?.framesPrice?.price);

    // addedAncillaries price
    if (addedAncillaries && addedAncillaries?.length > 0) {
      addedAncillaries.forEach((item) => {
        if (item.percentage > 0) {
          total += ((parseFloat(item?.ancillariesPrice ? item.ancillariesPrice : 0) + (totalFramePrice * (item.percentage / 100))) * (item?.quantity > 0 ? item.quantity : 0));
        } else {
          total += (parseFloat(item?.ancillariesPrice ? item.ancillariesPrice : 0) * (item?.quantity > 0 ? item.quantity : 0));
        }
      });
    }

    // total += ancTotalPrice

    setTotalPrice(total);

    setQuotationFramePrice(total + total * (generalTax?.percentage / 100));

    if (totalDiscount > 0 && addedPromotions?.length > 0) {

      let discountPrice =
        total -
        parseFloat(totalDiscount / 100) *
        getFameAndInstallPrice(frameData, installData);

      setDiscountSubtotal(discountPrice);
      setQuotationFramePrice(
        formatValue(parseFloat(discountPrice) + parseFloat(discountPrice * (generalTax?.percentage / 100)))
      );
    }

  }, [
    addedAncillaries,
    installation,
    installData,
    frameData,
    addedPromotions,
    totalDiscount,
  ]);

  const handlePresetModalValue = (e) => {
    const value = e.target.value;

    if (value === "-1") {
      setModalData((prevData) => ({
        ...prevData,
        id: 0,
        preset: value,
        description: "",
        lumpSum: 0,
        quantity: 1,
        percentage: 0,
      }));
    } else {
      let item = ancillaryData.find((item) => item?.id === value);

      setModalData((prevData) => ({
        ...prevData,
        id: 0,
        preset: value,
        description: item?.description,
        lumpSum: item?.cost,
        quantity: 1,
        percentage: item?.percentage,
      }));

      setIsUnLinked(!isUnlinked);
    }
  };

  const handleAncillaryModal = () => {
    setIsUnLinked(false);

    setModalData((prevData) => ({
      ...prevData,
      id: 0,
      preset: -1,
      description: '',
      lumpSum: 0,
      quantity: 1,
      percentage: 0,
    }));

    setAncillaryModal(true);
  };

  const getAncillariesData = async () => {
    const res = await getProjectAncillariesPricelist(accessToken);
    if (res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        setAncillaryData(res?.data?.entity);
      } else if (res?.data?.statusCode === 404) {
        navigate("/error/404");
      } else {
        setMessage(
          res?.data?.message ? res.data.message : "Something went wrong"
        );
        setMessageType("error");
      }
    } else {
      setMessage(res?.message ? res.message : "Something went wrong");
      setMessageType("error");
      if (res?.message === "Network Error") {
        navigate("/error/network");
      }
    }
  };

  const handleAncillaryEdit = (item) => {

    setIsUnLinked(true);

    setModalData((prevModalData) => ({
      ...prevModalData,
      id: item?.id || 0,
      preset: item?.ancillariesId,
      description: item?.ancillariesName,
      percentage: item?.percentage,
      lumpSum: item?.ancillariesPrice,
      quantity: item?.quantity,
    }));

    setDiscountSwitch(item?.discountable ? item?.discountable : false);

    setHidePrintSwitch(
      item?.hideWithInFrameWhenPrinting
        ? item?.hideWithInFrameWhenPrinting
        : false
    );

    setIsEdit(true);
    setAncillaryModal(true);
  };

  const handleAncillary = (item) => {

    let data = {
      id: 0,
      ancillariesId: item?.id,
      ancillariesName: item.description,
      ancillariesPrice: item.totalPlusVat,
      quotationId: quotationId,
      percentage: item?.percentage,
      quantity: 1,
      discountable: false,
      hideWithInFrameWhenPrinting: hidePrintSwitch,
    };

    addNewAncillary(data);
    setIsUnLinked(true);
  };

  const deleteAncillary = (idToRemove, indexToRemove) => {
    setAddedAncillaries((prevData) => {
      const newData = prevData.filter(
        (item, index) => index !== indexToRemove || item.id !== idToRemove
      );
      return newData;
    });
    deleteFrameAncillary(idToRemove);
  };

  const deleteFrameAncillary = async (id) => {
    const res = await deleteFrameAncillaries(accessToken, id);
    if (res?.data?.statusCode === 200) {
      getAncillariesData();
      console.log(res?.data?.message);
    } else {
      console.log(res?.data?.message);
    }
  };

  const addNewFrameAncillary = (modalData) => {
    let data = {
      id: modalData?.id || 0,
      ancillariesId: modalData?.preset,
      ancillariesName: modalData?.description,
      ancillariesPrice: modalData?.lumpSum,
      quotationId: quotationId,
      percentage: modalData?.percentage,
      quantity: modalData?.quantity,
      discountable: discountSwitch,
      hideWithInFrameWhenPrinting: hidePrintSwitch,
    };

    addNewAncillary(data);

    setIsEdit(false);
    setAncillaryModal(false);
  };

  // const getTotalAncillaryPricing = (item) => {

  //   const totalFramePrice = parseFloat(totalPriceObj?.framesPrice?.price);

  //   let ancillaryPricing = 0;

  //   if (item?.price !== 0 && item?.percentage !== 0) {
  //     ancillaryPricing +=
  //       parseFloat(item?.percentage / 100) * parseFloat(totalPrice) +
  //       parseFloat(item.price) *
  //       parseFloat(item?.quantity ? item?.quantity : 1);
  //   }

  //   if (item?.price !== 0 && item?.percentage === 0)
  //     ancillaryPricing +=
  //       parseFloat(item.price) *
  //       parseFloat(item?.quantity ? item?.quantity : 1);
  //   else if (item?.percentage !== 0 && item?.price === 0) {
  //     let price = parseFloat(item?.percentage / 100) * parseFloat(totalPrice);
  //     ancillaryPricing +=
  //       price * parseFloat(item?.quantity ? item?.quantity : 1);
  //   }

  //   return ancillaryPricing;
  // };

  const getPricing = (arr) => {
    let total = 0;
    arr.forEach((item) => {
      total += parseFloat(item?.price);
    });
    return total;
  };

  const getAncillaryPrice = (item) => {
    let price = 0;

    const totalFramePrice = parseFloat(totalPriceObj?.framesPrice?.price);

    if (item.percentage > 0) {
      price += ((parseFloat(item?.ancillariesPrice ? item.ancillariesPrice : 0) + (totalFramePrice * (item.percentage / 100))) * (item?.quantity > 0 ? item.quantity : 0));
    } else {
      price += (parseFloat(item?.ancillariesPrice ? item.ancillariesPrice : 0) * (item?.quantity > 0 ? item.quantity : 0));
    }

    return price;
  };

  const getTotalAncillaryPrice = (arr) => {
    let total = 0;

    const totalFramePrice = parseFloat(totalPriceObj?.framesPrice?.price);
    // totalFrame += getPricing(frameData);

    arr.forEach((item) => {
      if (item.percentage > 0) {
        total += ((parseFloat(item?.ancillariesPrice ? item.ancillariesPrice : 0) + (totalFramePrice * (item.percentage / 100))) * (item?.quantity > 0 ? item.quantity : 0));
      } else {
        total += (parseFloat(item?.ancillariesPrice ? item.ancillariesPrice : 0) * (item?.quantity > 0 ? item.quantity : 0));
      }
    });

    return total;
  };

  const getTotalAncillaryPriceDisc = (arr) => {
    let total = 0;

    arr.forEach((item) => {
      if (item.discountable) {
        total += ((parseFloat(item?.ancillariesPrice ? item.ancillariesPrice : 0) * (item?.quantity > 0 ? item.quantity : 0)) * (parseFloat(totalDiscount) / 100));
      }
    });
    return total;
  };

  const getFrameInstallationData = async () => {
    const res = await getFrameQuotationPricingService(
      accessToken,
      printId,
      quotationId
    );
    if (res?.data?.statusCode === 200) {
      const entity = res?.data?.entity || {};

      setFrameData(entity.framePrice?.length > 0 ? entity.framePrice : []);
      setInstallData(installation && entity.installationPrice?.length > 0 ? entity.installationPrice : []);
      setAddedAncillaries(entity.ancillariesPrice?.length > 0 ? entity.ancillariesPrice : []);
      setAddedPromotions(entity.promotionsPrice?.length > 0 ? entity.promotionsPrice : []);
      setTotalPriceObj(entity.total || null);
      setIsCopied(false)
    } else {
      setFrameData([]);
      setInstallData([]);
      setAddedAncillaries([]);
      setAddedPromotions([]);
      setTotalPriceObj(null);
      setIsCopied(false)
    }

  };

  const addNewAncillary = async (data) => {
    const res = await addNewFrameAncillaryService(accessToken, data);
    if (res?.data?.statusCode === 200) {
      getFrameInstallationData();
      console.log(res?.data?.message);
    } else {
      console.log(res?.data?.message);
    }
  };

  const getFameAndInstallPrice = (frameData, installData) => {
    let total = 0;
    total += getPricing(frameData);

    if (installation) {
      total += getPricing(installData);
    }

    return formatValue(parseFloat(total));
  };

  return (
    <div className="fixed_m_scroll full_h ms-2">
      <h5 className="mt-3 mb-2">Frame</h5>
      <Table className="pricing_table no_td_bg">
        <tbody>
          {frameData &&
            frameData.map((item, index) => (
              <tr key={index} className="border-bottom">
                <td className="text-start text-wrap">
                  <ItemQuantity name={item?.name} quantity={item.quantity} />
                </td>
                <td className="text-end">{projectDefault?.currency ? projectDefault?.currency : '£'}{parseFloat(item?.price)}</td>
              </tr>
            ))}
        </tbody>
      </Table>

      <FormGroup className="form-group">
        <Label for="installation">Installation</Label>
        <Form.Check // prettier-ignore
          type="switch"
          id="installation"
          className="form-switch-lg ms-1"
          // onChange={() => {
          //   setInstallation(!installation);
          // }}
          checked={installation}
          label={installation ? "Included" : "None"}
        />
      </FormGroup>

      {installation && installData && installData?.length > 0 && (
        <>
          <h5 className="mt-5 mb-2">Installation</h5>
          <Table className="pricing_table no_td_bg">
            <tbody>
              {installData &&
                installData.map((item, index) => (
                  <tr key={index} className="border-bottom">
                    <td className="text-start text-wrap">
                      <ItemQuantity name={item?.name} quantity={item.quantity} />
                    </td>
                    <td className="text-end">{projectDefault?.currency ? projectDefault?.currency : '£'}{parseFloat(item?.price)}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </>
      )}

      {userDataContext && userDataContext.role !== '' && (
        <>
          <AncillaryComponent
            modalData={modalData}
            setModalData={setModalData}
            leftPanelOpen={leftPanelOpen}
            accessToken={accessToken}
            addedAncillaries={addedAncillaries}
            handleAncillaryModal={handleAncillaryModal}
            handleAncillary={handleAncillary}
            deleteAncillary={deleteAncillary}
            setIsUnLinked={setIsUnLinked}
            ancillaryModal={ancillaryModal}
            setAncillaryModal={setAncillaryModal}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            handlePresetModalValue={handlePresetModalValue}
            ancillaryData={ancillaryData}
            setAncillaryData={setAncillaryData}
            addNewFrameAncillary={addNewFrameAncillary}
            handleAncillaryEdit={handleAncillaryEdit}
            discountSwitch={discountSwitch}
            setDiscountSwitch={setDiscountSwitch}
            hidePrintSwitch={hidePrintSwitch}
            isUnlinked={isUnlinked}
            setHidePrintSwitch={setHidePrintSwitch}
            getAncillariesData={getAncillariesData}
            getAncillaryPrice={getAncillaryPrice}
            projectDefault={projectDefault}
          />

          <PromotionComponent
            accessToken={accessToken}
            addedPromotions={addedPromotions}
            setAddedPromotions={setAddedPromotions}
            setTotalDiscount={setTotalDiscount}
            quotationId={quotationId}
            getFrameInstallationData={getFrameInstallationData}
          />
        </>
      )}

      <h5 className="mt-3">Total</h5>
      <Table className="td_p_2 no_td_bg">
        <tbody>
          <tr className="border-bottom">
            <td className="text-wrap">Frame</td>
            <td className="text-wrap text-end">
              {projectDefault?.currency ? projectDefault?.currency : '£'}{formatValue(parseFloat(totalPriceObj?.framesPrice?.price))}
              {/* {projectDefault?.currency ? projectDefault?.currency : '£'}{formatValue(getPricing(frameData))} */}
            </td>
          </tr>
          <>
            {installation && installData && installData?.length > 0 && (
              <tr className="border-bottom">
                <td className="text-wrap">Installation</td>
                <td className="text-wrap text-end">
                  {projectDefault?.currency ? projectDefault?.currency : '£'}
                  {formatValue(
                    parseFloat(totalPriceObj?.installationPrice?.price)
                  )}
                  {/* {projectDefault?.currency ? projectDefault?.currency : '£'}{formatValue(getPricing(installData))} */}
                </td>
              </tr>
            )}
          </>

          {addedAncillaries && addedAncillaries?.length > 0 ? (
            <tr className="border-bottom">
              <td className="text-wrap">Ancillaries</td>
              <td className="text-wrap text-end">
                {projectDefault?.currency ? projectDefault?.currency : '£'}{formatValue(getTotalAncillaryPrice(addedAncillaries))}
              </td>
            </tr>
          ) : null}

          <tr className="border-bottom">
            <td className="text-wrap fw-bold">Subtotal</td>
            <td className="text-wrap text-end fw-bold">
              {/* {projectDefault?.currency ? projectDefault?.currency : '£'}{formatValue(totalPriceObj?.subtotal?.price)} */}{projectDefault?.currency ? projectDefault?.currency : '£'}
              {formatValue(parseFloat(totalPrice))}
            </td>
          </tr>

          {totalDiscount > 0 && addedPromotions?.length > 0 && (
            <tr className="border-bottom">
              <td className="text-wrap ">Discount</td>
              <td className="text-wrap text-end ">
                -{projectDefault?.currency ? projectDefault?.currency : '£'}
                {formatValue(
                  parseFloat((totalDiscount / 100) * getFameAndInstallPrice(frameData, installData) +
                    parseFloat(getTotalAncillaryPriceDisc(addedAncillaries)))
                )}
              </td>
            </tr>
          )}

          {totalDiscount > 0 && addedPromotions?.length > 0 && (
            <tr className="border-bottom">
              <td className="text-wrap fw-bold">Subtotal</td>
              <td className="text-wrap text-end fw-bold">
                {projectDefault?.currency ? projectDefault?.currency : '£'}
                {formatValue(
                  totalPrice -
                  parseFloat((totalDiscount / 100) * getFameAndInstallPrice(frameData, installData) +
                    parseFloat(getTotalAncillaryPriceDisc(addedAncillaries)))
                )}
              </td>
            </tr>
          )}
          <tr>
            <td className="text-wrap">VAT @20 %</td>
            <td className="text-wrap text-end">
              {/* {projectDefault?.currency ? projectDefault?.currency : '£'}{formatValue(parseFloat(totalPriceObj?.vat?.price))} */}
              {projectDefault?.currency ? projectDefault?.currency : '£'}
              {totalDiscount > 0 && addedPromotions?.length > 0
                ? formatValue(discountSubTotal * (generalTax?.percentage / 100))
                : formatValue(parseFloat(totalPrice) * (generalTax?.percentage / 100))}
            </td>
          </tr>
          <tr className="border-bottom">
            <td className="text-wrap fw-bold">Total</td>
            <td className="text-wrap text-end fw-bold">
              {/* {projectDefault?.currency ? projectDefault?.currency : '£'}{formatValue(parseFloat(totalPriceObj?.total?.price))} */}
              {projectDefault?.currency ? projectDefault?.currency : '£'}
              {totalDiscount > 0 && addedPromotions?.length > 0
                ? formatValue(
                  parseFloat(discountSubTotal) +
                  parseFloat(discountSubTotal * (generalTax?.percentage / 100))
                )
                : formatValue(
                  parseFloat(totalPrice) + parseFloat(totalPrice * (generalTax?.percentage / 100))
                )}
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};
