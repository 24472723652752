import React, { useEffect, useState } from 'react';
import {
  Button,
  FormGroup,
  Label,
} from 'reactstrap';
import { Link } from "react-router-dom";
import { MdAdd, MdOutlineKeyboardArrowDown } from 'react-icons/md';
import { MenuItem, OutlinedInput, Select } from 'material-ui-core';
import { BiTrash } from 'react-icons/bi';
import { MenuProps, baseURL, servicePath } from '../utility/data';
import axios from 'axios';
import moment from 'moment';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useDesigner } from '../context/designerContext';

const AddLinksModal = ({ setLoading, navigate, setMessageType, accessToken, setMessage, quoteId, printingLinks, leftPanelOpen, updateQuotationInvoiceJsonDataService, userDataContext }) => {
  const [linkInputs, setLinkInputs] = useState([]);

  useEffect(() => {
    if(leftPanelOpen) {
      getLinkList()
    }
  }, [leftPanelOpen])

  const getLinkList = () => {
    const formData = new FormData();
    formData.append("QuotationId", quoteId);
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${servicePath}/Document/GetAllCustomerLink`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data'
      },
      data: formData
    };
    axios
      .request(config)
      .then((res) => {
        if (res?.data?.statusCode === 200) {
          setLinkInputs(res.data.entity)
          setLoading(false);

          if (quoteId && userDataContext?.website_url?.includes("glideline")) {
            updateQuotationInvoiceJsonDataService(quoteId)
          }

        } else {
          setMessage(
            res?.data?.message ? res.data.message : "Something went wrong"
          );
          setMessageType("error");
          setLoading(false);
        }
      }
      )
      .catch((error) => {
        console.log(error);
        setLoading(false);
        setMessage(error.message ? error.message : "Something went wrong");
        setMessageType("error");

        if (error?.message === "Network Error") {
          navigate("/error/network");
        }
      });
  }

  const handleLinkInputChange = (index, name) => {
    const selectedLink = printingLinks.find(link => link?.name === name);

    if (selectedLink) {
      const updatedLinkInputs = [...linkInputs];
      updatedLinkInputs[index].name = name;
      updatedLinkInputs[index].documentId = selectedLink.id;
      updatedLinkInputs[index].link = `${baseURL}/user/quotation/${quoteId}/print-view/${selectedLink.id}`;
      setLinkInputs(updatedLinkInputs);
    }
  };

  const handleAddLinkInput = () => {
    if (printingLinks && printingLinks.length > 0) {
      setLinkInputs([
        ...linkInputs,
        {
          id: 0,
          documentId: printingLinks[0].id,
          name: printingLinks[0].name,
          date: new Date(),
          link: `${baseURL}/user/quotation/${quoteId}/print-view/${printingLinks[0].id}`
        },
      ]);
    } else {
      console.error("Error: Unable to retrieve documentid from printingLinks");
    }
  };

  const handleDateChange = (index, date) => {
    const updatedLinkInputs = [...linkInputs];
    updatedLinkInputs[index].date = date;
    setLinkInputs(updatedLinkInputs);
  };

  const handleSave = () => {
    console.log(linkInputs, "102");
    const formData = linkInputs.map((link) => ({
      id: link?.id,
      quotationId: quoteId,
      documentId: link?.documentId,
      name: link?.name,
      expiryDate: moment(link?.date).format("YYYY-MM-DDTHH:mm:ss"),
      link: link.link,
      sequenceNo: 0,
      userId: null
    }));
    const jsonData = JSON.stringify(formData, null, 2);
    console.log('Form Data:', jsonData);
    setLoading(true)
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${servicePath}/Document/AddUpdateCustomerLink`,
      headers: {
        accept: "text/plain",
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(formData, null, 2),
    };
    axios
      .request(config)
      .then((res) => {
        if (res && res.status === 200) {
          if (res?.data?.statusCode === 200) {
            setMessageType("success");
            setMessage(
              res?.data?.message
                ? res.data.message
                : "Link Save Successfully"
            );
            getLinkList()
            setLoading(false);
          } else {
            setMessage(
              res?.data?.message ? res.data.message : "Something went wrong"
            );
            setMessageType("error");

            getLinkList()
            setLoading(false);
          }
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        setMessage(error.message ? error.message : "Something went wrong");
        setMessageType("error");

        if (error?.message === "Network Error") {
          navigate("/error/network");
        }
      });
  };

  const handleDeleteList = (id) => {
    const formData = new FormData();
    formData.append("Id", id);
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${servicePath}/Document/DeleteCustomerLink`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data'
      },
      data: formData
    };
    axios
      .request(config)
      .then((res) => {
        if (res?.data?.statusCode === 200) {
          const updatedLinkInputs = [...linkInputs];
          updatedLinkInputs.splice(linkInputs.id,1);
          setLinkInputs(updatedLinkInputs);
          getLinkList()
          setLoading(false);
        } else {
          setMessage(
            res?.data?.message ? res.data.message : "Something went wrong"
          );
          setMessageType("error");
          setLoading(false);
        }
      }
      )
      .catch((error) => {
        console.log(error);
        setLoading(false);
        setMessage(error.message ? error.message : "Something went wrong");
        setMessageType("error");

        if (error?.message === "Network Error") {
          navigate("/error/network");
        }
      });
  }
  

  return (
    <>
      <div className='fixed_m_scroll full_h d-flex flex-column justify-content-between'>
        <div>
          <Button
            color='primary'
            size='md'
            className='px-4 w-100 d-flex justify-content-between fw-medium mb-4'
            onClick={handleAddLinkInput}
          >
            <MdAdd size={18} className='me-1' />
            Add Links
            <span></span>
          </Button>

          {linkInputs && linkInputs.length > 0 && (
            linkInputs.map((link, index) => (
              <div className='mb-3 border-bottom' key={link?.id || index}>
                <div className='d-flex flex-column flex-sm-row justify-content-between align-items-center gap-2 mb-2'>
                  <div className='w-100 w-sm-50'>
                    <FormGroup className='mb-0'>
                      <Label for={`linkName-${link?.id}`}>Document</Label>
                      <Select
                        labelId={`linkColorLabel-${link?.id}`}
                        id={`test_linkColor-${link?.id}`}
                        value={link?.name}
                        onChange={(e) => handleLinkInputChange(index, e.target.value)}
                        input={<OutlinedInput className='w-100' name={`linkColor-${link?.name? link?.name: link?.documentName}`} />}
                        MenuProps={MenuProps}
                        IconComponent={() => (
                          <MdOutlineKeyboardArrowDown
                            size={20}
                            className='me-1 MuiSvgIcon-root MuiSelect-icon'
                          />
                        )}
                      >
                        {printingLinks.map((c, index) => (
                          <MenuItem key={index} value={c.name}>
                            {c.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormGroup>
                  </div>

                  <div className='w-100 w-sm-50 d-flex gap-2'>
                    <div className="w-100">
                      <FormGroup className='d-flex flex-column mb-0'>
                        <Label for={`linkDate-${link?.date}`}>Expiry date</Label>
                        <ReactDatePicker
                          className='form-control'
                          selected={link?.date ? link?.date : moment(link?.expiryDate, "YYYY-MM-DD").toDate()}
                          onChange={(date) => handleDateChange(index, date)}
                          dateFormat="dd-MM-yyyy"
                        />
                      </FormGroup>
                    </div>

                    <div className="d-flex align-items-end">
                      <Link to={{}} className="text-danger mb-2" onClick={() => handleDeleteList(link?.id)}>
                        <BiTrash size={20} />
                      </Link>
                    </div>
                  </div>
                </div>
                <div className='mb-3 border-bottom'>
                  <div className='w-100 mb-2'>
                    <a href={link?.link} target='_blank'>
                      {link?.link}
                    </a>
                  </div>
                </div>
              </div>
            ))
          )}

        </div>
      </div>
      <div className='w-100 d-flex justify-content-end fw-medium mb-4'>
        <Button color='primary' size='md' onClick={handleSave} className='px-4'>
          Save
        </Button>
      </div>
    </>
  );
};

export default AddLinksModal;
