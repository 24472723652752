import axios from "axios";
import { servicePath } from "../../utility/data";

// get all right menu item list based on page/type
export const getRightMenuList = (accessToken) => {

    let config = {
        method: 'GET',
        maxBodyLength: Infinity,
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'multipart/form-data'
        }
    };

    return axios
        .get(`${servicePath}/General/GetAllGeneralPromotion`, config)
        .then((res) => {
            return res;
        })
        .catch((error) => {
            return error;
        });
}

// get details of selected item in right menu
export const getItemDetails = (accessToken, id) => {
    const formData = new FormData();
    formData.append("Id", id);

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'multipart/form-data'
        }
    };

    return axios
        .post(`${servicePath}/General/GetGeneralPromotion`, formData, config)
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};

// delete selected item
export const deleteRightItem = (accessToken, id) => {
    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    const formData = new FormData()
    formData.append("Id", id)
    return axios
        .post(`${servicePath}/General/DeleteGeneralPromotion`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}

// update order of selected item
export const updateOrder = (accessToken, id, direction) => {

    console.log(id, direction, "order test")

    var requestBody = {
        "id": id,
        "sequenceNo": 0,
        "type": direction
    };

    const headers = {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
    }

    return axios
        .post(`${servicePath}/General/AddUpdateGeneralPromotion`, requestBody, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}

export const updateGeneralPromotionOrder = (accessToken, id, direction) => {

    console.log(id, direction, "order test")

    var requestBody = {
        "id": id,
        "sequenceNo": 0,
        "type": direction
    };

    const headers = {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
    }

    return axios
        .post(`${servicePath}/General/UpdateGeneralPromotionOrder`, requestBody, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}

// update details of selected item
export const updateItemDetails = (accessToken, id, data) => {

    const headers = {
        'accept': 'text/plain',
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data'
    }

    const formData = new FormData();

    formData.append("Id", id);
    formData.append('Name', data.name);
    formData.append('Description', data.Description);
    formData.append('Default', data.Default);
    formData.append('Maximum', data.Maximum);
    formData.append('DiscountType', data.DiscountType);
    formData.append('AddAutomatically', data.AddAutomatically);
    formData.append('AddPercentageToName', data.AddPercentageToName);
    formData.append('Disabled', data.Disabled);
    formData.append('HidePercentage', data.HidePercentage);

    if (data.DiscountableItems) {
        for (let i = 0; i < data.DiscountableItems.length; i++) {
            formData.append(`DiscountableItems`, data.DiscountableItems[i]);
        }
    }

    return axios
        .post(`${servicePath}/General/AddUpdateGeneralPromotion`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};

// add new right menu item based on page/type
export const addNewRightItem = (accessToken, data) => {
    const formData = new FormData();

    formData.append("Id", '');
    formData.append('Name', data);
    formData.append('DiscountType', "Percentage");

    const headers = {
        'accept': 'text/plain',
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data'
    }

    return axios
        .post(`${servicePath}/General/AddUpdateGeneralPromotion`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};