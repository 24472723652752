import React from 'react'
import { useState } from 'react';
import { useProductPaletteContext } from '../../context/productPaletteContext';
import {
    Row,
    Col,
    Button,
    Label,
    FormGroup,
    Input,
} from 'reactstrap';
import { Form } from 'react-bootstrap';
import { MenuItem, OutlinedInput, Select } from 'material-ui-core';
import { MdOutlineKeyboardArrowDown } from 'react-icons/md';
import { MenuProps } from '../../utility/data';
import { addUpdateGlazingPalette, uniqueGlazingPalette } from '../../services/glazingServices';
import { useMessage } from '../../context/messageContext';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { convertCanvasToImageFile, rgbStringToHex } from '../../utility/helper';
import { useRightContext } from '../../context/rightMenuContext';


const SpacebarPalette = ({ allPalette, getAllPaletteServices, setLoading, setCommonHeading, tabType, allColours }) => {

    const accessToken = localStorage.getItem('access_token');

    const { handleEdit, setTimestamp, activeIndex, timestamp, handleDescEdit, setActiveIndex, newItemList, handleModificationChange } = useProductPaletteContext();
    const { setMessage, setMessageType } = useMessage()
    const [name, setName] = useState('')
    const [desc, setDesc] = useState('')
    const [colour, setColour] = useState('')
    const [id, setId] = useState()

    const navigate = useNavigate()

    const { setSelectedRightItem, selectedRightItem } = useRightContext()
    const firstEl = allPalette[0];

    useEffect(() => {
        setActiveIndex(0)
    }, [tabType])

    useEffect(() => {
        if (firstEl && tabType === 'palette') {
            if (allPalette?.length && activeIndex !== 0 && tabType === 'palette') {
                setTimestamp(timestamp)
            } else {
                setTimestamp(firstEl?.id)
            }
        }
    }, [allPalette, timestamp, tabType])

    useEffect(() => {
        if (newItemList?.length === 0) {
            setTimestamp('')
            setName(' ')
            setDesc(' ')
            setColour(0)
        }
    }, [newItemList])

    useEffect(() => {
        if (timestamp && tabType === 'palette') {
            getUniquePaletteServices()
        }
    }, [timestamp])

    const handleNameChange = (e) => {
        const value = e.target.value;
        if (e.target.value?.length <= 50) {
            setName(value)
            handleEdit(value)
            setCommonHeading(value)
        }
    }

    const handleColour = (e) => {
        const value = e.target.value;
        setColour(typeof value === "string" ? value.split(",") : value);
        const selectedColor = allColours.find((item) => item?.id === value);
        handleModificationChange("hex", selectedColor?.hexValue);
    }

    const reset = () => {
        getUniquePaletteServices()
        getAllPaletteServices()
    }




    const getUniquePaletteServices = async () => {
        setLoading(true)
        const res = await uniqueGlazingPalette(timestamp, accessToken)
        if (res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                // console.log(res.data, "81");
                const selectedRight = allPalette?.find((p) => p.id == timestamp)
                setSelectedRightItem(selectedRight)
                setName(res?.data.entity?.name)
                setCommonHeading(res?.data.entity?.name)
                setDesc(res?.data.entity?.description ? res?.data.entity?.description : "")
                setColour(res?.data.entity?.colourId)
                setLoading(false)
                setId(res?.data.entity?.id)
                setTimestamp(timestamp)
            } else {
                setLoading(false)
                setMessage(res?.data?.message ? res.data.message : 'Something went wrong');
                setMessageType('error')
            }
        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')
            if (res?.message === "Network Error") {
                navigate('/error/network')
                setLoading(false)
            }

        }
    }

    const updateGlazingPaletteServices = async () => {

        const capturedFile = convertCanvasToImageFile();

        setLoading(true)
        const data = {
            id: timestamp,
            name: name,
            desc: desc,
            colourId: colour,
            typeId: 2,
            thickness: "",
            CapturedFile: capturedFile,
        }
        const res = await addUpdateGlazingPalette(data, accessToken)
        if (res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                setMessageType('success')
                setMessage(res?.data?.message ? res.data.message : 'Added new colour')
                getAllPaletteServices()
                getUniquePaletteServices()
                setLoading(false)
            } else {
                setMessage(res?.data?.message ? res.data.message : 'Something went wrong')
                setMessageType('error')
                getAllPaletteServices()
                getUniquePaletteServices()
                setLoading(false)

            }
        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')
            getAllPaletteServices()
            if (res?.message === "Network Error") {
                navigate('/error/network')
            }
        }
    }

    const handleDescChange = (e) => {
        const value = e.target.value
        if (e.target.value.length <= 80) {
            setDesc(value)
            handleDescEdit(value)
        }
    }




    return (
        <>
            <Form className='h-100 d-flex flex-column flex-grow-1 justify-content-between'>
                <div className='inside_scroll has_btns has_head'>
                    {/* <div className='d-flex align-items-center mb-3'>
                        {name && (
                            <h5 className="mb-0 text-wrap text-break me-4 main_title">{name}</h5>
                        )}
                    </div> */}
                    <Row>
                        <Col lg="6">
                            <FormGroup className="form-group">
                                <Label for="name">
                                    Name
                                </Label>
                                <Input disabled={!selectedRightItem?.isSelf} className='mb-1' name='name' value={name} onChange={(e) => handleNameChange(e)} />
                                {name && name.length > 49 && (
                                    <div className='mt-1 text-muted text-small'>Max 50 characters are allowed</div>
                                )}

                                {(!name && id) && (
                                    <div className='mt-1 text-danger text-small'>Name is required</div>
                                )}
                            </FormGroup>
                        </Col>
                        <Col lg="6" >
                            <FormGroup className="form-group">
                                <Label for="description">
                                    Description
                                </Label>
                                <Input disabled={!selectedRightItem?.isSelf} className='mb-1' name='description' value={desc} onChange={(e) => handleDescChange(e)} />
                                {desc && desc.length > 79 && (
                                    <div className='mt-1 text-muted text-small'>Max 80 characters are allowed</div>
                                )}
                            </FormGroup>
                        </Col>
                        <Col lg="6">
                            <FormGroup className="form-group">
                                <Label for="colours">
                                    Colours
                                </Label>
                                <Select
                                    labelId="printdoc-label"
                                    id="colors"
                                    value={colour}
                                    label="colors"
                                    disabled={!selectedRightItem?.isSelf}
                                    onChange={handleColour}
                                    input={<OutlinedInput className='w-100' name='colours' />}

                                    MenuProps={MenuProps}
                                    IconComponent={() => (
                                        <MdOutlineKeyboardArrowDown size={20} className='me-1 MuiSvgIcon-root MuiSelect-icon' />
                                    )}
                                >
                                    {allColours.map((item, index) => {
                                        return (
                                            <MenuItem value={item?.id} key={index} >{item?.name}</MenuItem>

                                        )
                                    })}
                                </Select>
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
                {selectedRightItem?.isSelf && <div className='d-flex gap-2 gap-xl-3 main_buttons mt-4'>
                    <Button
                        color="primary"
                        size="md"
                        className='px-4'
                        onClick={() => updateGlazingPaletteServices()}
                        disabled={allPalette?.length === 0 || desc.length > 79 || !name || name.length > 49}
                    >
                        Save Changes
                    </Button>

                    <Button
                        color="primary"
                        size="md"
                        outline
                        className='px-4'
                        onClick={() => reset()}
                        disabled={allPalette?.length === 0 || desc.length > 79 || !name || name.length > 49}
                    >
                        Reset
                    </Button>
                </div>}
            </Form>
        </>
    )
}

export default SpacebarPalette