import React, { useState, useEffect } from 'react'
import {
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
} from 'reactstrap';
import DocsGeneral from '../../../../../../components/DocsGeneral';
import { MdMoreHoriz } from 'react-icons/md';
import DocsHeader from '../../../../../../components/DocsHeader';
import DocsForeword from '../../../../../../components/DocsForeword';
import DocsTerms from '../../../../../../components/DocsTerms';
import DocsFrame from '../../../../../../components/DocsFrame';
import DocsSummary from '../../../../../../components/DocsSummary';
import DocsManufacturing from '../../../../../../components/DocsManufacturing';
import { useRightContext } from '../../../../../../context/rightMenuContext';

const DocumentsLeft = ({ fontOptions, accessToken, activeTab, setActiveTab, selectItemDetails, updateItemDetailsServices, handleFormReset }) => {

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [generalTabData, setGeneralTabData] = useState([]);
    const [headerTabData, setHeaderTabData] = useState([]);
    const [forewordTabData, setForewordTabData] = useState([]);
    const [frameTabData, setFrameTabData] = useState([]);
    const [summaryTabData, setSummaryTabData] = useState([]);
    const [termTabData, setTermTabData] = useState([]);
    const [manufacturingTabData, setManufacturingTabData] = useState([]);

    const toggleTabDropdown = () => setDropdownOpen((prevState) => !prevState);

    const handleActiveTab = (tab) => {
        setActiveTab(tab)
    }

    const { timestamp, selected, setSelected, handleEdit } = useRightContext()

    // setting all the value for prefilled inputs
    useEffect(() => {

        if (activeTab === "General") {
            setGeneralTabData(selectItemDetails)

            setSelected(selectItemDetails?.name)
        }

        if (activeTab === "Header") {

            setHeaderTabData(selectItemDetails)
        }

        if (activeTab === "Foreword") {
            setForewordTabData(selectItemDetails)
        }

        if (activeTab === "Frame") {
            setFrameTabData(selectItemDetails)
        }

        if (activeTab === "Summary") {
            setSummaryTabData(selectItemDetails)
        }

        if (activeTab === "Term") {
            setTermTabData(selectItemDetails)
        }

        if (activeTab === "Manufacturing") {
            setManufacturingTabData(selectItemDetails)
        }
    }, [selectItemDetails])

    // accepting the values form input and updating into the title and the right side list
    const handleTitleChange = (value) => {
        handleEdit(value)

        setSelected(value)
    }

    return (
        <div className='d-flex flex-column h-100'>

            <div className='d-flex align-items-center mb-3 flex-wrap gap-2 left_header'>
                {selected && (
                    <h5 className="mb-0 text-wrap text-break main_title">{selected}</h5>
                )}
            </div>

            <Nav tabs className="details_header border-0 nav-tabs-custom">
                <NavItem>
                    <NavLink
                        className={`${activeTab === "General" && 'active'}`}
                        onClick={() => { handleActiveTab("General") }}
                    >
                        General
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={`${activeTab === "Header" && 'active'}`}
                        onClick={() => { handleActiveTab("Header") }}
                    >
                        Header
                    </NavLink>
                </NavItem>

                <NavItem>
                    <NavLink
                        className={`${activeTab === "Foreword" && 'active'}`}
                        onClick={() => { handleActiveTab("Foreword") }}
                    >
                        Foreword
                    </NavLink>
                </NavItem>

                <NavItem className='d-none d-sm-block d-lg-block'>
                    <NavLink
                        className={`${activeTab === "Frame" && 'active'}`}
                        onClick={() => handleActiveTab("Frame")}
                    >
                        Frames
                    </NavLink>
                </NavItem>

                <NavItem className='d-none d-sm-block d-md-none d-lg-block'>
                    <NavLink
                        className={`${activeTab === "Summary" && 'active'}`}
                        onClick={() => { handleActiveTab("Summary") }}
                    >
                        Summary
                    </NavLink>
                </NavItem>
                <NavItem className='d-none d-lg-block'>
                    <NavLink
                        className={`${activeTab === "Term" && 'active'}`}
                        onClick={() => { handleActiveTab("Term") }}
                    >
                        Terms
                    </NavLink>
                </NavItem>
                <NavItem className='d-none d-xl-block'>
                    <NavLink
                        className={`${activeTab === "Manufacturing" && 'active'}`}
                        onClick={() => { handleActiveTab("Manufacturing") }}
                    >
                        Manufacturing
                    </NavLink>
                </NavItem>

                <Dropdown className='d-xl-none' isOpen={dropdownOpen} toggle={toggleTabDropdown}>
                    <DropdownToggle color='none' className='border-0 p-0'>
                        <MdMoreHoriz size={20} />
                    </DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem className='d-sm-none' href="#Foreword" onClick={() => handleActiveTab("Frame")}>
                            Frames
                        </DropdownItem>
                        <DropdownItem className='d-sm-none d-md-block d-lg-none' href="#Summary" onClick={() => handleActiveTab("Summary")}>
                            Summary
                        </DropdownItem>
                        <DropdownItem className='d-lg-none' href="#Term" onClick={() => handleActiveTab("Term")}>
                            Terms
                        </DropdownItem>
                        <DropdownItem className="d-xl-none" href="#Manufacturing" onClick={() => handleActiveTab("Manufacturing")}>
                            Manufacturing
                        </DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            </Nav>

            <TabContent activeTab={activeTab} className='flex-grow-1 d-flex'>
                <TabPane tabId="General" className='flex-grow-1 w-100'>
                    {activeTab === "General" && (
                        <DocsGeneral activeTab={activeTab} accessToken={accessToken} handleFormReset={handleFormReset} selectItemDetails={generalTabData} handleTitleChange={handleTitleChange} updateItemDetailsServices={updateItemDetailsServices} />
                    )}
                </TabPane>
                <TabPane tabId="Header" className='w-100'>
                    {activeTab === "Header" && (
                        <DocsHeader fontOptions={fontOptions} handleFormReset={handleFormReset} accessToken={accessToken} selectItemDetails={headerTabData} updateItemDetailsServices={updateItemDetailsServices} />
                    )}
                </TabPane>
                <TabPane tabId="Foreword" className='w-100'>
                    {activeTab === "Foreword" && (
                        <DocsForeword activeTab={activeTab} fontOptions={fontOptions} handleFormReset={handleFormReset} accessToken={accessToken} selectItemDetails={forewordTabData} updateItemDetailsServices={updateItemDetailsServices} />
                    )}
                </TabPane>
                <TabPane tabId="Frame" className='w-100'>
                    {activeTab === "Frame" && (
                        <DocsFrame activeTab={activeTab} fontOptions={fontOptions} handleFormReset={handleFormReset} accessToken={accessToken} selectItemDetails={frameTabData} updateItemDetailsServices={updateItemDetailsServices} />
                    )}
                </TabPane>
                <TabPane tabId="Summary" className='w-100'>
                    {activeTab === "Summary" && (
                        <DocsSummary handleFormReset={handleFormReset} accessToken={accessToken} selectItemDetails={summaryTabData} updateItemDetailsServices={updateItemDetailsServices} />
                    )}
                </TabPane>
                <TabPane tabId="Term" className='w-100'>
                    {activeTab === "Term" && (
                        <DocsTerms activeTab={activeTab} fontOptions={fontOptions} handleFormReset={handleFormReset} accessToken={accessToken} selectItemDetails={termTabData} updateItemDetailsServices={updateItemDetailsServices} />
                    )}
                </TabPane>
                <TabPane tabId="Manufacturing" className='w-100'>
                    {activeTab === "Manufacturing" && (
                        <DocsManufacturing handleFormReset={handleFormReset} accessToken={accessToken} selectItemDetails={manufacturingTabData} updateItemDetailsServices={updateItemDetailsServices} />
                    )}
                </TabPane>
            </TabContent>
        </div>
    )
}

export default DocumentsLeft