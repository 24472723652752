import React, { useEffect, useRef, useState } from "react";
import { Image } from "react-bootstrap";
import { BiArrowBack, BiSolidEditAlt } from "react-icons/bi";
import "choices.js/public/assets/styles/choices.css";
import { Alert, Button, FormGroup, Input, Label, NavLink } from "reactstrap";
import { addUpdateAdditionalProfileFormula } from "../services/ManufacturingServices";
import { useMessage } from "../context/messageContext";
import Choices from "choices.js";
import Multiselect from "multiselect-react-dropdown";



const ManufacturingAdditionalProfile = ({
  additionalProfileList,
  isAdditionalProfileEdit,
  setIsAdditionalProfileEdit,
  setIsEdit,
  setOpen,
  referencePoint,
  frameStyleId,
  accessToken,
  currentItem,
  getData,
  referencePointName,
  additionalProfiles,
  open,
  additionalProfileStatusServices
}) => {
  const [heightFormula, setHeightFormula] = useState("");
  const [widthFormula, setWidthFormula] = useState("");
  const [articleId, setArticleId] = useState(null);

 

  // const selectRef = useRef(null);
  // const choicesRef = useRef(null)

//   const options = [
//     {
//         "label": "Additional Profile 1",
//         "value": "234",
//         "selected" : false,
//         "disabled" : false
//     },
  
// ]


  useEffect(() => {
    if (
      articleId &&
      additionalProfileList &&
      additionalProfileList?.length > 0
    ) {
      const data = additionalProfileList?.find((ele) => ele?.id === articleId);
      setWidthFormula(data?.widthFormula ? data?.widthFormula : "");
      setHeightFormula(data?.heightFormula ? data?.heightFormula : "");
    }
  }, [articleId, additionalProfileList]);


  // useEffect(() => {
  //   if (selectRef.current && open) {

  //     choicesRef.current = new Choices(selectRef.current);

  //     choicesRef.current.setChoices(
  //       options
  //     );

  //   }
  // }, [selectRef.current, options, open]);

  const { setMessage, setMessageType } = useMessage();

  const handleHeightFormula = (e) => {
    const value = e.target.value;
    setHeightFormula(value);
  };

  const handleWidthFormula = (e) => {
    const value = e.target.value;
    setWidthFormula(value);
  };

  const saveFormula = async () => {
    const data = {
      id: 0,
      articleId: articleId,
      widthFormula: widthFormula,
      heightFormula: heightFormula,
      referencePoint: referencePoint,
      frameStyleId: frameStyleId,
      profileId: currentItem?.id,
    };
    const res = await addUpdateAdditionalProfileFormula(accessToken, data);
    if (res?.data?.statusCode === 200) {
      setMessageType("success");
      setMessage(res?.data?.message);
      getData(referencePoint);
    } else {
      setMessageType("error");
      setMessage(res?.data?.message);
    }
  };


  return (
    <>
      {!isAdditionalProfileEdit && (
        <div className="mt-1 right_list">
          {/* {additionalProfileList && additionalProfileList.length > 0 ? (
            additionalProfileList?.map((item, index) => (
              <div
                key={index}
                className="position-relative d-flex align-items-center right_item_wrap has_delete has_copy"
              >
                <NavLink className={`right_item w-100`}>
                  <span className={`me-2 image_icon`}>
                    {item.imagePath ? (
                      <Image src={item.imagePath} />
                    ) : (
                      <svg viewBox="0 0 100 50" width="50" height="50">
                        <path
                          className="stroke"
                          stroke="#989898"
                          fill="#fff"
                          strokeWidth="2"
                          d={`M 0,50 0,0 100,0 100,50 Z`}
                        ></path>
                      </svg>
                    )}
                  </span>
                  <div className="right_title">
                    <span className={`text-truncate text-dark`}>
                      {item.name}
                    </span>
                  </div>
                  <span className="position-absolute action_btns">
                    <NavLink
                      onClick={() => {
                        setIsAdditionalProfileEdit(true);
                        setArticleId(item?.id);
                      }}
                    >
                      <BiSolidEditAlt className="text-muted" size={18} />
                    </NavLink>
                  </span>
                </NavLink>
              </div>
            ))
          ) : (
            <Alert color="secondary" className="d-flex gap-2 p-2 my-2">
              <span>
                <MdInfoOutline size={18} />
              </span>
              <span>No additional profiles to show</span>
            </Alert>
          )} */}

          <Multiselect
            displayValue="name"
            onKeyPressFn={function noRefCheck() { }}
            onRemove={function removeItem(selectedList, selectedItem) {
              const updatedItem = { ...selectedItem, status: false }
              additionalProfileStatusServices(updatedItem)
            }}
            onSearch={function noRefCheck() { }}
            onSelect={function noRefCheck(selectedList, selectedItem) {
              const updatedItem = { ...selectedItem, status: true }
              additionalProfileStatusServices(updatedItem)
            }}
            options={additionalProfiles} // This will contain filtered suggestions

            style={{
              multiselectContainer: {
                color: 'black',
                minHeight: "20vh"
              },
            }}
            showCheckbox
          />


        </div>
      )}
      {isAdditionalProfileEdit && (
        <div className="text-black">
          <NavLink
            onClick={() => {
              setIsAdditionalProfileEdit(false);
              setOpen("0");
            }}
            className="text-link mb-3"
          >
            <BiArrowBack size={20} />
            <span className="ms-1">Back</span>
          </NavLink>
          <div className="text-dark">
            <div className="cutting_section">
              <h6 className="mb-2 text-muted">Cutting Formula</h6>

              {referencePointName?.includes("horizontal") ? (
                <FormGroup className="mb-3 d-flex align-items-center gap-2">
                  <Label for="width" className="mb-0 mini_text">
                    Length
                  </Label>
                  <Input
                    name="width"
                    onChange={handleWidthFormula}
                    value={widthFormula}
                    id="width"
                  />
                </FormGroup>
              ) : null}
              {referencePointName?.includes("vertical") ? (
                <FormGroup className="mb-3 d-flex align-items-center gap-2">
                  <Label for="height" className="mb-0 mini_text">
                    Length
                  </Label>
                  <Input
                    name="height"
                    onChange={handleHeightFormula}
                    id="height"
                    value={heightFormula}
                  />
                </FormGroup>
              ) : null}
            </div>
          </div>

          <div className="d-flex gap-2 gap-xl-3 main_buttons w-100 mt-3">
            <Button
              color="primary"
              size="md"
              className="px-4"
              onClick={() => {
                saveFormula();
              }}
            >
              Save
            </Button>

            <Button
              color="primary"
              size="md"
              outline
              className="px-4"
              onClick={() => {
                setIsEdit(false);
                setIsAdditionalProfileEdit(false);
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default ManufacturingAdditionalProfile;
