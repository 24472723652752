import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import { useRightContext } from "../../../../../../context/rightMenuContext";
import Editprices from "../../../../../../components/Editprices";
import { MenuItem, OutlinedInput, Select } from "material-ui-core";
import { MenuProps } from "../../../../../../utility/data";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import NumberInputDecimal from "../../../../../../components/NumberInputDecimal";
import { getProfileTypeMaster } from "../../../../../../services/commonServices";
import { getAllColoursCollection } from "../../../../../../services/productServices";
import DropZone from "../../../../../../components/DropZone";

const AdditionalProfileLeft = ({
    accessToken,
    selectItemDetails,
    pageName,
    setPageName,
    addNewRightItemServices,
    handleFormReset,
}) => {
    var acceptFileType = {
        'image/png': ['.jpg', '.jpeg', '.png', '.svg', '.gif'],
    }

    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [productCode, setProductCode] = useState("");
    const [nameError, setNameError] = useState(false);
    const [itemId, setItemId] = useState("");
    const [selectedPriceType, setSelectedPriceType] = useState("")
    const [selectedPosition, setSelectedPosition] = useState("")
    const [externalPaintSurfaceArea, setExternalPaintSurfaceArea] = useState(0);
    const [internalPaintSurfaceArea, setInternalPaintSurfaceArea] = useState(0)
    const [weight, setWeight] = useState(0);
    const [profileType, setProfileType] = useState("1");
    const [profileTypeOptions, setProfileTypeOptions] = useState([]);
    const [availableProfileColurData, setAvailaleProfileColurData] = useState([]);
    const [profileColor, setProfileColor] = useState("")

    const [uploadedFile, setUploadedFile] = useState('')
    const [filePreview, setFilePreview] = useState('')


    const priceTypeOptions = [
        {
            id: 1,
            name: "Per Meter"
        },
        {
            id: 2,
            name: "Quantity"
        }
    ]

    const positionsOptions = [
        {
            id: 1,
            name: "Top-left"
        },
        {
            id: 2,
            name: "Top-right"
        }, {
            id: 3,
            name: "Bottom-left"
        }, {
            id: 4,
            name: "Bottom-right"
        },
    ]

    const {
        timestamp,
        selected,
        setSelected,
        selectedRightItem,
        handleFileUpdate
    } = useRightContext();

    useEffect(() => {
        getProfileTypeOptions();
        getProfileColourData();
    }, [])

    useEffect(() => {
        setSelected(selectItemDetails?.name);
        setPageName(selectItemDetails?.name);

        setName(selectItemDetails?.name || "");
        setItemId(selectItemDetails?.id || "");
        setDescription(selectItemDetails?.description || "");
        setProductCode(selectItemDetails?.productCode || "");
        setSelectedPriceType(selectItemDetails?.priceType || "")
        setProfileType(selectItemDetails?.profileType || "")
        setProfileColor(selectItemDetails?.colourCollection || "")
        setExternalPaintSurfaceArea(selectItemDetails?.externalPaintSurfaceArea || 0);
        setInternalPaintSurfaceArea(selectItemDetails?.internalPaintSurfaceArea || 0);
        setWeight(selectItemDetails?.weight || 0);
        setSelectedPosition(selectItemDetails?.position || "")
        setUploadedFile('')
        setFilePreview(selectItemDetails?.filePathUrl || '')
        setNameError(false);
    }, [selectItemDetails]);

    useEffect(() => {
        if (filePreview) {
            handleFileUpdate(filePreview)
        }
    }, [filePreview])

    // This is for validating the values must be positive
    useEffect(() => {
        if (externalPaintSurfaceArea < 0) {
            setExternalPaintSurfaceArea(0)
        }
        if (internalPaintSurfaceArea < 0) {
            setInternalPaintSurfaceArea(0)
        }
        if (weight < 0) {
            setWeight(0)
        }
    }, [externalPaintSurfaceArea, internalPaintSurfaceArea, weight])

    // get Profile type from master API
    const getProfileTypeOptions = async () => {
        const res = await getProfileTypeMaster(accessToken);

        if (res && res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                if (res?.data?.entity !== null) {
                    if (res?.data?.entity?.length > 0) {
                        setProfileTypeOptions(res?.data?.entity)
                    }
                }
            }
        }
    }

    // Getting color data
    const getProfileColourData = async () => {

        const res = await getAllColoursCollection(1, accessToken);

        if (res && res.status === 200) {
            if (res?.data?.entity?.length > 0) {
                setAvailaleProfileColurData(res?.data?.entity);
            }
        }
    };

    const handleName = (e) => {
        const value = e.target?.value;
        if (value?.length <= 50) {
            setName(value);
        } else {
            setNameError(true);
        }
    };

    const handleProductCode = (e) => {
        const value = e.target?.value;
        if (value?.length <= 50)
            setProductCode(value);
    };

    const handleFormSubmit = () => {
        const data = {
            id: itemId,
            name: name,
            description: description || "",
            productCode: productCode || "",
            typeId: 1,
            priceType: selectedPriceType || "",
            weight: weight !== "" ? weight : 0,
            profileType: profileType || "",
            colorCollection: profileColor || "",
            externalPaintSurfaceArea: externalPaintSurfaceArea !== "" ? externalPaintSurfaceArea : 0,
            internalPaintSurfaceArea: internalPaintSurfaceArea !== "" ? internalPaintSurfaceArea : 0,
            position: selectedPosition || "",
            defaultSide: "",
            filePath:uploadedFile
        };

        addNewRightItemServices(data);
    };

    return (
        <div className="d-flex flex-column h-100">
            <div className="d-flex align-items-center mb-3 flex-wrap gap-2 left_header">
                {selected && (
                    <h5 className="mb-0 text-wrap text-break me-4 main_title">
                        {selected}
                    </h5>
                )}
                <Editprices data={{ url: "/pricing/additional-profile" }} />
            </div>

            <Form className={`h-100 d-flex flex-column flex-grow-1 justify-content-between ${selectedRightItem?.isSelf ? "" : "not_allowed"}`}>
                <div className="inside_scroll has_btns has_head">
                    <Row>
                        <Col lg="6">
                            <FormGroup className="form-group">
                                <Label for="name">Name</Label>
                                <Input
                                    className="mb-1"
                                    name="name"
                                    value={name}
                                    onChange={(e) => handleName(e)}
                                />
                                {nameError && (
                                    <div className="mt-1 text-muted text-small">
                                        Max 50 characters are allowed
                                    </div>
                                )}
                                {!name && (
                                    <div className="mt-1 text-danger text-small">
                                        Name is required
                                    </div>
                                )}
                            </FormGroup>
                        </Col>

                        <Col lg="6">
                            <FormGroup className="form-group">
                                <Label for="description">Description</Label>
                                <Input
                                    className="mb-1"
                                    name="description"
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                />
                            </FormGroup>
                        </Col>

                        <Col lg="6">
                            <FormGroup className="form-group">
                                <Label for="productCode">Product code</Label>
                                <Input
                                    className="mb-1"
                                    name="productCode"
                                    value={productCode}
                                    onChange={(e) => handleProductCode(e)}
                                />
                                {productCode?.length >= 50 && (
                                    <div className="mt-1 text-muted text-small">
                                        Max 50 characters are allowed
                                    </div>
                                )}
                                {!productCode && (
                                    <div className="mt-1 text-danger text-small">
                                        Product code is required
                                    </div>
                                )}
                            </FormGroup>
                        </Col>

                        <Col lg="6">
                            <FormGroup className="form-group">
                                <Label for="priceType">
                                    Price Type
                                </Label>

                                <Select
                                    labelId="priceType"
                                    id="priceType"
                                    value={selectedPriceType}
                                    label="priceType"
                                    onChange={(e) => { setSelectedPriceType(e.target.value) }}
                                    input={<OutlinedInput className='w-100' name='priceType' />}

                                    MenuProps={MenuProps}

                                    IconComponent={() => (
                                        <MdOutlineKeyboardArrowDown size={20} className='me-1 MuiSvgIcon-root MuiSelect-icon' />
                                    )}
                                >
                                    {priceTypeOptions && priceTypeOptions.map((item, index) => (
                                        <MenuItem key={index} value={item?.id}>
                                            {item?.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {!selectedPriceType && (
                                    <div className="mt-1 text-danger text-small">
                                        Price type is required
                                    </div>
                                )}
                            </FormGroup>
                        </Col>

                        <Col lg="6">
                            <FormGroup className="form-group">
                                <Label for="position">
                                    Position
                                </Label>

                                <Select
                                    labelId="position"
                                    id="position"
                                    value={selectedPosition}
                                    label="position"
                                    onChange={(e) => { setSelectedPosition(e.target.value) }}
                                    input={<OutlinedInput className='w-100' name='position' />}

                                    MenuProps={MenuProps}

                                    IconComponent={() => (
                                        <MdOutlineKeyboardArrowDown size={20} className='me-1 MuiSvgIcon-root MuiSelect-icon' />
                                    )}
                                >
                                    {positionsOptions && positionsOptions.map((item, index) => (
                                        <MenuItem key={index} value={item?.id}>
                                            {item?.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {/* {!selectedPosition && (
                                    <div className="mt-1 text-danger text-small">
                                        Position is required
                                    </div>
                                )} */}
                            </FormGroup>
                        </Col>

                        <Col lg="6">
                            <FormGroup className="form-group">
                                <Label for="profileType">
                                    Profile type
                                </Label>

                                <Select
                                    labelId="profileType"
                                    id="profileType"
                                    value={profileType}
                                    label="profileType"
                                    onChange={(e) => { setProfileType(e?.target?.value) }}
                                    input={<OutlinedInput className='w-100' name='profileType' />}

                                    MenuProps={MenuProps}

                                    IconComponent={() => (
                                        <MdOutlineKeyboardArrowDown size={20} className='me-1 MuiSvgIcon-root MuiSelect-icon' />
                                    )}
                                >
                                    {profileTypeOptions && profileTypeOptions.map((o, index) => (
                                        <MenuItem key={index} value={o?.id}>
                                            {o?.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormGroup>
                        </Col>

                        <Col lg="6">
                            <FormGroup className="form-group">
                                <Label for="profileColor">
                                    Available profile colour
                                </Label>

                                <Select
                                    labelId="profileColor"
                                    id="profileColor"
                                    value={profileColor || ''}
                                    label="profileColor"
                                    onChange={(e) => { setProfileColor(e.target.value) }}
                                    input={<OutlinedInput className='w-100' name='profileColor' />}

                                    MenuProps={MenuProps}

                                    IconComponent={() => (
                                        <MdOutlineKeyboardArrowDown size={20} className='me-1 MuiSvgIcon-root MuiSelect-icon' />
                                    )}
                                >
                                    {availableProfileColurData && availableProfileColurData.map((o, index) => (
                                        <MenuItem key={index} value={o?.id}>
                                            {o?.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormGroup>
                        </Col>

                        <Col lg="6">
                            <FormGroup className="form-group">
                                <Label for="externalSurfaceArea">External paint surface area(mm²)</Label>

                                <NumberInputDecimal
                                    name="externalSurfaceArea"
                                    value={externalPaintSurfaceArea}
                                    setValue={setExternalPaintSurfaceArea}
                                />
                                {/* {isNaN(externalPaintSurfaceArea) && (
                                    <div className="mt-1 text-danger text-small">
                                        This field cannot be left empty
                                    </div>
                                )} */}
                            </FormGroup>
                        </Col>

                        <Col lg="6">
                            <FormGroup className="form-group">
                                <Label for="internalPaintSurfaceArea">Internal paint surface area(mm²)</Label>

                                <NumberInputDecimal
                                    name="internalPaintSurfaceArea"
                                    value={internalPaintSurfaceArea}
                                    setValue={setInternalPaintSurfaceArea}
                                />
                            </FormGroup>
                        </Col>

                        <Col lg="6">
                            <FormGroup className="form-group">
                                <Label for="weight">Weight(kg)</Label>

                                <NumberInputDecimal
                                    name="weight"
                                    value={weight}
                                    setValue={setWeight}
                                />
                            </FormGroup>
                        </Col>
                        <Col lg="6">
                            <FormGroup className="form-group">
                                <Label for="uploadFile">Upload image</Label>

                                <DropZone
                                    acceptFileType={acceptFileType}
                                    multiple={false}
                                    uploadedFile={uploadedFile}
                                    setUploadedFile={setUploadedFile}
                                    filePreview={filePreview}
                                    setFilePreview={setFilePreview}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
                
                {selectedRightItem?.isSelf && (
                    <div className="d-flex gap-2 gap-xl-3 main_buttons">
                        <Button
                            color="primary"
                            size="md"
                            className="px-4"
                            disabled={!name || !productCode || !selectedPriceType}
                            onClick={handleFormSubmit}
                        >
                            Save Changes
                        </Button>

                        <Button
                            color="primary"
                            size="md"
                            outline
                            className="px-4"
                            disabled={!timestamp}
                            onClick={() => handleFormReset()}
                        >
                            Reset
                        </Button>
                    </div>
                )}
            </Form>
        </div>
    );
};

export default AdditionalProfileLeft;
