import axios from "axios";
import { servicePath } from "../utility/data";

export const setUpdateProfileStatus = (accessToken, data) => {
  const formData = new FormData();
  formData.append("Id", data?.id);
  formData.append("FrameProfileId", data?.frameProfileId);
  formData.append("FrameStyleId", data?.frameStyleId);
  formData.append("ReferencePoint", data?.referencePoint);
  formData.append("HeightFormula", data?.heightFormula);
  formData.append("WidthFormula", data?.widthFormula);
  formData.append("Status", data?.status);

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  return axios
    .post(
      `${servicePath}/ThreeJSModel/UpdateStatusFrameManufacture`,
      formData,
      {
        headers,
      }
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};

export const setUpdateSashHangingProfileStatus = (accessToken, data) => {
  const formData = new FormData();
  formData.append("Id", data?.id);
  formData.append("SashHangingId", data?.sashHangingId);
  formData.append("FrameStyleId", data?.frameStyleId);
  formData.append("ReferencePoint", data?.referencePoint);
  formData.append("HeightFormula", data?.heightFormula);
  formData.append("WidthFormula", data?.widthFormula);
  formData.append("Status", data?.status);

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  return axios
    .post(
      `${servicePath}/ThreeJSModel/UpdateStatusSashHangingManufacture`,
      formData,
      {
        headers,
      }
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};

export const getAdditionalProfiles = (accessToken, data) => {
  const formData = new FormData();
  formData.append("FrameStyleId", data?.frameStyleId);
  formData.append("ReferencePoint", data?.index);
  formData.append("Type", data?.type);

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  return axios
    .post(
      `${servicePath}/ThreeJSModel/GetManufactureAdditonalProfiles`,
      formData,
      {
        headers,
      }
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};


export const updateAdditionalProfileStatus = (accessToken,referencePoint, data , frameStyleId , typeId) => {
  const formData = new FormData();
  formData.append("Id", data?.id);
  formData.append("TypeId", typeId);
  formData.append("ProfileId", data?.id);
  formData.append("FrameStyleId", frameStyleId);
  formData.append("ReferencePoint", referencePoint);
  formData.append("HeightFormula", 0);
  formData.append("WidthFormula", 0);
  formData.append("TagsIds", 0);
  formData.append("Status", data?.status);
  formData.append("UserId", 0);

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  return axios
    .post(
      `${servicePath}/ThreeJSModel/UpdateStatusManufactureAdditonalProfiles`,
      formData,
      {
        headers,
      }
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};

export const setUpdateFormulaService = (accessToken, data) => {
  const formData = new FormData();
  formData.append("Id", data?.id);
  formData.append("FrameProfileId", data?.frameProfileId);
  formData.append("FrameStyleId", data?.frameStyleId);
  formData.append("ReferencePoint", data?.referencePoint);
  formData.append("HeightFormula", data?.heightFormula);
  formData.append("WidthFormula", data?.widthFormula);
  formData.append("Status", data?.status);
  formData.append("Orientation", data?.orientation);
  data?.tagIds.forEach((item) => {
    formData.append("TagsIds", item);
  });
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  return axios
    .post(
      `${servicePath}/ThreeJSModel/UpdateFormulaFrameManufacture`,
      formData,
      {
        headers,
      }
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};

export const setUpdateSashHangingFormulaService = (accessToken, data) => {
  const formData = new FormData();
  formData.append("Id", data?.id);
  formData.append("SashHangingId", data?.sashHangingId);
  formData.append("FrameStyleId", data?.frameStyleId);
  formData.append("ReferencePoint", data?.referencePoint);
  formData.append("HeightFormula", data?.heightFormula);
  formData.append("WidthFormula", data?.widthFormula);
  formData.append("Status", data?.status);
  data?.tagIds.forEach((item) => {
    formData.append("TagsIds", item);
  });
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  return axios
    .post(
      `${servicePath}/ThreeJSModel/UpdateFormulaSashHangingManufacture`,
      formData,
      {
        headers,
      }
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};

export const getProfileJointsService = (
  access_token,
  frameStyleId,
  referencePoint
) => {
  const formData = new FormData();
  formData.append("FrameStyleId", frameStyleId);
  formData.append("ReferencePoint", referencePoint);

  const headers = {
    Authorization: `Bearer ${access_token}`,
  };
  return axios
    .post(`${servicePath}/ThreeJSModel/GetProfileJoints`, formData, {
      headers,
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};

export const setUpdateProfileJointsStatus = (accessToken, data) => {
  const formData = new FormData();
  formData.append("Id", data?.id);
  formData.append("ProfileJointId", data?.profileJointId);
  formData.append("FrameStyleId", data?.frameStyleId);
  formData.append("ReferencePoint", data?.referencePoint);
  formData.append("Status", data?.status);

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  return axios
    .post(
      `${servicePath}/ThreeJSModel/UpdateStatusProfileJointsManufacture`,
      formData,
      {
        headers,
      }
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};

export const setUpdateManufacturingHardwareStatus = (accessToken, data) => {
  const formData = new FormData();
  formData.append("Id", data?.id);
  formData.append("HardwareStyleId", data?.hardwareStyleId);
  formData.append("ReferencePoint", data?.referencePoint);
  formData.append("FrameStyleId", data?.frameStyleId);
  formData.append("Status", data?.status);

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  return axios
    .post(
      `${servicePath}/ThreeJSModel/UpdateStatusHardwareManufacture`,
      formData,
      {
        headers,
      }
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};

export const addUpdateAdditionalProfileFormula = (accessToken, data) => {
  const formData = new FormData();
  formData.append("Id", data?.id);
  formData.append("ArticleId", data?.articleId);
  formData.append("FrameStyleId", data?.frameStyleId);
  formData.append("ReferencePoint", data?.referencePoint);
  formData.append("HeightFormula", data?.heightFormula);
  formData.append("WidthFormula", data?.widthFormula);
  formData.append("ProfileId", data?.profileId);

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  return axios
    .post(
      `${servicePath}/ThreeJSModel/UpdateFormulaManufactureAdditonalArticle`,
      formData,
      {
        headers,
      }
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};
