import Router from "./routes/router"
import "./assets/css/bootstrap.css"
import "./assets/css/theme.css"
import "./assets/css/style.css"
import 'react-toastify/dist/ReactToastify.css';
import 'react-rangeslider/lib/index.css'
import "./assets/css/custom.css"
import { useEffect } from "react";
import CustomContextAncillary from "./context/ancillarycontext";
import CustomContextRightMenu from "./context/rightMenuContext";
import SashHangingContext from "./context/sashHangingContext";
import CustomMessageContext from "./context/messageContext"
import CustomProductPaletteContext from "./context/productPaletteContext"
import CustomColourContext from "./context/collectionColourContext"
import CustomDesignerContext from "./context/designerContext"
import CustomPanelDesignerContext from "./context/designer2Context";

function App() {
  const isTouch = 'ontouchstart' in window || navigator.maxTouchPoints;

  useEffect(() => {
    if (isTouch) {
      document.body.classList.add('is_touch');
    } else {
      document.body.classList.remove('is_touch');
    }
  }, [isTouch])

  useEffect(() => {
    const handleOnlineStatus = () => {

      if(navigator.onLine) {
        window.location.href = localStorage.getItem('lastVisitedPage') || '/'
      } else {
        window.location.href = "/error/network";
        localStorage.setItem("network_error", true)
      }
    };

    window.addEventListener('online', handleOnlineStatus);
    window.addEventListener('offline', handleOnlineStatus);

    return () => {
      window.removeEventListener('online', handleOnlineStatus);
      window.removeEventListener('offline', handleOnlineStatus);
    };
  }, []);

  return (
    <div className="App">
      <CustomColourContext>
        <CustomProductPaletteContext>
          <CustomContextRightMenu>
            <SashHangingContext>
              <CustomContextAncillary>
                <CustomMessageContext>
                  <CustomDesignerContext>
                    <CustomPanelDesignerContext>
                      <Router />
                    </CustomPanelDesignerContext>
                  </CustomDesignerContext>
                </CustomMessageContext>
              </CustomContextAncillary>
            </SashHangingContext>
          </CustomContextRightMenu>
        </CustomProductPaletteContext>
      </CustomColourContext>
    </div>
  );
}

export default App;
