import axios from "axios";
import { servicePath } from "../utility/data";

export const addUpdateGlazingColours = (options, accessToken) => {

    const formData = new FormData();
    formData.append("Id", options.id || '');
    formData.append("Name", options.name ? options.name : "");
    formData.append("Description", options.desc ? options.desc : "");
    formData.append("Hex", options.hex ? options.hex : "");
    formData.append("Red", !options.red ? 0 : options.red);
    formData.append("Green", options.green);
    formData.append("Blue", !options.blue ? "" : options.blue);
    formData.append("Transparency", !options.transparency ? "" : options.transparency);
    formData.append("Roughness", !options.roughness ? "" : options.roughness);
    formData.append("Metalness", !options.metalness ? 0 : options.metalness);
    formData.append("TypeId", !options.typeId ? "" : options.typeId);
    formData.append("CapturedFile", options.CapturedFile ? options.CapturedFile : "");
    formData.append("CapturedImage", "");



    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    return axios
        .post(`${servicePath}/Glazing/AddUpdateGlazingColour`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}

export const allGlazingColours = (typeId, accessToken) => {
    const formData = new FormData();
    formData.append("TypeId", typeId);

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    return axios
        .post(`${servicePath}/Glazing/GetAllGlazingColour`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}

export const deleteGlazingColours = (id, accessToken) => {
    const formData = new FormData();
    formData.append("Id", id);

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    return axios
        .post(`${servicePath}/Glazing/DeleteGlazingColour`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}

export const deleteGlazingPalette = (id, accessToken) => {
    const formData = new FormData();
    formData.append("Id", id);

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    return axios
        .post(`${servicePath}/Glazing/DeleteGlazingPalette`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}


export const deleteGlazingCollection = (id, accessToken) => {
    const formData = new FormData();
    formData.append("Id", id);

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    return axios
        .post(`${servicePath}/Glazing/DeleteGlazingCollection`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}




export const allGlazingCollection = (typeId, accessToken) => {
    const formData = new FormData();
    formData.append("TypeId", typeId);

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    return axios
        .post(`${servicePath}/Glazing/GetAllGlazingCollection`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}

export const addUpdateGlazingCollection = (data, accessToken) => {

    const formData = new FormData();
    formData.append("Id", data.id)
    formData.append("Name", data.name)
    formData.append("TypeId", data.typeId)
    formData.append("SequenceNo", '')

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    return axios
        .post(`${servicePath}/Glazing/AddUpdateGlazingCollection`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}

export const getUniqueGlazingCollection = (id, accessToken) => {

    const formData = new FormData();
    formData.append("Id", id)

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    return axios
        .post(`${servicePath}/Glazing/GetGlazingCollection`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}

export const uniqueGlazingColours = (id, accessToken) => {
    const formData = new FormData();
    formData.append("Id", id);

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    return axios
        .post(`${servicePath}/Glazing/GetGlazingColour`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}

export const addUpdateGlazingPalette = (options, accessToken) => {

    const formData = new FormData();
    formData.append("Id", options.id || '');
    formData.append("Name", options.name ? options.name : "");
    formData.append("Description", options.desc ? options.desc : "");
    formData.append("Specification", options.specification ? options.specification : "");
    formData.append("ColourId", !options.colourId ? 0 : options.colourId);
    formData.append("Thickness", options.thickness);
    formData.append("NoOfPanes", !options.noOfPanes ? "" : options.noOfPanes);
    formData.append("TextureSide", !options.textureSide ? "" : options.textureSide);
    formData.append("MaximumWidth", !options.maximumWidth ? "" : options.maximumWidth);
    formData.append("MaximumHeight", !options.maximumHeight ? 0 : options.maximumHeight);
    formData.append("Maximumsquare", !options.maximumsquare ? "" : options.maximumsquare);
    formData.append("Weight", !options.weight ? 0 : options.weight);
    formData.append("ImageId", !options.imageId ? "" : options.imageId);
    formData.append("TypeId", !options.typeId ? "" : options.typeId);
    formData.append("HardwareCollectionId", !options.hardwareCollectionId ? "" : options.hardwareCollectionId);
    formData.append("SequenceNo", !options.sequenceNo ? "" : options.sequenceNo);
    formData.append("Style", !options.style ? "" : options.style);
    formData.append("Location", !options.loc ? "" : options.loc);
    formData.append("Pattern", !options.pattern ? "" : options.pattern);
    formData.append("Side", !options.side ? "" : options.side);
    formData.append("Symmetrical", !options.symmetrical ? "" : options.symmetrical);
    formData.append("SymmetricalSide", !options.symmetricalSide ? "" : options.symmetricalSide);
    formData.append("Dimensions", !options.dimension ? "" : options.dimension);
    formData.append("Shape", !options.shape ? "" : options.shape);
    formData.append("Width", !options.width ? "" : options.width);
    formData.append("Height", !options.height ? "" : options.height);
    formData.append("StepWidth", !options.stepWidth ? "" : options.stepWidth);
    formData.append("StepHeight", !options.stepHeight ? "" : options.stepHeight);
    formData.append("RadiusWidth", !options.radiusWidth ? "" : options.radiusWidth);
    formData.append("RadiusHeight", !options.radiusHeight ? "" : options.radiusHeight);
    formData.append("Rows", !options.rows ? "" : options.rows);
    formData.append("ColoursCollectionId", !options.colorCollectionId ? "" : options.colorCollectionId);
    formData.append("CapturedFile", options.CapturedFile ? options.CapturedFile : "");
    formData.append("CapturedImage", "");

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    return axios
        .post(`${servicePath}/Glazing/AddUpdateGlazingPalette`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}

export const allGlazingPalette = (typeId, accessToken) => {
    const formData = new FormData();
    formData.append("TypeId", typeId);

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    return axios
        .post(`${servicePath}/Glazing/GetAllGlazingPalette`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}

export const uniqueGlazingPalette = (id, accessToken) => {
    const formData = new FormData();
    formData.append("Id", id);

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    return axios
        .post(`${servicePath}/Glazing/GetGlazingPalette`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}

export const getGlazingCollectionPalette = (collectionId, typeId, accessToken) => {

    const formData = new FormData();
    formData.append("CollectionId", collectionId);
    formData.append("TypeId", typeId);

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    return axios
        .post(`${servicePath}/Glazing/GetAllGlazingCollectionPalette`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}

export const addGlazingCollectionPalette = (collectionId, paletteId, accessToken) => {

    const formData = new FormData();
    formData.append("Id", '');
    formData.append("GlazingCollectionId", collectionId);
    formData.append("GlazingPaletteId", paletteId);
    formData.append("SequenceNo", '');

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    return axios
        .post(`${servicePath}/Glazing/AddGlazingCollectionPalette`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}

export const deleteGlazingCollectionPalette = (id, accessToken) => {

    const formData = new FormData();
    formData.append("Id", id);

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    return axios
        .post(`${servicePath}/Glazing/DeleteGlazingCollectionPalette`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}

export const textureImageMaster = (accessToken) => {
    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }
    return axios
        .get(`${servicePath}/Master/GetGlazingTextureImageMaster`, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}

export const updateGlazingColourOrder = (accessToken, id, pageType, direction) => {

    var requestBody = {
        "id": id,
        "sequenceNo": 0,
        "typeText": pageType,
        "type": direction
    };

    const headers = {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
    }

    return axios
        .post(`${servicePath}/Glazing/UpdateGlazingColourOrder`, requestBody, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}

export const updatePaletteOrder = (accessToken, id, pageType, direction) => {

    var requestBody = {
        "id": id,
        "sequenceNo": 0,
        "typeText": pageType,
        "type": direction
    };

    const headers = {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
    }

    return axios
        .post(`${servicePath}/Glazing/UpdateGlazingPaletteOrder`, requestBody, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}

export const updateCollectionOrder = (accessToken, id, pageType, direction) => {

    var requestBody = {
        "id": id,
        "sequenceNo": 0,
        "typeText": pageType,
        "type": direction
    };

    const headers = {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
    }

    return axios
        .post(`${servicePath}/Glazing/UpdateGlazingCollectionOrder`, requestBody, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}

export const updateCollectionVisibleOrder = (accessToken, id, pageType, direction) => {

    var requestBody = {
        "id": id,
        "sequenceNo": 0,
        "typeText": pageType,
        "type": direction
    };

    const headers = {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
    }

    return axios
        .post(`${servicePath}/Glazing/UpdateGlazingCollectionVisibleOrder`, requestBody, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}

export const getGlazingStyleOptionsService = (accessToken) => {
    const headers = {
        Authorization: `Bearer ${accessToken}`,
    };
    return axios
        .get(`${servicePath}/Master/GetDesignStyleMaster`, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};

export const getGlazingDesignImageService = (id, accessToken) => {
    console.log("ID INSIDEEEEE,", id)
    const formData = new FormData();
    formData.append("StyleId", id);

    const headers = {
        Authorization: `Bearer ${accessToken}`,
    };

    return axios
        .post(`${servicePath}/Master/GetDesignImage`, formData, {
            headers,
        })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};


export const removeSpecificationInUsed = (accessToken, itemId) => {

    const formData = new FormData();

    formData.append("Id", itemId);

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    return axios
        .post(`${servicePath}/Master/RemoveSpecificationInUsed`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error
        });
}

export const getDefaultSpecificationsList = (typeId, accessToken) => {
    const formData = new FormData();
    typeId.forEach((id) => {
        formData.append("CollectionId", id);
    });

    const headers = {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data'
    }

    return axios
        .post(`${servicePath}/Master/GetDefaultSpecificationMaster`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}

export const getOverGlazingPriceSlabService = (accessToken, overSizeArea) => {
    const formData = new FormData();

    formData.append("OversizeValue", overSizeArea);

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    return axios
        .post(`${servicePath}/Glazing/GetGlazingOversizeSlabForPricing`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error
        });
}