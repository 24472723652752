import React, { useEffect, useState } from 'react'
import {
    Row,
    Col,
    Button,
    FormGroup,
    Label,
    Input,
    Form,
} from 'reactstrap';

import logo from "../../../../../../assets/img/logo/logo.png"
import { MdMailOutline, MdOutlinePinDrop, MdOutlinePhone, MdLanguage, MdOutlineFolder, MdClose, MdOutlineKeyboardArrowDown } from 'react-icons/md';
import { MenuItem, OutlinedInput, Select } from 'material-ui-core';
import { MenuProps } from '../../../../../../utility/data';
import { getPageSetupInfo, logoRemove, updatePageSetup } from '../../../../../../services/pageSetupService';
import { useNavigate } from 'react-router-dom';
import isURL from 'validator/lib/isURL';
import { getFontSizeMaster } from '../../../../../../services/commonServices';
import { Image } from 'react-bootstrap';

const PageSetupLeft = ({ accessToken, setMessage, setMessageType, setLoading }) => {

    const initialValues = {
        website: "",
    };

    const navigate = useNavigate()

    const [fontOptions, setFontOptions] = useState([])
    const [dynamicLogo, setDynamicLogo] = useState('')
    const [logoFile, setLogoFile] = useState('')
    const [address1, setAddress1] = useState('')
    const [address2, setAddress2] = useState('')
    const [phone, setPhone] = useState('')

    const [emailError, setEmailError] = useState(false)
    const [email, setEmail] = useState('')

    const [website, setWebsite] = useState('')
    const [validWebsiteURL, setValidWebsiteURL] = useState(false)

    const [vat, setVat] = useState('')
    const [fontValue, setFontValue] = useState("")
    const [logoType, setLogoType] = useState("")

    const [formErrors, setFormErrors] = useState({})
    const [formValues, setFormValues] = useState(initialValues)

    const [fontId, setFontId] = useState('');

    const validate = () => {
        let formErrors = {};
        let isValid = true;

        if (website && !isURL(website)) {
            formErrors.website = "Please enter valid Website URL";
            isValid = false
        }

        // if (phone && phone.length > 10) {
        //     formErrors.phone = "Max 10 digits are allowed";
        //     isValid = false
        // }

        // if (vat && vat.length > 20) {
        //     formErrors.vat = "Max 20 digits are allowed";
        //     isValid = false
        // }

        setFormErrors(formErrors)

        return isValid;
    }

    useEffect(() => {
        setLoading(true)

        getFontSizeOptions(1)
        getPageSetupData()
    }, [])

    useEffect(() => {
        if(fontOptions) {
            const fontDynamic = fontOptions?.find((p) => p.id == fontId)

            if(fontDynamic) {
                setFontValue(fontDynamic.value)
            } else {
                setFontValue("14")
            }
        }
    }, [fontOptions, fontId])


    // get font size dropdown from master
    const getFontSizeOptions = async (typeID) => {
        const res = await getFontSizeMaster(accessToken, typeID);

        if (res && res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                if (res?.data?.entity !== null) {
                    if (res?.data?.entity?.length > 0) {
                        setFontOptions(res?.data?.entity)
                    }
                }
            }
        }
    }

    const handleValidationChange = (e) => {

        const { name, value } = e.target

        setFormValues({ ...formValues, [name]: value })
    }

    const handleLogoChange = (e) => {

        const imageFile = e.target.files[0];

        setLogoFile(imageFile)

        console.log(e, imageFile, "imageFile")

        if (imageFile) {
            setDynamicLogo(URL.createObjectURL(imageFile))
        }
    }

    const handleEmailChange = (e) => {
        setEmail(e.target.value)

        handleValidationChange(e)
    }

    const handleEmail = (e) => {
        let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (e.target.value && regEmail.test(e.target.value)) {
            setEmailError(false)
        } else {
            setEmailError(true)
        }

        if (e.target.value === '') {
            setEmailError(false)
        }
    }

    const handleWebsiteChange = (e) => {
        setWebsite(e.target.value)

        if (isURL(e.target.value)) {
            setValidWebsiteURL(true)
        } else {
            setValidWebsiteURL(false)
        }

        handleValidationChange(e)
    }

    const handleChangePhone = (e) => {

        if (e.target.value?.length <= 10) {
            setPhone(e.target.value)
        }

        handleValidationChange(e)
    }

    const handleChangeVat = (e) => {

        if (e.target.value?.length <= 20) {
            setVat(e.target.value)
        }

        handleValidationChange(e)
    }

    const getPageSetupData = async () => {
        const res = await getPageSetupInfo(accessToken);

        if (res && res && res.status === 200) {
            console.log(res, "res12res")

            if (res?.data?.statusCode === 200) {

                if (res?.data?.entity !== null) {
                    setAddress1(res.data.entity.addressLine1 || '')
                    setAddress2(res.data.entity.addressLine2 || '')
                    setEmail(res.data.entity.email || '')
                    // setFontValue(res.data.entity.fontSize || fontOptions[2]?.value)
                    setFontId(res.data.entity.fontSize || fontOptions[2]?.id)
                    setPhone(res.data.entity.phone || "")
                    setVat(res.data.entity.vat || '')
                    setWebsite(res.data.entity.website || '')

                    setDynamicLogo(res.data.entity.logoImagePath || '')
                }
                

                setLoading(false)
            } else {
                setLoading(false)
                setMessage(res?.data.message ? res.data.message : 'Something went wrong');
                setMessageType('error')
            }
        } else {

            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')

            if (res?.message === "Network Error") {
                navigate('/error/network')
            }
        }
    }

    const handleFormSubmit = async () => {

        setLoading(true)

        var data = {
            'logo': logoFile,
            'address1': address1,
            'address2': address2,
            'phone': phone,
            'email': email,
            'website': website,
            'vat': vat,
            'fontValue': fontId,
            'logoType': logoType,
        }

        const res = await updatePageSetup(data, accessToken)

        console.log(res, "page update res")

        if (res && res && res.status === 200) {
            console.log(res, 'page setup info')
            if (res?.data?.statusCode === 200) {
                setLoading(false)
                setMessage(res?.message ? res.message : 'Page setup updated');
                setMessageType('success')

                getPageSetupData()

                setLoading(false)
            } else {
                setLoading(false)
                setMessage(res?.data.message ? res.data.message : 'Something went wrong');
                setMessageType('error')
            }
        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')

            if (res?.message === "Network Error") {
                navigate('/error/network')
            }
        }
    }

    const handleDeleteLogo = async () => {
        const res = await logoRemove(accessToken);

        console.log(res, "delete logo res")

        if (res && res && res.status === 200) {
            console.log(res, "resres")

            if (res?.data?.statusCode === 200) {

                getPageSetupData()

                setLogoFile('')
                setDynamicLogo('')

                document.getElementById("logoUpload").value = ''
                console.log(document.getElementById("logoUpload").value, "tt")

                setLoading(false)
            } else {
                setLoading(false)
                setMessage(res?.data.message ? res.data.message : 'Something went wrong');
                setMessageType('error')
            }
        } else {

            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')

            if (res?.message === "Network Error") {
                navigate('/error/network')
            }
        }
    }

    const handleFontOptions = (e) => {
        setFontId(e.target.value)
    };

    return (
        <div className='d-flex flex-column h-100'>

            <Form className='h-100 d-flex flex-column flex-grow-1 justify-content-between'>
                {/* has_tab has_btns has_head -> add respective class of included compoenent in div of class inside_scroll for proper scroll */}
                <div className='inside_scroll has_btns'>
                    <Row>
                        <Col xxl="12">

                            <Row className='mb-3'>
                                <Col lg="12" className="mb-3">
                                    <div className='border px-3 py-3 br-4 mb-1'>
                                        {/* <div className={`mt-0 page_setup_logo mb-3 ${logoType ? logoType : ''}`}>
                                            <Image src={dynamicLogo ? dynamicLogo : logo} alt="logo" />
                                        </div> */}

                                        {dynamicLogo && (
                                            <div className="mt-0 page_setup_logo mb-3">
                                                <Image src={dynamicLogo} alt="logo" />
                                            </div>
                                        )}

                                        <div className='item_list'>
                                            {address1 && (
                                                <span className='d-flex mb-2 head_item wrap_address' style={{ fontSize: `${fontValue ? fontValue : '14'}px` }}>
                                                    <span>
                                                        <MdOutlinePinDrop size={`${fontValue ? (parseInt(fontValue) + 4) : '18'}`} className='me-2' />
                                                    </span>
                                                    <span>
                                                        <span className='d-block lh-sm text-break'>{address1}</span>
                                                        <span className='d-block lh-sm text-break'>{address2}</span>
                                                    </span>
                                                </span>
                                            )}

                                            {phone && (
                                                <span className='d-flex align-items-center mb-2 head_item' style={{ fontSize: `${fontValue ? fontValue : '14'}px` }}>
                                                    <span>
                                                        <MdOutlinePhone size={`${fontValue ? (parseInt(fontValue) + 4) : '18'}`} className='me-2' />
                                                    </span>
                                                    <span className='lh-sm'>{phone}</span>
                                                </span>
                                            )}

                                            {email && (
                                                <span className='d-flex align-items-center mb-2 head_item' style={{ fontSize: `${fontValue ? fontValue : '14'}px` }}>
                                                    <span>
                                                        <MdMailOutline size={`${fontValue ? (parseInt(fontValue) + 4) : '18'}`} className='me-2' />
                                                    </span>
                                                    <span className='lh-sm'>{email}</span>
                                                </span>
                                            )}

                                            {website && (
                                                <span className='d-flex align-items-center mb-2 head_item' style={{ fontSize: `${fontValue ? fontValue : '14'}px` }}>
                                                    <span>
                                                        <MdLanguage size={`${fontValue ? (parseInt(fontValue) + 4) : '18'}`} className='me-2' />
                                                    </span>
                                                    <span className='lh-sm'>{website}</span>
                                                </span>
                                            )}

                                            {vat && (
                                                <span className='d-flex align-items-center mb-2 head_item' style={{ fontSize: `${fontValue ? fontValue : '14'}px` }}>
                                                    <span className='me-1'>VAT NO:</span>
                                                    <span className='lh-sm'>{vat}</span>
                                                </span>
                                            )}
                                        </div>
                                    </div>

                                    <span className='text-muted text-small'>Previewing your page setup</span>
                                </Col>

                                {fontOptions && fontOptions.length > 0 && (
                                    <Col md="6" lg="6" xl="4">
                                        <FormGroup className="form-group">
                                            <Label for="fontsize">
                                                Font size
                                            </Label>

                                            <Select
                                                labelId="fontsize"
                                                id="fontsize"
                                                value={fontId}
                                                label="fontsize"
                                                onChange={(e) => {
                                                    handleFontOptions(e)
                                                 }}
                                                input={<OutlinedInput className='w-100' name='fontsize' />}

                                                MenuProps={MenuProps}

                                                IconComponent={() => (
                                                    <MdOutlineKeyboardArrowDown size={20} className='me-1 MuiSvgIcon-root MuiSelect-icon' />
                                                )}
                                            >
                                                {fontOptions && fontOptions.map((o, index) => (
                                                    <MenuItem key={index} value={o?.id} style={{ fontSize: `${o.value}px` }}>
                                                        {o.title}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormGroup>
                                    </Col>
                                )}

                                {/* <Col sm="6" md="4" lg="3">
                                    <FormGroup className="form-group">
                                        <Label for="fontsize">
                                            Logo type
                                        </Label>

                                        <Select
                                            labelId="logoType"
                                            id="logoType"
                                            value={logoType}
                                            label="logoType"
                                            onChange={(e) => { setLogoType(e.target.value) }}
                                            input={<OutlinedInput className='w-100' name='logoType' />}

                                            MenuProps={MenuProps}

                                            IconComponent={() => (
                                                <MdOutlineKeyboardArrowDown size={20} className='me-1 MuiSvgIcon-root MuiSelect-icon' />
                                            )}
                                        >
                                            <MenuItem value="rect">
                                                Rectangular
                                            </MenuItem>
                                            <MenuItem value="square">
                                                Square/Circular
                                            </MenuItem>
                                        </Select>
                                    </FormGroup>
                                </Col> */}
                            </Row>

                            <Row >
                                <Col lg="12">
                                    <h4 className='main_title sub_title'>Header Information</h4>

                                    <FormGroup className="form-group">
                                        <Label>
                                            Logo
                                        </Label>

                                        <div className='d-flex gap-3 file_choose_group mb-1'>
                                            <div className='d-flex max-w-200'>
                                                <Label for="logoUpload" className='file_choose btn btn-primary text-white d-flex justify-content-center align-items-center w-100 mb-0'>
                                                    <Input id='logoUpload' className='mb-1' name="logoUpload" type="file" onChange={(e) => { handleLogoChange(e) }} />
                                                    <MdOutlineFolder size={20} className='me-2 lh-1' />
                                                    <span className='lh-sm'>Choose file</span>
                                                </Label>
                                            </div>

                                            <Button
                                                color="primary"
                                                size="md"
                                                outline
                                                className='px-2 px-sm-4 max-w-180'
                                                // disabled={!dynamicLogo}
                                                onClick={(e) => handleDeleteLogo(e)}
                                            >
                                                <MdClose size={20} className='me-1 lh-1' />
                                                <span className='lh-sm'>Remove</span>
                                            </Button>
                                        </div>

                                        <span className='text-muted text-small'>Your logo will be scaled by 50% to look better on mobile devices.</span>
                                    </FormGroup>
                                </Col>

                                <Col md="6" lg="6" xl="4">
                                    <FormGroup className="form-group">
                                        <Label for="address1">
                                            Address Line 1
                                        </Label>
                                        <Input className='mb-1' name="address1"
                                            value={address1}
                                            onChange={(e) => { { e.target.value?.length < 101 && setAddress1(e.target.value) } }}
                                        />
                                        {(address1 && address1?.length === 100) && (
                                            <div className='mt-1 text-muted text-small'>Max 100 characters are allowed</div>
                                        )}
                                    </FormGroup>
                                </Col>

                                <Col md="6" lg="6" xl="4">
                                    <FormGroup className="form-group">
                                        <Label for="address2">
                                            Address Line 2
                                        </Label>
                                        <Input className='mb-1' name="address2" value={address2} onChange={(e) => { { e.target.value?.length < 101 && setAddress2(e.target.value) } }} />

                                        {(address2 && address2?.length === 100) && (
                                            <div className='mt-1 text-muted text-small'>Max 100 characters are allowed</div>
                                        )}
                                    </FormGroup>
                                </Col>

                                <Col md="6" lg="6" xl="4">
                                    <FormGroup className="form-group">
                                        <Label for="email">
                                            Email
                                        </Label>
                                        <Input className='mb-1' name="email" type="email" value={email} onChange={(e) => { handleEmailChange(e) }} onBlur={handleEmail} />

                                        {emailError && (
                                            <div className='mt-1 text-danger'>Please enter valid email</div>
                                        )}
                                    </FormGroup>
                                </Col>

                                <Col md="6" lg="6" xl="4">
                                    <FormGroup className="form-group">
                                        <Label for="website">
                                            Website
                                        </Label>
                                        <Input className='mb-1' name="website" value={website} onChange={(e) => handleWebsiteChange(e)} />

                                        {(website && !validWebsiteURL) && (
                                            <div className='mt-1 text-danger'>{formErrors.website}</div>
                                        )}
                                    </FormGroup>
                                </Col>

                                <Col sm="6" lg="6" xl="4">
                                    <FormGroup className="form-group">
                                        <Label for="phone">
                                            Phone
                                        </Label>

                                        <Input className='mb-1' name="phone" value={phone} type='number'
                                            onChange={(e) => { handleChangePhone(e) }}
                                        />

                                        {/* {(phone && phone.length === 10) && (
                                            <div className='mt-1 text-muted text-small'>Max 10 digits are allowed</div>
                                        )} */}
                                    </FormGroup>
                                </Col>

                                <Col sm="6" lg="6" xl="4">
                                    <FormGroup className="form-group">
                                        <Label for="vat">
                                            VAT number
                                        </Label>
                                        <Input className='mb-1' name="vat" value={vat} type='number'
                                            onChange={(e) => { handleChangeVat(e) }}
                                        />
                                        {(vat && vat.length === 20) && (
                                            <div className='mt-1 text-muted text-small'>Max 20 digits are allowed</div>
                                        )}
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>

                {/* Start: space for buttons at the bottom */}
                <div className='d-flex gap-2 gap-xl-3 main_buttons'>
                    <Button
                        color="primary"
                        size="md"
                        className='px-4'
                        disabled={emailError}
                        onClick={() => validate() ? handleFormSubmit() : <></>}
                    >
                        Save Changes
                    </Button>

                    <Button
                        color="primary"
                        size="md"
                        outline
                        className='px-4'
                        onClick={() => getPageSetupData()}
                    >
                        Reset
                    </Button>
                </div>
                {/* End: space for buttons at the bottom */}
            </Form>
        </div>
    )
}

export default PageSetupLeft