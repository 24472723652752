import axios from "axios";
import { servicePath } from "../utility/data";

var version = 1;

// get all right menu item list based on page/type
export const getAllAdditionalArticlesService = (accessToken, typeId) => {
    const headers = {
        accept: "text/plain",
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "multipart/form-data",
    };

    const formData = new FormData();

    formData.append("TypeId", typeId);

    return axios
        .post(
            `${servicePath}/v${version}/ProfileV/GetAllAdditionalArticles`,
            formData,
            { headers }
        )
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};

export const addUpdateAdditionalArticle = (accessToken, data) => {
    const headers = {
        accept: "text/plain",
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "multipart/form-data",
    };

    const formData = new FormData();

    formData.append("Id", data?.id);
    formData.append("Name", data?.name);
    formData.append("Description", data?.description);
    formData.append("ProductCode", data?.productCode);
    formData.append("TypeId", data?.typeId)
    formData.append("PriceType", data?.priceType)
    formData.append("ProfileType", data?.profileType)
    formData.append("ColourCollection", data?.colorCollection)
    formData.append("ExternalPaintSurfaceArea", data?.externalPaintSurfaceArea)
    formData.append("InternalPaintSurfaceArea",data?.internalPaintSurfaceArea)
    formData.append("Weight",data?.weight)
    formData.append("Position",data?.position)
    formData.append("DefaultSide",data?.defaultSide)
    formData.append("FilePath",data?.filePath)
    


    return axios
        .post(
            `${servicePath}/v${version}/ProfileV/AddUpdateAdditionalArticles`,
            formData,
            { headers }
        )
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};

export const getAdditionalArticle = (accessToken, id) => {
    const formData = new FormData();
    formData.append("Id", id);

    let config = {
        method: "post",
        maxBodyLength: Infinity,
        headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "multipart/form-data",
        },
    };

    return axios
        .post(
            `${servicePath}/v${version}/ProfileV/GetAdditionalArticles`,
            formData,
            config
        )
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};


// delete selected item
export const deleteAdditionalArticle = (accessToken, id) => {
    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    const formData = new FormData()
    formData.append("Id", id)
    return axios
        .post(`${servicePath}/v${version}/ProfileV/DeleteAdditionalArticles`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}

// update order of selected item
export const updateAdditionalArticleOrderService = (accessToken, id, direction, typeId) => {

    var requestBody = {
        "id": id,
        "sequenceNo": 0,
        "type": direction,
        "typeId": typeId
    };

    const headers = {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
    }

    return axios
        .post(`${servicePath}/v${version}/ProfileV/UpdateAdditionalArticlesOrder`, requestBody, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}