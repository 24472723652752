import React, { useEffect, useState } from 'react'
import InnerLayout from "../../../../../../layouts/internalLayout"
import FrameProductsLeft from './left'
import FrameProductsRight from './right'
import { Oval } from 'react-loader-spinner'
import { useMessage } from '../../../../../../context/messageContext'
import { addNewRightItem, getAllFrameAccessories, getAllSysFrameProductsList, getItemDetails, updateItemDetails } from '../../../../../../services/sysFrameProductsService'
import { useRightContext } from '../../../../../../context/rightMenuContext'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { getFrameProductsCollection } from '../../../../../../services/sysFrameCollectionService'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'
import { MdKeyboardArrowDown } from 'react-icons/md'
import NoDataCard from '../../../../../../components/NoDataCard'

const FrameProducts = () => {

    // let { collectionId } = useParams();

    const location = useLocation();
    const collectionId = location.state ? location.state.data : null

    const accessToken = localStorage.getItem('access_token')

    const [pageName, setPageName] = useState("Frame Products")
    const [activeTab, setActiveTab] = useState("General")
    const [loading, setLoading] = useState(true)

    const { setMessage, setMessageType } = useMessage()
    const { timestamp, setSelectedRightItem, fetchSelected, setTimestamp, activeIndex, setActiveIndex, setMainMenuActive, setSubMenuActive, setSubMenu2Active } = useRightContext()

    const [selectItemDetails, setSelectItemDetails] = useState([])
    const [allRightListItems, setAllRightListItems] = useState([])
    const [allFrameAccessories, SetAllFrameAccessories] = useState([])

    const [headerDropdown, setHeaderDropdown] = useState(false);
    const [mainDropdownList, setMainDropdownList] = useState([])
    const [frameCollection, setFrameCollection] = useState('')

    const navigate = useNavigate();

    var getFirstElId = allRightListItems[0]?.id || ''
    var itemAddedTrue = ''

    useEffect(() => {
        getFrameProductsCollectionService()
        setMainMenuActive("Products")
        setSubMenuActive("Systems")
        setSubMenu2Active("Frame Products")
    }, [])

    useEffect(() => {
        if (mainDropdownList && mainDropdownList.length > 0) {
            console.log(location, "collectionId")

            if (collectionId) {
                const collectionItem = mainDropdownList?.find((p) => p.id == collectionId)

                if (collectionItem && collectionItem.id) {
                    setFrameCollection(collectionItem)
                }
            } else {
                setFrameCollection(mainDropdownList[0])
            }
        }
    }, [mainDropdownList]);

    useEffect(() => {
        setLoading(true)
        setTimestamp('')
        setActiveIndex(0)

        setTimeout(() => {
            if (frameCollection?.id) {
                getAllSysFrameProductsData(frameCollection?.id)
            }
        }, 400);
    }, [frameCollection?.id])

    const getFrameProductsCollectionService = async () => {

        const res = await getFrameProductsCollection(accessToken);

        if (res && res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                if (res?.data?.entity !== null) {
                    setMainDropdownList(res?.data?.entity)
                } else {
                    setLoading(false)
                    setMainDropdownList([])
                }
            }
        }
    }

    useEffect(() => {
        if (allRightListItems?.length > 0 && timestamp) {
            getRightItemDetails(timestamp)
            getAllFrameAccessoriesServices(timestamp)
        }
    }, [timestamp])

    useEffect(() => {
        if (getFirstElId && allRightListItems?.length > 0 && activeIndex !== -1) {
            if (allRightListItems?.length > 0 && activeIndex !== 0 && timestamp) {
                getRightItemDetails(timestamp)
            } else {
                getRightItemDetails(getFirstElId)
            }
        } else {
            setSelectItemDetails([])
        }
    }, [allRightListItems])

    useEffect(() => {
        if (timestamp) {
            getRightItemDetails(timestamp)
        }
    }, [activeTab])

    const toggleTabDropdown = () => setHeaderDropdown((prevState) => !prevState);

    // get collections list
    const getAllSysFrameProductsData = async (fetchId) => {

        const res = await getAllSysFrameProductsList(accessToken, fetchId);

        if (res && res && res.status === 200) {

            if (res?.data?.statusCode === 200) {
                if (res?.data?.entity !== null) {

                    if (res?.data?.entity?.length > 0) {
                        setAllRightListItems(res?.data?.entity)

                        if (itemAddedTrue == 'added') {
                            const addedItemIndex = res.data.entity.length - 1

                            if (addedItemIndex) {
                                fetchSelected(addedItemIndex, res.data.entity[addedItemIndex]?.id)
                                itemAddedTrue = ''
                            }
                        }

                    } else {
                        setAllRightListItems([])
                        setTimestamp('')

                        fetchSelected(0, '');
                    }
                }

                setTimeout(() => {
                    setLoading(false)
                }, 1200);
            } else {
                setLoading(false)
                setMessage(res?.data.message ? res.data.message : 'Something went wrong');
                setMessageType('error')
            }

        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')

            if (res?.message === "Network Error") {
                navigate('/error/network')
            }
        }
    }

    // get details of selected item in right menu by passing item id
    const getRightItemDetails = async (id) => {
        setLoading(true);

        const res = await getItemDetails(accessToken, id);

        if (res && res && res.status === 200) {

            if (res?.data?.statusCode === 200) {

                if (res?.data?.entity !== null) {

                    const selectedRight = allRightListItems?.find((p) => p.id == id)
                    setSelectedRightItem(selectedRight)

                    // set state to get details of current selected item which will display data in left side component
                    setSelectItemDetails(res.data.entity)

                    // set selected item id which will update context
                    setTimestamp(id)
                } else {
                    setSelectItemDetails([])
                }

                setTimeout(() => {
                    setLoading(false)
                }, 1200);
            } else {
                setLoading(false)
                setMessage(res?.data.message ? res.data.message : 'Something went wrong');
                setMessageType('error')
            }

        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')

            if (res?.message === "Network Error") {
                navigate('/error/network')
            }
        }
    }

    // api call to update all selected item details
    const updateItemDetailsServices = async (data, tabType) => {

        setLoading(true)

        const res = await updateItemDetails(accessToken, timestamp ? timestamp : getFirstElId, data, tabType)

        if (res && res && res.status === 200) {

            if (res?.data?.statusCode === 200) {
                setMessage(res?.data?.message);
                setMessageType('success')
                if (tabType == "Accessories") {
                    getAllFrameAccessoriesServices(timestamp)
                }

                getRightItemDetails(timestamp)

                setTimeout(() => {
                    setLoading(false)
                }, 1200);
            } else {
                setLoading(false)
                setMessage(res?.data?.message ? res.data.message : 'Something went wrong');
                setMessageType('error')
            }
        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')

            if (res?.message === "Network Error") {
                navigate('/error/network')
            }
        }
    }

    // api call to added new right item
    const addNewRightItemServices = async (title) => {
        setLoading(true)
        itemAddedTrue = "added"

        setActiveTab("General")

        const res = await addNewRightItem(accessToken, title, frameCollection?.id)

        if (res && res && res.status === 200) {

            if (res?.data?.statusCode === 200) {
                setMessage(res?.data?.message);
                setMessageType('success')

                getAllSysFrameProductsData(frameCollection?.id)
            } else {
                setLoading(false)
                setMessage(res?.data?.message ? res.data.message : 'Something went wrong');
                setMessageType('error')
            }
        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')

            if (res?.message === "Network Error") {
                navigate('/error/network')
            }
        }
    }

    const handleFormReset = () => {
        if (timestamp) {

            if (activeTab === "General") {
                getAllSysFrameProductsData(frameCollection?.id)
            }

            getRightItemDetails(timestamp)
        }
    }

    const getAllFrameAccessoriesServices = async () => {
        const res = await getAllFrameAccessories(accessToken, timestamp)

        if (res && res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                if (res?.data?.entity !== null) {
                    if (res?.data?.entity) {
                        SetAllFrameAccessories(res?.data?.entity)
                    } else {
                        SetAllFrameAccessories('')
                    }
                }
            }
        }
    }

    const handleItemSelect = (value) => {
        setFrameCollection(value)
    }

    return (
        <>
            {loading && (
                <div className='loader_main full_loader left_loader'>
                    <Oval
                        height={50}
                        width={50}
                        color="#44C8F5"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel='oval-loading'
                        secondaryColor="#44C8F5"
                        strokeWidth={2}
                        strokeWidthSecondary={2}
                    />
                </div>
            )}

            {mainDropdownList && mainDropdownList.length > 0 ? (
                <InnerLayout pageTitle='' layoutType=""
                    leftContent={<FrameProductsLeft allFrameAccessories={allFrameAccessories}
                        collectionId={frameCollection?.id} getAllFrameAccessoriesServices={getAllFrameAccessoriesServices} navigate={navigate} handleFormReset={handleFormReset} accessToken={accessToken} activeTab={activeTab} setActiveTab={setActiveTab} updateItemDetailsServices={updateItemDetailsServices} loading={loading} selectItemDetails={selectItemDetails} setMessage={setMessage} setMessageType={setMessageType} setLoading={setLoading} />}
                    rightContent={<FrameProductsRight collectionId={frameCollection?.id} accessToken={accessToken} selectItemDetails={selectItemDetails} allRightListItems={allRightListItems} setLoading={setLoading} getAllSysFrameProductsData={getAllSysFrameProductsData} addNewRightItemServices={addNewRightItemServices} />}
                    filterButton={<Dropdown
                        isOpen={headerDropdown}
                        toggle={toggleTabDropdown}
                    >
                        <DropdownToggle color="none" className="border-0 p-0">
                            <div className="d-flex align-items-center">
                                <span className="fw-semibold fs-5">
                                    {frameCollection && frameCollection?.name}
                                    <MdKeyboardArrowDown className="ms-1" size={20} />
                                </span>
                            </div>
                        </DropdownToggle>

                        <DropdownMenu className='full_height'>
                            {mainDropdownList && mainDropdownList?.map((item, index) => {
                                return (
                                    <DropdownItem
                                        onClick={() => handleItemSelect(item)}
                                        key={index}
                                        disabled={item?.id == frameCollection?.id}
                                        className={`${item?.id == frameCollection?.id ? 'text-primary fw-medium' : ''}`}
                                    >
                                        {item?.name}
                                    </DropdownItem>
                                );
                            })}
                        </DropdownMenu>
                    </Dropdown>}
                />
            ) : (
                <NoDataCard text="frame products" loading={loading} />
            )}

        </>
    )
}

export default FrameProducts