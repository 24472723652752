import React, { useState, useEffect } from "react";
import InternalLayout from "../../../../../../layouts/internalLayout";
import Left from "./left";
import { useMessage } from "../../../../../../context/messageContext";
import { Oval } from "react-loader-spinner";
import { useParams } from "react-router-dom";
import { getPanelProductCollection } from "../../../../../../services/panelCollectionsService";
import CustomizedButton from "../../../../../../components/CustomizedButton";
import { useRightContext } from "../../../../../../context/rightMenuContext";
import { getPanelProductMaster } from "../../../../../../services/panelProductsService";

const PricingPanelProduct = () => {
  const accessToken = localStorage.getItem('access_token')

  const [loading, setLoading] = useState(false);
  const [pageName, setPageName] = useState("Panel Styles")
  const { setMainMenuActive, setSubMenuActive, setSubMenu2Active } = useRightContext()

  const { setMessage, setMessageType } = useMessage();

  const { collectionId } = useParams();

  useEffect(() => {
    setMainMenuActive("Pricing")
    setSubMenuActive("Panels")
  }, []);

  useEffect(() => {
    if (collectionId) {
      getPanelCollectionListService(collectionId)
    }
  }, [collectionId]);

  const getPanelCollectionListService = async (fetchId) => {
    const res = await getPanelProductCollection(accessToken, fetchId);
    if (res && res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        setPageName(res?.data?.entity?.name)

        if(res?.data?.entity?.panelProductMasterId) {
          getPanelMasterDetails(res?.data?.entity?.panelProductMasterId)
        }
      }
    }
  }

  const getPanelMasterDetails = async (fetchId) => {
    const res = await getPanelProductMaster(accessToken, fetchId);
    if (res && res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        setSubMenu2Active(res?.data?.entity?.name)
      }
    }
  }

  const data1 = { url: `/product/panels/panel-products/39/collection/${collectionId}` }

  return (
    <>
      {loading && (
        <div className="loader_main full_loader">
          <Oval
            height={50}
            width={50}
            color="#44C8F5"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#44C8F5"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      )}
      <InternalLayout
        pageTitle=''
        layoutType="full"
        leftContent={
          <Left
            token={accessToken}
            setMessage={setMessage}
            setMessageType={setMessageType}
            loading={loading}
            setLoading={setLoading}
            collectionId={collectionId}
          />
        }
        rightContent={""}
        filterButton={
          <>
            <h5 className="mb-0 text-wrap text-break me-4 main_title">
              {pageName}
            </h5>
            <CustomizedButton data={data1} hasState={true} />
          </>
        }
      />
    </>
  );
};

export default PricingPanelProduct;
