import * as THREE from 'three';
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';
import { setInitialColors } from '../helper';
import { Button } from 'reactstrap';

// export const addLayoutLines = (bottomRef, rightRef, uiLinesRef, sceneRef, themeColor, camera) => {

//     console.log(bottomRef, rightRef, "bottomRef")

//     uiLinesRef.current = []

//     removeLayoutLines(sceneRef);

//     // Add the line to the scene
//     setTimeout(() => {
//         const boundingBoxBottom = new THREE.Box3().setFromObject(bottomRef);
//         const boundingBoxLeft = new THREE.Box3().setFromObject(rightRef);

//         // Arrowhead parameters
//         const arrowWidth = 0.02;
//         const arrowHeight = 0.05;

//         // Create arrowheads
//         const arrowLength = 0.03;
//         const arrowRadius = 0.01;
//         const arrowSegments = 5;

//         // Define two endpoints for the horizontal line
//         const startPoint1 = new THREE.Vector3(boundingBoxBottom?.min.x + arrowLength / 2, bottomRef?.position.y - 0.2, bottomRef?.position.z);
//         const endPoint1 = new THREE.Vector3(boundingBoxBottom?.max.x - arrowLength / 2, bottomRef?.position.y - 0.2, bottomRef?.position.z);


//         // Define two endpoints for the horizontal line's perpendicular sub lines
//         const startPoint1P1 = new THREE.Vector3(boundingBoxBottom?.min.x, bottomRef?.position.y - 0.2, bottomRef?.position.z);
//         const endPoint1P1 = new THREE.Vector3(boundingBoxBottom?.min.x, bottomRef?.position.y - .01, bottomRef?.position.z);

//         const centerPoint1P1 = new THREE.Vector3(
//             (startPoint1.x + endPoint1.x) / 2,
//             (startPoint1.y + endPoint1.y) / 2,
//             (startPoint1.z + endPoint1.z) / 2
//         );

//         // center point of created ui lines
//         const centerGroup = new THREE.Group();
//         centerGroup.position.copy(centerPoint1P1)

//         // Define two endpoints for the vertical line
//         const startPoint2 = new THREE.Vector3(rightRef?.position.x + 0.2, boundingBoxLeft?.min.y + arrowLength / 2, rightRef?.position.z);
//         const endPoint2 = new THREE.Vector3(rightRef?.position.x + 0.2, boundingBoxLeft?.max.y - arrowLength / 2, rightRef?.position.z);

//         // Define two endpoints for the vertical line's perpendicular sub lines
//         const startPoint2P2 = new THREE.Vector3(rightRef?.position.x, boundingBoxLeft?.min.y, rightRef?.position.z);
//         const endPoint2P2 = new THREE.Vector3(rightRef?.position.x + 0.2, boundingBoxLeft?.min.y, rightRef?.position.z);

//         // Create a line geometry
//         const geometry1 = new THREE.BufferGeometry().setFromPoints([startPoint1, endPoint1]);
//         const geometry2 = new THREE.BufferGeometry().setFromPoints([startPoint2, endPoint2]);

//         // geometry for perpendicular sub lines
//         const geometry3 = new THREE.BufferGeometry().setFromPoints([startPoint1P1, endPoint1P1]);
//         const geometry4 = new THREE.BufferGeometry().setFromPoints([startPoint2P2, endPoint2P2]);

//         // Create a line material
//         const material = new THREE.LineBasicMaterial({ color: themeColor });

//         // Create line meshes
//         const line1 = new THREE.Line(geometry1, material);
//         const line2 = new THREE.Line(geometry2, material);

//         const line1P1 = new THREE.Line(geometry3, material);
//         const line1P2 = line1P1.clone()
//         line1P2.position.x = (boundingBoxBottom?.max.x - boundingBoxBottom?.min.x)

//         const line2P1 = new THREE.Line(geometry4, material);
//         const line2P2 = line2P1.clone()
//         line2P2.position.y = (boundingBoxLeft?.max.y - boundingBoxLeft?.min.y)

//         // Create arrowhead geometries
//         const arrowGeometry = new THREE.ConeGeometry(arrowRadius, arrowLength, arrowSegments);

//         // Function to create an arrowhead mesh
//         const createArrowHead = (position, rotation) => {
//             const arrowMaterial = new THREE.MeshBasicMaterial({ color: themeColor, side: THREE.DoubleSide });
//             const arrowHead = new THREE.Mesh(arrowGeometry, arrowMaterial);
//             arrowHead.position.copy(position);
//             arrowHead.rotation.z = rotation; // Set rotation directly
//             return arrowHead;
//         };

//         const arrow1Start = createArrowHead(startPoint1, Math.PI / 2); // Arrow facing left
//         const arrow1End = createArrowHead(endPoint1, -Math.PI / 2); // Arrow facing right

//         const arrow2Start = createArrowHead(startPoint2, Math.PI); // Arrow facing down
//         const arrow2End = createArrowHead(endPoint2, 0); // Arrow facing up

//         // Create groups for lines and their arrowheads
//         const group1 = new THREE.Group();
//         group1.add(line1);
//         group1.add(line1P1);
//         group1.add(line1P2);
//         group1.add(arrow1Start);
//         group1.add(arrow1End);

//         const group2 = new THREE.Group();
//         group2.add(line2);
//         group2.add(line2P1);
//         group2.add(line2P2);
//         group2.add(arrow2Start);
//         group2.add(arrow2End);

//         // Name the groups
//         group1.name = "uiLines uiLines_h";
//         group2.name = "uiLines uiLines_v";

//         sceneRef?.current?.add(group1);
//         sceneRef?.current?.add(group2);

//         setTimeout(() => {
//             uiLinesRef.current.push(centerGroup)
//         }, 100);
//     }, 200);
// };

export const addUiLinesFrameH = (itemRef, uiLinesRef, sceneRef, themeColor) => {

    uiLinesRef.current = []

    removeFrameLines(sceneRef);

    setTimeout(() => {
        if (itemRef && itemRef.length > 0) {
            itemRef.forEach((item) => {
                if (item.name.includes('FrameTop')) {

                    const itemBoundingBox = new THREE.Box3().setFromObject(item);
                    const lineDistance = 0.16;

                    // Define two endpoints for the horizontal line
                    const startPoint1 = new THREE.Vector3(itemBoundingBox?.min.x, item?.position.y + lineDistance, item?.position.z);
                    const endPoint1 = new THREE.Vector3(itemBoundingBox?.max.x, item?.position.y + lineDistance, item?.position.z);

                    // Center point of created UI lines
                    const centerPoint1P1 = new THREE.Vector3(
                        (startPoint1.x + endPoint1.x) / 2,
                        ((startPoint1.y + endPoint1.y) / 2) - 0.05,
                        (startPoint1.z + endPoint1.z) / 2
                    );

                    // Clone the item and adjust its position
                    const centerGroup = item.clone();
                    centerGroup.position.copy(centerPoint1P1);

                    // Define two endpoints for the horizontal line's perpendicular sub-lines
                    const startPoint1P1 = new THREE.Vector3(itemBoundingBox?.min.x, item?.position.y + lineDistance, item?.position.z);
                    const endPoint1P1 = new THREE.Vector3(itemBoundingBox?.min.x, item?.position.y + (lineDistance / 1.8), item?.position.z);

                    // Create a line geometry
                    const geometry1 = new THREE.BufferGeometry().setFromPoints([startPoint1, endPoint1]);

                    // Geometry for perpendicular sub-lines
                    const geometry3 = new THREE.BufferGeometry().setFromPoints([startPoint1P1, endPoint1P1]);

                    // Create a line material
                    const material = new THREE.LineBasicMaterial({ color: themeColor });

                    // Create line meshes
                    const line1 = new THREE.Line(geometry1, material);
                    const line1P1 = new THREE.Line(geometry3, material);

                    // Clone and position the second perpendicular line
                    const line1P2 = line1P1.clone();
                    line1P2.position.x = (itemBoundingBox?.max.x - itemBoundingBox?.min.x);

                    // Circle parameters
                    const circleRadius = 15 / 1000; // 4px radius (assuming 1 unit = 1 meter, adjust if necessary)
                    const circleGeometry = new THREE.CircleGeometry(circleRadius, 32);

                    // Function to create a circle mesh
                    const createCircle = (position) => {
                        const circleMaterial = new THREE.MeshBasicMaterial({ color: themeColor, side: THREE.DoubleSide });
                        const circle = new THREE.Mesh(circleGeometry, circleMaterial);
                        circle.position.copy(position);
                        return circle;
                    };

                    // Create circles for perpendicular sub-lines
                    const circle1P1 = createCircle(endPoint1P1); // Circle at the end of line1P1
                    const circle1P2 = createCircle(new THREE.Vector3(itemBoundingBox?.max.x, item?.position.y + (lineDistance / 1.8), item?.position.z)); // Circle at the end of line1P2

                    // Create groups for lines and their circles
                    const group1 = new THREE.Group();
                    group1.add(line1);
                    group1.add(line1P1);
                    group1.add(line1P2);
                    group1.add(circle1P1);
                    group1.add(circle1P2);

                    // Name the group
                    group1.name = "fullLines";

                    // Add the group to the scene
                    sceneRef?.current?.add(group1);

                    // Store the center group for further manipulation if needed
                    setTimeout(() => {
                        uiLinesRef.current.push(centerGroup);
                    }, 100);
                }
            })

            // const elements = document.getElementById('elements')
            // elements.style.display = 'block'
        }
    }, 200);
};

export const addUiLinesFrameV = (itemRef, uiLinesRef, sceneRef, themeColor) => {

    uiLinesRef.current = []

    removeFrameLines(sceneRef);

    setTimeout(() => {
        if (itemRef && itemRef.length > 0) {
            itemRef.forEach((item) => {
                if (item.name.includes('FrameRight')) {

                    const itemBoundingBox = new THREE.Box3().setFromObject(item);
                    const lineDistance = 0.16;

                    // Define two endpoints for the verticle line
                    const startPoint1 = new THREE.Vector3(item?.position.x - lineDistance, itemBoundingBox?.min.y, item?.position.z);
                    const endPoint1 = new THREE.Vector3(item?.position.x - lineDistance, itemBoundingBox?.max.y, item?.position.z);

                    // Center point of created UI lines
                    const centerPoint1P1 = new THREE.Vector3(
                        (startPoint1.x + endPoint1.x) / 2,
                        ((startPoint1.y + endPoint1.y) / 2) - 0.05,
                        (startPoint1.z + endPoint1.z) / 2
                    );

                    // Clone the item and adjust its position
                    const centerGroup = item.clone();
                    centerGroup.position.copy(centerPoint1P1);

                    // Define two endpoints for the horizontal line's perpendicular sub-lines
                    const startPoint1P1 = new THREE.Vector3(item?.position.x - (lineDistance / 1.8), itemBoundingBox?.min.y, item?.position.z);
                    const endPoint1P1 = new THREE.Vector3(item?.position.x - lineDistance, itemBoundingBox?.min.y, item?.position.z);

                    // Create a line geometry
                    const geometry1 = new THREE.BufferGeometry().setFromPoints([startPoint1, endPoint1]);

                    // Geometry for perpendicular sub-lines
                    const geometry3 = new THREE.BufferGeometry().setFromPoints([startPoint1P1, endPoint1P1]);

                    // Create a line material
                    const material = new THREE.LineBasicMaterial({ color: themeColor });

                    // Create line meshes
                    const line1 = new THREE.Line(geometry1, material);
                    const line1P1 = new THREE.Line(geometry3, material);

                    // Clone and position the second perpendicular line
                    const line1P2 = line1P1.clone();
                    line1P2.position.y = (itemBoundingBox?.max.y - itemBoundingBox?.min.y);

                    // Circle parameters
                    const circleRadius = 15 / 1000; // 4px radius (assuming 1 unit = 1 meter, adjust if necessary)
                    const circleGeometry = new THREE.CircleGeometry(circleRadius, 32);

                    // Function to create a circle mesh
                    const createCircle = (position) => {
                        const circleMaterial = new THREE.MeshBasicMaterial({ color: themeColor, side: THREE.DoubleSide });
                        const circle = new THREE.Mesh(circleGeometry, circleMaterial);
                        circle.position.copy(position);
                        return circle;
                    };

                    // Create circles for perpendicular sub-lines
                    const circle1P1 = createCircle(startPoint1P1); // Circle at the end of line1P1
                    // const circle1P2 = createCircle(new THREE.Vector3(itemBoundingBox?.max.x, item?.position.y - (lineDistance / 1.8), item?.position.z)); // Circle at the end of line1P2
                    const circle1P2 = createCircle(startPoint1P1); // Circle at the end of line1P2
                    circle1P2.position.y = itemBoundingBox?.max.y

                    // Create groups for lines and their circles
                    const group1 = new THREE.Group();
                    group1.add(line1);
                    group1.add(line1P1);
                    group1.add(line1P2);
                    group1.add(circle1P1);
                    group1.add(circle1P2);

                    // Name the group
                    group1.name = "fullLines";

                    // Add the group to the scene
                    sceneRef?.current?.add(group1);

                    // Store the center group for further manipulation if needed
                    setTimeout(() => {
                        uiLinesRef.current.push(centerGroup);
                    }, 100);
                }
            })
        }
    }, 200);
}

export const addUiLinesRight = (itemRef, uiLinesRef, sceneRef, themeColor) => {

    return;

    uiLinesRef.current = []

    removeLayoutLines(sceneRef);

    setTimeout(() => {
        if (itemRef && itemRef.length > 0) {
            itemRef.forEach((item) => {
                if (item.name.includes('FrameLeft')) {

                    const itemBoundingBox = new THREE.Box3().setFromObject(item);
                    const lineDistance = 0.16;

                    // Define two endpoints for the verticle line
                    const startPoint1 = new THREE.Vector3(item?.position.x + lineDistance, itemBoundingBox?.min.y, item?.position.z);
                    const endPoint1 = new THREE.Vector3(item?.position.x + lineDistance, itemBoundingBox?.max.y, item?.position.z);

                    // Center point of created UI lines
                    const centerPoint1P1 = new THREE.Vector3(
                        (startPoint1.x + endPoint1.x) / 2,
                        ((startPoint1.y + endPoint1.y) / 2) - 0.05,
                        (startPoint1.z + endPoint1.z) / 2
                    );

                    // Clone the item and adjust its position
                    const centerGroup = item.clone();
                    centerGroup.position.copy(centerPoint1P1);

                    // Define two endpoints for the horizontal line's perpendicular sub-lines
                    const startPoint1P1 = new THREE.Vector3(item?.position.x + (lineDistance / 1.8), itemBoundingBox?.min.y, item?.position.z);
                    const endPoint1P1 = new THREE.Vector3(item?.position.x + lineDistance, itemBoundingBox?.min.y, item?.position.z);

                    // Create a line geometry
                    const geometry1 = new THREE.BufferGeometry().setFromPoints([startPoint1, endPoint1]);

                    // Geometry for perpendicular sub-lines
                    const geometry3 = new THREE.BufferGeometry().setFromPoints([startPoint1P1, endPoint1P1]);

                    // Create a line material
                    const material = new THREE.LineBasicMaterial({ color: themeColor });

                    // Create line meshes
                    const line1 = new THREE.Line(geometry1, material);
                    const line1P1 = new THREE.Line(geometry3, material);

                    // Clone and position the second perpendicular line
                    const line1P2 = line1P1.clone();
                    line1P2.position.y = (itemBoundingBox?.max.y - itemBoundingBox?.min.y);

                    // Circle parameters
                    const circleRadius = 15 / 1000; // 4px radius (assuming 1 unit = 1 meter, adjust if necessary)
                    const circleGeometry = new THREE.CircleGeometry(circleRadius, 32);

                    // Function to create a circle mesh
                    const createCircle = (position) => {
                        const circleMaterial = new THREE.MeshBasicMaterial({ color: themeColor, side: THREE.DoubleSide });
                        const circle = new THREE.Mesh(circleGeometry, circleMaterial);
                        circle.position.copy(position);
                        return circle;
                    };

                    // Create circles for perpendicular sub-lines
                    const circle1P1 = createCircle(startPoint1P1); // Circle at the end of line1P1
                    // const circle1P2 = createCircle(new THREE.Vector3(itemBoundingBox?.max.x, item?.position.y - (lineDistance / 1.8), item?.position.z)); // Circle at the end of line1P2
                    const circle1P2 = createCircle(startPoint1P1); // Circle at the end of line1P2
                    circle1P2.position.y = itemBoundingBox?.max.y

                    // Create groups for lines and their circles
                    const group1 = new THREE.Group();
                    group1.add(line1);
                    group1.add(line1P1);
                    group1.add(line1P2);
                    group1.add(circle1P1);
                    group1.add(circle1P2);

                    // Name the group
                    group1.name = "uiLines uiLines_v";

                    // Add the group to the scene
                    sceneRef?.current?.add(group1);

                    // Store the center group for further manipulation if needed
                    setTimeout(() => {
                        uiLinesRef.current.push(centerGroup);
                    }, 100);
                }
            })

            // const elements = document.getElementById('elements')
            // elements.style.display = 'block'
        }
    }, 200);
}

export const addUiLinesRightTransom = (itemRef, uiLinesRef, sceneRef, themeColor, rightFrame) => {

    return;

    // console.log(itemRef, "itemRef")

    uiLinesRef.current = []

    removeLayoutLines(sceneRef);

    const rightFrameBoundingBox = new THREE.Box3().setFromObject(rightFrame);

    setTimeout(() => {
        if (itemRef && itemRef.length > 0) {
            itemRef.forEach((item) => {
                if (item.name.includes('GlassPanel')) {

                    const itemBoundingBox = new THREE.Box3().setFromObject(item);

                    // console.log(rightFrameBoundingBox?.max.x - rightFrameBoundingBox?.min.x, "check potition")

                    // if(rightFrame.position.x > (itemBoundingBox?.max.x + (rightFrameBoundingBox?.max.x - rightFrameBoundingBox?.min.x) + .1)) {
                    //     console.log("return")
                    //     return;
                    // }

                    const lineDistance = 0.16;

                    // Define two endpoints for the verticle line
                    const startPoint1 = new THREE.Vector3(item?.position?.x + ((itemBoundingBox?.max.x - itemBoundingBox?.min.x) / 2) + lineDistance, itemBoundingBox?.min.y, item?.position.z);
                    const endPoint1 = new THREE.Vector3(item?.position?.x + ((itemBoundingBox?.max.x - itemBoundingBox?.min.x) / 2) + lineDistance, itemBoundingBox?.max.y, item?.position.z);

                    // Center point of created UI lines
                    const centerPoint1P1 = new THREE.Vector3(
                        (startPoint1.x + endPoint1.x) / 2,
                        ((startPoint1.y + endPoint1.y) / 2) - 0.05,
                        (startPoint1.z + endPoint1.z) / 2
                    );

                    // Clone the item and adjust its position
                    const centerGroup = item.clone();
                    centerGroup.position.copy(centerPoint1P1);

                    // Define two endpoints for the horizontal line's perpendicular sub-lines
                    const startPoint1P1 = new THREE.Vector3(item?.position?.x + ((itemBoundingBox?.max.x - itemBoundingBox?.min.x) / 2) + (lineDistance / 1.8), itemBoundingBox?.min.y, item?.position.z);
                    const endPoint1P1 = new THREE.Vector3(item?.position?.x + ((itemBoundingBox?.max.x - itemBoundingBox?.min.x) / 2) + lineDistance, itemBoundingBox?.min.y, item?.position.z);

                    // Create a line geometry
                    const geometry1 = new THREE.BufferGeometry().setFromPoints([startPoint1, endPoint1]);

                    // Geometry for perpendicular sub-lines
                    const geometry3 = new THREE.BufferGeometry().setFromPoints([startPoint1P1, endPoint1P1]);

                    // Create a line material
                    const material = new THREE.LineBasicMaterial({ color: themeColor });

                    // Create line meshes
                    const line1 = new THREE.Line(geometry1, material);
                    const line1P1 = new THREE.Line(geometry3, material);

                    // Clone and position the second perpendicular line
                    const line1P2 = line1P1.clone();
                    line1P2.position.y = (itemBoundingBox?.max.y - itemBoundingBox?.min.y);

                    // Circle parameters
                    const circleRadius = 15 / 1000; // 4px radius (assuming 1 unit = 1 meter, adjust if necessary)
                    const circleGeometry = new THREE.CircleGeometry(circleRadius, 32);

                    // Function to create a circle mesh
                    const createCircle = (position) => {
                        const circleMaterial = new THREE.MeshBasicMaterial({ color: themeColor, side: THREE.DoubleSide });
                        const circle = new THREE.Mesh(circleGeometry, circleMaterial);
                        circle.position.copy(position);
                        return circle;
                    };

                    // Create circles for perpendicular sub-lines
                    const circle1P1 = createCircle(startPoint1P1); // Circle at the end of line1P1
                    // const circle1P2 = createCircle(new THREE.Vector3(itemBoundingBox?.max.x, item?.position.y - (lineDistance / 1.8), item?.position.z)); // Circle at the end of line1P2
                    const circle1P2 = createCircle(startPoint1P1); // Circle at the end of line1P2
                    circle1P2.position.y = itemBoundingBox?.max.y

                    // Create groups for lines and their circles
                    const group1 = new THREE.Group();
                    group1.add(line1);
                    group1.add(line1P1);
                    group1.add(line1P2);
                    group1.add(circle1P1);
                    group1.add(circle1P2);

                    // Name the group
                    group1.name = "uiLines uiLines_v";
                    // group1.position.x = item?.position?.x

                    // Add the group to the scene
                    sceneRef?.current?.add(group1);

                    // Store the center group for further manipulation if needed
                    setTimeout(() => {
                        uiLinesRef.current.push(centerGroup);
                    }, 100);
                }
            })

            // const elements = document.getElementById('elements')
            // elements.style.display = 'block'
        }
    }, 200);
}

export const addUiLinesBottom = (itemRef, uiLinesRef, sceneRef, themeColor) => {

    uiLinesRef.current = []

    removeLayoutLines(sceneRef);

    setTimeout(() => {
        if (itemRef && itemRef.length > 0) {
            itemRef.forEach((item) => {
                if (item.name.includes('horizontalBarBottom') || item.name.includes('FrameBottom')) {

                    const itemBoundingBox = new THREE.Box3().setFromObject(item);
                    const lineDistance = 0.16;

                    // Define two endpoints for the horizontal line
                    const startPoint1 = new THREE.Vector3(itemBoundingBox?.min.x, item?.position.y - lineDistance, item?.position.z);
                    const endPoint1 = new THREE.Vector3(itemBoundingBox?.max.x, item?.position.y - lineDistance, item?.position.z);

                    // Center point of created UI lines
                    const centerPoint1P1 = new THREE.Vector3(
                        (startPoint1.x + endPoint1.x) / 2,
                        ((startPoint1.y + endPoint1.y) / 2) - 0.05,
                        (startPoint1.z + endPoint1.z) / 2
                    );

                    // Clone the item and adjust its position
                    const centerGroup = item.clone();
                    centerGroup.position.copy(centerPoint1P1);

                    // Define two endpoints for the horizontal line's perpendicular sub-lines
                    const startPoint1P1 = new THREE.Vector3(itemBoundingBox?.min.x, item?.position.y - lineDistance, item?.position.z);
                    const endPoint1P1 = new THREE.Vector3(itemBoundingBox?.min.x, item?.position.y - (lineDistance / 1.8), item?.position.z);

                    // Create a line geometry
                    const geometry1 = new THREE.BufferGeometry().setFromPoints([startPoint1, endPoint1]);

                    // Geometry for perpendicular sub-lines
                    const geometry3 = new THREE.BufferGeometry().setFromPoints([startPoint1P1, endPoint1P1]);

                    // Create a line material
                    const material = new THREE.LineBasicMaterial({ color: themeColor });

                    // Create line meshes
                    const line1 = new THREE.Line(geometry1, material);
                    const line1P1 = new THREE.Line(geometry3, material);

                    // Clone and position the second perpendicular line
                    const line1P2 = line1P1.clone();
                    line1P2.position.x = (itemBoundingBox?.max.x - itemBoundingBox?.min.x);

                    // Circle parameters
                    const circleRadius = 15 / 1000; // 4px radius (assuming 1 unit = 1 meter, adjust if necessary)
                    const circleGeometry = new THREE.CircleGeometry(circleRadius, 32);

                    // Function to create a circle mesh
                    const createCircle = (position) => {
                        const circleMaterial = new THREE.MeshBasicMaterial({ color: themeColor, side: THREE.DoubleSide });
                        const circle = new THREE.Mesh(circleGeometry, circleMaterial);
                        circle.position.copy(position);
                        return circle;
                    };

                    // Create circles for perpendicular sub-lines
                    const circle1P1 = createCircle(endPoint1P1); // Circle at the end of line1P1
                    const circle1P2 = createCircle(new THREE.Vector3(itemBoundingBox?.max.x, item?.position.y - (lineDistance / 1.8), item?.position.z)); // Circle at the end of line1P2

                    // Create groups for lines and their circles
                    const group1 = new THREE.Group();
                    group1.add(line1);
                    group1.add(line1P1);
                    group1.add(line1P2);
                    group1.add(circle1P1);
                    group1.add(circle1P2);

                    // Name the group
                    group1.name = "uiLines uiLines_h";

                    // Add the group to the scene
                    sceneRef?.current?.add(group1);

                    // Store the center group for further manipulation if needed
                    setTimeout(() => {
                        uiLinesRef.current.push(centerGroup);
                    }, 100);
                }
            })

            // const elements = document.getElementById('elements')
            // elements.style.display = 'block'
        }
    }, 200);
};

export const addUiLinesBottomTransom = (itemRef, uiLinesRef, sceneRef, themeColor, bottomFrame) => {

    uiLinesRef.current = []
    removeLayoutLines(sceneRef);

    let sillHeigth = 0

    setTimeout(() => {
        if (itemRef && itemRef.length > 0) {
            itemRef.forEach((item, index) => {
                if (item.name.includes('SashGroupH')) {

                    sceneRef?.current?.traverse(function (child) {
                        // console.log(child.name, "sceneRef?.current")
                        if (child.name.includes("sill")) {
                            const sillBounding = new THREE.Box3().setFromObject(child);

                            // console.log(sillBounding.max.y - sillBounding.min.y, "object");

                            sillHeigth = (sillBounding.max.y - sillBounding.min.y)
                        }
                    });

                    const itemBoundingBox = new THREE.Box3().setFromObject(item);
                    const lineDistance = 0.16;

                    // Define two endpoints for the horizontal line
                    const startPoint1 = new THREE.Vector3(itemBoundingBox?.min.x, bottomFrame?.position.y - lineDistance, item?.position.z);
                    const endPoint1 = new THREE.Vector3(itemBoundingBox?.max.x, bottomFrame?.position.y - lineDistance, item?.position.z);

                    // Center point of created UI lines
                    const centerPoint1P1 = new THREE.Vector3(
                        (startPoint1.x + endPoint1.x) / 2,
                        ((startPoint1.y + endPoint1.y) / 2) - 0.05 - sillHeigth,
                        (startPoint1.z + endPoint1.z) / 2
                    );

                    // Clone the item and adjust its position
                    const centerGroup = item.clone();
                    centerGroup.position.copy(centerPoint1P1);

                    // Define two endpoints for the horizontal line's perpendicular sub-lines
                    const startPoint1P1 = new THREE.Vector3(itemBoundingBox?.min.x, bottomFrame?.position.y - lineDistance, item?.position.z);
                    const endPoint1P1 = new THREE.Vector3(itemBoundingBox?.min.x, bottomFrame?.position.y - (lineDistance / 1.8), item?.position.z);

                    // Create a line geometry
                    const geometry1 = new THREE.BufferGeometry().setFromPoints([startPoint1, endPoint1]);

                    // Geometry for perpendicular sub-lines
                    const geometry3 = new THREE.BufferGeometry().setFromPoints([startPoint1P1, endPoint1P1]);

                    // Create a line material
                    const material = new THREE.LineBasicMaterial({ color: themeColor });

                    // Create line meshes
                    const line1 = new THREE.Line(geometry1, material);
                    const line1P1 = new THREE.Line(geometry3, material);

                    // Clone and position the second perpendicular line
                    const line1P2 = line1P1.clone();
                    line1P2.position.x = (itemBoundingBox?.max.x - itemBoundingBox?.min.x);

                    // Circle parameters
                    const circleRadius = 15 / 1000; // 4px radius (assuming 1 unit = 1 meter, adjust if necessary)
                    const circleGeometry = new THREE.CircleGeometry(circleRadius, 32);

                    // Function to create a circle mesh
                    const createCircle = (position) => {
                        const circleMaterial = new THREE.MeshBasicMaterial({ color: themeColor, side: THREE.DoubleSide });
                        const circle = new THREE.Mesh(circleGeometry, circleMaterial);
                        circle.position.copy(position);
                        return circle;
                    };

                    // Create circles for perpendicular sub-lines
                    const circle1P1 = createCircle(endPoint1P1); // Circle at the end of line1P1
                    const circle1P2 = createCircle(new THREE.Vector3(itemBoundingBox?.max.x, bottomFrame?.position.y - (lineDistance / 1.8), item?.position.z)); // Circle at the end of line1P2

                    // Create groups for lines and their circles
                    const group1 = new THREE.Group();
                    group1.add(line1);
                    group1.add(line1P1);
                    group1.add(circle1P1);

                    if (itemRef.length == (index + 1)) {
                        group1.add(line1P2);
                        group1.add(circle1P2);
                    }

                    // Name the group
                    group1.name = "uiLines uiLines_h";

                    // console.log(sillHeigth, "sillHeigth")
                    if (sillHeigth > 0) {
                        group1.position.y -= sillHeigth
                    }

                    // Add the group to the scene
                    sceneRef?.current?.add(group1);

                    // Store the center group for further manipulation if needed
                    setTimeout(() => {
                        uiLinesRef.current.push(centerGroup);
                    }, 100);
                }
            })

            // const elements = document.getElementById('elements')
            // elements.style.display = 'block'
        }
    }, 500);
};

// export const addLayoutLinesArrow = (bottomRef, rightRef, sceneRef, themeColor) => {
//     removeLayoutLines(sceneRef);

//     // Add the line to the scene
//     setTimeout(() => {
//         const boundingBoxBottom = new THREE.Box3().setFromObject(bottomRef);
//         const boundingBoxLeft = new THREE.Box3().setFromObject(rightRef);

//         // Create arrowheads
//         const arrowLength = 0.1;
//         const arrowRadius = 0.02;
//         const arrowSegments = 12;

//         // Define two endpoints for the first line
//         const startPoint1 = new THREE.Vector3(boundingBoxBottom?.min.x + (arrowLength / 2), bottomRef?.position.y - 0.2, bottomRef?.position.z);
//         const endPoint1 = new THREE.Vector3(boundingBoxBottom?.max.x - (arrowLength / 2), bottomRef?.position.y - 0.2, bottomRef?.position.z);

//         // Define two endpoints for the second line
//         const startPoint2 = new THREE.Vector3(rightRef?.position.x + 0.2, boundingBoxLeft?.min.y - arrowLength, rightRef?.position.z);
//         const endPoint2 = new THREE.Vector3(rightRef?.position.x + 0.2, boundingBoxLeft?.max.y - arrowLength, rightRef?.position.z);

//         // Create a line geometry
//         const geometry1 = new THREE.BufferGeometry().setFromPoints([startPoint1, endPoint1]);
//         const geometry2 = new THREE.BufferGeometry().setFromPoints([startPoint2, endPoint2]);

//         // Create a line material
//         const material = new THREE.LineBasicMaterial({ color: themeColor });

//         // Create line meshes
//         const line1 = new THREE.Line(geometry1, material);
//         const line2 = new THREE.Line(geometry2, material);

//         const arrowGeometry = new THREE.ConeGeometry(arrowRadius, arrowLength, arrowSegments);
//         const arrowMaterial = new THREE.MeshBasicMaterial({ color: themeColor });

//         // Function to create an arrowhead
//         const createArrowHead = (position, direction) => {
//             const arrowHead = new THREE.Mesh(arrowGeometry, arrowMaterial);
//             arrowHead.position.copy(position);
//             arrowHead.quaternion.setFromUnitVectors(new THREE.Vector3(0, 1, 0), direction.clone().normalize());
//             return arrowHead;
//         };

//         // Create arrowheads for line1
//         const arrow1Start = createArrowHead(startPoint1, new THREE.Vector3().subVectors(startPoint1, endPoint1));
//         const arrow1End = createArrowHead(endPoint1, new THREE.Vector3().subVectors(endPoint1, startPoint1));

//         // Create arrowheads for line2
//         const arrow2Start = createArrowHead(startPoint2, new THREE.Vector3().subVectors(startPoint2, endPoint2));
//         const arrow2End = createArrowHead(endPoint2, new THREE.Vector3().subVectors(endPoint2, startPoint2));

//         // Name the lines
//         line1.name = "uiLines uiLines_h";
//         line2.name = "uiLines uiLines_v";

//         // Add lines and arrowheads to the scene
//         sceneRef?.current?.add(line1);
//         sceneRef?.current?.add(line2);
//         sceneRef?.current?.add(arrow1Start);
//         sceneRef?.current?.add(arrow1End);
//         sceneRef?.current?.add(arrow2Start);
//         sceneRef?.current?.add(arrow2End);
//     }, 200);
// };

export const removeLayoutLines = (sceneRef) => {
    // removing Profile Joints before adding
    if (sceneRef.current && sceneRef.current.children && sceneRef.current.children.length > 0) {
        var uiLines = ''

        sceneRef?.current?.traverse(function (object) {
            if (object.name.includes("uiLines")) {
                object.visible = false;
                uiLines = object
            }
        });

        if (uiLines) {
            sceneRef.current.remove(uiLines);
        }
    }
}

export const removeFrameLines = (sceneRef) => {
    // removing Profile Joints before adding
    if (sceneRef.current && sceneRef.current.children && sceneRef.current.children.length > 0) {
        var uiLines = ''

        sceneRef?.current?.traverse(function (object) {
            if (object.name.includes("fullLines")) {
                object.visible = false;
                uiLines = object
            }
        });

        if (uiLines) {
            sceneRef.current.remove(uiLines);
        }
    }
}

export const  resizeSash = (sashGroup, frameStyleRight, applyPartialObj, savedData, setApplyPartialTrigger, setSavePartialWidths, setCustomModelData, lockRefIndex, setResizeSashStyles) => {

    let boundingBoxGlass = [];
    let glassObject = [];
    // let horizontalBarTop = [];
    let previousSashBoundingBox;

    let partialWidth = (parseFloat(savedData?.width) / parseInt(savedData?.count)).toFixed(2);

    let xScale = []
    let initialLength = []
    let finalLength = []

    console.log(partialWidth ,savedData?.sashSize, "836");
    
    if (savedData && savedData?.sashSize?.length > 0) {
        initialLength = savedData?.sashSize
    } else {
        let i = 0;

        for (i; i < sashGroup?.current?.length; i++) {
            let initWidth = partialWidth
            initialLength.push(parseInt(initWidth))
        }
    }

    console.log(initialLength ,applyPartialObj, "849");
    

    // This is for getting the final length array
    if (applyPartialObj && initialLength?.length > 0) {
        finalLength = updateArray(initialLength, parseFloat(savedData?.width), applyPartialObj?.index, parseFloat(applyPartialObj?.width), savedData, lockRefIndex)
    }

    // calculation xScale factor using finalLenght and initialLenght for eash glazing
    if (initialLength?.length > 0 && finalLength?.length > 0 && (initialLength?.length == finalLength?.length)) {
        finalLength?.forEach((item, index) => {
            let num = parseFloat(item) / parseFloat(initialLength[index])
            // console.log(item, initialLength[index], num, "test")
            xScale.push(num)
        })
    }

    console.log(initialLength, finalLength, xScale, sashGroup, "resize data")

    // using xScale to resize glazing
    if (finalLength?.length > 0 && xScale?.length > 0 && (xScale?.length == initialLength.length)) {
        sashGroup.current.forEach((child, index) => {

            child.traverse((item) => {
                if (item?.name.includes("Glass") || item?.name.includes("panelGlazing")) {
                    glassObject.push(item);
                    item.scale.x *= xScale[index];
                    boundingBoxGlass.push(new THREE.Box3().setFromObject(item));
                }

                // if (item?.name.includes("horizontalBarTop")) {
                //     horizontalBarTop.push(new THREE.Box3().setFromObject(item))
                // }
            })
        })

        sashGroup.current.forEach((child, index) => {

            child.traverse((item) => {

                if (item?.name.includes("horizontalBar")) {
                    // console.log(index, item.scale.x, xScale[index], item, "xScale[index]")
                    item.scale.x *= xScale[index];
                }

                if (item?.name.includes('verticalBarLeft')) {
                    // console.log(item?.name, glassObject[index], "aadi test")
                    // const width = boundingBoxGlass[index]?.max.x - boundingBoxGlass[index]?.min.x;
                    item.position.x = glassObject[index].position.x;
                }

                if (item?.name.includes('verticalBarRight')) {
                    // console.log(item?.name, glassObject[index], "aadi test")
                    // const width = boundingBoxGlass[index]?.max.x - boundingBoxGlass[index]?.min.x;
                    item.position.x = glassObject[index].position.x;
                }
            })

            if (index == 0) {
                const boundingBoxSash = new THREE.Box3().setFromObject(child);
                const sashWidth = boundingBoxSash?.max.x - boundingBoxSash?.min.x;
                child.position.x = frameStyleRight.current.position.x + sashWidth / 2;
            } else {
                previousSashBoundingBox = new THREE.Box3().setFromObject(sashGroup.current[index - 1])
                const boundingBoxSash = new THREE.Box3().setFromObject(child);
                const sashWidth = boundingBoxSash?.max.x - boundingBoxSash?.min.x;
                child.position.x = previousSashBoundingBox?.max.x + sashWidth / 2;
            }
        })

        sashGroup.current.forEach((child, index) => {
            // console.log(glassObject[index], "glassObject[index]")

            child.traverse((item) => {

                if (item?.name.includes('verticalBarRight')) {
                    boundingBoxGlass[index] = new THREE.Box3().setFromObject(sashGroup.current[index])
                    const boundingBox2 = new THREE.Box3().setFromObject(item)
                    const itemWidth = boundingBox2?.max.x - boundingBox2?.min.x;
                    const width = boundingBoxGlass[index]?.max.x - boundingBoxGlass[index]?.min.x;
                    item.position.x = glassObject[index].position.x + ((width * 0.5) * sashGroup?.current?.length) - ((itemWidth * 0.5) * sashGroup?.current?.length);
                }

                if (item?.name.includes('verticalBarLeft')) {
                    boundingBoxGlass[index] = new THREE.Box3().setFromObject(sashGroup.current[index])
                    const boundingBox2 = new THREE.Box3().setFromObject(item)
                    const itemWidth = boundingBox2?.max.x - boundingBox2?.min.x;
                    const width = boundingBoxGlass[index]?.max.x - boundingBoxGlass[index]?.min.x;
                    item.position.x = glassObject[index].position.x - ((width * 0.5) * sashGroup?.current?.length) + ((itemWidth * 0.5) * sashGroup?.current?.length);
                }
            })
        })

        setResizeSashStyles(true)

        setCustomModelData((prevModelData) => ({
            ...prevModelData,
            numberOfSash: {
                number: savedData.count,
            },
            xScale: xScale,
            sashSize: finalLength,
        }))

        setTimeout(() => {
            setApplyPartialTrigger(false)
            setSavePartialWidths(true)
        }, 100);
    }
}

export const resizeSashInit = (sashGroup, frameStyleRight, savedData, setPartialWidthInit, setResizeSashStyles) => {

    console.log(savedData, "resizeSashInit")

    let boundingBoxGlass = [];
    let glassObject = [];
    let horizontalBarTop = [];
    let previousSashBoundingBox;

    let partialWidth = (parseFloat(savedData?.width) / parseInt(savedData?.count)).toFixed(2);

    let xScale = []
    let initialLength = []
    let finalLength = []

    let i = 0;

    for (i; i < sashGroup?.current?.length; i++) {
        let initWidth = partialWidth
        initialLength.push(parseInt(initWidth))
    }

    // This is for getting the final length array
    if (initialLength?.length > 0) {
        finalLength = savedData.sashSize
    }

    // calculation xScale factor using finalLenght and initialLenght for eash glazing
    if (initialLength?.length > 0 && finalLength?.length > 0 && (initialLength?.length == finalLength?.length)) {
        finalLength?.forEach((item, index) => {
            let num = parseFloat(item) / parseFloat(initialLength[index])
            xScale.push(num)
        })
    }

    console.log(initialLength, finalLength, sashGroup, "resize data init")

    // using xScale to resize glazing
    if (finalLength?.length > 0 && xScale?.length > 0 && (xScale?.length == initialLength.length)) {

        sashGroup.current.forEach((child, index) => {

            child.traverse((item) => {
                if (item?.name.includes("Glass") || item?.name.includes("panelGlazing")) {
                    glassObject.push(item);
                    item.scale.x *= xScale[index];
                    boundingBoxGlass.push(new THREE.Box3().setFromObject(item));
                }

                if (item?.name.includes("horizontalBarTop")) {
                    horizontalBarTop.push(new THREE.Box3().setFromObject(item))
                }
            })
        })

        sashGroup.current.forEach((child, index) => {

            child.traverse((item) => {
                if (item?.name.includes("horizontalBar")) {
                    item.scale.x *= xScale[index];
                }

                if (item?.name.includes('verticalBarLeft')) {
                    // const width = boundingBoxGlass[index]?.max?.x - boundingBoxGlass[index]?.min?.x;
                    item.position.x = glassObject[index]?.position?.x;
                }

                if (item?.name.includes('verticalBarRight')) {
                    // const width = boundingBoxGlass[index]?.max?.x - boundingBoxGlass[index]?.min?.x;
                    item.position.x = glassObject[index]?.position?.x;
                }
            })

            if (index == 0) {
                const boundingBoxSash = new THREE.Box3().setFromObject(child);
                const sashWidth = boundingBoxSash.max.x - boundingBoxSash.min.x;
                child.position.x = frameStyleRight?.current?.position?.x + sashWidth / 2;
            } else {
                previousSashBoundingBox = new THREE.Box3().setFromObject(sashGroup.current[index - 1])
                const boundingBoxSash = new THREE.Box3().setFromObject(child);
                const sashWidth = boundingBoxSash.max.x - boundingBoxSash.min.x;
                child.position.x = previousSashBoundingBox.max.x + sashWidth / 2;
            }
        })


        sashGroup.current.forEach((child, index) => {
            child.traverse((item) => {
                if (item?.name.includes('verticalBarRight')) {
                    boundingBoxGlass[index] = new THREE.Box3().setFromObject(sashGroup.current[index])
                    const boundingBox2 = new THREE.Box3().setFromObject(item)
                    const itemWidth = boundingBox2?.max.x - boundingBox2?.min.x;
                    const width = boundingBoxGlass[index]?.max.x - boundingBoxGlass[index]?.min.x;
                    item.position.x = glassObject[index].position.x + ((width * 0.5) * sashGroup?.current?.length) - ((itemWidth * 0.5) * sashGroup?.current?.length);
                }

                if (item?.name.includes('verticalBarLeft')) {
                    boundingBoxGlass[index] = new THREE.Box3().setFromObject(sashGroup.current[index])
                    const boundingBox2 = new THREE.Box3().setFromObject(item)
                    const itemWidth = boundingBox2?.max.x - boundingBox2?.min.x;
                    const width = boundingBoxGlass[index]?.max.x - boundingBoxGlass[index]?.min.x;
                    item.position.x = glassObject[index].position.x - ((width * 0.5) * sashGroup?.current?.length) + ((itemWidth * 0.5) * sashGroup?.current?.length);
                }
            })
            // console.log(glassObject[index], "glassObject[index]")
        })
    }
    
    setResizeSashStyles(true)

    setTimeout(() => {
        setPartialWidthInit(false)
    }, 100);
}

// function updateArray2(originalArray, frameWidth, newIndex, partialValue, savedData, lockRefIndex) {

//     let extraVal = parseFloat((parseFloat(originalArray[newIndex]) - parseFloat(partialValue)) / (parseInt(savedData.count) - 1))

//     if (lockRefIndex?.length > 0) {
//         extraVal = parseFloat((parseFloat(originalArray[newIndex]) - parseFloat(partialValue)) / (parseInt(savedData.count) - (1 + lockRefIndex?.length)))
//     }

//     // console.log(lockRefIndex, "lockRefIndex")

//     let resultedArray = []

//     originalArray.map((value, index) => {
//         if (index == newIndex) {
//             // console.log(partialValue, `aadi test 1`)
//             resultedArray.push(partialValue)
//         } else {
//             if (!lockRefIndex.includes(index)) {
//                 // console.log(Math.round(parseFloat(originalArray[index]) + parseFloat(extraVal)), `aadi test 2`)
//                 resultedArray.push(Math.round(parseFloat(originalArray[index]) + parseFloat(extraVal)))
//             } else {
//                 resultedArray.push(parseFloat(originalArray[index]))
//                 // console.log(`this -> ${index} index is locked`)
//             }
//         }
//     });

//     return resultedArray;
// }

function updateArray(originalArray, frameWidth, newIndex, partialValue, savedData, lockRefIndex) {

    let extraVal = parseFloat((parseFloat(originalArray[newIndex]) - parseFloat(partialValue)) / (parseInt(savedData.count) - 1))

    if (lockRefIndex?.length > 0) {
        extraVal = parseFloat((parseFloat(originalArray[newIndex]) - parseFloat(partialValue)) / (parseInt(savedData.count) - (1 + lockRefIndex?.length)))
    }

    let lockedLength = lockRefIndex && lockRefIndex.length > 0 ? lockRefIndex?.reduce((sum, index) => sum + savedData.sashSize[index], 0) : 0;

    let resultedArray = []
    let resultedArrayFinal = []
    let newTotalWidth = 0

    originalArray.map((value, index) => {
        if (index == newIndex) {
            resultedArray.push(partialValue)
        } else {
            if (!lockRefIndex.includes(index)) {
                resultedArray.push(Math.round(parseFloat(originalArray[index]) + parseFloat(extraVal)))
            } else {
                resultedArray.push(parseFloat(originalArray[index]))
            }
        }
    });

    if (resultedArray && resultedArray.length > 0) {
        resultedArray.map((value, index) => {
            if (value > 0) {
                newTotalWidth += parseFloat(value)
            } else {
                newTotalWidth -= parseFloat(value)
            }
        });
    }

    if (newTotalWidth > frameWidth) {
        let partNewWidth = (parseFloat(savedData.width) - parseFloat(partialValue) - lockedLength) / (parseInt(savedData.count) - (1 + lockRefIndex?.length))

        originalArray.map((value, index) => {
            if (index == newIndex) {
                resultedArrayFinal.push(partialValue)
            } else {
                if (!lockRefIndex.includes(index)) {
                    resultedArrayFinal.push(Math.round(partNewWidth))
                } else {
                    resultedArrayFinal.push(parseFloat(originalArray[index]))
                }
            }
        });
    } else {
        resultedArrayFinal = resultedArray
    }

    return resultedArrayFinal;
}

export const updateSashSize = (sashSize, oldWidth, width, sashCount, lockRefIndex) => {

    console.log(sashSize, oldWidth, width, "updateSashSize")

    let resultedArray = []
    let resultedArrayFinal = []
    let newTotalWidth = 0

    let extraVal = parseFloat((parseFloat(width) - parseFloat(oldWidth)) / sashCount)

    if (lockRefIndex?.length > 0) {
        extraVal = parseFloat((parseFloat(width) - parseFloat(oldWidth)) / (sashCount - lockRefIndex?.length))
    }

    let lockedLength = lockRefIndex && lockRefIndex.length > 0 ? lockRefIndex?.reduce((sum, index) => sum + sashSize[index], 0) : 0;

    if (sashSize && sashSize.length > 0) {
        sashSize.map((item, index) => {
            if (!lockRefIndex.includes(index)) {
                resultedArray.push(Math.round(parseFloat(item) + parseFloat(extraVal)))
            } else {
                resultedArray.push(parseFloat(item))
            }
        });
    }

    if (resultedArray && resultedArray.length > 0) {
        resultedArray.map((value, index) => {
            if (value > 0) {
                newTotalWidth += parseFloat(value)
            } else {
                newTotalWidth -= parseFloat(value)
            }
        });
    }

    if (newTotalWidth > width) {
        let partNewWidth = (parseFloat(width) - lockedLength) / (parseInt(sashCount) - (1 + lockRefIndex?.length))

        sashSize.map((item, index) => {
            if (!lockRefIndex.includes(index)) {
                resultedArray.push(Math.round(partNewWidth))
            } else {
                resultedArray.push(parseFloat(item))
            }
        });
    } else {
        resultedArrayFinal = resultedArray
    }

    return resultedArrayFinal;
}

export const getAllProfileRefScene = (allProfileRefSeq, sceneRef) => {

    allProfileRefSeq.current = []

    sceneRef.current.traverse((item) => {

        if ((item.name.includes("FrameRight") || item.name.includes("FrameLeft") || item.name.includes("FrameTop") || item.name.includes("FrameBottom")) && !item.name.includes("ProfileJoints")) {
            const index = allProfileRefSeq.current.findIndex((newItem) => newItem.name === item.name);
            if (index === -1) {
                allProfileRefSeq.current.push(item);
            }
        }

        setTimeout(() => {
            if (item?.name.includes("verticalBarRight") || item?.name.includes("verticalBarLeft") || item?.name.includes("horizontalBarTop") || item?.name.includes("horizontalBarBottom")) {
                allProfileRefSeq?.current?.push(item)
            }
        }, 100);

        setTimeout(() => {
            if (item?.name.includes("sill")) {
                allProfileRefSeq?.current?.push(item)
            }
        }, 200);
    })
}

export const getAllProfileRef = (allProfileRefSeq, glassRefSeq, frameRef, sashRef, sillRef, sashGroup) => {

    allProfileRefSeq.current = []
    glassRefSeq.current = []

    if (frameRef && frameRef.current && frameRef.current.length > 0) {
        frameRef.current.forEach((item) => {
            allProfileRefSeq.current.push(item);
        });
    }

    setTimeout(() => {
        if (sashRef && sashRef.current && sashRef.current.length > 0) {
            sashRef.current.forEach((item) => {
                allProfileRefSeq.current.push(item);
            });
        }
    }, 100);

    setTimeout(() => {
        if (sillRef && sillRef.current && sillRef.current.name === 'sill') {
            allProfileRefSeq.current.push(sillRef.current);
        }
    }, 200);

    if (sashGroup && sashGroup.current && sashGroup.current.length > 0) {
        sashGroup.current.forEach((item) => {
            glassRefSeq.current.push(item);
        });
    }

    // sceneRef.current.traverse((item) => {

    //     if ((item.name.includes("FrameRight") || item.name.includes("FrameLeft") || item.name.includes("FrameTop") || item.name.includes("FrameBottom")) && !item.name.includes("ProfileJoints")) {
    //         const index = allProfileRefSeq.current.findIndex((newItem) => newItem.name === item.name);
    //         if (index === -1) {
    //             allProfileRefSeq.current.push(item);
    //         }
    //     }

    //     setTimeout(() => {
    //         if (item?.name.includes("verticalBarRight") || item?.name.includes("verticalBarLeft") || item?.name.includes("horizontalBarTop") || item?.name.includes("horizontalBarBottom")) {
    //             allProfileRefSeq?.current?.push(item)
    //         }
    //     }, 100);

    //     setTimeout(() => {
    //         if (item?.name.includes("sill")) {
    //             allProfileRefSeq?.current?.push(item)
    //         }
    //     }, 200);
    // })
}

export const sortTransomFrame = (horizontal, transomFrame, transomFrameSortedX, transomFrameSortedY, frameStyleRight, applyPartialObj, savedData, setApplyPartialTrigger, setSavePartialWidths, setCustomModelData, lockRefIndex) => {
    if (horizontal) {
        // Extract x positions from the objects and store them in an array
        const xPositions = transomFrame.current.map(obj => obj.position.x);

        // Sort the x positions array
        const sortedXPositions = xPositions.slice().sort((a, b) => a - b);

        // Create a new array of objects sorted by their x position
        transomFrameSortedX.current = sortedXPositions.map(xPos =>
            transomFrame.current.find(obj => obj.position.x === xPos)
        );
    } else {
        // Extract x positions from the objects and store them in an array
        const xPositions = transomFrame.current.map(obj => obj.position.y);

        // Sort the x positions array
        const sortedXPositions = xPositions.slice().sort((a, b) => a - b);

        // Create a new array of objects sorted by their x position
        transomFrameSortedY.current = sortedXPositions.map(xPos =>
            transomFrame.current.find(obj => obj.position.y === xPos)
        );
    }


    let boundingBoxGlass = [];
    let glassObject = [];
    let horizontalBarTop = [];
    let previousSashBoundingBox;

    let partialWidth = (parseFloat(savedData?.width) / parseInt(savedData?.count)).toFixed(2);

    let xScale = []
    let initialLength = []
    let finalLength = []

    if (savedData && savedData?.sashSize?.length > 0) {
        initialLength = savedData?.sashSize
    } else {
        let i = 0;

        for (i; i < transomFrameSortedX?.current?.length; i++) {
            let initWidth = partialWidth
            initialLength.push(parseInt(initWidth))
        }
    }

    // This is for getting the final length array
    if (applyPartialObj && initialLength?.length > 0) {
        finalLength = updateArray(initialLength, parseFloat(savedData?.width), applyPartialObj?.index, parseFloat(applyPartialObj?.width), savedData, lockRefIndex)
    }

    // calculation xScale factor using finalLenght and initialLenght for eash glazing
    if (initialLength?.length > 0 && finalLength?.length > 0 && (initialLength?.length == finalLength?.length)) {
        finalLength?.forEach((item, index) => {
            let num = parseFloat(item) / parseFloat(initialLength[index])
            xScale.push(num)
        })
    }

    console.log(initialLength, finalLength, xScale, transomFrameSortedX, "resize data")

    // using xScale to resize glazing
    if (finalLength?.length > 0 && xScale?.length > 0 && (xScale?.length == initialLength.length)) {
        transomFrameSortedX.current.forEach((child, index) => {

            child.traverse((item) => {
                if (item?.name.includes("TransomFrame") || item?.name.includes("panelGlazing")) {
                    // console.log("glass found sash 00111 ")
                    glassObject.push(item);
                    item.scale.x *= xScale[index];
                    boundingBoxGlass.push(new THREE.Box3().setFromObject(item));
                }

                if (item?.name.includes("horizontalBarTop")) {
                    horizontalBarTop.push(new THREE.Box3().setFromObject(item))
                }
            })
        })

        transomFrameSortedX.current.forEach((child, index) => {
            if (index == 0) {
                const boundingBoxSash = new THREE.Box3().setFromObject(child);
                const sashWidth = boundingBoxSash?.max.x - boundingBoxSash?.min.x;
                child.position.x = frameStyleRight.current.position.x + sashWidth / 2;
            } else {
                previousSashBoundingBox = new THREE.Box3().setFromObject(transomFrameSortedX.current[index - 1])
                const boundingBoxSash = new THREE.Box3().setFromObject(child);
                const sashWidth = boundingBoxSash?.max.x - boundingBoxSash?.min.x;
                child.position.x = previousSashBoundingBox?.max.x + sashWidth / 2;
            }
        })

        setCustomModelData((prevModelData) => ({
            ...prevModelData,
            numberOfSash: {
                number: savedData.count,
            },
            xScale: xScale,
            sashSize: finalLength,
        }))

        setTimeout(() => {
            setApplyPartialTrigger(false)
            setSavePartialWidths(true)
        }, 100);
    }
}