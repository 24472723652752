import axios from "axios";
import { servicePath } from "../utility/data";
export const addOversizeGlazingService = (accessToken, formDataValues) => {
  const formData = new FormData();

  formData.append("Id", formDataValues.id);
  formData.append("MinValue", formDataValues?.minArea);
  formData.append("MaxValue", formDataValues?.maxArea);
  formData.append("Percentage", formDataValues?.percentage);

  let config = {
    method: "POST",
    maxBodyLength: Infinity,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "multipart/form-data",
    },
  };

  return axios
    .post(
      `${servicePath}/Glazing/AddUpdateGlazingOversizePrice`,
      formData,
      config
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error;
    });
};

export const getAllOversizeGlazingService = (accessToken) => {
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  return axios
    .get(`${servicePath}/Glazing/GetAllGlazingOversizePrice`, { headers })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};

export const deleteOverSizeGlazingService = (id, accessToken) => {
  const formData = new FormData();
  formData.append("Id", id);

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  return axios
    .post(`${servicePath}/Glazing/DeleteGlazingOversizePrice`, formData, {
      headers,
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};

export const getOverSizeGlazingIndividualService = (accessToken, id) => {
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const formData = new FormData();
  formData.append("Id", id);
  return axios
    .post(`${servicePath}/Glazing/GetGlazingOversizePrice`, formData, {
      headers,
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};
