import React from "react";
import InternalLayout from "../../../../../../layouts/internalLayout";
import Left from "./left";
import Right from "./right";

const PricingFrameProducts = () => {
  return (
    <>
      <InternalLayout
        pageTitle="Frame Products"
        layoutType=""
        leftContent={<Left />}
        rightContent={<Right />}
      />
    </>
  );
};

export default PricingFrameProducts;
