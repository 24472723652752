import React, { useEffect, useState } from 'react'

import {
    Row,
    Col,
    Button,
    FormGroup,
    Label,
    Input,
    Form,
} from 'reactstrap';
import { useRightContext } from '../../../../../../context/rightMenuContext';

const ForewordsLeft = ({ selectItemDetails, updateItemDetailsServices, handleFormReset }) => {

    const { selected, setSelected, handleEdit, setTimestamp } = useRightContext()

    const [itemId, setItemId] = useState('')
    const [name, setName] = useState('')
    const [textArea, setTextArea] = useState('')
    const [nameError, setNameError] = useState('')
    const [textAreaError, setTextAreaError] = useState('')

    useEffect(() => {
        setSelected(selectItemDetails?.name || '')
        setItemId(selectItemDetails?.id || '')

        setName(selectItemDetails?.name || '')
        setTextArea(selectItemDetails?.templateText || '')

        if (!selectItemDetails?.id) {
            setTimestamp(selectItemDetails?.id)
        }

        setNameError(false)
        setTextAreaError(false)
    }, [selectItemDetails])


    // accepting the values form input and updating into the title and the right side list
    const handleInputChange = (e) => {
        const { value, name } = e.target;

        if (e.target.value.length <= 80) {
            handleEdit(value)
            setName(value)
            setSelected(value)
        }

        if (e.target.value.length > 80) {
            setNameError(true)
        } else {
            setNameError(false)
        }
    }

    const handleTextChange = (e) => {
        const { value } = e.target;

        if (e.target.value.length <= 900) {
            setTextArea(value)
        }

        if (e.target.value.length > 900) {
            setTextAreaError(true)
        } else {
            setTextAreaError(false)
        }
    }

    const handleFormSubmit = (e) => {
        const formValues = {
            "name": name,
            "text": textArea,
        }

        updateItemDetailsServices(formValues)
    }

    return (
        <div className='d-flex flex-column h-100'>
            <Form className='h-100 d-flex flex-column flex-grow-1 justify-content-between'>
                <div className='inside_scroll has_btns'>
                    <div className='d-flex align-items-center mb-3 flex-wrap gap-2 left_header'>
                        {selected && (
                            <h5 className="mb-0 text-wrap text-break main_title">{selected}</h5>
                        )}
                    </div>

                    <Row>
                        <Col lg="6">
                            <FormGroup className="form-group">
                                <Label for="name">
                                    Name
                                </Label>
                                <Input className='mb-1' name='name' onChange={(e) => handleInputChange(e)} value={name || ''} />

                                {(nameError && itemId) && (
                                    <div className='mt-1 text-muted text-small'>Max 80 characters are allowed</div>
                                )}

                                {!name && itemId && (
                                    <div className='mt-1 text-danger text-small'>Name is required</div>
                                )}
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg="6">
                            <FormGroup className="form-group">
                                <Label for="text">
                                    Text
                                </Label>

                                <Input className='mb-1' rows="6" type="textarea" name='text' onChange={(e) => handleTextChange(e)} value={textArea || ''} />

                                {(textAreaError && itemId) && (
                                    <div className='mt-1 text-muted text-small'>Max 900 characters are allowed</div>
                                )}

                                {!textArea && itemId && (
                                    <div className='mt-1 text-danger text-small'>Name is required</div>
                                )}
                            </FormGroup>
                        </Col>
                    </Row>
                </div>

                {/* Start: space for buttons at the bottom */}
                <div className='d-flex gap-2 gap-xl-3 main_buttons'>
                    <Button
                        color="primary"
                        size="md"
                        className='px-4'
                        disabled={!itemId && !name && !textArea || textArea === ""}
                        onClick={() => handleFormSubmit()}
                    >
                        Save Changes
                    </Button>

                    <Button
                        color="primary"
                        size="md"
                        outline
                        className='px-4'
                        onClick={() => handleFormReset()}
                        disabled={!itemId}
                    >
                        Reset
                    </Button>
                </div>
                {/* End: space for buttons at the bottom */}
            </Form>
        </div>
    )
}

export default ForewordsLeft