import React, { useEffect, useState } from 'react'
import {
    Row,
    Col,
    Button,
    Form,
    FormGroup,
    Label,
    Input
} from 'reactstrap';
import NumberInput from '../../../../../../components/NumberInput';
import { Checkbox, FormControl, FormControlLabel, Radio, RadioGroup, Select } from 'material-ui-core';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import CheckboxTree from 'react-checkbox-tree';
import PricingHeader from "../../../../../../components/Pricing/PricingHeader";
import { servicePath } from "../../../../../../utility/data";
import axios from 'axios';
import { useNavigate } from "react-router-dom";

const CustomNode = ({ node, onExpand, onCheck }) => (
    <div className="custom-node">
        <Input
            type="checkbox"
            checked={node.checked}
            onChange={() => onCheck(node)}
        />
        <span className="arrow-icon" onClick={() => onExpand(node)}>
            {node.expanded ? <MdKeyboardArrowDown /> : <MdKeyboardArrowUp />}
        </span>
        <label>
            {node.label} test
        </label>
    </div>
);

const Left = ({ accessToken, setMessage, setMessageType, setLoading }) => {

    const navigate = useNavigate()

    const [selectedPricing, setSelectedPricing] = useState();
    const [selectedSupplyOnly, setSelectedSupplyOnly] = useState('');

    // const [percentage, setPercentage] = useState(0);

    const [nodeData1, setNodeData1] = useState([]);
    const [nodeData11, setNodeData11] = useState([]);
    const [nodeData2, setNodeData2] = useState([]);
    const [nodeData3, setNodeData3] = useState([]);

    const [checked, setChecked] = useState([]);
    const [expanded, setExpanded] = useState(["Project Anillaries"]);

    const [checked11, setChecked11] = useState([]);
    const [expanded11, setExpanded11] = useState(["Frame Ancillaries"]);

    const [checked2, setChecked2] = useState([]);
    const [expanded2, setExpanded2] = useState(["Frame Collection"]);

    const [checked3, setChecked3] = useState([]);
    const [expanded3, setExpanded3] = useState(["Hardware Collection"]);

    const [markupData, setMarkupData] = useState({
        id: '',
        percentage: 0,
        type: 'Add',
        priceListType: 'Cost',
    });

    useEffect(() => {
        setLoading(true);
        getMarkUpData();

        getAllItemsList()
    }, []);

    const handleTypeChange = (value) => {
        setMarkupData((prev) => ({ ...prev, type: value }));
    };

    const handlePriceListChange = (value) => {
        setMarkupData((prev) => ({ ...prev, priceListType: value }));
    };

    const getMarkUpData = () => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${servicePath}/General/GetGlobalMarkup`,
            headers: {
                accept: 'text/plain',
                Authorization: `Bearer ${accessToken}`,
            },
        };
        axios
            .request(config)
            .then((res) => {
                if (res?.data?.statusCode === 200) {
                    setLoading(false);

                    console.log(res?.data?.entity, "get markup data")

                    if (res?.data?.entity !== null) {
                        setMarkupData(res?.data?.entity);
                    }

                } else if (res?.data?.statusCode === 404) {
                    setLoading(false);
                    navigate('/error/404');
                } else {
                    setLoading(false);
                    setMessage(
                        res?.data?.message ? res.data.message : 'Something went wrong'
                    );
                    setMessageType('error');
                }
            })
            .catch((error) => {
                setLoading(false);
                setMessage(
                    error.message ? error.message : 'Something went wrong'
                );
                setMessageType('error');

                if (error?.message === 'Network Error') {
                    navigate('/error/network');
                }
            });
    };

    const getAllItemsList = () => {

        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${servicePath}/General/GetGlobalProductList`,
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        };

        axios.request(config)
            .then((res) => {
                console.log(res?.data?.entity, "get all data");

                if (res?.data?.statusCode === 200) {

                    if (res?.data?.entity != null) {
                        if (res?.data?.entity[0] && res?.data?.entity[0]?.children?.length > 0) {
                            setNodeData1([res?.data?.entity[0]?.children[0]])
                        }

                        if (res?.data?.entity[0] && res?.data?.entity[0]?.children?.length > 1) {
                            setNodeData11([res?.data?.entity[0]?.children[1]])
                        }

                        if (res?.data?.entity[1]) {
                            setNodeData2([res?.data?.entity[1]])
                        }

                        if (res?.data?.entity[2]) {
                            setNodeData3([res?.data?.entity[2]])
                        }
                    }

                } else if (res?.data?.statusCode === 404) {
                    setLoading(false)
                    navigate('/error/404')
                    
                } else {
                    setLoading(false)
                    setMessage(res?.data?.message ? res.data.message : 'Something went wrong');
                    setMessageType('error')
                }

                getMarkUpChecked()
            })
            .catch((error) => {
                setLoading(false)
                setMessage(error.message ? error.message : 'Something went wrong');
                setMessageType('error')

                getMarkUpChecked()

                if (error?.message === "Network Error") {
                    navigate('/error/network')
                }
            });
    };

    const getMarkUpChecked = () => {

        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${servicePath}/General/GetGlobalCheckedProductList`,
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        };

        axios.request(config)
            .then((res) => {
                console.log(res?.data?.entity, "get checked ids");

                if (res?.data?.statusCode === 200) {
                    setLoading(false)

                    if (res?.data?.entity != null) {
                        if (res?.data?.entity?.ancillaries?.projectAncillary != null) {
                            setChecked(res?.data?.entity.ancillaries.projectAncillary)
                        } else {
                            setChecked([])
                        }

                        if (res?.data?.entity?.ancillaries?.frameAncillary  != null) {
                            setChecked11(res?.data?.entity.ancillaries.frameAncillary)
                        } else {
                            setChecked11([])
                        }

                        if (res?.data?.entity.frameProducts != null) {
                            setChecked2(res?.data?.entity.frameProducts)
                        } else {
                            setChecked2([])
                        }

                        if (res?.data?.entity.hardwareCollections != null) {
                            setChecked3(res?.data?.entity.hardwareCollections)
                        } else {
                            setChecked3([])
                        }
                    }

                } else if (res?.data?.statusCode === 404) {
                    setLoading(false)
                    navigate('/error/404')
                } else {
                    setLoading(false)
                    setMessage(res?.data?.message ? res.data.message : 'Something went wrong');
                    setMessageType('error')
                }
            })
            .catch((error) => {
                setLoading(false)
                setMessage(error.message ? error.message : 'Something went wrong');
                setMessageType('error')

                if (error?.message === "Network Error") {
                    navigate('/error/network')
                }
            });
    };

    const updateTableData = (btnType) => {
        const FormData = require('form-data');
        let rdata = new FormData();

        rdata.append("Id", markupData.id || '');
        rdata.append("Percentage", markupData.percentage || 0);
        rdata.append("PriceListType", markupData.priceListType || "Cost");
        rdata.append("Type", markupData.type || "Add");
        rdata.append("UserId", markupData.userId || '');

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${servicePath}/General/AddUpdateGlobalMarkup`,
            headers: {
                'accept': 'text/plain',
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'multipart/form-data'
            },
            data: rdata
        };

        axios.request(config)
            .then((res) => {
                if (res?.data?.statusCode === 200) {

                    if(btnType !== "applyMarkUp") {
                        setMessage(res.data.message);
                        setMessageType('success');
                    }

                    getMarkUpData();
                }
            })
            .catch((error) => {

                setLoading(false)
                setMessage(error.message ? error.message : 'Something went wrong');
                setMessageType('error')

                if (error?.message === "Network Error") {
                    navigate('/error/network')
                }
            });
    }

    const applyGlobalMarkup = (data) => {
        setLoading(true);

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${servicePath}/General/ApplyGlobalMarkup`,
            headers: {
                'accept': 'text/plain',
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios.request(config)
            .then((res) => {

                if (res?.data?.statusCode === 200) {

                    setMessage(res?.data?.message ? res.data.message : 'Mark up applied');

                    setMessageType('success');

                    setLoading(false);

                } else if (res?.data?.statusCode === 404) {
                    setLoading(false);
                    navigate('/error/404');

                } else {
                    setLoading(false);
                    setMessage(
                        res?.data?.message ? res.data.message : 'Something went wrong'
                    );
                    setMessageType('error');
                }

                getMarkUpChecked()
            })
            .catch((error) => {
                setLoading(false)
                setMessage(error.message ? error.message : 'Something went wrong');
                setMessageType('error')

                if (error?.message === "Network Error") {
                    navigate('/error/network')
                }

                getMarkUpChecked()
            });
    }

    const handleUpdate = () => {
        setLoading(true)
        updateTableData()
    }

    const handleSaveChanges = () => {

        var projectAncillaryId = []
        var frameAncillaryId = []
        var frameProductsId = []
        var hardwareCollectionId = []

        if (checked && checked.length > 0) {
            for (let i = 0; i < checked.length; i++) {
                projectAncillaryId.push({
                    id: checked[i]
                })
            }
        }

        if (checked11 && checked11.length > 0) {
            for (let i = 0; i < checked11.length; i++) {
                frameAncillaryId.push({
                    id: checked11[i]
                })
            }
        }

        if (checked2 && checked2.length > 0) {
            for (let i = 0; i < checked2.length; i++) {
                frameProductsId.push({
                    id: checked2[i]
                })
            }
        }

        if (checked3 && checked3.length > 0) {
            for (let i = 0; i < checked3.length; i++) {
                hardwareCollectionId.push({
                    id: checked3[i]
                })
            }
        }

        const node1Data = {
            "ancillaries": {
                "projectAncillary": projectAncillaryId,
                "frameAncillary": frameAncillaryId
            },
            "frameProducts": frameProductsId,
            "hardwareCollections": hardwareCollectionId,
            "userId": null
        }

        updateTableData("applyMarkUp")
        applyGlobalMarkup(node1Data)

        // console.log(node1Data, checked2, checked3, "checked items")
    }

    const handleFormReset = () => {

        getMarkUpData()
        getAllItemsList()

        getMarkUpChecked()
    }

    console.log(nodeData1, nodeData11, "nodeData1")

    return (
        <div className='d-flex flex-column h-100'>
            <PricingHeader
                setSelectedSupplyOnly={setSelectedSupplyOnly}
                setSelectedPricing={setSelectedPricing}
                selectedPricing={selectedPricing}
                selectedSupplyOnly={selectedSupplyOnly}
                costPrice={''}
            />
            {/* <h5 className="mb-0 text-wrap text-break me-4 main_title mb-3">Hidden Discount</h5> */}
            <Form className='h-100 d-flex flex-column flex-grow-1 justify-content-between'>
                <div className='inside_scroll has_btns'>
                    <Row>
                        <Col md="6">
                            <FormGroup className="form-group">
                                <Label for="percent">Percentage</Label>
                                <NumberInput
                                    name="percent"
                                    value={markupData?.percentage || 0}

                                    setValue={(value) => {
                                        setMarkupData((prev) => ({ ...prev, percentage: value }));
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col lg="6">
                            <FormControl className="mb-3 w-100">
                                <Label></Label>
                                <RadioGroup
                                    aria-labelledby="customerInfoSelection-label"
                                    name="customerInfoSelection"
                                    className='mt-2'
                                    value={markupData?.type || ''}
                                    onChange={(e) => handleTypeChange(e.target.value)}
                                >
                                    <FormControlLabel value="Add" control={<Radio />} label="Add" />
                                    <FormControlLabel className='' value="replace" control={<Radio />} label="Replace" />
                                </RadioGroup>
                            </FormControl>
                        </Col>
                        <Col lg="12">
                            <FormControl className="mb-3 w-100">
                                <Label>Price List</Label>
                                <RadioGroup
                                    aria-labelledby="customerInfoSelection-label"
                                    name="customerInfoSelection"
                                    className='mt-2'
                                    value={markupData?.priceListType || ''}
                                    onChange={(e) => handlePriceListChange(e.target.value)}
                                >
                                    <FormControlLabel value="Cost" control={<Radio />} label="Cost" />
                                    <FormControlLabel className='' value="Mark-Up" control={<Radio />} label="Mark-Up" />
                                </RadioGroup>
                            </FormControl>

                            <div className='max-w-200 mb-4'>
                                <Button
                                    color="primary"
                                    outline
                                    size="small"
                                    className='w-100'
                                    onClick={() => handleUpdate()}
                                // disabled={loading}
                                >
                                    Save Changes
                                </Button>
                            </div>
                        </Col>
                    </Row>

                    <Row className='d-flex justify-content-between nested_checkbox mb-4'>
                        <Col sm="6" lg="4" className="mb-4">

                            <h5>Anillaries</h5>
                            
                            {(nodeData1.length > 0 && nodeData11.length > 0) ? '' : <p className='mb-0'>No anillaries</p>}

                            <CheckboxTree
                                nodes={nodeData1}
                                checked={checked}
                                expanded={expanded}
                                onCheck={(newChecked) => setChecked(newChecked)}
                                onExpand={(newExpanded) => setExpanded(newExpanded)}
                                nodeContentRenderer={CustomNode}
                                icons={{
                                    expandClose: <MdKeyboardArrowDown size={20} />,
                                    expandOpen: <MdKeyboardArrowUp size={20} />,
                                }}
                            />

                            <CheckboxTree
                                nodes={nodeData11}
                                checked={checked11}
                                expanded={expanded11}
                                onCheck={(newChecked11) => setChecked11(newChecked11)}
                                onExpand={(newExpanded11) => setExpanded11(newExpanded11)}
                                nodeContentRenderer={CustomNode}
                                icons={{
                                    expandClose: <MdKeyboardArrowDown size={20} />,
                                    expandOpen: <MdKeyboardArrowUp size={20} />,
                                }}
                            />
                        </Col>

                        <Col sm="6" lg="4" className="mb-4">
                            <CheckboxTree
                                nodes={nodeData2}
                                checked={checked2}
                                expanded={expanded2}
                                onCheck={(newChecked2) => setChecked2(newChecked2)}
                                onExpand={(newExpanded2) => setExpanded2(newExpanded2)}
                                icons={{
                                    expandClose: <MdKeyboardArrowDown size={20} className="custom-icon" />,
                                    expandOpen: <MdKeyboardArrowUp size={20} className="custom-icon" />,
                                }}
                            />
                        </Col>

                        <Col sm="6" lg="4" className="mb-4">
                            <CheckboxTree
                                nodes={nodeData3}
                                checked={checked3}
                                expanded={expanded3}
                                onCheck={(newChecked3) => setChecked3(newChecked3)}
                                onExpand={(newExpanded3) => setExpanded3(newExpanded3)}
                                icons={{
                                    expandClose: <MdKeyboardArrowDown size={20} className="custom-icon" />,
                                    expandOpen: <MdKeyboardArrowUp size={20} className="custom-icon" />,
                                }}
                            />
                        </Col>
                    </Row>
                </div>

                <div className='d-flex gap-2 gap-xl-3 main_buttons'>
                    <Button
                        color="primary"
                        size="md"
                        className='px-4'
                        onClick={handleSaveChanges}
                    >
                        Apply mark up
                    </Button>

                    <Button
                        color="primary"
                        size="md"
                        outline
                        className='px-4'
                        onClick={handleFormReset}
                    >
                        Reset
                    </Button>
                </div>
            </Form>
        </div>
    )
}

export default Left