import React, { useEffect } from 'react'
import { useState } from 'react';
import Slider from 'react-rangeslider';
import { PhotoshopPicker } from 'react-color';
import {
    Row,
    Col,
    Button,
    Label,
    FormGroup,
    Input,
} from 'reactstrap';
import { useProductPaletteContext } from '../../context/productPaletteContext';
import { useRightContext } from "../../context/rightMenuContext"
import { Form } from 'react-bootstrap';
import { addUpdateGlazingColours, uniqueGlazingColours } from '../../services/glazingServices';
import { useMessage } from '../../context/messageContext';
import { useNavigate } from 'react-router-dom';
import { convertCanvasToImageFile, denormalizeValue, normalizeValue } from '../../utility/helper';

const SpacerbarColour = ({ tabType, allColours, setLoading, setCommonHeading, getAllColoursServices }) => {

    const accessToken = localStorage.getItem('access_token');

    const [roughness, setRoughness] = useState(0)
    const [metalness, setMetalness] = useState(0)
    const [name, setName] = useState('')
    const [desc, setDesc] = useState('')
    const [id, setId] = useState()
    const [defaultColor, setDefaultColor] = useState({
        r: '241',
        g: '112',
        b: '19',
        hex: 'F17013'
    },)

    const { handleEdit, setTimestamp, timestamp, activeIndex, handleDescEdit, setActiveIndex, newItemList, handleModificationChange } = useProductPaletteContext();
    const { setSelectedRightItem, selectedRightItem } = useRightContext()
    const { setMessage, setMessageType } = useMessage()

    const navigate = useNavigate()

    const firstEl = allColours[0]?.id;

    let minSliderVal = 0;
    let maxSliderVal = 100;

    useEffect(() => {
        if (firstEl && tabType === 'colours') {
            if (allColours?.length && activeIndex !== 0 && tabType === 'colours') {
                setTimestamp(timestamp)
            } else {
                setTimestamp(firstEl)
            }
        }
    }, [allColours, timestamp, tabType])



    useEffect(() => {
        if (timestamp && tabType === "colours" && newItemList?.length > 0) {
            getUniqueColourServices()
        }
    }, [timestamp, tabType])

    useEffect(() => {
        setActiveIndex(0)
    }, [tabType])

    useEffect(() => {
        if (newItemList?.length === 0) {
            setTimestamp('')
            setName(' ')
            setDesc(' ')
        } else {
            setName(name)
        }
    }, [])


    const reset = () => {
        getUniqueColourServices()
        getAllColoursServices()
    }



    const getUniqueColourServices = async () => {
        setLoading(true)
        const res = await uniqueGlazingColours(timestamp, accessToken)
        if (res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                const selectedRight = allColours?.find((p) => p.id == timestamp)
                setSelectedRightItem(selectedRight)
                setName(res?.data.entity?.name)
                setCommonHeading(res?.data.entity?.name)
                setDesc(res?.data.entity?.description ? res?.data.entity?.description : "")
                setRoughness(denormalizeValue(res?.data.entity?.roughness, minSliderVal, maxSliderVal))
                setMetalness(denormalizeValue(res?.data.entity?.metalness, minSliderVal, maxSliderVal))
                setDefaultColor({ r: res?.data?.entity?.red, g: res?.data?.entity?.green, b: res?.data?.entity?.blue, hex: res?.data.entity?.hexValue })
                setId(res?.data?.entity?.id)
                setLoading(false)
            } else {
                setLoading(false)
                setMessage(res?.data?.message ? res.data.message : 'Something went wrong');
                setMessageType('error')
            }
        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')
            if (res?.message === "Network Error") {
                navigate('/error/network')
                setLoading(false)
            }

        }
    }

    // const handleRoughnessChange = (value) => {
    //     setRoughness(value)
    // }


    // const handelMetalness = (value) => {
    //     setMetalness(value)
    // }

    const handleRoughnessChange = (value) => {
        setRoughness(value)
        handleModificationChange('roughness', normalizeValue(value, minSliderVal, maxSliderVal));
    }

    const handelMetalness = (value) => {
        setMetalness(value)
        handleModificationChange('metalness', normalizeValue(value, minSliderVal, maxSliderVal))
    }

    const updateGlazingColoursServices = async () => {
        const capturedFile = convertCanvasToImageFile();
        setLoading(true)

        const data = {
            id: timestamp,
            name: name,
            desc: desc,
            hex: defaultColor?.hex,
            red: defaultColor?.r,
            green: defaultColor?.g,
            blue: defaultColor?.b,
            roughness: normalizeValue(roughness, minSliderVal, maxSliderVal),
            metalness: normalizeValue(metalness, minSliderVal, maxSliderVal),
            transparency: 0,
            typeId: 2,
            CapturedFile: capturedFile,

        }

        const res = await addUpdateGlazingColours(data, accessToken)
        if (res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                setMessageType('success')
                setMessage(res?.data?.message ? res.data.message : 'Added new colour')
                getAllColoursServices()
                getUniqueColourServices(timestamp)
                setLoading(false)
            } else {
                setMessage(res?.data?.message ? res.data.message : 'Something went wrong')
                setMessageType('error')
                getAllColoursServices()
                getUniqueColourServices(timestamp)
                setLoading(false)

            }
        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')
            getAllColoursServices()
            if (res?.message === "Network Error") {
                navigate('/error/network')
            }
        }
    }

    // const handleColorPicker = (value) => {
    //     let rgb = value?.rgb
    //     setDefaultColor({ r: rgb?.r, g: rgb?.g, b: rgb?.b, hex: value?.hex })
    // }

    const handleColorPicker = (value) => {
        let rgb = value?.rgb
        setDefaultColor({ r: rgb?.r, g: rgb?.g, b: rgb?.b, hex: value?.hex })
        handleModificationChange('hex', value?.hex)
    }

    const handleNameChange = (e) => {
        const value = e.target.value;
        if (e.target.value.length <= 50) {
            setName(value)
            handleEdit(value)
            setCommonHeading(value)
        }
    }

    const handleDescChange = (e) => {
        const value = e.target.value
        if (e.target.value.length <= 80) {
            setDesc(value)
            handleDescEdit(value)
        }
    }



    return (
        <Form className='h-100 d-flex flex-column flex-grow-1 justify-content-between'>
            <div className='inside_scroll has_btns has_head'>
                <Row>
                    <Col lg="6">
                        <FormGroup className="form-group">
                            <Label for="name">
                                Name
                            </Label>
                            <Input disabled={!selectedRightItem?.isSelf} className='mb-1' name='name' value={name} onChange={(e) => handleNameChange(e)} />
                            {name && name.length > 49 && (
                                <div className='mt-1 text-muted text-small'>Max 50 characters are allowed</div>
                            )}

                            {(!name && id) && (
                                <div className='mt-1 text-danger text-small'>Name is required</div>
                            )}
                        </FormGroup>
                    </Col>
                    <Col lg="6" >
                        <FormGroup className="form-group">
                            <Label for="description">
                                Description
                            </Label>
                            <Input disabled={!selectedRightItem?.isSelf} className='mb-1' name='description' value={desc} onChange={(e) => handleDescChange(e)} />
                            {desc && desc.length > 79 && (
                                <div className='mt-1 text-muted text-small'>Max 80 characters are allowed</div>
                            )}
                        </FormGroup>
                    </Col>
                    <Col lg="12">
                        <FormGroup className={`${!selectedRightItem?.isSelf ? "not_allowed" : ""}`}>
                            <div className='palette-color-picker'>
                                <PhotoshopPicker onChange={handleColorPicker} disableAlpha={false} color={defaultColor} key={defaultColor} />
                            </div>
                        </FormGroup>
                    </Col>

                    <Col lg="6">
                        <FormGroup className={`${(!selectedRightItem?.isSelf) && "opacity-75 pointer_none"}`}>
                            <Label>Roughness</Label>
                            <div className='d-flex align-items-center'>
                                <Slider
                                    min={0}
                                    max={100}
                                    value={roughness}
                                    className='w-75'
                                    onChange={handleRoughnessChange}
                                />
                                <span className='ms-3'>{roughness}</span>
                            </div>
                        </FormGroup>
                    </Col>

                    <Col lg="6">
                        <FormGroup className={`${(!selectedRightItem?.isSelf) && "opacity-75 pointer_none"}`}>
                            <Label>Metalness</Label>
                            <div className='d-flex align-items-center'>
                                <Slider
                                    min={0}
                                    max={100}
                                    value={metalness}
                                    className='w-75'
                                    onChange={handelMetalness}
                                />
                                <span className='ms-3'>{metalness}</span>
                            </div>
                        </FormGroup>
                    </Col>
                </Row>
            </div>
            {selectedRightItem?.isSelf && <div className='d-flex gap-2 gap-xl-3 main_buttons'>
                <Button
                    color="primary"
                    size="md"
                    className='px-4'
                    onClick={() => updateGlazingColoursServices()}
                    disabled={allColours?.length === 0 || desc?.length > 79 || !name || name?.length > 49}
                >
                    Save Changes
                </Button>

                <Button
                    color="primary"
                    size="md"
                    outline
                    className='px-4'
                    onClick={() => reset()}
                    disabled={allColours?.length === 0 || desc?.length > 79 || !name || name?.length > 49}
                >
                    Reset
                </Button>
            </div>}
        </Form>
    )
}

export default SpacerbarColour