import axios from "axios";
import { servicePath } from "../../utility/data";

export const getFrameAncillariesData = (
  accessToken,
  selectedPricing,
  selectedSupplyOnly
) => {
  const data = new FormData();
  data.append("PriceMasterId", selectedPricing);
  data.append("MarkupMasterId", selectedSupplyOnly);

  let config = {
    method: "POST",
    maxBodyLength: Infinity,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "multipart/form-data",
    },
  };

  return axios
    .post(`${servicePath}/General/GetFrameAncillariesPricing`, data, config)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error;
    });
};
