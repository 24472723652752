import React, { useEffect, useRef, useState } from 'react';
import { Alert, Button, FormGroup, Input, Label, PopoverBody, UncontrolledPopover } from 'reactstrap';
import { useDesigner } from '../../context/designerContext';
import NumberInput from '../NumberInput';
import { BiLockOpenAlt, BiSolidCheckSquare, BiX } from 'react-icons/bi';
import { FaGripLines } from 'react-icons/fa';
import { handleMultiSelect } from '../../utility/threeJsHelpers/general';
import { MdInfoOutline } from 'react-icons/md';

function EditSashSizeModel({ uiLinesRef, savedData, type }) {

  const { lockRefPoints, setLockRefPoints, lockRefIndex, setLockRefIndex, setApplyPartialObj, setApplyPartialTrigger } = useDesigner()

  const [popoverIndex, setPopoverIndex] = useState(null)
  const [modelWidth, setModelWidth] = useState(0)
  const [modelHeight, setModelHeight] = useState(0)
  const [validLength, setValidLength] = useState(0)

  const dimensionRef = useRef(null)

  // console.log(savedData, "savedData")

  useEffect(() => {
    if (dimensionRef?.current && uiLinesRef?.current && uiLinesRef?.current?.length > 0) {
      dimensionRef?.current?.focus()
      dimensionRef?.current?.select()
    }
  }, [popoverIndex])

  const handleApply = (e) => {
    // e.preventDefault();
    setPopoverIndex(!popoverIndex);
    setApplyPartialTrigger(true)
  }

  const handleInputW = (value) => {
    if (value < 0) {
      return
    }
    // e.preventDefault();
    setModelWidth(value)

    var data = {
      'index': popoverIndex,
      'width': value,
      'height': '',
      'type': 'H'
    }

    setApplyPartialObj(data)
  }

  const handleInputH = (value) => {
    if (value < 0) {
      return
    }
    // e.preventDefault();
    setModelHeight(value)

    var data = {
      'index': popoverIndex,
      'width': '',
      'height': value,
      'type': 'V'
    }

    setApplyPartialObj(data)
  }

  const handleLockIndex = (index) => {
    setLockRefIndex((prevData) => {
      const indexExists = prevData.some(item => item === index);
      if (indexExists) {
        const newData = prevData.filter(item => item !== index);
        // console.log(newData, "newData")
        return newData;
      } else {
        const newData = [
          ...prevData,
          index
        ];

        // console.log(newData, "newData")
        return newData;
      }
    });
  }

  const handleRefClick = (item, index) => {

    // console.log(lockRefIndex, savedData.sashSize, "test val")

    if (savedData.sashSize && savedData.sashSize.length > 0) {
      let lockedLength = lockRefIndex && lockRefIndex.length > 0 ? lockRefIndex?.reduce((sum, index) => sum + savedData.sashSize[index], 0) : 0;

      if (type === 'V') {
        let validValue = (savedData?.layoutFrame?.heigth - lockedLength - ((savedData.sashSize.length - lockRefIndex.length - 1) * 100))
        setValidLength(validValue)
      } else {
        let validValue = (savedData?.layoutFrame?.width - lockedLength - ((savedData.sashSize.length - lockRefIndex.length - 1) * 100))
        setValidLength(validValue)
      }
    }


    if (savedData?.sashSize && savedData.sashSize.length > 0) {
      setModelWidth(parseInt(savedData?.sashSize[index]))
    } else {
      setModelWidth(parseInt((parseFloat(savedData?.layoutFrame?.width) / (parseInt(savedData?.numberOfSash?.number) ? parseInt(savedData?.numberOfSash?.number) : 1))))
    }
  }

  const handleApplyOnEnter = (e) => {
    if (
      (e?.key === "Enter" &&
        ((type === "V" && modelHeight > 1 && modelHeight <= validLength) ||
          (type === "H" && modelWidth > 1 && modelWidth <= validLength)))
    ) {
      handleApply(e);
    }
  };

  // console.log(uiLinesRef, 'uiLinesRef')

  return (
    <>
      {uiLinesRef?.current && uiLinesRef?.current?.length > 0 && uiLinesRef?.current?.map((item, index) => {
        const selectedIndex = lockRefPoints.find((obj) => obj.index === index)
        return (
          <div id={`overlayLines${index}`} key={index} className="ref_btn_wrap ui_ref">
            <div
              // className={`btn_parent ${(item.name.includes('FrameLeft') || item.name.includes('GlassPanel')) ? 'is_left' : ''}`}
              className={`btn_parent ${type === 'V' ? 'is_right' : 'is_bottom'}`}
              style={{
                position: 'absolute',
                left: item.overlayX,
                top: item.overlayY,
              }}
            >
              <div className='potition-relative'>
                {uiLinesRef?.current && uiLinesRef?.current?.length > 2 && (!item.name.includes('FrameLeft')) && (
                  <Button
                    type="button"
                    color={`${(selectedIndex?.index === index && selectedIndex?.data?.name === item.name) ? 'outline-danger' : 'light'}`}
                    className={`rounded position-absolute ref_btn lock_btn ${type === 'V' ? 'is_lef' : ''}`}
                    onClick={() => { handleMultiSelect(index, item, setLockRefPoints); handleLockIndex(index) }}
                  >
                    <BiLockOpenAlt size={12} />
                  </Button>
                )}

                <Button
                  type="button"
                  color='light'
                  className={`rounded ref_btn position-relative type_2 ${type === 'V' ? 'is_lef' : ''}`}
                  id={`linesButtons${index}`}
                  onClick={() => { setApplyPartialTrigger(false); handleRefClick(item, index) }}
                >
                  <span>
                    {(type === 'V') && (parseInt(savedData?.layoutFrame?.height))}

                    {/* {type === 'H' && parseInt((parseFloat(data?.layoutFrame.width) / (parseInt(data?.numberOfSash?.number) ? parseInt(data?.numberOfSash?.number) : 1)))} */}

                    {/* {type === "H" && (
                      parseInt((parseInt((parseFloat(savedData?.layoutFrame?.width) / (parseFloat(savedData?.numberOfSash?.number) ? parseInt(savedData?.numberOfSash?.number) : 1))) * (savedData?.xScale?.length > 0 ? parseFloat(savedData?.xScale[index]) : 1)))
                    )} */}

                    {type === "H" && (
                      <>
                        {savedData?.sashSize && savedData.sashSize.length > 0 ? (
                          parseInt(savedData?.sashSize[index])
                        ) : (
                          parseInt((parseFloat(savedData?.layoutFrame?.width) / (parseInt(savedData?.numberOfSash?.number) ? parseInt(savedData?.numberOfSash?.number) : 1)))
                        )}
                      </>
                    )}
                  </span>
                </Button>

                <UncontrolledPopover
                  placement="bottom"
                  target={`linesButtons${index}`}
                  trigger="legacy"
                  isOpen={popoverIndex === index}
                  toggle={() => {
                    setPopoverIndex(popoverIndex === index ? null : index);
                  }}
                  className='custom_shadow'
                >
                  <PopoverBody className='p-0 position-relative'>

                    {(!lockRefIndex.includes(index) && (lockRefIndex?.length !== (uiLinesRef?.current?.length - 1)) && (lockRefIndex?.length !== uiLinesRef?.current?.length)) ? (
                      <div className='p-2'>
                        <Button
                          color="primary"
                          className='circle_cross box_right_top rounded'
                          onClick={() => {
                            setPopoverIndex(!popoverIndex);
                          }}
                        >
                          <BiX size={20} />
                        </Button>

                        <React.Fragment >
                          <FormGroup className='mb-0'>
                            {type === 'H' ? (
                              <Label for="width" className="text-dark fs-6 fw-bold">
                                Width
                              </Label>
                            ) : (
                              <Label for="height" className="text-dark fs-6 fw-bold">
                                Height
                              </Label>
                            )}

                            <div className='d-flex align-items-center gap-2'>
                              <div className="w-50">
                                {type === 'H' ? (
                                  // <NumberInput
                                  //   name="width"
                                  //   value={modelWidth}
                                  //   setValue={handleInputW}
                                  //   className={modelWidth ? 'border-primary' : "border-danger"}
                                  // />
                                  <Input onKeyDown={(e) => {
                                    handleApplyOnEnter(e)
                                  }} id='partialWidth' innerRef={dimensionRef} type='number' name='width' value={modelWidth} onChange={(e) => handleInputW(e.target.value)} className={modelWidth ? 'border-primary' : "border-danger"}></Input>
                                ) : (
                                  // <NumberInput
                                  //   name="height"
                                  //   value={modelHeight}
                                  //   setValue={handleInputH}
                                  //   className={modelHeight ? 'border-primary' : "border-danger"}
                                  // />
                                  <Input onKeyDown={(e) => {
                                    handleApplyOnEnter(e)
                                  }} id='partialHeight' innerRef={dimensionRef} type='number' name='height' value={modelHeight} onChange={(e) => handleInputH(e.target.value)} className={modelHeight ? 'border-primary' : "border-danger"}></Input>
                                )}
                              </div>

                              <Button
                                outline
                                color="primary"
                                size="md"
                                className='px-3 mb-1 w-50'
                                onClick={(e) => { handleApply(e) }}
                                disabled={(type === 'H' && (modelWidth < 1)) || (type !== 'H' && modelHeight < 1) || ((type === "V" && modelHeight > validLength) || (type === "H" && modelWidth > validLength))}
                              >
                                <BiSolidCheckSquare size={18} className="me-2" />
                                Apply
                              </Button>
                            </div>

                            {type === 'H' && (modelWidth > validLength) && validLength > 0 && (
                              <p className='text-danger mb-0'>Max value should be {validLength}</p>
                            )}

                            {type !== 'H' && (modelHeight > validLength) && validLength > 0 && (
                              <p className='text-danger mb-0'>Max value should be {validLength}</p>
                            )}

                            {((type === 'H' && modelWidth < 1) || (type !== 'H' && modelHeight < 1)) && (
                              <p className='text-danger mb-0'>Min value should be 1</p>
                            )}
                          </FormGroup>

                          <div className='d-none gap-2'>
                            <Button
                              color="primary"
                              size="md"
                              className='px-3 w-50'
                            >
                              <FaGripLines size={18} className="me-2" />
                              Frame
                            </Button>

                            <Button
                              color="primary"
                              size="md"
                              className='px-3 w-50'
                            >
                              <FaGripLines size={18} className="me-2" />
                              Glass
                            </Button>
                          </div>
                        </React.Fragment>
                      </div>
                    ) : (

                      <div className='p-2'>
                        <h4 className='text-bark mb-2'>Oops!</h4>

                        <Alert
                          color={`${lockRefIndex.includes(index) ? 'warning' : 'warning'}`}
                          className="p-2 m-0"
                        >
                          <div className='d-flex gap-2'>
                            <span>
                              <MdInfoOutline size={18} />
                            </span>

                            {lockRefIndex.includes(index) ? (
                              <span>
                                This glazing is locked, you can't change dimention of locked items
                              </span>
                            ) : (
                              <span>
                                You'll need at least one unlocked item to change dimentions
                              </span>
                            )}
                          </div>
                        </Alert>
                      </div>
                    )}
                  </PopoverBody>
                </UncontrolledPopover>
              </div>
            </div>
          </div>
        )
      })}
    </>
  );
};
export default EditSashSizeModel;
