import React, { useState } from 'react'
import InnerLayout from "../../../../../../layouts/internalLayout"
import Wip from '../../../errors/wip'
import StylesLeft from './left'
import StylesRight from './right'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect } from 'react'
import { Oval } from 'react-loader-spinner'
import { useMessage } from '../../../../../../context/messageContext'
import { useRightContext } from '../../../../../../context/rightMenuContext'
import { noSelectOptions, productHardwareStyleMenu } from '../../../../../../utility/data'
import { getHardwareModelService } from '../../../../../../services/commonServices'
import { getAllColoursCollection } from '../../../../../../services/productServices'
import { addNewRightItem, addUpdateAccessories, getAllSupplier, getItemDetails, getRightMenuList, updateItemDetails } from '../../../../../../services/accessoriesServices'

const Accessories = () => {

    const accessToken = localStorage.getItem('access_token')

    const [modelOptions, setModalOptions] = useState([]);
    const [colourCollectionOptions, setColourCollectionOptions] = useState([]);

    const [loading, setLoading] = useState(false)
    const [selectItemDetails, setSelectItemDetails] = useState([])
    const [allRightListItems, setAllRightListItems] = useState([])
    const [allRightListItemsOrder, setAllRightListItemsOrder] = useState(true)
    const [allSupplierList, setAllSupplierList] = useState('')

    const { setMessage, setMessageType } = useMessage()

    const { timestamp, fetchSelected, setTimestamp, activeIndex, setActiveIndex, setSelectedRightItem } = useRightContext()

    const navigate = useNavigate();

    var getFirstElId = allRightListItems[0]?.id || ''

    var itemAddedTrue = ''

    const [wip, setWIP] = useState(false)
    const [pageName, setPageName] = useState('')
    const [styleMasterId, setStyleMasterId] = useState(1)


    useEffect(() => {
        getRightMenuListData()
        getAllSupplierServices()
    }, [])


    useEffect(() => {
        if (allRightListItems?.length > 0 && timestamp) {
            getRightItemDetails(timestamp)
        }
    }, [timestamp])

    useEffect(() => {
        if (allRightListItemsOrder) {
            getFirstElId = allRightListItems[0]?.id

            setActiveIndex(0)

            if (getFirstElId && allRightListItems?.length) {
                getRightItemDetails(getFirstElId)
            }
        } else {
            if (getFirstElId && allRightListItems?.length) {
                if (timestamp && allRightListItems?.length && activeIndex !== 0) {
                    getRightItemDetails(timestamp)
                } else {
                    getRightItemDetails(getFirstElId)
                }
            }
        }
    }, [allRightListItems])

    // get Profile type from master API
    // const getModalSelectOptions = async (fetchMasterId) => {
    //     const res = await getHardwareModelService(accessToken, fetchMasterId);

    //     if (res && res && res.status === 200) {
    //         if (res?.data?.statusCode === 200) {
    //             if (res?.data?.entity !== null) {
    //                 if (res?.data?.entity?.length > 0) {
    //                     setModalOptions(res?.data?.entity)
    //                 }
    //             }
    //         }
    //     }
    // }

    // get color collection from hardware collection API
    // const getColorCollectionSelectOptions = async () => {
    //     const res = await getAllColoursCollection("2", accessToken)
    //     if (res && res && res.status === 200) {
    //         if (res?.data?.statusCode === 200) {
    //             if (res?.data?.entity !== null) {
    //                 if (res?.data?.entity?.length > 0) {
    //                     setColourCollectionOptions(res?.data?.entity)
    //                 } else {
    //                     setColourCollectionOptions(noSelectOptions)
    //                 }
    //             }
    //         }
    //     }
    // }

    // get all right menu item list based on page/type (type = 1 for forewords)
    const getRightMenuListData = async () => {
        const res = await getRightMenuList(accessToken);
        // console.log(res, "108");
        if (res && res && res.status === 200) {

            if (res?.data?.statusCode === 200) {

                if (res?.data?.entity !== null) {

                    if (res?.data?.entity?.length > 0) {
                        setAllRightListItems(res?.data?.entity)

                        if (itemAddedTrue == 'added') {
                            const addedItemIndex = res?.data?.entity.length - 1

                            if (addedItemIndex) {
                                fetchSelected(addedItemIndex, res?.data?.entity[addedItemIndex]?.id)
                                itemAddedTrue = ''
                            }
                        }
                    } else {
                        setAllRightListItems([])

                        setAllRightListItems([])
                        setTimestamp('')

                        fetchSelected(0, '');

                        setSelectItemDetails([])
                    }
                }

                setTimeout(() => {
                    setLoading(false)
                }, 1200);
            } else {
                setLoading(false)
                setMessage(res?.data.message ? res.data.message : 'Something went wrong');
                setMessageType('error')
            }

        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')

            if (res?.message === "Network Error") {
                navigate('/error/network')
            }
        }
    }

    // get details of selected item in right menu by passing item id
    const getRightItemDetails = async (id) => {
        const res = await getItemDetails(accessToken, id);

        setAllRightListItemsOrder(false)
        // console.log(res, "163");

        if (res) {

            if (res?.statusCode == 200) {
                if (res?.entity !== null) {

                    // set state to get details of current selected item which will display data in left side component
                    setSelectItemDetails(res.entity)

                    const selectedRight = allRightListItems?.find((p) => p.id == id)
                    setSelectedRightItem(selectedRight)

                    // set selected item id which will update context
                    setTimestamp(id)
                }

                setTimeout(() => {
                    setLoading(false)
                }, 1200);
            } else {
                setLoading(false)
                setMessage(res?.message ? res.message : 'Something went wrong');
                setMessageType('error')
            }

        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')

            if (res?.message === "Network Error") {
                navigate('/error/network')
            }
        }
    }

    // api call to update all selected item details
    const updateItemDetailsServices = async (data) => {
        setLoading(true)

        const res = await updateItemDetails(accessToken, timestamp ? timestamp : getFirstElId, data)

        if (res && res && res.status === 200) {
            // console.log(res, 'selected item info')

            if (res?.data?.statusCode === 200) {
                setLoading(false)
                setMessage(res?.data?.message ? res.data.message : 'Updated details');
                setMessageType('success')
                getRightMenuListData()
                getRightItemDetails(timestamp)

                setLoading(false)
            } else {
                getRightMenuListData()
                setLoading(false)
                setMessage(res?.data?.message ? res.data.message : 'Something went wrong');
                setMessageType('error')
            }
        } else {
            getRightMenuListData()
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')

            if (res?.message === "Network Error") {
                navigate('/error/network')
            }
        }
    }

    // api call to added new right item
    const addNewRightItemServices = async (data) => {
        setLoading(true)
        itemAddedTrue = "added"

        const res = await addNewRightItem(accessToken, data)

        if (res && res && res.status === 200) {

            if (res?.data?.statusCode === 200) {
                setMessage(res?.data?.message ? res.data.message : 'Record created successfully!');
                setMessageType('success')

                getRightMenuListData()
            } else {
                setLoading(false)
                setMessage(res?.data?.message ? res.data.message : 'Something went wrong');
                setMessageType('error')
            }
        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')

            if (res?.message === "Network Error") {
                navigate('/error/network')
            }
        }
    }

    const handleFormReset = () => {
        if (timestamp) {
            getRightMenuListData()
            getRightItemDetails(timestamp)
        }
    }

    const getAllSupplierServices = async () => {
        setLoading(true)

        const res = await getAllSupplier(accessToken)

        if (res?.statusCode === 200) {
            if (res?.entity !== null) {
                if (res?.entity?.length > 0) {
                    setAllSupplierList(res?.entity)

                } else {
                    setAllSupplierList([])
                }
            }

            setTimeout(() => {
                setLoading(false)
            }, 1200);

        } else if (res?.statusCode === 404) {
            setLoading(false)
            navigate('/error/404')
        } else {
            setMessage(res.message)
            setMessageType('error')
            setLoading(false)
        }
    }

    return (
        <>
            {wip ? (
                <Wip title={`${pageName} in Progress`} />
            ) : (

                <>
                    {loading && (
                        <div className='loader_main full_loader'>
                            <Oval
                                height={50}
                                width={50}
                                color="#44C8F5"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                                ariaLabel='oval-loading'
                                secondaryColor="#44C8F5"
                                strokeWidth={2}
                                strokeWidthSecondary={2}
                            />
                        </div>
                    )}

                    <InnerLayout pageTitle={`${pageName !== '' ? pageName : 'Accessories'}`} layoutType=''
                        leftContent={<StylesLeft allSupplierList={allSupplierList} accessToken={accessToken} styleMasterId={styleMasterId} selectItemDetails={selectItemDetails} updateItemDetailsServices={updateItemDetailsServices} handleFormReset={handleFormReset} modelOptions={modelOptions} colourCollectionOptions={colourCollectionOptions} />}
                        rightContent={<StylesRight accessToken={accessToken} pageName={pageName} styleMasterId={styleMasterId} selectItemDetails={selectItemDetails} allRightListItems={allRightListItems} setLoading={setLoading} getRightMenuListData={getRightMenuListData} addNewRightItemServices={addNewRightItemServices} />}
                    />
                </>
            )}
        </>
    )
}

export default Accessories