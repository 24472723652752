import React, { useEffect, useRef, useState } from "react";
import { Form, Table } from "react-bootstrap";
import { Button } from "reactstrap";
import { getSizeTagsService } from "../services/frameStylesService";
import { useNavigate } from "react-router-dom";
import { useMessage } from "../context/messageContext";
import axios from "axios";
import { servicePath } from "../utility/data";

const SizeTagsComp = ({
  setLoading,
  frameStyleCollectionId,
  frameStyleId,
  activeTab,
  selectedRightItem,
}) => {
  const access_token = localStorage.getItem("access_token");
  const [tagData, setTagData] = useState([]);

  const inputRefs = useRef([]);
  const { setMessage, setMessageType } = useMessage();
  const navigate = useNavigate();

  useEffect(() => {
    if (frameStyleCollectionId && activeTab === "Size tags") {
      getTagNames(frameStyleCollectionId);
    }
  }, [frameStyleCollectionId, frameStyleId, activeTab]);

  const handleRowClick = (index) => {
    if (inputRefs.current[index]) {
      inputRefs.current[index].focus();
      inputRefs.current[index].select();
    }
  };

  console.log(tagData, "36");


  const handleDimensionChange = (val, index) => {
    setTagData((prevData) => {
      const updatedData = [...prevData];
      updatedData[index] = {
        ...updatedData[index],
        length: parseFloat(val),
      };
      return updatedData;
    });
  };

  const getTagNames = async (frameStyleCollectionId, frameStyleId) => {
    setLoading(true);
    const res = await getSizeTagsService(
      access_token,
      frameStyleCollectionId,
      frameStyleId
    );
    if (res?.data?.statusCode === 200) {
      setLoading(false);
      if (res?.data?.entity?.length > 0) {
        setTagData(res?.data?.entity);
      } else {
        setTagData([]);
      }
    } else {
      setLoading(false);
      setMessageType("error");
      setMessage(
        res?.data?.message ? res?.data?.message : "Something went wrong !"
      );
      if (res?.response?.status === 401) {
        navigate("/unauthorized");
        localStorage.removeItem("access_token");
      }
      if (res?.response?.status == 404) {
        navigate("/error/503");
      }
      if (res?.message === "Network Error") {
        navigate("/error/network");
      }
    }
  };

  const updateTagData = () => {
    setLoading(true);
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${servicePath}/Product/AddUpdateTagsMapping`,
      headers: {
        accept: "text/plain",
        Authorization: `Bearer ${access_token}`,
        "Content-Type": "application/json",
      },
      data: [...tagData],
    };

    axios
      .request(config)
      .then((res) => {
        if (res?.data?.statusCode === 200) {
          setLoading(false);
          setMessage(res.data.message);
          setMessageType("success");
          getTagNames(frameStyleCollectionId, frameStyleId);
        }
      })
      .catch((error) => {
        console.log(error);

        setLoading(false);
        setMessage(error.message ? error.message : "Something went wrong");
        setMessageType("error");

        if (error?.message === "Network Error") {
          navigate("/error/network");
        }
      });
  };

  return (
    <div className={`h-100 d-flex flex-column flex-grow-1 justify-content-between ${selectedRightItem?.isSelf ? "" : "not_allowed"}`}>
      <Table responsive className="w-100 discount_table">
        <thead className="border-bottom">
          <tr className="text-end">
            <th style={{ width: "90%", minWidth: "90%" }}>Tags</th>
            <th>Dimension</th>
          </tr>
        </thead>
        <tbody>
          {tagData && tagData.length > 0 ? (
            <>
              {tagData &&
                tagData.map((item, index) => (
                  <tr key={index} onClick={() => handleRowClick(index)}>
                    <td>{item.name}</td>
                    <td>
                      <Form.Control
                        ref={(el) => (inputRefs.current[index] = el)}
                        type="number"
                        value={item?.length}
                        onChange={(e) =>
                          handleDimensionChange(e.target.value, index)
                        }
                      />
                    </td>
                  </tr>
                ))}
            </>
          ) : (
            <tr>
              <td colSpan={2}>
                <h5 className="my-4 text-center text-muted w-100">
                  No data found
                </h5>
              </td>
            </tr>
          )}
        </tbody>
      </Table>


      {selectedRightItem?.isSelf && (
        <div className="d-flex gap-2 gap-xl-3 main_buttons">
          <Button
            color="primary"
            size="md"
            className="px-4"
            onClick={updateTagData}
          >
            Save Changes
          </Button>

          <Button
            color="primary"
            size="md"
            outline
            className="px-4"
            onClick={() => {
              getTagNames(frameStyleCollectionId, frameStyleId);
            }}
          >
            Reset
          </Button>
        </div>
      )}
    </div>
  );
};

export default SizeTagsComp;
