import React, { useState, useEffect } from 'react'
import { Form } from 'react-bootstrap';
import { MdOutlineKeyboardArrowDown } from 'react-icons/md';
import { FormControl, FormControlLabel, MenuItem, OutlinedInput, Radio, RadioGroup, Select } from 'material-ui-core';
import {
    Row,
    Col,
    Button,
    FormGroup,
    Label,
    Input,
} from 'reactstrap';
import { MenuProps } from '../utility/data';
import { FaRegFile } from 'react-icons/fa6';
import NumberInput from './NumberInput';
import { useRightContext } from '../context/rightMenuContext';
import { GetMarginMasterService, GetMarkUpMasterService } from '../services/commonServices';

const DocsGeneral = ({ activeTab, accessToken, handleTitleChange, selectItemDetails, updateItemDetailsServices, handleFormReset }) => {
    const { timestamp } = useRightContext()

    const [marginOptions, setMarginOptions] = useState([])
    const [markUpPriceListOptions, setMarkUpPriceListOptions] = useState([])

    const [name, setName] = useState('')
    const [itemId, setItemId] = useState('')
    const [fileName, setFileName] = useState('')
    const [pageSize, setPageSize] = useState('A4')
    const [pageType, setPageType] = useState('Portrait')
    const [marginValue, setMarginValue] = useState("")
    const [frameCount, setFrameCount] = useState('4')
    const [markUp, setMarkUp] = useState('')

    const [nameError, setNameError] = useState(false)
    const [fileNameError, setFileNameError] = useState(false)

    useEffect(() => {
        if (activeTab === "General") {
            getMarginOptions()
            getMarkUpPriceListOptions()
        }
    }, [activeTab])

    // setting all the value for prefilled inputs
    useEffect(() => {
        setName(selectItemDetails?.name || '')
        setItemId(selectItemDetails?.id || '')

        setFileName(selectItemDetails?.fileName || '')
        setPageSize(selectItemDetails?.paperSize || "A4")
        setPageType(selectItemDetails?.paperOrientation || "Portrait")

        if (marginOptions && marginOptions.length > 0) {
            setMarginValue(selectItemDetails?.margin ? selectItemDetails?.margin : marginOptions[0]?.value)
        }

        if (markUpPriceListOptions && markUpPriceListOptions.length > 0) {
            setMarkUp(selectItemDetails?.markUpPriceList ? selectItemDetails?.markUpPriceList : markUpPriceListOptions[0]?.name)
        }

        setFrameCount(selectItemDetails?.showTableOfContentsFrames ? selectItemDetails?.showTableOfContentsFrames : "4")

        setNameError(false)
        setFileNameError(false)
    }, [selectItemDetails, activeTab])

    // const handleNameChange = (e) => {
    //     const value = e.target.value

    //     if (e.target.value?.length <= 50) {

    //         setName(value)
    //         handleTitleChange(e.target.value)
    //     }
    // }

    const handleNameChange = (e) => {
        const value = e.target.value

        if (e.target.value?.length <= 50) {
            setName(value)
            handleTitleChange(e.target.value)
        }

        if (value.length > 50) {
            setNameError(true)
        } else {
            setNameError(false)
        }
    }

    const handleFileNameChange = (e) => {
        const value = e.target.value

        if (e.target.value?.length <= 50) {
            setFileName(value)
        }

        if (value.length > 50) {
            setFileNameError(true)
        } else {
            setFileNameError(false)
        }
    }

    const handleMarginChange = (e) => {
        const value = e.target.value

        setMarginValue(value)
    }

    const handleMarkUpChange = (e) => {
        const value = e.target.value

        setMarkUp(value)
    }

    // get margin options for dropdown from master
    const getMarginOptions = async () => {
        const res = await GetMarginMasterService(accessToken);

        if (res && res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                if (res?.data?.entity !== null) {
                    if (res?.data?.entity?.length > 0) {
                        setMarginOptions(res?.data?.entity)
                    }
                }
            }
        }
    }

    // get mark up price list dropdown from master
    const getMarkUpPriceListOptions = async () => {
        const res = await GetMarkUpMasterService(accessToken);

        if (res && res && res.status === 200) {

            if (res?.data?.statusCode === 200) {
                if (res?.data?.entity !== null) {
                    if (res?.data?.entity?.length > 0) {
                        setMarkUpPriceListOptions(res?.data?.entity)
                    }
                }
            }
        }
    }

    const handleFormSubmit = () => {

        var formDataValues = {
            'name': name,
            'fileName': fileName,
            'pageSize': pageSize,
            'pageType': pageType,
            'margin': marginValue || marginOptions[0]?.value,
            'frame': frameCount,
            'markUp': markUp || markUpPriceListOptions[0]?.name,
        }

        updateItemDetailsServices(formDataValues, "General")
    }

    return (
        <Form className='h-100 d-flex flex-column flex-grow-1 justify-content-between'>
            <div className='inside_scroll has_tab has_btns has_head'>
                <Row>
                    <Col xxl="12">
                        <Row>
                            <Col lg="6">
                                <FormGroup className="form-group">
                                    <Label for="name">
                                        Name
                                    </Label>
                                    <Input className='mb-1' name='name' value={name} onChange={(e) => handleNameChange(e)} />

                                    {nameError && timestamp && itemId && (
                                        <div className='mt-1 text-muted text-small'>Max 50 characters are allowed</div>
                                    )}

                                    {(!name && timestamp && itemId) && (
                                        <div className='mt-1 text-danger text-small'>Name is required</div>
                                    )}
                                </FormGroup>
                            </Col>

                            <Col lg="6">
                                <FormGroup className="form-group">
                                    <Label for="fileName">
                                        File name
                                    </Label>
                                    <Input className='mb-1' name='fileName' value={fileName} onChange={(e) => handleFileNameChange(e)} />

                                    {fileNameError && timestamp && itemId && (
                                        <div className='mt-1 text-muted text-small'>Max 50 characters are allowed</div>
                                    )}
                                </FormGroup>
                            </Col>

                            <Col lg="6">
                                <FormControl className="form-group">
                                    <Label for="pageSize">
                                        Paper size
                                    </Label>

                                    <RadioGroup
                                        aria-labelledby="pageSize-label"
                                        name="pageSize"
                                        value={pageSize}
                                        onChange={(e) => setPageSize(e.target.value)}
                                    >
                                        <FormControlLabel value="A4" control={<Radio />} label="A4 (Standard)" />
                                        <FormControlLabel value="A3" control={<Radio />} label="A3 (Large)" />
                                    </RadioGroup>
                                </FormControl>
                            </Col>

                            <Col lg="6">
                                <FormControl className="form-group">
                                    <Label for="pageType">
                                        Page orientation
                                    </Label>

                                    <RadioGroup
                                        aria-labelledby="pageType-label"
                                        name="pageType"
                                        className='flex-row gap-2'
                                        value={pageType}
                                        onChange={(e) => setPageType(e.target.value)}
                                    >
                                        <FormControlLabel className='page_type_radio_2' value="Portrait" control={<Radio />} label={<div>
                                            <span><FaRegFile /></span>
                                            <span>Portrait</span>
                                        </div>} />

                                        <FormControlLabel className='page_type_radio_2' value="Landscape" control={<Radio />} label={<div className='landscape'>
                                            <span><FaRegFile /></span>
                                            <span>Landscape</span>
                                        </div>} />
                                    </RadioGroup>
                                </FormControl>
                            </Col>

                            <Col lg="6">
                                <FormGroup className="form-group">
                                    <Label for="marginSize">
                                        Margin
                                    </Label>

                                    <Select
                                        labelId="marginSize"
                                        id="marginSize"
                                        value={marginValue || ''}
                                        label="marginSize"
                                        onChange={(e) => handleMarginChange(e)}
                                        input={<OutlinedInput className='w-100' name='marginSize' />}

                                        MenuProps={MenuProps}

                                        IconComponent={() => (
                                            <MdOutlineKeyboardArrowDown size={20} className='me-1 MuiSvgIcon-root MuiSelect-icon' />
                                        )}
                                    >
                                        {marginOptions && marginOptions.map((o, index) => (
                                            <MenuItem key={index} value={o?.id}>
                                                {o?.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormGroup>
                            </Col>

                            <Col lg="6">
                                <FormGroup className="form-group">
                                    <Label for="frameCount">
                                        {`Show table of contents if frame >=`}
                                    </Label>

                                    <NumberInput name="frameCount" value={frameCount} setValue={setFrameCount} />
                                </FormGroup>
                            </Col>

                            <Col lg="6" className='mb-5'>
                                <FormGroup className="form-group">
                                    <FormControl fullWidth>
                                        <Label for="markUpPriceList">
                                            Mark-up price list
                                        </Label>

                                        <Select
                                            labelId="markUp-label"
                                            id="markUpPriceList"
                                            value={markUp || ''}
                                            onChange={(e) => handleMarkUpChange(e)}
                                            input={<OutlinedInput name='markUpPriceList' />}
                                            MenuProps={MenuProps}

                                            IconComponent={() => (
                                                <MdOutlineKeyboardArrowDown size={20} className='me-1 MuiSvgIcon-root MuiSelect-icon' />
                                            )}
                                        >
                                            {markUpPriceListOptions && markUpPriceListOptions.map((o, index) => (
                                                <MenuItem key={index} value={o?.name}>
                                                    {o?.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
            <div className='d-flex gap-2 gap-xl-3 main_buttons'>
                <Button
                    color="primary"
                    size="md"
                    className='px-4'
                    onClick={() => handleFormSubmit()}
                    disabled={!timestamp || !name.trim()}
                >
                    Save Changes
                </Button>

                <Button
                    color="primary"
                    size="md"
                    outline
                    className='px-4'
                    onClick={() => handleFormReset()}
                    disabled={!timestamp || selectItemDetails?.initialValue}
                >
                    Reset
                </Button>
            </div>
        </Form>
    )
}

export default DocsGeneral