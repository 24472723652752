import React, { useEffect } from 'react'
import Left from './left'
import { useState } from 'react'
import { Oval } from 'react-loader-spinner'

const Designer2 = () => {
    const [loading, setLoading] = useState(false)
    const [modelVisible, setModelVisible] = useState(false)

    useEffect(() => {
        setModelVisible(false)
    }, []);

    return (
        <>
            {loading && (
                <div className='loader_main full_loader'>
                    <Oval
                        height={50}
                        width={50}
                        color="#44C8F5"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel='oval-loading'
                        secondaryColor="#44C8F5"
                        strokeWidth={2}
                        strokeWidthSecondary={2}
                    />
                </div>
            )}
            <Left
                loading={loading}
                setLoading={setLoading}
                modelVisible={modelVisible}
                setModelVisible={setModelVisible} />
        </>
    )
}

export default Designer2