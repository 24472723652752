import React, { useState } from "react";
import { Link } from "react-router-dom";

const Right = () => {
  const rightMenu = [
    {
      id: 1,
      name: "GS20 Sliding Door",
      img: "",
    },
    {
      id: 2,
      name: "GS20 Sliding Pocket Door",
      img: "",
    },
    {
      id: 3,
      name: "GS25 Sliding Door",
      img: "",
    },
    {
      id: 4,
      name: "Casement Window",
      img: "",
    },
    {
      id: 5,
      name: "Tilt And Turn Window",
      img: "",
    },
    {
      id: 6,
      name: "Bifold Door",
      img: "",
    },
    {
      id: 7,
      name: "Flat Roof Light",
      img: "",
    },
    {
      id: 8,
      name: "French Door (In)",
      img: "",
    },
    {
      id: 9,
      name: "French Door (Out)",
      img: "",
    },
    {
      id: 10,
      name: "Residential Door (In)",
      img: "",
    },
    {
      id: 11,
      name: "Residential Door (Out)",
      img: "",
    },
    {
      id: 12,
      name: "Oriel Window",
      img: "",
    },
    {
      id: 13,
      name: "Balcony",
      img: "",
    },
  ];

  const [activeTab, setActiveTab] = useState(rightMenu[0].name);

  return (
    <>
      <div className="h-100 d-flex flex-column flex-grow-1 justify-content-between">
        {/* has_tab has_btns has_head -> add respective class of included compoenent in div of class inside_scroll for proper scroll */}
        <div className="inside_scroll has_btns">
          <div className="right_list">
            {/* right side menu listing type 1 */}
            {rightMenu?.map((item, index) => (
              <div
                key={index}
                className="position-relative d-flex align-items-center right_item_wrap has_delete has_copy mb-2"
              >
                <Link
                  to="#"
                  onClick={() => setActiveTab(item.name)}
                  className={`right_item w-100 ${
                    activeTab === item.name && "active"
                  }`}
                >
                  <span className="me-2">
                    {item.img ? (
                      <img src={item.img} />
                    ) : (
                      <div
                        style={{
                          width: "41px",
                          height: "41px",
                          background: "#D9D9D9",
                        }}
                      ></div>
                    )}
                  </span>

                  <span className="text-truncate">{item.name}</span>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Right;
