import React, { useEffect } from 'react';
import { MdLanguage, MdMailOutline, MdOutlinePhone, MdOutlinePinDrop } from 'react-icons/md';
import { Image } from 'react-bootstrap';
import moment from "moment";

const DocHeader = ({ customClass, stylesHeader, generalCss, pdfData, header, itemCount, pageNum, totalPages, setPageNum }) => {

    let newDate = new Date();
    let date = moment(newDate).format("DD MMMM YYYY"); // .format("DD-MM-YYYY hh:mm:ss")
    // console.log("what is count ?", header);

    useEffect(() => {
        if (pageNum) {
            // console.log("GOT PAGE",pageNum);
            
            setPageNum(pageNum+1)
        }
    }, [])

    return (
        <div className={customClass}>
            <div style={stylesHeader.pdfHeader}>
                <div>
                    {pdfData?.logoImagePath && (
                        <div style={stylesHeader.logo}>
                            <Image src={pdfData?.logoImagePath} alt="logo" style={stylesHeader.logoImg} />
                        </div>
                    )}

                    <div>
                        {pdfData?.addressLine1 && (
                            <span style={stylesHeader.page_setup_text}>
                                <span>
                                    <MdOutlinePinDrop size={`${pdfData?.fontSize ? (parseInt(pdfData?.fontSize) + 4) : '18'}`} style={stylesHeader.head_icon} />
                                </span>

                                <span style={stylesHeader.head_item_span}>
                                    <span style={stylesHeader.head_item_span}>{pdfData?.addressLine1}</span>
                                    <span style={stylesHeader.head_item_span}>{pdfData?.addressLine2}</span>
                                </span>
                            </span>
                        )}

                        {pdfData?.phone && (
                            <span style={stylesHeader.page_setup_text}>
                                <span>
                                    <MdOutlinePhone size={`${pdfData?.fontSize ? (parseInt(pdfData?.fontSize) + 4) : '18'}`} style={stylesHeader.head_icon} />
                                </span>
                                <span style={stylesHeader.head_item_span}>{pdfData?.phone}</span>
                            </span>
                        )}

                        {pdfData?.email && (
                            <span style={stylesHeader.page_setup_text}>
                                <span>
                                    <MdMailOutline size={`${pdfData?.fontSize ? (parseInt(pdfData?.fontSize) + 4) : '18'}`} style={stylesHeader.head_icon} />
                                </span>
                                <span style={stylesHeader.head_item_span}>{pdfData?.email}</span>
                            </span>
                        )}

                        {pdfData?.website && (
                            <span style={stylesHeader.page_setup_text}>
                                <span>
                                    <MdLanguage size={`${pdfData?.fontSize ? (parseInt(pdfData?.fontSize) + 4) : '18'}`} style={stylesHeader.head_icon} />
                                </span>
                                <span style={stylesHeader.head_item_span}>{pdfData?.website}</span>
                            </span>
                        )}

                        {pdfData?.vat && (
                            <span style={stylesHeader.page_setup_text}>
                                <span style={stylesHeader.head_icon}>VAT NO:</span>
                                <span style={stylesHeader.head_item_span}>{pdfData?.vat}</span>
                            </span>
                        )}
                    </div>
                </div>

                <div>
                    <h6 style={stylesHeader.rightTitle}>
                        {header && header.title && header.title}
                    </h6>

                    <table style={stylesHeader.tableRight}>
                        <tbody>
                            <tr>
                                <td style={stylesHeader.tableRight.td}>
                                    Date
                                </td>
                                <td style={stylesHeader.tableRight.right}>
                                    {date}
                                </td>
                            </tr>
                            {/* <tr>
                                <td style={stylesHeader.tableRight.td}>
                                    Page
                                </td>
                                <td style={stylesHeader.tableRight.right}>
                                    1 of 2
                                </td>
                            </tr> */}
                            <tr>
                                <td style={stylesHeader.tableRight.td}>
                                    Items
                                </td>
                                <td style={stylesHeader.tableRight.right}>
                                    {itemCount}
                                </td>
                            </tr>
                            {/* <tr>
                                <td style={stylesHeader.tableRight.td}>
                                    {`Page ${pageNum} of ${totalPages}`}
                                </td>
                            </tr> */}
                        </tbody>
                    </table>

                    {/* {(header.projectInfo.name !== null) && ( */}
                    {header.projectInfo && (header.projectInfo.name || header.projectInfo.referencne || header.projectInfo.email || header.projectInfo.phone || header.projectInfo.address) && (
                        <div className='unsetPrintHeight' style={stylesHeader.rightBox}>
                            {header.projectInfo && header.projectInfo.name !== null && (
                                <h6 style={{ ...stylesHeader.rightBox.headText, ...generalCss.mb2 }}>
                                    {header.projectInfo.name}
                                </h6>
                            )}

                            {header.projectInfo && header.projectInfo.referencne !== null && (
                                <p style={stylesHeader.rightBox.headText}>
                                    <span style={generalCss.fontWeight}>Referencne:</span>
                                    <span style={{ marginLeft: "8px" }}>
                                        {header.projectInfo.referencne}
                                    </span>
                                </p>
                            )}

                            {header.projectInfo && header.projectInfo.email !== null && (
                                <p style={stylesHeader.rightBox.headText}>
                                    <span style={generalCss.fontWeight}>Email:</span>
                                    <span style={{ marginLeft: "8px" }}>
                                        {header.projectInfo.email}
                                    </span>
                                </p>
                            )}

                            {header.projectInfo && header.projectInfo.phone !== null && (
                                <p style={stylesHeader.rightBox.headText}>
                                    <span style={generalCss.fontWeight}>Phone:</span>
                                    <span style={{ marginLeft: "8px" }}>
                                        {header.projectInfo.phone}
                                    </span>
                                </p>
                            )}

                            {header.projectInfo && header.projectInfo.address !== null && (
                                <p style={stylesHeader.rightBox.headText}>
                                    <span style={generalCss.fontWeight}>Address:</span>
                                    <span style={{ marginLeft: "8px" }}>
                                        {header.projectInfo.address}
                                    </span>
                                </p>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}


export default DocHeader