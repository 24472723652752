import React from "react";
import { Card } from "reactstrap";

const NoDataCard = ({ text, loading }) => {

    return (
        <Card className="p-4">
            <h4 className="text-center mb-0">
                {loading ? 'Loading.. Please wait!' : `No ${text} found`}
            </h4>
        </Card>
    );
};

export default NoDataCard;
