import React, { useEffect } from "react";
import { Oval } from "react-loader-spinner";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import AdditionalProfileLeft from "./left";
import AdditionalRight from "./right";
import InnerLayout from "../../../../../../layouts/internalLayout";
import { useMessage } from "../../../../../../context/messageContext";
import { useRightContext } from "../../../../../../context/rightMenuContext";
import { addUpdateAdditionalArticle, getAdditionalArticle, getAllAdditionalArticlesService } from "../../../../../../services/additionalArticlesService";

const AdditionalProfiles = () => {
    const accessToken = localStorage.getItem("access_token");

    const [loading, setLoading] = useState(false);
    const [pageName, setPageName] = useState("");
    const [allRightListItems, setAllRightListItems] = useState([]);
    const [selectItemDetails, setSelectItemDetails] = useState([]);
    const [allRightListItemsOrder, setAllRightListItemsOrder] = useState(true);

    var itemAddedTrue = "";

    var getFirstElId = allRightListItems[0]?.id || "";

    const { setMessage, setMessageType } = useMessage();

    const {
        timestamp,
        fetchSelected,
        setTimestamp,
        activeIndex,
        setActiveIndex,
        setMainMenuActive,
        setSubMenuActive,
        setSelectedRightItem
    } = useRightContext();

    const navigate = useNavigate();

    useEffect(() => {
        setTimestamp("");
        setAllRightListItemsOrder(true);
        getAllAdditionalArticles();
        setMainMenuActive("Products")
        setSubMenuActive("Profiles")
    }, []);

    useEffect(() => {
        if (allRightListItems?.length > 0 && timestamp) {
            getRightItemDetails(timestamp);
        }
    }, [timestamp]);

    useEffect(() => {
        if (allRightListItemsOrder) {
            getFirstElId = allRightListItems[0]?.id;
            setActiveIndex(0);

            if (getFirstElId && allRightListItems?.length) {
                getRightItemDetails(getFirstElId);
            }
        } else {
            if (getFirstElId && allRightListItems?.length) {
                if (timestamp && allRightListItems?.length && activeIndex !== 0) {
                    getRightItemDetails(timestamp);
                } else {
                    getRightItemDetails(getFirstElId);
                }
            }
        }
    }, [allRightListItems]);

    // get all right menu item list
    const getAllAdditionalArticles = async () => {
        const res = await getAllAdditionalArticlesService(accessToken, 1);

        if (res && res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                if (res?.data?.entity !== null) {
                    if (res?.data?.entity?.length > 0) {
                        setAllRightListItems(res?.data?.entity);

                        if (itemAddedTrue == "added") {
                            const addedItemIndex = res.data.entity.length - 1;

                            if (addedItemIndex) {
                                fetchSelected(
                                    addedItemIndex,
                                    res.data.entity[addedItemIndex]?.id
                                );
                                itemAddedTrue = "";
                            }
                        }
                    } else {
                        setAllRightListItems([]);
                        setTimestamp("");

                        fetchSelected(0, "");

                        const formDataValues = {
                            id: "",
                            name: "",
                            description: "",
                            productCode: "",
                        };

                        setSelectItemDetails(formDataValues);
                    }
                }

                setTimeout(() => {
                    setLoading(false);
                }, 1200);
            } else {
                setLoading(false);
                setMessage(
                    res?.data.message ? res.data.message : "Something went wrong"
                );
                setMessageType("error");
            }
        } else {
            setLoading(false);
            setMessage(res?.message ? res.message : "Something went wrong");
            setMessageType("error");

            if (res?.message === "Network Error") {
                navigate("/error/network");
            }
        }
    };

    //   adding new item in the list
    const addNewRightItemServices = async (data) => {
        setLoading(true);
        if (data?.id === "") itemAddedTrue = "added";

        const res = await addUpdateAdditionalArticle(accessToken, data);

        if (res && res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                setMessage(res?.data?.message ? res.data.message : "Created profile");
                setMessageType("success");

                getAllAdditionalArticles();
            } else {
                setLoading(false);
                setMessage(
                    res?.data?.message ? res.data.message : "Something went wrong"
                );
                setMessageType("error");
            }
        } else {
            setLoading(false);
            setMessage(res?.message ? res.message : "Something went wrong");
            setMessageType("error");

            if (res?.message === "Network Error") {
                navigate("/error/network");
            }
        }
    };

    // get details of selected item in right menu by passing item id
    const getRightItemDetails = async (id) => {
        setLoading(true);

        const res = await getAdditionalArticle(accessToken, id);

        setAllRightListItemsOrder(false);

        if (res && res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                if (res?.data?.entity !== null) {

                    const selectedRight = allRightListItems?.find((p) => p.id == id)
                    setSelectedRightItem(selectedRight)
                    
                    // set state to get details of current selected item which will display data in left side component
                    setSelectItemDetails(res.data.entity);

                    // set selected item id which will update context
                    setTimestamp(id);
                }

                setTimeout(() => {
                    setLoading(false);
                }, 1200);
            } else {
                setLoading(false);
                setMessage(
                    res?.data.message ? res.data.message : "Something went wrong"
                );
                setMessageType("error");
            }
        } else {
            setLoading(false);
            setMessage(res?.message ? res.message : "Something went wrong");
            setMessageType("error");

            if (res?.message === "Network Error") {
                navigate("/error/network");
            }
        }
    };

    const handleFormReset = () => {
        if (timestamp) {
            getAllAdditionalArticles();
            getRightItemDetails(timestamp);
        }
    };

    return (
        <>
            {loading && (
                <div className="loader_main full_loader">
                    <Oval
                        height={50}
                        width={50}
                        color="#44C8F5"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel="oval-loading"
                        secondaryColor="#44C8F5"
                        strokeWidth={2}
                        strokeWidthSecondary={2}
                    />
                </div>
            )}
            <InnerLayout
                pageTitle="Additional Profile"
                layoutType=""
                leftContent={
                    <AdditionalProfileLeft
                        addNewRightItemServices={addNewRightItemServices}
                        accessToken={accessToken}
                        pageName={pageName}
                        setPageName={setPageName}
                        setMessage={setMessage}
                        setMessageType={setMessageType}
                        loading={loading}
                        setLoading={setLoading}
                        selectItemDetails={selectItemDetails}
                        handleFormReset={handleFormReset}
                    />
                }
                rightContent={
                    <AdditionalRight
                        accessToken={accessToken}
                        selectItemDetails={selectItemDetails}
                        allRightListItems={allRightListItems}
                        setLoading={setLoading}
                        getAllAdditionalArticles={getAllAdditionalArticles}
                        addNewRightItemServices={addNewRightItemServices}
                    />
                }
            />
        </>
    );
};

export default AdditionalProfiles;
