import React, { useEffect, useState } from 'react'
import { Card } from 'react-bootstrap';
import { BsReverseLayoutSidebarInsetReverse } from 'react-icons/bs';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import { useMessage } from '../context/messageContext';
import { useRef } from 'react';

const InternalLayout = ({ leftContent, rightContent, pageTitle, layoutType, filterButton, headerButton }) => {
    const [rightMenu, setRightMenu] = useState(false);

    const { message, messageType, setMessage } = useMessage()
    const isFirstRender = useRef(true);

    useEffect(() => {
        if (message && messageType === 'success') {
            toast(message, {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                type: 'success'
            })
        } else if (message && messageType === 'error') {
            toast(message, {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                type: 'error'
            })
        }
        setMessage('')

    }, [message])


    // for side side menu positioning

    // const handleScroll = () => {
    //     const position = window.scrollY;

    //     if (position < 47) {
    //         document.body.classList.remove('sticky-header');
    //     } else {
    //         document.body.classList.add('sticky-header');
    //     }
    // };

    // useEffect(() => {
    //     window.addEventListener('scroll', handleScroll, { passive: true });

    //     return () => {
    //         window.removeEventListener('scroll', handleScroll);
    //     };
    // }, []);

    return (
        <>
            <ToastContainer />
            <div className='page_title d-flex justify-content-between align-items-center'>
                <div className='d-flex gap-3 align-items-center'>
                    {pageTitle && (
                        <h3 className='mb-0 text-nowrap'>{pageTitle}</h3>
                    )}

                    {filterButton}
                </div>

                <div className='d-flex gap-2'>
                    {headerButton}

                    {layoutType !== "full" && (
                        <Button
                            color=""
                            size="md"
                            className='border-0 text-muted p-0 d-md-none right_menu_btn'
                            onClick={() => { setRightMenu(!rightMenu) }}
                        >
                            <BsReverseLayoutSidebarInsetReverse size={26} />
                        </Button>
                    )}
                </div>
            </div>

            {/* <Row className='main_layout'>
                <Col md={`${layoutType === "full" ? '12' : '7'}`} lg={`${layoutType === "full" ? '12' : '8'}`} xl={`${layoutType === "full" ? '12' : '9'}`}>
                    <Card className='mb-0 h-100'>
                        <Card.Body className='p-3 p-sm-4'>
                            <div className='d-flex flex-column justify-content-between h-100'>
                                {leftContent}
                            </div>
                        </Card.Body>
                    </Card>
                </Col>

                {layoutType !== "full" && (
                    <Col md="5" lg="4" xl="3" className={`right_menu ${rightMenu ? 'open_menu' : ''}`}>
                        <Card className='mb-0 h-100'>
                            <Card.Body className='d-flex flex-column justify-content-between py-3 py-sm-4'>
                                {rightContent}
                            </Card.Body>
                        </Card>
                    </Col>
                )}
            </Row> */}

            <div className={`main_layout page_scroll d-flex gap-3 gap-xl-4`}>
                <div className={`left_content flex-grow-1 ${layoutType}`}>
                    <Card className='mb-0 h-100'>
                        <Card.Body className='p-3 p-sm-4 main-card'>
                            <div className='d-flex flex-column justify-content-between h-100'>
                                {leftContent}
                            </div>
                        </Card.Body>
                    </Card>
                </div>

                {layoutType !== "full" && (
                    <div className={`right_content right_menu ${rightMenu ? 'open_menu' : ''}`}>
                        <Card className='mb-0 h-100'>
                            <Card.Body className='d-flex flex-column justify-content-between px-0 py-3 py-sm-4 main-card'>
                                {rightContent}
                            </Card.Body>
                        </Card>
                    </div>
                )}
            </div>
        </>
    )
}

InternalLayout.propTypes = {
    leftContent: PropTypes.node.isRequired,
    rightContent: PropTypes.node.isRequired,
};

export default InternalLayout