import React from 'react'
import InternalLayout from '../../../../../../layouts/internalLayout'
// import Left from '../promotions/left'
import Right from '../promotions/right'
import Left from './left'
import { useState } from 'react'
import { useMessage } from '../../../../../../context/messageContext'
import { useParams } from "react-router";
import { useEffect } from "react";
import { Oval } from 'react-loader-spinner'

const MarkUp = () => {
  var access_token = localStorage.getItem("access_token")

  const { setMessage, setMessageType } = useMessage()

  const [loading, setLoading] = useState(false)

  return (

    <div>
      {loading && (
        <div className="loader_main full_loader">
          <Oval
            height={50}
            width={50}
            color="#44C8F5"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#44C8F5"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      )}
      <InternalLayout pageTitle="Mark-up" layoutType="full" leftContent={<Left accessToken={access_token} setMessage={setMessage} setMessageType={setMessageType} loading={loading} setLoading={setLoading} />} rightContent="" />
    </div>
  )
}

export default MarkUp