import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import Editprices from "../../../../../../components/Editprices";
import { useRightContext } from "../../../../../../context/rightMenuContext";
import { MenuItem, OutlinedInput, Select } from "material-ui-core";
import { MenuProps, noSelectOptions } from "../../../../../../utility/data";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { getAllColoursCollection } from "../../../../../../services/productServices";
import { getHardwareDefaultSideService } from "../../../../../../services/commonServices";
import DropZone from "../../../../../../components/DropZone";

const Left = ({
    accessToken,
    selectItemDetails,
    pageName,
    setPageName,
    addNewRightItemServices,
    handleFormReset,
}) => {

    var acceptFileType = {
        'image/png': ['.jpg', '.jpeg', '.png', '.svg', '.gif'],
    }

    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [productCode, setProductCode] = useState("");
    const [nameError, setNameError] = useState(false);
    const [itemId, setItemId] = useState("");
    const [selectedPriceType, setSelectedPriceType] = useState("")

    const [colorCollection, setColorCollection] = useState("");
    const [colourCollectionOptions, setColourCollectionOptions] = useState([]);
    const [defaultSideOptions, setDefaultSideOptions] = useState([]);
    const [defaultSide, setDefaultSide] = useState("")
    const [uploadedFile, setUploadedFile] = useState('')
    const [filePreview, setFilePreview] = useState('')


    const priceTypeOptions = [
        {
            id: 1,
            name: "Per Meter"
        },
        {
            id: 2,
            name: "Quantity"
        }
    ]

    const {
        timestamp,
        selected,
        setSelected,
        handleEdit,
        handleDescEdit,
        setDescUpdate,
        handleFileUpdate,
        selectedRightItem,
    } = useRightContext();

    useEffect(() => {
        setSelected(selectItemDetails?.name);
        setPageName(selectItemDetails?.name);
        setName(selectItemDetails?.name || "");
        setItemId(selectItemDetails?.id || "");
        setDescription(selectItemDetails?.description || "");
        setProductCode(selectItemDetails?.productCode || "");
        setSelectedPriceType(selectItemDetails?.priceType || "")
        setDefaultSide(selectItemDetails?.defaultSide || "")
        setColorCollection(selectItemDetails?.colourCollection || "")
        setNameError(false);
        setUploadedFile('')
        setFilePreview(selectItemDetails?.filePathUrl || '')
    }, [selectItemDetails]);

    useEffect(() => {
        if (filePreview) {
            handleFileUpdate(filePreview)
        }
    }, [filePreview])

    useEffect(() => {
        getColorCollectionSelectOptions();
        getDefaultSideOptions(1);
    }, [])

    // get color collection from hardware collection API
    const getColorCollectionSelectOptions = async () => {
        const res = await getAllColoursCollection("2", accessToken)
        if (res && res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                if (res?.data?.entity !== null) {
                    if (res?.data?.entity?.length > 0) {
                        setColourCollectionOptions(res?.data?.entity)
                    } else {
                        setColourCollectionOptions(noSelectOptions)
                    }
                }
            }
        }
    }

    // get default side options from master API
    const getDefaultSideOptions = async (fetchMasterId) => {
        const res = await getHardwareDefaultSideService(accessToken, fetchMasterId);

        if (res && res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                if (res?.data?.entity !== null) {
                    if (res?.data?.entity?.length > 0) {
                        setDefaultSideOptions(res?.data?.entity)
                    }
                }
            }
        }
    }

    const handleName = (e) => {
        const value = e.target?.value;
        if (value?.length <= 50) {
            setName(value);
        } else {
            setNameError(true);
        }
    };

    const handleProductCode = (e) => {
        const value = e.target?.value;
        if (value?.length <= 50)
            setProductCode(value);
    };

    const handleFormSubmit = () => {
        const data = {
            id: itemId,
            name: name,
            description: description || "",
            productCode: productCode || "",
            typeId: 2,
            priceType: selectedPriceType,
            colorCollection: colorCollection || "",
            defaultSide: defaultSide || "",
            // This is to be send because the same API is used for additional hardware and profile
            weight: 0,
            externalPaintSurfaceArea: 0,
            internalPaintSurfaceArea: 0,
            position: "",
            profileType: "",
            filePath:uploadedFile
        };

        addNewRightItemServices(data);
    };

    return (
        <div className="d-flex flex-column h-100">
            <div className="d-flex align-items-center mb-3 flex-wrap gap-2 left_header">
                {selected && (
                    <h5 className="mb-0 text-wrap text-break me-4 main_title">
                        {selected}
                    </h5>
                )}
                <Editprices data={{ url: "/pricing/additional-hardware" }} />
            </div>

            <Form className="h-100 d-flex flex-column flex-grow-1 justify-content-between">
                <div className="inside_scroll has_btns has_head">
                    <Row>
                        <Col lg="6">
                            <FormGroup className="form-group">
                                <Label for="name">Name</Label>
                                <Input
                                    className="mb-1"
                                    name="name"
                                    disabled={!selectedRightItem?.isSelf}
                                    value={name}
                                    onChange={(e) => handleName(e)}
                                />
                                {nameError && (
                                    <div className="mt-1 text-muted text-small">
                                        Max 50 characters are allowed
                                    </div>
                                )}
                                {!name && (
                                    <div className="mt-1 text-danger text-small">
                                        Name is required
                                    </div>
                                )}
                            </FormGroup>
                        </Col>

                        <Col lg="6">
                            <FormGroup className="form-group">
                                <Label for="description">Description</Label>
                                <Input
                                    className="mb-1"
                                    name="description"
                                    disabled={!selectedRightItem?.isSelf}
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                />
                            </FormGroup>
                        </Col>

                        <Col lg="6">
                            <FormGroup className="form-group">
                                <Label for="productCode">Product code</Label>
                                <Input
                                    className="mb-1"
                                    name="productCode"
                                    disabled={!selectedRightItem?.isSelf}
                                    value={productCode}
                                    onChange={(e) => handleProductCode(e)}
                                />
                                {productCode?.length >= 50 && (
                                    <div className="mt-1 text-muted text-small">
                                        Max 50 characters are allowed
                                    </div>
                                )}
                                {!productCode && (
                                    <div className="mt-1 text-danger text-small">
                                        Product code is required
                                    </div>
                                )}
                            </FormGroup>
                        </Col>

                        <Col lg="6">
                            <FormGroup className="form-group">
                                <Label for="priceType">
                                    Price Type
                                </Label>

                                <Select
                                    labelId="priceType"
                                    id="priceType"
                                    value={selectedPriceType}
                                    disabled={!selectedRightItem?.isSelf}
                                    label="priceType"
                                    onChange={(e) => { setSelectedPriceType(e.target.value) }}
                                    input={<OutlinedInput className='w-100' name='priceType' />}

                                    MenuProps={MenuProps}

                                    IconComponent={() => (
                                        <MdOutlineKeyboardArrowDown size={20} className='me-1 MuiSvgIcon-root MuiSelect-icon' />
                                    )}
                                >
                                    {priceTypeOptions && priceTypeOptions.map((item, index) => (
                                        <MenuItem key={index} value={item?.id}>
                                            {item?.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {!selectedPriceType && (
                                    <div className="mt-1 text-danger text-small">
                                        Price type is required
                                    </div>
                                )}
                            </FormGroup>
                        </Col>

                        <Col lg="6">
                            <FormGroup className="form-group">
                                <Label for="colorCollection">
                                    Colour collection
                                </Label>

                                <Select
                                    labelId="colorCollection"
                                    id="colorCollection"
                                    value={colorCollection}
                                    disabled={!selectedRightItem?.isSelf}
                                    label="colorCollection"
                                    onChange={(e) => { setColorCollection(e.target?.value) }}
                                    input={<OutlinedInput className='w-100' name='colorCollection' />}

                                    MenuProps={MenuProps}

                                    IconComponent={() => (
                                        <MdOutlineKeyboardArrowDown size={20} className='me-1 MuiSvgIcon-root MuiSelect-icon' />
                                    )}
                                >
                                    {colourCollectionOptions && colourCollectionOptions.map((o, index) => (
                                        <MenuItem key={index} value={o?.id}>
                                            {o?.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormGroup>
                        </Col>

                        <Col lg="6">
                            {defaultSideOptions && defaultSideOptions.length > 0 && (
                                <FormGroup className="form-group">
                                    <Label for="defaultSide">
                                        Default side
                                    </Label>

                                    <Select
                                        labelId="defaultSide"
                                        id="defaultSide"
                                        value={defaultSide}
                                        disabled={!selectedRightItem?.isSelf}
                                        label="defaultSide"
                                        onChange={(e) => { setDefaultSide(e.target?.value) }}
                                        input={<OutlinedInput className='w-100' name='defaultSide' />}

                                        MenuProps={MenuProps}

                                        IconComponent={() => (
                                            <MdOutlineKeyboardArrowDown size={20} className='me-1 MuiSvgIcon-root MuiSelect-icon' />
                                        )}
                                    >
                                        {defaultSideOptions && defaultSideOptions.map((o, index) => (
                                            <MenuItem key={index} value={o?.id}>
                                                {o?.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormGroup>
                            )}
                        </Col>
                        <Col lg="6">
                            <FormGroup className="form-group">
                                <Label for="uploadFile">Upload image</Label>

                                <DropZone
                                    acceptFileType={acceptFileType}
                                    multiple={false}
                                    uploadedFile={uploadedFile}
                                    setUploadedFile={setUploadedFile}
                                    filePreview={filePreview}
                                    setFilePreview={setFilePreview}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </div>

                {selectedRightItem?.isSelf && <div className="d-flex gap-2 gap-xl-3 main_buttons">
                    <Button
                        color="primary"
                        size="md"
                        className="px-4"
                        disabled={!name || !productCode || !selectedPriceType}
                        onClick={handleFormSubmit}
                    >
                        Save Changes
                    </Button>

                    <Button
                        color="primary"
                        size="md"
                        outline
                        className="px-4"
                        disabled={!timestamp}
                        onClick={() => handleFormReset()}
                    >
                        Reset
                    </Button>
                </div>}
            </Form>
        </div>
    );
};

export default Left;
