import React, { useEffect, useState } from 'react'
import { BiPlus, BiTrashAlt } from 'react-icons/bi';
import { MdArrowDownward, MdArrowUpward, MdClose, MdContentCopy, MdNotes } from 'react-icons/md';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useRightContext } from '../../../../../../context/rightMenuContext';
import { useMessage } from '../../../../../../context/messageContext';
import { deleteRightItem, updateOrder } from '../../../../../../services/sysSashProductsService';
import { Image } from 'react-bootstrap';
import { copyItem } from '../../../../../../services/commonServices';

const DocumentsRight = ({ sashId, accessToken, allRightListItems, setLoading, getAllPrintTempData, addNewRightItemServices, selectItemDetails,usedInProductsList }) => {

	// const [previouseSelected, setPreviouseSelected] = useState({ prevId: 1, prevIndex: 1 })
	// const [currentSelected, setCurrentSelected] = useState({ currId: 1, currIndex: 1 })
	const [showDeleteModal, setShowDeleteModal] = useState(false)

	const [isDeleting, setIsDeleting] = useState(false)
	const [showVisibilityModal, setShowVisibilityModal] = useState(false);
	const navigate = useNavigate()

	const { timestamp, selectedRightItem, selected, fetchSelected, newItemList, setNewItemList, handleActiveIndexUp, handleActiveIndexDown, activeIndex, setActiveIndex } = useRightContext();

	const { setMessage, setMessageType } = useMessage()

	useEffect(() => {

		const assignItemValue = allRightListItems?.map((item) => {
			return {
				name: item.name,
				description: item.description,
				imageUrl: item?.profilePathUrl,
				id: item.id,
				isEnabled: item?.isEnabled,
				isSelf: item?.isSelf
			}
		})

		setNewItemList(assignItemValue)
	}, [allRightListItems])

	const handleItemClick = (currentIndex, id) => {
		// setCurrentSelected({ currId: id, currIndex: currentIndex + 1 });
		// if (currentSelected) {
		// 	setPreviouseSelected({
		// 		prevId: currentSelected.currId,
		// 		prevIndex: currentSelected.currIndex,
		// 	});
		// }
		fetchSelected(currentIndex, id);
	};

	// api to delete right side items
	const deleteRightItemServices = async () => {
		
		setIsDeleting(true)
		setLoading(true)

		const res = await deleteRightItem(accessToken, timestamp)

		// console.log(res, "res delete doc")

		if (res && res && res.status === 200) {

			setShowDeleteModal(false)

			fetchSelected(0, allRightListItems[0]?.id);

			if (res?.data?.statusCode === 200) {
				setMessage(res?.data?.message ? res.data.message : 'Record removed successfully !');
				setMessageType('success')

				getAllPrintTempData(sashId)
				setIsDeleting(false)
			} else {
				setIsDeleting(false)
				setLoading(false)
				setMessage(res?.data?.message ? res.data.message : 'Something went wrong');
				setMessageType('error')
			}
		} else {
			setIsDeleting(false)
			setLoading(false)
			setMessage(res?.message ? res.message : 'Something went wrong');
			setMessageType('error')

			setShowDeleteModal(false)
			getAllPrintTempData(sashId)

			if (res?.message === "Network Error") {
				navigate('/error/network')
			}
		}
	}

	// api to update right side item
	const updateOrderServices = async (direction) => {
		setLoading(true)

		const res = await updateOrder(accessToken, timestamp, direction, sashId)

		if (res && res && res.status === 200) {

			if (res?.data?.statusCode === 200) {
				getAllPrintTempData(sashId)
			} else {
				setLoading(false)
				setMessage(res?.data?.message ? res.data.message : 'Something went wrong');
				setMessageType('error')
			}
		} else {
			setLoading(false)
			setMessage(res?.message ? res.message : 'Something went wrong');
			setMessageType('error')

			if (res?.message === "Network Error") {
				navigate('/error/network')
			}
		}
	}

	const handleItemUp = () => {
		handleActiveIndexUp()
		updateOrderServices("UP")
	}

	const handleItemDown = () => {
		handleActiveIndexDown()
		updateOrderServices("DOWN")
	}

	const copyPaletteServices = async (id) => {
		setLoading(true)
		const res = await copyItem(accessToken, id, 22)
		if (res && res.status === 200) {
			if (res?.data?.statusCode === 200) {
				setMessageType('success')
				setMessage(res?.data?.message ? res.data.message : 'Item copied')
				getAllPrintTempData(sashId)
				setLoading(false)
			} else {
				setMessage(res?.data?.message ? res.data.message : 'Something went wrong')
				setMessageType('error')
				getAllPrintTempData(sashId)
				setLoading(false)
			}
		} else {
			setLoading(false)
			setMessage(res?.message ? res.message : 'Something went wrong');
			setMessageType('error')
			getAllPrintTempData(sashId)
			if (res?.message === "Network Error") {
				navigate('/error/network')
			}
		}
	}
	
	return (
		<>
			<div className='h-100 d-flex flex-column flex-grow-1 justify-content-between'>
				<div className='inside_scroll has_btns'>
					<div className='right_list'>
						{newItemList?.map((item, index) =>
							// add has_delete and has_copy class in below div based on availability of icons in action_btns div
							<div key={index} className='position-relative d-flex align-items-center right_item_wrap has_delete has_copy'>
								<Link to="#" className={`right_item w-100 ${activeIndex === index && "active"}`} onClick={() => handleItemClick(index, item.id)}>
									<span className='me-2 image_icon'>
										{item.imageUrl ? (
											<Image src={item.imageUrl} style={{ width: "55px", height: "55px" }} />
										) : (
											<span>
												<svg viewBox="0 0 100 50" width="55" height="55"><path className="stroke" stroke='#989898' fill='#fff' strokeWidth="2" d={`M 0,50 0,0 100,0 100,50 Z`}></path></svg>
											</span>
										)}
									</span>
									<div className="right_title">
										<span className="text-truncate">{item.name}</span>
										<span className="text-truncate d-block text-small">{item.description}</span>
									</div>
								</Link>
								<span className='position-absolute action_btns'>
									{item?.isSelf && <>
										<Link to="#" onClick={() => { setShowDeleteModal(true); fetchSelected(index, item.id); }}> <BiTrashAlt className='text-muted' size={20} /> </Link>
										<Link to="#" onClick={() => copyPaletteServices(item?.id)}> <MdContentCopy className='text-muted' size={18} /> </Link>
									</>}
								</span>
							</div>
						)}
					</div>
				</div>

				{/* Start: space for buttons at the bottom */}
				<div className='d-flex gap-2 gap-xl-3 main_buttons'>
					<Button
						color="primary"
						size="md"
						className='px-4'
						onClick={() => addNewRightItemServices(`Sash product ${newItemList && newItemList.length ? (newItemList.length + 1) : 1}`, 1, `${newItemList && newItemList.length ? newItemList.length : 0}`)}
					>
						<BiPlus size={20} className='me-1' />
						New
					</Button>

					{selectedRightItem?.isSelf && (
						<>
							<Button
								color=""
								size="md"
								className='border-0 text-primary px-0'
								onClick={() => handleItemUp()}
								disabled={timestamp === '' || activeIndex === 0}
							>
								<MdArrowUpward size={22} className='me-1' />
								Up
							</Button>

							<Button
								color=""
								size="md"
								className='border-0 text-primary px-0'
								onClick={() => handleItemDown()}
								disabled={timestamp === '' || (allRightListItems?.length - 1 === activeIndex) || (selectedRightItem?.isLast)}
							>
								<MdArrowDownward size={22} className='me-1' />
								Down
							</Button>
						</>
					)}
				</div>
				{/* End: space for buttons at the bottom */}
			</div>

			{/* <Modal isOpen={showDeleteModal} toggle={() => setShowDeleteModal(false)}>
				<ModalHeader >
					Delete Selected Item
				</ModalHeader>
				<ModalBody>
					Are you sure you want delete <strong>{selectItemDetails?.name || selected}</strong> ?
				</ModalBody>
				<ModalFooter>
					<Button color="outline-danger" size="md" disabled={isDeleting} onClick={() => deleteRightItemServices()}>Delete</Button>
					<Button onClick={() => setShowDeleteModal(false)} color="primary" size="md">Cancel</Button>
				</ModalFooter>
			</Modal> */}
			<Modal isOpen={showDeleteModal} toggle={() => setShowDeleteModal(false)}>
				{usedInProductsList?.length > 0 ? (
					<h2 className='px-3 pt-3'>
						Oops!
					</h2>
				) : (
					<ModalHeader >
						Delete Selected Item
					</ModalHeader>
				)}
				{usedInProductsList?.length > 0 ? (
					<ModalBody>
						<p className='bt-0'>This sash product is being used by the following products:</p>

						{usedInProductsList.map((item, index) =>
							<span key={index}>{index == 0 ? null : <br />} <b>{item.productType}</b> - {item.usedIn}</span>
						)}

						<p className='mt-4'>Remove the sash product from these products first.</p>
					</ModalBody>
				) : (
					<ModalBody>
						Are you sure you want delete <strong>{selectItemDetails?.name}</strong> ?
					</ModalBody>
				)}

				<ModalFooter>
					{usedInProductsList?.length == 0 ? <Button color="outline-danger" size="md" disabled={isDeleting} onClick={() => deleteRightItemServices()}>Delete</Button> : null}

					<Button onClick={() => setShowDeleteModal(false)} color="primary" size="md">Cancel</Button>
				</ModalFooter>
			</Modal>

			<Modal isOpen={showVisibilityModal} toggle={() => setShowVisibilityModal(false)}>
				<div className='d-flex justify-content-between align-items-center p-3'>
					<h2 className='mb-0 lh-1'>
						Oops!
					</h2>

					<NavLink onClick={() => setShowVisibilityModal(false)}>
						<MdClose size={20} />
					</NavLink>
				</div>

				<ModalBody>
					<p className='bt-0'>This sash product is being used by the following products:</p>

					{usedInProductsList.map((item, index) =>
						<span key={index}>{index == 0 ? null : <br />} <b>{item.productType}</b> - {item.usedIn}</span>
					)}

					<p className='mt-4'>Remove the sash product from these products first.</p>
				</ModalBody>
			</Modal>
			
		</>
	)
}

export default DocumentsRight