import axios from "axios";
import { servicePath } from "../../utility/data";

// get all right menu item list based on page/type
export const getRightMenuList = (accessToken, collectionId) => {

    const formData = new FormData();
    formData.append("CollectionId", collectionId);

    let config = {
        method: 'POST',
        maxBodyLength: Infinity,
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'multipart/form-data'
        }
    };

    return axios
        .post(`${servicePath}/General/GetAllColoursPrice`, formData, config)
        .then((res) => {
            return res;
        })
        .catch((error) => {
            return error;
        });
}

// get details of selected item in right menu
export const getItemDetails = (accessToken, id) => {
    const formData = new FormData();
    formData.append("Id", id);

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'multipart/form-data'
        }
    };

    return axios
        .post(`${servicePath}/General/GetColoursPrice`, formData, config)
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};

// get details of selected item in right menu
export const getPriceTableData = (accessToken, data) => {
    const formData = new FormData();
    formData.append("ColourCollectionId", data.colourCollectionId);
    formData.append("ColourPriceId", data.colourPriceId);
    formData.append("PriceMasterId", data.priceMasterId);
    formData.append("MarkupMasterId", data.markupMasterId);
    formData.append("ColourType", data.colorType);

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'multipart/form-data'
        }
    };

    return axios
        .post(`${servicePath}/General/GetColourPricing`, formData, config)
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};

// delete selected item
export const deleteRightItem = (accessToken, id) => {
    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    const formData = new FormData()
    formData.append("Id", id)
    return axios
        .post(`${servicePath}/General/DeleteColoursPrice`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}

// update order of selected item
export const updateOrder = (accessToken, id, direction) => {

    console.log(id, direction, "order test")

    var requestBody = {
        "id": id,
        "sequenceNo": 0,
        "type": direction
    };

    const headers = {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
    }

    return axios
        .post(`${servicePath}/General/AddUpdateGeneralPromotion`, requestBody, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}

export const updateColoursPriceOrder = (accessToken, id, direction, collectionId) => {

    console.log(id, direction, "order test")

    var requestBody = {
        "id": id,
        "sequenceNo": 0,
        "typeText": collectionId,
        "type": direction
    };

    const headers = {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
    }

    return axios
        .post(`${servicePath}/General/UpdateColoursPriceOrder`, requestBody, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}

// update details of selected item
export const updateItemDetails = (accessToken, data) => {

    const headers = {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
    }

    var requestBody = data

    return axios
        .post(`${servicePath}/General/AddUpdateColourPricing`, requestBody, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};

// add new right menu item based on page/type
export const addNewRightItem = (accessToken, data, collectionId) => {
    const formData = new FormData();

    formData.append("Id", data.id || '');

    formData.append('Name', data.name);

    formData.append('ColourCollectionId', collectionId);

    const headers = {
        'accept': 'text/plain',
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data'
    }

    return axios
        .post(`${servicePath}/General/AddColoursPrice`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};