import React from 'react'
import ErrorLayout from './error-layout'
import maintenanceImg from '../../../../assets/img/maintenance.svg'

const Error503 = () => {
    return (
        <>
            <ErrorLayout 
                code={maintenanceImg} 
                text="Site is Under Maintenance"
                subText="Please check back in sometime"
                layout="layout-2"
                pageType="full_cover"
            />
        </>
    )
}

export default Error503