import React, { useEffect, useState } from "react";
import InternalLayout from "../../../../../layouts/internalLayout";
import Left from "./left";
import Right from "./right";
import { useNavigate } from "react-router-dom";
import { Oval } from "react-loader-spinner";
import { useMessage } from "../../../../../context/messageContext";
import { useRightContext } from "../../../../../context/rightMenuContext";
import { addNewRightItem, getItemDetails, getRightMenuList, updateItemDetails } from "../../../../../services/pricing/costPriceMarkupPriceService";

const CostPriceLists = () => {
  const accessToken = localStorage.getItem("access_token");

  const [loading, setLoading] = useState(false);
  const [selectItemDetails, setSelectItemDetails] = useState([]);
  const [allRightListItems, setAllRightListItems] = useState([]);
  const [allRightListItemsOrder, setAllRightListItemsOrder] = useState(true);

  const { setMessage, setMessageType } = useMessage();

  const {
    timestamp,
    fetchSelected,
    setTimestamp,
    activeIndex,
    setActiveIndex,
  } = useRightContext();

  const navigate = useNavigate();

  var getFirstElId = allRightListItems[0]?.id || "";

  var itemAddedTrue = "";

  useEffect(() => {
    setLoading(true);
    setTimestamp("");
    getRightMenuListData();
  }, []);

  useEffect(() => {
    if (allRightListItems?.length > 0 && timestamp) {
      getRightItemDetails(timestamp)
  }
  }, [timestamp]);

  useEffect(() => {
    if (allRightListItemsOrder) {
      getFirstElId = allRightListItems[0]?.id;

      setActiveIndex(0);

      if (getFirstElId && allRightListItems?.length) {
        getRightItemDetails(getFirstElId);
      }
    } else {
      if (getFirstElId && allRightListItems?.length) {
        if (timestamp && allRightListItems?.length && activeIndex !== 0) {
          getRightItemDetails(timestamp);
        } else {
          getRightItemDetails(getFirstElId);
        }
      }
    }
  }, [allRightListItems]);

  // get all right menu item list
  const getRightMenuListData = async () => {
    setLoading(true);

    const res = await getRightMenuList(accessToken);

    if (res && res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        if (res?.data?.entity !== null) {
          if (res?.data?.entity?.length > 0) {
            setAllRightListItems(res?.data?.entity);

            if (itemAddedTrue == "added") {
              const addedItemIndex = res.data.entity.length - 1;

              if (addedItemIndex) {
                fetchSelected(
                  addedItemIndex,
                  res.data.entity[addedItemIndex]?.id
                );
                itemAddedTrue = "";
              }
            }
          } else {
            setAllRightListItems([]);
            setTimestamp("");

            fetchSelected(0, "");

            setSelectItemDetails([]);
          }
        }

        setTimeout(() => {
          setLoading(false);
        }, 1200);
      } else {
        setLoading(false);
        setMessage(
          res?.data.message ? res.data.message : "Something went wrong"
        );
        setMessageType("error");
      }
    } else {
      setLoading(false);
      setMessage(res?.message ? res.message : "Something went wrong");
      setMessageType("error");

      if (res?.message === "Network Error") {
        navigate("/error/network");
      }
    }
  };

  // get details of selected item in right menu by passing item id
  const getRightItemDetails = async (id) => {
    setLoading(true);

    const res = await getItemDetails(accessToken, id);

    setAllRightListItemsOrder(false);

    if (res && res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        if (res?.data?.entity !== null) {
          // set state to get details of current selected item which will display data in left side component
          setSelectItemDetails(res.data.entity);

          // set selected item id which will update context
          setTimestamp(id);
        }

        setTimeout(() => {
          setLoading(false);
        }, 1200);
      } else {
        setLoading(false);
        setMessage(
          res?.data.message ? res.data.message : "Something went wrong"
        );
        setMessageType("error");
      }
    } else {
      setLoading(false);
      setMessage(res?.message ? res.message : "Something went wrong");
      setMessageType("error");

      if (res?.message === "Network Error") {
        navigate("/error/network");
      }
    }
  };

  // api call to update all selected item details
  const updateItemDetailsServices = async (data) => {
    setLoading(true);

    const res = await updateItemDetails(accessToken, data);

    if (res && res && res.status === 200) {
      // console.log(res, 'selected item info')

      if (res?.data?.statusCode === 200) {
        setLoading(false);
        setMessage(res?.data?.message ? res.data.message : "Updated details");
        setMessageType("success");

        getRightMenuListData();
        setLoading(false);
      } else {
        setLoading(false);
        setMessage(
          res?.data?.message ? res.data.message : "Something went wrong"
        );
        setMessageType("error");
      }
    } else {
      setLoading(false);
      setMessage(res?.message ? res.message : "Something went wrong");
      setMessageType("error");

      if (res?.message === "Network Error") {
        navigate("/error/network");
      }
    }
  };

  // api call to added new right item
  const addNewRightItemServices = async (title) => {
    setLoading(true);
    itemAddedTrue = "added";

    const res = await addNewRightItem(accessToken, title);

    if (res && res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        setMessage(
          res?.data?.message ? res.data.message : "Record created successfully!"
        );
        setMessageType("success");

        getRightMenuListData();
      } else {
        setLoading(false);
        setMessage(
          res?.data?.message ? res.data.message : "Something went wrong"
        );
        setMessageType("error");
      }
    } else {
      setLoading(false);
      setMessage(res?.message ? res.message : "Something went wrong");
      setMessageType("error");

      if (res?.message === "Network Error") {
        navigate("/error/network");
      }
    }
  };

  const handleFormReset = () => {
    if (timestamp) {
      getRightMenuListData();

      getRightItemDetails(timestamp);
    }
  };

  return (
    <>
      {loading && (
        <div className="loader_main full_loader">
          <Oval
            height={50}
            width={50}
            color="#44C8F5"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#44C8F5"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      )}

      <InternalLayout
        pageTitle="Cost Price List"
        layoutType=""
        leftContent={
          <Left
            selectItemDetails={selectItemDetails}
            updateItemDetailsServices={updateItemDetailsServices}
            handleFormReset={handleFormReset}
          />
        }
        rightContent={
          <Right
            accessToken={accessToken}
            selectItemDetails={selectItemDetails}
            allRightListItems={allRightListItems}
            setLoading={setLoading}
            getRightMenuListData={getRightMenuListData}
            addNewRightItemServices={addNewRightItemServices}
          />
        }
      />
    </>
  );
};

export default CostPriceLists;
