import React from 'react';
import { Col } from 'reactstrap';
import { Image } from 'react-bootstrap';
import hardwareIcon1 from "../../../../assets/img/images/hardware2.png";
import print3 from "../../../../assets/img/images/print3.png";
import grillIcon from '../../../../assets/img/grill_icon.png';
import alphabetIcon from '../../../../assets/img/alphabet.svg';

const ItemListWrap = ({ colVal, dataArray, listType, heading, frameGridView, generalCss, stylesHeader, frame }) => {

    return (
        <>
            <Col sm={`${frame.minimumWidth < 450 ? '12' : colVal}`} style={stylesHeader.rightBoxWrap}>
                <div className='unsetPrintHeight2' style={stylesHeader.rightBox}>
                    <h6 style={stylesHeader.rightBox.text}>
                        {heading}
                    </h6>

                    <div className='d-flex gap-2 flex-wrap'>
                        {listType == 1 && (
                            <>
                                {dataArray?.length > 0 && dataArray.map((o, index) => (
                                    <div style={frameGridView.mainFrameLeft.inner.box} key={index}>
                                        <div style={frameGridView.mainFrameLeft.inner.box.sm.wrap} className='ball_shadow'>
                                            <Image src={o.image || print3} style={frameGridView.mainImage} alt="image" />

                                            {(o?.design?.name === 'Astragal rectangular' || o?.design?.name === 'Georgian rectangular') && (
                                                <Image src={grillIcon} className='glaze_img grill_bar'></Image>
                                            )}

                                            {o?.design?.name === 'Sandblasted numerals' && (
                                                <Image src={alphabetIcon} className='glaze_img name_icon'></Image>
                                            )}
                                        </div>

                                        <div style={generalCss.flexCol}>
                                            <h6 style={frameGridView.mainFrameLeft.inner.box.title.head}>
                                                {frame?.printFrameProperty?.includes("Glazing specification") && o.spec !== null && o?.spec?.name}
                                            </h6>
                                            
                                            {frame?.printFrameProperty?.includes("Glazing texture") && o.texture !== null && (
                                                <span style={frameGridView.mainFrameLeft.inner.box.title.sub}>
                                                    {o?.texture?.name}
                                                </span>
                                            )}

                                            {frame?.printFrameProperty?.includes("Glazing design") && o.design !== null && (
                                                <span style={frameGridView.mainFrameLeft.inner.box.title.sub}>
                                                    {o?.design?.name}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </>
                        )}

                        {listType == 2 && (
                            <>
                                {dataArray?.length > 0 && dataArray.map((o, index) => (
                                    <div style={frameGridView.mainFrameLeft.inner.box} className='mb-0' key={index}>
                                        <div style={frameGridView.mainFrameLeft.inner.box.sm.wrap}>
                                            <Image src={hardwareIcon1} style={frameGridView.mainImage} alt="image" />
                                        </div>
                                        <div style={generalCss.flexCol}>
                                            <h6 style={frameGridView.mainFrameLeft.inner.box.title.head}>
                                                {o?.name} X{o?.count}
                                            </h6>
                                            <span style={frameGridView.mainFrameLeft.inner.box.title.sub}>
                                                {o?.description}
                                            </span>
                                            <span style={frameGridView.mainFrameLeft.inner.box.title.sub}>
                                                {o.color}
                                            </span>
                                        </div>
                                    </div>
                                ))}
                            </>
                        )}
                    </div>
                </div>
            </Col>
        </>
    );
}


export default ItemListWrap