import React, { useState, useEffect } from 'react'
import { Form } from 'react-bootstrap';
import { MdInfoOutline, MdOutlineKeyboardArrowDown } from 'react-icons/md';
import { FormControl, FormControlLabel, MenuItem, OutlinedInput, Radio, RadioGroup, Select } from 'material-ui-core';
import {
    Row,
    Col,
    Button,
    FormGroup,
    Label,
    Input,
    Alert,
} from 'reactstrap';
import { MenuProps, noSelectOptions } from '../utility/data';
import NumberInputDecimal from './NumberInputDecimal';
import { useRightContext } from '../context/rightMenuContext';
import UsedInProducts from './UsedInProducts';
import { getProfileJointsList } from '../services/sysProfileJointsService';
import DropZone from "../components/DropZone";
const SystemSashGeneral = ({ isEditable, pageName, activeTab, accessToken, selectItemDetails, updateItemDetailsServices, handleFormReset, usedInProductsList, deleteSysFrameSashInUsedService, removePanelProdInUsedService }) => {
    var acceptFileType = {
        'image/png': ['.jpg', '.jpeg', '.png', '.svg', '.gif'],
    }
    const { timestamp, setSelected, handleEdit, handleDescEdit, setDescUpdate, handleFileUpdate } = useRightContext()

    const [jointsOptions, setJointsOptions] = useState([]);

    const [name, setName] = useState('')
    const [itemId, setItemId] = useState('')
    const [description, setDescription] = useState('')
    const [joints, setJoints] = useState("")

    const [uploadedFile, setUploadedFile] = useState('')
    const [filePreview, setFilePreview] = useState('')

    const [highClearance, setHighClearance] = useState("0")
    const [lockClearance, setLockClearance] = useState("0")

    const [lowClearance, SetLowClearance] = useState("0")

    const [clearance, setClearance] = useState("0")
    const [gasketClearance, setGasketClearance] = useState("0")

    const [openRadio, setOpenRadio] = useState('External')
    const [flushSwitch, setFlushSwitch] = useState(false)

    // const [usedInProductsList, setUsedInProductsList] = useState([])

    const [nameError, setNameError] = useState(false)
    const [descriptionError, setDescriptionError] = useState(false)

    useEffect(() => {
        if (activeTab === "General") {
            getProfilejointsOptions()
        }
    }, [activeTab])

    // setting all the value for prefilled inputs
    useEffect(() => {

        // console.log(selectItemDetails, "data left")

        setName(selectItemDetails?.name || '')
        setItemId(selectItemDetails?.id || '')
        setDescription(selectItemDetails?.description || '')

        setHighClearance(selectItemDetails?.hingeClearance || 0)
        setLockClearance(selectItemDetails?.lockClearance || 0)
        SetLowClearance(selectItemDetails?.lowThresholdClearance || 0)

        setUploadedFile('')
        setFilePreview(selectItemDetails?.imagePath || '')

        setClearance(selectItemDetails?.clearance || 0)
        setGasketClearance(selectItemDetails?.gasketClearance || 0)
        setFlushSwitch(selectItemDetails?.flush || false)
        setOpenRadio(selectItemDetails?.open || "Internal")

        setNameError(false)
        setDescriptionError(false)
    }, [selectItemDetails])


    useEffect(() => {
        if (filePreview) {
            handleFileUpdate(filePreview)
        }
    }, [filePreview])

    useEffect(() => {
        if (jointsOptions && jointsOptions.length > 0) {
            setJoints(selectItemDetails?.joints || jointsOptions[0]?.id)
        }
    }, [selectItemDetails, jointsOptions])

    // get profile joints list
    const getProfilejointsOptions = async () => {
        const res = await getProfileJointsList(accessToken)

        if (res && res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                if (res?.data?.entity !== null) {
                    if (res?.data?.entity?.length > 0) {
                        setJointsOptions(res?.data?.entity)
                    } else {
                        setJointsOptions(noSelectOptions)
                    }
                }
            }
        }
    }

    const handleNameChange = (e) => {
        const value = e.target.value

        if (e.target.value?.length <= 50) {
            setName(value)
            handleEdit(value)
            setSelected(value)
        }

        if (value.length > 50) {
            setNameError(true)
        } else {
            setNameError(false)
        }
    }

    const handleDescriptionChange = (e) => {
        const value = e.target.value

        if (e.target.value?.length <= 80) {
            setDescription(value)
            handleDescEdit(value)
            setDescUpdate(value)
        }

        if (value.length > 80) {
            setDescriptionError(true)
        } else {
            setDescriptionError(false)
        }
    }

    const handleOpenChange = (e) => {
        const value = e.target.value

        setOpenRadio(value)
    }

    const handleFlushChange = (e) => {
        const value = e.target.checked

        setFlushSwitch(value)
    }

    const handleFormSubmit = () => {

        var formDataValues = {
            'id': selectItemDetails?.id,
            "SashProductMasterId": selectItemDetails?.sashProductMasterId,
            'name': name,
            'description': description,
            'Joints': joints,
            'Clearance': clearance,
            'GasketClearance': gasketClearance,
            'HingeClearance': highClearance,
            'LockClearance': lockClearance,
            'LowThresholdClearance': lowClearance,
            'Open': openRadio,
            'Flush': flushSwitch,
            "filePath": uploadedFile || '',
            "imagePath": '',
        }

        updateItemDetailsServices(formDataValues, "General")
    }

    return (
        <Form className='h-100 d-flex flex-column flex-grow-1 justify-content-between'>
            <div className='inside_scroll has_tab has_btns has_head'>
                <Row>
                    <Col xxl="12">
                        <Row>
                            <Col lg="6">
                                <FormGroup className="form-group">
                                    <Label for="name">
                                        Name
                                    </Label>
                                    <Input className='mb-1' name='name' value={name} onChange={(e) => handleNameChange(e)} />

                                    {nameError && timestamp && itemId && (
                                        <div className='mt-1 text-muted text-small'>Max 50 characters are allowed</div>
                                    )}

                                    {!name && itemId && (
                                        <div className='mt-1 text-danger text-small'>Name is required</div>
                                    )}
                                </FormGroup>
                            </Col>

                            <Col lg="6">
                                <FormGroup className="form-group">
                                    <Label for="description">
                                        Description
                                    </Label>
                                    <Input className='mb-1' name='description' value={description} onChange={(e) => handleDescriptionChange(e)} />

                                    {(descriptionError && timestamp && itemId) && (
                                        <div className='mt-1 text-muted text-small'>Max 80 characters are allowed</div>
                                    )}
                                </FormGroup>
                            </Col>

                            <Col lg="6">
                                <FormGroup className="form-group">
                                    <Label for="jointsSelect">
                                        Joints
                                    </Label>

                                    <Select
                                        labelId="jointsSelect"
                                        id="jointsSelect"
                                        value={joints || ''}
                                        label="jointsSelect"
                                        onChange={(e) => { setJoints(e.target.value) }}
                                        input={<OutlinedInput className='w-100' name='jointsSelect' />}

                                        MenuProps={MenuProps}

                                        IconComponent={() => (
                                            <MdOutlineKeyboardArrowDown size={20} className='me-1 MuiSvgIcon-root MuiSelect-icon' />
                                        )}
                                    >
                                        {jointsOptions && jointsOptions.map((o, index) => (
                                            <MenuItem key={index} value={o?.id}>
                                                {o?.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormGroup>
                            </Col>

                            <Col lg="6">
                                <FormGroup className="form-group">
                                    <Label for="clearance">Clearance</Label>

                                    <NumberInputDecimal
                                        name="clearance"
                                        value={clearance}
                                        setValue={setClearance}
                                    />
                                </FormGroup>
                            </Col>

                            <Col lg="6">
                                <FormGroup className="form-group">
                                    <Label for="gasketClearance">Gasket clearance</Label>

                                    <NumberInputDecimal
                                        name="gasketClearance"
                                        value={gasketClearance}
                                        setValue={setGasketClearance}
                                    />
                                </FormGroup>
                            </Col>

                            {(pageName === "Door sash" || pageName === "Sliding door sash" || pageName === "Bifolding door sash") && (
                                <>

                                    {(pageName !== "Sliding door sash") && (
                                        <Col lg="6">
                                            <FormGroup className="form-group">
                                                <Label for="highClearance">Hinge clearance</Label>

                                                <NumberInputDecimal
                                                    name="highClearance"
                                                    value={highClearance}
                                                    setValue={setHighClearance}
                                                />
                                            </FormGroup>
                                        </Col>
                                    )}

                                    <Col lg="6">
                                        <FormGroup className="form-group">
                                            <Label for="lockClearance">Lock clearance</Label>

                                            <NumberInputDecimal
                                                name="lockClearance"
                                                value={lockClearance}
                                                setValue={setLockClearance}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col lg="6">
                                        <FormGroup className="form-group">
                                            <Label for="lowClearance">Low threshold clearance</Label>

                                            <NumberInputDecimal
                                                name="lowClearance"
                                                value={lowClearance}
                                                setValue={SetLowClearance}
                                            />
                                        </FormGroup>
                                    </Col>
                                </>
                            )}

                            {(pageName !== "Sliding window sash" && pageName !== "Sliding door sash") && (
                                <Col lg="6">
                                    <FormGroup className="form-group">
                                        <Label for="openRadio">Open</Label>

                                        <FormControl className="mb-0 w-100">
                                            <RadioGroup
                                                aria-labelledby="feature-label"
                                                name="openRadio"
                                                className='flex-row flex-wrap custom_x_gap'
                                                value={openRadio}
                                                onChange={(e) => handleOpenChange(e)}
                                            >
                                                <FormControlLabel value="Internal" control={<Radio />} label="Internal" />
                                                <FormControlLabel value="External" control={<Radio />} label="External" />
                                            </RadioGroup>
                                        </FormControl>
                                    </FormGroup>
                                </Col>
                            )}

                            <Col lg="6">
                                <FormGroup className="form-group">
                                    <Label for="flushSwitch">
                                        Flush
                                    </Label>
                                    <Form.Check // prettier-ignore
                                        type="switch"
                                        id="flushSwitch"
                                        className='form-switch-lg default_switch'
                                        onChange={(e) => handleFlushChange(e)}
                                        label={flushSwitch ? 'On' : 'Off'}
                                        disabled={selectItemDetails?.flushSwitch}
                                        checked={flushSwitch}
                                    />
                                </FormGroup>
                            </Col>

                            <Col lg="6">
                                <FormGroup className="form-group">
                                    <Label for="uploadFile">Upload image</Label>

                                    <DropZone
                                        acceptFileType={acceptFileType}
                                        multiple={false}
                                        uploadedFile={uploadedFile}
                                        setUploadedFile={setUploadedFile}
                                        filePreview={filePreview}
                                        setFilePreview={setFilePreview}
                                    />
                                </FormGroup>
                            </Col>

                            <Col sm="12">
                                <h4 className="main_title sub_title">Used in products</h4>

                                <UsedInProducts data={usedInProductsList} handleRemove={removePanelProdInUsedService} />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>

            {isEditable && (
                <div className='d-flex gap-2 gap-xl-3 main_buttons'>
                    <Button
                        color="primary"
                        size="md"
                        className='px-4'
                        onClick={() => handleFormSubmit()}
                        disabled={!timestamp || !name.trim()}
                    >
                        Save Changes
                    </Button>

                    <Button
                        color="primary"
                        size="md"
                        outline
                        className='px-4'
                        onClick={() => handleFormReset()}
                        disabled={!timestamp || selectItemDetails?.initialValue}
                    >
                        Reset
                    </Button>
                </div>
            )}
        </Form>
    )
}

export default SystemSashGeneral