import React, { useEffect, useState } from 'react'
import InnerLayout from "../../../../layouts/internalLayout"
import { useMessage } from '../../../../context/messageContext'
import { useNavigate } from 'react-router-dom'
import { Oval } from 'react-loader-spinner'
import ProfileLinkingLeft from './left'
import { addProfileLinking, deleteProfileLinking, getAllProfileLinking, getDropDownProfiles, getProfileLinking } from '../../../../services/profileLinkingServices'

import { getAllFrameStyleColletion } from '../../../../services/frameCollectionsService'
import { getAllFrameStyle } from '../../../../services/frameStylesService'

const ProfileLink = () => {

    const accessToken = localStorage.getItem('access_token')
    const [loading, setLoading] = useState(false)
    const [frameCollectionList, setFrameCollectionList] = useState([])
    const [allProfileLinkingListData, setAllProfileLinkingListData] = useState([])
    const [profileLinkingListData, setProfileLinkingListData] = useState([])
    const [profilesListData, setProfilesListData] = useState([])
    const [frameCollectionSelected, setFrameCollectionSelected] = useState('');
    const [selectedFrameStyle, setSelectedFrameStyle] = useState('');
    const [allFrameStyle, setAllFrameStyle] = useState([])
    const [showTableDeleteModal, setTableShowDeleteModal] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false)
    const { setMessage, setMessageType } = useMessage()

    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true)
        getFrameStyleCollection()
    }, [])

    // get all right menu list
    const getFrameStyleCollection = async () => {

        const res = await getAllFrameStyleColletion(accessToken);

        if (res && res.status === 200) {

            if (res?.data?.statusCode === 200) {
                if (res?.data?.entity !== null) {

                    if (res?.data?.entity?.length > 0) {
                        setFrameCollectionList(res?.data?.entity)
                        setFrameCollectionSelected(res?.data?.entity[0])

                    } else {
                        setFrameCollectionList([])
                        setFrameCollectionSelected([])
                    }
                }

                setLoading(false)
            } else {
                setLoading(false)
                setMessage(res?.data.message ? res.data.message : 'Something went wrong');
                setMessageType('error')
            }

        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')

            if (res?.message === "Network Error") {
                navigate('/error/network')
            }
        }
    }

    const getProfilesDropdownServices = async (frameCollectionId) => {

        setLoading(true)

        const res = await getDropDownProfiles(accessToken, frameCollectionId);

        if (res && res.status === 200) {

            if (res?.data?.statusCode === 200) {
                if (res?.data?.entity !== null) {
                    setProfilesListData(res.data.entity)
                    console.log(res.data.entity, "res.data.entity")
                } else {
                    setProfilesListData()
                }

                setLoading(false)
            } else {
                setLoading(false)
                setMessage(res?.data.message ? res.data.message : 'Something went wrong');
                setMessageType('error')
            }

        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')

            if (res?.message === "Network Error") {
                navigate('/error/network')
            }
        }
    }

    const getAllProfileLinkingServices = async (frameStyleId) => {

        setLoading(true)

        const res = await getAllProfileLinking(accessToken, frameStyleId);

        if (res && res.status === 200) {

            if (res?.data?.statusCode === 200) {
                console.log(res?.data?.entity, "table data")
                if (res?.data?.entity !== null) {
                    setAllProfileLinkingListData(res?.data?.entity)
                } else {
                    setAllProfileLinkingListData([])
                }

                setLoading(false)
            } else {
                setAllProfileLinkingListData([])
                setLoading(false)
                setMessage(res?.data.message ? res.data.message : 'Something went wrong');
                setMessageType('error')
            }
        } else {
            setAllProfileLinkingListData([])
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')

            if (res?.message === "Network Error") {
                navigate('/error/network')
            }
        }
    }

    const getProfileLinkingServices = async (ProfileLinkingId) => {

        setLoading(true)

        const res = await getProfileLinking(accessToken, ProfileLinkingId);

        if (res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                if (res?.data?.entity !== null) {
                    setProfileLinkingListData(res?.data?.entity)
                } else {
                    setProfileLinkingListData('')
                }

                setLoading(false)
            } else {
                setLoading(false)
                setMessage(res?.data.message ? res.data.message : 'Something went wrong');
                setMessageType('error')
            }

        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')

            if (res?.message === "Network Error") {
                navigate('/error/network')
            }
        }
    }

    const deleteProfileLinkingServices = async (ProfileLinkingId) => {
        setIsDeleting(true)
        setLoading(true)

        const res = await deleteProfileLinking(accessToken, ProfileLinkingId);

        if (res && res.status === 200) {
            setTableShowDeleteModal(false)
            if (res?.data?.statusCode === 200) {
                setMessage(res?.data.message ? res.data.message : 'Something went wrong');
                getAllProfileLinkingServices(selectedFrameStyle?.id)

                setIsDeleting(false)
                setTimeout(() => {
                    setLoading(false)
                }, 1200);
            } else {
                setLoading(false)
                setIsDeleting(false)
                setMessage(res?.data?.message ? res?.data?.message : 'Something went wrong');
                setMessageType('error')
            }

        } else {
            setLoading(false)
            setIsDeleting(false)
            setMessage(res?.data.message ? res?.data?.message : 'Something went wrong');
            setMessageType('error')

            setTableShowDeleteModal(false)

            if (res?.data?.message === "Network Error") {
                navigate('/error/network')
            }
        }
    }

    const addProfileLinkingServices = async (formDataValues) => {

        setLoading(true)

        const res = await addProfileLinking(accessToken, formDataValues);

        if (res && res.status === 200) {

            if (res?.data?.statusCode == 200) {
                if (res?.data?.message == "Profiles is Linked") {
                    setMessage(res?.data.message);
                    getAllProfileLinkingServices(selectedFrameStyle?.id)
                    setMessageType('success')
                    setLoading(false)
                } else if (res?.data?.message == "This Profile Is Already Linked") {
                    setLoading(false)
                    setMessage(res?.data?.message);
                    setMessageType('success')
                } else {
                    setLoading(false)
                    setMessage(res?.data?.message ? res?.data?.message : 'Something went wrong');
                    getAllProfileLinkingServices(selectedFrameStyle?.id)
                    setMessageType('success')
                }
            } else {
                setMessage(res?.data?.message ? res?.data?.message : 'Please fill all required fields');
                setMessageType('error')
            }

        } else {
            setLoading(false)
            setMessage(res?.message ? res?.message : 'Something went wrong');
            setMessageType('error')

            if (res?.message === "Network Error") {
                navigate('/error/network')
            }
        }
    }

    const getFrameStyleData = async (fetchId) => {

        const res = await getAllFrameStyle(accessToken, fetchId);

        if (res && res.status === 200) {

            if (res?.data?.statusCode === 200) {
                if (res?.data?.entity !== null) {

                    if (res?.data?.entity?.length > 0) {

                        setAllFrameStyle(res?.data?.entity)
                    } else {
                        setAllFrameStyle([])
                    }
                }

                setTimeout(() => {
                    setLoading(false)
                }, 1200);
            } else {
                setLoading(false)
                setMessage(res?.data.message ? res.data.message : 'Something went wrong');
                setMessageType('error')
            }

        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')

            if (res?.message === "Network Error") {
                navigate('/error/network')
            }
        }
    }

    return (
        <>
            {loading && (
                <div className='loader_main full_loader'>
                    <Oval
                        height={50}
                        width={50}
                        color="#44C8F5"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel='oval-loading'
                        secondaryColor="#44C8F5"
                        strokeWidth={2}
                        strokeWidthSecondary={2}
                    />
                </div>
            )}

            <InnerLayout pageTitle={'Profile Linking'} layoutType='full'
                leftContent={<ProfileLinkingLeft frameCollectionList={frameCollectionList} setTableShowDeleteModal={setTableShowDeleteModal} showTableDeleteModal={showTableDeleteModal} isDeleting={isDeleting} getFrameStyleData={getFrameStyleData} setAllFrameStyle={setAllFrameStyle} allFrameStyle={allFrameStyle} selectedFrameStyle={selectedFrameStyle} setSelectedFrameStyle={setSelectedFrameStyle} frameCollectionSelected={frameCollectionSelected} setFrameCollectionSelected ={setFrameCollectionSelected} deleteProfileLinkingServices={deleteProfileLinkingServices} getProfileLinkingServices={getProfileLinkingServices} profileLinkingListData={profileLinkingListData} getAllProfileLinkingServices={getAllProfileLinkingServices} allProfileLinkingListData={allProfileLinkingListData} addProfileLinkingServices={addProfileLinkingServices} profilesListData={profilesListData} getProfilesDropdownServices={getProfilesDropdownServices} setLoading={setLoading} accessToken={accessToken} setMessage={setMessage} setMessageType={setMessageType} />}
                rightContent={''} />

        </>
    )
}

export default ProfileLink;