import React, { useEffect, useState } from 'react';
import { Image } from 'react-bootstrap';
import { Col, Row } from 'reactstrap';
import frameStyleIcon from "../../../../assets/img/images/sq_sm.png";
import HardwareItemList from './HardwareItemList';
import ItemListWrap from './ItemListWrap';

const DocFrameLayout = ({ item, stylesHeader, frameGridView, frame, generalCss, header, pdfData, currency }) => {
    var hardwares = []

    const [hardwareList, setHardwareList] = useState([])
    const [modelImagesList, setModelImagesList] = useState({})

    useEffect(() => {
        if (item?.quotationJson?.handles?.length > 0) {
            hardwares.push(...item?.quotationJson?.handles)
        }
        if (item?.quotationJson?.barHandleOffset?.length > 0) {
            hardwares.push(...item?.quotationJson?.barHandleOffset)
        }
        if (item?.quotationJson?.knocker?.length > 0) {
            hardwares.push(...item?.quotationJson?.knocker)
        }
        if (item?.quotationJson?.spyhole?.length > 0) {
            hardwares.push(...item?.quotationJson?.spyhole)
        }
        if (item?.quotationJson?.escutcheon?.length > 0) {
            hardwares.push(...item?.quotationJson?.escutcheon)
        }
        if (item?.quotationJson?.hinge?.length > 0) {
            hardwares.push(...item?.quotationJson?.hinge)
        }
        if (item?.quotationJson?.numeral?.length > 0) {
            hardwares.push(...item?.quotationJson?.numeral)
        }
        if (item?.quotationJson?.letterPlate?.length > 0) {
            hardwares.push(...item?.quotationJson?.letterPlate)
        }
        if (item?.quotationJson?.trickleVent?.length > 0) {
            hardwares.push(...item?.quotationJson?.trickleVent)
        }
        if (item?.quotationJson?.slideAndTurnHardware?.length > 0) {
            hardwares.push(...item?.quotationJson?.slideAndTurnHardware)
        }

        setHardwareList(hardwares)

        console.log(JSON.parse(item.modelImages), "model images")
        if (JSON.parse(item.modelImages)) {
            setModelImagesList(JSON.parse(item.modelImages))
        } else {
            setModelImagesList({})
        }

    }, [item])

    return (
        <>
            <div style={frameGridView.mainFrame.title}>
                <h5 style={frameGridView.mainFrame.title.head} id={`frame_${item.id}`}>
                    {item?.title}
                </h5>
                {frame?.printFrameProperty?.includes("Sizes") && (
                    <span style={frameGridView.mainFrame.title.sub}>
                        {item?.dimention}
                    </span>
                )}
            </div>

            <div style={{ ...frameGridView.mainFrame, ...generalCss.mb3 }}>

                {/* main image */}
                {frame && frame.externalView && modelImagesList?.externalImage && (
                    <div style={{ ...frameGridView.mainImageWrap, ...frameGridView.externalView }}>
                        {frame && frame.externalView && frame.internalView && (
                            <h6 style={frameGridView.viewName}>External</h6>
                        )}

                        {modelImagesList?.externalImage && (
                            <Image
                                src={modelImagesList?.externalImage}
                                // style={{
                                //     ...frameGridView.mainImageFrame, 
                                //     marginTop: `-${item?.quotationJson?.yMargin}px`,
                                //     marginBottom: `-${item?.quotationJson?.yMargin}px`,
                                //     transform: `scale(${item?.quotationJson?.scaleValue})`,
                                // }} 

                                style={{
                                    ...frameGridView.mainImageFrame,
                                    // marginTop: `-50px`,
                                }}

                                alt="external image"
                            />
                        )}
                    </div>
                )}

                {frame && frame.internalView && modelImagesList?.internalImage && (
                    <div style={frameGridView.mainImageWrap}>
                        <h6 style={frameGridView.viewName}>Internal</h6>

                        <Image
                            src={modelImagesList?.internalImage}
                            style={{
                                ...frameGridView.mainImageFrame,
                            }}
                            alt="intermal image"
                        />
                    </div>
                )}

                {/* {frame && frame.internalView && modelImagesList?.internalImage && (
                    <div style={frameGridView.mainImageWrap}>
                        <h6 style={frameGridView.viewName}>Seq number</h6>

                        <Image
                            src={modelImagesList?.seqImage}
                            style={{
                                ...frameGridView.mainImageFrame,
                            }}
                            alt="seq image"
                        />
                    </div>
                )} */}

                {/* {frame && frame.externalView && frame.internalView && (
                    <div style={frameGridView.mainMultiWrap}>
                        <div style={frameGridView.mainImageWrap}>
                            <Image src={frameImage} style={frameGridView.mainImage} alt="image" />
                        </div>

                        <div style={frameGridView.mainImageWrap}>
                            <Image src={frameImage} style={frameGridView.mainImage} alt="image" />
                        </div>
                    </div>
                )} */}

                {/* <div style={frameGridView.mainFrameLeft}>

                </div> */}
                {frame?.view === "Icons" ? (
                    <div style={frameGridView.mainFrameLeft.icons}>
                        <div style={frameGridView.mainFrameLeft.inner}>
                            
                            {(frame?.printFrameProperty?.includes("Product") || frame?.printFrameProperty?.includes("Frame style") || frame?.printFrameProperty?.includes("Frame colour") || frame?.printFrameProperty?.includes("Frame profile")) && (
                                <div style={stylesHeader.rightBoxWrap}>
                                    <div className='unsetPrintHeight' style={stylesHeader.rightBox}>

                                        {item && item?.frame && (
                                            <>
                                                <h6 style={stylesHeader.rightBox.text}>Frame</h6>
                                                <div style={frameGridView.mainFrameLeft.inner.box}>
                                                    {(frame?.printFrameProperty?.includes("Product") || frame?.printFrameProperty?.includes("Frame style")) && (
                                                        <div style={frameGridView.mainFrameLeft.inner.box.lg.wrap}>
                                                            {item?.frame?.image ? (
                                                                <Image src={`data:image/svg+xml;base64,${item?.frame?.image}`} style={frameGridView.mainImage} alt={item?.frame?.name} />
                                                            ) : (
                                                                <Image src={frameStyleIcon} style={frameGridView.mainImage} alt={item?.frame?.name} />
                                                            )}
                                                        </div>
                                                    )}

                                                    <div style={generalCss.flexCol}>
                                                        {frame?.printFrameProperty?.includes("Product") && (
                                                            <h6 style={frameGridView.mainFrameLeft.inner.box.title.head}>
                                                                {item?.frame?.name}
                                                            </h6>
                                                        )}

                                                        {frame?.printFrameProperty?.includes("Frame style") && (
                                                            <span style={frameGridView.mainFrameLeft.inner.box.title.sub}>
                                                                {item?.frame?.type}
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                            </>
                                        )}

                                        <div className='d-flex gap-2 flex-wrap mb-2'>
                                            {frame?.printFrameProperty?.includes("Frame colour") && (
                                                <>
                                                    {/* External color */}
                                                    {item?.quotationJson && item?.quotationJson?.frameColorExt?.itemId && (
                                                        <div style={frameGridView.mainFrameLeft.inner.box}>
                                                            <div style={frameGridView.mainFrameLeft.inner.box.sm.wrap} className='ball_shadow'>
                                                                {item?.quotationJson?.frameColorExt?.imagePath && (
                                                                    <Image src={item?.quotationJson?.frameColorExt?.imagePath} style={frameGridView.mainImage} alt="image" />
                                                                )}
                                                            </div>
                                                            <div style={generalCss.flexCol}>
                                                                <h6 style={frameGridView.mainFrameLeft.inner.box.title.head}>
                                                                    External
                                                                </h6>

                                                                {item?.quotationJson?.frameColorExt?.name === "Custom RAL" && (
                                                                    <span style={frameGridView.mainFrameLeft.inner.box.title.sub}>
                                                                        {item?.quotationJson?.frameColorExt?.customRALCode} - {item?.quotationJson?.frameColorExt?.customRALName}
                                                                    </span>
                                                                )}

                                                                <span style={frameGridView.mainFrameLeft.inner.box.title.sub}>
                                                                    {item?.quotationJson?.frameColorExt?.name}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    )}

                                                    {/* Internal color */}
                                                    {item?.quotationJson && item?.quotationJson?.frameColorInt?.itemId && (
                                                        <div style={frameGridView.mainFrameLeft.inner.box}>
                                                            <div style={frameGridView.mainFrameLeft.inner.box.sm.wrap} className='ball_shadow'>
                                                                {item?.quotationJson?.frameColorInt?.imagePath && (
                                                                    <Image src={item?.quotationJson?.frameColorInt?.imagePath} style={frameGridView.mainImage} alt="image" />
                                                                )}
                                                            </div>
                                                            <div style={generalCss.flexCol}>
                                                                <h6 style={frameGridView.mainFrameLeft.inner.box.title.head}>
                                                                    Internal
                                                                </h6>

                                                                {item?.quotationJson?.frameColorInt?.name === "Custom RAL" && (
                                                                    <span style={frameGridView.mainFrameLeft.inner.box.title.sub}>
                                                                        {item?.quotationJson?.frameColorInt?.customRALCode} - {item?.quotationJson?.frameColorInt?.customRALName}
                                                                    </span>
                                                                )}

                                                                <span style={frameGridView.mainFrameLeft.inner.box.title.sub}>
                                                                    {item?.quotationJson?.frameColorInt?.name}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    )}
                                                </>
                                            )}

                                            {item && item?.quotationJson?.profileData && item?.quotationJson?.profileData?.length > 0 && (
                                                <>
                                                    {item?.quotationJson?.profileData.map((o, index) => (
                                                        <div style={frame?.printFrameProperty?.includes(o?.frameType) ? { ...frameGridView.mainFrameLeft.inner.box } : { ...generalCss.displayNone }} key={index}>
                                                            <div style={frameGridView.mainFrameLeft.inner.box.sm.wrap}>
                                                                {o.imagePath && (
                                                                    <Image src={o.imagePath} style={frameGridView.mainImage} alt="image" />
                                                                )}
                                                            </div>
                                                            <div style={generalCss.flexCol}>
                                                                <h6 style={frameGridView.mainFrameLeft.inner.box.title.head}>
                                                                    {o.frameType === "Frame profile" && o?.orientation?.includes("Top") && o?.orientation?.includes("Right") && o?.orientation?.includes("Left") && o?.orientation?.includes("Bottom") ? (
                                                                        <>
                                                                            {o?.frameType}
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            {o?.frameType} {o?.orientation ? `(${o?.orientation})` : ''}
                                                                        </>
                                                                    )}
                                                                </h6>
                                                                <span style={frameGridView.mainFrameLeft.inner.box.title.sub}>
                                                                    {o?.name}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </>
                                            )}

                                            {/* {item && item?.quotationJson?.addedFrames && item?.quotationJson?.addedFrames?.length > 0 && (
                                                <>
                                                    {item?.quotationJson?.addedFrames.map((o, index) => (
                                                        <div style={{ ...frameGridView.mainFrameLeft.inner.box }} key={index}>
                                                            <div style={frameGridView.mainFrameLeft.inner.box.sm.wrap}>
                                                                {o.image && (
                                                                    <Image src={o?.image || frameStyleIcon} style={frameGridView.mainImage} alt="image" />
                                                                )}
                                                            </div>
                                                            <div style={generalCss.flexCol}>
                                                                <h6 style={frameGridView.mainFrameLeft.inner.box.title.head}>
                                                                    {o?.frameType}
                                                                </h6>
                                                                <span style={frameGridView.mainFrameLeft.inner.box.title.sub}>
                                                                    {o?.name}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </>
                                            )} */}
                                        </div>

                                        {item && item?.quotationJson?.addedFrames && item?.quotationJson?.addedFrames.length > 0 && (
                                            <>
                                                <h6 style={stylesHeader.rightBox.text}>Added Frames</h6>

                                                <div className='d-flex gap-2 flex-wrap mb-2'>
                                                    {item?.quotationJson?.addedFrames.map((o, index) => (
                                                        <div key={index} style={frameGridView.mainFrameLeft.inner.box}>
                                                            <div style={frameGridView.mainFrameLeft.inner.box.lg.wrap}>
                                                                {o?.imagePath ? (
                                                                    <Image src={`data:image/svg+xml;base64,${o?.imagePath}`} style={frameGridView.mainImage} alt={o.name} />
                                                                ) : (
                                                                    <Image src={frameStyleIcon} style={frameGridView.mainImage} alt={o.name} />
                                                                )}

                                                                {/* <Image src={o?.imagePath || frameStyleIcon} style={frameGridView.mainImage} alt="image" /> */}
                                                            </div>
                                                            <div style={generalCss.flexCol}>
                                                                <h6 style={frameGridView.mainFrameLeft.inner.box.title.head}>
                                                                    {o?.collectionName}
                                                                </h6>
                                                                <span style={frameGridView.mainFrameLeft.inner.box.title.sub}>
                                                                    {o?.name} <b>{o?.count > 1 && `X ${o?.count}`}</b>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            )}

                            <Row className="custom_row_pdf">

                                {/* glazing list */}
                                {frame?.printFrameProperty?.includes("Glazing") && item && item?.quotationJson?.glazingData?.length > 0 && (
                                    <ItemListWrap colVal={item?.quotationJson?.panel?.length > 0 ? '6' : '12'} dataArray={item?.quotationJson?.glazingData} listType="1" heading="Glazing" frameGridView={frameGridView} generalCss={generalCss} stylesHeader={stylesHeader} frame={frame} />
                                )}

                                {/* panels list */}
                                {frame?.printFrameProperty?.includes("Panel") && item && item?.quotationJson?.panel?.length > 0 && (
                                    <ItemListWrap colVal="6" dataArray={item?.quotationJson?.panel} listType="1" heading="Panels" frameGridView={frameGridView} generalCss={generalCss} stylesHeader={stylesHeader} frame={frame} />
                                )}

                                {/* Hardware lists */}
                                {frame?.printFrameProperty?.includes("Hardware") && hardwareList && hardwareList.length > 0 && (
                                    <Col sm={`${frame.minimumWidth < 450 ? '12' : '12'}`} style={stylesHeader.rightBoxWrap}>
                                        <div className='unsetPrintHeight2' style={stylesHeader.rightBox}>
                                            <h6 style={stylesHeader.rightBox.text}>Hardware</h6>

                                            <div className="d-flex gap-2 flex-wrap">
                                                {hardwareList.map((o, index) => (
                                                    <HardwareItemList frameGridView={frameGridView} generalCss={generalCss} o={o} index={index} key={index} />
                                                ))}
                                            </div>
                                        </div>
                                    </Col>
                                )}
                            </Row>

                            {frame && frame.total && (
                                <div style={{ ...frameGridView.mainFrameLeft.inner.box, ...generalCss.maxwauto }}>
                                    <div className='unsetPrintHeight' style={{ ...stylesHeader.rightBox, ...generalCss.w100 }}>
                                        <h6 style={stylesHeader.rightBox.text}>Price</h6>

                                        <table style={stylesHeader.table}>
                                            <tbody>
                                                {item?.quotationJson?.ancillaryData?.length > 0 ? (
                                                    <>
                                                        {/* listing frame and ancillaries prices */}
                                                        <tr>
                                                            <td style={{ ...stylesHeader.table.td, ...stylesHeader.table.borderNone }}>
                                                                <strong>Frame</strong>
                                                            </td>
                                                            <td style={{ ...stylesHeader.table.td, ...stylesHeader.table.borderNone, ...stylesHeader.table.textEnd }}>
                                                                {currency}{item?.quotationJson?.totalFramePrice}
                                                            </td>
                                                        </tr>

                                                        {item && item?.quotationJson?.ancillaryData && item?.quotationJson?.ancillaryData?.length > 0 && item?.quotationJson?.ancillaryData.map((o, index) => (
                                                            <tr key={index}>
                                                                <td style={stylesHeader.table.td}>
                                                                    {o?.name}
                                                                </td>
                                                                <td style={{ ...stylesHeader.table.td, ...stylesHeader.table.textEnd }}>
                                                                    {currency}{o?.price}
                                                                </td>
                                                            </tr>
                                                        ))}

                                                        <tr>
                                                            <td style={{ ...stylesHeader.table.td, ...stylesHeader.table.borderNone }}>
                                                                <strong>Total</strong>
                                                            </td>
                                                            <td style={{ ...stylesHeader.table.td, ...stylesHeader.table.borderNone, ...stylesHeader.table.textEnd }}>
                                                                {currency}{item?.totalPrice?.toFixed(2)}
                                                                {/* {currency}{isNaN(item?.quotationJson?.totalPrice) ? 0 : formatValue(parseFloat(item?.quotationJson?.totalPrice) + parseFloat(item?.quotationJson?.ancillyTotalPrice))} */}
                                                            </td>
                                                        </tr>
                                                    </>
                                                ) : (
                                                    <>
                                                        <tr>
                                                            <td style={{ ...stylesHeader.table.td, ...stylesHeader.table.borderNone }}>
                                                                <strong>Total</strong>
                                                            </td>
                                                            <td style={{ ...stylesHeader.table.td, ...stylesHeader.table.borderNone, ...stylesHeader.table.textEnd }}>
                                                                {currency}{item?.totalPrice?.toFixed(2)}
                                                            </td>
                                                        </tr>
                                                    </>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                ) : (
                    <div style={frameGridView.mainFrameLeft.grid}>
                        <div style={frameGridView.mainFrameLeft.inner}>

                            <Row className='custom_row_pdf'>
                                <Col sm={(!frame.itemised && frame?.total) ? 8 : 12}>
                                    <div className='unsetPrintHeight' style={{ ...stylesHeader.rightBox, ...generalCss.pb0 }}>
                                        <div style={{ ...frameGridView.mainFrameLeft.inner.box, ...generalCss.maxwauto }}>
                                            <table style={stylesHeader.table}>
                                                <tbody>

                                                    {frame?.printFrameProperty?.includes("Product") && (
                                                        <tr>
                                                            <td style={frame?.printFrameProperty?.length !== (0 + 1) ? { ...stylesHeader.table.td, ...stylesHeader.table.gridLineBottom, ...generalCss.mw180 } : { ...stylesHeader.table.td, ...stylesHeader.table.gridLineBottom, ...stylesHeader.table.borderNone }}>
                                                                Frame product
                                                            </td>
                                                            <td style={frame?.printFrameProperty?.length !== (0 + 1) ? { ...stylesHeader.table.td, ...stylesHeader.table.gridLineBottom, ...stylesHeader.table.gridLineLeft } : { ...stylesHeader.table.td, ...stylesHeader.table.gridLineBottom, ...stylesHeader.table.borderNone, ...stylesHeader.table.gridLineLeft }}>
                                                                {item?.frame?.name}
                                                            </td>

                                                            {frame.itemised && (
                                                                <td style={frame?.printFrameProperty?.length !== (0 + 1) ? { ...stylesHeader.table.td, ...stylesHeader.table.gridLineBottom, ...generalCss.mw100, ...stylesHeader.table.textEnd } : { ...stylesHeader.table.td, ...stylesHeader.table.gridLineBottom, ...stylesHeader.table.borderNone, ...stylesHeader.table.textEnd }}>
                                                                </td>
                                                            )}
                                                        </tr>
                                                    )}


                                                    {frame?.printFrameProperty?.includes("Frame style") && (
                                                        <tr>
                                                            <td style={frame?.printFrameProperty?.length !== (0 + 1) ? { ...stylesHeader.table.td, ...stylesHeader.table.gridLineBottom, ...generalCss.mw180 } : { ...stylesHeader.table.td, ...stylesHeader.table.gridLineBottom, ...stylesHeader.table.borderNone }}>
                                                                Frame style
                                                            </td>
                                                            <td style={frame?.printFrameProperty?.length !== (0 + 1) ? { ...stylesHeader.table.td, ...stylesHeader.table.gridLineBottom, ...stylesHeader.table.gridLineLeft } : { ...stylesHeader.table.td, ...stylesHeader.table.gridLineBottom, ...stylesHeader.table.borderNone, ...stylesHeader.table.gridLineLeft }}>
                                                                {item?.quotationJson?.frameStyle?.name}
                                                            </td>

                                                            {frame.itemised && (
                                                                <td style={frame?.printFrameProperty?.length !== (0 + 1) ? { ...stylesHeader.table.td, ...stylesHeader.table.gridLineBottom, ...generalCss.mw100, ...stylesHeader.table.textEnd } : { ...stylesHeader.table.td, ...stylesHeader.table.gridLineBottom, ...stylesHeader.table.borderNone, ...stylesHeader.table.textEnd }}>
                                                                </td>
                                                            )}
                                                        </tr>
                                                    )}

                                                    {frame?.printFrameProperty?.includes("Frame colour") && (
                                                        <tr>
                                                            <td style={frame?.printFrameProperty?.length !== (0 + 1) ? { ...stylesHeader.table.td, ...stylesHeader.table.gridLineBottom, ...generalCss.mw180 } : { ...stylesHeader.table.td, ...stylesHeader.table.gridLineBottom, ...stylesHeader.table.borderNone }}>
                                                                Frame colour
                                                            </td>
                                                            <td style={frame?.printFrameProperty?.length !== (0 + 1) ? { ...stylesHeader.table.td, ...stylesHeader.table.gridLineBottom, ...stylesHeader.table.gridLineLeft } : { ...stylesHeader.table.td, ...stylesHeader.table.gridLineBottom, ...stylesHeader.table.borderNone, ...stylesHeader.table.gridLineLeft }}>
                                                                {item?.quotationJson?.frameColorExt?.name} {' '}

                                                                {item?.quotationJson?.frameColorExt?.name === "Custom RAL" && (
                                                                    <>
                                                                        {item?.quotationJson?.frameColorExt?.customRALCode} - {item?.quotationJson?.frameColorExt?.customRALName}
                                                                    </>
                                                                )}

                                                                {item?.quotationJson?.frameColorExt?.itemId && item?.quotationJson?.frameColorInt?.itemId && ' / '}

                                                                {item?.quotationJson?.frameColorInt?.name} {' '}

                                                                {item?.quotationJson?.frameColorInt?.name === "Custom RAL" && (
                                                                    <>
                                                                        {item?.quotationJson?.frameColorInt?.customRALCode} - {item?.quotationJson?.frameColorInt?.customRALName}
                                                                    </>
                                                                )}
                                                            </td>

                                                            {frame.itemised && (
                                                                <td style={frame?.printFrameProperty?.length !== (0 + 1) ? { ...stylesHeader.table.td, ...stylesHeader.table.gridLineBottom, ...generalCss.mw100, ...stylesHeader.table.textEnd } : { ...stylesHeader.table.td, ...stylesHeader.table.gridLineBottom, ...stylesHeader.table.borderNone, ...stylesHeader.table.textEnd }}>
                                                                    {currency}{item?.quotationJson?.color?.totalPrice?.toFixed(2)}
                                                                </td>
                                                            )}
                                                        </tr>
                                                    )}

                                                    {item && item?.quotationJson?.profileData && item?.quotationJson?.profileData?.length > 0 && (
                                                        <>
                                                            {item?.quotationJson?.profileData.map((o, index) => (
                                                                <tr key={index} style={frame?.printFrameProperty?.includes(o?.frameType) ? { ...generalCss.mb0 } : { ...generalCss.displayNone }}>
                                                                    <td style={frame?.printFrameProperty?.length !== (index + 1) ? { ...stylesHeader.table.td, ...stylesHeader.table.gridLineBottom, ...generalCss.mw180 } : { ...stylesHeader.table.td, ...stylesHeader.table.gridLineBottom, ...stylesHeader.table.borderNone }}>
                                                                        {o.frameType === "Frame profile" && o?.orientation?.includes("Top") && o?.orientation?.includes("Right") && o?.orientation?.includes("Left") && o?.orientation?.includes("Bottom") ? (
                                                                            <>
                                                                                {o?.frameType}
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                {o?.frameType} {o?.orientation ? `(${o?.orientation})` : ''}
                                                                            </>
                                                                        )}
                                                                    </td>
                                                                    <td style={frame?.printFrameProperty?.length !== (index + 1) ? { ...stylesHeader.table.td, ...stylesHeader.table.gridLineBottom, ...stylesHeader.table.gridLineLeft } : { ...stylesHeader.table.td, ...stylesHeader.table.gridLineBottom, ...stylesHeader.table.borderNone, ...stylesHeader.table.gridLineLeft }}>
                                                                        {o?.name}
                                                                    </td>
                                                                    {frame.itemised && (
                                                                        <td style={frame?.printFrameProperty?.length !== (index + 1) ? { ...stylesHeader.table.td, ...stylesHeader.table.gridLineBottom, ...generalCss.mw100, ...stylesHeader.table.textEnd } : { ...stylesHeader.table.td, ...stylesHeader.table.gridLineBottom, ...stylesHeader.table.borderNone, ...stylesHeader.table.textEnd }}>
                                                                            {currency}{o?.totalPrice?.toFixed(2)}
                                                                        </td>
                                                                    )}
                                                                </tr>
                                                            ))}
                                                        </>
                                                    )}

                                                    {/* glazing list */}
                                                    {frame?.printFrameProperty?.includes("Glazing specification") && item && item?.quotationJson?.glazing?.length > 0 && (
                                                        <>
                                                            {item?.quotationJson?.glazing.map((o, index) => (
                                                                <tr key={index} className={`${o?.name == '' ? '' : ''}`}>
                                                                    <td style={frame?.printFrameProperty?.length !== (index + 1) ? { ...stylesHeader.table.td, ...stylesHeader.table.gridLineBottom, ...generalCss.mw180 } : { ...stylesHeader.table.td, ...stylesHeader.table.gridLineBottom, ...stylesHeader.table.borderNone }}>
                                                                        {o?.frameType} {o?.count > 1 && `X${o?.count}`}
                                                                    </td>
                                                                    <td style={frame?.printFrameProperty?.length !== (index + 1) ? { ...stylesHeader.table.td, ...stylesHeader.table.gridLineBottom, ...stylesHeader.table.gridLineLeft } : { ...stylesHeader.table.td, ...stylesHeader.table.gridLineBottom, ...stylesHeader.table.borderNone, ...stylesHeader.table.gridLineLeft }}>
                                                                        {o?.name}
                                                                    </td>
                                                                    {frame.itemised && (
                                                                        <td style={frame?.printFrameProperty?.length !== (index + 1) ? { ...stylesHeader.table.td, ...stylesHeader.table.gridLineBottom, ...generalCss.mw100, ...stylesHeader.table.textEnd } : { ...stylesHeader.table.td, ...stylesHeader.table.gridLineBottom, ...stylesHeader.table.borderNone, ...stylesHeader.table.textEnd }}>
                                                                            {currency}{o?.totalPrice?.toFixed(2)}
                                                                        </td>
                                                                    )}
                                                                </tr>
                                                            ))}
                                                        </>
                                                    )}

                                                    {frame?.printFrameProperty?.includes("Panel") && item?.quotationJson && item?.quotationJson?.panel?.length > 0 && (
                                                        <>
                                                            {item?.quotationJson?.panel.map((o, index) => (
                                                                <tr key={index} className={`${o?.name == '' ? '' : ''}`}>
                                                                    <td style={frame?.printFrameProperty?.length !== (index + 1) ? { ...stylesHeader.table.td, ...stylesHeader.table.gridLineBottom, ...generalCss.mw180 } : { ...stylesHeader.table.td, ...stylesHeader.table.gridLineBottom, ...stylesHeader.table.borderNone }}>
                                                                        {o?.frameType} {o?.count > 1 && `X${o?.count}`}
                                                                    </td>
                                                                    <td style={frame?.printFrameProperty?.length !== (index + 1) ? { ...stylesHeader.table.td, ...stylesHeader.table.gridLineBottom, ...stylesHeader.table.gridLineLeft } : { ...stylesHeader.table.td, ...stylesHeader.table.gridLineBottom, ...stylesHeader.table.borderNone, ...stylesHeader.table.gridLineLeft }}>
                                                                        {o?.name}
                                                                    </td>
                                                                    {frame.itemised && (
                                                                        <td style={frame?.printFrameProperty?.length !== (index + 1) ? { ...stylesHeader.table.td, ...stylesHeader.table.gridLineBottom, ...generalCss.mw100, ...stylesHeader.table.textEnd } : { ...stylesHeader.table.td, ...stylesHeader.table.gridLineBottom, ...stylesHeader.table.borderNone, ...stylesHeader.table.textEnd }}>
                                                                            {currency}{o?.price}
                                                                        </td>
                                                                    )}
                                                                </tr>
                                                            ))}
                                                        </>
                                                    )}

                                                    {frame?.printFrameProperty?.includes("Hardware") && hardwareList && hardwareList?.length > 0 && (
                                                        <>
                                                            {hardwareList.map((o, index) => (
                                                                <tr key={index} className={`${o?.name == '' ? '' : ''}`}>
                                                                    <td style={frame?.printFrameProperty?.length !== (index + 1) ? { ...stylesHeader.table.td, ...stylesHeader.table.gridLineBottom, ...generalCss.mw180, ...generalCss.capitalize } : { ...stylesHeader.table.td, ...stylesHeader.table.gridLineBottom, ...stylesHeader.table.borderNone, ...generalCss.capitalize }}>
                                                                        {o?.type} {o?.count > 1 && `X${o?.count}`}
                                                                    </td>
                                                                    <td style={frame?.printFrameProperty?.length !== (index + 1) ? { ...stylesHeader.table.td, ...stylesHeader.table.gridLineBottom, ...stylesHeader.table.gridLineLeft } : { ...stylesHeader.table.td, ...stylesHeader.table.gridLineBottom, ...stylesHeader.table.borderNone, ...stylesHeader.table.gridLineLeft }}>
                                                                        {o?.name}
                                                                    </td>
                                                                    {frame.itemised && (
                                                                        <td style={frame?.printFrameProperty?.length !== (index + 1) ? { ...stylesHeader.table.td, ...stylesHeader.table.gridLineBottom, ...generalCss.mw100, ...stylesHeader.table.textEnd } : { ...stylesHeader.table.td, ...stylesHeader.table.gridLineBottom, ...stylesHeader.table.borderNone, ...stylesHeader.table.textEnd }}>
                                                                            {currency}{o?.price}
                                                                        </td>
                                                                    )}
                                                                </tr>
                                                            ))}
                                                        </>
                                                    )}

                                                    {item?.quotationJson?.addedFrames && item?.quotationJson?.addedFrames?.length > 0 && (
                                                        <>
                                                            {item?.quotationJson?.addedFrames.map((o, index) => (
                                                                <tr key={index} className={`${o?.name == '' ? '' : ''}`}>
                                                                    <td style={frame?.printFrameProperty?.length !== (index + 1) ? { ...stylesHeader.table.td, ...stylesHeader.table.gridLineBottom, ...generalCss.mw180, ...generalCss.capitalize } : { ...stylesHeader.table.td, ...stylesHeader.table.gridLineBottom, ...stylesHeader.table.borderNone, ...generalCss.capitalize }}>
                                                                        {o?.frameType} {o?.count > 1 && `X${o?.count}`}
                                                                    </td>
                                                                    <td style={frame?.printFrameProperty?.length !== (index + 1) ? { ...stylesHeader.table.td, ...stylesHeader.table.gridLineBottom, ...stylesHeader.table.gridLineLeft } : { ...stylesHeader.table.td, ...stylesHeader.table.gridLineBottom, ...stylesHeader.table.borderNone, ...stylesHeader.table.gridLineLeft }}>
                                                                        {o?.name}
                                                                    </td>
                                                                    {frame.itemised && (
                                                                        <td style={frame?.printFrameProperty?.length !== (index + 1) ? { ...stylesHeader.table.td, ...stylesHeader.table.gridLineBottom, ...generalCss.mw100, ...stylesHeader.table.textEnd } : { ...stylesHeader.table.td, ...stylesHeader.table.gridLineBottom, ...stylesHeader.table.borderNone, ...stylesHeader.table.textEnd }}>
                                                                            {currency}{o?.totalPrice}
                                                                        </td>
                                                                    )}
                                                                </tr>
                                                            ))}
                                                        </>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </Col>

                                {frame && frame.total && (
                                    <Col sm={!frame.itemised ? 4 : 12}>
                                        <div className='unsetPrintHeight' style={stylesHeader.rightBox}>
                                            <h6 style={{ ...stylesHeader.rightBox.text, ...generalCss.mb0 }}>Price</h6>

                                            <table style={stylesHeader.table}>
                                                <tbody>
                                                    {item?.quotationJson?.ancillaryData?.length > 0 ? (
                                                        <>
                                                            {/* listing frame and ancillaries prices */}
                                                            <tr>
                                                                <td style={{ ...stylesHeader.table.td, ...stylesHeader.table.borderNone }}>
                                                                    <strong>Frame</strong>
                                                                </td>
                                                                <td style={{ ...stylesHeader.table.td, ...stylesHeader.table.borderNone, ...stylesHeader.table.textEnd }}>
                                                                    {currency}{item?.quotationJson?.totalFramePrice}
                                                                </td>
                                                            </tr>

                                                            {item && item?.quotationJson?.ancillaryData && item?.quotationJson?.ancillaryData?.length > 0 && item?.quotationJson?.ancillaryData.map((o, index) => (
                                                                <tr key={index}>
                                                                    <td style={stylesHeader.table.td}>
                                                                        {o?.name}
                                                                    </td>
                                                                    <td style={{ ...stylesHeader.table.td, ...stylesHeader.table.textEnd }}>
                                                                        {currency}{o?.price}
                                                                    </td>
                                                                </tr>
                                                            ))}

                                                            <tr>
                                                                <td style={{ ...stylesHeader.table.td, ...stylesHeader.table.borderNone }}>
                                                                    <strong>Total</strong>
                                                                </td>
                                                                <td style={{ ...stylesHeader.table.td, ...stylesHeader.table.borderNone, ...stylesHeader.table.textEnd }}>
                                                                    {currency}{item?.totalPrice?.toFixed(2)}
                                                                    {/* {currency}{isNaN(item?.quotationJson?.totalPrice) ? 0 : formatValue(parseFloat(item?.quotationJson?.totalPrice) + parseFloat(item?.quotationJson?.ancillyTotalPrice))} */}
                                                                </td>
                                                            </tr>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <tr>
                                                                <td style={{ ...stylesHeader.table.td, ...stylesHeader.table.borderNone }}>
                                                                    <strong>Total</strong>
                                                                </td>
                                                                <td style={{ ...stylesHeader.table.td, ...stylesHeader.table.borderNone, ...stylesHeader.table.textEnd }}>
                                                                    {currency}{item?.totalPrice?.toFixed(2)}
                                                                </td>
                                                            </tr>
                                                        </>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </Col>
                                )}
                            </Row>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}

export default DocFrameLayout;