import React, { useState, useEffect } from 'react'
import { Form } from 'react-bootstrap';
import { MdOutlineKeyboardArrowDown, MdOutlineModeEdit } from 'react-icons/md';
import { MenuItem, OutlinedInput, Select } from 'material-ui-core';
import {
    Row,
    Col,
    Button,
    FormGroup,
    Label,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from 'reactstrap';
import { MenuProps, noSelectOptions } from '../utility/data';
import { useRightContext } from '../context/rightMenuContext'
import { allAccessoriesCollection } from '../services/productHardwareServices';
import NumberInput from "../components/NumberInput";
import DataTable from "react-data-table-component";
import { BiSort, BiTrashAlt } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import { useMessage } from '../context/messageContext'
import { useNavigate } from 'react-router-dom'
import { deleteAccessories, getIndividualFrameProductAccessories } from '../services/sysFrameProductsService';
const SystemAccessories = ({ isEditable, setLoading, collectionId, allFrameAccessories, getAllFrameAccessoriesServices, activeTab, accessToken, selectItemDetails, updateItemDetailsServices, handleFormReset }) => {

    const { timestamp } = useRightContext()
    const navigate = useNavigate();
    const { setMessage, setMessageType } = useMessage()
    const [accessoriesCollection, SetAccessoriesCollection] = useState([]);
    const [quantity, setQuantity] = useState('1')
    const [individualAccessoriesId, setIndividualAccessoriesId] = useState('')
    const [deleteIndividualAccessoriesId, setDeleteIndividualAccessoriesId] = useState('')
    const [showTableDeleteModal, setTableShowDeleteModal] = useState(false)
    const [individualAccessories, SetIndividualAccessories] = useState('')
    const [itemId, setItemId] = useState('')
    const [isDeleting, setIsDeleting] = useState(false)
    const [accessoriesId, setAccessoriesId] = useState("")

    console.log(selectItemDetails, "32");

    useEffect(() => {
        if (activeTab === "Accessories") {
            getAccessoriesCollections()
        }
    }, [activeTab])

    // setting all the value for prefilled inputs
    // useEffect(() => {
    //     setItemId(selectItemDetails?.id || '')
    // }, [selectItemDetails])

    // useEffect(() => {
    //     if (collectionId) {
    //         getAllFrameAccessoriesServices(collectionId)
    //     }
    // }, [collectionId])

    useEffect(() => {
        if (individualAccessoriesId) {
            getIndividualAccessoriesCollections(individualAccessoriesId)
        }
    }, [individualAccessoriesId])

    useEffect(() => {
        if (individualAccessories) {
            setItemId(individualAccessories?.id)
            setAccessoriesId(individualAccessories?.accessoriesId)
            setQuantity(individualAccessories?.quantity)
        }
    }, [individualAccessories])

    useEffect(() => {
        if (timestamp) {
            setIndividualAccessoriesId('')
            setItemId('')
            setAccessoriesId('')
            setQuantity('1')
        }

    }, [timestamp])

    // useEffect(() => {
    //     if (accessoriesCollection && accessoriesCollection.length > 0) {
    //         setAccessoriesId(selectItemDetails?.collectionId || accessoriesCollection[0]?.id)
    //     }
    // }, [selectItemDetails, accessoriesCollection])

    const getAccessoriesCollections = async () => {
        const res = await allAccessoriesCollection(accessToken)

        if (res && res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                if (res?.data?.entity !== null) {
                    if (res?.data?.entity?.length > 0) {
                        SetAccessoriesCollection(res?.data?.entity)
                    } else {
                        SetAccessoriesCollection(noSelectOptions)
                    }
                }
            }
        }
    }

    const getIndividualAccessoriesCollections = async (id) => {
        const res = await getIndividualFrameProductAccessories(accessToken, id)

        if (res && res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                if (res?.data?.entity !== null) {
                    if (res?.data?.entity) {
                        SetIndividualAccessories(res?.data?.entity)
                    } else {
                        SetIndividualAccessories('')
                    }
                }
            }
        }
    }

    const handleFormSubmit = () => {
        var formDataValues = {
            'id': itemId ? itemId : '',
            'AccessoriesId': accessoriesId,
            'Quantity': quantity,
            'FrameProductGeneralId': '',
        }

        updateItemDetailsServices(formDataValues, "Accessories")
        if (individualAccessoriesId || timestamp) {
            setItemId('')
            setIndividualAccessoriesId('')
        }
    }

    const getAllAccessories = allFrameAccessories?.map((item) => {
        return {
            accessoriesId: item?.accessoriesId,
            accessoriesDescription: item?.accessoriesDescription,
            quantity: item?.quantity,
            id: item?.id,
            FrameProductGeneralId: item?.FrameProductGeneralId,
        };
    })

    const columns = [
        {
            name: <span>Accessories Name</span>,
            selector: row => row.accessoriesDescription,
        },
        {
            name: <span>Quantity</span>,
            selector: row => row.quantity,
        },
        {
            name: <span>Action</span>,
            cell: (row) => (
                <div className='d-flex gap-2'>
                    <Link onClick={() => setIndividualAccessoriesId(row?.id)}>
                        <MdOutlineModeEdit size={20} />
                    </Link>

                    <Link >
                        <BiTrashAlt size={20} onClick={() => {
                            setTableShowDeleteModal(true);
                            setDeleteIndividualAccessoriesId(row);
                        }} />
                    </Link>
                </div >
            ),
        }
    ]

    const deleteAccessoriesServices = async (Id) => {
        setIsDeleting(true)
        setLoading(true)

        const res = await deleteAccessories(accessToken, Id);
        if (res && res.status === 200) {
            setTableShowDeleteModal(false)
            if (res?.data?.statusCode === 200) {
                setMessage(res?.data.message ? res.data.message : 'Something went wrong');
                setMessageType('success')
                getAllFrameAccessoriesServices(timestamp)
                setIsDeleting(false)
                setTimeout(() => {
                    setLoading(false)
                }, 1200);
            } else {
                setLoading(false)
                setIsDeleting(false)
                setMessage(res?.data?.message ? res?.data?.message : 'Something went wrong');
                setMessageType('error')
            }

        } else {
            setLoading(false)
            setIsDeleting(false)
            setMessage(res?.data.message ? res?.data?.message : 'Something went wrong');
            setMessageType('error')

            setTableShowDeleteModal(false)

            if (res?.data?.message === "Network Error") {
                navigate('/error/network')
            }
        }
    }

    return (
        <Form className='h-100 d-flex flex-column flex-grow-1 justify-content-between'>
            <div className='inside_scroll has_tab has_btns has_head'>
                <Row>
                    <Col xxl="12">
                        <Row>
                            <Col lg="6">
                                <FormGroup className="form-group">
                                    <Label for="collectionSelect">
                                        Accessories
                                    </Label>

                                    <Select
                                        labelId="collectionSelect"
                                        id="collectionSelect"
                                        value={accessoriesId}
                                        label="collectionSelect"
                                        onChange={(e) => { setAccessoriesId(e.target.value) }}
                                        input={<OutlinedInput className='w-100' name='collectionSelect' />}

                                        MenuProps={MenuProps}

                                        IconComponent={() => (
                                            <MdOutlineKeyboardArrowDown size={20} className='me-1 MuiSvgIcon-root MuiSelect-icon' />
                                        )}
                                    >
                                        {accessoriesCollection && accessoriesCollection.map((o, index) => (
                                            <MenuItem key={index} value={o?.id}>
                                                {o.description}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {/* {!accessoriesId ? <span className='text-danger'>select the required field</span> : ""} */}
                                </FormGroup>
                            </Col>
                            <Col sm="6">
                                <FormGroup className="form-group">
                                    <Label for="minimumOrderQuantity">
                                        Quantity
                                    </Label>

                                    <NumberInput className='mb-1' name='Quantity' setValue={setQuantity} value={quantity} />
                                    {!quantity ? <span className='text-danger'>field is required</span> : ""}
                                </FormGroup>
                            </Col>
                        </Row>

                        {isEditable && (
                            <div className='d-flex gap-2 gap-xl-3 main_buttons mb-2'>
                                <Button
                                    color="primary"
                                    size="md"
                                    className='px-4'
                                    onClick={() => handleFormSubmit()}
                                    disabled={!timestamp || !accessoriesId || !quantity}
                                >
                                    {!individualAccessoriesId ? 'Add' : 'Update'}
                                </Button>
                            </div>
                        )}

                        <div className='d-flex flex-column flex-grow-1 justify-content-between border border-1'>
                            <div className='profile_link'>
                                <DataTable
                                    columns={columns}
                                    data={getAllAccessories}
                                    className="custom_datatable clickable"
                                    sortIcon={<BiSort size={18} className="text-muted" />}
                                    // onRowClicked={handleItemClick}
                                    highlightOnHover
                                    // pointerOnHover
                                    pagination
                                    paginationPerPage={10}
                                    // paginationPerPage={window?.innerHeight <= 720 ? 8 : window?.innerWidth <= 1200 ? 10 : 12}
                                    paginationRowsPerPageOptions={[5, 10, 15, 20, 30]}
                                />
                            </div>
                        </div>
                        
                        <Modal isOpen={showTableDeleteModal} toggle={() => setTableShowDeleteModal(false)}>
                            <ModalHeader >
                                Delete Selected Item
                            </ModalHeader>
                            <ModalBody>
                                Are you sure you want delete the <strong>{deleteIndividualAccessoriesId?.accessoriesDescription}</strong> ?
                            </ModalBody>
                            <ModalFooter>
                                <Button color="outline-danger" size="md" disabled={isDeleting} onClick={() => deleteAccessoriesServices(deleteIndividualAccessoriesId?.id)}>Delete</Button>
                                <Button onClick={() => setTableShowDeleteModal(false)} color="primary" size="md">Cancel</Button>
                            </ModalFooter>
                        </Modal>
                    </Col>
                </Row>
            </div>
        </Form>
    )
}

export default SystemAccessories