import React from "react";
import Left from "./left";
import InternalLayout from "../../../../../../layouts/internalLayout";

const PanelProductsPricing = () => {
  return (
    <>
      <InternalLayout
        pageTitle="Colours"
        layoutType="full"
        leftContent={<Left />}
        rightContent={""}
      />
    </>
  );
};

export default PanelProductsPricing;
