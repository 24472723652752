import React, { useState } from "react";
import PricingHeader from "../../../../../../../components/Pricing/PricingHeader";
import { Link } from "react-router-dom";
import { MdOutlineCreate, MdOutlineKeyboardArrowDown } from "react-icons/md";
import { Form, Table } from "react-bootstrap";
import { Button } from "reactstrap";
import { MenuItem, OutlinedInput, Select } from "material-ui-core";
import { MenuProps } from "../../../../../../../utility/data";

const Left = () => {
  const [addMM, setaddMM] = useState("Glideline");
  const [priceMM, setPriceMM] = useState("ColourPricing");
  const [twinTrack, setTwinTrack] = useState("Glideline");
  const [twinTrackPricing, setTwingTrackPricing] = useState("ColourPricing");
  const [tripleTrack, setTripleTrack] = useState("Glideline");
  const [tripleTrackPricing, setTripleTrackPricing] = useState("ColourPricing");
  const [selectedPricing, setSelectedPricing] = useState();
  const [selectedSupplyOnly, setSelectedSupplyOnly] = useState("");
  const [costPrice, setCostPrice] = useState([]);

  const handleaddMM = (e) => {
    const value = e.target.value;
    setaddMM(typeof value === "string" ? value.split(",") : value);
  };

  const handleColourPricingMM = (e) => {
    const value = e.target.value;
    setPriceMM(typeof value === "string" ? value.split(",") : value);
  };

  const handleTwinTrack = (e) => {
    const value = e.target.value;
    setTwinTrack(typeof value === "string" ? value.split(",") : value);
  };

  const handleTwinTrackPricing = (e) => {
    const value = e.target.value;
    setTwingTrackPricing(typeof value === "string" ? value.split(",") : value);
  };

  const handleTripleTrack = (e) => {
    const value = e.target.value;
    setTripleTrack(typeof value === "string" ? value.split(",") : value);
  };

  const handleTripleTrackPricing = (e) => {
    const value = e.target.value;
    setTripleTrackPricing(typeof value === "string" ? value.split(",") : value);
  };

  return (
    <>
      <PricingHeader
        setSelectedSupplyOnly={setSelectedSupplyOnly}
        setSelectedPricing={setSelectedPricing}
        selectedPricing={selectedPricing}
        selectedSupplyOnly={selectedSupplyOnly}
        costPrice={costPrice}
      />
      <div className="d-flex align-items-center mb-4 flex-wrap gap-3 left_header">
        <h5 className="mb-0 text-wrap text-break me-4 main_title">Add-on</h5>
        <Link to={{}}>
          <div className="ms-sm-3">
            <MdOutlineCreate className="text-primary me-1 mb-1" size={18} />
            <span className="text-primary d-none d-sm-inline-block">
              Customize
            </span>
          </div>
        </Link>
      </div>
      <Form className="h-100 d-flex flex-column flex-grow-1 justify-content-between">
        <div className="inside_scroll has_tab has_btns">
          <Table responsive className="w-100 discount_table">
            <thead className="border-bottom">
              <tr>
                <th style={{ width: "60%", minWidth: "60%" }}></th>
                <th style={{ width: "20%", minWidth: "20%" }}>Collection</th>
                <th
                  style={{ width: "20%", minWidth: "20%" }}
                  className="text-start"
                >
                  Price list
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ width: "40%", minWidth: "40%" }}>38mm Add-on</td>
                <td>
                  <Select
                    id="38mm"
                    displayEmpty
                    value={addMM}
                    label={"GLideline"}
                    onChange={handleaddMM}
                    input={<OutlinedInput className="w-100" name="addMM" />}
                    MenuProps={MenuProps}
                    IconComponent={() => (
                      <MdOutlineKeyboardArrowDown
                        size={20}
                        className="me-1 MuiSvgIcon-root MuiSelect-icon"
                      />
                    )}
                  >
                    <MenuItem value={"Glideline"}>Glideline</MenuItem>
                    <MenuItem value={"Glideline2"}>Glideline2</MenuItem>
                    <MenuItem value={"Glideline3"}>Glideline3</MenuItem>
                  </Select>
                </td>
                <td>
                  <Select
                    id="38mmPrice"
                    displayEmpty
                    value={priceMM}
                    label={"Colour Pricing"}
                    onChange={handleColourPricingMM}
                    input={
                      <OutlinedInput className="w-100" name="pricechart" />
                    }
                    MenuProps={MenuProps}
                    IconComponent={() => (
                      <MdOutlineKeyboardArrowDown
                        size={20}
                        className="me-1 MuiSvgIcon-root MuiSelect-icon"
                      />
                    )}
                  >
                    <MenuItem value={"ColourPricing"}>Colour Pricing</MenuItem>
                    <MenuItem value={"ColourPricing2"}>
                      Colour Pricing2
                    </MenuItem>
                    <MenuItem value={"ColourPricing3"}>
                      Colour Pricing3
                    </MenuItem>
                  </Select>
                </td>
              </tr>
              <tr>
                <td style={{ width: "40%", minWidth: "40%" }}>
                  Twin Track 38mm Add-on
                </td>
                <td>
                  <Select
                    id="twinTrack"
                    displayEmpty
                    value={twinTrack}
                    label={"GLideline"}
                    onChange={handleTwinTrack}
                    input={<OutlinedInput className="w-100" name="twinTrack" />}
                    MenuProps={MenuProps}
                    IconComponent={() => (
                      <MdOutlineKeyboardArrowDown
                        size={20}
                        className="me-1 MuiSvgIcon-root MuiSelect-icon"
                      />
                    )}
                  >
                    <MenuItem value={"Glideline"}>Glideline</MenuItem>
                    <MenuItem value={"Glideline2"}>Glideline2</MenuItem>
                    <MenuItem value={"Glideline3"}>Glideline3</MenuItem>
                  </Select>
                </td>
                <td>
                  <Select
                    id="twinTrackPricing"
                    displayEmpty
                    value={twinTrackPricing}
                    label={"Colour Pricing"}
                    onChange={handleTwinTrackPricing}
                    input={
                      <OutlinedInput className="w-100" name="twinTrackPrice" />
                    }
                    MenuProps={MenuProps}
                    IconComponent={() => (
                      <MdOutlineKeyboardArrowDown
                        size={20}
                        className="me-1 MuiSvgIcon-root MuiSelect-icon"
                      />
                    )}
                  >
                    <MenuItem value={"ColourPricing"}>Colour Pricing</MenuItem>
                    <MenuItem value={"ColourPricing2"}>
                      Colour Pricing2
                    </MenuItem>
                    <MenuItem value={"ColourPricing3"}>
                      Colour Pricing3
                    </MenuItem>
                  </Select>
                </td>
              </tr>
              <tr>
                <td style={{ width: "40%", minWidth: "40%" }}>
                  Triple Track 38mm Add-on
                </td>
                <td>
                  <Select
                    id="tripleTrack"
                    displayEmpty
                    value={tripleTrack}
                    label={"GLideline"}
                    onChange={handleTripleTrack}
                    input={
                      <OutlinedInput className="w-100" name="tripleTrack" />
                    }
                    MenuProps={MenuProps}
                    IconComponent={() => (
                      <MdOutlineKeyboardArrowDown
                        size={20}
                        className="me-1 MuiSvgIcon-root MuiSelect-icon"
                      />
                    )}
                  >
                    <MenuItem value={"Glideline"}>Glideline</MenuItem>
                    <MenuItem value={"Glideline2"}>Glideline2</MenuItem>
                    <MenuItem value={"Glideline3"}>Glideline3</MenuItem>
                  </Select>
                </td>
                <td>
                  <Select
                    id="tripleTrackPricing"
                    displayEmpty
                    value={tripleTrackPricing}
                    label={"Colour Pricing"}
                    onChange={handleTripleTrackPricing}
                    input={
                      <OutlinedInput
                        className="w-100"
                        name="tripleTrackPrice"
                      />
                    }
                    MenuProps={MenuProps}
                    IconComponent={() => (
                      <MdOutlineKeyboardArrowDown
                        size={20}
                        className="me-1 MuiSvgIcon-root MuiSelect-icon"
                      />
                    )}
                  >
                    <MenuItem value={"ColourPricing"}>Colour Pricing</MenuItem>
                    <MenuItem value={"ColourPricing2"}>
                      Colour Pricing2
                    </MenuItem>
                    <MenuItem value={"ColourPricing3"}>
                      Colour Pricing3
                    </MenuItem>
                  </Select>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
        <div className="d-flex gap-2 gap-xl-3 main_buttons">
          <Button color="primary" size="md" className="px-4">
            Save Changes
          </Button>

          <Button color="primary" size="md" outline className="px-4">
            Reset
          </Button>
        </div>
      </Form>
    </>
  );
};

export default Left;
