import React from 'react'
import ErrorLayout from './error-layout'
import { BiSolidBuoy } from 'react-icons/bi'

const Error404 = () => {
    return (
        <>
            <ErrorLayout 
                code={<h1 className="display-1 fw-medium">4<BiSolidBuoy className='bx-spin text-primary display-3' />4</h1>} 
                text="Page not found"
                subText="The resource you are looking for might have been removed had its name changed or is temporarily unavailable"
            />
        </>
    )
}

export default Error404