import React, { useState } from 'react'
import { MdOutlineSell } from 'react-icons/md';
import { Form } from 'react-bootstrap';
import {
    Row,
    Col,
    Button,
    FormGroup,
    Label,
    Input,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { useAncillary } from '../../../../../../context/ancillarycontext';
import { useEffect } from 'react';
import Editprices from '../../../../../../components/Editprices';

const AncillaryLeft = ({ uniquePorjectAncillary, updateProjectAncillaryServices, handleFormReset }) => {

    // all the function from the custom hooks context
    const { timestamp, selected, setSelected, handleEdit } = useAncillary()

    const [itemId, setItemId] = useState('')
    const [description, setDescription] = useState('')
    const [addToFrame, setAddToFrame] = useState(false)
    const [discountable, setDiscountable] = useState(false)

    const [descriptionError, setDescriptionError] = useState(false)

    // setting all the value for prefilled inputs
    useEffect(() => {
        setSelected(uniquePorjectAncillary?.description || '')
        setItemId(uniquePorjectAncillary?.id || '')

        setDescription(uniquePorjectAncillary?.description || '')
        setAddToFrame(uniquePorjectAncillary?.addToFrame || false)
        setDiscountable(uniquePorjectAncillary?.discountable || false)

        // console.log(uniquePorjectAncillary, "uniquePorjectAncillary")

        setDescriptionError(false)
    }, [uniquePorjectAncillary])

    // accepting the values form input and updating into the title and the right side list

    const handleDescriptionChange = (e) => {
        const value = e.target.value

        if (e.target.value?.length <= 80) {
            setDescription(value)
            handleEdit(value)
            setSelected(value)
        }

        if (value.length > 80) {
            setDescriptionError(true)
        } else {
            setDescriptionError(false)
        }
    }

    const handleFormSubmit = () => {
        const formDataValues = {
            "id": itemId,
            'description': description,
            'newprojects': addToFrame,
            'discountable': discountable,
        }

        updateProjectAncillaryServices(formDataValues)
    }

    const editNavData = { url: "/pricing/general/project-ancillaries" }

    return (
        <div className='d-flex flex-column h-100'>
            <Form className='h-100 d-flex flex-column flex-grow-1 justify-content-between'>
                <div className='inside_scroll has_btns'>

                    <div className='d-flex align-items-center mb-3 flex-wrap gap-2 left_header'>
                        {selected && (
                            <h5 className="mb-0 text-wrap text-break main_title me-4">{selected}</h5>
                        )}

                        <Editprices data={editNavData} />
                    </div>

                    <Row>
                        <Col xl="6">
                            <FormGroup className="form-group">
                                <Label for="description">
                                    Description
                                </Label>

                                <Input className='mb-1' name='description' onChange={(e) => handleDescriptionChange(e)} value={description} />

                                {(descriptionError && timestamp && itemId) && (
                                    <div className='mt-1 text-muted text-small'>Max 80 characters are allowed</div>
                                )}

                                {!description && itemId && (
                                    <div className='mt-1 text-danger text-small'>Name is required</div>
                                )}
                            </FormGroup>

                            <Row>
                                <Col sm="6">
                                    <FormGroup className="form-group">
                                        <Label for="addNew">
                                            Add to new projects
                                        </Label>
                                        <Form.Check // prettier-ignore
                                            type="switch"
                                            id="addNew"
                                            className='form-switch-lg'
                                            name='addNew'
                                            onChange={() => setAddToFrame(!addToFrame)}
                                            value={addToFrame}
                                            checked={addToFrame}
                                            label={addToFrame ? 'On' : 'Off'}
                                        />
                                    </FormGroup>
                                </Col>

                                <Col sm="6">
                                    <FormGroup className="form-group">
                                        <Label for="discountable">
                                            Discountable
                                        </Label>
                                        <Form.Check // prettier-ignore
                                            type="switch"
                                            id="discountable"
                                            className='form-switch-lg'
                                            name='discountable'
                                            onChange={() => setDiscountable(!discountable)}
                                            value={discountable}
                                            checked={discountable}
                                            label={discountable ? 'On' : 'Off'}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>

                <div className='d-flex gap-2 gap-xl-3 main_buttons'>
                    <Button
                        color="primary"
                        size="md"
                        className='px-4'
                        onClick={() => handleFormSubmit()}
                        disabled={!timestamp || !description}
                    >
                        Save Changes
                    </Button>

                    <Button
                        color="primary"
                        size="md"
                        outline
                        className='px-4'
                        onClick={() => handleFormReset()}
                        disabled={!timestamp}
                    >
                        Reset
                    </Button>
                </div>
            </Form>
        </div>
    )
}

export default AncillaryLeft