import axios from "axios";
import { servicePath } from "../utility/data";

// get all right menu item list based on page/type
export const getRightMenuList = (accessToken) => {

    let config = {
        method: 'GET',
        maxBodyLength: Infinity,
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'multipart/form-data'
        }
    };

    return axios
        .get(`${servicePath}/Product/GetAllProfileJoints`, config)
        .then((res) => {
            return res;
        })
        .catch((error) => {
            return error;
        });
}

// get all right menu item list based on page/type
export const getProfileJointsList = (accessToken) => {

    let config = {
        method: 'GET',
        maxBodyLength: Infinity,
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'multipart/form-data'
        }
    };

    return axios
        .get(`${servicePath}/Product/GetAllProfileJoints`, config)
        .then((res) => {
            return res;
        })
        .catch((error) => {
            return error;
        });
}

// get details of selected item in right menu
export const getProfileJointDetails = (accessToken, id) => {
    const formData = new FormData();
    formData.append("Id", id);

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'multipart/form-data'
        }
    };

    return axios
        .post(`${servicePath}/Product/GetProfileJoint`, formData, config)
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};

// delete selected item
export const deleteRightItem = (accessToken, id) => {
    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    const formData = new FormData()
    formData.append("Id", id)
    return axios
        .post(`${servicePath}/Product/DeleteProfileJoint`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}

// update order of selected item
export const updateOrder = (accessToken, id, direction) => {

    console.log(id, direction, "order test")

    var requestBody = {
        "id": id,
        "sequenceNo": 0,
        "type": direction
    };

    const headers = {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
    }

    return axios
        .post(`${servicePath}/Product/UpdateProfileJointOrder`, requestBody, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}

// update details of selected item
export const updateItemDetails = (accessToken, id, data) => {

    const headers = {
        'accept': 'text/plain',
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data'
    }

    const formData = new FormData();

    formData.append("Id", id);
    formData.append('Name', data.name);
    formData.append('Frame', data.frame);
    formData.append('FrameFirstUnit', data.frameValue1 || 0);
    formData.append('FrameSecondUnit', data.frameValue1 || 0);
    formData.append('FrameThirdUnit', data.frameValue1 || 0);

    formData.append('Bead', data.bead);
    formData.append('BeadUnit', data.beadValue || 0);

    formData.append('Transom', data.transom);
    formData.append('TransomFirstUnit', data.transomValue1 || 0);
    formData.append('TransomSecondUnit', data.transomValue2 || 0);
    formData.append('TransomThirdUnit', data.transomValue3 || 0);

    formData.append('AllowCruciformWelds', data.allowCruciformWelds);
    formData.append('CruciformUnit', data.cruciformUnit || 0);

    return axios
        .post(`${servicePath}/Product/AddUpdateProfileJoint`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};

// add new right menu item based on page/type
export const addNewRightItem = (accessToken, data) => {
    const formData = new FormData();

    formData.append("Id", '');
    formData.append('Name', data);
    formData.append('Frame', '');
    formData.append('FrameFirstUnit', 0);
    formData.append('FrameSecondUnit', 0);
    formData.append('FrameThirdUnit', 0);

    formData.append('Bead', '');
    formData.append('BeadUnit', 0);

    formData.append('Transom', '');
    formData.append('TransomFirstUnit', 0);
    formData.append('TransomSecondUnit', 0);
    formData.append('TransomThirdUnit', 0);

    formData.append('AllowCruciformWelds', false);
    formData.append('cruciformUnit', 0);

    const headers = {
        'accept': 'text/plain',
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data'
    }

    return axios
        .post(`${servicePath}/Product/AddUpdateProfileJoint`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};