import axios from "axios";
import { servicePath } from "../utility/data";

export const getFramesCuttingSheetData = (
  accessToken,
  quoteId,
  frameStyles
) => {
  const formData = new FormData();
  formData.append("QuotationId", quoteId);
  frameStyles?.forEach((element) => {
    formData.append("QuotationFrameId", element);
  });

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  return axios
    .post(`${servicePath}/ThreeJSModel/GetFramesCuttingSheetData`, formData, {
      headers,
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error;
    });
};

export const saveCuttingListDataService = (accessToken, data) => {
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  var mainData = [];

  for (let i = 0; i < data?.length; i++) {
    let item = {
      quotationFrameId: Number(data[i]?.id),
      profileDetails: JSON.stringify(data[i]),
    };
    mainData.push(item);
  }
  return axios
    .post(`${servicePath}/ThreeJSModel/AddUpdateCuttingSheetJson`, mainData, {
      headers,
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error;
    });
};
