import React, { useState } from 'react'
import InnerLayout from "../../../../../layouts/internalLayout"
import Left from './left'
import { useMessage } from '../../../../../context/messageContext'
import { Oval } from 'react-loader-spinner'
import { useParams } from 'react-router-dom'

const List = () => {
  const accessToken = localStorage.getItem('access_token')

  const { setMessage, setMessageType } = useMessage();

  const [loading, setLoading] = useState(false)
  const { quoteId } = useParams()

  return (
    <>
      {loading && (
        <div className="loader_main full_loader">
          <Oval
            height={50}
            width={50}
            color="#44C8F5"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#44C8F5"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      )}

      <Left
        accessToken={accessToken}
        setMessage={setMessage}
        setMessageType={setMessageType}
        loading={loading}
        setLoading={setLoading}
        quoteId={quoteId}
      />
    </>
  );
}

export default List