import React, { useEffect } from "react";
import InternalLayout from "../../../../../layouts/internalLayout";
import Left from "./left";
import { useMessage } from "../../../../../context/messageContext";
import { useState } from "react";
import { Oval } from "react-loader-spinner";
import { useParams } from "react-router-dom";

const GlazingPricing = () => {
  var access_token = localStorage.getItem("access_token");

  const [loading, setLoading] = useState(false);

  const [pageName, setPageName] = useState("");
  const [urlName, setUrlName] = useState("");

  const { setMessage, setMessageType } = useMessage();

  const { palleteId } = useParams();

  useEffect(() => {
    getPageName();
  }, [palleteId]);

  const getPageName = () => {
    if (palleteId == 1) {
      setPageName("Specifications");
    } 
    else if (palleteId == 3) setPageName("Textures");
    else if (palleteId == 4) setPageName("Designs");
  };

  return (
    <>
      {loading && (
        <div className="loader_main full_loader">
          <Oval
            height={50}
            width={50}
            color="#44C8F5"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#44C8F5"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      )}
      <InternalLayout
        pageTitle="Glazing"
        layoutType="full"
        leftContent={
          <Left
            token={access_token}
            setMessage={setMessage}
            setMessageType={setMessageType}
            loading={loading}
            setLoading={setLoading}
            pageName={pageName}
            palleteId={palleteId}
          />
        }
        rightContent={""}
      />
    </>
  );
};

export default GlazingPricing;
