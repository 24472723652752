import axios from "axios";
import { servicePath } from "../utility/data";

// get all right menu item list based on page/type
// export const getRightMenuList = (accessToken, masterId) => {

//     const formData = new FormData()
//     formData.append('StyleMasterId', masterId);

//     let config = {
//         method: 'post',
//         maxBodyLength: Infinity,
//         headers: {
//             'Authorization': `Bearer ${accessToken}`,
//             'Content-Type': 'multipart/form-data'
//         }
//     };

//     return axios
//         .post(`${servicePath}/Hardware/GetAllHardwareStyle`, formData, config)
//         .then((res) => {
//             return res;
//         })
//         .catch((error) => {
//             return error;
//         });
// }

// get details of selected item in right menu
// export const getItemDetails = (accessToken, id) => {

//     const formData = new FormData();
//     formData.append("Id", id);

//     let config = {
//         method: 'post',
//         maxBodyLength: Infinity,
//         headers: {
//             'Authorization': `Bearer ${accessToken}`,
//             'Content-Type': 'multipart/form-data'
//         }
//     };

//     return axios
//         .post(`${servicePath}/Hardware/GetHardwareStyle`, formData, config)
//         .then((res) => {
//             return res;
//         })
//         .catch((error) => {
//             console.log(error);
//             return error;
//         });
// };

// delete selected item
export const deleteRightItem = (accessToken, id) => {

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    const formData = new FormData()
    formData.append("Id", id)

    return axios
        .post(`${servicePath}/Pricing/DeleteAccessories`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}

// update order of selected item
export const updateOrder = (accessToken, id, styleMasterId, direction) => {
    console.log(id, styleMasterId, direction)

    var requestBody = {
        "id": id,
        "sequenceNo": 0,
        "typeText": styleMasterId,
        "type": direction
    };

    const headers = {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
    }

    return axios
        .post(`${servicePath}/Hardware/UpdateHardwareStyleOrder`, requestBody, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}

// update order of selected item
export const updateAccessoryOrder = (accessToken, id, direction) => {
    console.log(id, direction)

    var requestBody = {
        "id": id,
        "sequenceNo": 0,
        "type": direction
    };

    const headers = {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
    }

    return axios
        .post(`${servicePath}/Pricing/UpdateAccessoriessOrder`, requestBody, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}

// update details of selected item
export const updateItemDetails = (accessToken, id, data) => {

    const headers = {
        'accept': 'text/plain',
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data'
    }

    const formData = new FormData();

    formData.append("Id", id || '');
    formData.append('ProductCode', data?.productCode ? data?.productCode : '');
    formData.append('Description', data?.description ? data.description : "Injection cleat");
    formData.append('Supplier', data?.supplier ? data.supplier : 1);
    formData.append('PriceUnit', data?.pricePer ? data.pricePer : 1);
    // formData.append('Price', data?.price ? data.price : 0);
    formData.append('MinimumOrderQuantity', data?.minOrderQuantity ? data.minOrderQuantity : 1);
    formData.append('FilePath', data?.filePath);

    return axios
        .post(`${servicePath}/Pricing/AddUpdateAccessories`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};

// add new right menu item based on page/type
export const addNewRightItem = (accessToken, data) => {
    const formData = new FormData();

    formData.append("Id", '');
    formData.append('ProductCode', data);
    formData.append('Description', `Accessories${data}`);
    formData.append('Supplier', 1);
    formData.append('PriceUnit', 1);
    // formData.append('Price', 0);
    formData.append('MinimumOrderQuantity', 1);

    const headers = {
        'accept': 'text/plain',
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data'
    }

    return axios
        .post(`${servicePath}/Pricing/AddUpdateAccessories`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};


export const getRightMenuList = (accessToken) => {
    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    return axios
        .get(`${servicePath}/Pricing/GetAllAccessories`, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error.response;
        });
}


export const addUpdateAccessories = (accessToken, data) => {
    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    const formData = new FormData();
    formData.append("Id", data?.id ? data?.id : '');
    formData.append('ProductCode', data?.productCode ? data?.productCode : 2448220);
    formData.append('Description', data?.description ? data.description : "Injection cleat");
    formData.append('Supplier', data?.supplier ? data.supplier : 1);
    formData.append('PriceUnit', data?.pricePer ? data.pricePer : 1);
    // formData.append('Price', data?.price ? data.price : 0);
    formData.append('MinimumOrderQuantity', data?.minOrderQuantity ? data.minOrderQuantity : 1);



    return axios
        .post(`${servicePath}/Pricing/AddUpdateAccessories`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error.response;
        });
};


export const getItemDetails = (accessToken, id) => {
    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    const formData = new FormData();
    formData.append("Id", id);



    return axios
        .post(`${servicePath}/Pricing/GetAccessories`, formData, { headers })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log(error);
            return error.response.data;
        });
};

export const getAllSupplier = (accessToken) => {
    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    return axios
        .get(`${servicePath}/Pricing/GetAllSuppliers`, { headers })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log(error);
            return error.response.data;
        });
}


// export const deleteAccessories = (accessToken, id) => {
//     const headers = {
//         'Authorization': `Bearer ${accessToken}`
//     }

//     const formData = new FormData()
//     formData.append("Id", id)

//     return axios
//         .post(`${servicePath}/Pricing/DeleteAccessories`, formData, { headers })
//         .then((res) => {
//             return res?.data;
//         })
//         .catch((error) => {
//             console.log(error);
//             return error.response.data;
//         });
// }