import React from "react";
import { Tooltip } from "material-ui-core";

const StyledTooltip = ({ title, className, children, ...props }) => {

    return (
        <Tooltip
            {...props}
            title={title}
        >
            {children}
        </Tooltip>
    );
}

export default StyledTooltip;