import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { Row, Col, Button, FormGroup, Label, Input } from "reactstrap";
import { Link } from "react-router-dom";
import { MdOutlineSell } from "react-icons/md";
import { useRightContext } from "../../../../../context/rightMenuContext";

const Left = ({
  selectItemDetails,
  updateItemDetailsServices,
  handleFormReset,
}) => {
  const { timestamp, selected, setSelected, handleEdit } = useRightContext();

  const [name, setName] = useState("");
  const [itemId, setItemId] = useState("");
  const [defaultCollection, setDefaultCollection] = useState(false);
  const [nameError, setNameError] = useState(false)

  useEffect(() => {
    setSelected(selectItemDetails?.name || "");

    setName(selectItemDetails?.name || "");
    setItemId(selectItemDetails?.id || "");
    setDefaultCollection(selectItemDetails?.default || false);

    setNameError(false)
  }, [selectItemDetails]);

  const handleNameChange = (e) => {
    const value = e.target.value

    if (e.target.value?.length <= 50) {
      setName(value)
      handleEdit(value)
      setSelected(value)
    }

    if (value.length > 50) {
      setNameError(true)
    } else {
      setNameError(false)
    }
  }

  const handleFormSubmit = () => {
    const formDataValues = {
      id: selectItemDetails?.id,
      name: name,
      default: defaultCollection || false,
    };
    updateItemDetailsServices(formDataValues);
  };

  return (
    <div className="d-flex flex-column h-100">
      <div className="d-flex align-items-center mb-3 flex-wrap gap-2 left_header">
        {selected && (
          <h5 className="mb-0 text-wrap text-break main_title">{selected}</h5>
        )}
      </div>

      <Form className="h-100 d-flex flex-column flex-grow-1 justify-content-between">
        {/* has_tab has_btns has_head -> add respective class of included compoenent in div of class inside_scroll for proper scroll */}

        <div className="inside_scroll has_btns has_head">
          { }
          <Row>
            <Col lg="8" xl="6">
              <FormGroup className="form-group">
                <Label for="name">Name</Label>
                <Input
                  className="mb-1"
                  name="name"
                  value={name}
                  onChange={(e) => handleNameChange(e)}
                />

                {nameError && timestamp && itemId && (
                  <div className="mt-1 text-muted text-small">
                    Max 50 characters are allowed
                  </div>
                )}

                {!name && timestamp && itemId && (
                  <div className="mt-1 text-danger text-small">
                    Name is required
                  </div>
                )}
              </FormGroup>
            </Col>
            <Col lg="6"></Col>
            <Col lg="8" xl="6">
              <FormGroup className="form-group">
                <Label for="mark-ups">Default</Label>
                <Form.Check // prettier-ignore
                  type="switch"
                  id="mark-ups"
                  className="form-switch-lg"
                  onChange={() => {
                    setDefaultCollection(!defaultCollection);
                  }}
                  label={defaultCollection ? "On" : "Off"}
                  disabled={defaultCollection}
                  checked={defaultCollection}
                />
              </FormGroup>
            </Col>
          </Row>
        </div>

        {/* Start: space for buttons at the bottom */}
        <div className="d-flex gap-2 gap-xl-3 main_buttons">
          <Button
            color="primary"
            size="md"
            className="px-4"
            onClick={() => handleFormSubmit()}
            disabled={!timestamp || !name.trim()}
          >
            Save Changes
          </Button>

          <Button
            color="primary"
            size="md"
            outline
            className="px-4"
            disabled={!timestamp}
            onClick={() => handleFormReset()}
          >
            Reset
          </Button>
        </div>
        {/* End: space for buttons at the bottom */}
      </Form>
    </div>
  );
};

export default Left;
