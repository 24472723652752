import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap';
import { MdOutlineKeyboardArrowDown } from 'react-icons/md';
import { FormControl, MenuItem, OutlinedInput, Select } from 'material-ui-core';
import {
    Row,
    Col,
    Button,
    FormGroup,
    Label,
    TabContent,
    TabPane,
    Input,
} from 'reactstrap';
import { MenuProps} from '../../../../../utility/data';
import { getAllFrameStyleColletion, setUpModelService } from "../../../../../services/frameCollectionsService";
import { ToastContainer } from "react-toastify";
import { useMessage } from '../../../../../context/messageContext';
import { useNavigate } from 'react-router-dom';
import DropZone from '../../../../../components/DropZone';
import { getAllFrameStyle } from '../../../../../services/frameStylesService';

const CollectionLeft = ({ accessToken, setLoading }) => {

    const initialValues = {
        frameCollection: "",
        discountableItems: "",
        subaccounts: "",
        markupsAndOthers: "",
        printDocuments: ""
    };

    const [formErrors, setFormErrors] = useState({})
    const [formValues, setFormValues] = useState(initialValues)
    const [uploadedFile, setUploadedFile] = useState('')
    const [filePreview, setFilePreview] = useState('')
    const { setMessage, setMessageType } = useMessage()
    
    const [referenceMinValue, setReferenceMinValue] = useState('')
    const [referenceMaxValue, setReferenceMaxValue] = useState('')

    const [frameCollectionList, setFrameCollectionList] = useState([]);
    const [frameStyleList, setFrameStyleList] = useState([]);

    const [frameCollection, setFrameCollection] = useState('')
    const [frameStyleId, setFrameStyleId] = useState('')

    const navigate = useNavigate();
    
    var acceptFileType = {
        'image/png': ['.glb'],
    }

    useEffect(() => {
        getFrameCollectionsList()
    }, [])

    useEffect(() => {
        if (frameCollectionList.length > 0) {
            setFrameCollection(frameCollectionList[0].id);
        }
    }, [frameCollectionList]);

    useEffect(() => {
        if(frameStyleList && frameStyleList.length > 0) {
            setFrameStyleId(frameStyleList[0].id);
        }
    }, [frameStyleList]);

    const getFrameCollectionsList = async () => {
        
        const res = await getAllFrameStyleColletion(accessToken);
        console.log(71);
        if (res && res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                if (res?.data?.entity !== null) {
                    if (res?.data?.entity?.length > 0) {
                        setFrameCollectionList(res?.data?.entity);
                        // Fetch frame styles for the selected frame collection
                        getFrameStyleList(res?.data?.entity[0]?.id);
                    } else {
                        setFrameCollectionList([]);
                    }
                }
            } else {
                setFrameCollectionList([]);
            }
        } else {
            setFrameCollectionList([]);
        }
    };

    const getFrameStyleList = async (fetchId) => {
        const res = await getAllFrameStyle(accessToken, fetchId);
        if (res && res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                if (res?.data?.entity !== null) {
                    if (res?.data?.entity?.length > 0) {
                        setFrameStyleList(res?.data?.entity);
                        const defaultFrameStyleItems = res?.data?.entity?.map(item => item.name);
                        setFrameStyleId(defaultFrameStyleItems);

                    } else {
                        setFrameStyleList([]);
                    }
                }
            } else {
                setFrameStyleList([]);
            }
        } else {
            setFrameStyleList([]);
        }
    };

    // function to handle frame style
    const handleFrameStyleChange = (e) => {
        const value = e.target.value

        setFrameStyleId(value)
    }


    const handleFrameCollection = (e) => {
        const value = e.target.value;
        setFrameCollection(value);
        getFrameStyleList(value);
    }

    const updateSubAccountsServices = async () => {
        setLoading(true)
        const data = {
            'frameStyleId': frameStyleId,
            'referenceMinValue': referenceMinValue,
            'referenceMaxValue': referenceMaxValue,
            'uploadedFile': uploadedFile
        }
        const res = await setUpModelService(accessToken, data)

        console.log(res, "165");
        if (res?.statusCode === 200) {
            setLoading(false)
            setMessage(res?.message);
            setMessageType('success')
        } else if (res?.statusCode === 404) {
            setLoading(false)
            navigate('/error/404')
        } else {
            setLoading(false)
            setMessage(res.message)
            setMessageType('error')
        }
    }


    return (
        <>
            <ToastContainer />

            <div className='d-flex flex-column h-100'>
                <TabContent className='flex-grow-1 d-flex'>
                    <TabPane className='flex-grow-1 w-100'>
                        <Form className='h-100 d-flex flex-column flex-grow-1 justify-content-between'>
                            <div className='inside_scroll has_btns has_head'>
                                <Row>
                                    <Col xxl="12">
                                        <Row>
                                            <Col md="6">
                                                <FormGroup className="form-group">
                                                    <Label for="CostPrice">
                                                        Frame Collection
                                                    </Label>

                                                    <Select
                                                        labelId="frameCollection-label"
                                                        id="frameCollection"
                                                        value={frameCollection}
                                                        label="frameCollection"
                                                        onChange={handleFrameCollection}
                                                        input={<OutlinedInput className='w-100' name='frameCollection' />}
                                                        MenuProps={MenuProps}
                                                        IconComponent={() => (
                                                            <MdOutlineKeyboardArrowDown size={20} className='me-1 MuiSvgIcon-root MuiSelect-icon' />
                                                        )}
                                                    >
                                                        {frameCollectionList.map((item, index) => {
                                                            return (
                                                                <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
                                                            );
                                                        })}
                                                    </Select>


                                                    {formErrors.frameCollection && (
                                                        <div className='mt-1 text-danger'>{formErrors.frameCollection}</div>
                                                    )}
                                                </FormGroup>
                                            </Col>

                                            <Col md="6">
                                                <FormGroup className="form-group">
                                                    <FormControl fullWidth>
                                                        <Label for="discountableItems">
                                                            Frame Style
                                                        </Label>
                                                        <Select
                                                            labelId="discountedItems-label"
                                                            id="discountedItems"
                                                            value={frameStyleId}
                                                            onChange={handleFrameStyleChange}
                                                            input={<OutlinedInput name='frameStyleId' />}

                                                            MenuProps={MenuProps}

                                                            IconComponent={() => (
                                                                <MdOutlineKeyboardArrowDown size={20} className='me-1 MuiSvgIcon-root MuiSelect-icon' />
                                                            )}
                                                        >
                                                            {frameStyleList?.map((item, index) => (
                                                                <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>

                                                    {formErrors.discountableItems && (
                                                        <div className='mt-1 text-danger'>{formErrors.discountableItems}</div>
                                                    )}
                                                </FormGroup>
                                            </Col>

                                            <Col md="6">
                                                <FormGroup className="form-group">
                                                    <Label for="maxHeight">Reference max value</Label>

                                                    <Input
                                                        name="referenceMaxValue"
                                                        value={referenceMaxValue}
                                                        onChange={(e) => setReferenceMaxValue(e.target.value)}
                                                    />
                                                    {/* {parseInt(maxHeight, 10) < parseInt(minHeight, 10) && (
                                                        <div className='mt-1 text-danger text-small'>Maximum height can't exceed minimum height</div>
                                                    )} */}
                                                </FormGroup>
                                            </Col>
                                            <Col md="6">
                                                <FormGroup className="form-group">
                                                    <Label for="maxHeight">Reference min value</Label>

                                                    <Input
                                                        name="referenceMinValue"
                                                        value={referenceMinValue}
                                                        onChange={(e) => setReferenceMinValue(e.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="6">
                                                <FormGroup className="form-group">
                                                    <Label for="uploadFile">Model File</Label>

                                                    <DropZone
                                                        acceptFileType={acceptFileType}
                                                        multiple={false}
                                                        uploadedFile={uploadedFile}
                                                        setUploadedFile={setUploadedFile}
                                                        filePreview={filePreview}
                                                        setFilePreview={setFilePreview}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                            <div className='d-flex gap-2 gap-xl-3 main_buttons'>
                                <Button
                                    color="primary"
                                    size="md"
                                    className='px-4'
                                    disabled={!frameStyleId || !frameCollection || !uploadedFile || !referenceMaxValue || !referenceMinValue}
                                onClick={() => updateSubAccountsServices()}
                                >
                                    Save Changes
                                </Button>
                            </div>
                        </Form>
                    </TabPane>
                </TabContent>
            </div>
        </>

    )
}

export default CollectionLeft