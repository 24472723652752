import React from "react";
import { Col, Row, Alert } from "reactstrap";
import StyledTooltip from "./StyledTooltip";
import { MdClear, MdInfoOutline } from "react-icons/md";
import { Link } from "react-router-dom";

const UsedInProducts = ({ data, handleRemove, disabled }) => {

    const handleOnRemove = (id, productType) => {
        // console.log(id, "Frame")
        handleRemove(id, productType == "Frame" ? 1 : productType == "Sash" ? 2 : 0)
    }

    return (
        <>
            {data && data.length > 0 ? (
                <Row className="remove_item_wrap mb-4">
                    {data && data?.map((item, index) => (
                        <Col sm="6" xxl="4" key={index}>
                            <div className="d-flex remove_item">
                                {disabled ? (
                                    <Link className="disabled" to={{}}>
                                        <MdClear className="text-muted" />
                                    </Link>
                                ) : (
                                    <StyledTooltip
                                        title="Remove profile from this product"
                                        placement="top"
                                        arrow
                                    >
                                        <Link className="" to={{}} onClick={() => handleOnRemove(item.id, item.productType)}>
                                            <MdClear />
                                        </Link>
                                    </StyledTooltip>
                                )}
                                <span>
                                    <strong>{item.productType || ' '}</strong> <span>{item.usedIn}</span>
                                </span>
                            </div>
                        </Col>
                    ))}
                </Row>
            ) : (
                <div className="d-flex mb-4">
                    <Alert color="warning" className="d-flex gap-2 align-items-center py-2 pe-sm-5">
                        <span>
                            <MdInfoOutline size={18} />
                        </span>
                        <span>Not used in any products</span>
                    </Alert>
                </div>
            )}
        </>
    );
}

export default UsedInProducts;