import React, { useState, useEffect } from 'react'
import { Form } from 'react-bootstrap';
import { MdInfoOutline, MdOutlineKeyboardArrowDown } from 'react-icons/md';
import { Checkbox, FormControl, FormControlLabel, ListItemText, MenuItem, OutlinedInput, Radio, RadioGroup, Select } from 'material-ui-core';
import {
    Row,
    Col,
    Button,
    FormGroup,
    Label,
    Input,
    Alert,
} from 'reactstrap';
import { MenuProps, noSelectOptions } from '../utility/data';
import NumberInputDecimal from './NumberInputDecimal';
import { useRightContext } from '../context/rightMenuContext';
import { allGlazingCollection } from '../services/glazingServices';

const SystemSashGlazing = ({ isEditable, activeTab, accessToken, selectItemDetails, updateItemDetailsServices, handleFormReset }) => {

    const { timestamp } = useRightContext()

    const [specificationsOptions, setSpecificationsOptions] = useState([])
    const [specifications, setSpecifications] = useState([])
    const [frameClearance, setFrameClearance] = useState("0")
    const [gasketClearance, setGasketClearance] = useState("0")

    const [beadRadio, setBeadRadio] = useState('Internal')
    // This will be added if there will be a cutting formula
    const [width, setWidth] = useState('')
    const [height, setHeight] = useState('')

    useEffect(() => {
        if (activeTab === "Glazing") {
            getSpecificationsOptions()
        }
    }, [activeTab])

    // setting all the value for prefilled inputs
    useEffect(() => {
        // console.log(selectItemDetails, "glazing tab")

        // setItemId(selectItemDetails?.id || '')
        setBeadRadio(selectItemDetails?.bead || "Internal")
        setFrameClearance(selectItemDetails?.frameClearance || 0)
        setGasketClearance(selectItemDetails?.gasketClearance || 0)
        // setWidth(selectItemDetails?.width || '')
        // setHeight(selectItemDetails?.height || '')
    }, [selectItemDetails])

    useEffect(() => {
        if (selectItemDetails && selectItemDetails?.specification?.length > 0) {
            const propertiesSelectNames = selectItemDetails?.specification?.map((item) => {
                const proj = specificationsOptions?.find((p) => p.id == item)
                return proj ? proj.name : null
            })?.filter(id => id !== null);

            setSpecifications(propertiesSelectNames);
        } else {
            setSpecifications([]);
        }
    }, [selectItemDetails, specificationsOptions])

    // get product color collection
    const getSpecificationsOptions = async () => {
        const res = await allGlazingCollection("1", accessToken)
        if (res && res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                if (res?.data?.entity !== null) {
                    if (res?.data?.entity?.length > 0) {
                        setSpecificationsOptions(res?.data?.entity)
                    } else {
                        setSpecificationsOptions(noSelectOptions)
                    }
                }
            }
        }
    }

    const handleSpecificationsChange = (e) => {
        const value = e.target.value

        setSpecifications(typeof value === 'string' ? value.split(',') : value)
    }

    const handleBeadSurface = (e) => {
        const value = e.target.value

        setBeadRadio(value)
    }

    const handleFormSubmit = () => {

        const specificationsSelectIds = specifications?.map((name) => {
            const project = specificationsOptions.find((item) => item?.name === name)
            return project ? project?.id : null;
        })

        var formDataValues = {
            'id': selectItemDetails?.id,
            'specifications': specificationsSelectIds,
            'frameClearance': frameClearance,
            'gasketClearance': gasketClearance,
            'beadRadio': beadRadio,
            // This is for new input added can be removed in the future
            // 'width':width,
            // 'height':height
        }

        updateItemDetailsServices(formDataValues, "Glazing")
    }

    return (
        <Form className='h-100 d-flex flex-column flex-grow-1 justify-content-between'>
            <div className='inside_scroll has_tab has_btns has_head'>
                <Row>
                    <Col xxl="12">
                        <Row>
                            <Col lg="6">
                                <FormGroup className="form-group">
                                    <FormControl fullWidth>
                                        <Label for="specifications">
                                            Specifications
                                        </Label>

                                        <Select
                                            labelId="specifications-label"
                                            id="specifications"
                                            multiple
                                            value={specifications}
                                            onChange={(e) => handleSpecificationsChange(e)}
                                            input={<OutlinedInput name='specifications' />}
                                            renderValue={(selected) => selected.join(', ')}
                                            MenuProps={MenuProps}

                                            IconComponent={() => (
                                                <MdOutlineKeyboardArrowDown size={20} className='me-1 MuiSvgIcon-root MuiSelect-icon' />
                                            )}
                                        >
                                            {specificationsOptions && specificationsOptions.map((item, index) => (
                                                <MenuItem key={index} value={item.name} className='check_list'>
                                                    <Checkbox checked={specifications?.indexOf(item.name) > -1} />
                                                    <ListItemText primary={item.name} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </FormGroup>
                            </Col>

                            <Col lg="6">

                            </Col>

                            <Col lg="6">
                                <FormGroup className="form-group">
                                    <Label for="frameClearance">Frame clearance</Label>

                                    <NumberInputDecimal
                                        name="frameClearance"
                                        value={frameClearance}
                                        setValue={setFrameClearance}
                                    />
                                </FormGroup>
                            </Col>

                            <Col lg="6">
                                <FormGroup className="form-group">
                                    <Label for="gasketClearance">Gasket clearance</Label>

                                    <NumberInputDecimal
                                        name="gasketClearance"
                                        value={gasketClearance}
                                        setValue={setGasketClearance}
                                    />
                                </FormGroup>
                            </Col>

                            <Col lg="6">
                                <FormGroup className="form-group">
                                    <Label for="beadRadio">Bead</Label>

                                    <FormControl className="mb-0 w-100">
                                        <RadioGroup
                                            aria-labelledby="feature-label"
                                            name="beadRadio"
                                            className='flex-row flex-wrap custom_x_gap'
                                            value={beadRadio}
                                            onChange={(e) => handleBeadSurface(e)}
                                        >
                                            <FormControlLabel value="Internal" control={<Radio />} label="Internal" />
                                            <FormControlLabel value="External" control={<Radio />} label="External" />
                                        </RadioGroup>
                                    </FormControl>
                                </FormGroup>
                            </Col>

                            <Col lg="6">
                                <Alert color="secondary" className="d-flex gap-2 p-2 w-100">
                                    <span>
                                        <MdInfoOutline size={18} />
                                    </span>
                                    <span>
                                        See specification collections for allowed textured and designs
                                    </span>
                                </Alert>
                            </Col>

                            {/* <Col lg="12" className='mt-4'>
                                <h4 className="main_title sub_title">Cutting formula</h4>
                                <Row>
                                    <Col lg="6">
                                        <FormGroup className="form-group">
                                            <Label for="gasketClearance">Width</Label>

                                            <Input className='mb-0' name='width' value={width} onChange={(e) => setWidth(e.target.value)} />
                                        </FormGroup>
                                    </Col>

                                    <Col lg="6">
                                        <FormGroup className="form-group">
                                            <Label for="gasketClearance">Height</Label>

                                            <Input className='mb-0' name='height' value={height} onChange={(e) => setHeight(e.target.value)} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Col>  */}
                        </Row>
                    </Col>
                </Row>
            </div>

            {isEditable && (
                <div className='d-flex gap-2 gap-xl-3 main_buttons'>
                    <Button
                        color="primary"
                        size="md"
                        className='px-4'
                        onClick={() => handleFormSubmit()}
                        disabled={!timestamp}
                    >
                        Save Changes
                    </Button>

                    <Button
                        color="primary"
                        size="md"
                        outline
                        className='px-4'
                        onClick={() => handleFormReset()}
                        disabled={!timestamp}
                    >
                        Reset
                    </Button>
                </div>
            )}
        </Form>
    )
}

export default SystemSashGlazing