import React, { useEffect, useState } from "react";
import { BiArrowBack } from "react-icons/bi";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  NavLink,
} from "reactstrap";
import {
  setUpdateFormulaService,
  setUpdateSashHangingFormulaService,
} from "../services/ManufacturingServices";
import { useMessage } from "../context/messageContext";
import ManufacturingAdditionalProfile from "./ManufacturingAdditionalProfile";
import { IoMdLink } from "react-icons/io";
import { getSizeTagsService } from "../services/frameStylesService";
import { useNavigate } from "react-router-dom";
import { getOrientation } from "../utility/helper";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";




const ManufacturingEditComp = ({
  setIsEdit,
  frameStyleProdId,
  referencePoint,
  currentItem,
  accessToken,
  setLoading,
  getData,
  getSashHangingData,
  additionalProfileList,
  collectionId,
  frameProductDetails
}) => {
  
  const [heightFormula, setHeightFormula] = useState("");
  const [widthFormula, setWidthFormula] = useState("");
  const [open, setOpen] = useState("0");  // copy
  const [dropdownOpen, setDropdownOpen] = useState(false);  // copy
  const [isAdditionalProfileEdit, setIsAdditionalProfileEdit] = useState(false);    // copy
  const [toggleTagSizeData, setToggleTagSizeData] = useState(false);
  const [tagData, setTagData] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [formulaWithIds, setFormulaWithIds] = useState([]);

  const { setMessage, setMessageType } = useMessage();
  const navigate = useNavigate();

  const access_token = localStorage.getItem("access_token");






  // useEffect(() => {
  //   if (currentItem !== null) {
  //     const isSashTab = activeTab === "Sash";
  //     const hasHangingName = currentItem?.hasOwnProperty("hangingName");

  //     const updateFormula = (type, formula, setFormula) => {
  //       if (formula === null && isSashTab && hasHangingName) {
  //         getDefaultCuttingFormula(type);
  //       } else {
  //         setFormula(formula === null ? "" : formula);
  //       }
  //     };

  //     updateFormula("height", currentItem?.heightFormula, setHeightFormula);
  //     updateFormula("width", currentItem?.widthFormula, setWidthFormula);
  //   }
  // }, [currentItem, activeTab]);

  // console.log("idds", collectionId, frameStyleProdId?.id);

  useEffect(() => {
    if (currentItem !== null) {
      // setWidthFormula(
      //   currentItem?.widthFormula !== null
      //     ? currentItem?.widthFormula
      //     : `P${referencePoint?.index + 1}`
      // );
      setSelectedIds(
        currentItem?.tagsIds?.length > 0 ? currentItem?.tagsIds : []
      );
    }
  }, [currentItem]);

  // This will create the array which will generate the persisting formula on the basis of ids and this if for default when the data is not saved
  useEffect(() => {
    if (
      tagData &&
      selectedIds &&
      selectedIds?.length > 0 &&
      tagData?.length > 0
    ) {
      let temp = [];
      selectedIds?.forEach((item) => {
        let element = tagData?.find((ele) => ele?.id === item);
        if (element) {
          let obj = {
            id: element?.id,
            length: element?.length,
          };
          temp.push(obj);
        }
      });
      setFormulaWithIds(temp);
    } else {
      if (!referencePoint?.data?.name?.includes("Frame")) {
        const index = referencePoint?.data?.name
          ? Math.floor(referencePoint.index / 4)
          : referencePoint?.index;
        const formula = referencePoint?.data?.name?.includes("verticalBar")
          ? "H"
          : `P${index + 1}`;

        setWidthFormula(formula);
      } else {
        if (
          referencePoint?.data?.name?.includes("Top") ||
          referencePoint?.data?.name?.includes("Bottom")
        ) {
          setWidthFormula(`W`);
        } else {
          setWidthFormula("H");
        }
      }
    }
  }, [selectedIds, tagData]);

  useEffect(() => {
    if (open === "0" || open === "1") {
      setIsAdditionalProfileEdit(false);
    }
  }, [open]);

  useEffect(() => {
    if (frameProductDetails?.id) {
      getTagNames(frameProductDetails?.id);
    }
  }, [frameProductDetails]);

  // This will generate the formula everytime on the basis of selected ids when the data is saved
  useEffect(() => {
    if (formulaWithIds && formulaWithIds?.length > 0) {
      let tempFormula = "";
      if (!referencePoint?.data?.name?.includes("Frame")) {
        const index = referencePoint?.data?.name
          ? Math.floor(referencePoint.index / 4)
          : referencePoint.index;
        tempFormula = referencePoint?.data?.name?.includes("verticalBar")
          ? "H"
          : `P${index + 1}`;
      } else {
        if (
          referencePoint?.data?.name?.includes("Top") ||
          referencePoint?.data?.name?.includes("Bottom")
        ) {
          tempFormula = `W`;
        } else {
          tempFormula = "H";
        }
      }
      formulaWithIds?.forEach((item) => {
        tempFormula += `-${item?.length}`;
      });
      setWidthFormula(tempFormula);
    } else if (formulaWithIds?.length === 0) {
      setWidthFormula(`P${referencePoint?.index + 1}`);
    }
  }, [formulaWithIds]);

  const toggleAccordion = (id) => {
    if (open === id) {
      setOpen("0");
    } else {
      setOpen(id);
    }
  };

  const handleAccordionClick = (e) => {
    e.stopPropagation();
  };

  const toggleDropDown = (e) => {
    setDropdownOpen(!dropdownOpen);
    e.stopPropagation();
  };

  // const handleHeightFormula = (e) => {
  //   const value = e.target.value;
  //   setHeightFormula(value);
  // };

  // const handleWidthFormula = (e) => {
  //   const value = e.target.value;
  //   setWidthFormula(value);
  // };

  const saveFormula = async () => {
    setLoading(true);
    console.log(selectedIds, "ids");
    const data = {
      id: 0,
      frameStyleId: frameStyleProdId?.id,
      referencePoint: referencePoint?.index,
      heightFormula,
      widthFormula,
      status: currentItem?.status,
      tagIds: selectedIds,
      orientation:extractOrientation(referencePoint?.data?.name)
    };

    const isHanging = currentItem?.hasOwnProperty("hangingName");
    const service = isHanging
      ? setUpdateSashHangingFormulaService
      : setUpdateFormulaService;
    data[isHanging ? "sashHangingId" : "frameProfileId"] = currentItem?.id;

    try {
      const res = await service(accessToken, data);
      if (res && res?.data?.statusCode === 200) {
        setMessage(res?.data?.message);
        setMessageType("success");
      }
    } catch (error) {
      setMessage("An error occurred");
      setMessageType("error");
    } finally {
      setLoading(false);
      (isHanging ? getSashHangingData : getData)(referencePoint?.index);
    }
  };


  

  const getTagNames = async (frameCollectionId) => {
    const res = await getSizeTagsService(
      access_token,
      frameCollectionId,
    );
    if (res?.data?.statusCode === 200) {
      if (res?.data?.entity?.length > 0) {
        setTagData(res?.data?.entity);
      } else {
        setTagData([]);
      }
    } else {
      setMessageType("error");
      setMessage(
        res?.data?.message ? res?.data?.message : "Something went wrong !"
      );
      if (res?.response?.status === 401) {
        navigate("/unauthorized");
        localStorage.removeItem("access_token");
      }
      if (res?.response?.status == 404) {
        navigate("/error/503");
      }
      if (res?.message === "Network Error") {
        navigate("/error/network");
      }
    }
  };

  const handleCuttingFormula = (item) => {
    if (!selectedIds?.includes(item?.id)) {
      setSelectedIds((prevIds) => {
        return [...prevIds, item?.id];
      });
      let obj = {
        id: item?.id,
        length: item?.length,
      };
      setFormulaWithIds((prevIds) => {
        return [...prevIds, obj];
      });
    } else {
      const filteredItems = selectedIds?.filter((ele) => ele !== item?.id);
      const filterItemsWithLenth = formulaWithIds?.filter(
        (ele) => ele?.id !== item?.id
      );
      setSelectedIds(filteredItems);
      setFormulaWithIds(filterItemsWithLenth);
    }
  };

  const extractOrientation=(inputString)=> {
    // Define an array of possible orientations
    const orientations = ["Left", "Right", "Top", "Bottom"];
    
    // Iterate over the orientations array and check if the input string ends with one of them
    for (let orientation of orientations) {
      if (inputString.endsWith(orientation)) {
        return orientation;
      }
    }
    
    // If no orientation is found, return null or some default value
    return null;
  }

  return (
    <div className="d-flex flex-column text-dark pe-3 manufacturing_scroll">
      {!isAdditionalProfileEdit && (
        <>
          <NavLink onClick={() => setIsEdit(false)} className="text-link mb-3">
            <BiArrowBack size={20} />
            <span className="ms-1">Back</span>
          </NavLink>
          <Accordion
            open={open}
            toggle={toggleAccordion}
            onClick={(e) => handleAccordionClick(e)}
            tag="div"
          >
            <AccordionItem tag="div">
              <AccordionHeader targetId="1">
                Cutting Formula
                <Dropdown
                  isOpen={dropdownOpen}
                  toggle={toggleDropDown}
                  className="ms-auto"
                >
                  <DropdownToggle
                    color="none"
                    className="border-0 p-0 py-1 d-block text-muted"
                    tag="span"
                  ></DropdownToggle>
                </Dropdown>
              </AccordionHeader>

              <AccordionBody accordionId="1">
                <div className="text-dark">
                  <div className="cutting_section d-flex align-items-center gap-3">
                    {(referencePoint?.data?.name?.includes("horizontal") ||
                      referencePoint?.data?.name?.includes("vertical") ||
                      referencePoint?.data?.name === "" ||
                      referencePoint?.data?.name?.includes("Frame")) && (
                      <>
                        <FormGroup className="mb-0 d-flex align-items-center gap-1 w-75">
                          <Label for="width" className="mb-0 mini_text">
                            Length
                          </Label>
                          <Input
                            name="width"
                            disabled={true}
                            // onChange={handleWidthFormula}
                            value={widthFormula}
                            id="width"
                          />
                        </FormGroup>
                        <FormGroup className="mb-0 d-flex align-items-center ms-3">
                          <Label
                            for="tagsName"
                            className="mb-0 mini-text"
                          ></Label>
                          <Dropdown
                            isOpen={toggleTagSizeData}
                            toggle={() => {
                              setToggleTagSizeData(!toggleTagSizeData);
                            }}
                          >
                            <DropdownToggle
                              color="none"
                              className="border-0 p-0"
                            >
                              <div className="d-flex align-items-center">
                                <span className="fw-semibold">
                                  <Button color="outline-primary">
                                    <IoMdLink size={22} />
                                  </Button>
                                </span>
                              </div>
                            </DropdownToggle>
                            <DropdownMenu
                              className={`${
                                window?.innerHeight < 900 ? "has_scroll" : ""
                              } body_dropdown hardware_list`}
                              container="body"
                            >
                              {tagData &&
                                tagData?.length > 0 &&
                                tagData?.map((item, index) => {
                                  return (
                                    <DropdownItem
                                      onClick={() => {
                                        handleCuttingFormula(item);
                                      }}
                                      key={index}
                                    >
                                      {/* {item?.name} */}
                                      <div className="d-flex align-items-center gap-5">
                                        <span className="w-100" name="checkTag">
                                          {item?.name}
                                        </span>
                                        <div className="form-check mb-0 ms-1">
                                          <Input
                                            className="check_box"
                                            type="checkbox"
                                            id="checkTag"
                                            checked={selectedIds?.includes(
                                              item?.id
                                            )}
                                            readOnly={true}
                                          />
                                        </div>
                                      </div>
                                    </DropdownItem>
                                  );
                                })}
                            </DropdownMenu>
                          </Dropdown>
                        </FormGroup>
                      </>
                    )}
                  </div>
                </div>
                {open === "1" && (
                  <div className="d-flex gap-2 gap-xl-3 main_buttons w-100 mt-3 mb-3">
                    <Button
                      color="primary"
                      size="md"
                      // disabled={open === "1" && (!heightFormula || !widthFormula)}
                      className="px-4"
                      onClick={() => {
                        saveFormula();
                      }}
                    >
                      Save
                    </Button>

                    <Button
                      color="primary"
                      size="md"
                      outline
                      className="px-4"
                      onClick={() => {
                        setIsEdit(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                )}
              </AccordionBody>
            </AccordionItem>
          </Accordion>
        </>
      )}





      {/* <Accordion
        open={open}
        toggle={toggleAccordion}
        onClick={(e) => handleAccordionClick(e)}
        tag="div"
      >
        <AccordionItem tag="div">
          {!isAdditionalProfileEdit &&
            referencePoint?.data?.name !== "" &&
            referencePoint?.data?.name !== "GlassPanel034" && (
              <AccordionHeader targetId="2">
                Additional Profiles
                <Dropdown
                  isOpen={dropdownOpen}
                  toggle={toggleDropDown}
                  className="ms-auto"
                >
                  <DropdownToggle
                    color="none"
                    className="border-0 p-0 py-1 d-block text-muted"
                    tag="span"
                  ></DropdownToggle>
                </Dropdown>
              </AccordionHeader>
            )}
          <AccordionBody accordionId="2">
            <ManufacturingAdditionalProfile
              additionalProfileList={additionalProfileList}
              isAdditionalProfileEdit={isAdditionalProfileEdit}
              setIsAdditionalProfileEdit={setIsAdditionalProfileEdit}
              setIsEdit={setIsEdit}
              setOpen={setOpen}
              referencePoint={referencePoint?.index}
              referencePointName={referencePoint?.data?.name}
              frameStyleId={frameStyleProdId?.id}
              accessToken={accessToken}
              currentItem={currentItem}
              getData={getData}
            />
          </AccordionBody>
        </AccordionItem>
      </Accordion> */}
    </div>
  );
};

export default ManufacturingEditComp;
