import React, { useState } from "react";
import dummyProfile from "../assets/img/dummy-user.png"
import logo from "../assets/img/logo/logo.png"
import smallLogo from "../assets/img/logo/small-logo.png"
import { Link } from "react-router-dom";
import { Image } from "react-bootstrap";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import { MdKeyboardArrowDown, MdOutlinePowerSettingsNew } from "react-icons/md";
import { Oval } from "react-loader-spinner";
import { logOutOrbit, srcIconCondition, srcLogoCondition } from "../services/commonServices";

const Header = ({ userData, accessToken }) => {

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [loading, setLoading] = useState(false)

    const toggleTabDropdown = () => setDropdownOpen((prevState) => !prevState);

    const handleLogout = async () => {
        setLoading(true);

        // var company_data = JSON.parse(localStorage.getItem("user_data"))

        const res = await logOutOrbit(accessToken)

        console.log(res, "res")

        if (res?.status === 200) {
            localStorage.removeItem("access_token")
            localStorage.removeItem("user_data")
    
            setTimeout(() => {
                if(userData && userData?.role === "Super Admin") {
                    window.location.href = `https://${userData?.website_url}/admin/logout`
                } else {
                    window.location.href = `https://${userData?.website_url}/logout`
                }
                setLoading(false)
            }, 1000);
        }
    }

    return (
        <>
            {loading && (
                <div className='loader_main full_loader'>
                    <Oval
                        height={50}
                        width={50}
                        color="#44C8F5"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel='oval-loading'
                        secondaryColor="#44C8F5"
                        strokeWidth={2}
                        strokeWidthSecondary={2}
                    />
                </div>
            )}

            <header id="page-topbar">
                <div className="navbar-header">
                    <div className="d-flex align-items-center me-3">
                        {/* <div className="navbar-brand-box">
                            <Link to="/" className="logo dash_small_logo">
                                <span className="logo-lg">
                                    {userData && userData?.role ? (
                                        <Image src={userData && srcIconCondition(userData, smallLogo)} alt="" height="55" />
                                    ) : (
                                        <Image src={smallLogo} alt="" height="55" />
                                    )}
                                </span>
                            </Link>
                        </div> */}

                        <Link to="/" className="logo_large mt-0">
                            {userData && userData?.role ? (
                                <Image src={userData && srcLogoCondition(userData, logo)} alt="logo" />
                            ) : (
                                <Image src={logo} alt="logo" />
                            )}
                        </Link>
                    </div>

                    {/* <div className="d-flex gap-1">
                        <div className="d-flex align-items-center">
                            <Image className="rounded-circle header-profile-user" src={userData && userData.profile_image ? userData.profile_image : dummyProfile} alt="User Avatar" />

                            <span className="ms-2 text-black fw-medium">{userData && userData.user_name ? userData.user_name : 'User Name'}</span>
                        </div>
                    </div> */}

                    <Dropdown isOpen={dropdownOpen} toggle={toggleTabDropdown}>
                        <DropdownToggle color='none' className='border-0 p-0'>
                            <div className="d-flex align-items-center">
                                <Image className="rounded-circle header-profile-user" src={userData && userData.profile_image ? userData.profile_image : dummyProfile} alt="User Avatar" />
                                <span className="ms-2 text-black fw-medium">
                                    <span className="text-truncate">
                                        {userData && userData.user_name ? userData.user_name : 'User Name'}
                                    </span>
                                    <MdKeyboardArrowDown className="ms-1" size={13} />
                                </span>
                            </div>
                        </DropdownToggle>

                        <DropdownMenu>
                            <DropdownItem className="text-danger" onClick={() => handleLogout()}>
                                <MdOutlinePowerSettingsNew className="me-2" size={16} />
                                Logout
                            </DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </div>
            </header>
        </>
    );
}

export default Header;