import React from "react";
import RotatingCube from "../3DComponents/RotatingCube";

const FrameDesign = ({ item, handleFrameOptions, type }) => {
  return (
    <div
      className="position-relative d-flex align-items-center"
      onClick={() => handleFrameOptions(item, "1")}
    >
      <div className={`right_item w-100 cursor-pointer`}>
        <span className="me-2 ball_shadow" id={`ball_id_${item?.type}`}>
          <RotatingCube
            elements={item?.model}
            roughnessVal={item?.roughness}
            colorVal={item?.hex}
            metalNessVal={item?.metalness}
            bumpiness={item?.bumpiness}
            textureVal={item?.model}
          // capturedAccordion={capturedAccordion}
          // onImageCapture={handleCaptureComplete}
          />
        </span>

        {item && (
          <div className="right_title">
            <span className="text-truncate text-dark">{type}</span>
            <span className="text-truncate">{item?.name}</span>
            {item && item.customRALCode && (
              <p className="text-truncate text-small mb-0">
                RAL{item?.customRALCode} - {item?.customRALName}
              </p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default FrameDesign;
