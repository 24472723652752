import React from "react";
import InternalLayout from "../../../../../layouts/internalLayout";
import Left from "./left";
import { Oval } from "react-loader-spinner";
import { useMessage } from "../../../../../context/messageContext";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { Card, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'
import { getAllFrameCollection } from "../../../../../services/3dModelServices";
import { MdKeyboardArrowDown } from "react-icons/md";
import CustomizedButton from "../../../../../components/CustomizedButton";
import { useRightContext } from "../../../../../context/rightMenuContext";
import NoDataCard from "../../../../../components/NoDataCard";

const PricingFrameStyleFrameProducts = () => {

  const { setMainMenuActive, setSubMenuActive, setSubMenu2Active } = useRightContext()
  const accessToken = localStorage.getItem('access_token')

  const [headerDropdown, setHeaderDropdown] = useState(false);
  const toggleTabDropdown = () => setHeaderDropdown((prevState) => !prevState);
  const [mainDropdownList, setMainDropdownList] = useState([])
  const [frameCollection, setFrameCollection] = useState('')
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  const { setMessage, setMessageType } = useMessage();

  const location = useLocation();

  const collectionId = location.state ? location.state.data : null

  useEffect(() => {
    getAllFrameService()

    setMainMenuActive("Pricing")
    setSubMenuActive("Systems")
    setSubMenu2Active("")
  }, []);

  useEffect(() => {
    if (mainDropdownList && mainDropdownList.length > 0) {
      if (collectionId) {
        const collectionItem = mainDropdownList?.find((p) => p.id == collectionId)

        if (collectionItem && collectionItem.id) {
          setFrameCollection(collectionItem)
        }
      } else {
        setFrameCollection(mainDropdownList[0])
      }
    }
  }, [mainDropdownList]);

  const getAllFrameService = async () => {

    const res = await getAllFrameCollection(accessToken)

    // console.log(res, "get data")

    if (res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        setMainDropdownList(res?.data?.entity)
      } else {
        setLoading(false)
        setMessage(res?.data?.message);
        setMessageType('error')
      }
    } else {
      setMessage(res?.message ? res.message : 'Something went wrong');
      setMessageType('error')
      if (res?.message === "Network Error") {
        navigate('/error/network')
      }
    }
  }

  const handleItemSelect = (value) => {
    setFrameCollection(value)
  }

  const data1 = { url: `/product/frame-styles/list`, stateValue: frameCollection?.id }

  return (
    <>
      {loading && (
        <div className="loader_main full_loader">
          <Oval
            height={50}
            width={50}
            color="#44C8F5"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#44C8F5"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      )}
      {mainDropdownList && mainDropdownList.length > 0 ? (
        <InternalLayout
          pageTitle=""
          layoutType="full"
          leftContent={
            <Left
              token={accessToken}
              setMessage={setMessage}
              setMessageType={setMessageType}
              loading={loading}
              setLoading={setLoading}
              collectionId={frameCollection?.id}
              pageName={frameCollection?.name}
            />
          }
          rightContent={""}
          filterButton={
            <>
              <Dropdown
                isOpen={headerDropdown}
                toggle={toggleTabDropdown}
              >
                <DropdownToggle color="none" className="border-0 p-0">
                  <div className="d-flex align-items-center">
                    <span className="fw-semibold fs-5">
                      {frameCollection && frameCollection?.name}
                      <MdKeyboardArrowDown className="ms-1" size={20} />
                    </span>
                  </div>
                </DropdownToggle>
  
                <DropdownMenu className='full_height'>
                  {mainDropdownList?.map((item, index) => {
                    return (
                      <DropdownItem
                        onClick={() => handleItemSelect(item)}
                        key={index}
                        disabled={item?.id == frameCollection?.id}
                        className={`${item?.id == frameCollection?.id ? 'text-primary fw-medium' : ''}`}
                      >
                        {item?.name}
                      </DropdownItem>
                    );
                  })}
                </DropdownMenu>
              </Dropdown>
              <CustomizedButton data={data1} hasState={true} />
            </>
          }
        />
      ) : (
        <NoDataCard text="frame styles" loading={loading} />
      )}
    </>
  );
};

export default PricingFrameStyleFrameProducts;
