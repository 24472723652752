import React from 'react'
import ErrorLayout from './error-layout'
import { BiSolidBuoy } from 'react-icons/bi'

const Error402 = () => {
    return (
        <>
            <ErrorLayout 
                code={<h1 className="display-1 fw-medium">4<BiSolidBuoy className='bx-spin text-primary display-3' />2</h1>} 
                text="Payment required"
                subText="Payment is required"
                layout=""
            />
        </>
    )
}

export default Error402