import React, { useEffect, useState } from 'react'
import InnerLayout from "../../../../../../layouts/internalLayout"
import ComingSoon from '../../../errors/coming-soon'
import { useNavigate, useParams } from 'react-router-dom'
import PriceListLeft from './left'
import PriceListRight from './right'
import { useMessage } from '../../../../../../context/messageContext'
import { useRightContext } from '../../../../../../context/rightMenuContext'
import { addNewRightItem, getRightMenuList, getItemDetails, updateItemDetails, getPriceTableData } from '../../../../../../services/pricing/priceListService'
import { Oval } from 'react-loader-spinner'
import { GetDiscountableItems } from '../../../../../../services/commonServices'
import { noSelectOptions } from '../../../../../../utility/data'

const PriceList = () => {

    const accessToken = localStorage.getItem('access_token')

    const [discountableItemsOptions, setDiscountableItemsOptions] = useState([]);

    const [wip, setWIP] = useState(false)
    const [pageName, setPageName] = useState("Glideline")

    const [loading, setLoading] = useState(false)
    const [selectItemDetails, setSelectItemDetails] = useState([])
    const [selectItemTableData, setSelectItemTableData] = useState([])
    const [allRightListItems, setAllRightListItems] = useState([])
    const [allRightListItemsOrder, setAllRightListItemsOrder] = useState(true)
    const [priceBarData, setPriceBarData] = useState([])

    const { setMessage, setMessageType } = useMessage()
    const { timestamp, fetchSelected, setTimestamp, activeIndex, setActiveIndex, pricingColoursMenuList } = useRightContext()

    const navigate = useNavigate();

    var getFirstElId = allRightListItems[0]?.id || ''

    var itemAddedTrue = ''

    let { collectionId } = useParams();

    useEffect(() => {
        setLoading(true)
        setTimestamp('')

        getDiscountableItemsOptions()

        if (pricingColoursMenuList && pricingColoursMenuList.length > 0) {
            const getPageName = pricingColoursMenuList.filter((item) => {
                return item.id == collectionId;
            });

            setPageName(getPageName[0]?.title)
        }

        setTimeout(() => {
            getRightMenuListData(collectionId)
        }, 400);
    }, [collectionId])

    useEffect(() => {
        if (allRightListItems?.length > 0 && timestamp) {
            getRightItemDetails(timestamp)
        }
    }, [timestamp])

    useEffect(() => {
        if (allRightListItemsOrder) {
            getFirstElId = allRightListItems[0]?.id

            setActiveIndex(0)

            if (getFirstElId && allRightListItems?.length) {
                getRightItemDetails(getFirstElId)
            }
        } else {
            if (getFirstElId && allRightListItems?.length) {
                if (timestamp && allRightListItems?.length && activeIndex !== 0) {
                    getRightItemDetails(timestamp)
                } else {
                    getRightItemDetails(getFirstElId)
                }
            }
        }
    }, [allRightListItems])

    // get dropdown data from master API's
    const getDiscountableItemsOptions = async () => {
        const res = await GetDiscountableItems(accessToken)
        if (res && res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                if (res?.data?.entity !== null) {
                    if (res?.data?.entity?.length > 0) {
                        setDiscountableItemsOptions(res?.data?.entity)
                    } else {
                        setDiscountableItemsOptions(noSelectOptions)
                    }
                }
            }
        }
    }

    // get all right menu list
    const getRightMenuListData = async (fetchId) => {

        const res = await getRightMenuList(accessToken, fetchId);

        // console.log(res, "ss12")
        
        if (res && res && res.status === 200) {

            if (res?.data?.statusCode === 200) {
                if (res?.data?.entity !== null) {

                    if (res?.data?.entity?.length > 0) {
                        setAllRightListItems(res?.data?.entity)

                        if (itemAddedTrue == 'added') {
                            const addedItemIndex = res.data.entity.length - 1

                            if (addedItemIndex) {
                                fetchSelected(addedItemIndex, res.data.entity[addedItemIndex]?.id)
                                itemAddedTrue = ''
                            }
                        }
                    } else {
                        setAllRightListItems([])
                        setSelectItemDetails([])
                        setTimestamp('')

                        fetchSelected(0, '');
                    }
                }
                setTimeout(() => {
                    setLoading(false)
                }, 1200);
            } else {
                setLoading(false)
                setMessage(res?.data.message ? res.data.message : 'Something went wrong');
                setMessageType('error')
            }

        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')

            if (res?.message === "Network Error") {
                navigate('/error/network')
            }
        }
    }

    // get details of selected item in right menu by passing item id
    const getRightItemDetails = async (id) => {

        setLoading(true)

        const res = await getItemDetails(accessToken, id);

        setAllRightListItemsOrder(false)

        if (res && res && res.status === 200) {

            if (res?.data?.statusCode === 200) {
                if (res?.data?.entity !== null) {

                    // set state to get details of current selected item which will display data in left side component
                    setSelectItemDetails(res.data.entity)

                    // set selected item id which will update context
                    setTimestamp(id)
                }
            } else {
                setLoading(false)
                setMessage(res?.data.message ? res.data.message : 'Something went wrong');
                setMessageType('error')
            }

        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')

            if (res?.message === "Network Error") {
                navigate('/error/network')
            }
        }
    }

    // get table data of selected price item
    const getPriceTableDataService = async (data) => {

        // setLoading(true)

        const res = await getPriceTableData(accessToken, data);

        setAllRightListItemsOrder(false)

        if (res && res && res.status === 200) {

            if (res?.data?.statusCode === 200) {
                if (res?.data?.entity !== null) {
                    setSelectItemTableData(res.data.entity["lumpSum"])
                    setPriceBarData(res?.data?.entity["barLengthPrice"])

                    console.log(res.data.entity, "res.data.entity")
                } else {
                    setSelectItemTableData([])
                }

                setLoading(false)
            } else {
                setLoading(false)
                setMessage(res?.data.message ? res.data.message : 'Something went wrong');
                setMessageType('error')
            }
            setLoading(false)
        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')

            if (res?.message === "Network Error") {
                navigate('/error/network')
            }
        }
    }

    // api call to update all selected item details
    const updateItemDetailsServices = async (data, tableData) => {
        setLoading(true)

        console.log(data, tableData, "data 123")

        const res = await updateItemDetails(accessToken, tableData)

        const updateName = await addNewRightItem(accessToken, data, collectionId)

        if (res && res && res.status === 200) {
            console.log(res, "1234")

            if (res?.data?.statusCode === 200) {
                setMessage(res?.data?.message ? res.data.message : 'Updated pricing');
                setMessageType('success')

                // setLoading(false)
                getRightItemDetails(timestamp)
            } else {
                setLoading(false)
                setMessage(res?.data?.message ? res.data.message : 'Something went wrong');
                setMessageType('error')
            }
        } else {
            console.log(res, "tetst")
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')

            if (res?.message === "Network Error") {
                navigate('/error/network')
            }
        }
    }

    // api call to added new right item
    const addNewRightItemServices = async (title) => {

        var data = {
            "id": '',
            "name": title,
        }

        setLoading(true)
        itemAddedTrue = "added"

        const res = await addNewRightItem(accessToken, data, collectionId)

        if (res && res && res.status === 200) {

            if (res?.data?.statusCode === 200) {
                setMessage(res?.data?.message ? res.data.message : 'Record created successfully!');
                setMessageType('success')

                getRightMenuListData(collectionId)
            } else {
                setLoading(false)
                setMessage(res?.data?.message ? res.data.message : 'Something went wrong');
                setMessageType('error')
            }
        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')

            if (res?.message === "Network Error") {
                navigate('/error/network')
            }
        }
    }

    const handleFormReset = () => {
        if (timestamp) {
            getRightMenuListData(collectionId)
            getRightItemDetails(timestamp)
        }
    }

    return (
        <>
            {wip ? (
                <ComingSoon title="Price list Coming Soon" />
            ) : (

                <>
                    {loading && (
                        <div className='loader_main full_loader'>
                            <Oval
                                height={50}
                                width={50}
                                color="#44C8F5"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                                ariaLabel='oval-loading'
                                secondaryColor="#44C8F5"
                                strokeWidth={2}
                                strokeWidthSecondary={2}
                            />
                        </div>
                    )}

                    <InnerLayout pageTitle="Price list" layoutType=''
                        leftContent={<PriceListLeft setLoading={setLoading} pageName={pageName} selectItemDetails={selectItemDetails} updateItemDetailsServices={updateItemDetailsServices} handleFormReset={handleFormReset} discountableItemsOptions={discountableItemsOptions} getPriceTableDataService={getPriceTableDataService} selectItemTableData={selectItemTableData} setSelectItemTableData={setSelectItemTableData} priceBarData={priceBarData} setPriceBarData={setPriceBarData} />}
                        rightContent={<PriceListRight accessToken={accessToken} collectionId={collectionId} selectItemDetails={selectItemDetails} allRightListItems={allRightListItems} setLoading={setLoading} getRightMenuListData={getRightMenuListData} addNewRightItemServices={addNewRightItemServices} />} />
                </>
            )}
        </>
    )
}

export default PriceList