import axios from "axios";
import { servicePath } from "../utility/data";

export const getAllAccounts = (accessToken) => {
  const headers = {
    'Authorization': `Bearer ${accessToken}`
  }
  return axios
    .get(`${servicePath}/Account/GetAllAccount`, { headers })
    .then((res) => {
      return res?.data;
    })
    .catch((error) => {
      console.log(error);
      return error.response.data;
    });
};

export const getSubAccountSettings = (accessToken, id) => {
  const formData = new FormData();
  formData.append("Id", id);
  const headers = {
    'Authorization': `Bearer ${accessToken}`
  }

  return axios
    .post(`${servicePath}/SubAccount/GetSubAccountSetting`, formData, { headers })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      return error.response.data;
    });
};

export const getPorjectList = (accessToken) => {
  const headers = {
    'Authorization': `Bearer ${accessToken}`
  }

  return axios
    .get(`${servicePath}/SubAccount/GetProjectList`, { headers })
    .then((res) => {
      return res?.data;
    })
    .catch((error) => {
      console.log(error);
      return error?.response?.data;
    });
};

export const discountableList = (accessToken) => {
  const headers = {
    'Authorization': `Bearer ${accessToken}`
  }

  return axios
    .get(`${servicePath}/SubAccount/GetDiscountableItemList`, { headers })
    .then((res) => {
      return res?.data;
    })
    .catch((error) => {
      console.log(error);
      return error?.response?.data;
    });
}

export const updateSubAccountSettings = (accessToken, data, discountableId, projectId, discountData) => {
  const headers = {
    'Authorization': `Bearer ${accessToken}`
  }

  let apiName="UpdateSubAccountSetting"

  if(data?.Id===null){
    apiName="CreateSubAccount"
  }

  const requestBody = {
    Id: data.Id,
    UserId: data.UserId,
    CostPriceId: data.CostPriceId,
    UseMarkUpsAndOtherSettings: data.UseMarkUpsAndOtherSettings,
    UsePrintDocumentSettings: data.UsePrintDocumentSettings,
    DiscountableItem: discountableId,
    ProjectToShow: projectId,
    DiscountModel: discountData
  };

  return axios
    .post(`${servicePath}/SubAccount/${apiName}`, requestBody, { headers })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      return error.response.data;
    });
};

export const getDiscountProducts = (accessToken, subaccountId) => {
  const headers = {
    'Authorization': `Bearer ${accessToken}`
  }

  const formData = new FormData()
  formData.append("Id", subaccountId)

  return axios
    .post(`${servicePath}/SubAccount/GetProductMaster`, formData, { headers })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      return error.response.data;
    });
};

