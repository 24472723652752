import axios from "axios";
import { servicePath } from "../utility/data";


export const addUpdateColoursName = (data, accessToken) => {
    const formData = new FormData();
    formData.append("Id", data.id);
    formData.append("Name", data.name);
    formData.append("Description", data.description);
    formData.append("Type", data.type)
    formData.append("ChartId", data.chartId)
    formData.append("ChartColourType", data.chartColorType)
    formData.append("ChartColourId", data.chartColorId)
    formData.append("TextureId", data.textureId)
    formData.append("Red", data.red)
    formData.append("Green", data.green)
    formData.append("Blue", data.blue)
    formData.append("Hex", data.hex)
    formData.append("Roughness", data.roughness)
    formData.append("Metalness", data.metalness)
    formData.append("BumpId", data.bumpId)
    formData.append("Bumpiness", data.bumpiness)
    formData.append("PaletteType", data.paletteType)
    formData.append("CapturedFile", data.CapturedFile)
    formData.append("StockColour", data?.StockColour || false)
    formData.append("CapturedImage", "")


    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    return axios
        .post(`${servicePath}/Colours/AddUpdateColorsPalette`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error
        });
};

export const getVisualElements = (accessToken) => {
    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    return axios
        .get(`${servicePath}/Master/GetPaletteVisualElement`, { headers })
        .then((res) => {
            return res
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};

export const getAllColours = (palleteType, accessToken) => {
    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }
    const formData = new FormData()
    formData.append('PaletteType', palleteType)
    return axios
        .post(`${servicePath}/Colours/GetAllColoursPalette`, formData, { headers })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log(error);
            return error.response.data;
        });
};

export const getBuildingVisualElements = (accessToken) => {
    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    return axios
        .get(`${servicePath}/Master/GetBuildingVisualElement`, { headers })
        .then((res) => {
            return res
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};

export const getAllColoursV2 = (accessToken) => {

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }
    return axios
        .get(`${servicePath}/Master/GetPaletteVisualElement`, { headers })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log(error);
            return error.response.data;
        });
};

export const getHardwareVisualElements = (accessToken) => {

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }
    return axios
        .get(`${servicePath}/Master/GetHardwarePaletteVisualElement`, { headers })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log(error);
            return error.response.data;
        });
};

export const getUniquePalette = (accessToken, id) => {
    const formData = new FormData();
    formData.append("Id", id);

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    return axios
        .post(`${servicePath}/Colours/GetColoursPalette`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}

export const deletePalette = (accessToken, id) => {
    const formData = new FormData();
    formData.append("Id", id);

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    return axios
        .post(`${servicePath}/Colours/DeleteColorPalette`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}

export const updateColorPaletterOrder = (accessToken, id, type, direction) => {
    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }
    const data = {
        "id": id,
        "sequenceNo": 0,
        "typeText": type,
        "type": direction
    }

    return axios
        .post(`${servicePath}/Colours/UpdateColoursPaletteOrder`, data, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}

export const bumpList = (accessToken) => {
    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    return axios
        .get(`${servicePath}/Master/GetBumpMaster`, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}

export const chartTypeList = (accessToken) => {
    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    return axios
        .get(`${servicePath}/Master/GetChartType`, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}

export const colorList = (accessToken) => {
    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }
    return axios
        .get(`${servicePath}/Master/GetColorMaster`, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}

export const textureList = (accessToken) => {
    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }
    // /Master/GetTexture
    return axios
        .get(`${servicePath}/Master/GetGlazingTextureImageMaster`, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}

export const addCollectionColours = (data, accessToken) => {

    const formData = new FormData();
    formData.append("Id", data.id ? data.id : "");
    formData.append("Name", data.name);
    formData.append("CollectionType", data.type);

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    return axios
        .post(`${servicePath}/Colours/AddColoursCollection`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error
        });
};

export const getAllColoursCollection = (type, accessToken) => {

    const formData = new FormData();
    formData.append("CollectionType", type);

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    return axios
        .post(`${servicePath}/Colours/GetAllColourCollection`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error
        });
};

export const getUniqueCollection = (id, accessToken) => {

    const formData = new FormData();
    formData.append("Id", id);

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    return axios
        .post(`${servicePath}/Colours/GetColourCollection`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error
        });
};

export const updateCollectionOrder = (id, type, direction, accessToken) => {
    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }
    const data = {
        "id": id,
        "sequenceNo": 0,
        "typeText": type,
        "type": direction
    }

    return axios
        .post(`${servicePath}/Colours/UpdateColoursCollectionOrder`, data, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}

export const addExternalColours = (collectionId, colorId, colorName, accessToken) => {

    const formData = new FormData();
    formData.append("ColourCollectionId", collectionId);
    formData.append("ColourId", colorId);
    formData.append("ColourName", colorName);

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    return axios
        .post(`${servicePath}/Colours/AddExternalColourForCollection`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error
        });
}

export const addInternalColours = (externalColorId, colorId, colorName, accessToken) => {
    const formData = new FormData();
    formData.append("ExternalColourId", externalColorId);
    formData.append("ColourId", colorId);
    formData.append("ColourName", colorName);

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    return axios
        .post(`${servicePath}/Colours/AddInternalColourForCollection`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error
        });
}

export const deleteExternalColours = (externalColorId, accessToken) => {
    const formData = new FormData();
    formData.append("EnternalColourId", externalColorId);

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    return axios
        .post(`${servicePath}/Colours/DeleteExternalColour`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error
        });
}

export const deleteInternalColours = (internalColourId, accessToken) => {
    const formData = new FormData();
    formData.append("InternalColourId", internalColourId);

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    return axios
        .post(`${servicePath}/Colours/DeleteInternalColour`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error
        });
}

export const deleteCollection = (accessToken, id) => {
    const formData = new FormData();
    formData.append("Id", id);

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    return axios
        .post(`${servicePath}/Colours/DeleteColorCollection`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}

export const hiddenColourListExternal = (accessToken, collectionId, type) => {
    const formData = new FormData();
    formData.append("CollectionId", collectionId);
    formData.append("Type", type);


    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    return axios
        .post(`${servicePath}/Colours/GetExternalColourHiddenList`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}

export const hiddenColourListInternal = (accessToken, collectionId, type, externalId) => {
    const formData = new FormData();
    formData.append("CollectionId", collectionId);
    formData.append("Type", type);
    formData.append("ExternalId", externalId);


    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    return axios
        .post(`${servicePath}/Colours/GetInternalColourHiddenList`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}

export const visibleColourListExternal = (accessToken, collectionId, type) => {
    const formData = new FormData();
    formData.append("CollectionId", collectionId);
    formData.append("Type", type);


    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    return axios
        .post(`${servicePath}/Colours/GetExternalColourVisiblenList`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}

export const visibleColourListInternal = (accessToken, collectionId, type, externalId) => {

    const formData = new FormData();
    formData.append("CollectionId", collectionId);
    formData.append("Type", type);
    formData.append("ExternalId", externalId);

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    return axios
        .post(`${servicePath}/Colours/GetInternalColourVisibleList`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}

export const setDefaultExternalColour = (accessToken, externalColourId) => {
    const formData = new FormData();
    formData.append("EnternalColourId", externalColourId);

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    return axios
        .post(`${servicePath}/Colours/SetDefaultExternalColourForCollection`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}

export const setDefaultInternalColour = (accessToken, internalColourId) => {
    const formData = new FormData();
    formData.append("InternalColourId", internalColourId);

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    return axios
        .post(`${servicePath}/Colours/SetDefaultInternalColourForCollection`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}

export const updateOrder = (accessToken, id, direction) => {

    console.log(id, direction, "order test")

    var requestBody = {
        "id": id,
        "sequenceNo": 0,
        "typeText": 7,
        "type": direction
    };

    const headers = {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
    }

    return axios
        .post(`${servicePath}/Colours/UpdateColoursPaletteOrder`, requestBody, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}


export const updateInternalVisibleOrder = (accessToken, id, direction, colorId) => {

    console.log(id, direction, "order test")

    var requestBody = {
        "id": id,
        "sequenceNo": 0,
        "typeText": colorId,
        "type": direction
    };

    const headers = {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
    }

    return axios
        .post(`${servicePath}/Colours/UpdateInternalVisibleOrder`, requestBody, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}

export const updateExternalVisibleOrder = (accessToken, id, direction, colorId) => {

    console.log(id, direction, "order test")

    var requestBody = {
        "id": id,
        "sequenceNo": 0,
        "typeText": colorId,
        "type": direction
    };

    const headers = {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
    }

    return axios
        .post(`${servicePath}/Colours/UpdateExternalVisibleOrder`, requestBody, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}