import React, { useState } from "react";
import { Form, Table } from "react-bootstrap";
import { Button, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { MdOutlineCreate } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";
import { servicePath } from "../../../../../../utility/data";
import PricingHeader from "../../../../../../components/Pricing/PricingHeader";
import CustomizedButton from "../../../../../../components/CustomizedButton";
import {
  handleEmptyValues,
  handleFocus,
  newHandleInput,
} from "../../../../../../utility/helper";
import { getFrameAncillariesData } from "../../../../../../services/pricing/frameAncillariesService";
import { useRightContext } from "../../../../../../context/rightMenuContext";
import PricingTableComp from "../../../../../../components/PricingTableComp";

const Left = ({ token, setMessage, setMessageType, loading, setLoading }) => {
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState("Lump sum");
  const { userDataContext, setUserDataContext } = useRightContext("");
  const [selectedPricing, setSelectedPricing] = useState("");
  const [selectedSupplyOnly, setSelectedSupplyOnly] = useState("");

  const [data, setData] = useState([]);

  // useEffect(() => {
  //     setLoading(true);
  //     getFrameAncillaryData();
  // }, [])

  useEffect(() => {
    setLoading(true);

    if (selectedPricing && selectedSupplyOnly) {
      getFrameAncillaryData();
    }
  }, [selectedPricing, selectedSupplyOnly]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const getFrameAncillaryData = async () => {
    // const FormData = require('form-data');

    // let data = new FormData();

    // data.append("PriceMasterId", selectedPricing);
    // data.append("MarkupMasterId", selectedSupplyOnly);

    // let config = {
    //     method: 'post',
    //     maxBodyLength: Infinity,
    //     url: `${servicePath}/General/GetFrameAncillariesPricing`,
    //     headers: {
    //         'accept': 'text/plain',
    //         'Authorization': `Bearer ${token}`,
    //         'Content-Type': 'multipart/form-data',
    //     },
    //     data: data
    // };
    // axios.request(config).then(res => {
    //     if (res?.data?.statusCode === 200) {
    //         setLoading(false)
    //         setData(res?.data?.entity)
    //         console.log(data, "data")

    //     } else if (res?.data?.statusCode === 404) {
    //         setLoading(false)
    //         navigate('/error/404')
    //     } else {
    //         setLoading(false)
    //         setMessage(res?.data?.message ? res.data.message : 'Something went wrong');
    //         setMessageType('error')
    //     }

    // }).catch(error => {
    //     console.log(error);

    //     setLoading(false)
    //     setMessage(error.message ? error.message : 'Something went wrong');
    //     setMessageType('error')

    //     if (error?.message === "Network Error") {
    //         navigate('/error/network')
    //     }
    // })

    setLoading(true);
    const res = await getFrameAncillariesData(
      token,
      selectedPricing,
      selectedSupplyOnly
    );
    if (res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        setLoading(false);
        setData(res?.data?.entity);
        console.log(data, "data");
      } else if (res?.data?.statusCode === 404) {
        setLoading(false);
        navigate("/error/404");
      } else {
        setLoading(false);
        setMessage(
          res?.data?.message ? res.data.message : "Something went wrong"
        );
        setMessageType("error");
      }
    } else {
      setLoading(false);
      setMessage(res?.message ? res.message : "Something went wrong");
      setMessageType("error");
      if (res?.message === "Network Error") {
        navigate("/error/network");
        setLoading(false);
      }
    }
  };

  const handleInput = (id, key, value) => {
    if (validate(value)) {
      setData((prevData) =>
        prevData.map((item, index) =>
          index === id ? { ...item, [key]: parseFloat(value) } : item
        )
      );
    }
  };

  const validate = (value) => {
    if (value < 0) return false;
    return true;
  };

  //   console.log(data, "allfram");

  const handleUpdate = () => {
    setLoading(true);
    updateTableData();
  };

  const updateTableData = () => {
    console.log("Updated data", data);
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${servicePath}/General/AddUpdateFrameAncillariesPricing`,
      headers: {
        accept: "text/plain",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: handleEmptyValues(data),
    };

    axios
      .request(config)
      .then((res) => {
        console.log(res.data, "UpdatedProjectAncillaries");

        if (res?.data?.statusCode === 200) {
          setMessage(res.data.message);
          setMessageType("success");
          getFrameAncillaryData();
        }
      })
      .catch((error) => {
        console.log(error);

        setLoading(false);
        setMessage(error.message ? error.message : "Something went wrong");
        setMessageType("error");

        if (error?.message === "Network Error") {
          navigate("/error/network");
        }
      });
  };

  const handleFormReset = () => {
    setLoading(true);
    getFrameAncillaryData();
  };

  const data1 = { url: "/settings/frames/ancillary" };
  return (
    <div className="d-flex flex-column h-100">
      <PricingHeader
        setSelectedSupplyOnly={setSelectedSupplyOnly}
        setSelectedPricing={setSelectedPricing}
        selectedPricing={selectedPricing}
        selectedSupplyOnly={selectedSupplyOnly}
      />

      <Nav tabs className="details_header border-0 nav-tabs-custom">
        <NavItem>
          <NavLink
            className={`${activeTab === "Lump sum" && "active"}`}
            onClick={() => handleTabChange("Lump sum")}
          >
            Lump sum
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={`${activeTab === "Percentage" && "active"}`}
            onClick={() => handleTabChange("Percentage")}
          >
            Percentage
          </NavLink>
        </NavItem>

        <CustomizedButton data={data1} />
      </Nav>
      <TabContent activeTab={activeTab} className="flex-grow-1 d-flex">
        <TabPane tabId="Lump sum" className="flex-grow-1 w-100">
          <Form className="h-100 d-flex flex-column flex-grow-1 justify-content-between">
            <div className="inside_scroll has_tab has_btns">
              {/* <Table responsive className='w-100 discount_table'>
                                <thead className='border-bottom'>
                                    <tr className='text-end'>
                                        <th style={{ width: "40%", minWidth: "40%" }}></th>
                                        <th>
                                            {userDataContext?.role === "Supplier" ? ("Selling Price") : ("Cost")}

                                        </th>
                                        <th>
                                            {userDataContext?.role === "Supplier" ? ("Selling Price") : ("Cost")} + VAT
                                        </th>
                                        <th>
                                            Mark-up $
                                        </th>
                                        <th>
                                            Mark-up %
                                        </th>
                                        <th>
                                            Total
                                        </th>
                                        <th>
                                            Total + VAT
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data && data.length > 0 ? (
                                        <>
                                            {data && data.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{item.description}</td>
                                                    <td>
                                                        <Form.Control type="number" value={item.cost} readOnly={userDataContext?.role !== "Supplier" && !item?.isSelf} onChange={(e) => newHandleInput(data, setData, index, "cost", e.target.value)} />
                                                    </td>
                                                    <td>
                                                        <Form.Control type="number" value={item.costPlusVat} readOnly onChange={(e) => handleInput(index, "costPlusVat", e.target.value)} />
                                                    </td>
                                                    <td>
                                                        <Form.Control type="number" readOnly={userDataContext?.role === "Supplier"} value={item.markUp} onChange={(e) => newHandleInput(data, setData, index, "markUp", e.target.value)} />
                                                    </td>
                                                    <td>
                                                        <Form.Control type="number" readOnly={userDataContext?.role === "Supplier"} value={item.markUpPercentage} onChange={(e) => newHandleInput(data, setData, index, "markUpPercentage", e.target.value)} />
                                                    </td>
                                                    <td>
                                                        <Form.Control type="number" value={item.total} readOnly onChange={(e) => handleInput(index, "total", e.target.value)} />
                                                    </td>
                                                    <td className='text-end'>
                                                        <Form.Control type="number" value={item.totalPlusVat} readOnly onChange={(e) => handleInput(index, "totalPlusVat", e.target.value)} />
                                                    </td>
                                                </tr>
                                            ))}
                                        </>
                                    ) : (
                                        <tr>
                                            <td colSpan={7}>
                                                <h5 className='my-4 text-center text-muted w-100'>No data found</h5>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table> */}
              <PricingTableComp
                data={data}
                handleInput={handleInput}
                newHandleInput={newHandleInput}
                setData={setData}
                userDataContext={userDataContext}
              ></PricingTableComp>
            </div>
            <div className="d-flex gap-2 gap-xl-3 main_buttons">
              <Button
                color="primary"
                size="md"
                className="px-4"
                onClick={handleUpdate}
              >
                Save Changes
              </Button>

              <Button
                color="primary"
                size="md"
                outline
                className="px-4"
                onClick={handleFormReset}
              >
                Reset
              </Button>
            </div>
          </Form>
        </TabPane>

        <TabPane tabId="Percentage" className="w-100">
          <Form className="h-100 d-flex flex-column flex-grow-1 justify-content-between">
            <div className="inside_scroll has_tab has_btns">
              <Table responsive className="w-100 discount_table">
                <thead className="border-bottom">
                  <tr>
                    <th></th>
                    <th>Percentage</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, index) => (
                    <tr key={index}>
                      <td>{item.description}</td>
                      <td>
                        <Form.Control
                          type="number"
                          onFocus={handleFocus}
                          value={item.percentage !== null ? item.percentage : 0}
                          onChange={(e) =>
                            handleInput(index, "percentage", e.target.value)
                          }
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
            <div className="d-flex gap-2 gap-xl-3 main_buttons">
              <Button
                color="primary"
                size="md"
                className="px-4"
                onClick={handleUpdate}
              >
                Save Changes
              </Button>

              <Button
                color="primary"
                size="md"
                outline
                className="px-4"
                onClick={handleFormReset}
              >
                Reset
              </Button>
            </div>
          </Form>
        </TabPane>
      </TabContent>
    </div>
  );
};

export default Left;
