import React, { useEffect, useState } from "react";
import { Form, Image } from "react-bootstrap";
import {
    Row,
    Col,
    Button,
    FormGroup,
    Label,
    Input,
} from "reactstrap";
import { Link } from "react-router-dom";
import { MdOutlineKeyboardArrowDown, MdOutlineSell } from "react-icons/md";
import { AccessoriesPricePer, MenuProps, noSelectOptions } from "../../../../../../utility/data";
import { MenuItem, OutlinedInput, Select } from "material-ui-core";
import NumberInput from "../../../../../../components/NumberInput";
import { useRightContext } from "../../../../../../context/rightMenuContext";
import NumberInputDecimal from "../../../../../../components/NumberInputDecimal";
import Editprices from "../../../../../../components/Editprices";
import DropZone from "../../../../../../components/DropZone";
const StylesLeft = ({ accessToken, selectItemDetails, updateItemDetailsServices, handleFormReset, allSupplierList }) => {

    const { timestamp, selected, setSelected, handleEdit, handleDescEdit, setDescUpdate, handleFileUpdate, selectedRightItem } = useRightContext()

    var acceptFileType = {
        'image/png': ['.jpg', '.jpeg', '.png', '.svg', '.gif'],
    }

    const [name, setName] = useState('')
    const [itemId, setItemId] = useState('')
    const [productCode, setProductCode] = useState('')
    const [supplier, setSupplier] = useState('')
    const [pricePer, setPricePer] = useState('')
    // const [price, setPrice] = useState('')
    const [minOrderQuantity, setMinOrderQuantity] = useState('')

    const [nameError, setNameError] = useState(false)
    const [descriptionError, setDescriptionError] = useState(false)
    const [uploadedFile, setUploadedFile] = useState('')
    const [filePreview, setFilePreview] = useState('')


    useEffect(() => {

        // if (styleMasterId == 4) {
        //     getIiternalStyleOptions()
        // }

        // if (styleMasterId == 1 || styleMasterId == 2 || styleMasterId == 5) {
        //     getEscutcheonstyleOptions()
        // }

        setName(selectItemDetails?.description || '')
        setProductCode(selectItemDetails?.productCode || '')
        setSupplier(selectItemDetails?.supplier || '')
        setPricePer(selectItemDetails?.priceUnit || '')
        // setPrice(selectItemDetails?.price || '')
        setMinOrderQuantity(selectItemDetails?.minimumOrderQuantity || '')
        setUploadedFile('')
        setFilePreview(selectItemDetails?.filePathUrl || '')

    }, [selectItemDetails])

    useEffect(() => {
        if (filePreview) {
            handleFileUpdate(filePreview)
        }
    }, [filePreview])

    const handleNameChange = (e) => {
        const value = e.target.value

        if (e.target.value?.length <= 50) {
            setName(value)
            handleEdit(value)
            setSelected(value)
        }

        if (value.length > 50) {
            setNameError(true)
        } else {
            setNameError(false)
        }
    }

    const handleFormSubmit = () => {
        const formDataValues = {
            "id": '',
            'description': name,
            'productCode': productCode,
            'supplier': supplier,
            // 'price': price,
            'pricePer': pricePer,
            'minOrderQuantity': minOrderQuantity,
            'filePath': uploadedFile
        }

        updateItemDetailsServices(formDataValues)
    }

    const editNavData = { url: `/pricing/hardware/14` }

    return (
        <div className="d-flex flex-column h-100">
            <div className="d-flex align-items-center mb-3 flex-wrap gap-2 left_header">
                {selectItemDetails && (
                    <h5 className="mb-0 text-wrap text-break me-4 main_title">{selectItemDetails?.description}</h5>
                )}
                <Editprices data={editNavData} />
            </div>

            <Form className="h-100 d-flex flex-column flex-grow-1 justify-content-between">
                <div className="inside_scroll has_btns has_head">
                    <Row>
                        <Col sm="6">
                            <FormGroup className="form-group">
                                <Label for="description">
                                    Product Code
                                </Label>

                                <NumberInput disabled={!selectedRightItem?.isSelf} className='mb-1' name='productCode' setValue={setProductCode} value={productCode} />

                            </FormGroup>
                        </Col>
                        <Col sm="6">
                            <FormGroup className="form-group">
                                <Label for="description">
                                    Description
                                </Label>

                                <Input disabled={!selectedRightItem?.isSelf} className='mb-1' name='name' onChange={(e) => handleNameChange(e)} value={name} />

                            </FormGroup>
                        </Col>
                        <Col sm="6">
                            <FormGroup className="form-group">
                                <Label for="supplier">
                                    Supplier
                                </Label>
                                <Select
                                    labelId="supplier"
                                    id="supplier"
                                    value={supplier}
                                    disabled={!selectedRightItem?.isSelf}
                                    label="supplier"
                                    onChange={(e) => { setSupplier(e.target.value) }}
                                    input={<OutlinedInput className='w-100' name='supplier' />}

                                    MenuProps={MenuProps}

                                    IconComponent={() => (
                                        <MdOutlineKeyboardArrowDown size={20} className='me-1 MuiSvgIcon-root MuiSelect-icon' />
                                    )}
                                >
                                    {allSupplierList && allSupplierList?.map((o, index) => (
                                        <MenuItem key={index} value={o?.id}>
                                            {o?.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormGroup>
                        </Col>
                        <Col sm="6">
                            <FormGroup className="form-group">
                                <Label for="pricePer">
                                    Price Per
                                </Label>

                                <Select
                                    labelId="pricePer"
                                    id="pricePer"
                                    value={pricePer}
                                    disabled={!selectedRightItem?.isSelf}
                                    label="supplier"
                                    onChange={(e) => { setPricePer(e.target.value) }}
                                    input={<OutlinedInput className='w-100' name='pricePer' />}

                                    MenuProps={MenuProps}

                                    IconComponent={() => (
                                        <MdOutlineKeyboardArrowDown size={20} className='me-1 MuiSvgIcon-root MuiSelect-icon' />
                                    )}
                                >
                                    {AccessoriesPricePer && AccessoriesPricePer?.map((o, index) => (
                                        <MenuItem key={index} value={o?.id}>
                                            {o?.name}
                                        </MenuItem>
                                    ))}
                                </Select>

                            </FormGroup>
                        </Col>
                        {/* <Col sm="6">
                            <FormGroup className="form-group">
                                <Label for="price">
                                    Price £
                                </Label>

                                <NumberInputDecimal className='mb-1' name='price' setValue={setPrice} value={price} />

                            </FormGroup>
                        </Col> */}
                        <Col sm="6">
                            <FormGroup className="form-group">
                                <Label for="minimumOrderQuantity">
                                    Minimum Order Quantity
                                </Label>

                                <NumberInput disabled={!selectedRightItem?.isSelf} className='mb-1' name='minimumOrderQuantity' setValue={setMinOrderQuantity} value={minOrderQuantity} />

                            </FormGroup>
                        </Col>
                        <Col sm="6">
                            <FormGroup className="form-group">
                                <Label for="uploadFile">Upload image</Label>

                                <DropZone
                                    acceptFileType={acceptFileType}
                                    multiple={false}
                                    uploadedFile={uploadedFile}
                                    setUploadedFile={setUploadedFile}
                                    filePreview={filePreview}
                                    setFilePreview={setFilePreview}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </div>

                {/* Start: space for buttons at the bottom */}
                {selectedRightItem?.isSelf && <div className="d-flex gap-2 gap-xl-3 main_buttons">
                    <Button color="primary" size="md" className="px-4"
                        onClick={() => handleFormSubmit()}
                        disabled={!timestamp || !name.trim().trim() || !productCode}
                    >
                        Save Changes
                    </Button>

                    <Button color="primary" size="md" outline className="px-4" disabled={!timestamp} onClick={() => handleFormReset()} >
                        Reset
                    </Button>
                </div>}
                {/* End: space for buttons at the bottom */}
            </Form>
        </div>
    );
};

export default StylesLeft;
