import React, { useEffect } from 'react'
import { useState } from 'react'
import { Form } from 'react-bootstrap'
import {
  Row,
  Col,
  Button,
  Label,
  FormGroup,
  Input,
} from 'reactstrap';
import CollectionColours from '../CollectionColours';
import { addGlazingCollectionPalette, addUpdateGlazingCollection, deleteGlazingCollectionPalette, getGlazingCollectionPalette, getUniqueGlazingCollection, updateCollectionVisibleOrder } from '../../services/glazingServices';
import { useProductPaletteContext } from '../../context/productPaletteContext';
import { useMessage } from '../../context/messageContext';
import { useNavigate } from 'react-router-dom';
import { useRightContext } from '../../context/rightMenuContext';

const DesignCollection = ({ tabType, setLoading, getAllCollectionServices, setCommonHeading, allCollection }) => {

  const [name, setName] = useState()
  const [id, setId] = useState()
  const [selectedHiddenIndex, setSelectedHiddenIndex] = useState(0);
  const [selectedVisibleIndex, setSelectedVisibleIndex] = useState(0);
  const [visibleData, setVisibleData] = useState('')
  const [hiddenExternalColours, setHiddenExternalColours] = useState([])
  const [visibleExternalColors, setVisibleExternalColors] = useState([])

  const accessToken = localStorage.getItem('access_token')

  const { timestamp, newItemList, handleEdit, setTimestamp, activeIndex } = useProductPaletteContext();
  const { setMessage, setMessageType } = useMessage()
  const { setSelectedRightItem, selectedRightItem } = useRightContext()


  const navigate = useNavigate()


  useEffect(() => {
    if (timestamp && tabType === 'collections') {
      allGlazingCollectionPaletteServices()

    }
  }, [timestamp])

  const firstEl = allCollection[0];

  useEffect(() => {
    if (firstEl && tabType === 'collections') {
      if (allCollection?.length && activeIndex !== 0 && tabType === 'collections') {
        setTimestamp(timestamp)
      } else {
        setTimestamp(firstEl?.id)
      }
    }
  }, [allCollection, timestamp, tabType])

  useEffect(() => {
    if (timestamp && tabType === 'collections') {
      getUniqueCollectionServices()
    }
  }, [timestamp, tabType])

  const reset = () => {
    getAllCollectionServices()
    getUniqueCollectionServices()
  }

  const updateGlazingCollectionServices = async () => {
    setLoading(true)
    const data = {
      id: timestamp,
      name: name,
      typeId: 4,
      sequenceNo: "",
    }

    const res = await addUpdateGlazingCollection(data, accessToken)
    if (res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        setMessageType('success')
        setMessage(res?.data?.message ? res.data.message : 'Added new palette')
        getAllCollectionServices()
        getUniqueCollectionServices()
        setLoading(false)
      } else {
        setMessage(res?.data?.message ? res.data.message : 'Something went wrong')
        setMessageType('error')
        getAllCollectionServices()
        getUniqueCollectionServices()
        setLoading(false)

      }
    } else {
      setLoading(false)
      setMessage(res?.message ? res.message : 'Something went wrong');
      setMessageType('error')
      getAllCollectionServices()
      if (res?.message === "Network Error") {
        navigate('/error/network')
      }
    }
  }


  const getUniqueCollectionServices = async () => {
    setLoading(true)
    const res = await getUniqueGlazingCollection(timestamp, accessToken)
    if (res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        const selectedRight = allCollection?.find((p) => p.id == timestamp)
        setSelectedRightItem(selectedRight)
        setName(res?.data.entity?.name)
        setCommonHeading(res?.data.entity?.name)
        setLoading(false)
        setId(res?.data.entity?.id)
      } else {
        setLoading(false)
        setMessage(res?.data?.message ? res.data.message : 'Something went wrong');
        setMessageType('error')
      }
    } else {
      setLoading(false)
      setMessage(res?.message ? res.message : 'Something went wrong');
      setMessageType('error')
      if (res?.message === "Network Error") {
        navigate('/error/network')
        setLoading(false)
      }

    }
  }


  const allGlazingCollectionPaletteServices = async () => {
    setLoading(true)
    const res = await getGlazingCollectionPalette(timestamp, 4, accessToken)
    if (res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        setVisibleExternalColors(res?.data?.entity?.visiblePalette)
        setHiddenExternalColours(res?.data?.entity?.hiddenPalette)
        setLoading(false)
      } else {
        setLoading(false)
        setMessage(res?.data?.message ? res.data.message : 'Something went wrong');
        setMessageType('error')
      }
    } else {
      setLoading(false)
      setMessage(res?.message ? res.message : 'Something went wrong');
      setMessageType('error')
      if (res?.message === "Network Error") {
        navigate('/error/network')
        setLoading(false)
      }

    }
  }

  const deleteExternalColourServices = async (paletteId) => {
    setLoading(true)
    const res = await deleteGlazingCollectionPalette(paletteId, accessToken)
    if (res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        allGlazingCollectionPaletteServices()
        setLoading(false)
      } else {
        setLoading(false)
        setMessage(res?.data?.message ? res.data.message : 'Something went wrong');
        setMessageType('error')
        allGlazingCollectionPaletteServices()
      }
    } else {
      setLoading(false)
      setMessage(res?.message ? res.message : 'Something went wrong');
      setMessageType('error')
      if (res?.message === "Network Error") {
        navigate('/error/network')
        setLoading(false)
      }

    }
  }

  // const updateOrder = () => {
  //   console.log("Order UPDATE");
  // }

  const defaultServices = () => {
    console.log("default services");
  }

  const addExternalProductService = async (paletteId) => {
    setLoading(true)
    const res = await addGlazingCollectionPalette(timestamp, paletteId, accessToken)
    if (res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        allGlazingCollectionPaletteServices()
        setLoading(false)
      } else {
        setLoading(false)
        setMessage(res?.data?.message ? res.data.message : 'Something went wrong');
        setMessageType('error')
      }
    } else {
      setLoading(false)
      setMessage(res?.message ? res.message : 'Something went wrong');
      setMessageType('error')
      if (res?.message === "Network Error") {
        navigate('/error/network')
        setLoading(false)
      }
    }
  }





  const handleNameChange = (e) => {
    const value = e.target.value;
    if (e.target.value.length <= 50) {
      setName(value)
      handleEdit(value)
    }
  }


  const updateHiddenVisibleOrderServices = async (direction) => {
    setLoading(true)
    console.log(visibleData, "251");
    const res = await updateCollectionVisibleOrder(accessToken, visibleData?.id, timestamp, direction)

    if (res && res && res.status === 200) {

      if (res?.data?.statusCode === 200) {
        allGlazingCollectionPaletteServices()

      } else {
        setLoading(false)
        setMessage(res?.data?.message ? res.data.message : 'Something went wrong');
        setMessageType('error')
      }
    } else {
      setLoading(false)
      setMessage(res?.message ? res.message : 'Something went wrong');
      setMessageType('error')

      if (res?.message === "Network Error") {
        navigate('/error/network')
      }
    }
  }

  const updateOrder = (direction) => {
    updateHiddenVisibleOrderServices(direction)

    if (direction === "UP") {
      setSelectedVisibleIndex(selectedVisibleIndex - 1)
    } else {
      setSelectedVisibleIndex(selectedVisibleIndex + 1)
    }
  }

  return (
    <>
      <Form className='h-100 d-flex flex-column flex-grow-1 justify-content-between'>
        <div className='inside_scroll has_btns has_head'>
          <Row>
            <Col lg="6">
              <FormGroup className="form-group">
                <Label for="name">
                  Name
                </Label>
                <Input disabled={!selectedRightItem?.isSelf} className='mb-1' name='name' value={name} onChange={(e) => handleNameChange(e)} />
                {name && name.length > 49 && (
                  <div className='mt-1 text-muted text-small'>Max 50 characters are allowed</div>
                )}

                {(!name && id) && (
                  <div className='mt-1 text-danger text-small'>Name is required</div>
                )}
              </FormGroup>
            </Col>
            <Col lg="12">
              <CollectionColours shownColours={visibleExternalColors} hiddenColours={hiddenExternalColours} moveToRight={addExternalProductService} moveToLeft={deleteExternalColourServices} selectedHiddenIndex={selectedHiddenIndex} selectedVisibleIndex={selectedVisibleIndex} setSelectedVisibleIndex={setSelectedVisibleIndex} setSelectedHiddenIndex={setSelectedHiddenIndex} setVisibleData={setVisibleData} visibleData={visibleData} setDefaultColour={defaultServices} updateOrder={updateOrder} isImage={true} isSelf={selectedRightItem?.isSelf} />
            </Col>
          </Row>
        </div>
        {selectedRightItem?.isSelf && <div className='d-flex gap-2 gap-xl-3 main_buttons mt-4'>
          <Button
            color="primary"
            size="md"
            className='px-4'
            onClick={() => updateGlazingCollectionServices()}
            disabled={allCollection?.length === 0 || !name || name.length > 49}
          >
            Save Changes
          </Button>

          <Button
            color="primary"
            size="md"
            outline
            className='px-4'
            onClick={() => reset()}
            disabled={allCollection?.length === 0 || !name || name.length > 49}

          >
            Reset
          </Button>
        </div>}
      </Form>
    </>
  )
}

export default DesignCollection