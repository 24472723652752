import React, { useState, useEffect } from 'react'
import { Form } from 'react-bootstrap';
import { MdInfoOutline, MdOutlineKeyboardArrowDown } from 'react-icons/md';
import { Checkbox, FormControl, FormControlLabel, ListItemText, MenuItem, OutlinedInput, Radio, RadioGroup, Select } from 'material-ui-core';
import {
    Row,
    Col,
    Button,
    FormGroup,
    Label,
    Input,
    Alert,
} from 'reactstrap';
import { MenuProps, noSelectOptions } from '../utility/data';
import { FaRegFile } from 'react-icons/fa6';
import NumberInputDecimal from './NumberInputDecimal';
import { useRightContext } from '../context/rightMenuContext';
import { GetMarginMasterService, GetMarkUpMasterService } from '../services/commonServices';
import { getAllColoursCollection } from '../services/productServices';
import { allGlazingCollection, allGlazingPalette, getDefaultSpecificationsList } from '../services/glazingServices';

const SystemGlazing = ({ isEditable, activeTab, accessToken, selectItemDetails, updateItemDetailsServices, handleFormReset }) => {

    const { timestamp } = useRightContext()

    const [specificationsOptions, setSpecificationsOptions] = useState([])
    const [defaultSpecificationsOptions, setDefaultSpecificationsOptions] = useState([])

    const [itemId, setItemId] = useState('')
    const [specifications, setSpecifications] = useState([])
    const [defaultSpecifications, setDefaultSpecifications] = useState("")

    const [frameClearance, setFrameClearance] = useState("0")
    const [gasketClearance, setGasketClearance] = useState("0")

    const [beadRadio, setBeadRadio] = useState('Internal')

    useEffect(() => {
        if (activeTab === "Glazing") {
            getSpecificationsOptions()
            getDefaultSpecificationsOptions()
        }
    }, [activeTab])


    useEffect(() => {
        if (specifications) {
            getDefaultSpecificationsOptions();
        }
    }, [specifications]);

    useEffect(() => {
        // console.log(selectItemDetails, "data left")

        setItemId(selectItemDetails?.id || '')

        setBeadRadio(selectItemDetails?.bead || "Internal")

        setFrameClearance(selectItemDetails?.frameClearance || 0)
        setGasketClearance(selectItemDetails?.gasketClearance || 0)

    }, [selectItemDetails])

    useEffect(() => {
        if (selectItemDetails && selectItemDetails?.specifications?.length > 0) {
            const propertiesSelectNames = selectItemDetails?.specifications?.map((item) => {
                const proj = specificationsOptions?.find((p) => p.id == item)
                return proj ? proj.name : null
            })?.filter(id => id !== null);

            setSpecifications(propertiesSelectNames);
        } else {
            setSpecifications([]);
        }
    }, [selectItemDetails, specificationsOptions])

    useEffect(() => {
        if (defaultSpecificationsOptions && defaultSpecificationsOptions.length > 0) {
            setDefaultSpecifications(selectItemDetails?.defaultSpecificationId || defaultSpecificationsOptions[0]?.id)
        }
    }, [selectItemDetails, defaultSpecificationsOptions])

    // get product color collection
    const getSpecificationsOptions = async () => {
        const res = await allGlazingCollection("1", accessToken)
        if (res && res && res?.status === 200) {
            if (res?.data?.statusCode === 200) {
                if (res?.data?.entity !== null) {
                    if (res?.data?.entity?.length > 0) {
                        setSpecificationsOptions(res?.data?.entity)
                    } else {
                        setSpecificationsOptions(noSelectOptions)
                    }
                }
            }
        }
    }

    const handleSpecificationsChange = async (e) => {
        const value = e.target.value;
        setSpecifications(typeof value === 'string' ? value.split(',') : value);
    };

    const getDefaultSpecificationsOptions = async () => {
        try {
            const selectedIds = specificationsOptions?.filter((item) => specifications?.indexOf(item.name) > -1)?.map((item) => item?.id);
            const res = await getDefaultSpecificationsList(selectedIds, accessToken);

            if (res && res?.status === 200 && res?.data?.statusCode === 200) {
                const defaultSpecificationsArray = res?.data.entity;

                if (defaultSpecificationsArray?.length > 0) {
                    setDefaultSpecificationsOptions(defaultSpecificationsArray);
                } else {
                    setDefaultSpecificationsOptions([]);
                }
            }
        } catch (error) {
            console.error('Error fetching default specifications:', error);
        }
    };


    const handleBeadSurface = (e) => {
        const value = e.target.value

        setBeadRadio(value)
    }

    const handleFormSubmit = () => {

        const specificationsSelectIds = specifications?.map((name) => {
            const project = specificationsOptions.find((item) => item?.name === name)
            return project ? project?.id : null;
        })

        var formDataValues = {
            'specifications': specificationsSelectIds,
            'defaultSpecifications': defaultSpecifications,
            'frameClearance': frameClearance,
            'gasketClearance': gasketClearance,
            'beadRadio': beadRadio,

            'id': selectItemDetails?.id,
            'collectionId': selectItemDetails?.collectionId,
            'name': selectItemDetails?.name,
            'description': selectItemDetails?.description,
            'colourId': selectItemDetails?.colourId,
            'frameStyleId': selectItemDetails?.frameStyleId,
            'jointsId': selectItemDetails?.jointsId,
            'defaultTransomDrop': selectItemDetails?.defaultTransomDrop,
            'defaultSideHungWidth': selectItemDetails?.defaultSideHungWidth,
            'defaultSill': selectItemDetails?.defaultSill,
            'defaultWindow': selectItemDetails?.defaultWindow,

            'profileId': selectItemDetails?.profileId,
            'subProductId': selectItemDetails?.subProductId,
            'hardwareId': selectItemDetails?.hardwareId,
        }

        updateItemDetailsServices(formDataValues, "Glazing")
    }

    return (
        <Form className='h-100 d-flex flex-column flex-grow-1 justify-content-between'>
            <div className='inside_scroll has_tab has_btns has_head'>
                <Row>
                    <Col xxl="12">
                        <Row>
                            <Col lg="6">
                                <FormGroup className="form-group">
                                    <FormControl fullWidth>
                                        <Label for="specifications">
                                            Specifications
                                        </Label>

                                        <Select
                                            labelId="specifications-label"
                                            id="specifications"
                                            multiple
                                            value={specifications}
                                            onChange={(e) => handleSpecificationsChange(e)}
                                            input={<OutlinedInput name='specifications' />}
                                            renderValue={(selected) => selected.join(', ')}
                                            MenuProps={MenuProps}

                                            IconComponent={() => (
                                                <MdOutlineKeyboardArrowDown size={20} className='me-1 MuiSvgIcon-root MuiSelect-icon' />
                                            )}
                                        >
                                            {specificationsOptions && specificationsOptions?.map((item, index) => (
                                                <MenuItem key={index} value={item.name} className='check_list'>
                                                    <Checkbox checked={specifications?.indexOf(item.name) > -1} />
                                                    <ListItemText primary={item.name} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </FormGroup>
                            </Col>

                            <Col lg="6">
                                <FormGroup className="form-group">
                                    <Label for="defaultSpecifications">
                                        Default specifications
                                    </Label>

                                    <Select
                                        labelId="defaultSpecifications"
                                        id="defaultSpecifications"
                                        value={defaultSpecifications || ''}
                                        label="defaultSpecifications"
                                        onChange={(e) => { setDefaultSpecifications(e.target.value) }}
                                        input={<OutlinedInput className='w-100' name='defaultSpecifications' />}

                                        MenuProps={MenuProps}

                                        IconComponent={() => (
                                            <MdOutlineKeyboardArrowDown size={20} className='me-1 MuiSvgIcon-root MuiSelect-icon' />
                                        )}
                                    >
                                        {defaultSpecificationsOptions && defaultSpecificationsOptions?.map((o, index) => (
                                            <MenuItem key={index} value={o?.id}>
                                                {o?.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormGroup>
                            </Col>

                            <Col lg="6">
                                <FormGroup className="form-group">
                                    <Label for="frameClearance">Frame clearance</Label>

                                    <NumberInputDecimal
                                        name="frameClearance"
                                        value={frameClearance}
                                        setValue={setFrameClearance}
                                    />
                                </FormGroup>
                            </Col>

                            <Col lg="6">
                                <FormGroup className="form-group">
                                    <Label for="gasketClearance">Gasket clearance</Label>

                                    <NumberInputDecimal
                                        name="gasketClearance"
                                        value={gasketClearance}
                                        setValue={setGasketClearance}
                                    />
                                </FormGroup>
                            </Col>

                            <Col lg="6">
                                <FormGroup className="form-group">
                                    <Label for="beadRadio">Bead</Label>

                                    <FormControl className="mb-0 w-100">
                                        <RadioGroup
                                            aria-labelledby="feature-label"
                                            name="beadRadio"
                                            className='flex-row flex-wrap custom_x_gap'
                                            value={beadRadio}
                                            onChange={(e) => handleBeadSurface(e)}
                                        >
                                            <FormControlLabel value="Internal" control={<Radio />} label="Internal" />
                                            <FormControlLabel value="External" control={<Radio />} label="External" />
                                        </RadioGroup>
                                    </FormControl>
                                </FormGroup>
                            </Col>

                            <Col lg="6">
                                <Alert color="secondary" className="d-flex gap-2 p-2 w-100">
                                    <span>
                                        <MdInfoOutline size={18} />
                                    </span>
                                    <span>
                                        See specification collections for allowed textured and designs
                                    </span>
                                </Alert>
                            </Col>

                            {/* <Col lg="12" className='mb-5'>
                                <h4 className='main_title sub_title mb-3'>Cross section</h4>
                                <div className="d-flex gap-2 align-items-center flex-nowrap">
                                    <span className="css-502">Inside</span>
                                    <svg width="200" height="140"><g strokeWidth="1" transform="scale(2,2)"><g fill="rgba(73,72,71,0.2)" stroke="rgba(73,72,71,0.6)"><path d="M 0,70 0,30 7.5,30 7.5,50 92.5,50 92.5,30 100,30 100,70 Z "></path></g><path d="M 55,0 59,0 59,50 55,50 Z M 81,0 85,0 85,50 81,50 Z" fill="rgba(0,120,212,0.2)" stroke="rgba(0,120,212,0.6)"></path><path d="M 59,40 81,40 81,50 59,50 Z" fill="rgba(73,72,71,0.4)" stroke="rgba(73,72,71,0.8)"></path></g></svg>
                                    <span className="css-502">Outside</span>
                                </div>
                            </Col> */}
                        </Row>
                    </Col>
                </Row>
            </div>

            {isEditable && (
                <div className='d-flex gap-2 gap-xl-3 main_buttons'>
                    <Button
                        color="primary"
                        size="md"
                        className='px-4'
                        onClick={() => handleFormSubmit()}
                        disabled={!timestamp || !itemId}
                    >
                        Save Changes
                    </Button>

                    <Button
                        color="primary"
                        size="md"
                        outline
                        className='px-4'
                        onClick={() => handleFormReset()}
                        disabled={!timestamp}
                    >
                        Reset
                    </Button>
                </div>
            )}
        </Form>
    )
}

export default SystemGlazing