import React, { useState } from 'react'
import { MdArrowDownward, MdArrowUpward, MdClear, MdInfoOutline, MdOutlineKeyboardArrowDown } from 'react-icons/md';
import {
    Row,
    Col,
    Button,
    Label,
    FormGroup,
    Input,
    Form,
    Alert
} from 'reactstrap';
import { useProductPaletteContext } from '../../context/productPaletteContext';
import { Link, useNavigate } from 'react-router-dom';
import { AiOutlineArrowLeft, AiOutlineArrowRight } from 'react-icons/ai';
import { BsSquare } from 'react-icons/bs';
import { MenuItem, OutlinedInput, Select } from 'material-ui-core';
import { MenuProps } from '../../utility/data';
import CollectionColours from '../CollectionColours';
import { useEffect } from 'react';
import { addGlazingCollectionPalette, addUpdateGlazingCollection, deleteGlazingCollectionPalette, getGlazingCollectionPalette, getUniqueGlazingCollection, removeSpecificationInUsed, updateCollectionVisibleOrder } from '../../services/glazingServices';
import { useMessage } from '../../context/messageContext';
import { getGlazingspecInUsed } from '../../services/commonServices';
import UsedInProducts from '../UsedInProducts';
import { useRightContext } from '../../context/rightMenuContext';

const SpecificationCollection = ({ getAllCollectionServices, allCollection, tabType, setCommonHeading, setLoading, setUsedProductsFromCollection }) => {

    const [name, setName] = useState('')
    const [hiddenExternalColours, setHiddenExternalColours] = useState([])
    const [visibleExternalColors, setVisibleExternalColors] = useState([])
    const [texturesId, setTexturesId] = useState(1)
    const [selectedHiddenIndex, setSelectedHiddenIndex] = useState(0);
    const [selectedVisibleIndex, setSelectedVisibleIndex] = useState(0);
    const [visibleData, setVisibleData] = useState('')
    const [id, setId] = useState()
    const [usedProducts, setUsedProducts] = useState([])
    const { handleEdit, timestamp, setTimestamp, activeIndex, newItemList } = useProductPaletteContext()
    const { setSelectedRightItem, selectedRightItem } = useRightContext()

    const { setMessageType, setMessage } = useMessage()

    const navigate = useNavigate();

    const accessToken = localStorage.getItem('access_token');
    const firstEl = allCollection[0];

    const textures = [
        {
            id: 1,
            name: "Standard"
        },
    ]

    useEffect(() => {
        if (timestamp) {
            getGlazingspecInUsedService()
        };
    }, [timestamp]);

    useEffect(() => {
        if (allCollection?.length === 0) {
            setTimestamp('')
        }
    }, [allCollection])

    useEffect(() => {
        if (newItemList?.length === 0) {
            setTimestamp('')
            setName(' ')
        }
    }, [newItemList])

    useEffect(() => {
        if (firstEl && tabType === 'collections') {
            if (allCollection?.length && activeIndex !== 0 && tabType === 'collections') {
                setTimestamp(timestamp)
            } else {
                setTimestamp(firstEl?.id)
            }
        }
    }, [allCollection, timestamp, tabType])

    useEffect(() => {
        if (timestamp && tabType === 'collections') {
            getUniqueCollectionServices()
        }
    }, [timestamp])

    useEffect(() => {
        if (timestamp && tabType === "collections") {
            allGlazingCollectionPaletteServices()
        }
    }, [timestamp])

    useEffect(() => {
        setUsedProductsFromCollection(usedProducts);
    }, [usedProducts])



    const handleNameChange = (e) => {
        const value = e.target.value;
        if (e.target.value.length <= 50) {
            setName(value)
            handleEdit(value)
        }
    }

    const reset = () => {
        getUniqueCollectionServices()
        getAllCollectionServices()
    }

    const getUniqueCollectionServices = async () => {
        setLoading(true)
        const res = await getUniqueGlazingCollection(timestamp, accessToken)
        if (res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                // console.log(res.data, "75");
                const selectedRight = allCollection?.find((p) => p.id == timestamp)
                setSelectedRightItem(selectedRight)
                setName(res?.data.entity?.name)
                setCommonHeading(res?.data.entity?.name)
                setId(res?.data.entity?.id)
                setLoading(false)
            } else {
                setLoading(false)
                setMessage(res?.data?.message ? res.data.message : 'Something went wrong');
                setMessageType('error')
            }
        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')
            if (res?.message === "Network Error") {
                navigate('/error/network')
                setLoading(false)
            }

        }
    }

    const allGlazingCollectionPaletteServices = async () => {
        setLoading(true)
        const res = await getGlazingCollectionPalette(timestamp, 1, accessToken)
        if (res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                // console.log(res.data, "75");
                setVisibleExternalColors(res?.data?.entity?.visiblePalette)
                setHiddenExternalColours(res?.data?.entity?.hiddenPalette)
                setLoading(false)
            } else {
                setLoading(false)
                setMessage(res?.data?.message ? res.data.message : 'Something went wrong');
                setMessageType('error')
            }
        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')
            if (res?.message === "Network Error") {
                navigate('/error/network')
                setLoading(false)
            }

        }
    }

    const handleTextures = (e) => {
        const value = e.target.value
        setTexturesId(typeof value === 'string' ? value.split(',') : value)
    }

    const updateGlazingCollectionServices = async () => {
        setLoading(true)
        const data = {
            id: timestamp,
            name: name,
            typeId: 1,
            sequenceNo: "",
        }

        const res = await addUpdateGlazingCollection(data, accessToken)
        if (res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                setMessageType('success')
                setMessage(res?.data?.message ? res.data.message : 'Added new palette')
                getAllCollectionServices()
                getUniqueCollectionServices()
                setLoading(false)
            } else {
                setMessage(res?.data?.message ? res.data.message : 'Something went wrong')
                setMessageType('error')
                getAllCollectionServices()
                getUniqueCollectionServices()
                setLoading(false)

            }
        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')
            getAllCollectionServices()
            if (res?.message === "Network Error") {
                navigate('/error/network')
            }
        }
    }

    const addExternalProductService = async (paletteId) => {
        setLoading(true)
        const res = await addGlazingCollectionPalette(timestamp, paletteId, accessToken)
        if (res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                allGlazingCollectionPaletteServices()
                setLoading(false)
            } else {
                setLoading(false)
                setMessage(res?.data?.message ? res.data.message : 'Something went wrong');
                setMessageType('error')
            }
        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')
            if (res?.message === "Network Error") {
                navigate('/error/network')
                setLoading(false)
            }

        }
    }

    const deleteExternalColourServices = async (paletteId) => {
        setLoading(true)
        const res = await deleteGlazingCollectionPalette(paletteId, accessToken)
        if (res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                allGlazingCollectionPaletteServices()
                setLoading(false)
            } else {
                setLoading(false)
                setMessage(res?.data?.message ? res.data.message : 'Something went wrong');
                setMessageType('error')
                allGlazingCollectionPaletteServices()
            }
        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')
            if (res?.message === "Network Error") {
                navigate('/error/network')
                setLoading(false)
            }

        }
    }

    const defaultServices = async () => {
        console.log("Default Coll services");
    }



    const updateHiddenVisibleOrderServices = async (direction) => {
        setLoading(true)
        // console.log(visibleData ,  "251");
        const res = await updateCollectionVisibleOrder(accessToken, visibleData?.id, timestamp, direction)

        if (res && res && res.status === 200) {

            if (res?.data?.statusCode === 200) {
                allGlazingCollectionPaletteServices()

            } else {
                setLoading(false)
                setMessage(res?.data?.message ? res.data.message : 'Something went wrong');
                setMessageType('error')
            }
        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')

            if (res?.message === "Network Error") {
                navigate('/error/network')
            }
        }
    }

    const updateOrder = (direction) => {
        updateHiddenVisibleOrderServices(direction)

        if (direction === "UP") {
            setSelectedVisibleIndex(selectedVisibleIndex - 1)
        } else {
            setSelectedVisibleIndex(selectedVisibleIndex + 1)
        }
    }

    const getGlazingspecInUsedService = async () => {
        setLoading(true)

        const res = await getGlazingspecInUsed(accessToken, timestamp)

        if (res && res && res.status === 200) {
            if (res?.data?.statusCode === 200) {

                if (res?.data.entity.result !== null) {
                    setUsedProducts(res?.data.entity.result)

                } else {
                    setUsedProducts([])
                }

                setLoading(false)
            } else {
                setLoading(false)
                setMessage(res?.data?.message ? res.data.message : 'Something went wrong');
                setMessageType('error')
            }
        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')

            if (res?.message === "Network Error") {
                navigate('/error/network')
            }
        }
    }

    const removeSpecificationInUsedService = async (itemId) => {

        const res = await removeSpecificationInUsed(accessToken, itemId)

        if (res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                setMessage(res?.data?.message ? "Specification unlinked" : 'Specification unlinked');
                setMessageType('success')
                getGlazingspecInUsedService()

            } else {
                setMessage(res?.data?.message ? res.data.message : 'Something went wrong');
                setMessageType('error')
                getGlazingspecInUsedService()
            }
        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')
            if (res?.message === "Network Error") {
                navigate('/error/network')
                setLoading(false)
            }
            getGlazingspecInUsedService()
        }
    }

    return (
        <Form className='h-100 d-flex flex-column flex-grow-1 justify-content-between'>
            <div className='inside_scroll has_btns has_head'>

                <Row className='mb-2'>
                    <Col lg="12">
                        <Row className='align-items-center'>
                            <Col lg="6">
                                <FormGroup className="form-group">
                                    <Label for="name">
                                        Name
                                    </Label>
                                    <Input disabled={!selectedRightItem?.isSelf} className='mb-1' name='name' value={name} onChange={(e) => handleNameChange(e)} />
                                    {name && name.length > 49 && (
                                        <div className='mt-1 text-muted text-small'>Max 50 characters are allowed</div>
                                    )}

                                    {(!name && id) && (
                                        <div className='mt-1 text-danger text-small'>Name is required</div>
                                    )}
                                </FormGroup>
                            </Col>
                            <Col lg="6">
                                <FormGroup className="form-group">
                                    <Label for="texture">
                                        Textures
                                    </Label>
                                    <Select
                                        labelId="printdoc-label"
                                        id="texture"
                                        value={texturesId}
                                        disabled={!selectedRightItem?.isSelf}
                                        label="texture"
                                        onChange={handleTextures}
                                        input={<OutlinedInput className='w-100' name='texture' />}

                                        MenuProps={MenuProps}
                                        IconComponent={() => (
                                            <MdOutlineKeyboardArrowDown size={20} className='me-1 MuiSvgIcon-root MuiSelect-icon' />
                                        )}
                                    >
                                        {textures.map((item, index) => {
                                            return (
                                                <MenuItem value={item?.id} key={index} >{item?.name}</MenuItem>

                                            )
                                        })}
                                    </Select>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Col>
                    {newItemList?.length > 0 &&
                        <Col lg="12">
                            <CollectionColours shownColours={visibleExternalColors} hiddenColours={hiddenExternalColours} moveToRight={addExternalProductService} moveToLeft={deleteExternalColourServices} selectedHiddenIndex={selectedHiddenIndex} selectedVisibleIndex={selectedVisibleIndex} setSelectedVisibleIndex={setSelectedVisibleIndex} setSelectedHiddenIndex={setSelectedHiddenIndex} setVisibleData={setVisibleData} visibleData={visibleData} setDefaultColour={defaultServices} updateOrder={updateOrder} isSelf={selectedRightItem?.isSelf} />
                        </Col>
                    }

                    <Col sm="12" className='mt-5'>
                        <div className={`${!selectedRightItem?.isSelf ? "not_allowed" : ""}`}>
                            <h4 className="main_title sub_title">Used in products</h4>

                            <UsedInProducts data={usedProducts} handleRemove={removeSpecificationInUsedService} disabled={true} />
                        </div>
                    </Col>
                </Row>



            </div>
            {selectedRightItem?.isSelf && <div className='d-flex gap-2 gap-xl-3 main_buttons mt-4'>
                <Button
                    color="primary"
                    size="md"
                    className='px-4'
                    onClick={() => updateGlazingCollectionServices()}
                    disabled={allCollection?.length === 0 || !name || name.length > 49}
                >
                    Save Changes
                </Button>

                <Button
                    color="primary"
                    size="md"
                    outline
                    className='px-4'
                    onClick={() => reset()}
                    disabled={allCollection?.length === 0 || !name || name.length > 49}
                >
                    Reset
                </Button>
            </div>}
        </Form>
    )
}

export default SpecificationCollection