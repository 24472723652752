import React from "react";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import { Input } from "reactstrap";

const NumberInput = ({ name, value, setValue, disabled, className }) => {

    const handleValueUpdate = (e, type) => {
        e.preventDefault()

        // console.log(e.target.value, value, "123")

        if (!disabled) {
            if (e.target.value === undefined && value === '') {
                var newVal = 0
                setValue(newVal);
            } else {
                var newVal = ''

                if(type === 'minus') {
                    newVal = parseInt(value) - 1
                } else {
                    newVal = parseInt(value) + 1
                }
                setValue(newVal);
            }
        }
    }

    const handleonBlur = (e) => {
        if (!/[0-9]/.test(e.key)) {
            e.preventDefault();
        }
    }

    return (
        <>
            <div className='input_with_buttons'>
                <span className='up_down_btns'>
                    <span onClick={(e) => { handleValueUpdate(e, 'plus') }}>
                        <MdKeyboardArrowUp />
                    </span>
                    <span onClick={(e) => { handleValueUpdate(e, 'minus') }}>
                        <MdKeyboardArrowDown />
                    </span>
                </span>
                <Input type="number" className={`mb-1 ${className}`} id={name} name={name} value={value} disabled={disabled} onChange={(e) => setValue(e.target.value)} onKeyPress={(e) => { handleonBlur(e) }} />
            </div>
        </>
    );
}

export default NumberInput;