import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ErrorLayout from './error-layout';
import wipImage from '../../../../assets/img/network-error.svg';

const GeneralError = () => {
    // const [lastVisitedPage, setLastVisitedPage] = useState(null);
    // const location = useLocation();

    // useEffect(() => {
    //     setLastVisitedPage(localStorage.getItem('lastVisitedPage') || '/');
    // }, []);

    // useEffect(() => {
    //     const handleOnline = () => {
    //         window.location.href = lastVisitedPage;
    //     };

    //     window.addEventListener('online', handleOnline);

    //     return () => {
    //         window.removeEventListener('online', handleOnline);
    //     };
    // }, [lastVisitedPage]);

    // useEffect(() => {
    //     localStorage.setItem('lastVisitedPage', location.pathname);

    //     return () => {
    //         localStorage.removeItem('lastVisitedPage');
    //     };
    // }, [location.pathname]);

    return (
        <>  
            <ErrorLayout 
                code={wipImage} 
                text="Network Error"
                subText="Please check your internet connection"
                subText2=""
                layout="layout-2"
                pageType="full_cover"
            />
        </>
    );
};

export default GeneralError;
