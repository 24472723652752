import { MenuItem, OutlinedInput, Select } from "material-ui-core";
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import {
  MdArrowDownward,
  MdArrowUpward,
  MdOutlineKeyboardArrowDown,
} from "react-icons/md";
import { Row, Col, Button, Label, FormGroup, Input } from "reactstrap";
import {
  MenuProps,
  hardwareCollectionsDrop,
} from "../../../../../../utility/data";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { AiOutlineArrowRight } from "react-icons/ai";
import { Link } from "react-router-dom";
import { BsSquare } from "react-icons/bs";
import { useProductPaletteContext } from "../../../../../../context/productPaletteContext";
import { useMessage } from "../../../../../../context/messageContext";
import {
  getUniqueCollection,
  hiddenColourListExternal,
  visibleColourListExternal,
} from "../../../../../../services/productServices";
import CollectionColours from "../../../../../../components/CollectionColours";
import {
  UpdateVisibleHardwareStyleOrder,
  addHardwareStyles,
  addUpdateHardwareCollection,
  allHardwareCollectionStyles,
  deleteHardwareStyles,
  getUniqueHardwareCollection,
  updateHardwareStyleOrder,
} from "../../../../../../services/productHardwareServices";
import { getHardwareCollectionInUsed } from "../../../../../../services/commonServices";
import UsedInProducts from "../../../../../../components/UsedInProducts";
import { useRightContext } from "../../../../../../context/rightMenuContext";

const CollectionsLeft = ({
  setLoading,
  allCollection,
  getAllCollectionServices,
  setUsedProductsFromLeft,
}) => {
  const [name, setName] = useState("");
  const [hiddenExternalColours, setHiddenExternalColours] = useState([]);
  const [visibleExternalColors, setVisibleExternalColors] = useState([]);
  const [selectedHiddenIndex, setselectedHiddenIndex] = useState(0);
  const [selectedVisibleIndex, setSelectedVisibleIndex] = useState(0);
  const [selectedVisibleData, setSelectedVisibleData] = useState("");
  const [usedProducts, setUsedProducts] = useState([]);
  const [itemId, setItemId] = useState();

  const [styleType, setStyleType] = useState(1);

  const accessToken = localStorage.getItem("access_token");
  let firstVisibleEl = null;

  useEffect(() => {
    if (visibleExternalColors?.length > 0) {
      firstVisibleEl = visibleExternalColors[0]
    }
  }, [visibleExternalColors])

  const { timestamp, newItemList, handleEdit, setTimestamp, activeIndex } =
    useProductPaletteContext();

  const { setSelectedRightItem, selectedRightItem } = useRightContext()


  const { setMessage, setMessageType } = useMessage();

  const navigate = useNavigate();

  const firstElId = allCollection[0]?.id;

  useEffect(() => {
    if (newItemList?.length === 0) {
      setTimestamp("");
      setName("");
      setItemId();
    }
  }, [newItemList]);

  useEffect(() => {
    if (hardwareCollectionsDrop) {
      setStyleType(hardwareCollectionsDrop[0].id);
    }
  }, [hardwareCollectionsDrop]);

  useEffect(() => {
    if (firstElId) {
      if (allCollection?.length && activeIndex !== 0) {
        setTimestamp(timestamp);
      } else {
        setTimestamp(firstElId);
      }
    }
  }, [allCollection, timestamp]);

  useEffect(() => {
    if (firstVisibleEl) {
      if (visibleExternalColors?.length && selectedVisibleIndex !== 0) {
        setSelectedVisibleData(selectedVisibleData);
      } else {
        setSelectedVisibleData(firstVisibleEl);
      }
    }
  }, [selectedVisibleData]);

  useEffect(() => {
    if (timestamp) {
      getUniqueCollectionsServices();
    }
  }, [timestamp]);

  useEffect(() => {
    if (timestamp) {
      allCollectionStyleServices();
    }
  }, [timestamp, styleType]);

  const reset = () => {
    getUniqueCollectionsServices();
    getAllCollectionServices();
  };
  useEffect(() => {
    setUsedProductsFromLeft(usedProducts);
  }, [usedProducts])


  const updateVisibleOrderServices = async (direction) => {
    const res = await UpdateVisibleHardwareStyleOrder(
      selectedVisibleData?.id,
      timestamp,
      styleType,
      direction,
      accessToken
    );
    if (res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        setMessageType("success");
        setMessage(res?.data?.message);
        allCollectionStyleServices();
      } else {
        setMessage(res?.data?.message);
        setMessageType("error");
        setLoading(false);
        allCollectionStyleServices();
      }
    } else {
      setLoading(false);
      setMessage(res?.message ? res.message : "Something went wrong");
      setMessageType("error");
      allCollectionStyleServices();
      if (res?.message === "Network Error") {
        navigate("/error/network");
      }
    }
  };

  const updateOrderServices = async (direction, type) => {
    updateVisibleOrderServices(direction, type);

    if (direction === "UP") {
      setSelectedVisibleIndex(selectedVisibleIndex - 1);
    } else {
      setSelectedVisibleIndex(selectedVisibleIndex + 1);
    }
  };

  const updateCollectionServices = async () => {
    setLoading(true);
    const data = {
      id: timestamp,
      name: name,
    };
    const res = await addUpdateHardwareCollection(data, accessToken);
    if (res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        setMessageType("success");
        setMessage(
          res?.data?.message ? res?.data?.message : "Updated Collection"
        );
        getAllCollectionServices();
        setLoading(false);
      } else {
        setMessage(
          res?.data?.message ? res?.data?.message : "Something went wrong"
        );
        setMessageType("error");
        getAllCollectionServices();
        setLoading(false);
      }
    }
  };

  const addHardwareStyleServices = async (hardwareStyleId) => {
    const res = await addHardwareStyles(
      timestamp,
      styleType,
      hardwareStyleId,
      accessToken
    );
    if (res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        setMessageType("success");
        setMessage(res?.data?.message);
        allCollectionStyleServices();
      } else {
        setMessage(res?.data?.message);
        setMessageType("error");
        setLoading(false);
        allCollectionStyleServices();
      }
    } else {
      setLoading(false);
      setMessage(res?.message ? res.message : "Something went wrong");
      setMessageType("error");
      allCollectionStyleServices();
      if (res?.message === "Network Error") {
        navigate("/error/network");
      }
    }
  };

  const allCollectionStyleServices = async () => {
    const res = await allHardwareCollectionStyles(
      timestamp,
      accessToken,
      styleType
    );
    gethardwareCollectionInUsedService(timestamp);
    if (res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        setMessageType("success");
        setMessage(res?.data?.message);
        getAllCollectionServices();
        setLoading(false);
        setHiddenExternalColours(res?.data?.entity?.hiddenStyles);
        setVisibleExternalColors(res?.data?.entity?.visibleStyles);
      } else {
        setMessage(res?.data?.message);
        setMessageType("error");
        getAllCollectionServices();
        setLoading(false);
        setHiddenExternalColours(res?.entity?.hiddenStyles);
        setVisibleExternalColors(res?.entity?.visibleStyles);
      }
    } else {
      setLoading(false);
      setMessage(res?.message ? res.message : "Something went wrong");
      setMessageType("error");
      getAllCollectionServices();
      if (res?.message === "Network Error") {
        navigate("/error/network");
      }
    }
  };

  const getUniqueCollectionsServices = async () => {
    setLoading(true);
    const res = await getUniqueHardwareCollection(timestamp, accessToken);
    if (res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        const selectedRight = allCollection?.find((p) => p.id == timestamp)
        setSelectedRightItem(selectedRight)
        setName(res?.data.entity?.name);
        setItemId(res?.data.entity?.id);
        setTimestamp(timestamp);
        setLoading(false);
      } else {
        setLoading(false);
        setMessage(
          res?.data?.message ? res.data.message : "Something went wrong"
        );
        setMessageType("error");
      }
    } else {
      setLoading(false);
      setMessage(res?.message ? res.message : "Something went wrong");
      setMessageType("error");
      if (res?.message === "Network Error") {
        navigate("/error/network");
        setLoading(false);
      }
    }
  };

  const deleteCollectionStyleServices = async (styleId) => {
    setLoading(true);
    const res = await deleteHardwareStyles(styleId, accessToken);
    if (res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        setMessageType("success");
        setMessage(res?.data?.message);
        setLoading(false);
        allCollectionStyleServices();
      } else {
        setMessage(res?.data?.message);
        setMessageType("error");
        setLoading(false);
        allCollectionStyleServices();
      }
    } else {
      setLoading(false);
      setMessage(res?.message ? res.message : "Something went wrong");
      setMessageType("error");
      allCollectionStyleServices();
      if (res?.message === "Network Error") {
        navigate("/error/network");
      }
    }
  };

  const handleStyleType = (e) => {
    const value = e.target.value;
    setStyleType(typeof value === "string" ? value.split(",") : value);
  };

  const handleNameChange = (e) => {
    const value = e.target.value;
    setName(value);
    handleEdit(value);
  };

  const defaultServices = async () => {
    console.log("default");
    // const res = await setDefaultExternalColour(accessToken, selectedVisibleData?.primaryId)
    // if (res && res.status === 200) {
    //     if (res?.data?.statusCode === 200) {
    //         setMessage(res?.data?.message)
    //         setMessageType('success')
    //         setLoading(false)
    //         allCollectionStyleServices()
    //     } else {
    //         setLoading(false)
    //         setMessage(res?.data?.message);
    //         setMessageType('error')
    //         allCollectionStyleServices()
    //     }
    // } else {
    //     setLoading(false)
    //     setMessage(res?.message ? res.message : 'Something went wrong');
    //     setMessageType('error')
    //     // getAllColoursServices()
    //     if (res?.message === "Network Error") {
    //         navigate('/error/network')
    //         setLoading(false)
    //     }
    // }
  };
  const gethardwareCollectionInUsedService = async (id) => {
    setLoading(true);

    const res = await getHardwareCollectionInUsed(accessToken, id);

    if (res && res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        if (res.data.entity.result !== null) {
          setUsedProducts(res.data.entity.result);

        } else {
          setUsedProducts([]);
        }

        setLoading(false);
      } else {
        setLoading(false);
        setMessage(
          res?.data?.message ? res.data.message : "Something went wrong"
        );
        setMessageType("error");
      }
    } else {
      setLoading(false);
      setMessage(res?.message ? res.message : "Something went wrong");
      setMessageType("error");

      if (res?.message === "Network Error") {
      }
    }
  };
  return (
    <div className="d-flex flex-column h-100">
      {/* <h5 className='main_title'>Lorem ipsum dolor sit amet</h5> */}
      {/* <Nav tabs className="details_header border-0 nav-tabs-custom">
                <NavItem>
                    <NavLink
                        className={`${activeTab === "General" && 'active'}`}
                        onClick={() => noRefCheck("General")}
                    >
                        General
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={`${activeTab === "Discount" && 'active'}`}
                        onClick={() => noRefCheck("Discount")}
                    >
                        Discount
                    </NavLink>
                </NavItem>
            </Nav> */}
      <Form className="h-100 d-flex flex-column flex-grow-1 justify-content-between">
        <div className="inside_scroll has_btns has_head">
          {name && (
            <div className="d-flex align-items-center mb-3 left_header">
              <h5 className="mb-0 text-wrap text-break me-4 main_title">
                {name}
              </h5>{" "}
              <div></div>
            </div>
          )}
          <Row>
            <Col lg="12">
              <Row className="align-items-center">
                <Col lg="6">
                  <FormGroup className="form-group">
                    <Label for="name">Name</Label>
                    <Input
                      disabled={!selectedRightItem?.isSelf}
                      className="mb-1"
                      name="name"
                      value={name}
                      onChange={(e) => handleNameChange(e)}
                    />
                    {name && name.length > 49 && (
                      <div className="mt-1 text-muted text-small">
                        Max 50 characters are allowed
                      </div>
                    )}
                    {!name && itemId && (
                      <div className="mt-1 text-danger text-small">
                        Name is required
                      </div>
                    )}
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup className="form-group">
                    <Label for="handle" className="text-white">
                      Handle
                    </Label>
                    <Select
                      labelId="printdoc-label"
                      id="handle"
                      value={styleType}
                      disabled={!selectedRightItem?.isSelf}
                      label="handle"
                      onChange={handleStyleType}
                      input={<OutlinedInput className="w-100" name="handle" />}
                      MenuProps={MenuProps}
                      IconComponent={() => (
                        <MdOutlineKeyboardArrowDown
                          size={20}
                          className="me-1 MuiSvgIcon-root MuiSelect-icon"
                        />
                      )}
                    >
                      {hardwareCollectionsDrop.map((item, index) => {
                        return (
                          <MenuItem value={item?.id} key={index}>
                            {item?.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormGroup>
                </Col>
              </Row>
            </Col>
            {newItemList?.length > 0 && (
              <Col lg="12" className="mb-3">
                <CollectionColours
                  shownColours={visibleExternalColors}
                  hiddenColours={hiddenExternalColours}
                  moveToRight={addHardwareStyleServices}
                  moveToLeft={deleteCollectionStyleServices}
                  selectedHiddenIndex={selectedHiddenIndex}
                  selectedVisibleIndex={selectedVisibleIndex}
                  setSelectedVisibleIndex={setSelectedVisibleIndex}
                  setSelectedHiddenIndex={setselectedHiddenIndex}
                  setVisibleData={setSelectedVisibleData}
                  visibleData={selectedVisibleData}
                  setDefaultColour={defaultServices}
                  updateOrder={updateOrderServices}
                  isImage={true}
                  isHardwareCollection={true}
                  isSelf={selectedRightItem?.isSelf}
                />
              </Col>
            )}
          </Row>
          <div className={`${!selectedRightItem?.isSelf ? "not_allowed" : ""}`}>
            <h4 className="main_title sub_title">Used in products</h4>

            <UsedInProducts data={usedProducts} disabled={true} />
          </div>
        </div>
        {selectedRightItem?.isSelf && <div className="d-flex gap-2 gap-xl-3 main_buttons">
          <Button
            color="primary"
            size="md"
            className="px-4"
            onClick={() => updateCollectionServices()}
            disabled={
              newItemList?.length === 0 || name?.length > 49 || !name || !itemId
            }
          >
            Save Changes
          </Button>

          <Button
            color="primary"
            size="md"
            outline
            className="px-4"
            disabled={newItemList?.length === 0 || name?.length > 49 || !name}
            onClick={() => reset()}
          >
            Reset
          </Button>
        </div>}
      </Form>
    </div>
  );
};

export default CollectionsLeft;
