import React, { useState } from 'react'
import InnerLayout from "../../../../layouts/internalLayout"
import Left from './listLeft'
import { useMessage } from '../../../../context/messageContext'
import { Oval } from 'react-loader-spinner'
import { Link } from 'react-router-dom'
import { useRightContext } from '../../../../context/rightMenuContext'

const Quotations = () => {

  const accessToken = localStorage.getItem('access_token')

  const { userDataContext } = useRightContext('')
  const { setMessage, setMessageType } = useMessage();

  const [loading, setLoading] = useState(userDataContext.role !== 'Supplier' ? true : false);

  return (
    <>
      {loading && (
        <div className="loader_main full_loader">
          <Oval
            height={50}
            width={50}
            color="#44C8F5"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#44C8F5"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      )}

      {userDataContext && userDataContext.role !== 'Supplier' && (
        <InnerLayout
          pageTitle="Quotations"
          layoutType="full"
          leftContent={
            <Left
              accessToken={accessToken}
              setMessage={setMessage}
              setMessageType={setMessageType}
              userData={userDataContext}
              setLoading={setLoading}
            />
          }
          rightContent=""
          headerButton={userDataContext?.role !== "Super Admin" && (<Link to='/create-quotation'
            className='px-3 btn btn-primary'
          >
            Create Quotation
          </Link>)}
        />
      )}
    </>
  );
}

export default Quotations