import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap';
import {
    Row,
    Col,
    Button,
    FormGroup,
    Label,
    Input,
} from 'reactstrap';
import { useRightContext } from '../context/rightMenuContext';

const DocsManufacturing = ({ accessToken, selectItemDetails, updateItemDetailsServices, handleFormReset }) => {
    const { timestamp } = useRightContext()
    const [individualCuttingList, setIndividualCuttingList] = useState(false)

    const [indCLFirstCut, setIndCLFirstCut] = useState(false)
    const [indCLFinishedCut, setIndCLFinishedCut] = useState(false)
    const [indCLLength, setIndCLLength] = useState(false)
    const [indCLEndPrep, setIndCLEndPrep] = useState(false)

    const [indGlassSize, setIndGlassSize] = useState(false)
    const [indPanelSize, setIndPanelSize] = useState(false)
    const [indDoorSize, setIndDoorSize] = useState(false)

    const [combCutList, setCombCutList] = useState(false)

    const [combCDFirstCut, setCombCDFirstCut] = useState(false)
    const [combCDFinishedCut, setCombCDFinishedCut] = useState(false)

    const [combCLFirstCut, setCombCLFirstCut] = useState(false)
    const [combCLFinishedCut, setCombCLFinishedCut] = useState(false)

    const [combGlassSize, setCombGlassSize] = useState(false)
    const [combPanelSize, setCombPanelSize] = useState(false)
    const [combinedCuttingDiagram, setCombinedCuttingDiagram] = useState(false)

    const [combDoorSize, setCombDoorSize] = useState(false)

    // setting all the value for prefilled inputs
    useEffect(() => {
        if(selectItemDetails && (selectItemDetails.id || selectItemDetails.initialValue)) {

            console.log(selectItemDetails, "selectItemDetails")
    
            setIndividualCuttingList(selectItemDetails?.individualCuttingList)
            setIndCLFirstCut(selectItemDetails?.indCLFirstCut)
            setIndCLFinishedCut(selectItemDetails?.indCLFinishedCut)
            setIndCLLength(selectItemDetails?.indCLLength)
            setIndCLEndPrep(selectItemDetails?.indCLEndPrep)
            setIndGlassSize(selectItemDetails?.indGlassSize)
            setIndPanelSize(selectItemDetails?.indPanelSize)
            setIndDoorSize(selectItemDetails?.indDoorSize)
    
            setCombCutList(selectItemDetails?.combinedProfileCuttingList)
            setCombCDFirstCut(selectItemDetails?.combCDFirstCut)
            setCombCDFinishedCut(selectItemDetails?.combCDFinishedCut)
            setCombCLFirstCut(selectItemDetails?.combCLFirstCut)
            setCombCLFinishedCut(selectItemDetails?.combCLFinishedCut)
            setCombGlassSize(selectItemDetails?.combGlassSize)
            setCombPanelSize(selectItemDetails?.combPanelSize)
            setCombinedCuttingDiagram(selectItemDetails?.combinedCuttingDiagram)
    
            setCombDoorSize(selectItemDetails?.combDoorSize)
    
            // if (selectItemDetails?.combCLFirstCut || selectItemDetails?.combCLFinishedCut) {
            //     setCombCutList(true)
            // } else {
            //     setCombCutList(false)
            // }
        }
    }, [selectItemDetails])

    const handleFormSubmit = () => {

        var formDataValues = {
            'id': selectItemDetails?.id,

            'individualCuttingList': individualCuttingList,
            'indCLFirstCut': indCLFirstCut,
            'indCLFinishedCut': indCLFinishedCut,
            'indCLLength': indCLLength,
            'indCLEndPrep': indCLEndPrep,
            'indGlassSize': indGlassSize,
            'indPanelSize': indPanelSize,
            'indDoorSize': indDoorSize,

            'combCutList': combCutList,
            'combCDFirstCut': combCDFirstCut,
            'combCDFinishedCut': combCDFinishedCut,
            'combCLFirstCut': combCLFirstCut,
            'combCLFinishedCut': combCLFinishedCut,
            'combGlassSize': combGlassSize,
            'combPanelSize': combPanelSize,
            'combinedCuttingDiagram': combinedCuttingDiagram,
            "combDoorSize": combDoorSize,
        }

        updateItemDetailsServices(formDataValues, "Manufacturing")
    }

    return (
        <Form className='h-100 d-flex flex-column flex-grow-1 justify-content-between'>
            <div className='inside_scroll has_tab has_btns has_head'>

                <h5 className='main_title sub_title'>
                    Individual frame
                </h5>

                <Row className='mb-4'>
                    <Col xs="6" sm="6" xl="3" xxl="3">
                        <FormGroup className="form-group">
                            <Label for="individualCuttingList">
                                Profile cutting list
                            </Label>
                            <Form.Check // prettier-ignore
                                type="switch"
                                id="individualCuttingList"
                                className='form-switch-lg'
                                name='individualCuttingList'
                                onChange={(e) => setIndividualCuttingList(!individualCuttingList)}
                                value={individualCuttingList || false}
                                checked={individualCuttingList}
                                label={individualCuttingList ? 'On' : 'Off'}
                            />

                            <div className={`ps-2 mt-3 ${!individualCuttingList ? 'opacity-75' : ''}`}>
                                <FormGroup check>
                                    <Input type="checkbox"
                                        name='indCLFirstCut'
                                        id='indCLFirstCut'
                                        disabled={!individualCuttingList}
                                        checked={indCLFirstCut}
                                        onChange={(e) => setIndCLFirstCut(!indCLFirstCut)}
                                    />
                                    <Label check for="indCLFirstCut">
                                        First cut
                                    </Label>
                                </FormGroup>

                                <FormGroup check>
                                    <Input
                                        type="checkbox"
                                        name="indCLFinishedCut"
                                        id="indCLFinishedCut"
                                        disabled={!individualCuttingList}
                                        checked={indCLFinishedCut}
                                        onChange={(e) => setIndCLFinishedCut(!indCLFinishedCut)}
                                    />
                                    <Label check for="indCLFinishedCut">
                                        Finished cut
                                    </Label>
                                </FormGroup>

                                <FormGroup check>
                                    <Input
                                        type="checkbox"
                                        name="indCLLength"
                                        id="indCLLength"
                                        disabled={!individualCuttingList}
                                        checked={indCLLength}
                                        onChange={(e) => setIndCLLength(!indCLLength)}
                                    />
                                    <Label check for="indCLLength">
                                        Length
                                    </Label>
                                </FormGroup>

                                <FormGroup check>
                                    <Input
                                        type="checkbox"
                                        name="indCLEndPrep"
                                        id="indCLEndPrep"
                                        disabled={!individualCuttingList}
                                        checked={indCLEndPrep}
                                        onChange={(e) => setIndCLEndPrep(!indCLEndPrep)}
                                    />
                                    <Label check for="indCLEndPrep">
                                        End prep
                                    </Label>
                                </FormGroup>
                            </div>
                        </FormGroup>
                    </Col>

                    <Col xs="6" sm="6" xl="3" xxl="3">
                        <FormGroup className="form-group">
                            <Label for="indGlassSize">
                                Glass sizes
                            </Label>
                            <Form.Check // prettier-ignore
                                type="switch"
                                id="indGlassSize"
                                className='form-switch-lg'
                                name='indGlassSize'
                                onChange={(e) => setIndGlassSize(!indGlassSize)}
                                value={indGlassSize || false}
                                checked={indGlassSize}
                                label={indGlassSize ? 'On' : 'Off'}
                            />
                        </FormGroup>
                    </Col>

                    <Col xs="6" sm="6" xl="3" xxl="3">
                        <FormGroup className="form-group">
                            <Label for="indPanelSize">
                                Panel sizes
                            </Label>
                            <Form.Check // prettier-ignore
                                type="switch"
                                id="indPanelSize"
                                className='form-switch-lg'
                                name='indPanelSize'
                                onChange={(e) => setIndPanelSize(!indPanelSize)}
                                value={indPanelSize || false}
                                checked={indPanelSize}
                                label={indPanelSize ? 'On' : 'Off'}
                            />
                        </FormGroup>
                    </Col>

                    {/* <Col xs="6" sm="6" xl="3" xxl="3" className='ps-xl-0 ps-xxl-3'>
                        <FormGroup className="form-group">
                            <Label for="indDoorSize">
                                Composite door sizes
                            </Label>
                            <Form.Check // prettier-ignore
                                type="switch"
                                id="indDoorSize"
                                className='form-switch-lg'
                                name='indDoorSize'
                                onChange={(e) => setIndDoorSize(!indDoorSize)}
                                value={indDoorSize || false}
                                checked={indDoorSize}
                                label={indDoorSize ? 'On' : 'Off'}
                            />
                        </FormGroup>
                    </Col> */}
                </Row>

                <h5 className='main_title sub_title'>
                    Combined frames
                </h5>
                
                <Row>
                    <Col xs="6" sm="6" xl="3" xxl="3">
                        <FormGroup className="form-group">
                            <Label for="combinedCuttingDiagram">
                                Profile cutting diagram
                            </Label>
                            <Form.Check // prettier-ignore
                                type="switch"
                                id="combinedCuttingDiagram"
                                className='form-switch-lg'
                                name='combinedCuttingDiagram'
                                onChange={(e) => setCombinedCuttingDiagram(!combinedCuttingDiagram)}
                                value={combinedCuttingDiagram || false}
                                checked={combinedCuttingDiagram}
                                label={combinedCuttingDiagram ? 'On' : 'Off'}
                            />

                            <div className={`ps-2 mt-3 ${!combinedCuttingDiagram ? 'opacity-75' : ''}`}>
                                <FormGroup check>
                                    <Input type="checkbox"
                                        name='combCDFirstCut'
                                        id='combCDFirstCut'
                                        disabled={!combinedCuttingDiagram}
                                        checked={combCDFirstCut}
                                        onChange={(e) => setCombCDFirstCut(!combCDFirstCut)}
                                    />
                                    <Label check for="combCDFirstCut">
                                        First cut
                                    </Label>
                                </FormGroup>

                                <FormGroup check>
                                    <Input
                                        type="checkbox"
                                        name="combCDFinishedCut"
                                        id="combCDFinishedCut"
                                        disabled={!combinedCuttingDiagram}
                                        checked={combCDFinishedCut}
                                        onChange={(e) => setCombCDFinishedCut(!combCDFinishedCut)}
                                    />
                                    <Label check for="combCDFinishedCut">
                                        Finished cut
                                    </Label>
                                </FormGroup>
                            </div>
                        </FormGroup>
                    </Col>

                    <Col xs="6" sm="6" xl="3" xxl="3">
                        <FormGroup className="form-group">
                            <Label for="combCutList">
                                Profile cutting list
                            </Label>
                            <Form.Check // prettier-ignore
                                type="switch"
                                id="combCutList"
                                className='form-switch-lg'
                                name='combCutList'
                                onChange={(e) => setCombCutList(!combCutList)}
                                value={combCutList || false}
                                checked={combCutList}
                                label={combCutList ? 'On' : 'Off'}
                            />

                            <div className={`ps-2 mt-3 ${!combCutList ? 'opacity-75' : ''}`}>
                                <FormGroup check>
                                    <Input
                                        type="checkbox"
                                        name="combCLFirstCut"
                                        id="combCLFirstCut"
                                        disabled={!combCutList}
                                        checked={combCLFirstCut}
                                        onChange={(e) => setCombCLFirstCut(!combCLFirstCut)}
                                    />
                                    <Label check for="combCLFirstCut">
                                        First cut
                                    </Label>
                                </FormGroup>

                                <FormGroup check>
                                    <Input
                                        type="checkbox"
                                        name="combCLFinishedCut"
                                        id="combCLFinishedCut"
                                        disabled={!combCutList}
                                        checked={combCLFinishedCut}
                                        onChange={(e) => setCombCLFinishedCut(!combCLFinishedCut)}
                                    />
                                    <Label check for="combCLFinishedCut">
                                        Finished cut
                                    </Label>
                                </FormGroup>
                            </div>
                        </FormGroup>
                    </Col>

                    <Col xs="6" sm="6" xl="3" xxl="3">
                        <FormGroup className="form-group">
                            <Label for="combGlassSize">
                                Glass sizes
                            </Label>
                            <Form.Check // prettier-ignore
                                type="switch"
                                id="combGlassSize"
                                className='form-switch-lg'
                                name='combGlassSize'
                                onChange={(e) => setCombGlassSize(!combGlassSize)}
                                value={combGlassSize || false}
                                checked={combGlassSize}
                                label={combGlassSize ? 'On' : 'Off'}
                            />
                        </FormGroup>
                    </Col>

                    <Col xs="6" sm="6" xl="3" xxl="3">
                        <FormGroup className="form-group">
                            <Label for="combPanelSize">
                                Panel sizes
                            </Label>
                            <Form.Check // prettier-ignore
                                type="switch"
                                id="combPanelSize"
                                className='form-switch-lg'
                                name='combPanelSize'
                                onChange={(e) => setCombPanelSize(!combPanelSize)}
                                value={combPanelSize || false}
                                checked={combPanelSize}
                                label={combPanelSize ? 'On' : 'Off'}
                            />
                        </FormGroup>
                    </Col>

                    {/* <Col xs="6" sm="6" xl="3" xxl="3">
                        <FormGroup className="form-group">
                            <Label for="combDoorSize">
                                Composite door sizes
                            </Label>
                            <Form.Check // prettier-ignore
                                type="switch"
                                id="combDoorSize"
                                className='form-switch-lg'
                                name='combDoorSize'
                                onChange={(e) => setCombDoorSize(!combDoorSize)}
                                value={combDoorSize || false}
                                checked={combDoorSize}
                                label={combDoorSize ? 'On' : 'Off'}
                            />
                        </FormGroup>
                    </Col> */}
                </Row>
            </div>

            <div className='d-flex gap-2 gap-xl-3 main_buttons'>
                <Button
                    color="primary"
                    size="md"
                    className='px-4'

                    onClick={() => handleFormSubmit()}
                    disabled={!timestamp}
                >
                    Save Changes
                </Button>

                <Button
                    color="primary"
                    size="md"
                    outline
                    className='px-4'
                    onClick={() => handleFormReset()}
                    disabled={!timestamp || selectItemDetails?.initialValue}
                >
                    Reset
                </Button>
            </div>
        </Form>
    )
}

export default DocsManufacturing