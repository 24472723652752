import React from 'react'
import ErrorLayout from './error-layout'
import { BiSolidBuoy } from 'react-icons/bi'

const Error500 = () => {
    return (
        <>
            <ErrorLayout 
                code={<h1 className="display-1 fw-medium">5<BiSolidBuoy className='bx-spin text-primary display-3' />0</h1>} 
                text="Sorry, It.s not you. It's us"
                subText="We're experiencing an internal server problem. Please try again later"
            />
        </>
    )
}

export default Error500