import * as THREE from 'three';
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';
import { setInitialColors } from '../helper';
// import evesFrameProfile from "../../assets/models/eves.glb"

export function addFrameStyle(element, targetPosition, targetName, data, gltfModel, sceneRef, Frame, profileJointDetails, frameStyleTop, frameStyleLeft, frameStyleBottom, frameStyleRight, setMultiSelectRefPoints, internalFrameRef, externalFrameRef, rgbStringToHex, internalRAL, internalColor, externalRAL, externalColor, customModelData, setInternal, setExternal, headerSelectedItem) {
    // element - "Path of the element"
    // targetPosition - "Position of the element"
    // targetName - side name for eg top bottom
    const width = (data?.width / 80);
    const height = (data?.height / 40);

    let addedNewFrame;

    if (element && !element.includes(undefined)) {
        if (targetName?.includes("FrameBottom")) {
            //scale up element x so that it matches model (gltfMode.current) width

            // Instantiate a loader
            const loader = new GLTFLoader();

            // Load a GLTF model
            loader.load(element, (gltf) => {
                addedNewFrame = gltf.scene

                gltf.scene.traverse((child) => {
                    if (child.name.includes("Base")) {
                        base = child;
                        if (base) {
                            bB = new THREE.Box3().setFromObject(base);
                            baseMax = bB.max.y;
                        }

                        child.scale.z = width + (profileJointDetails.frame !== 3 ? 0.01 : 0);

                        child.scale.y = height;

                        if (base) {
                            bB = new THREE.Box3().setFromObject(base);

                            base.position.y = baseMax - ((bB.max.y - bB.min.y) / 2);
                        }
                    }
                })

                gltf.scene.traverse((child) => {
                    if (child.name.includes("External")) {
                        externalFrameRef.current.push(child);
                    } else {
                        internalFrameRef.current.push(child);
                    }

                    setInitialColors(rgbStringToHex, internalRAL, internalColor, externalRAL, externalColor, customModelData, setInternal, setExternal)



                    if (child.name.includes("TrackLeft")) {
                        bB2 = new THREE.Box3().setFromObject(child);
                        const width = bB2.max.z - bB2.min.z;
                        child.position.z = bB.min.z + width / 2;

                        trackLeft = child;
                    }

                    if (child.name.includes("TrackRight")) {
                        bB2 = new THREE.Box3().setFromObject(child);
                        const width = bB2.max.z - bB2.min.z;
                        child.position.z = bB.max.z - width / 2;

                        trackRight = child;
                    }

                    if (child.name.includes("side")) {
                        bB2 = new THREE.Box3().setFromObject(child);
                        const width = bB2.max.z - bB2.min.z;
                        child.position.z = bB.max.z - width / 2;
                    }

                    if (child.name.includes("Bead")) {
                        //add little space between frame and bead
                        child.position.y += 0.002;
                    }
                })

                const boundingBox = new THREE.Box3().setFromObject(frameStyleBottom.current);

                // Calculate the width of the bounding box
                const modelWidth = boundingBox.max.x - boundingBox.min.x;

                const boundingBox2 = new THREE.Box3().setFromObject(gltf.scene);
                const modelWidth2 = boundingBox2.max.x - boundingBox2.min.x;

                // Calculate the scaling factor to match the width of the model
                let scaleFactor
                scaleFactor = modelWidth / modelWidth2;

                if (targetPosition) {
                    gltf.scene.position.set(targetPosition.x, targetPosition.y, targetPosition.z);
                }

                // Scale up the element
                if (headerSelectedItem.name === "Eaves Frame") {
                    gltf.scene.scale.x = scaleFactor + (headerSelectedItem.name === "Eaves Frame" ? 0.15 : 0.3);
                } else {
                    gltf.scene.scale.x = scaleFactor;
                }
                // Add the element to the scene
                sceneRef.current.remove(frameStyleBottom.current)
                frameStyleBottom.current = gltf.scene;
                Frame.current.push(gltf.scene);
                gltf.scene.name = "FrameBottom";

                if(headerSelectedItem.name !== "Oriel Window"){
                    sceneRef.current.add(gltf.scene);
    
              
                    gltfModel.current.add(gltf.scene)
          
                }

            });
            setMultiSelectRefPoints([])
        }

        let scaleFacTop = 0
        let scaleFacLeft = 0
        let center = 0

        if (targetName?.includes("FrameTop")) {

            // Instantiate a loader
            const loader = new GLTFLoader();

            var base, trackLeft, trackRight;
            var bB, bB2, boundingBox3;
            var baseMax;
            // Load a GLTF model
            loader.load(element, (gltf) => {

                gltf.scene.traverse((child) => {
                    if (child?.name?.includes("Base")) {

                        base = child;
                        if (base) {
                            bB = new THREE.Box3().setFromObject(base);
                            baseMax = bB.max.y;
                        }

                        child.scale.z = width + (profileJointDetails.frame !== 3 ? 0.01 : 0);
                        child.scale.y = height;

                        if (base) {
                            bB = new THREE.Box3().setFromObject(base);
                            let adjustmentValue = headerSelectedItem?.name === "Oriel Window" ? 0.014 : 0

                            base.position.y = baseMax - ((bB.max.y - bB.min.y) / 2) + adjustmentValue;
                        }
                    }

                })

                addedNewFrame = gltf.scene

                gltf.scene.traverse((child) => {

                    if (child.name.includes("External")) {
                        externalFrameRef.current.push(child);
                    } else {
                        internalFrameRef.current.push(child);
                    }



                    setInitialColors(rgbStringToHex, internalRAL, internalColor, externalRAL, externalColor, customModelData, setInternal, setExternal)

                    if (child?.name?.includes("Base")) {

                        base = child;
                        if (base) {
                            bB = new THREE.Box3().setFromObject(base);
                            baseMax = bB.max.y;
                        }

                        child.scale.z = width + (profileJointDetails.frame !== 3 ? 0.01 : 0);
                        child.scale.y = height;

                        if (base) {
                            bB = new THREE.Box3().setFromObject(base);

                            base.position.y = baseMax - ((bB.max.y - bB.min.y) / 2);
                        }
                    }

                    if (child?.name?.includes("TrackLeft")) {
                        bB2 = new THREE.Box3().setFromObject(child);
                        const width = bB2.max.z - bB2.min.z;
                        child.position.z = bB.min.z + width / 2;

                        trackLeft = child;
                    }

                    if (child?.name?.includes("TrackRight")) {
                        bB2 = new THREE.Box3().setFromObject(child);
                        const width = bB2.max.z - bB2.min.z;
                        child.position.z = bB.max.z - width / 2;

                        trackRight = child;
                    }

                    if (child.name.includes("side")) {
                        bB2 = new THREE.Box3().setFromObject(child);
                        const width = bB2.max.z - bB2.min.z;
                        child.position.z = bB.max.z - width / 2;
                    }

                    if (child.name.includes("Bead")) {
                        //add little space between frame and bead
                        child.position.y += 0.002;
                    }
                })

                //	base.position.y = boundingBox2.max.y - boundingBox.height/2;

                const boundingBox = new THREE.Box3().setFromObject(frameStyleTop.current);

                // Calculate the width of the bounding box
                const modelWidth = boundingBox.max.x - boundingBox.min.x;

                const boundingBox2 = new THREE.Box3().setFromObject(gltf.scene);
                const modelWidth2 = boundingBox2.max.x - boundingBox2.min.x;
                // Calculate the scaling factor to match the width of the model
                let scaleFactor;

                // if (headerSelectedItem?.name === "Eaves Frame") {
                //     scaleFactor = (modelWidth ) / modelWidth2;
                // } else {
                scaleFactor = modelWidth / modelWidth2;
                // }

                scaleFacTop = scaleFactor

                center = boundingBox2.min.x + 1 / 4 * modelWidth
                gltf.scene.position.set(targetPosition.x, targetPosition.y, targetPosition.z);
                // Scale up the element
                if (headerSelectedItem.name === "Oriel Window" || headerSelectedItem.name === "Eaves Frame") {
                    gltf.scene.scale.x = scaleFactor + (headerSelectedItem.name === "Eaves Frame" ? 0.15 : 0);
                } else {
                    gltf.scene.scale.x = scaleFactor;
                }

                gltf.scene.rotation.set(0, 0, Math.PI);
                // Add the element to the scene
                
                sceneRef.current.add(gltf.scene);

                sceneRef.current.remove(frameStyleTop.current)
                frameStyleTop.current = gltf.scene;
                gltf.scene.name = "FrameTop";

                gltfModel.current.add(gltf.scene)
                Frame.current.push(gltf.scene);
            });
            setMultiSelectRefPoints([])

        }

        if (targetName?.includes("FrameLeft")) {
            //scale up element x so that it matches model (gltfMode.current) width

            // Instantiate a loader
            const loader = new GLTFLoader();

            // Load a GLTF model
            loader.load(element, (gltf) => {

                addedNewFrame = gltf.scene


                gltf.scene.traverse((child) => {
                    if (child.name.includes("Base")) {
                        base = child;
                        if (base) {
                            bB = new THREE.Box3().setFromObject(base);
                            baseMax = bB.max.y;
                        }

                        child.scale.z = width + (profileJointDetails.frame !== 3 ? 0.01 : 0);
                        child.scale.y = height;

                        if (base) {
                            bB = new THREE.Box3().setFromObject(base);

                            base.position.y = baseMax - ((bB.max.y - bB.min.y) / 2);
                        }
                    }
                })
                gltf.scene.traverse((child) => {

                    if (child.name.includes("External")) {
                        externalFrameRef.current.push(child);
                    } else {
                        internalFrameRef.current.push(child);
                    }

                    setInitialColors(rgbStringToHex, internalRAL, internalColor, externalRAL, externalColor, customModelData, setInternal, setExternal)

                    // if (child.name.includes("Base")) {

                    //     base = child;
                    //     if (base) {
                    //         bB = new THREE.Box3().setFromObject(base);
                    //         baseMax = bB.max.y;
                    //     }

                    //     child.scale.z = width;
                    //     child.scale.y = height;

                    //     if (base) {
                    //         bB = new THREE.Box3().setFromObject(base);

                    //         base.position.y = baseMax - ((bB.max.y - bB.min.y) / 2);
                    //     }
                    // }

                    if (child?.name?.includes("TrackLeft")) {
                        bB2 = new THREE.Box3().setFromObject(child);
                        const width = bB2.max.z - bB2.min.z;
                        child.position.z = bB.min.z + width / 2;

                        trackLeft = child;
                    }

                    if (child?.name?.includes("TrackRight")) {
                        bB2 = new THREE.Box3().setFromObject(child);
                        const width = bB2.max.z - bB2.min.z;
                        child.position.z = bB.max.z - width / 2;

                        trackRight = child;
                    }

                    if (child.name.includes("side")) {
                        bB2 = new THREE.Box3().setFromObject(child);
                        const width = bB2.max.z - bB2.min.z;
                        child.position.z = bB.max.z - width / 2;
                    }

                    if (child.name.includes("Bead")) {
                        //add little space between frame and bead
                        child.position.y += 0.002;
                    }
                })

                const boundingBox = new THREE.Box3().setFromObject(frameStyleLeft.current);

                // Calculate the width of the bounding box
                const modelWidth = boundingBox.max.y - boundingBox.min.y;

                const boundingBox2 = new THREE.Box3().setFromObject(gltf.scene);
                const modelWidth2 = boundingBox2.max.x - boundingBox2.min.x;

                // Calculate the scaling factor to match the width of the model
                let scaleFactor;

         
                    scaleFactor = modelWidth / modelWidth2;
            
                scaleFacLeft = scaleFactor

                gltf.scene.position.set(targetPosition.x, targetPosition.y, targetPosition.z);
                // Scale up the element
                gltf.scene.scale.x = scaleFactor + (headerSelectedItem.name === "Eaves Frame" ?  0.15 : 0);
            
                gltf.scene.rotation.set(0, 0, Math.PI / 2);

                // Add the element to the scene
                sceneRef.current.add(gltf.scene);

                sceneRef.current.remove(frameStyleLeft.current)
                frameStyleLeft.current = gltf.scene;
                // let newClone = gltf.scene.clone()
                // newClone.scale.x = Math.pow((Math.pow(scaleFacLeft,2) + Math.pow(scaleFacTop,2)), 1/2)
                // newClone.position.set(center, 0, 0)
                // sceneRef.current.add(newClone);

                gltf.scene.name = "FrameLeft";
                Frame.current.push(gltf.scene);
                gltfModel.current.add(gltf.scene)

            });
            setMultiSelectRefPoints([])
        }

        if (targetName?.includes("FrameRight")) {

            const loader = new GLTFLoader();

            // Load a GLTF model
            loader.load(element, (gltf) => {

                gltf.scene.traverse((child) => {
                    if (child.name.includes("Base")) {
                        base = child;
                        if (base) {
                            bB = new THREE.Box3().setFromObject(base);
                            baseMax = bB.max.y;
                        }

                        child.scale.z = width + (profileJointDetails.frame !== 3 ? 0.01 : 0);
                        child.scale.y = height;

                        if (base) {
                            bB = new THREE.Box3().setFromObject(base);

                            base.position.y = baseMax - ((bB.max.y - bB.min.y) / 2);
                        }
                    }
                })




                gltf.scene.traverse((child) => {

                    if (child.name.includes("External")) {
                        externalFrameRef.current.push(child);
                    } else {
                        internalFrameRef.current.push(child);
                    }

                    setInitialColors(rgbStringToHex, internalRAL, internalColor, externalRAL, externalColor, customModelData, setInternal, setExternal)

                    if (child.name.includes("Base")) {

                        base = child;
                        if (base) {
                            bB = new THREE.Box3().setFromObject(base);
                            baseMax = bB.max.y;
                        }

                        child.scale.z = width;
                        child.scale.y = height;

                        if (base) {
                            bB = new THREE.Box3().setFromObject(base);

                            base.position.y = baseMax - ((bB.max.y - bB.min.y) / 2);
                        }
                    }

                    if (child?.name?.includes("TrackLeft")) {
                        bB2 = new THREE.Box3().setFromObject(child);
                        const width = bB2.max.z - bB2.min.z;
                        child.position.z = bB.min.z + width / 2;

                        trackLeft = child;
                    }

                    if (child?.name?.includes("TrackRight")) {
                        bB2 = new THREE.Box3().setFromObject(child);
                        const width = bB2.max.z - bB2.min.z;
                        child.position.z = bB.max.z - width / 2;

                        trackRight = child;
                    }

                    if (child.name.includes("side")) {
                        bB2 = new THREE.Box3().setFromObject(child);
                        const width = bB2.max.z - bB2.min.z;
                        child.position.z = bB.max.z - width / 2;
                    }

                    if (child.name.includes("Bead")) {
                        //add little space between frame and bead
                        child.position.y += 0.002;
                    }
                })

                const boundingBox = new THREE.Box3().setFromObject(frameStyleRight.current);

                const modelWidth = boundingBox.max.y - boundingBox.min.y;

                const boundingBox2 = new THREE.Box3().setFromObject(gltf.scene);

                const modelWidth2 = boundingBox2.max.x - boundingBox2.min.x;

                // Calculate the scaling factor to match the width of the model
                let scaleFactor;
   
                    scaleFactor = modelWidth / modelWidth2;
   

                gltf.scene.position.set(targetPosition.x, targetPosition.y, targetPosition.z);
                // Scale up the element
                gltf.scene.scale.x = scaleFactor + (headerSelectedItem.name === "Eaves Frame" ?  0.15 : 0);

                gltf.scene.rotation.set(0, 0, -Math.PI / 2);

                // Add the element to the scene
                sceneRef.current.add(gltf.scene);

                sceneRef.current.remove(frameStyleRight.current)
                frameStyleRight.current = gltf.scene;

                gltf.scene.name = "FrameRight";
                gltfModel.current.add(gltf.scene)
                Frame.current.push(gltf.scene);


            });
            setMultiSelectRefPoints([])

        }

        gltfModel?.current?.traverse((child) => {
            if (child.name.includes(targetName)) {
                child?.traverse((subChild) => {
                    subChild.visible = false
                })
                child.visible = false
            }
        })
    }
}

export function addCornerJoint(baseModel, cornerJointModel, sceneRef, frameStyleTop, frameStyleLeft, frameStyleBottom, frameStyleRight, profileJointDetails, internalFrameRef, externalFrameRef, rgbStringToHex, internalRAL, internalColor, externalRAL, externalColor, customModelData, setInternal, setExternal , headerSelectedItem) {

    if (profileJointDetails.frame !== 3) {
        return;
    }

    // removing Profile Joints before adding
    if (sceneRef.current) {
        var ProfileJoints

        sceneRef.current.traverse(function (object) {
            if (object.name.includes("ProfileJoints")) {
                object.visible = false;
                ProfileJoints = object
            }
        });

        if (ProfileJoints) {
            sceneRef.current.remove(ProfileJoints);
        }
    }

    if (!baseModel && !cornerJointModel) {
        console.error('baseModel is missing or undefined');
        return;
    }

    // Load the cornerJointModel
    const loader = new GLTFLoader();
    var baseModelSize;
    loader.load(cornerJointModel, function (gltf) {
        console.log("glb corner joint",556);

    
        const cornerJointModel = gltf.scene;

        // Create a bounding box around the baseModel and get its size
        if (baseModel) {
            baseModelSize = new THREE.Box3().setFromObject(baseModel).getSize(new THREE.Vector3());
        } else {
            console.error('baseModel does not have a geometry');
        }

        if (cornerJointModel) {

            const cornerJointSize = new THREE.Box3().setFromObject(cornerJointModel);

            let jointWidth = cornerJointSize.max.x - cornerJointSize.min.x
            let jointheight = cornerJointSize.max.y - cornerJointSize.min.y
            let jointDepth = cornerJointSize.max.z - cornerJointSize.min.z

            const boundingBoxTop = new THREE.Box3().setFromObject(frameStyleTop.current);
            const boundingBoxLeft = new THREE.Box3().setFromObject(frameStyleLeft.current);
            const boundingBoxRight = new THREE.Box3().setFromObject(frameStyleRight.current);
            const boundingBoxBottom = new THREE.Box3().setFromObject(frameStyleBottom.current);

    
            let material = null;
            if (headerSelectedItem.name === "Oriel Window" || headerSelectedItem.name === "Eaves Frame") {
                // Create an invisible transparent material
                material = new THREE.MeshBasicMaterial({
                    color: 0xffffff,
                    transparent: true,
                    opacity: 0,
                    depthWrite: false
                });
            }

            // adding top left joint
            if ((boundingBoxTop.max.x - boundingBoxTop.min.x) > 0 && ((boundingBoxTop.max.z.toFixed(4) - boundingBoxTop.min.z.toFixed(4)) == (boundingBoxRight.max.z.toFixed(4) - boundingBoxRight.min.z.toFixed(4)))) {

                const jointLeftTop = cornerJointModel.clone();

                jointLeftTop.scale.set(
                    ((boundingBoxRight.max.x - boundingBoxRight.min.x) / jointWidth),
                    ((boundingBoxTop.max.y - boundingBoxTop.min.y) / jointheight),
                );

                const jointLeftTopScale = new THREE.Box3().setFromObject(jointLeftTop);

                jointLeftTop.position.set(boundingBoxTop.min.x + (((jointLeftTopScale.max.x - jointLeftTopScale.min.x) / 2)), boundingBoxTop.max.y - (((jointLeftTopScale.max.y - jointLeftTopScale.min.y) / 2)), boundingBoxTop?.max.z + jointDepth + .0001);

                jointLeftTop.name = "ProfileJoints FrameTop FrameRight";

                // assign colors to all joints
                jointLeftTop.traverse((child) => {
                    if(material){
                        child.material = material
                    }
                    externalFrameRef.current.push(child);
                })

                var jointLeftTopBack = jointLeftTop.clone()
                jointLeftTopBack.position.z = boundingBoxTop?.min.z - jointDepth

                // assign colors to all joints
                jointLeftTopBack.traverse((child) => {
                    if(material){
                        child.material = material
                    }
                    internalFrameRef.current.push(child);
                })

                sceneRef.current.add(jointLeftTop);
                sceneRef.current.add(jointLeftTopBack);
            }

            // adding top right joint
            if ((boundingBoxTop.max.x - boundingBoxTop.min.x) > 0 && ((boundingBoxTop.max.z.toFixed(4) - boundingBoxTop.min.z.toFixed(4)) == (boundingBoxLeft.max.z.toFixed(4) - boundingBoxLeft.min.z.toFixed(4)))) {

                const jointRightTop = cornerJointModel.clone();

                jointRightTop.rotateZ(Math.PI / 2)

                jointRightTop.scale.set(
                    ((boundingBoxTop.max.y - boundingBoxTop.min.y) / jointheight),
                    ((boundingBoxLeft.max.x - boundingBoxLeft.min.x) / jointWidth),
                );

                const jointRightTopScale = new THREE.Box3().setFromObject(jointRightTop);

                jointRightTop.position.set(boundingBoxTop.max.x - (((jointRightTopScale.max.x - jointRightTopScale.min.x) / 2)), boundingBoxTop.max.y - (((jointRightTopScale.max.y - jointRightTopScale.min.y) / 2)), boundingBoxTop?.max.z + jointDepth + .0001);

                jointRightTop.name = "ProfileJoints FrameTop FrameLeft";

                // assign colors to all joints
                jointRightTop.traverse((child) => {
                    if(material){
                        child.material = material
                    }
                    externalFrameRef.current.push(child);
                })

                var jointRightTopBack = jointRightTop.clone()
                jointRightTopBack.position.z = boundingBoxTop?.min.z - jointDepth

                // assign colors to all joints
                jointRightTopBack.traverse((child) => {
                    if(material){
                        child.material = material
                    }
                    internalFrameRef.current.push(child);
                })

                sceneRef.current.add(jointRightTop);
                sceneRef.current.add(jointRightTopBack);
            }

            // adding bottom right joint
            if ((boundingBoxBottom.max.x - boundingBoxBottom.min.x) > 0 && ((boundingBoxBottom.max.z.toFixed(4) - boundingBoxBottom.min.z.toFixed(4)) == (boundingBoxLeft.max.z.toFixed(4) - boundingBoxLeft.min.z.toFixed(4)))) {
                const jointRightBottom = cornerJointModel.clone();

                jointRightBottom.scale.set(
                    ((boundingBoxLeft.max.x - boundingBoxLeft.min.x) / jointWidth),
                    ((boundingBoxBottom.max.y - boundingBoxBottom.min.y) / jointheight),
                );

                const jointRightBottomScale = new THREE.Box3().setFromObject(jointRightBottom);
                jointRightBottom.position.set(boundingBoxBottom.max.x - (((jointRightBottomScale.max.x - jointRightBottomScale.min.x) / 2)), boundingBoxBottom.min.y + (((jointRightBottomScale.max.y - jointRightBottomScale.min.y) / 2)), boundingBoxBottom?.max.z + jointDepth + .0001);

                jointRightBottom.name = "ProfileJoints FrameBottom FrameLeft";

                // assign colors to all joints
                jointRightBottom.traverse((child) => {
                    if(material){
                        child.material = material
                    }
                    externalFrameRef.current.push(child);
                })

                var jointRightBottomBack = jointRightBottom.clone()
                jointRightBottomBack.position.z = boundingBoxBottom?.min.z - jointDepth

                // assign colors to all joints
                jointRightBottomBack.traverse((child) => {
                    if(material){
                        child.material = material
                    }
                    internalFrameRef.current.push(child);
                })

                if (headerSelectedItem.name !== "Oriel Window") {
                    sceneRef.current.add(jointRightBottom);
                    sceneRef.current.add(jointRightBottomBack);
                }

            }

            // adding bottom left joint
            if ((boundingBoxBottom.max.x - boundingBoxBottom.min.x) > 0 && ((boundingBoxBottom.max.z.toFixed(4) - boundingBoxBottom.min.z.toFixed(4)) == (boundingBoxRight.max.z.toFixed(4) - boundingBoxRight.min.z.toFixed(4)))) {
                const jointLeftBottom = cornerJointModel.clone();
                jointLeftBottom.rotateZ(Math.PI / 2)

                jointLeftBottom.scale.set(
                    ((boundingBoxBottom.max.y - boundingBoxBottom.min.y) / jointheight),
                    ((boundingBoxRight.max.x - boundingBoxRight.min.x) / jointWidth),
                );

                const jointLeftBottomScale = new THREE.Box3().setFromObject(jointLeftBottom);
                jointLeftBottom.position.set(boundingBoxBottom.min.x + (((jointLeftBottomScale.max.x - jointLeftBottomScale.min.x) / 2)), boundingBoxBottom.min.y + (((jointLeftBottomScale.max.y - jointLeftBottomScale.min.y) / 2)), boundingBoxBottom?.max.z + jointDepth + .0001);

                jointLeftBottom.name = "ProfileJoints FrameBottom FrameRight";

                // assign colors to all joints
                jointLeftBottom.traverse((child) => {
                    if(material){
                        child.material = material
                    }
                    externalFrameRef.current.push(child);
                })

                var jointLeftBottomBack = jointLeftBottom.clone()
                jointLeftBottomBack.position.z = boundingBoxBottom?.min.z - jointDepth

                // assign colors to all joints
                jointLeftBottomBack.traverse((child) => {
                    if(material){
                        child.material = material
                    }
                    internalFrameRef.current.push(child);
                })
                
                if (headerSelectedItem.name !== "Oriel Window") {
                    sceneRef.current.add(jointLeftBottom);
                    sceneRef.current.add(jointLeftBottomBack);
                }

            }
        }

        setInitialColors(rgbStringToHex, internalRAL, internalColor, externalRAL, externalColor, customModelData, setInternal, setExternal)

    }, undefined, function (error) {
        console.error('An error occurred while loading the model', error);
    });
}

// export function addProfileNumber(sceneRef, parent, targetPosition) {
//     // Create a group to hold the circle and SVG texture
//     const group = new THREE.Group();

//     const circleRadius = 0.05; // Adjust size as needed

//     const svgData = `
//         <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 25 25" width="25" height="25">
//             <circle cx="12.5" cy="12.5" r="12.25" fill="#ff0000" />
//             <text transform="translate(5 18)" style="font-size:14px; fill:#fff; font-weight: 400;">
//                 26
//             </text>
//         </svg>
//     `;

//     // Create SVG image as texture
//     const svgBlob = new Blob([svgData], { type: 'image/svg+xml' });
//     const svgUrl = URL.createObjectURL(svgBlob);

//     const loader = new THREE.TextureLoader();
//     loader.load(svgUrl, (texture) => {
//         // Create material for the SVG texture
//         const svgMaterial = new THREE.MeshBasicMaterial({ map: texture, transparent: true });

//         // Create a plane for the SVG texture
//         const svgPlane = new THREE.Mesh(new THREE.PlaneGeometry(circleRadius * 2, circleRadius * 2), svgMaterial);

//         // Position SVG plane in front of the circle
//         svgPlane.position.set(0, 0, 0.01);

//         // Add SVG plane to the group
//         group.add(svgPlane);

//         // Clean up URL object after use
//         URL.revokeObjectURL(svgUrl);

//         // Position the group at the desired location in gltf.scene
//         const desiredPosition = new THREE.Vector3(0, 1, 0); // Adjust position as needed
//         group.position.copy(targetPosition);
//         group.position.z += .1

//         // Add the group to the scene
//         sceneRef.current.add(group);
//     });
// }

export function addProfileNumber2(sceneRef, parent, targetPosition) {
    // Create a group to hold the circle and SVG texture
    const group = new THREE.Group();

    const circleRadius = 0.05; // Adjust size as needed

    // SVG data
    const svgData = `
        <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 25 25" width="25" height="25">
            <circle cx="12.5" cy="12.5" r="12.25" fill="#ff0000" />
            <text transform="translate(5 18)" style="font-size:14px; fill:#fff; font-weight: 400; font-family: Poppins;">
                26
            </text>
        </svg>
    `;

    // Create a canvas element
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    // Set canvas size to match SVG size
    canvas.width = 25; // Match SVG width
    canvas.height = 25; // Match SVG height

    // Create an SVG image and draw it on the canvas
    const img = new Image();
    const svgBlob = new Blob([svgData], { type: 'image/svg+xml' });
    const svgUrl = URL.createObjectURL(svgBlob);

    img.onload = function () {
        context.drawImage(img, 0, 0);
        URL.revokeObjectURL(svgUrl);

        // Create texture from canvas
        const texture = new THREE.CanvasTexture(canvas);

        // Create material for the texture
        const svgMaterial = new THREE.MeshBasicMaterial({ map: texture, transparent: true });

        // Create a plane for the texture
        const svgPlane = new THREE.Mesh(new THREE.PlaneGeometry(circleRadius * 2, circleRadius * 2), svgMaterial);

        // Position SVG plane in front of the circle
        svgPlane.position.set(0, 0, 0.01);

        // Add SVG plane to the group
        group.add(svgPlane);

        // Position the group at the desired location in gltf.scene
        group.position.copy(targetPosition);
        group.position.z += .1

        // Add the group to the scene
        sceneRef.current.add(group);
    };
    img.src = svgUrl;
}

export function addProfileNumber(sceneRef, targetPosition, index, itemName, itemRef) {

    return;

    const boundingBox = new THREE.Box3().setFromObject(itemRef);

    console.log(boundingBox, "boundingBox")

    // Create a group to hold the circle and number
    const group = new THREE.Group();

    const circleRadius = 0.05; // Adjust size as needed
    const number = (index + 1); // Number to display

    // Create a canvas element
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    // Set canvas size (larger canvas for better quality)
    const canvasSize = 512; // Increase canvas size for better quality
    canvas.width = canvasSize;
    canvas.height = canvasSize;

    // Draw a circle on the canvas
    if (itemName.includes('Frame')) {
        context.fillStyle = '#f70d0d';
    } else if (itemName.includes('Bar')) {
        context.fillStyle = '#1c42f3';
    } else if (itemName.includes('Sill')) {
        context.fillStyle = '#14e599';
    }

    // context.fillStyle = '#556ee6' // Circle color

    context.beginPath();
    context.arc(canvasSize / 2, canvasSize / 2, canvasSize / 2, 0, Math.PI * 2);
    context.fill();

    // Draw the number in the center
    context.fillStyle = '#ffffff'; // Text color
    context.font = 'normal 300px Arial'; // Larger font size
    context.textAlign = 'center';
    context.textBaseline = 'middle';
    context.fillText(number, canvasSize / 2, canvasSize / 1.9);

    // Create texture from canvas
    const texture = new THREE.CanvasTexture(canvas);

    // Create material for the texture
    const material = new THREE.MeshBasicMaterial({ map: texture, transparent: true });

    // Create a plane geometry for the texture
    const planeGeometry = new THREE.PlaneGeometry(circleRadius * 2, circleRadius * 2);

    // Create a mesh with the plane geometry and material
    const planeMesh = new THREE.Mesh(planeGeometry, material);

    // Add the plane mesh to the group
    group.add(planeMesh);

    // Position the group at the desired location
    group.position.copy(targetPosition);
    // group.position.z = (boundingBox.max.z + 0.02)
    // group.position.y = (boundingBox.max.y - 0.03)
    group.position.z = (boundingBox.max.z + 0.1)

    if (itemName.includes('FrameTop') || itemName.includes('FrameBottom')) {
        group.position.x -= .35
        group.position.y = (boundingBox.max.y + .03)
    }

    if (itemName.includes('FrameLeft') || itemName.includes('FrameRight')) {
        group.position.y += .1
    }

    // if (itemName.includes('BarLeft') || itemName.includes('BarRight')) {
    //     group.position.x += boundingBox.min.y
    // }

    // if (itemName.includes('BarTop')) {
    //     group.position.y = (boundingBox.min.y - 0.03)
    // } else if (itemName.includes('BarBottom')) {
    //     group.position.y = (boundingBox.max.y + 0.03)
    // } else if (itemName.includes('Sill')) {
    //     group.position.y = (boundingBox.min.y - 0.03)
    // }

    group.name = "seqNumbers";

    // Add the group to the scene
    sceneRef.current.add(group);
}


