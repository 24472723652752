import React from 'react';
import { BsTools } from 'react-icons/bs';
import { Image } from 'react-bootstrap';
import hardwareIcon1 from "../../../../assets/img/images/hardware2.png";

const HardwareItemList = ({ frameGridView, generalCss, o, index }) => {

    return (
        <div style={frameGridView.mainFrameLeft.inner.box} className='mb-0' key={index}>
            <div style={frameGridView.mainFrameLeft.inner.box.sm.wrap}>
                {o.imagePath ? (
                    <Image src={o?.imagePath ? o?.imagePath : hardwareIcon1} style={frameGridView.mainImage} alt="image" />
                ) : (
                    <BsTools color='#989898' size={20} />
                )}
            </div>
            <div style={generalCss.flexCol}>
                <h6 style={{ ...frameGridView.mainFrameLeft.inner.box.title.head, ...generalCss.capitalize }}>
                    {o?.type}
                </h6>
                <span style={frameGridView.mainFrameLeft.inner.box.title.sub}>
                    {o?.name}
                    {/* {o?.count > 1 && `X${o?.count}`} */}
                </span>
                <span style={frameGridView.mainFrameLeft.inner.box.title.sub}>
                    {o.color}
                </span>
            </div>
        </div>
    );
}


export default HardwareItemList