import React, { useEffect, useState } from "react";
import { MdKeyboardArrowDown, MdOutlineArticle, MdOutlineCreate } from "react-icons/md";
import { FaPencilAlt } from "react-icons/fa";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { servicePath } from "../../utility/data";
import axios from "axios";
import { useMessage } from "../../context/messageContext";
import { Link, useNavigate } from "react-router-dom";

const PricingHeader = ({
  setSelectedSupplyOnly,
  setSelectedPricing,
  selectedPricing,
  selectedSupplyOnly,
  costPrice
}) => {
  
  const [supplyOnlyList, setSupplyOnlyList] = useState([]);
  const [standardPricingList, setStandardPricingList] = useState([]);

  const [stdPricing, setStdPricing] = useState(false);
  const [supplyOnly, setSupplyOnly] = useState(false);
  
  const [selectedPricingName, setSelectedPricingName] = useState('');
  const [selectedSupplyOnlyName, setSelectedSupplyOnlyName] = useState('');

  const { setMessage, setMessageType } = useMessage();
  var token = localStorage.getItem("access_token");
  const navigate = useNavigate();

  useEffect(() => {
    getStandardPricing();
    getMarkupPricing();
  }, []);

  useEffect(() => {
    if(standardPricingList && standardPricingList.length > 0) {
      setSelectedPricing(standardPricingList[0].id)
      setSelectedPricingName(standardPricingList[0].name)
    }
  }, [standardPricingList]);

  useEffect(() => {
    if(supplyOnlyList && supplyOnlyList.length > 0) {
      setSelectedSupplyOnly(supplyOnlyList[0].id)
      setSelectedSupplyOnlyName(supplyOnlyList[0].name)
    }
  }, [supplyOnlyList]);

  const handleStandardPricingDrop = () => {
    setStdPricing((prev) => !prev);
  };

  const handleSupplyOnlyDrop = () => {
    setSupplyOnly((prev) => !prev);
  };

  const handleSelectedPricing = (data) => {
    setSelectedPricing(data.id);
    setSelectedPricingName(data.name)
  };

  const handleSelectedSupplyOnly = (data) => {
    setSelectedSupplyOnly(data.id);
    setSelectedSupplyOnlyName(data.name)
  };

  const getStandardPricing = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${servicePath}/Master/GetCostPriceList`,
      headers: {
        accept: "text/plain",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    axios
      .request(config)
      .then((res) => {
        if (res?.data?.statusCode === 200) {
          // setLoading(false)
          setStandardPricingList(res?.data?.entity);
          console.log(standardPricingList, "standardPricing");
        } else if (res?.data?.statusCode === 404) {
          // setLoading(false)
          navigate("/error/404");
        } else {
          // setLoading(false)
          setMessage(
            res?.data?.message ? res.data.message : "Something went wrong"
          );
          setMessageType("error");
        }
      })
      .catch((error) => {
        console.log(error);

        // setLoading(false)
        setMessage(error.message ? error.message : "Something went wrong");
        setMessageType("error");

        if (error?.message === "Network Error") {
          navigate("/error/network");
        }
      });
  };

  const getMarkupPricing = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${servicePath}/Master/GetMarkUpPriceList`,
      headers: {
        accept: "text/plain",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    axios
      .request(config)
      .then((res) => {
        if (res?.data?.statusCode === 200) {
          // setLoading(false)
          setSupplyOnlyList(res?.data?.entity);
          
          console.log(supplyOnlyList, "dataMarkup");
        } else if (res?.data?.statusCode === 404) {
          // setLoading(false)
          navigate("/error/404");
        } else {
          // setLoading(false)
          setMessage(
            res?.data?.message ? res.data.message : "Something went wrong"
          );
          setMessageType("error");
        }
      })
      .catch((error) => {
        console.log(error);

        // setLoading(false)
        setMessage(error.message ? error.message : "Something went wrong");
        setMessageType("error");

        if (error?.message === "Network Error") {
          navigate("/error/network");
        }
      });
  };

  const handlePriceListNavigation=()=>{
    navigate("/pricing/cost-price-list")
  }

  const handleSupplyOnlyListNavigation=()=>{
    navigate("/pricing/mark-up-price-list")
  }

  return (
    <div className="d-flex justify-content-between pb-3 border-bottom mb-3">
      <div className="d-flex align-items-center gap-3 gap-lg-5">
        <Dropdown isOpen={stdPricing} toggle={handleStandardPricingDrop}>
          <DropdownToggle color="none" className="border-0 p-0 lh-1 h-auto">
            <div className="d-flex align-items-center">
              <span className="fw-semibold">
                <MdOutlineArticle size={20} className="me-1" />
                {selectedPricingName}

                <MdKeyboardArrowDown className="ms-1" size={20} />
              </span>
            </div>
          </DropdownToggle>

          <DropdownMenu>
            {standardPricingList.map((item, index) => {
              return (
                <DropdownItem
                  key={index}
                  onClick={() => handleSelectedPricing(item)}
                >
                  {item.name}
                </DropdownItem>
              );
            })}
            <DropdownItem key={100}>
              <div className="d-flex align-items-center">
                <span className="text-primary"  onClick={handlePriceListNavigation}>
                <MdOutlineCreate className="text-primary me-1 mb-1" size={18} /> Cost price lists
              </span>
            </div>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>

        <Dropdown isOpen={supplyOnly} toggle={handleSupplyOnlyDrop}>
          <DropdownToggle color="none" className="border-0 p-0 lh-1 h-auto">
            <div className="d-flex align-items-center">
              <span className="fw-semibold">
                <MdOutlineArticle size={20} className="me-1" />
                {selectedSupplyOnlyName}

                <MdKeyboardArrowDown className="ms-1" size={20} />
              </span>
            </div>
          </DropdownToggle>

          <DropdownMenu>
            {supplyOnlyList && supplyOnlyList.map((item, index) => (
              <DropdownItem
                key={index}
                onClick={() => handleSelectedSupplyOnly(item)}
              >
                {item.name}
              </DropdownItem>
            ))}
             <DropdownItem key={101}>
              <div className="d-flex align-items-center">
                <span className="text-primary" onClick={handleSupplyOnlyListNavigation}>
                <MdOutlineCreate className="text-primary me-1 mb-1" size={18} /> Mark-up price lists
              </span>
            </div>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>
    </div>
  );
};

export default PricingHeader;
