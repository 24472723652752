import React, { useEffect, useRef, useState } from 'react';
import * as THREE from 'three';
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';
import { usePanelDesigner } from '../../context/designer2Context';
import { servicePath } from '../../utility/data';
import { getPosition } from '../../utility/helper';
import { Canvas } from 'react-three-fiber';
import { OrbitControls, PerspectiveCamera } from '@react-three/drei';
import { PanelModel } from './AluminiumPanel';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js';


const PanelDesign2 = ({ shape, styleSelect, methodSelect, angle, repeat, hardwareType, setHardwareType }) => {

  const sliderRef = useRef(0);
  const [hardwareModel, setHardwareModel] = useState()

  let mixer;
  let gltfModel = useRef(null)
  let panelRef = useRef()
  const windowRef = useRef(null);
  const sceneRef = useRef(null);
  const textMeshRef = useRef(null);
  const textMeshPos = useRef(null);

  let renderer = new THREE.WebGLRenderer({ antialias: true });
  let camera = new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 0.25, 30);
  let scene = new THREE.Scene();

  const {
    horizontalPos,
    verticalPos,
    handleCurrentModel,
    verticalAlignment,
    horizontalAlignment,
    horizontalOrigin,
    verticalOrigin,
    modelData,
    isDeletedHardware,
    gotData,
    setGotData
  } = usePanelDesigner();

  
  useEffect(() => {
    if (modelData) {
      console.log("DATA CHANGED--->", modelData)
      // setHardwareType(Object.values(modelData)[0])
    }
  }, [modelData, gotData])

  useEffect(() => {
    if (hardwareType && hardwareType.name) {
      let type = "";
      const hardwareName = hardwareType?.name?.toLocaleLowerCase();
      console.log(hardwareName, "68");
      if (hardwareName === "letter") {
        createText(0, 0)
      } else {
        const isBarHandle =
          hardwareName?.includes("offset") ||
          hardwareName?.includes("inline");
        type =
          hardwareName?.includes("spyhole")
            ? "spyhole"
            : hardwareName?.includes("knob")
              ? "door_knob"
              : hardwareName?.includes("escutcheon")
                ? "escutcheon"
                : isBarHandle
                  ? "bar_offset"
                  : "";

        const pos = getPosition(hardwareName);
        console.log(`${servicePath}/ThreeJSModel/Glb/${hardwareType?.modelFilePath}`, hardwareType, '87')
        if (
          hardwareType?.isAlreadyAdded ||
          (horizontalPos === 0 && verticalPos === 0)
        ) {
          addElement(
            `${servicePath}/ThreeJSModel/Glb/${hardwareType?.modelFilePath}`,
            hardwareType?.horizontalPos || pos?.horizontalPos,
            hardwareType?.verticalPos || pos?.verticalPos,
            type,
            pos?.scale
          );
        } else {
          addElement(
            `${servicePath}/ThreeJSModel/Glb/${hardwareType?.modelFilePath}`,
            horizontalPos,
            verticalPos,
            type,
            pos?.scale
          );
        }
      }
    }
  }, [
    hardwareType,
    horizontalPos,
    verticalPos,
    verticalAlignment,
    horizontalAlignment,
    horizontalOrigin,
    verticalOrigin,
    // modelData,
  ]);

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (isDeletedHardware) {
      const hardwareArray = Object.values(hardwareModel);
      const updatedHardwareArray = hardwareArray.filter(item => item.name !== hardwareType?.name);
      const updatedHardwareModel = Object.fromEntries(updatedHardwareArray.map(item => [item.name, item]));
      setHardwareModel(updatedHardwareModel);
    }
  }, [isDeletedHardware, hardwareModel, hardwareType]);

  // const handleSliderChange = () => {
  //   const inputValue = sliderRef.current.value;
  //   openWindow(inputValue)
  // }

  function init() {
    sceneRef.current = scene;
    handleCurrentModel(panelRef)
  }

  function addElement(element, horizontal, vertical, hardwareType, scaling) {
    // Remove the second element if it exists
    if (gltfModel?.current?.children?.length > 5) {
      // let index = hardwareType?.isAlreadyAdded ? 6 : 5;
      for (let i = 5; i <= gltfModel.current.children.length; i++) {
        const secondElement = gltfModel.current.children[i];
        gltfModel.current.remove(secondElement);
        scene.remove(secondElement);
      }
    }

    let posZ;
    let posY;

    if (verticalOrigin !== "" && horizontalOrigin !== "") {
      posZ = parseFloat(
        getAlignmentPosition(horizontalOrigin, "horizontalOri") / 1000
      );
      posY = parseFloat(
        getAlignmentPosition(verticalOrigin, "verticalOri") / 1000
      );
    } else if (verticalAlignment !== "" && horizontalAlignment !== "") {
      posZ = parseFloat(
        getAlignmentPosition(horizontalAlignment, "horizontal") / 1000
      );
      posY = parseFloat(
        getAlignmentPosition(verticalAlignment, "vertical") / 1000
      );
    } else if (verticalAlignment !== "") {
      posZ = parseFloat(horizontal / 1000);
      posY = parseFloat(
        getAlignmentPosition(verticalAlignment, "vertical") / 1000
      );
    } else if (horizontalAlignment !== "") {
      posZ = parseFloat(
        getAlignmentPosition(horizontalAlignment, "horizontal") / 1000
      );
      posY = parseFloat(vertical / 1000);
    } else if (horizontalOrigin !== "") {
      posZ = parseFloat(
        getAlignmentPosition(horizontalOrigin, "horizontalOri") / 1000
      );
      posY = parseFloat(vertical / 1000);
    } else if (verticalOrigin !== "") {
      posZ = parseFloat(horizontal / 1000);
      posY = parseFloat(
        getAlignmentPosition(verticalOrigin, "verticalOri") / 1000
      );
    } else {
      posZ = parseFloat(horizontal / 1000);
      posY = parseFloat(vertical / 1000);
    }

    const elementLoader = new GLTFLoader();
    if (element) {
      elementLoader.load(element, function (gltf) {
        const scale = new THREE.Vector3(scaling?.posX, scaling?.posY, scaling?.posZ);
        // const scale = new THREE.Vector3(0.2, 0.2, 0.5);
        gltf.scene.scale.copy(scale);
        //const position = new THREE.Vector3(0, 0.15, 0);
        let posX = -0.03;
        if (hardwareType === "spyhole") {
          posX = -0.023
        }
        else if (hardwareType === "bar_offset") {
          posX = -0.059
        }
        else if (hardwareType === "door_knob") {
          posX = -0.05
        }
        else if (hardwareType === "escutcheon") {
          posX = -0.0099
        }
        // else if (hardwareType==="escutcheon"){
        //   posX=-0.0099
        // }

        const position = new THREE.Vector3(posX, 0.19, 0);
        gltf.scene.position.copy(position);
        //gltf.scene.rotation.set(0, Math.PI / 2, 0);
        gltf.scene.rotation.set(0, 4.7, 0);
        // gltf.scene.position.y += 0.3;
        // gltf.scene.position.z -= 0.05;
        gltf.scene.position.y += posY;
        gltf.scene.position.z -= posZ;
        // scene.add(gltf.scene);
        // gltfModel.current.add(gltf.scene);
        console.log(gltf.scene, '  333');
        setHardwareModel(gltf.scene);
        render();
      });
    }
    setGotData(false)
  }

  const getAlignmentPosition = (alignment, type) => {
    let position = 0;

    switch (type) {
      case "vertical":
        position = alignment === "Centre" ? 0 : alignment === "Top" ? 300 : -300;
        break;
      case "horizontal":
        position =
          alignment === "Centre"
            ? 0
            : alignment === "Left" || alignment === "Lock"
              ? 380
              : -380;
        break;
      case "verticalOri":
      case "horizontalOri":
        position =
          alignment === "Slab"
            ? 50
            : alignment === "Outside frame"
              ? type === "horizontalOri"
                ? 50
                : -50
              : 50;
        break;
    }
    return position;
  };

  function createText(text, position, hex) {

    let textMesh1
    let textGeo
    const loader = new FontLoader()

    if (textMeshRef.current && gltfModel.current) {
      sceneRef.current.remove(textMeshRef.current)
    }

    console.log("TEXT",text,position)

    loader.load("https://jackodiamond.github.io/babylonGroundTracking/Roboto_Regular.json", function (font) {
      textGeo = new TextGeometry(text?.toString(), {
        font: font,
        size: 0.7,
        height: 0.7,
      });

      const materials = new THREE.MeshStandardMaterial({ color: hex ? hex : 0x373f43, metalness: 1 }) // side

      textMesh1 = new THREE.Mesh(textGeo, materials);
      let units = 0.01
      textMesh1.position.x = position;
      textMesh1.position.z = position - 0.07;
      textMesh1.position.y = position - 0.06;
      // textMesh1.position.x = element==="no"?position.position?.x - 0.07:(parseInt(element?.horizontalPos))*units;
      // textMesh1.position.z = position.position?.z - 0.07;
      // textMesh1.position.y = element==="no"?position.position?.y - 0.06:(parseInt(element?.verticalPos))*units;
      textMesh1.scale.x = 0.1;
			textMesh1.scale.y = 0.1;
			textMesh1.scale.z = 0.03;

      textMeshRef.current = textMesh1;
      textMeshPos.current = new THREE.Vector3(textMesh1.position.x, textMesh1.position.y, textMesh1.position.z)
      if (textMeshRef.current) {
        console.log("MESH",textMeshRef)
        sceneRef.current.add(textMeshRef.current)
      }
    })

  }

  function openWindow(inValue) {
    var posX = mapCustom(inValue, 0, inValue, 0.2, -0.195);
    windowRef.current.position.x = posX;
  }

  //x value of window changes from -0.195 (closed) to 0.2 (open)
  function mapCustom(value) {
    const inputMin = 0;
    const inputMax = 100;
    const outputMin = -0.19;
    const outputMax = 0.2;

    // Ensure the input is within the specified range
    const clampedInput = Math.min(Math.max(value, inputMin), inputMax);

    // Perform a custom mapping
    const mappedValue = outputMin + (outputMax - outputMin) * (clampedInput / inputMax);

    return mappedValue;
  }

  //x value of window changes from -0.195 (closed) to 0.2 (open)
  function mapRange(value, inMin, inMax, outMin, outMax) {
    return ((value - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
  }

  function render() {
    renderer.render(scene, camera);
  }

  function animate() {
    requestAnimationFrame(animate);
    // updateOverlayPosition()
    if (mixer) {
      mixer.update(0.01);
    }
    // updateIconPositions();
    render();
  }

  return (
    <>
      <div className='position-relative' >
        <div id='container'
        // ref={containerRef} 
        >
          <Canvas gl={{ localClippingEnabled: true }}>
            {/* {state ? <mesh></mesh> : null} */}
            <PerspectiveCamera makeDefault fov={45} near={0.25} far={30} aspect={window.innerWidth / window.innerHeight} position={[-2, 0, 0]} />
            <mesh>
              <mesh ref={panelRef} position={[0, 0, 0]}>
                <PanelModel shape={shape ? shape : 4} styleSelect={styleSelect} methodSelect={methodSelect} angle={angle ? angle : 0} repeat={repeat ? repeat : 0} />
                {hardwareModel ?
                  <mesh>
                    <primitive object={hardwareModel} />
                  </mesh>
                  : null}
              </mesh>
              <ambientLight color={0xffffff} />
              <hemisphereLight color={0xffffff} intensity={10} position={[-3, 0, 0]} />
              <hemisphereLight color={0xffffff} intensity={10} position={[0, 0, -3]} />
              <directionalLight color={0xffffff} intensity={0.8} position={[-10, 5, 3]} />
              <directionalLight color={0xffffff} intensity={0.8} position={[-10, 5, -3]} />
              <directionalLight color={0xffffff} intensity={0.8} position={[10, 5, 3]} />
              <directionalLight color={0xffffff} intensity={0.8} position={[10, 5, -3]} />

              <directionalLight color={0xffffff} intensity={.5} position={[-10, 1, 0]} />
              <directionalLight color={0xffffff} intensity={.5} position={[10, 1, 0]} />
            </mesh>
            <OrbitControls />
          </Canvas>
        </div>
      </div>
    </>
  );
}

export default PanelDesign2;