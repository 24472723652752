import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Sqsm from "../../../../../assets/img/images/sq_sm.png"
import { servicePath } from "../../../../../utility/data"; import axios from "axios";
import { Image } from "react-bootstrap";
import { Col, Row } from "reactstrap";

const Left = ({ accessToken, setMessage, setMessageType, loading, setLoading, quoteId }) => {

    const [framesList, setFramesList] = useState([]);
    const navigate = useNavigate()

    useEffect(() => {
        document.body.classList.add('layout_2');

        return () => {
            document.body.classList.remove('layout_2');
        }
    }, []);

    useEffect(() => {
        setLoading(true)

        if (quoteId) {
            getAllFrameList(quoteId)
        }
    }, [quoteId])

    const getAllFrameList = (fetchId) => {
        const FormData = require("form-data");

        let data = new FormData();

        data.append("QuotationId", fetchId);

        let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: `${servicePath}/Quotation/GetAllQuotationFrame`,
            headers: {
                accept: "text/plain",
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "multipart/form-data",
            },
            data: data,
        };

        axios
            .request(config)
            .then((res) => {
                if (res?.data?.statusCode === 200) {
                    console.log("res", res);
                    setFramesList(res?.data?.entity);

                    setTimeout(() => {
                        setLoading(false);
                    }, 400);

                } else if (res?.data?.statusCode === 404) {
                    setLoading(false);
                    navigate("/error/404");
                } else {

                    setLoading(false);
                    setMessage(
                        res?.data?.message ? res.data.message : "Something went wrong"
                    );
                    setMessageType("error");
                }
            })
            .catch((error) => {
                console.log(error);

                setLoading(false);
                setMessage(error.message ? error.message : "Something went wrong");
                setMessageType("error");

                if (error?.message === "Network Error") {
                    navigate("/error/network");
                }
            });
    };

    return (
        <>
            <div className='page_title d-flex justify-content-center align-items-center'>
                <h3 className='mb-0 text-capitaliz'>Choose a product</h3>
            </div>

            <Row className="item_box_wrap dynamic_card type_2 py-3 custom_row_8">
                {framesList && framesList.length > 0 ?
                    <>
                        {framesList && framesList.map((item, index) => (
                            <Col sm="6" md="4" lg="3" xxl="2" key={index} className="item_box">
                                <div className={`item_box_inner mt-2`}>
                                    <Link to={`/product/panels/panel-products/panel-designer/${item?.frameStyleCollectionId}`} className="d-flex flex-column h-100">
                                        <div className="item_box_img">
                                            <Image src={item.imagePath || Sqsm} className="img-fluid" alt="GS20 Sliding Door" />
                                        </div>

                                        <div className="item_box_content">
                                            <h5 className="mb-0">
                                                {item.name}
                                            </h5>
                                        </div>
                                    </Link>
                                </div>
                            </Col>
                        ))}
                    </>
                    :
                    <Col lg="12">
                        <h3 className="text-center mt-5">No products found!</h3>
                    </Col>}
            </Row>
        </>
    );
};

export default Left;
