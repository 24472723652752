import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { MdOutlineCreate } from "react-icons/md";
import { Button, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { Form, Table } from "react-bootstrap";
import { servicePath } from "../../../../../../utility/data";
import PricingHeader from "../../../../../../components/Pricing/PricingHeader";
import axios from "axios";
import { useEffect } from "react";
import { useRightContext } from "../../../../../../context/rightMenuContext";
import { handleEmptyValues } from "../../../../../../utility/helper";

const Left = ({ token, setMessage, setMessageType, loading, setLoading }) => {
  const navlinks = [
    {
      id: 1,
      name: "Lump sum",
    },
    {
      id: 2,
      name: "Square metre",
    },
    {
      id: 3,
      name: "Minimum area",
    },
  ];
  const [selectedPricing, setSelectedPricing] = useState();
  const [selectedSupplyOnly, setSelectedSupplyOnly] = useState("");
  const [costPrice, setCostPrice] = useState([]);
  const [activeTab, setActiveTab] = useState("Lump sum");
  const [lumpSumData, setLumpSumData] = useState([]);
  const [squareMetreData, setSquareMetreData] = useState([]);
  const { userDataContext, setUserDataContext } = useRightContext('')
  let { collectionId } = useParams();

  const navigate = useNavigate();

  const noRefCheck = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    setLoading(true);
    getFrameStylePricing();
  }, []);

  useEffect(() => {
    setLoading(true);
    getFrameStylePricing();
  }, [selectedPricing, collectionId, selectedSupplyOnly]);

  const getFrameStylePricing = () => {
    const FormData = require("form-data");

    let data = new FormData();

    data.append("PriceMasterId", selectedPricing);
    data.append("MarkupMasterId", selectedSupplyOnly);
    data.append("FrameStyleCollectionId", collectionId);
    data.append("TypeId", 2);
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${servicePath}/General/GetFrameStylePricingList`,
      headers: {
        accept: "text/plain",
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
      data: data,
    };

    axios
      .request(config)
      .then((res) => {
        if (res?.data?.statusCode === 200) {
          console.log("Frame11212s", res);
          setLoading(false);
          setLumpSumData(res?.data?.entity["lumpsum"]);
          setSquareMetreData(res?.data?.entity["squareMetre"]);
          console.log(lumpSumData, squareMetreData, "frameStylePricingData");
        } else if (res?.data?.statusCode === 404) {
          setLoading(false);
          navigate("/error/404");
        } else {
          setLoading(false);
          setMessage(
            res?.data?.message ? res.data.message : "Something went wrong"
          );
          setMessageType("error");
        }
      })
      .catch((error) => {
        console.log(error);

        setLoading(false);
        setMessage(error.message ? error.message : "Something went wrong");
        setMessageType("error");

        if (error?.message === "Network Error") {
          navigate("/error/network");
        }
      });
  };

  const handleInput = (setMethod, id, key, value) => {
    if (validate(value)) {
      setMethod((prevData) =>
        prevData.map((item, index) =>
          index === id ? { ...item, [key]: parseFloat(value) } : item
        )
      );
    }
  };

  const validate = (value) => {
    if (value < 0) return false;
    return true;
  };

  const handleUpdate = () => {
    setLoading(true);
    updateFrameStylePricing();
  };

  const updateFrameStylePricing = () => {
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${servicePath}/General/AddUpdateFrameStylePricing`,
      headers: {
        accept: "text/plain",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: handleEmptyValues([...lumpSumData, ...squareMetreData]),
    };

    axios
      .request(config)
      .then((res) => {
        console.log(res.data, "UpdatedFrameStylingPricing");

        if (res?.data?.statusCode === 200) {
          setMessage(res.data.message);
          setMessageType("success");
          getFrameStylePricing();
        }
      })
      .catch((error) => {
        console.log(error);

        setLoading(false);
        setMessage(error.message ? error.message : "Something went wrong");
        setMessageType("error");

        if (error?.message === "Network Error") {
          navigate("/error/network");
        }
      });
  };

  const handleFormReset = () => {
    setLoading(true);
    getFrameStylePricing();
  };

  return (
    <>
      <PricingHeader
        setSelectedSupplyOnly={setSelectedSupplyOnly}
        setSelectedPricing={setSelectedPricing}
        selectedPricing={selectedPricing}
        selectedSupplyOnly={selectedSupplyOnly}
        costPrice={costPrice}
      />
      <div className="d-flex align-items-center mb-4 flex-wrap gap-3 left_header">
        <h5 className="mb-0 text-wrap text-break me-4 main_title">
          GS20SlidingDoor
        </h5>
        <Link to={{}}>
          <div className="ms-sm-3">
            <MdOutlineCreate className="text-primary me-1 mb-1" size={18} />
            <span className="text-primary d-none d-sm-inline-block">
              Customize
            </span>
          </div>
        </Link>
      </div>
      <Nav tabs className="details_header border-0 nav-tabs-custom">
        {navlinks.map((item) => (
          <NavItem key={item.id}>
            <NavLink
              className={`${activeTab === item.name ? "active" : "text-muted"}`}
              onClick={() => noRefCheck(item.name)}
            >
              {item.name}
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      <TabContent activeTab={activeTab} className="flex-grow-1 d-flex">
        <TabPane tabId="Lump sum" className="flex-grow-1 w-100">
          <Form className="h-100 d-flex flex-column flex-grow-1 justify-content-between">
            <div className="inside_scroll has_tab has_btns">
              <Table responsive className="w-100 discount_table">
                <thead className="border-bottom">
                  <tr className="text-end">
                    <th style={{ width: "40%", minWidth: "40%" }}></th>
                    <th>
                      {userDataContext?.role === "Supplier" ? ("Selling Price") : ("Cost")}
                    </th>
                    <th>
                      {userDataContext?.role === "Supplier" ? ("Selling Price") : ("Cost")} + VAT
                    </th>
                    <th>Mark-up $</th>
                    <th>Mark-up %</th>
                    <th>Total</th>
                    <th>Total + VAT</th>
                  </tr>
                </thead>
                <tbody>
                  {lumpSumData &&
                    lumpSumData.map((item, index) => (
                      <tr key={index}>
                        <td>{item.frameStyleName}</td>
                        <td>
                          <Form.Control
                            type="number"
                            value={item.cost} 
                            readOnly={userDataContext?.role !== "Supplier" && !item?.isSelf}
                            onChange={(e) =>
                              handleInput(
                                setLumpSumData,
                                index,
                                "cost",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <Form.Control
                            type="number"
                            value={item.costPlusVat}
                            onChange={(e) =>
                              handleInput(
                                setLumpSumData,
                                index,
                                "costPlusVat",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <Form.Control
                            type="number"
                            value={item.markUp}
                            onChange={(e) =>
                              handleInput(
                                setLumpSumData,
                                index,
                                "markUp",
                                e.target.value
                              )
                            }
                            readOnly={userDataContext?.role === "Supplier"} 
                          />
                        </td>
                        <td>
                          <Form.Control
                            type="number"
                            value={item.markUpPercentage}
                            onChange={(e) =>
                              handleInput(
                                setLumpSumData,
                                index,
                                "markUpPercentage",
                                e.target.value
                              )
                            }
                            readOnly={userDataContext?.role === "Supplier"} 
                          />
                        </td>
                        <td>
                          <Form.Control
                            type="number"
                            value={item.total}
                            onChange={(e) =>
                              handleInput(
                                setLumpSumData,
                                index,
                                "total",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td className="text-end">
                          <Form.Control
                            type="number"
                            value={item.totalPlusVat}
                            onChange={(e) =>
                              handleInput(
                                setLumpSumData,
                                index,
                                "totalPlusVat",
                                e.target.value
                              )
                            }
                          />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </div>
            <div className="d-flex gap-2 gap-xl-3 main_buttons">
              <Button
                color="primary"
                size="md"
                className="px-4"
                onClick={handleUpdate}
              >
                Save Changes
              </Button>

              <Button
                color="primary"
                size="md"
                outline
                className="px-4"
                onClick={handleFormReset}
              >
                Reset
              </Button>
            </div>
          </Form>
        </TabPane>
        <TabPane tabId="Square metre" className="flex-grow-1 w-100">
          <Form className="h-100 d-flex flex-column flex-grow-1 justify-content-between">
            <div className="inside_scroll has_tab has_btns">
              <Table responsive className="w-100 discount_table">
                <thead className="border-bottom">
                  <tr className="text-end">
                    <th style={{ width: "40%", minWidth: "40%" }}></th>
                    <th>
                      {userDataContext?.role === "Supplier" ? ("Selling Price") : ("Cost")}
                    </th>
                    <th>
                      {userDataContext?.role === "Supplier" ? ("Selling Price") : ("Cost")} + VAT
                    </th>
                    <th>Mark-up $</th>
                    <th>Mark-up %</th>
                    <th>Total</th>
                    <th>Total + VAT</th>
                  </tr>
                </thead>
                <tbody>
                  {squareMetreData &&
                    squareMetreData.map((item, index) => (
                      <tr key={index}>
                        <td>{item.frameStyleName}</td>
                        <td>
                          <Form.Control
                            type="number"
                            value={item.cost} 
                            readOnly={userDataContext?.role !== "Supplier" && !item?.isSelf}
                            onChange={(e) =>
                              handleInput(
                                setSquareMetreData,
                                index,
                                "cost",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <Form.Control
                            type="number"
                            value={item.costPlusVat}
                            onChange={(e) =>
                              handleInput(
                                setSquareMetreData,
                                index,
                                "costPlusVat",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <Form.Control
                            type="number"
                            value={item.markUp}
                            onChange={(e) =>
                              handleInput(
                                setSquareMetreData,
                                index,
                                "markUp",
                                e.target.value
                              )
                            }
                            readOnly={userDataContext?.role === "Supplier"} 
                          />
                        </td>
                        <td>
                          <Form.Control
                            type="number"
                            value={item.markUpPercentage}
                            onChange={(e) =>
                              handleInput(
                                setSquareMetreData,
                                index,
                                "markUpPercentage",
                                e.target.value
                              )
                            }
                            readOnly={userDataContext?.role === "Supplier"} 
                          />
                        </td>
                        <td>
                          <Form.Control
                            type="number"
                            value={item.total}
                            onChange={(e) =>
                              handleInput(
                                setSquareMetreData,
                                index,
                                "total",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td className="text-end">
                          <Form.Control
                            type="number"
                            value={item.totalPlusVat}
                            onChange={(e) =>
                              handleInput(
                                setSquareMetreData,
                                index,
                                "totalPlusVat",
                                e.target.value
                              )
                            }
                          />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </div>
            <div className="d-flex gap-2 gap-xl-3 main_buttons">
              <Button
                color="primary"
                size="md"
                className="px-4"
                onClick={handleUpdate}
              >
                Save Changes
              </Button>

              <Button
                color="primary"
                size="md"
                outline
                className="px-4"
                onClick={handleFormReset}
              >
                Reset
              </Button>
            </div>
          </Form>
        </TabPane>
        <TabPane tabId="Minimum area" className="flex-grow-1 w-100">
          <Form className="h-100 d-flex flex-column flex-grow-1 justify-content-between">
            <div className="inside_scroll has_tab has_btns">
              <Table responsive className="w-100 discount_table">
                <thead className="border-bottom">
                  <tr className="text-end">
                    <th style={{ width: "90%", minWidth: "90%" }}></th>
                    <th>
                      m<sup>2</sup>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {lumpSumData &&
                    lumpSumData.map((item, index) => (
                      <tr key={index}>
                        <td>{item.frameStyleName}</td>
                        <td>
                          <Form.Control
                            type="number"
                            value={item.mSquar}
                            onChange={(e) =>
                              handleInput(
                                setLumpSumData,
                                index,
                                "mSquar",
                                e.target.value
                              )
                            }
                          />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </div>
            <div className="d-flex gap-2 gap-xl-3 main_buttons">
              <Button
                color="primary"
                size="md"
                className="px-4"
                onClick={handleUpdate}
              >
                Save Changes
              </Button>

              <Button
                color="primary"
                size="md"
                outline
                className="px-4"
                onClick={handleFormReset}
              >
                Reset
              </Button>
            </div>
          </Form>
        </TabPane>
      </TabContent>
    </>
  );
};

export default Left;
