import React, { useEffect, useState } from 'react'
import { BiPlus, BiTrashAlt } from 'react-icons/bi';
import { MdArrowDownward, MdArrowUpward, MdFormatListBulletedAdd, MdContentCopy } from 'react-icons/md';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useRightContext } from '../../../../../../context/rightMenuContext';
import { useMessage } from '../../../../../../context/messageContext';
import { deleteRightItem, updateOrder, updateGeneralPromotionOrder } from '../../../../../../services/pricing/promotionsService';
import { copyItem } from '../../../../../../services/commonServices';

const PromotionsRight = ({ accessToken, pageType, allRightListItems, setLoading, getRightMenuListData, addNewRightItemServices, selectItemDetails }) => {

    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false)

    const navigate = useNavigate()

    const { timestamp, fetchSelected, newItemList, setNewItemList, handleActiveIndexUp, handleActiveIndexDown, activeIndex } = useRightContext();
    const { setMessage, setMessageType } = useMessage()

    useEffect(() => {
        const assignItemValue = allRightListItems?.map((item) => {
            return {
                name: item.name,
                id: item.id
            }
        })

        setNewItemList(assignItemValue)
    }, [allRightListItems, setNewItemList])

    const handleItemClick = (currentIndex, id) => {
        fetchSelected(currentIndex, id);
    };

    // api to delete right side items
    const deleteRightItemServices = async () => {
        setIsDeleting(true)
        setLoading(true)

        const res = await deleteRightItem(accessToken, selectItemDetails?.id)

        if (res && res && res.status === 200) {

            setShowDeleteModal(false)
            fetchSelected(0, allRightListItems[0]?.id);

            if (res?.data?.statusCode === 200) {
                setMessage(res?.data?.message ? res.data.message : 'Record removed successfully !');
                setMessageType('success')

                getRightMenuListData()
                setIsDeleting(false)
            } else {
                setIsDeleting(false)
                setLoading(false)
                setMessage(res?.data?.message ? res.data.message : 'Something went wrong');
                setMessageType('error')
            }
        } else {
            setIsDeleting(false)
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')

            setShowDeleteModal(false)
            getRightMenuListData()

            if (res?.message === "Network Error") {
                navigate('/error/network')
            }
        }
    }

    // api to update right side item
    const updateOrderServices = async (direction) => {
        setLoading(true)

        const res = await updateGeneralPromotionOrder(accessToken, timestamp, direction)

        if (res && res && res.status === 200) {

            if (res?.data?.statusCode === 200) {
                getRightMenuListData()
            } else {
                setLoading(false)
                setMessage(res?.data?.message ? res.data.message : 'Something went wrong');
                setMessageType('error')
            }
        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')

            if (res?.message === "Network Error") {
                navigate('/error/network')
            }
        }
    }

    const handleItemUp = () => {
        handleActiveIndexUp()
        updateOrderServices("UP")
    }

    const handleItemDown = () => {
        handleActiveIndexDown()
        updateOrderServices("DOWN")
    }

    const copyServices = async (id) => {
        setLoading(true)
        const res = await copyItem(accessToken, id, 14) // pass typeId 14 for promotions
        if (res && res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                setLoading(false)
                setMessage(res?.data?.message ? res.data.message : 'Record removed successfully !');
                setMessageType('success')

                getRightMenuListData()
            } else {
                setLoading(false)
                setMessage(res?.data?.message ? res.data.message : 'Something went wrong');
                setMessageType('error')
            }
        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')

            if (res?.message === "Network Error") {
                navigate('/error/network')
            }
        }

    }

    return (
        <>
            <div className='h-100 d-flex flex-column flex-grow-1 justify-content-between'>
                <div className='inside_scroll has_btns'>
                    <div className='right_list'>
                        {newItemList?.map((item, index) =>
                            // add has_delete and has_copy class in below div based on availability of icons in action_btns div
                            <div key={index} className='position-relative d-flex align-items-center right_item_wrap has_delete'>
                                <Link to="#" className={`right_item w-100 ${activeIndex === index && "active"}`} onClick={() => handleItemClick(index, item.id)}>
                                    <span className='me-2'>
                                        <MdFormatListBulletedAdd size={22} />
                                    </span>

                                    <div className="right_title">
                                        <span className="text-truncate">{item.name}</span>
                                        <span className="text-truncate d-block text-small">{item.description}</span>
                                    </div>
                                </Link>
                                <span className='position-absolute action_btns'>
                                    {newItemList?.length > 1 && (
                                        <Link to="#" onClick={() => { setShowDeleteModal(true); fetchSelected(index, item.id); }}> <BiTrashAlt className='text-muted' size={20} /> </Link>
                                    )}
                                    <Link to="#" onClick={() => copyServices(item?.id)}> <MdContentCopy className='text-muted' size={18} /> </Link>
                                </span>
                            </div>
                        )}
                    </div>
                </div>

                {/* Start: space for buttons at the bottom */}
                <div className='d-flex gap-2 gap-xl-3 main_buttons'>
                    <Button
                        color="primary"
                        size="md"
                        className='px-4'
                        onClick={() => addNewRightItemServices(`Discounts ${newItemList && newItemList.length ? (newItemList.length + 1) : 1}`, pageType)}
                    >
                        <BiPlus size={20} className='me-1' />
                        New
                    </Button>

                    <Button
                        color=""
                        size="md"
                        className='border-0 text-primary px-0'
                        onClick={() => handleItemUp()}
                        disabled={timestamp === '' || activeIndex === 0}
                        // disabled
                    >
                        <MdArrowUpward size={22} className='me-1' />
                        Up
                    </Button>
                    <Button
                        color=""
                        size="md"
                        className='border-0 text-primary px-0'
                        onClick={() => handleItemDown()}
                        // disabled
                    disabled={timestamp === '' || (allRightListItems?.length - 1 === activeIndex)}
                    >
                        <MdArrowDownward size={22} className='me-1' />
                        Down
                    </Button>
                </div>
                {/* End: space for buttons at the bottom */}
            </div>

            <Modal isOpen={showDeleteModal} toggle={() => setShowDeleteModal(false)}>
                <ModalHeader >
                    Delete Selected Item
                </ModalHeader>
                <ModalBody>
                    Are you sure you want delete <strong>{selectItemDetails?.name}</strong> ?
                </ModalBody>
                <ModalFooter>
                    <Button color="outline-danger" size="md" disabled={isDeleting} onClick={() => deleteRightItemServices()}>Delete</Button>
                    <Button onClick={() => setShowDeleteModal(false)} color="primary" size="md">Cancel</Button>
                </ModalFooter>
            </Modal>
        </>
    )
}

export default PromotionsRight