import axios from "axios";
import { servicePath } from "../utility/data";

// get all right menu item list based on page/type
export const getAllPrintTemplate = (accessToken) => {

    const headers = {
        'accept': 'text/plain',
        'Authorization': `Bearer ${accessToken}`
    }

    return axios
        .get(`${servicePath}/Document/GetAllPrintGeneral`, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            return error;
        });
}

// update details of selected item
export const getTabPrintTemplate = (accessToken, printID, tabName) => {

    const headers = {
        'accept': 'text/plain',
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data'
    }

    const formData = new FormData();
    formData.append("PrintId", printID);

    return axios
        .post(`${servicePath}/Document/GetPrint${tabName}`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};

// get details of selected item in right menu
export const getItemDetails = (accessToken, id, tabName) => {

    const formData = new FormData();

    if (tabName === "General") {
        formData.append("Id", id);
    } else {
        formData.append("PrintId", id);
    }


    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'multipart/form-data'
        }
    };

    return axios
        .post(`${servicePath}/Document/GetPrint${tabName}`, formData, config)
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};

// delete selected item
export const deleteRightItem = (accessToken, id) => {
    const headers = {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data'
    }

    const formData = new FormData()
    formData.append("Id", id)

    return axios
        .post(`${servicePath}/Document/DeletePrintDocument`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}

// update order of selected item
export const updateOrder = (accessToken, id, direction) => {

    // console.log(accessToken, id, direction, "update docs order")

    var requestBody = {
        "id": id,
        "sequenceNo": 0,
        "type": direction
    };

    const headers = {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
    }

    return axios
        .post(`${servicePath}/Document/UpdateDocumentOrder`, requestBody, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}

// update details of selected item
export const updateItemDetails = (accessToken, id, printId, data, tabName) => {
    // console.log(data, "datadata")

    const headers = {
        'accept': 'text/plain',
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data'
    }

    var apiName = "AddPrintGeneral"

    const formData = new FormData();

    if (tabName === "General") {
        formData.append("Id", id);
        formData.append("Name", data.name);
        formData.append("FileName", data.fileName);
        formData.append("PaperSize", data.pageSize);
        formData.append('PaperOrientation', data.pageType);
        formData.append('Margin', data.margin);
        formData.append('ShowTableOfContentsFrames', data.frame);
        formData.append('MarkUpPriceList', data.markUp);
    }

    if (tabName === "Header") {
        apiName = "AddUpdatePrintHeader"

        formData.append("Id", data.id || '');
        formData.append("PrintId", printId);

        formData.append("Title", data.title);
        formData.append("TitleFontSize", data.titleFontSize);
        formData.append('ProjectInfoFontSize', data.projectInfoFontSize);
        formData.append('ShowHeader', data.showHeader);

        if (data.projectInfo) {
            for (let i = 0; i < data.projectInfo.length; i++) {
                formData.append(`ProjectInfo`, data.projectInfo[i]);
            }
        }

        // formData.append("ProjectInfo", data.projectInfo ? data.projectInfo : null);
    }

    if (tabName === "Foreword" || tabName === "Term") {

        if (tabName === "Foreword") {
            apiName = "AddUpdatePrintForeword"

            if (data.text) {
                for (let i = 0; i < data.text.length; i++) {
                    formData.append(`ForeWordId`, data.text[i]);
                }
            }

        } else {
            apiName = "AddUpdatePrintTerm"

            if (data.text) {
                for (let i = 0; i < data.text.length; i++) {
                    formData.append(`TermId`, data.text[i]);
                }
            }
        }

        formData.append("Id", data.id || '');
        formData.append("PrintId", printId);

        formData.append("FontSize", data.fontSize);

        // formData.append("ForeWordId", data.text);

        if (data.newPageAfter) {
            for (let i = 0; i < data.newPageAfter.length; i++) {
                formData.append(`StartNewPageId`, data.newPageAfter[i]);
            }
        }

        // formData.append("StartNewPageId", data.newPageAfter);
    }

    if (tabName === "Summary") {
        apiName = "AddUpdatePrintSummary"

        formData.append("Id", data.id || '');
        formData.append("PrintId", printId);

        formData.append("Frames", data.frames || false);
        formData.append("Installation", data.installation || false);
        formData.append("Ancillaries", data.ancillaries || false);
        formData.append("AncillaryPrices", data.ancillariesPrice || false);
        formData.append("Total", data.total);
        formData.append("StartNewPageBeforeSummary", data.startNewPageBeforeSummary || false);
    }

    if (tabName === "Manufacturing") {

        apiName = "AddUpdatePrintManufacturing"

        formData.append("Id", data.id || '');
        formData.append("PrintId", printId);

        formData.append("IndividualCuttingList", data.individualCuttingList);
        formData.append("IndCLFirstCut", data.indCLFirstCut);
        formData.append("IndCLFinishedCut", data.indCLFinishedCut);
        formData.append("IndCLLength", data.indCLLength);
        formData.append("IndCLEndPrep", data.indCLEndPrep);
        formData.append("IndGlassSize", data.indGlassSize);
        formData.append("IndPanelSize", data.indPanelSize);
        formData.append("IndDoorSize", data.indDoorSize);

        formData.append("CombinedProfileCuttingList", data.combCutList);
        formData.append("CombinedCuttingDiagram", data.combinedCuttingDiagram);
        formData.append("CombCDFirstCut", data.combCDFirstCut);
        formData.append("CombCDFinishedCut", data.combCDFinishedCut);
        formData.append("CombCLFirstCut", data.combCLFirstCut);
        formData.append("CombCLFinishedCut", data.combCLFinishedCut);
        formData.append("CombGlassSize", data.combGlassSize);
        formData.append("CombPanelSize", data.combPanelSize);
        formData.append("CombDoorSize", data.combDoorSize);
    }

    if (tabName === 'Frame') {
        apiName = "AddUpdatePrintFrame"

        formData.append("Id", data.id || '');
        formData.append("PrintId", printId);

        formData.append("NameFontSize", data.nameFontSize);
        formData.append("StartNewPageForEachFrame", data.StartNewPageForEachFrame);
        formData.append("StartNewRowForEachFrame", data.StartNewRowForEachFrame);

        formData.append("FontSize", data.FontSize);
        formData.append("View", data.View);

        formData.append("MinimumWidth", data.MinimumWidth);
        formData.append("PanelTitleFontSize", data.PanelTitleFontSize);
        formData.append("PropertyIconSize", data.PropertyIconSize);
        formData.append("PropertyFontSize", data.PropertyFontSize);
        formData.append("HideInstallation", data.HideInstallation);
        formData.append("Ancillaries", data.Ancillaries);
        formData.append("Total", data.Total);
        formData.append("ExternalView", data.ExternalView);
        formData.append("InternalView", data.InternalView);
        formData.append("FrameDimensions", data.FrameDimensions);
        formData.append("IncludeSill", data.IncludeSill);
        formData.append("TransomDimensions", data.TransomDimensions);
        formData.append("OpenSash", data.openSash);
        formData.append("Size", data.Size);
        formData.append("RotationX", data.RotationX);
        formData.append("RotationY", data.RotationY);

        formData.append("GridLineHorizontal", data.gridLineHorizontal);
        formData.append("GridLineVertical", data.gridLineVertical);
        formData.append("LeftRightPadding", data.leftRightPadding);
        formData.append("TopBottomPadding", data.topBottomPadding);
        formData.append("Itemised", data.itemised);

        if (data.printFrameInfo) {
            for (let i = 0; i < data.printFrameInfo.length; i++) {
                formData.append(`printFrameInfo`, data.printFrameInfo[i]);
            }
        }

        if (data.printFrameProperty) {
            for (let i = 0; i < data.printFrameProperty.length; i++) {
                formData.append(`printFrameProperty`, data.printFrameProperty[i]);
            }
        }
    }

    return axios
        .post(`${servicePath}/Document/${apiName}`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};

// add new right menu item based on page/type
export const addNewRightItem = (accessToken, data, type) => {

    const formData = new FormData();

    formData.append("Id", '');
    formData.append("Name", data);
    formData.append("FileName", '');
    formData.append("PaperSize", "A4");
    formData.append('PaperOrientation', "Portrait");
    formData.append('Margin', '');
    formData.append('ShowTableOfContentsFrames', 4);
    formData.append('MarkUpPriceList', "");

    const headers = {
        'accept': 'text/plain',
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data'
    }
    return axios
        .post(`${servicePath}/Document/AddPrintGeneral`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};

export const getProjectInfoDropdown = async (accessToken) => {
    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    try {
        const res = await axios
            .get(`${servicePath}/SubAccount/GetProjectList`, { headers });
        return res?.data;
    } catch (error) {
        console.log(error);
        return error?.response?.data;
    }
};

export const getPrintTempApi = async (accessToken, type) => {

    const formData = new FormData()

    formData.append('Type', type);

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    try {
        const res = await axios
            .post(`${servicePath}/PrintTemplate/getAllPrintTemplate`, formData, { headers });
        return res;
    } catch (error) {
        return error;
    }
};