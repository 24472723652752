
import axios from "axios";
import { servicePath } from "../utility/data";


export const getDropDownProfiles = (accessToken, id) => {

    const formData = new FormData();

    formData.append("FrameStyleCollectionId", id);

    let config = {
        method: 'POST',
        maxBodyLength: Infinity,
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'multipart/form-data'
        }
    };

    return axios
        .post(`${servicePath}/Pricing/GetVisibleProfiles`, formData, config)
        .then((res) => {
            return res;
        })
        .catch((error) => {
            return error;
        });
}


export const getAllProfileLinking = (accessToken, id) => {

    const formData = new FormData();

    formData.append("FrameStyleId", id);

    let config = {
        method: 'POST',
        maxBodyLength: Infinity,
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'multipart/form-data'
        }
    };

    return axios
        .post(`${servicePath}/Pricing/GetAllProfileLinking`, formData, config)
        .then((res) => {
            return res;
        })
        .catch((error) => {
            return error;
        });
}

export const getProfileLinking = (accessToken, id) => {

    const formData = new FormData();

    formData.append("Id", id);

    let config = {
        method: 'POST',
        maxBodyLength: Infinity,
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'multipart/form-data'
        }
    };

    return axios
        .post(`${servicePath}/Pricing/GetProfileLinking`, formData, config)
        .then((res) => {
            return res;
        })
        .catch((error) => {
            return error;
        });
}

export const deleteProfileLinking = (accessToken, id) => {

    const formData = new FormData();

    formData.append("Id", id);

    let config = {
        method: 'POST',
        maxBodyLength: Infinity,
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'multipart/form-data'
        }
    };

    return axios
        .post(`${servicePath}/Pricing/DeleteProfileLinking`, formData, config)
        .then((res) => {
            return res;
        })
        .catch((error) => {
            return error;
        });
}

export const addProfileLinking = (accessToken, formDataValues) => {

    const formData = new FormData();

    formData.append("Id", formDataValues.id);
    formData.append("FirstProfile", formDataValues?.FirstProfile);
    formData.append("SecondProfile", formDataValues?.SecondProfile);
    formData.append("Dimension", formDataValues?.Dimension);
    formData.append("Glass", formDataValues?.Glass);
    formData.append("CutType", formDataValues?.CutType);
    formData.append("TypeId", formDataValues?.TypeId);
    formData.append("LengthType", formDataValues?.LengthType);
    formData.append("FrameStyleCollectionId", formDataValues?.FrameStyleCollectionId);
    formData.append("FrameStyleId", formDataValues?.FrameStyleId);

    let config = {
        method: 'POST',
        maxBodyLength: Infinity,
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'multipart/form-data'
        }
    };

    return axios
        .post(`${servicePath}/Pricing/AddUpdateProfileLinking`, formData, config)
        .then((res) => {
            return res;
        })
        .catch((error) => {
            return error;
        });
}

export const getProfileLinkingData = (accessToken, ProfileId, FrameStyleId) => {

    const formData = new FormData();

    formData.append("ProfileId", ProfileId);
    formData.append("FrameStyleId", FrameStyleId);

    let config = {
        method: 'POST',
        maxBodyLength: Infinity,
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'multipart/form-data'
        }
    };

    return axios
        .post(`${servicePath}/Pricing/GetProfileLinkingData`, formData, config)
        .then((res) => {
            return res;
        })
        .catch((error) => {
            return error;
        });
}