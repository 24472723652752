import React from 'react'
import { MdOutlineSell } from 'react-icons/md';
import { Link, useNavigate } from "react-router-dom";
const Editprices = ({ data, hasState }) => {

    const navigate = useNavigate();

    const handleNavigate = () => {
        if (hasState) {
            navigate(data.url,
                { state: { data: data.stateValue } }
            )
        } else {
            console.log(hasState, data, "hasState2")
            navigate(data.url)
        }
    }

    return (
        <div>
            <MdOutlineSell className='text-primary me-2' size={24} />
            <span className='text-primary clickable' onClick={handleNavigate}>
                Edit prices
            </span>
        </div>
    )
}

export default Editprices