import React from 'react'
import { BiPlus, BiTrashAlt } from 'react-icons/bi';
import { MdArrowDownward, MdPersonOutline, MdArrowUpward, MdContentCopy } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';

const DummyRight = () => {

  const rightMenuList = [
    {
      id: '1',
      icon: <MdPersonOutline size={22} />,
      text: 'Lorem ipsum dolor',
      to: '',
    },
    {
      id: '2',
      icon: <MdPersonOutline size={22} />,
      text: 'Lorem ipsum dolor',
      to: '',
    },
    {
      id: '3',
      icon: <MdPersonOutline size={22} />,
      text: 'Lorem ipsum dolor',
      to: '',
    },
    {
      id: '4',
      icon: <MdPersonOutline size={22} />,
      text: 'Lorem ipsum dolor',
      to: '',
    },
    {
      id: '5',
      icon: <MdPersonOutline size={22} />,
      text: 'Lorem ipsum dolor',
      to: '',
    },
  ]

  const rightMenuList2 = [
    {
      id: '1',
      color: "#E81123",
      text: 'Requires follow up',
      to: '',
    },
    {
      id: '2',
      color: "#e89911",
      text: 'Check Quote',
      to: '',
    },
    {
      id: '3',
      color: "#FFB900",
      text: 'Quote Checked',
      to: '',
    },
    {
      id: '4',
      color: "#107C10",
      text: 'Quote Sent',
      to: '',
    },
    {
      id: '5',
      color: "#E3008C",
      text: 'Needs Survey',
      to: '',
    },
    {
      id: '6',
      color: "#00BCF2",
      text: 'Ordered',
      to: '',
    },
  ]

  return (
    <>
      <div className='h-100 d-flex flex-column flex-grow-1 justify-content-between'>

        {/* has_tab has_btns has_head -> add respective class of included compoenent in div of class inside_scroll for proper scroll */}
        <div className='inside_scroll has_btns'>

          <div className='right_list'>

            {/* right side menu listing type 1 */}
            {rightMenuList?.map((item, index) =>
              <div key={index} className='position-relative d-flex align-items-center right_item_wrap has_delete has_copy'>
                <Link to="#" className={`right_item w-100 ${index === 0 && "active"}`}>
                  <span className='me-2'>
                    {item.icon}
                  </span>
                  <span className='text-truncate'>
                    {item.text}
                  </span>
                </Link>
                <span className='position-absolute action_btns'>
                  <Link to="#"> <BiTrashAlt className='text-muted' size={20} /> </Link>
                  <Link to="#"> <MdContentCopy className='text-muted' size={18} /> </Link>
                </span>
              </div>
            )}


            {/* right side menu listing type 2 */}

            {rightMenuList2?.map((item, index) =>
              <div key={index} className='position-relative d-flex align-items-center right_item_wrap has_delete has_copy'>
                <Link to="#" className={`right_item w-100 ${index === 0 && "active"}`}>
                  <span className='me-2 color_circle' style={{ backgroundColor: item.color }} >
                    {item.icon}
                  </span>
                  <span className='text-truncate'>
                    {item.text}
                  </span>
                </Link>
                <span className='position-absolute action_btns'>
                  <Link to="#"> <BiTrashAlt className='text-muted' size={20} /> </Link>
                  <Link to="#"> <MdContentCopy className='text-muted' size={18} /> </Link>
                </span>
              </div>
            )}
          </div>
        </div>

        {/* Start: space for buttons at the bottom */}
        <div className='d-flex gap-2 gap-xl-3 main_buttons'>
          <Button
            color="primary"
            size="md"
            className='px-4'
          >
            <BiPlus size={20} className='me-1' />
            New
          </Button>

          <Button
            color=""
            size="md"
            className='border-0 text-primary px-0'
          >
            <MdArrowUpward size={22} className='me-1' />
            Up
          </Button>
          <Button
            color=""
            size="md"
            className='border-0 text-primary px-0'
          >
            <MdArrowDownward size={22} className='me-1' />
            Down
          </Button>
        </div>
        {/* End: space for buttons at the bottom */}

      </div>
    </>
  )
}

export default DummyRight