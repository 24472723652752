import React, { useEffect, useState } from "react";
import { hasChildren } from "../utils";
import { Collapse, List, ListItem, ListItemIcon, ListItemText } from "material-ui-core";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import { useNavigate, useLocation } from "react-router-dom";
import DynamicIcon from "./DynamicIcon";
import QuotesIcon from '../assets/img/icons/quotes.svg'
import ProductsIcon from '../assets/img/icons/products2.svg'
import SettingsIcon from '../assets/img/icons/settings.svg'
import PricingIcon from '../assets/img/icons/pricing.svg'
// import ProfileLinking from '../assets/img/icons/profile-linking.svg'
import CalenderIcon from '../assets/img/icons/menu_customer_icons/calendar.svg'
import DashboardIcon from '../assets/img/icons/menu_customer_icons/dashboard.svg'
import TaskIcon from '../assets/img/icons/menu_customer_icons/task-manager.svg'
import LeadsIcon from '../assets/img/icons/menu_customer_icons/leads.svg'
import QuoteIcon from '../assets/img/icons/menu_customer_icons/quotes.svg'
import InvoiceIcon from '../assets/img/icons/menu_customer_icons/invoice.svg'
import OrdersIcon from '../assets/img/icons/menu_customer_icons/orders.svg'
import SurveyIcon from '../assets/img/icons/menu_customer_icons/survey.svg'
import DrawingIcon from '../assets/img/icons/menu_customer_icons/drawing.svg'
import ProductionIcon from '../assets/img/icons/menu_customer_icons/production.svg'
import InstallationIcon from '../assets/img/icons/menu_customer_icons/installation.svg'
import ServiceIcon from '../assets/img/icons/menu_customer_icons/service.svg'
import DeliveriesIcon from '../assets/img/icons/menu_customer_icons/deliveries.svg'
import { useRightContext } from "../context/rightMenuContext";
import { pricingHardwareMenu, productHardwareStyleMenu } from "../utility/data";
import { getPlatformMenus } from "../services/platformService";
import { Image } from "react-bootstrap";

const SidebarMenu = ({ setSidebarActive, sidebarActive, role, userData }) => {

    var website_url = userData?.website_url;

    const navigate = useNavigate()
    const location = useLocation();

    const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;

    const { mainMenuActive, setMainMenuActive, subMenuActive, setSubMenuActive, subMenu2Active, setSubMenu2Active, panelMenuList, frameMenuList, frameProductsMenuList, pricingColoursMenuList, pricingPanelMenuList, pricingFrameStylesMenuList, pricingInstallFrameStylesMenuList, pricingFrameProductsMenuList, pricingSashProductsMenuList } = useRightContext()

    const menuAdmin = [
        {
            icon: <DynamicIcon icon={QuotesIcon} />,
            title: "Quotations",
            parent: 'Quotations',
            level: 1,
            to: "/",
            items: []
        },
        {
            icon: <DynamicIcon icon={SettingsIcon} />,
            title: "Settings",
            parent: 'Settings',
            level: 1,
            items: [
                {
                    title: "Accounts",
                    parent: 'Settings',
                    level: 2,
                    items: [
                        {
                            title: "CRM",
                            parent: 'Settings',
                            submenu: 'Accounts',
                            level: 3,
                            to: "/settings/account/crm",
                        },
                        {
                            title: "Sub Accounts",
                            parent: 'Settings',
                            submenu: 'Accounts',
                            level: 3,
                            to: "/settings/account/sub-account"
                        },
                    ]
                },
                {
                    title: "Quotations",
                    parent: 'Settings',
                    level: 2,
                    items: [
                        {
                            title: "Ancillaries",
                            parent: 'Settings',
                            submenu: 'Quotations',
                            level: 3,
                            to: "/settings/quotations/ancillaries"
                        },
                    ]
                },
                {
                    title: "Frames",
                    parent: 'Settings',
                    level: 2,
                    items: [
                        {
                            title: "Ancillaries",
                            parent: 'Settings',
                            submenu: 'Frames',
                            level: 3,
                            to: "/settings/frames/ancillary"
                        },
                    ]
                },
                {
                    title: "Printing",
                    parent: 'Settings',
                    level: 2,
                    items: [
                        {
                            title: "Page setup",
                            parent: 'Settings',
                            submenu: 'Printing',
                            level: 3,
                            to: "/settings/printing/page-setup"
                        },
                        {
                            title: "Forewords",
                            parent: 'Settings',
                            submenu: 'Printing',
                            level: 3,
                            to: "/settings/printing/forewords"
                        },
                        {
                            title: "Terms",
                            parent: 'Settings',
                            submenu: 'Printing',
                            level: 3,
                            to: "/settings/printing/terms"
                        },
                        {
                            title: "Documents",
                            parent: 'Settings',
                            submenu: 'Printing',
                            level: 3,
                            to: "/settings/printing/documents"
                        },
                    ]
                },
                // {
                //     title: "Website designers",
                //     parent: 'Settings',
                //     level: 2,
                //     items: [
                //         {
                //             title: "Retail designer",
                //             parent: 'Settings',
                //             submenu: 'Website designers',
                //             level: 3,
                //             to: "/settings/website/retail-designer"
                //         },
                //         {
                //             title: "Trade designer",
                //             parent: 'Settings',
                //             submenu: 'Website designers',
                //             level: 3,
                //             to: "/settings/website/trade-designer"
                //         }
                //     ]
                // },
            ]
        },
        {
            icon: <DynamicIcon icon={ProductsIcon} />,
            title: "Products",
            parent: 'Products',
            level: 1,
            items: [
                {
                    title: "Systems",
                    parent: 'Products',
                    level: 2,
                    items: [
                        {
                            id: 0,
                            title: "Profile Joints",
                            parent: 'Products',
                            submenu: 'Systems',
                            submenu2: '',
                            level: 3,
                            items: [],
                            to: "/product/systems/profile-joints",
                        },
                        {
                            title: "Frame Styles",
                            parent: 'Products',
                            submenu: 'Systems',
                            level: 3,
                            items: [
                                {
                                    "id": 0,
                                    "title": "Collections",
                                    "parent": "Products",
                                    "submenu": "Systems",
                                    "submenu2": "Frame Styles",
                                    "level": 4,
                                    "to": "/product/frame-styles",
                                    "items": [],
                                },
                                {
                                    "id": 1,
                                    "title": "Style items",
                                    "parent": "Products",
                                    "submenu": "Systems",
                                    "submenu2": "Frame Styles",
                                    "level": 4,
                                    "to": "/product/frame-styles/list",
                                    "items": [],
                                },
                            ]
                        },
                        {
                            id: "0",
                            title: "Frame",
                            parent: 'Products',
                            submenu: 'Systems',
                            submenu2: '',
                            level: 3,
                            items: [
                                {
                                    "id": 0,
                                    "title": "Collections",
                                    "parent": "Products",
                                    "submenu": "Systems",
                                    "level": 4,
                                    "to": "/product/systems/frame-products",
                                    "items": [],
                                    "submenu2": "Frame"
                                },
                                {
                                    "id": 1,
                                    "title": "Frame Products",
                                    "parent": "Products",
                                    "submenu": "Systems",
                                    "level": 4,
                                    "to": "/product/systems/frame-products/list",
                                    "items": [],
                                    "submenu2": "Frame"
                                },
                            ]
                        },
                        {
                            id: "0",
                            title: "Sash",
                            parent: 'Products',
                            submenu: 'Systems',
                            submenu2: '',
                            level: 3,
                            to: "/product/systems/sash/list",
                            items: [],
                            // items: sashProductsSidebarMenu
                        },
                    ]
                },
                {
                    title: "Colours",
                    parent: 'Products',
                    level: 2,
                    items: [
                        {
                            title: "Palette",
                            parent: 'Products',
                            submenu: 'Colours',
                            level: 3,
                            to: "/product/colours/palette",
                        },
                        {
                            title: "Collections",
                            parent: 'Products',
                            submenu: 'Colours',
                            level: 3,
                            to: "/product/colours/collections",
                        },
                    ]
                },
                {
                    title: "Profiles",
                    parent: 'Products',
                    level: 2,
                    to:'/product/profiles/list',
                    // items: [
                    //     {
                    //         id: "0",
                    //         title: "Profile Items",
                    //         parent: 'Profiles',
                    //         submenu: '',
                    //         submenu2: '',
                    //         level: 3,
                    //         to: "/product/profiles/list",
                    //         items: [],
                    //         // items: sashProductsSidebarMenu
                    //     },
                    //     {
                    //         id: "1",
                    //         title: "Additional Profile",
                    //         parent: 'Profiles',
                    //         submenu: '',
                    //         submenu2: '',
                    //         level: 3,
                    //         to: "/product/profiles/additional-profile",
                    //         items: [],
                    //     }
                    // ]
                },
                {
                    title: "Hardware",
                    parent: 'Products',
                    level: 2,
                    items: [
                        {
                            title: "Colours",
                            parent: 'Products',
                            submenu: 'Hardware',
                            level: 3,
                            items: [
                                {
                                    title: "Palette",
                                    parent: 'Products',
                                    submenu: 'Hardware',
                                    submenu2: 'Colours',
                                    level: 4,
                                    to: "/product/hardware/colours/palette",
                                },
                                {
                                    title: "Collections",
                                    parent: 'Products',
                                    submenu: 'Hardware',
                                    submenu2: 'Colours',
                                    level: 4,
                                    to: "/product/hardware/colours/collections",
                                }
                            ]
                        },
                        {
                            title: "Hardware",
                            parent: 'Products',
                            submenu: 'Hardware',
                            level: 3,
                            to: "/product/hardware/list",
                            items: []
                        },
                        {
                            title: "Collection",
                            parent: 'Products',
                            submenu: 'Hardware',
                            level: 3,
                            to: "/product/hardware/collections",
                        },
                        // {
                        //     title: "Accessories",
                        //     parent: 'Products',
                        //     submenu: 'Hardware',
                        //     level: 3,
                        //     to: "/product/hardware/accessories",
                        // },
                        // {
                        //     title: "Additional Hardware",
                        //     parent: 'Products',
                        //     submenu: 'Hardware',
                        //     level: 3,
                        //     to: "/product/hardware/additional-hardware",
                        // },
                    ]
                },
                {
                    title: "Glazing",
                    parent: 'Products',
                    level: 2,
                    items: [
                        {
                            title: "Specifications",
                            parent: 'Products',
                            submenu: 'Glazing',
                            level: 3,
                            to: "/product/glazing/specifications",
                        },
                        {
                            title: "Spacer bars",
                            parent: 'Products',
                            submenu: 'Glazing',
                            level: 3,
                            to: "/product/glazing/spacer-bars",
                        },
                        {
                            title: "Textures",
                            parent: 'Products',
                            submenu: 'Glazing',
                            level: 3,
                            to: "/product/glazing/textures",
                        },
                        {
                            title: "Designs",
                            parent: 'Products',
                            submenu: 'Glazing',
                            level: 3,
                            to: "/product/glazing/designs",
                        },
                    ]
                },
                {
                    title: "Panels",
                    parent: 'Products',
                    level: 2,
                    items: panelMenuList
                },
                // {
                //     title: "Building",
                //     parent: 'Products',
                //     level: 2,
                //     items: [
                //         {
                //             title: "Colours",
                //             parent: 'Products',
                //             submenu: 'Building',
                //             level: 3,
                //             to: "/product/building/colours",
                //         }
                //     ]
                // }
            ]
        },
        {
            icon: <DynamicIcon icon={PricingIcon} />,
            title: "Pricing",
            parent: 'Pricing',
            level: 1,
            items: [
                {
                    title: "General",
                    parent: 'Pricing',
                    level: 2,
                    items: [
                        {
                            id: "1",
                            title: "Project defaults",
                            parent: 'Pricing',
                            submenu: 'General',
                            submenu2: '',
                            level: 3,
                            to: "/pricing/general/project-default",
                        },
                        {
                            id: "2",
                            title: "Taxes",
                            parent: 'Pricing',
                            submenu: 'General',
                            submenu2: '',
                            level: 3,
                            to: "/pricing/general/taxes",
                        },
                        {
                            id: "3",
                            title: "Project ancillaries",
                            parent: 'Pricing',
                            submenu: 'General',
                            submenu2: '',
                            level: 3,
                            to: "/pricing/general/project-ancillaries",
                        },
                        {
                            id: "4",
                            title: "Frame ancillaries",
                            parent: 'Pricing',
                            submenu: 'General',
                            submenu2: '',
                            level: 3,
                            to: "/pricing/general/frame-ancillaries"
                        },
                        {
                            id: "5",
                            title: "Promotions",
                            parent: 'Pricing',
                            submenu: 'General',
                            submenu2: '',
                            level: 3,
                            to: '/pricing/general/promotion'
                        },
                        {
                            id: "6",
                            title: "Global mark-up",
                            parent: 'Pricing',
                            submenu: 'General',
                            submenu2: '',
                            level: 3,
                            to: '/pricing/general/mark-up'
                        },
                    ]
                },
                {
                    title: "Systems",
                    parent: 'Pricing',
                    level: 2,
                    items: [
                        {
                            id: '0',
                            title: "Frame Styles",
                            parent: 'Pricing',
                            submenu: 'Systems',
                            level: 3,
                            to: '/pricing/frame-styles',
                            items: []
                        },
                        {
                            id: "0",
                            title: "Frame",
                            parent: 'Pricing',
                            submenu: 'Systems',
                            submenu2: '',
                            level: 3,
                            to: '/pricing/frame-products',
                            items: []
                        },
                        {
                            id: "0",
                            title: "Sash",
                            parent: 'Pricing',
                            submenu: 'Systems',
                            submenu2: '',
                            level: 3,
                            to: '/pricing/sash',
                            items: []
                        },
                    ]
                },
                {
                    title: "Colours",
                    parent: "Pricing",
                    level: 2,
                    items: [
                        {
                            title: "Price List",
                            parent: 'Pricing',
                            submenu: 'Colours',
                            level: 3,
                            items: pricingColoursMenuList
                        },
                        // {
                        //     id: "2",
                        //     title: "Profile",
                        //     parent: 'Pricing',
                        //     submenu: 'Colours',
                        //     submenu2: '',
                        //     level: 3,
                        //     items: [
                        //         {
                        //             id: "1",
                        //             title: "Add-on",
                        //             parent: 'Pricing',
                        //             submenu: 'Colours',
                        //             submenu2: "Profile",
                        //             level: 4,
                        //             to: "/pricing/colours/profile/1"
                        //         }
                        //     ]
                        // },
                        // {
                        //     id: "3",
                        //     title: "Frame Products",
                        //     parent: 'Pricing',
                        //     submenu: 'Colours',
                        //     submenu2: '',
                        //     level: 3,
                        //     to: "/pricing/colours/frame-products",
                        // },
                        // {
                        //     id: "3",
                        //     title: "Glazing Designs",
                        //     parent: 'Pricing',
                        //     submenu: 'Colours',
                        //     submenu2: '',
                        //     level: 3,
                        //     to: "/pricing/colours/glazing-designs",
                        // },
                        // {
                        //     id: "4",
                        //     title: "Panel Products",
                        //     parent: 'Pricing',
                        //     submenu: 'Colours',
                        //     submenu2: '',
                        //     level: 3,
                        //     to: "/pricing/colours/panel-products",
                        // },
                    ]
                },
                {
                    title: "Profile",
                    parent: 'Pricing',
                    level: 2,
                    to:'/pricing/profiles',
                    // items: [
                    //     {
                    //         id: "0",
                    //         title: "Profile Items",
                    //         parent: "Pricing",
                    //         submenu: "Profile",
                    //         level: 3,
                    //         to: "/pricing/profiles"
                    //     },
                    //     {
                    //         id: "1",
                    //         title: "Additional Profile",
                    //         parent: "Pricing",
                    //         submenu: "Profile",
                    //         level: 3,
                    //         to: "/pricing/additional-profile"
                    //     }
                    // ]
                },
                {
                    title: "Hardware",
                    parent: 'Pricing',
                    level: 2,
                    to: "/pricing/hardware",
                    items: []
                },
                {
                    title: "Glazing",
                    parent: 'Pricing',
                    level: 2,
                    items: [
                        {
                            id: "1",
                            title: "Specifications",
                            parent: "Pricing",
                            submenu: "Glazing",
                            level: 3,
                            to: "/pricing/glazing/1"
                        },
                        {
                            id: "3",
                            title: "Textures",
                            parent: "Pricing",
                            submenu: "Glazing",
                            level: 3,
                            to: "/pricing/glazing/3"
                        },
                        {
                            id: "4",
                            title: "Designs",
                            parent: "Pricing",
                            submenu: "Glazing",
                            level: 3,
                            to: "/pricing/glazing/4"
                        },
                        {
                            id: "5",
                            title: "Oversize",
                            parent: "Pricing",
                            submenu: "Glazing",
                            level: 3,
                            to: "/pricing/glazing/oversize-glazing"
                        },
                    ]
                },
                {
                    title: "Panels",
                    parent: 'Pricing',
                    level: 2,
                    items: pricingPanelMenuList
                },
                {
                    title: "Installation",
                    parent: 'Pricing',
                    level: 2,
                    items: [
                        {
                            id: "1",
                            title: "Frame styles",
                            parent: "Pricing",
                            submenu: "Installation",
                            level: 3,
                            to: '/pricing/installation/frame-styles'
                        },
                        // {
                        //     id: "2",
                        //     title: "Frame products",
                        //     parent: "Pricing",
                        //     submenu: "Installation",
                        //     level: 3,
                        //     items: [
                        //         {
                        //             id: "1",
                        //             title: "GS20 Sliding door",
                        //             parent: "Pricing",
                        //             submenu: "Installation",
                        //             submenu2: "Frame products",
                        //             level: 4,
                        //             to: "/pricing/installation/frame-products/1"
                        //         }
                        //     ]
                        // }
                    ]
                },
                // {
                //     title: "Additional Articles",
                //     parent: 'Pricing',
                //     level: 2,
                //     to: "/additional-articles-pricing",
                //     items: []
                // },
            ],
        },
        // {
        //     icon: <DynamicIcon icon={ProfileLinking} />,
        //     title: "Profile Linking",
        //     parent: 'Profile Linking',
        //     level: 1,
        //     to: "/profile-linking",
        //     items: []
        // },
        // {
        //     icon:<DynamicIcon icon={ProductsIcon} />,
        //     title: "Additional Articles",
        //     parent: 'Additional Articles',
        //     level: 1,
        //     to: "/additional-articles",
        //     items: []
        // },
    ];

    const menuSupplier = [
        {
            icon: <DynamicIcon icon={QuotesIcon} />,
            title: "Quotations",
            parent: 'Quotations',
            level: 1,
            to: "/",
            items: []
        },
        {
            icon: <DynamicIcon icon={ProductsIcon} />,
            title: "Products",
            parent: 'Products',
            level: 1,
            items: [
                {
                    title: "Systems",
                    parent: 'Products',
                    level: 2,
                    items: [
                        {
                            id: 0,
                            title: "Profile Joints",
                            parent: 'Products',
                            submenu: 'Systems',
                            submenu2: '',
                            level: 3,
                            items: [],
                            to: "/product/systems/profile-joints",
                        },
                        {
                            title: "Frame Styles",
                            parent: 'Products',
                            submenu: 'Systems',
                            level: 3,
                            items: [
                                {
                                    "id": 0,
                                    "title": "Collections",
                                    "parent": "Products",
                                    "submenu": "Systems",
                                    "submenu2": "Frame Styles",
                                    "level": 4,
                                    "to": "/product/frame-styles",
                                    "items": [],
                                },
                                {
                                    "id": 1,
                                    "title": "Style items",
                                    "parent": "Products",
                                    "submenu": "Systems",
                                    "submenu2": "Frame Styles",
                                    "level": 4,
                                    "to": "/product/frame-styles/list",
                                    "items": [],
                                },
                            ]
                        },
                        {
                            id: "0",
                            title: "Frame",
                            parent: 'Products',
                            submenu: 'Systems',
                            submenu2: '',
                            level: 3,
                            items: [
                                {
                                    "id": 0,
                                    "title": "Collections",
                                    "parent": "Products",
                                    "submenu": "Systems",
                                    "level": 4,
                                    "to": "/product/systems/frame-products",
                                    "items": [],
                                    "submenu2": "Frame"
                                },
                                {
                                    "id": 1,
                                    "title": "Frame Products",
                                    "parent": "Products",
                                    "submenu": "Systems",
                                    "level": 4,
                                    "to": "/product/systems/frame-products/list",
                                    "items": [],
                                    "submenu2": "Frame"
                                },
                            ]
                        },
                        {
                            id: "0",
                            title: "Sash",
                            parent: 'Products',
                            submenu: 'Systems',
                            submenu2: '',
                            level: 3,
                            to: "/product/systems/sash/list",
                            items: [],
                            // items: sashProductsSidebarMenu
                        },
                    ]
                },
                {
                    title: "Colours",
                    parent: 'Products',
                    level: 2,
                    items: [
                        {
                            title: "Palette",
                            parent: 'Products',
                            submenu: 'Colours',
                            level: 3,
                            to: "/product/colours/palette",
                        },
                        {
                            title: "Collections",
                            parent: 'Products',
                            submenu: 'Colours',
                            level: 3,
                            to: "/product/colours/collections",
                        },
                    ]
                },
                {
                    title: "Profiles",
                    parent: 'Products',
                    level: 2,
                    to:'/product/profiles/list',
                    // items: [
                    //     {
                    //         id: "0",
                    //         title: "Profile Items",
                    //         parent: 'Profiles',
                    //         submenu: '',
                    //         submenu2: '',
                    //         level: 3,
                    //         to: "/product/profiles/list",
                    //         items: [],
                    //         // items: sashProductsSidebarMenu
                    //     },
                    //     {
                    //         id: "1",
                    //         title: "Additional Profile",
                    //         parent: 'Profiles',
                    //         submenu: '',
                    //         submenu2: '',
                    //         level: 3,
                    //         to: "/product/profiles/additional-profile",
                    //         items: [],
                    //     }
                    // ]
                },
                {
                    title: "Hardware",
                    parent: 'Products',
                    level: 2,
                    items: [
                        {
                            title: "Colours",
                            parent: 'Products',
                            submenu: 'Hardware',
                            level: 3,
                            items: [
                                {
                                    title: "Palette",
                                    parent: 'Products',
                                    submenu: 'Hardware',
                                    submenu2: 'Colours',
                                    level: 4,
                                    to: "/product/hardware/colours/palette",
                                },
                                {
                                    title: "Collections",
                                    parent: 'Products',
                                    submenu: 'Hardware',
                                    submenu2: 'Colours',
                                    level: 4,
                                    to: "/product/hardware/colours/collections",
                                }
                            ]
                        },
                        // {
                        //     title: "Styles",
                        //     parent: 'Products',
                        //     submenu: 'Hardware',
                        //     level: 3,
                        //     items: productHardwareStyleMenu
                        // },
                        {
                            title: "Hardware",
                            parent: 'Products',
                            submenu: 'Hardware',
                            level: 3,
                            to: "/product/hardware/list",
                            items: []
                        },
                        {
                            title: "Collection",
                            parent: 'Products',
                            submenu: 'Hardware',
                            level: 3,
                            to: "/product/hardware/collections",
                        },
                        // {
                        //     title: "Accessories",
                        //     parent: 'Products',
                        //     submenu: 'Hardware',
                        //     level: 3,
                        //     to: "/product/hardware/accessories",
                        // },
                        // {
                        //     title: "Additional Hardware",
                        //     parent: 'Products',
                        //     submenu: 'Hardware',
                        //     level: 3,
                        //     to: "/product/hardware/additional-hardware",
                        // },
                    ]
                },
                {
                    title: "Glazing",
                    parent: 'Products',
                    level: 2,
                    items: [
                        {
                            title: "Specifications",
                            parent: 'Products',
                            submenu: 'Glazing',
                            level: 3,
                            to: "/product/glazing/specifications",
                        },
                        {
                            title: "Spacer bars",
                            parent: 'Products',
                            submenu: 'Glazing',
                            level: 3,
                            to: "/product/glazing/spacer-bars",
                        },
                        {
                            title: "Textures",
                            parent: 'Products',
                            submenu: 'Glazing',
                            level: 3,
                            to: "/product/glazing/textures",
                        },
                        {
                            title: "Designs",
                            parent: 'Products',
                            submenu: 'Glazing',
                            level: 3,
                            to: "/product/glazing/designs",
                        },
                    ]
                },
                {
                    title: "Panels",
                    parent: 'Products',
                    level: 2,
                    items: panelMenuList
                },
            ]
        },
        {
            icon: <DynamicIcon icon={PricingIcon} />,
            title: "Pricing",
            parent: 'Pricing',
            level: 1,
            items: [
                {
                    title: "General",
                    parent: 'Pricing',
                    level: 2,
                    items: [
                        {
                            id: "1",
                            title: "Project defaults",
                            parent: 'Pricing',
                            submenu: 'General',
                            submenu2: '',
                            level: 3,
                            to: "/pricing/general/project-default",
                        },
                        {
                            id: "2",
                            title: "Taxes",
                            parent: 'Pricing',
                            submenu: 'General',
                            submenu2: '',
                            level: 3,
                            to: "/pricing/general/taxes",
                        },
                        {
                            id: "3",
                            title: "Project ancillaries",
                            parent: 'Pricing',
                            submenu: 'General',
                            submenu2: '',
                            level: 3,
                            to: "/pricing/general/project-ancillaries",
                        },
                        {
                            id: "4",
                            title: "Frame ancillaries",
                            parent: 'Pricing',
                            submenu: 'General',
                            submenu2: '',
                            level: 3,
                            to: "/pricing/general/frame-ancillaries"
                        },
                        {
                            id: "5",
                            title: "Promotions",
                            parent: 'Pricing',
                            submenu: 'General',
                            submenu2: '',
                            level: 3,
                            to: '/pricing/general/promotion'
                        },
                        {
                            id: "6",
                            title: "Global mark-up",
                            parent: 'Pricing',
                            submenu: 'General',
                            submenu2: '',
                            level: 3,
                            to: '/pricing/general/mark-up'
                        },
                    ]
                },
                {
                    title: "Systems",
                    parent: 'Pricing',
                    level: 2,
                    items: [
                        {
                            id: '0',
                            title: "Frame Styles",
                            parent: 'Pricing',
                            submenu: 'Systems',
                            level: 3,
                            to: '/pricing/frame-styles',
                            items: []
                        },
                        {
                            id: "0",
                            title: "Frame",
                            parent: 'Pricing',
                            submenu: 'Systems',
                            submenu2: '',
                            level: 3,
                            to: '/pricing/frame-products',
                            items: []
                        },
                        {
                            id: "0",
                            title: "Sash",
                            parent: 'Pricing',
                            submenu: 'Systems',
                            submenu2: '',
                            level: 3,
                            to: '/pricing/sash',
                            items: []
                        },
                    ]
                },
                {
                    title: "Colours",
                    parent: "Pricing",
                    level: 2,
                    items: [
                        {
                            title: "Price List",
                            parent: 'Pricing',
                            submenu: 'Colours',
                            level: 3,
                            items: pricingColoursMenuList
                        },
                        // {
                        //     id: "2",
                        //     title: "Profile",
                        //     parent: 'Pricing',
                        //     submenu: 'Colours',
                        //     submenu2: '',
                        //     level: 3,
                        //     items: [
                        //         {
                        //             id: "1",
                        //             title: "Add-on",
                        //             parent: 'Pricing',
                        //             submenu: 'Colours',
                        //             submenu2: "Profile",
                        //             level: 4,
                        //             to: "/pricing/colours/profile/1"
                        //         }
                        //     ]
                        // },
                        // {
                        //     id: "3",
                        //     title: "Frame Products",
                        //     parent: 'Pricing',
                        //     submenu: 'Colours',
                        //     submenu2: '',
                        //     level: 3,
                        //     to: "/pricing/colours/frame-products",
                        // },
                        // {
                        //     id: "3",
                        //     title: "Glazing Designs",
                        //     parent: 'Pricing',
                        //     submenu: 'Colours',
                        //     submenu2: '',
                        //     level: 3,
                        //     to: "/pricing/colours/glazing-designs",
                        // },
                        // {
                        //     id: "4",
                        //     title: "Panel Products",
                        //     parent: 'Pricing',
                        //     submenu: 'Colours',
                        //     submenu2: '',
                        //     level: 3,
                        //     to: "/pricing/colours/panel-products",
                        // },
                    ]
                },

                {
                    title: "Profiles",
                    parent: 'Pricing',
                    level: 2,
                    to:'/pricing/profiles',
                },
                // {
                //     title: "Profile",
                //     parent: 'Pricing',
                //     level: 2,
                //     items: [
                //         {
                //             id: "0",
                //             title: "Profile Items",
                //             parent: "Pricing",
                //             submenu: "Profile",
                //             level: 3,
                //             to: "/pricing/profiles"
                //         },
                //         {
                //             id: "1",
                //             title: "Additional Profile",
                //             parent: "Pricing",
                //             submenu: "Profile",
                //             level: 3,
                //             to: "/pricing/additional-profile"
                //         }
                //     ]
                // },
                {
                    title: "Hardware",
                    parent: 'Pricing',
                    level: 2,
                    to: "/pricing/hardware",
                    items: []
                },
                // {
                //     title: "Hardware",
                //     parent: 'Pricing',
                //     level: 2,
                //     items: pricingHardwareMenu
                // },
                {
                    title: "Glazing",
                    parent: 'Pricing',
                    level: 2,
                    items: [
                        {
                            id: "1",
                            title: "Specifications",
                            parent: "Pricing",
                            submenu: "Glazing",
                            level: 3,
                            to: "/pricing/glazing/1"
                        },
                        {
                            id: "3",
                            title: "Textures",
                            parent: "Pricing",
                            submenu: "Glazing",
                            level: 3,
                            to: "/pricing/glazing/3"
                        },
                        {
                            id: "4",
                            title: "Designs",
                            parent: "Pricing",
                            submenu: "Glazing",
                            level: 3,
                            to: "/pricing/glazing/4"
                        },
                        {
                            id: "5",
                            title: "Oversize",
                            parent: "Pricing",
                            submenu: "Glazing",
                            level: 3,
                            to: "/pricing/glazing/oversize-glazing"
                        },
                    ]
                },
                {
                    title: "Panels",
                    parent: 'Pricing',
                    level: 2,
                    items: pricingPanelMenuList
                },
                {
                    title: "Installation",
                    parent: 'Pricing',
                    level: 2,
                    items: [
                        {
                            id: "1",
                            title: "Frame styles",
                            parent: "Pricing",
                            submenu: "Installation",
                            level: 3,
                            to: '/pricing/installation/frame-styles'
                        },
                        // {
                        //     id: "2",
                        //     title: "Frame products",
                        //     parent: "Pricing",
                        //     submenu: "Installation",
                        //     level: 3,
                        //     items: [
                        //         {
                        //             id: "1",
                        //             title: "GS20 Sliding door",
                        //             parent: "Pricing",
                        //             submenu: "Installation",
                        //             submenu2: "Frame products",
                        //             level: 4,
                        //             to: "/pricing/installation/frame-products/1"
                        //         }
                        //     ]
                        // }
                    ]
                },
                // {
                //     title: "Additional Articles",
                //     parent: 'Pricing',
                //     level: 2,
                //     to: "/additional-articles-pricing",
                //     items: []
                // },
            ],
        },
    ];

    const menuCustomer = [
        {
            icon: <DynamicIcon icon={DashboardIcon} />,
            title: "Dashboard",
            parent: 'Dashboard',
            level: 1,
            to: `https://${website_url}/dashboard`,
            items: []
        },
        {
            icon: <DynamicIcon icon={CalenderIcon} />,
            title: "Calendar",
            parent: 'Calendar',
            level: 1,
            to: `https://${website_url}/calendar`,
            items: []
        },
        {
            icon: <DynamicIcon icon={TaskIcon} />,
            title: "Tasks",
            parent: 'Tasks',
            level: 1,
            to: `https://${website_url}/task/manager`,
            items: []
        },
        {
            icon: <DynamicIcon icon={LeadsIcon} />,
            title: "Leads",
            parent: 'Leads',
            level: 1,
            to: `https://${website_url}/leads`,
            items: []
        },
        {
            icon: <DynamicIcon icon={QuoteIcon} />,
            title: "Quotes",
            parent: 'Quotes',
            level: 1,
            to: `https://${website_url}/quotations`,
            items: []
        },
        {
            icon: <DynamicIcon icon={InvoiceIcon} />,
            title: "Invoices",
            parent: 'Invoices',
            level: 1,
            to: `https://${website_url}/invoices`,
            items: []
        },
        {
            icon: <DynamicIcon icon={OrdersIcon} />,
            title: "Orders",
            parent: 'Orders',
            level: 1,
            to: `https://${website_url}/orders`,
            items: []
        },
        {
            icon: <DynamicIcon icon={SurveyIcon} />,
            title: "Survey",
            parent: 'Survey',
            level: 1,
            to: `https://${website_url}/surveys`,
            items: []
        },
        {
            icon: <DynamicIcon icon={DrawingIcon} />,
            title: "Drawing",
            parent: 'Drawing',
            level: 1,
            to: `https://${website_url}/drawings`,
            items: []
        },
        {
            icon: <DynamicIcon icon={ProductionIcon} />,
            title: "Production",
            parent: 'Production',
            level: 1,
            to: `https://${website_url}/productions/create`,
            items: []
        },
        {
            icon: <DynamicIcon icon={InstallationIcon} />,
            title: "Installation",
            parent: 'Installation',
            level: 1,
            to: `https://${website_url}/installations`,
            items: []
        },
        {
            icon: <DynamicIcon icon={ServiceIcon} />,
            title: "Service",
            parent: 'Service',
            level: 1,
            to: `https://${website_url}/services`,
            items: []
        },
        {
            icon: <DynamicIcon icon={DeliveriesIcon} />,
            title: "Deliveries",
            parent: 'Deliveries',
            level: 1,
            to: `https://${website_url}/deliveries`,
            items: []
        },
        {
            icon: <DynamicIcon icon={SettingsIcon} />,
            title: "Settings",
            parent: 'Settings',
            level: 1,
            to: `https://${website_url}/settings`,
            items: []
        },
    ]

    const [menu, setMenu] = useState()
    // const [mainMenuActive, setMainMenuActive] = useState('Quotations')
    // const [subMenuActive, setSubMenuActive] = useState('')
    // const [subMenu2Active, setSubMenu2Active] = useState('')

    useEffect(() => {
        if (role === "Admin" || role === "Super Admin") {
            setMenu(menuAdmin)
        } else if (role === "Supplier") {
            setMenu(menuSupplier)
        }

        // else if (role === "Customer" || role === "Trade") {
        //     setMenu(menuCustomer)
        // }
    }, [role, panelMenuList, frameMenuList, frameProductsMenuList, pricingColoursMenuList, pricingPanelMenuList, pricingFrameStylesMenuList, pricingInstallFrameStylesMenuList, pricingFrameProductsMenuList, pricingSashProductsMenuList])

    useEffect(() => {
        // console.log(userData, "userData")
        if (userData && userData.website_url && (role !== "Admin" && role !== "Super Admin" && role !== "Supplier")) {
            getPlatformMenusService(userData?.website_url, userData?.user_id)
        }
    }, [userData])

    const getPlatformMenusService = async (fetch_url, user_id) => {
        const res = await getPlatformMenus(fetch_url, user_id);

        console.log(res.status, "menu data")

        if (res?.status === 200) {
            if (res?.data) {
                setMenu(res?.data);
            }
        } else {
            setMenu([]);

            if (res.message === "Network Error") {
                navigate("/error/network");
            }
        }
    };

    const MenuItem = ({ item }) => {
        const Component = hasChildren(item) ? MultiLevel : SingleLevel;
        return <Component item={item} />;
    };

    useEffect(() => {
        if (location.pathname == '/') {
            setMainMenuActive("Quotations")
        }
    }, [location])

    const SingleLevel = ({ item }) => {

        const handleURLClick = (item) => {

            // setSidebarActive(false);

            if (item && item.to) {
                if (role === "Admin" || role === "Super Admin" || role === "Supplier") {
                    if (item.parent === item.title) {
                        setMainMenuActive(item.parent ? item.parent : 'Quotations')
                        setSubMenuActive('')
                        setSubMenu2Active('')
                    } else {
                        setMainMenuActive(item.parent ? item.parent : 'Quotations')
                        setSubMenuActive(item.submenu ? item.submenu : '')

                        if (item.level === 4) {
                            setSubMenu2Active(item.submenu2 ? item.submenu2 : '')
                        } else {
                            setSubMenu2Active('')
                        }
                    }

                    // if (window.innerWidth < 10000) {
                    //     setSidebarActive(false);
                    // }

                    navigate(item.to);
                } else {
                    window.open(item.to, "_self")
                }
            }
        };

        return (
            <ListItem button className={`page_links ${item.parent === item.title ? "main_menu" : `sub_menu sub_menu_${item.level}`} ${location.pathname === item.to && 'mm-active'}`} onClick={() => { handleURLClick(item) }}>
                {/* {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>} */}

                {/* <span className="menu_icon">
                    <Image src={item.icon} alt="icon" />
                </span> */}

                {item?.icon && urlPattern.test(item?.icon) ? (
                    <>
                        <span className="menu_icon">
                            <Image src={item.icon} alt="icon" />
                        </span>
                    </>
                ) : (
                    <>
                        <ListItemIcon>{item.icon}</ListItemIcon>
                    </>
                )}

                <ListItemText primary={item.title} />
            </ListItem>
        );
    };

    var i = 0;

    // function increment() { i += 1; }

    const MultiLevel = ({ item }) => {

        const { items: children } = item;

        const [open, setOpen] = useState(false);

        const handleClick = (e, itemClicked) => {

            e.stopPropagation()

            if (sidebarActive) {
                if (e.target.classList.contains("test_0") && e.target.classList.contains("initial_active")) {

                    e.target.classList.remove("test_0")
                    e.target.classList.remove("initial_active")
                } else {
                    setOpen((prev) => !prev);

                    e.target.classList.remove("initial_active")
                }
            } else {
                setSidebarActive(true); // comment for new sidebar

                console.log('menu_test')
            }

            // if(itemClicked.title === "Colours" && itemClicked.parent === "Pricing" && itemClicked.level == 2) {
            //     console.log(itemClicked, "itemClicked")
            // }
        };

        return (
            <React.Fragment>
                <ListItem button className={`test_${i} ${(item.parent === item.title && item.level === 1) ? `main_menu ${mainMenuActive === item.parent ? "mm-active initial_active" : ''}` : `sub_menu sub_menu_${item.level} ${(subMenuActive === item.title && item.level === 2 && mainMenuActive === item.parent) ? 'mm-active initial_active' : ''} ${(subMenu2Active === item.title && item.level === 3 && subMenuActive === item.submenu && mainMenuActive === item.parent) ? 'mm-active initial_active' : ''}`}`} onClick={(e) => { handleClick(e, item) }}>
                    {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
                    <ListItemText primary={item.title} />
                    {open ? <BiChevronUp className="up_icon" /> : <BiChevronDown className="down_icon" />}
                </ListItem>

                <Collapse in={open} timeout="auto">
                    <List component="div" disablePadding>
                        {children !== undefined && children?.map((child, key) => (
                            <MenuItem key={key} item={child} />
                        ))}
                    </List>
                </Collapse>
            </React.Fragment>
        );
    };

    return (
        <>
            {menu && menu.length > 0 && menu?.map((item, key) => <MenuItem key={key} item={item} />)}
        </>
    );
}

export default SidebarMenu;