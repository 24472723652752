import React, { useState } from 'react'
import InnerLayout from "../../../layouts/internalLayout"
import ComingSoon from './errors/coming-soon'

const Pricing = () => {

    const [wip, setWIP] = useState(true)

    return (
        <>
            {wip ? (
                <ComingSoon title="Pricing Coming Soon" />
            ) : (
                <InnerLayout pageTitle="Pricing" layoutType="full" leftContent='' rightContent='' />
            )}
        </>
    )
}

export default Pricing