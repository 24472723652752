import React from "react";
import { capitalizeFirstLetter, getOrientation } from "../utility/helper";

const DisplayPricingDataComp = ({
  pricingData,
  getEachPrice,
  totalEachPrice,
}) => {
  const getCountString = (count) => {
    if (count > 1) {
      return `${count} x `;
    } else {
      return "";
    }
  };
  return (
    <>
      {Object.values(pricingData).map((item, index) => (
        <tr key={index} className="border-bottom">
          <td className="text-start text-wrap">
            {item.frameType}
            {item?.type && `(${capitalizeFirstLetter(item?.type)})`}
            {item?.orientation !== "" &&
              `(${getOrientation(item?.orientation)})`}
          </td>
          <td className="fw-bold text-wrap text-start">
            {getCountString(item?.count)} {item.name}
          </td>
          <td className="text-end">
            £{getEachPrice(totalEachPrice, item?.id)}
          </td>
        </tr>
      ))}
    </>
  );
};

export default DisplayPricingDataComp;
