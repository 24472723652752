import React from 'react'
import InnerLayout from "../../../../../../layouts/internalLayout"
import ProjectAncillariesLeft from './left'
import { useState } from 'react'
import { useMessage } from '../../../../../../context/messageContext'
import { Oval } from 'react-loader-spinner'

const ProjectAncillaries = () => {
  var access_token = localStorage.getItem("access_token")

  const [loading, setLoading] = useState(false)

  const { setMessage, setMessageType } = useMessage()
  return (
    <>
        {loading && (
                <div className='loader_main full_loader'>
                    <Oval
                        height={50}
                        width={50}
                        color="#44C8F5"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel='oval-loading'
                        secondaryColor="#44C8F5"
                        strokeWidth={2}
                        strokeWidthSecondary={2}
                    />
                </div>
            )}
        <InnerLayout pageTitle="Project Ancillaries" layoutType="full" leftContent ={<ProjectAncillariesLeft token={access_token} setMessage={setMessage} setMessageType={setMessageType} loading={loading} setLoading={setLoading} />} rightContent={''}  />
    </>
  )
}

export default ProjectAncillaries