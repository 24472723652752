import React, { useEffect, useState } from 'react'
import InnerLayout from "../../../../../../layouts/internalLayout"

import TradeDesignerLeft from './tradedesignerleft'
import { getDesignerSetting } from '../../../../../../services/designerServices'
import { useMessage } from '../../../../../../context/messageContext'
import { useNavigate } from 'react-router-dom'
import { Oval } from 'react-loader-spinner'

const TradeDesigners = () => {

    const accessToken = localStorage.getItem('access_token')

    const navigate = useNavigate()

    const [designerSetting, setDesignerSetting] = useState('')
    const [loading, setLoading] = useState(false)
    const { setMessage, setMessageType } = useMessage()

    useEffect(() => {
        getDesignerSettingServices()
    }, [])


    const getDesignerSettingServices = async () => {
        setLoading(true)
        const res = await getDesignerSetting("2", accessToken)

        if (res && res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                setLoading(false)
                setMessage(res?.message);
                setMessageType('success')
                setDesignerSetting(res?.data?.entity)
                setLoading(false)
            } else {
                setLoading(false)
                setMessage(res?.data.message ? res.data.message : 'Something went wrong');
                setMessageType('error')
            }
        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')

            if (res?.message === "Network Error") {
                navigate('/error/network')
            }
        }
    }

    return (
        <>
            {loading &&
                <div className='loader_main full_loader'>
                    <Oval
                        height={50}
                        width={50}
                        color="#44C8F5"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel='oval-loading'
                        secondaryColor="#44C8F5"
                        strokeWidth={2}
                        strokeWidthSecondary={2}
                    />
                </div>
            }

            <InnerLayout pageTitle="Trade Designer" layoutType="full" leftContent={<TradeDesignerLeft accessToken={accessToken} loading={loading} setLoading={setLoading} designerSetting={designerSetting} getDesignerSettingServices={getDesignerSettingServices} />} rightContent={''} />
        </>
    )
}

export default TradeDesigners